import { apiSlice } from "app/api/apiSlice";

export const alertsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllAlerts: builder.query({
            query: () => ({
                url: `Alerts/GetAll`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "Alerts", id: "LIST" }],
        }),
    }),
});

export const { useGetAllAlertsQuery } = alertsApiSlice;

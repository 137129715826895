import { apiSlice } from "app/api/apiSlice";

export const employeeCategoriesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getEmployeeCategoriesPaginated: builder.query({
            query: (query) => {
                const params = [];
                params.push(`limit=${(query && query.limit) || 10}`);
                params.push(`offset=${(query && query.offset) || 0}`);
                if (query && query.search)
                    params.push(
                        `search=${encodeURIComponent(query && query.search)}`
                    );
                if (query && query.filters)
                    params.push(
                        `filters=${encodeURIComponent(
                            JSON.stringify(query && query.filters)
                        )}`
                    );
                if (query && query.orderByCollection)
                    params.push(
                        `orderByCollection=${encodeURIComponent(
                            JSON.stringify(query && query.orderByCollection)
                        )}`
                    );
                const queryString =
                    params.length > 0 ? `?${params.join("&")}` : "";
                return {
                    url: `EmployeeCategories/GetAllPaginated${queryString.toString()}`,
                    validateStatus: (response, result) => {
                        return response.status === 200 && !result.isError;
                    },
                    query,
                };
            },
            providesTags: [{ type: "EmployeeCategories", id: "LIST" }],
        }),
        getAllEmployeeCategories: builder.query({
            query: () => ({
                url: `EmployeeCategories/GetAll`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "EmployeeCategories", id: "LIST" }],
        }),
        postEmployeeCategory: builder.mutation({
            query: (employeeCategoryData) => ({
                url: `EmployeeCategories`,
                method: "POST",
                body: {
                    ...employeeCategoryData,
                },
            }),
            invalidatesTags: [{ type: "EmployeeCategories", id: "LIST" }],
        }),
        getEmployeeCategoryById: builder.query({
            query: (id) => ({
                url: `EmployeeCategories/${id}`,
            }),
            providesTags: (result, error, arg) => [
                { type: "EmployeeCategoryId", id: arg },
            ],
        }),
        updateEmployeeCategory: builder.mutation({
            query: (employeeCategoryData) => ({
                url: `EmployeeCategories`,
                method: "PUT",
                body: {
                    ...employeeCategoryData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "EmployeeCategories", id: "LIST" },
                { type: "EmployeeCategoryId", id: arg.id },
                { type: "EmployeeById", id: "BYID" },
                { type: "Employees", id: "LIST" },
            ],
        }),
        deleteEmployeeCategoryById: builder.mutation({
            query: (id) => ({
                url: `EmployeeCategories/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "EmployeeCategories", id: "LIST" }],
        }),
    }),
});

export const {
    useGetAllEmployeeCategoriesQuery,
    usePostEmployeeCategoryMutation,
    useGetEmployeeCategoryByIdQuery,
    useUpdateEmployeeCategoryMutation,
    useDeleteEmployeeCategoryByIdMutation,
} = employeeCategoriesApiSlice;

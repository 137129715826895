import { apiSlice } from "app/api/apiSlice";

export const vehiclesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getVehiclesPaginated: builder.query({
            query: (query) => {
                const params = [];
                params.push(`limit=${(query && query.limit) || 10}`);
                params.push(`offset=${(query && query.offset) || 0}`);
                if (query && query.search)
                    params.push(
                        `search=${encodeURIComponent(query && query.search)}`
                    );
                if (query && query.filters)
                    params.push(
                        `filters=${encodeURIComponent(
                            JSON.stringify(query && query.filters)
                        )}`
                    );
                if (query && query.orderByCollection)
                    params.push(
                        `orderByCollection=${encodeURIComponent(
                            JSON.stringify(query && query.orderByCollection)
                        )}`
                    );
                const queryString =
                    params.length > 0 ? `?${params.join("&")}` : "";
                return {
                    url: `Vehicles/GetAllPaginated${queryString.toString()}`,
                    validateStatus: (response, result) => {
                        return response.status === 200 && !result.isError;
                    },
                    query,
                };
            },
            providesTags: [{ type: "Vehicles", id: "LIST" }],
        }),
        getVehicles: builder.query({
            query: () => ({
                url: `Vehicles/GetAll`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "Vehicles", id: "LIST" }],
        }),
        getVehiclePlanningsByVehicleId: builder.query({
            query: (Id) => ({
                url: `VehiclePlannings/${Id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: (result, error, arg) => [
                { type: "VehiclePlanningsByVehicleId", id: arg },
            ],
        }),
        getVehicleCategories: builder.query({
            query: () => ({
                url: `VehicleCategories/GetAll`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "VehicleCategories", id: "LIST" }],
        }),
        postVehicle: builder.mutation({
            query: (vehicleData) => ({
                url: `Vehicles`,
                method: "POST",
                body: {
                    ...vehicleData,
                },
            }),
            invalidatesTags: [
                { type: "Vehicles", id: "LIST" },
                { type: "VehiclesNotInWorkPlanning", id: "LIST" },
                { type: "Alerts", id: "LIST" },
            ],
        }),
        getVehicleTypes: builder.query({
            query: () => ({
                url: `VehicleTypes`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "VehicleTypes", id: "LIST" }],
        }),
        getVehicleById: builder.query({
            query: (id) => ({
                url: `Vehicles/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: (result, error, arg) => [
                { type: "Vehicles", id: arg },
                { type: "VehicleById", id: "BYID" },
            ],
        }),
        getvehiclesNotInWorkPlanning: builder.query({
            query: (id) => ({
                url: `Vehicles/GetVehiclesNotInWorkPlanning/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: (result, error, arg) => [
                { type: "VehiclesNotInWorkPlanning", id: arg },
                { type: "VehiclesNotInWorkPlanning", id: "LIST" },
            ],
        }),
        getVehiclesInWorkPlanningAndNotInEmployeePlanning: builder.query({
            query: (id) => ({
                url: `Vehicles/GetVehiclesInWorkPlanningAndNotInEmployeePlanning/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: (result, error, arg) => [
                {
                    type: "VehiclesInWorkPlanningAndNotInEmployeePlanning",
                    id: arg.split("-")[1],
                },
                // { type: "VehiclesNotInWorkPlanning", id: "LIST" },
            ],
        }),
        getVehiclesInWorkPlanning: builder.query({
            query: (id) => ({
                url: `Vehicles/GetVehiclesInWorkPlanning/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: (result, error, arg) => [
                { type: "VehiclesInWorkPlanning", id: arg },
            ],
        }),
        getEmployeePlanningsByVehicleId: builder.query({
            query: (id) => ({
                url: `EmployeePlannings/GetEmployeePlanningsByVehicleId/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: (result, error, arg) => [
                { type: "EmployeePlannings", id: arg },
                { type: "EmployeePlannings", id: "LIST" },
            ],
        }),
        updateVehicle: builder.mutation({
            query: (vehicleData) => ({
                url: `Vehicles`,
                method: "PUT",
                body: {
                    ...vehicleData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Vehicles", id: "LIST" },
                { type: "Vehicles", id: arg.id },
                { type: "WorkPlanningById", id: "BYID" },
                { type: "Alerts", id: "LIST" },
            ],
        }),
        deleteVehicleById: builder.mutation({
            query: (id) => ({
                url: `Vehicles/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [
                { type: "Vehicles", id: "LIST" },
                { type: "Alerts", id: "LIST" },
            ],
        }),
    }),
});

export const {
    useGetVehiclesQuery,
    useGetVehicleCategoriesQuery,
    useGetVehiclesInWorkPlanningQuery,
    useGetEmployeePlanningsByVehicleIdQuery,
    useGetVehiclePlanningsByVehicleIdQuery,
    usePostVehicleMutation,
    useGetVehicleTypesQuery,
    useGetVehicleByIdQuery,
    useGetWorkPlanningPlanningsByVehicleIdQuery,
    useLazyGetVehiclesInWorkPlanningAndNotInEmployeePlanningQuery,
    useUpdateVehicleMutation,
    useDeleteVehicleByIdMutation,
    useGetvehiclesNotInWorkPlanningQuery,
} = vehiclesApiSlice;

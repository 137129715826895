import { useState, useRef } from "react";
import { usePostClientMutation } from "./clientsApiSlice";
import PageHeader from "components/PageHeader";
import {
    Box,
    TextField,
    Typography,
    useTheme,
    Alert,
    useMediaQuery,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";

const NewClientForm = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate(); /* Set the Navigation Service */
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const clientSchema = yup.object().shape({
        name: yup.string().required(t("requiredField")),
        address: yup.string().required(t("requiredField")),
        contact: yup.object().shape({
            name: yup.string().required(t("requiredField")),
            position: yup.string().required(t("requiredField")),
            email: yup
                .string()
                .email(t("invalidEmail"))
                .required(t("requiredField")),
            phoneNumber: yup.string().required(t("requiredField")),
        }),
    }); /* Set the Login Schema */

    const initialValuesClient = {
        name: "",
        address: "",
        contact: {
            name: "",
            position: "",
            email: "",
            phoneNumber: "",
        },
    }; /* Set the Form Initial Values */

    const [createClient, { isLoading }] =
        usePostClientMutation(); /* Set the Login Mutation */

    const nameRef = useRef(); /* User Ref to set the focus to the user input */
    const errRef = useRef(); /* Error Ref to set the focus to error */

    const [errMsg, setErrMsg] = useState(""); /* Set the Error Message State */
    const [openSnackbar, setOpenSnackBar] = useState(false);

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    };

    const handleFormSubmit = async (values, onSubmitProps) => {
        setErrMsg("");

        try {
            const { codeResult, clientId } = await createClient(
                values
            ).unwrap();

            if (codeResult !== RESPONSES.client.successToInsertClient) {
                setErrMsg(t("invalidToInsertClient"));
            } else {
                setOpenSnackBar(true);
                onSubmitProps.resetForm();
                navigate(`${location.pathname.replace("new", clientId)}`);
            }
        } catch (err) {
            setErrMsg(t("errorInsertClient"));
        }
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesClient}
            validationSchema={clientSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        m={!isNonMobile ? "0" : "1.5rem 2.5rem"}
                        display="grid"
                        gridTemplateRows="repeat(3, auto)"
                        gap="20px"
                        borderRadius="5px"
                        p="2rem"
                    >
                        <PageHeader title={t("newClientTitle")} subtitle="" />
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                            /* p="2rem" */
                            borderRadius="5px"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("clientInformation")}
                            </Typography>
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <TextField
                                    autoFocus={!isNonMobile ? false : true}
                                    variant="outlined"
                                    label={t("name")}
                                    inputRef={nameRef}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    name="name"
                                    error={
                                        Boolean(touched.name) &&
                                        Boolean(errors.name)
                                    }
                                    helperText={touched.name && errors.name}
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("address")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.address}
                                    name="address"
                                    error={
                                        Boolean(touched.address) &&
                                        Boolean(errors.address)
                                    }
                                    helperText={
                                        touched.address && errors.address
                                    }
                                    sx={{ width: "100%" }}
                                />
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                            /* p="2rem" */
                            borderRadius="5px"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("contactInformation")}
                            </Typography>
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <TextField
                                    variant="outlined"
                                    label={t("name")}
                                    onBlur={() => handleBlur("contact.name")}
                                    onChange={(event) =>
                                        handleChange("contact.name")(
                                            event.target.value
                                        )
                                    }
                                    name="contact.name"
                                    value={values.contact.name}
                                    error={
                                        Boolean(touched.contact?.name) &&
                                        Boolean(errors.contact?.name)
                                    }
                                    helperText={
                                        touched.contact?.name &&
                                        errors.contact?.name
                                    }
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("position")}
                                    onBlur={() =>
                                        handleBlur("contact.position")
                                    }
                                    onChange={(event) =>
                                        handleChange("contact.position")(
                                            event.target.value
                                        )
                                    }
                                    name="contact.position"
                                    value={values.contact.position}
                                    error={
                                        Boolean(touched.contact?.position) &&
                                        Boolean(errors.contact?.position)
                                    }
                                    helperText={
                                        touched.contact?.position &&
                                        errors.contact?.position
                                    }
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("email")}
                                    onBlur={() => handleBlur("contact.email")}
                                    onChange={(event) =>
                                        handleChange("contact.email")(
                                            event.target.value
                                        )
                                    }
                                    name="contact.email"
                                    value={values.contact.email}
                                    error={
                                        Boolean(touched.contact?.email) &&
                                        Boolean(errors.contact?.email)
                                    }
                                    helperText={
                                        touched.contact?.email &&
                                        errors.contact?.email
                                    }
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("phoneNumber")}
                                    onBlur={() =>
                                        handleBlur("contact.phoneNumber")
                                    }
                                    onChange={(event) =>
                                        handleChange("contact.phoneNumber")(
                                            event.target.value
                                        )
                                    }
                                    name="contact.phoneNumber"
                                    value={values.contact.phoneNumber}
                                    error={
                                        Boolean(touched.contact?.phoneNumber) &&
                                        Boolean(errors.contact?.phoneNumber)
                                    }
                                    helperText={
                                        touched.contact?.phoneNumber &&
                                        errors.contact?.phoneNumber
                                    }
                                    sx={{ width: "100%" }}
                                />
                            </Box>
                        </Box>
                        {errMsg && (
                            <Alert
                                ref={errRef}
                                severity="error"
                                sx={{
                                    mt: "10px",
                                    backgroundColor: "#540e0e",
                                    alignItems: "center",
                                    color: "#FFFFFF",
                                }}
                            >
                                {errMsg}
                            </Alert>
                        )}
                        {/* BUTTONS */}
                        <LoadingButton
                            fullWidth
                            type="submit"
                            loading={isLoading}
                            sx={{
                                width: "100%",
                                p: "1rem",
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.neutral.white,
                                "&:hover": {
                                    backgroundColor:
                                        theme.palette.primary.light,
                                },
                            }}
                        >
                            {t("addNewClienteButton")}
                        </LoadingButton>
                    </Box>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity="success"
                            sx={{
                                mt: "10px",
                                backgroundColor: "#10781F",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {t("successToInsertClient")}
                        </Alert>
                    </Snackbar>
                </form>
            )}
        </Formik>
    );
};

export default NewClientForm;

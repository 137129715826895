import { useState, useRef } from "react";
import PageHeader from "components/PageHeader";
import {
    Box,
    TextField,
    Typography,
    useTheme,
    Alert,
    FormControl,
    useMediaQuery,
    Autocomplete,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import { Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";
import { usePostApplicationMutation } from "./applicationsApiSlice";
import { useGetAllEmployeeCategoriesQuery } from "features/employeeCategories/employeeCategoriesApiSlice";

const NewApplicationForm = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const nameRef = useRef(); /* User Ref to set the focus to the user input */

    const applicationSchema = yup.object().shape({
        name: yup.string().required(t("requiredField")),
        categoryId: yup.string().required(t("requiredField")),
        phoneNumber: yup.string().required(t("requiredField")),
        country: yup.string().required(t("requiredField")),
    });

    const initialValuesApplication = {
        name: "",
        categoryId: "",
        phoneNumber: "",
        country: "",
    }; /* Set the Form Initial Values */

    const [createApplication, { isLoading }] = usePostApplicationMutation();

    const { data: employeeCategories, isLoadingEmployeeCategories } =
        useGetAllEmployeeCategoriesQuery();

    const [errMsg, setErrMsg] = useState("");
    const [openSnackbar, setOpenSnackBar] = useState(false);

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    };

    const handleFormSubmit = async (values, onSubmitProps) => {
        setErrMsg("");
        try {
            const { codeResult } = await createApplication(values).unwrap();

            if (
                codeResult !== RESPONSES.application.sucessToInsertApplication
            ) {
                if (
                    codeResult ===
                    RESPONSES.application.invalidToInsertApplication
                ) {
                    setErrMsg(t("invalidToInsertApplication"));
                } else {
                    setErrMsg(t("errorToInsertApplication"));
                }
            } else {
                setOpenSnackBar(true);
                onSubmitProps.resetForm();
            }
        } catch (err) {
            setErrMsg(t("errorToInsertApplication"));
        }
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesApplication}
            validationSchema={applicationSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                setFieldError,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        m={!isNonMobile ? "0" : "1.5rem 2.5rem"}
                        display="grid"
                        gridTemplateRows="repeat(3, auto)"
                        gap="20px"
                        borderRadius="5px"
                        p="2rem"
                    >
                        <PageHeader
                            title={t("newApplicationTitle")}
                            subtitle=""
                        />
                        <Box
                            display="flex"
                            gap={!isNonMobile ? "10px" : "20px"}
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("information")}
                            </Typography>
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <TextField
                                    autoFocus={!isNonMobile ? false : true}
                                    variant="outlined"
                                    label={t("name")}
                                    inputRef={nameRef}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    name="name"
                                    error={
                                        Boolean(touched.name) &&
                                        Boolean(errors.name)
                                    }
                                    helperText={touched.name && errors.name}
                                    sx={{ width: "100%" }}
                                />

                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingEmployeeCategories}
                                    options={employeeCategories || []}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                        (employeeCategories &&
                                            employeeCategories.find(
                                                (category) =>
                                                    category.id ===
                                                    values.categoryId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "categoryId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                    }}
                                    onBlur={handleBlur("categoryId")}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("category")}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.categoryId
                                                    ) &&
                                                    Boolean(errors.categoryId)
                                                }
                                                helperText={
                                                    touched.categoryId &&
                                                    errors.categoryId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />
                                <TextField
                                    label={t("phoneNumber")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                    name="phoneNumber"
                                    error={
                                        Boolean(touched.phoneNumber) &&
                                        Boolean(errors.phoneNumber)
                                    }
                                    helperText={
                                        touched.phoneNumber &&
                                        errors.phoneNumber
                                    }
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("country")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.country}
                                    name="country"
                                    error={
                                        Boolean(touched.country) &&
                                        Boolean(errors.country)
                                    }
                                    helperText={
                                        touched.country && errors.country
                                    }
                                    sx={{ width: "100%" }}
                                />
                            </Box>
                        </Box>
                        {errMsg && (
                            <Alert
                                severity="error"
                                sx={{
                                    mt: "10px",
                                    backgroundColor: "#540e0e",
                                    color: "#FFFFFF",
                                    alignItems: "center",
                                }}
                            >
                                {errMsg}
                            </Alert>
                        )}
                        {/* BUTTONS */}
                        <LoadingButton
                            fullWidth
                            type="submit"
                            loading={isLoading}
                            sx={{
                                width: "100%",
                                p: "1rem",
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.neutral.white,
                                "&:hover": {
                                    backgroundColor:
                                        theme.palette.primary.light,
                                },
                            }}
                        >
                            {t("newApplicationTitle")}
                        </LoadingButton>
                    </Box>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity="success"
                            sx={{
                                mt: "10px",
                                backgroundColor: "#10781F",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {t("successToInsertApplication")}
                        </Alert>
                    </Snackbar>
                </form>
            )}
        </Formik>
    );
};

export default NewApplicationForm;

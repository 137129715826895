import { useState, useRef } from "react";
import {
    usePostVehicleMutation,
    useGetVehicleTypesQuery,
    useGetVehicleCategoriesQuery,
} from "./vehiclesApiSlice";
import PageHeader from "components/PageHeader";
import {
    Box,
    TextField,
    Typography,
    useTheme,
    useMediaQuery,
    Alert,
    Autocomplete,
    FormControl,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import { Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";
import { useNavigate, useLocation } from "react-router-dom";

const NewVehicleForm = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const nameRef = useRef(); /* User Ref to set the focus to the user input */

    const [errMsg, setErrMsg] = useState(""); /* Set the Error Message State */
    const [openSnackbar, setOpenSnackBar] = useState(false);

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    };

    const vehicleSchema = yup.object().shape({
        brand: yup.string().required(t("requiredField")),
        licensePlateNumber: yup.string().required(t("requiredField")),
        vehicleTypeId: yup.string().required(t("requiredField")),
        insurancePrice: yup
            .number(t("invalidValue"))
            .positive(t("invalidValue"))
            .typeError(t("invalidValue"))
            .notRequired(),
        inspectionPrice: yup
            .number(t("invalidValue"))
            .positive(t("invalidValue"))
            .typeError(t("invalidValue"))
            .notRequired(),
        monthPrice: yup
            .number(t("invalidValue"))
            .positive(t("invalidValue"))
            .typeError(t("invalidValue"))
            .notRequired(),
        iUC: yup
            .number(t("invalidValue"))
            .positive(t("invalidValue"))
            .typeError(t("invalidValue"))
            .notRequired(),
        vehicleCategoryId: yup.string().required(t("requiredField")),
        lastMaintenance: yup.date().nullable(true).notRequired(),
        inspectionDate: yup.date().nullable(true).notRequired(),
        licensePlateNumberMonth: yup.date().nullable(true).notRequired(),
        kmLastMaintenance: yup
            .number(t("invalidValue"))
            .positive(t("invalidValue"))
            .typeError(t("invalidValue"))
            .notRequired(),
    }); /* Set the vehicle forms Schema */

    const initialValuesVehicle = {
        brand: "",
        licensePlateNumber: "",
        vehicleTypeId: "",
        insurancePrice: "",
        inspectionDate: null,
        licensePlateNumberMonth: null,
        inspectionPrice: "",
        monthPrice: "",
        iUC: "",
        vehicleCategoryId: "",
        lastMaintenance: null,
        kmLastMaintenance: "",
    }; /* Set the Forms Initial Values */

    const { data: vehicleTypes, isLoading: isLoadingVehicleTypes } =
        useGetVehicleTypesQuery();

    const { data: vehicleCategories, isLoading: isLoadingVehicleCategories } =
        useGetVehicleCategoriesQuery();

    const [createVehicle, { isLoading }] =
        usePostVehicleMutation(); /* Set the Vehicle Mutation */

    const handleFormSubmit = async (values, onSubmitProps) => {
        setErrMsg("");
        try {
            let vehicleToInsert;
            vehicleToInsert = {
                Brand: values.brand,
                LicensePlateNumber: values.licensePlateNumber,
                VehicleTypeId: values.vehicleTypeId,
                InsurancePrice: values.insurancePrice,
                InspectionPrice: values.inspectionPrice,
                MonthPrice: values.monthPrice,
                IUC: values.iUC,
                LicensePlateNumberMonth: values.licensePlateNumberMonth,
                InspectionDate: values.inspectionDate,
                vehicleCategoryId: values.vehicleCategoryId,
                lastMaintenance: values.lastMaintenance,
                kmLastMaintenance: values.kmLastMaintenance,
            };
            const { codeResult, vehicleId } = await createVehicle(
                vehicleToInsert
            ).unwrap();

            if (codeResult !== RESPONSES.vehicle.sucessToInsertVehicle) {
                if (codeResult === RESPONSES.vehicle.invalidVehicle) {
                    setErrMsg(t("invalidVehicle"));
                } else {
                    setErrMsg(t("errorInsertVehicle"));
                }
            } else {
                setOpenSnackBar(true);
                onSubmitProps.resetForm();
                navigate(`${location.pathname.replace("new", vehicleId)}`);
            }
        } catch (err) {
            setErrMsg(t("errorInsertVehicle"));
        }
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesVehicle}
            validationSchema={vehicleSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        m={!isNonMobile ? "0" : "1.5rem 2.5rem"}
                        display="grid"
                        gridTemplateRows="repeat(3, auto)"
                        gap="20px"
                        borderRadius="5px"
                        p="2rem"
                    >
                        <PageHeader title={t("newVehicleTitle")} subtitle="" />
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("vehicleInformation")}
                            </Typography>
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <TextField
                                    autoFocus={!isNonMobile ? false : true}
                                    variant="outlined"
                                    label={t("brand")}
                                    inputRef={nameRef}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.brand}
                                    name="brand"
                                    error={
                                        Boolean(touched.brand) &&
                                        Boolean(errors.brand)
                                    }
                                    helperText={touched.brand && errors.brand}
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("licensePlateNumber")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.licensePlateNumber}
                                    name="licensePlateNumber"
                                    error={
                                        Boolean(touched.licensePlateNumber) &&
                                        Boolean(errors.licensePlateNumber)
                                    }
                                    helperText={
                                        touched.licensePlateNumber &&
                                        errors.licensePlateNumber
                                    }
                                    sx={{ width: "100%" }}
                                />

                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingVehicleTypes}
                                    options={vehicleTypes || []}
                                    getOptionLabel={(option) => option.type}
                                    value={
                                        (vehicleTypes &&
                                            vehicleTypes.find(
                                                (vehicleType) =>
                                                    vehicleType.id ===
                                                    values.vehicleTypeId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "vehicleTypeId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                    }}
                                    onBlur={handleBlur("vehicleTypeId")}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("vehicleType")}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.vehicleTypeId
                                                    ) &&
                                                    Boolean(
                                                        errors.vehicleTypeId
                                                    )
                                                }
                                                helperText={
                                                    touched.vehicleTypeId &&
                                                    errors.vehicleTypeId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />

                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingVehicleCategories}
                                    options={vehicleCategories || []}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                        (vehicleCategories &&
                                            vehicleCategories.find(
                                                (vehicleCategory) =>
                                                    vehicleCategory.id ===
                                                    values.vehicleCategoryId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "vehicleCategoryId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                    }}
                                    onBlur={handleBlur("vehicleCategoryId")}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("vehicleCategory")}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.vehicleCategoryId
                                                    ) &&
                                                    Boolean(
                                                        errors.vehicleCategoryId
                                                    )
                                                }
                                                helperText={
                                                    touched.vehicleCategoryId &&
                                                    errors.vehicleCategoryId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />
                            </Box>
                        </Box>

                        {values.vehicleTypeId === 1 ? (
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <TextField
                                    label={t("insurancePrice")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.insurancePrice}
                                    name="insurancePrice"
                                    error={
                                        Boolean(touched.insurancePrice) &&
                                        Boolean(errors.insurancePrice)
                                    }
                                    helperText={
                                        touched.insurancePrice &&
                                        errors.insurancePrice
                                    }
                                    InputProps={{
                                        endAdornment: "€",
                                    }}
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("inspectionPrice")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.inspectionPrice}
                                    name="inspectionPrice"
                                    error={
                                        Boolean(touched.inspectionPrice) &&
                                        Boolean(errors.inspectionPrice)
                                    }
                                    helperText={
                                        touched.inspectionPrice &&
                                        errors.inspectionPrice
                                    }
                                    InputProps={{
                                        endAdornment: "€",
                                    }}
                                    sx={{ width: "100%" }}
                                />
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        label={t("lastMaintenanceDate")}
                                        value={values.lastMaintenance}
                                        format="DD/MM/YYYY"
                                        onChange={(value) =>
                                            setFieldValue(
                                                "lastMaintenance",
                                                value
                                            )
                                        }
                                    />
                                    <DatePicker
                                        label={t("inspectionDate")}
                                        value={values.inspectionDate}
                                        format="DD/MM/YYYY"
                                        onChange={(value) =>
                                            setFieldValue(
                                                "inspectionDate",
                                                value
                                            )
                                        }
                                    />
                                    <DatePicker
                                        label={t("licensePlateNumberDate")}
                                        value={values.licensePlateNumberMonth}
                                        format="DD/MM/YYYY"
                                        onChange={(value) =>
                                            setFieldValue(
                                                "licensePlateNumberMonth",
                                                value
                                            )
                                        }
                                    />
                                </LocalizationProvider>
                                <TextField
                                    label={t("kmLastMaintenance")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.kmLastMaintenance}
                                    name="kmLastMaintenance"
                                    error={
                                        Boolean(touched.kmLastMaintenance) &&
                                        Boolean(errors.kmLastMaintenance)
                                    }
                                    helperText={
                                        touched.kmLastMaintenance &&
                                        errors.kmLastMaintenance
                                    }
                                    InputProps={{
                                        endAdornment: "Km",
                                    }}
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("iuc")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.iUC}
                                    name="iUC"
                                    error={
                                        Boolean(touched.iUC) &&
                                        Boolean(errors.iUC)
                                    }
                                    helperText={touched.iUC && errors.iUC}
                                    InputProps={{
                                        endAdornment: "€",
                                    }}
                                    sx={{ width: "100%" }}
                                />
                            </Box>
                        ) : values.vehicleTypeId === 2 ? (
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <TextField
                                    label={t("monthPrice")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.monthPrice}
                                    name="monthPrice"
                                    error={
                                        Boolean(touched.monthPrice) &&
                                        Boolean(errors.monthPrice)
                                    }
                                    helperText={
                                        touched.monthPrice && errors.monthPrice
                                    }
                                    InputProps={{
                                        endAdornment: "€",
                                    }}
                                    sx={{ width: "100%" }}
                                />
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        label={t("lastMaintenanceDate")}
                                        value={values.lastMaintenance}
                                        format="DD/MM/YYYY"
                                        onChange={(value) =>
                                            setFieldValue(
                                                "lastMaintenance",
                                                value
                                            )
                                        }
                                    />
                                </LocalizationProvider>
                                <TextField
                                    label={t("kmLastMaintenance")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.kmLastMaintenance}
                                    name="kmLastMaintenance"
                                    error={
                                        Boolean(touched.kmLastMaintenance) &&
                                        Boolean(errors.kmLastMaintenance)
                                    }
                                    helperText={
                                        touched.kmLastMaintenance &&
                                        errors.kmLastMaintenance
                                    }
                                    InputProps={{
                                        endAdornment: "Km",
                                    }}
                                    sx={{ width: "100%" }}
                                />
                            </Box>
                        ) : (
                            <div></div>
                        )}

                        {/* </Box> */}
                        {errMsg && (
                            <Alert
                                severity="error"
                                sx={{
                                    mt: "10px",
                                    backgroundColor: "#540e0e",
                                    color: "#FFFFFF",
                                    alignItems: "center",
                                }}
                            >
                                {errMsg}
                            </Alert>
                        )}
                        {/* BUTTONS */}
                        <LoadingButton
                            fullWidth
                            type="submit"
                            loading={isLoading}
                            sx={{
                                width: "100%",
                                p: "1rem",
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.neutral.white,
                                "&:hover": {
                                    backgroundColor:
                                        theme.palette.primary.light,
                                },
                            }}
                        >
                            {t("addVehicle")}
                        </LoadingButton>
                    </Box>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity="success"
                            sx={{
                                mt: "10px",
                                backgroundColor: "#10781F",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {t("sucessToInsertVehicle")}
                        </Alert>
                    </Snackbar>
                </form>
            )}
        </Formik>
    );
};
export default NewVehicleForm;

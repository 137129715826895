import { useState, useRef } from "react";
import {
    useGetInternalRegistByIdQuery,
    useUpdateInternalRegistMutation,
    useDeleteInternalRegistByIdMutation,
} from "./internalRegistsApiSlice";
import PageHeader from "components/PageHeader";
import LoadingSpinner from "components/LoadingSpinner";
import {
    Box,
    TextField,
    Typography,
    useTheme,
    Alert,
    useMediaQuery,
    Autocomplete,
    FormControl,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";
import { useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useGetAllOfficeEmployeesQuery } from "features/employees/employeesApiSlice";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const EditInternalRegistForm = () => {
    // #region Setups

    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate(); /* Set the Navigation Service */
    const location = useLocation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const nameRef = useRef(); /* User Ref to set the focus to the user input */

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMsg(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
        useState(false);

    const handleOpenDeleteConfirmationDialog = () => {
        setOpenDeleteConfirmationDialog(true);
    };

    const handleCloseDeleteConfirmationDialog = () => {
        setOpenDeleteConfirmationDialog(false);
    };
    //#endregion Setups

    const { id } = useParams();

    const { data: employees, isLoading: isLoadingEmployees } =
        useGetAllOfficeEmployeesQuery();

    const { data: InternalRegist, isLoading: isLoadingGetInternalRegist } =
        useGetInternalRegistByIdQuery(id);

    const [updateInternalRegist, { isLoading: isLoadingUpdateInternalRegist }] =
        useUpdateInternalRegistMutation();

    const [deleteInternalRegist, { isLoading: isLoadingDeleteInternalRegist }] =
        useDeleteInternalRegistByIdMutation();

    const internalRegistSchema = yup.object().shape({
        monthCost: yup.date().required(t("requiredField")),
        employeeId: yup.string().required(t("requiredField")),
        description: yup.string().required(t("requiredField")),
        cost: yup
            .number(t("invalidValue"))
            .required(t("requiredField"))
            .typeError(t("invalidValue")),
    });

    const initialValuesInternalRegist = {
        description: "",
        monthCost: null,
        cost: "",
        employeeId: "",
    };

    const handleFormSubmit = async (values, onSubmitProps) => {
        try {
            if (!values.monthCost || values.monthCost === "") {
                handleSnackbarOpen(t("requiredMonthCost"), "error");
            } else {
                const { codeResult } = await updateInternalRegist(
                    values
                ).unwrap();

                if (
                    codeResult !==
                    RESPONSES.internalRegists.sucessToUpdateInternalRegist
                ) {
                    if (
                        codeResult ===
                        RESPONSES.internalRegists.invalidToUpdateInternalRegist
                    ) {
                        handleSnackbarOpen(
                            t("invalidToUpdateInternalRegist"),
                            "error"
                        );
                    } else {
                        handleSnackbarOpen(
                            t("errorUpdateInternalRegist"),
                            "error"
                        );
                    }
                } else {
                    handleSnackbarOpen(
                        t("sucessToUpdateInternalRegist"),
                        "success"
                    );
                }
            }
        } catch (err) {
            handleSnackbarOpen(t("errorUpdateInternalRegist"), "error");
        }
    };

    const handleDeleteInternalRegist = async () => {
        setOpenDeleteConfirmationDialog(false);

        try {
            const { codeResult } = await deleteInternalRegist(id).unwrap();

            if (
                codeResult !==
                RESPONSES.internalRegists.sucessToDeleteInternalRegist
            ) {
                if (
                    codeResult ===
                    RESPONSES.internalRegists.invalidToDeleteInternalRegist
                ) {
                    handleSnackbarOpen(
                        t("invalidToDeleteInternalRegist"),
                        "error"
                    );
                } else {
                    handleSnackbarOpen(t("errorDeleteInternalRegist"), "error");
                }
            } else {
                navigate(`/${location.pathname.split("/")[1]}`);
            }
        } catch (err) {
            handleSnackbarOpen(t("errorDeleteInternalRegist"), "error");
        }
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={InternalRegist || initialValuesInternalRegist}
            validationSchema={internalRegistSchema}
            enableReinitialize={true}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    {isLoadingGetInternalRegist ||
                    isLoadingUpdateInternalRegist ||
                    isLoadingDeleteInternalRegist ? (
                        <LoadingSpinner />
                    ) : null}
                    <Box
                        m={!isNonMobile ? "0" : "1.5rem 2.5rem"}
                        display={!isNonMobile ? "flex" : "grid"}
                        gridTemplateRows={
                            !isNonMobile ? undefined : "repeat(4, auto)"
                        }
                        flexDirection={!isNonMobile ? "column" : undefined}
                        gap="20px"
                        borderRadius="5px"
                        p="2rem"
                    >
                        <PageHeader title={values.name} subtitle="" />

                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("InternalRegistInformation")}
                            </Typography>
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <TextField
                                    variant="outlined"
                                    label={t("description")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.description}
                                    name="description"
                                    error={
                                        Boolean(touched.description) &&
                                        Boolean(errors.description)
                                    }
                                    helperText={
                                        touched.description &&
                                        errors.description
                                    }
                                    sx={{ width: "100%" }}
                                    multiline
                                />

                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingEmployees}
                                    options={employees || []}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                        (employees &&
                                            employees.find(
                                                (employee) =>
                                                    employee.id ===
                                                    values.employeeId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "employeeId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                    }}
                                    onBlur={handleBlur("employeeId")}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("employee")}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.employeeId
                                                    ) &&
                                                    Boolean(errors.employeeId)
                                                }
                                                helperText={
                                                    touched.employeeId &&
                                                    errors.employeeId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />

                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="pt"
                                >
                                    <DatePicker
                                        label={t("costDate")}
                                        value={dayjs(values.monthCost)}
                                        format="DD/MM/YYYY"
                                        onChange={(value) =>
                                            setFieldValue("monthCost", value)
                                        }
                                    />
                                </LocalizationProvider>
                                <TextField
                                    label={t("monthlyCost")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.cost}
                                    name="cost"
                                    error={
                                        Boolean(touched.cost) &&
                                        Boolean(errors.cost)
                                    }
                                    InputProps={{
                                        endAdornment: "€",
                                    }}
                                    helperText={touched.cost && errors.cost}
                                    sx={{ width: "100%" }}
                                />
                            </Box>
                        </Box>

                        {/* BUTTONS */}
                        <Box display="flex" gap="10px">
                            <LoadingButton
                                fullWidth
                                type="button"
                                onClick={handleOpenDeleteConfirmationDialog}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                            >
                                {t("deleteInternalRegistButton")}
                            </LoadingButton>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                loading={isLoadingUpdateInternalRegist}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                            >
                                {t("editInternalRegist")}
                            </LoadingButton>
                        </Box>
                    </Box>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity={snackbarSeverity}
                            sx={{
                                mt: "10px",
                                backgroundColor:
                                    snackbarSeverity === "success"
                                        ? "#10781F"
                                        : "#ff000082",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {snackbarMsg}
                        </Alert>
                    </Snackbar>
                    <Dialog
                        open={openDeleteConfirmationDialog}
                        onClose={handleCloseDeleteConfirmationDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("deleteInternalRegistConfirmationTitle")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t("deleteInternalRegistConfirmationMessage")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <LoadingButton
                                sx={{
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                                onClick={handleCloseDeleteConfirmationDialog}
                            >
                                {t("noMessage")}
                            </LoadingButton>
                            <LoadingButton
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                                // isLoading={isLoadingDeleteInternalRegist}
                                onClick={handleDeleteInternalRegist}
                                autoFocus
                            >
                                {t("yesMessage")}
                            </LoadingButton>
                        </DialogActions>
                    </Dialog>
                </form>
            )}
        </Formik>
    );
};

export default EditInternalRegistForm;

import React, { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

const MainLayout = () => {
    const isNonMobile = useMediaQuery("(min-width: 600px)");
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [isSidebarOpenMobile, setIsSidebarOpenMobile] = useState(false);

    return (
        <Box
            display={isNonMobile && isSidebarOpen ? "flex" : "block"}
            width="100%"
            height="100%"
        >
            <Sidebar
                isNonMobile={isNonMobile}
                drawerWidth="250px"
                isSidebarOpen={
                    isNonMobile ? isSidebarOpen : isSidebarOpenMobile
                }
                setIsSidebarOpen={
                    isNonMobile ? setIsSidebarOpen : setIsSidebarOpenMobile
                }
            />
            <Box flex={1}>
                <Navbar
                    isSidebarOpen={
                        isNonMobile ? isSidebarOpen : isSidebarOpenMobile
                    }
                    setIsSidebarOpen={
                        isNonMobile ? setIsSidebarOpen : setIsSidebarOpenMobile
                    }
                />

                <Outlet
                    setIsSidebarOpen={
                        isNonMobile ? setIsSidebarOpen : setIsSidebarOpenMobile
                    }
                />

                {/* <Footer /> */}
            </Box>
        </Box>
    );
};

export default MainLayout;

import { useSelector } from "react-redux";
import { selectCurrentUser, selectCurrentToken } from "features/auth/authSlice";

const useAuth = () => {
    const user = useSelector(selectCurrentUser);
    const token = useSelector(selectCurrentToken);

    if (user && token) {
        const username = user.name;
        const userType = user.userType.type;
        const userId = user.id;
        const userPhoto = user.photo;
        const accessToken = token;

        return { username, userType, userId, userPhoto, accessToken };
    }

    return {
        username: "",
        userType: "",
        userId: 0,
        userPhoto: "",
        accessToken: "",
    };
};
export default useAuth;

import React, { useState } from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import LoginForm from "./LoginForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import bg from "img/loginBG.png";
import { useTranslation } from "react-i18next";

const Login = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const isNonMobileScreens = useMediaQuery("(min-width: 600px)");

    const [showLoginForm, setShowLoginForm] = useState(true);

    const toggleForm = () => {
        setShowLoginForm(!showLoginForm);
    };

    return (
        <>
            <Box display="flex" sx={{ filter: "blur(5px)" }}>
                <Box
                    width="70%"
                    sx={{
                        backgroundImage: `url(${bg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: "100vh",
                    }}
                />
                <Box backgroundColor={theme.palette.primary.main} width="30%" />
            </Box>

            <Box
                width={!isNonMobileScreens ? "80%" : "40%"}
                p="2rem"
                borderRadius="5px"
                backgroundColor={theme.palette.background.D}
                position="absolute"
                textAlign="center"
                top="50%"
                left={!isNonMobileScreens ? "50%" : "70%"}
                sx={{ transform: "translate(-50%, -50%)" }}
            >
                <Typography
                    fontWeight="500"
                    fontSize="25px"
                    color="primary"
                    variant="h5"
                    textAlign="left"
                    sx={{ mb: "1.5rem" }}
                >
                    {showLoginForm ? (
                        <>{t("greeting")}</>
                    ) : (
                        <>{t("forgotPassword")}</>
                    )}
                </Typography>

                {showLoginForm ? (
                    <LoginForm toggleForm={toggleForm} />
                ) : (
                    <ForgotPasswordForm toggleForm={toggleForm} />
                )}
                {/* <LoginForm /> */}
            </Box>
        </>
    );
};
export default Login;

import React, { useCallback, useRef } from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import PageHeader from "components/PageHeader";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Add, Edit } from "@mui/icons-material";
import { apiSlice } from "app/api/apiSlice";
import { useDispatch } from "react-redux";
import CustomTable from "components/CustomTable";

const PAGE_SIZE_OPTIONS = [10, 20, 50];

const AccomodationsList = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate(); /* Set the Navigation Service */
    const location = useLocation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */
    const dispatch = useDispatch();

    const columns = useRef([
        {
            field: "address",
            title: t("address"),
            filtering: true,
        },
        {
            field: "contact.name",
            title: t("contactName"),
            filtering: true,
            valueGetter: (params) => params.row.contact.name,
        },
        {
            field: "contact.phoneNumber",
            title: t("phoneNumber"),
            filtering: true,
        },
    ]);

    const columnsMobile = useRef([
        {
            field: "address",
            title: t("address"),
            filtering: true,
        },
    ]);

    const tableRef = useRef(null);
    const handleGetAccomodations = useCallback(
        async (query) => {
            const limit = query.pageSize;
            const offset = query.page * query.pageSize;
            const search = query.search || null;
            const filters = query.filters
                ? JSON.stringify(
                      query.filters.map((filter) => ({
                          column: filter.column.field,
                          operator: filter.operator,
                          value: filter.value,
                      }))
                  )
                : null;
            const orderByCollection = query.orderByCollection
                ? JSON.stringify(
                      query.orderByCollection.map((orderBy) => ({
                          column: isNonMobile
                              ? columns.current[orderBy.orderBy].field
                              : columnsMobile.current[orderBy.orderBy].field,
                          orderDirection: orderBy.orderDirection,
                      }))
                  )
                : null;

            const response = await dispatch(
                apiSlice.endpoints.getAccomodationsPaginated.initiate({
                    limit: limit || 10,
                    offset: offset || 0,
                    search,
                    filters,
                    orderByCollection,
                })
            ).unwrap();
            return {
                data: response.accomodations,
                page: query.page,
                totalCount: response.totalAccomodations || 0,
            };
        },
        [isNonMobile ? columns : columnsMobile]
    );

    return (
        <Box m={!isNonMobile ? "1.5rem 1.5rem" : "1.5rem 2.5rem"}>
            <PageHeader
                title={t("accomodations")}
                // subtitle={t("accomodationsList")}
            />
            <Box
                mt="40px"
                minHeight="70vh"
                display="flex"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                        flexGrow: 1,
                        width: "100%",
                    },
                    "& .MuiDataGrid-cell": {
                        // borderBottom: "none",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: `${theme.palette.background.J} !important`,
                        color: theme.palette.secondary[100],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.background.A,
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: `${theme.palette.background.J} !important`,
                        color: theme.palette.secondary[100],
                        borderTop: "none",
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.background.I}`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiBox-root": {
                        display: !isNonMobile ? "flex" : undefined,
                        flexDirection: !isNonMobile ? "column" : undefined,
                    },
                    "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller": {
                        backgroundColor: `${theme.palette.background.A} !important`,
                    },
                }}
            >
                <CustomTable
                    tableRef={tableRef}
                    title={t("accomodationsList")}
                    pageSizeOptions={PAGE_SIZE_OPTIONS}
                    columns={
                        isNonMobile ? columns.current : columnsMobile.current
                    }
                    getData={handleGetAccomodations}
                    style={{
                        width: "100%",
                        padding: "0 10px 0 10px",
                        margin: "0 2% 1% 2%",
                        overflow: !isNonMobile ? "auto" : null,
                    }}
                    actions={[
                        {
                            icon: () => <Add />,
                            tooltip: "Adicionar",
                            isFreeAction: true,
                            onClick: () => {
                                navigate(`${location.pathname}/new`);
                                return Promise.resolve();
                            },
                        },
                        {
                            icon: () => <Edit />,
                            tooltip: "Editar",
                            isFreeAction: false,
                            onClick: (event, rowData) => {
                                navigate(`${location.pathname}/${rowData.id}`);
                                return Promise.resolve();
                            },
                        },
                    ]}
                    onRefresh={() =>
                        tableRef.current && tableRef.current.onQueryChange()
                    }
                />
            </Box>
        </Box>
    );
};

export default AccomodationsList;

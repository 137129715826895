import { apiSlice } from "app/api/apiSlice";

export const clientsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        uploadAttachment: builder.mutation({
            query: (event_ClientUserData) => ({
                url: `S3`,
                method: "POST",
                body: {
                    ...event_ClientUserData,
                },
            }),
        }),
        deleteAttachment: builder.mutation({
            query: (event_ClientUserData) => ({
                url: `S3`,
                method: "DELETE",
                body: {
                    ...event_ClientUserData,
                },
            }),
        }),
    }),
});

export const { useUploadAttachmentMutation, useDeleteAttachmentMutation } =
    clientsApiSlice;

import React, { useCallback, useRef } from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import PageHeader from "components/PageHeader";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomTable from "components/CustomTable";
import { Add, Edit } from "@mui/icons-material";
import { apiSlice } from "app/api/apiSlice";
import { useDispatch } from "react-redux";
import { useGetEmployeeWorkHourCostsQuery } from "features/employeeWorkHourCosts/employeeWorkHourCostsApiSlice";

const PAGE_SIZE_OPTIONS = [10, 20, 50];

const ClientsList = () => {
    const theme = useTheme();
    const navigate = useNavigate(); /* Set the Navigation Service */
    const { t } = useTranslation();
    const location = useLocation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */
    const dispatch = useDispatch();
    // const { data: clients, isLoading } = useGetClientsQuery();

    const clientColumns = useRef([
        { title: "Id", field: "id", hidden: true },
        { title: t("name"), field: "name", filtering: true },
        { title: t("address"), field: "address", filtering: true },
        { title: t("contactName"), field: "contact.name", filtering: true },
        {
            title: t("phoneNumber"),
            field: "contact.phoneNumber",
            filtering: true,
        },
    ]);

    const clientColumnsMobile = useRef([
        { title: "Id", field: "id", hidden: true },
        { title: t("name"), field: "name", filtering: true },
        { title: t("address"), field: "address", filtering: true },
        {
            title: t("contactName"),
            field: "contact.name",
            filtering: true,
            hidden: true,
        },
        {
            title: t("phoneNumber"),
            field: "contact.phoneNumber",
            filtering: true,
            hidden: true,
        },
    ]);

    const tableRef = useRef(null);
    const handleGetClients = useCallback(
        async (query) => {
            const limit = query.pageSize;
            const offset = query.page * query.pageSize;
            const search = query.search || null;
            const filters = query.filters
                ? JSON.stringify(
                      query.filters.map((filter) => ({
                          column: filter.column.field,
                          operator: filter.operator,
                          value: filter.value,
                      }))
                  )
                : null;
            const orderByCollection = query.orderByCollection
                ? JSON.stringify(
                      query.orderByCollection.map((orderBy) => ({
                          column: isNonMobile
                              ? clientColumns.current[orderBy.orderBy].field
                              : clientColumnsMobile.current[orderBy.orderBy]
                                    .field,
                          orderDirection: orderBy.orderDirection,
                      }))
                  )
                : null;

            const response = await dispatch(
                apiSlice.endpoints.getClientsPaginated.initiate({
                    limit: limit || 10,
                    offset: offset || 0,
                    search,
                    filters,
                    orderByCollection,
                })
            ).unwrap();
            return {
                data: response.clients,
                page: query.page,
                totalCount: response.totalClients || 0,
            };
        },
        [isNonMobile ? clientColumns : clientColumnsMobile]
    );

    return (
        <Box m={!isNonMobile ? "1.5rem 1.5rem" : "1.5rem 2.5rem"}>
            {/* {isLoading || !clients ? (
                <LoadingSpinner />
            ) : ( */}
            <>
                <PageHeader
                    title={t("clientsTitle")}
                    // subtitle={t("clientsList")}
                />
                <Box
                    mt="40px"
                    minHeight="70vh"
                    display="flex"
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                            flexGrow: 1,
                            width: "100%",
                        },
                        "& .MuiDataGrid-cell": {
                            // borderBottom: "none",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: `${theme.palette.background.J} !important`,
                            color: theme.palette.secondary[100],
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: theme.palette.background.A,
                        },
                        "& .MuiDataGrid-footerContainer": {
                            backgroundColor: `${theme.palette.background.J} !important`,
                            color: theme.palette.secondary[100],
                            borderTop: "none",
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${theme.palette.background.I}`,
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiBox-root": {
                            display: !isNonMobile ? "flex" : undefined,
                            flexDirection: !isNonMobile ? "column" : undefined,
                        },
                        "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                            {
                                backgroundColor: `${theme.palette.background.A} !important`,
                            },
                    }}
                >
                    <CustomTable
                        tableRef={tableRef}
                        title={t("clientsList")}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        columns={
                            isNonMobile
                                ? clientColumns.current
                                : clientColumnsMobile.current
                        }
                        defaul
                        getData={handleGetClients}
                        style={{
                            width: "100%",
                            padding: "0 10px 0 10px",
                            margin: "0 2% 1% 2%",
                            overflow: !isNonMobile ? "auto" : null,
                        }}
                        actions={[
                            {
                                icon: () => <Add />,
                                tooltip: "Adicionar",
                                isFreeAction: true,
                                onClick: () => {
                                    navigate(`${location.pathname}/new`);
                                    return Promise.resolve();
                                },
                            },
                            {
                                icon: () => <Edit />,
                                tooltip: "Editar",
                                isFreeAction: false,
                                onClick: (event, rowData) => {
                                    navigate(
                                        `${location.pathname}/${rowData.id}`
                                    );
                                    return Promise.resolve();
                                },
                            },
                        ]}
                        onRefresh={() =>
                            tableRef.current && tableRef.current.onQueryChange()
                        }
                    />
                </Box>
            </>
            {/* )} */}
        </Box>
    );
};

export default ClientsList;

import { Box, useTheme, useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";

const BoxDataGrid = ({
    isLoading,
    entity,
    columns,
    columnsMobile,
    CustomToolbar,
}) => {
    const theme = useTheme();
    const isNonMobile = useMediaQuery("(min-width:600px)"); // Define a Media Query

    return (
        <Box
            display="flex"
            sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                    flexGrow: 1,
                    width: "100%",
                },
                "& .MuiDataGrid-cell": {
                    // borderBottom: "none",
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: `${theme.palette.background.J} !important`,
                    color: theme.palette.secondary[100],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: theme.palette.background.A,
                },
                "& .MuiDataGrid-footerContainer": {
                    backgroundColor: `${theme.palette.background.J} !important`,
                    color: theme.palette.secondary[100],
                    borderTop: "none",
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${theme.palette.background.I}`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiBox-root": {
                    display: !isNonMobile ? "flex" : undefined,
                    flexDirection: !isNonMobile ? "column" : undefined,
                },
                "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller": {
                    backgroundColor: `${theme.palette.background.A} !important`,
                },
            }}
        >
            <DataGrid
                autoHeight
                loading={isLoading}
                getRowId={(row) => row.id}
                rows={entity || []}
                columns={isNonMobile ? columns : columnsMobile}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                        },
                    },
                }}
                pageSizeOptions={[5]}
                slots={{
                    toolbar: CustomToolbar,
                }}
                key={JSON.stringify(entity)}
                disableRowSelectionOnClick
            />
        </Box>
    );
};

export default BoxDataGrid;

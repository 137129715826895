import { useState, useRef } from "react";
import { useGetUserTypesQuery, usePostUserMutation } from "./usersApiSlice";
import PageHeader from "components/PageHeader";
import {
    Box,
    TextField,
    Typography,
    useTheme,
    Alert,
    Autocomplete,
    useMediaQuery,
    FormControl,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import { Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";
import usePersist from "hooks/usePersist";

const NewUserForm = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const nameRef = useRef(); /* User Ref to set the focus to the user input */

    const [errMsg, setErrMsg] = useState(""); /* Set the Error Message State */
    const [openSnackbar, setOpenSnackBar] = useState(false);

    const [persist, setPersist, language, setLanguage] = usePersist();

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    };

    const employeeSchema = yup.object().shape({
        name: yup.string().required(t("requiredField")),
        address: yup.string().required(t("requiredField")),
        email: yup
            .string()
            .email(t("invalidEmail"))
            .required(t("requiredField")),
        phoneNumber: yup.string().required(t("requiredField")),
        userTypeId: yup.string().required(t("requiredField")),
        username: yup.string().required(t("requiredField")),
    }); /* Set the employee forms Schema */

    const initialValuesEmployee = {
        name: "",
        address: "",
        email: "",
        phoneNumber: "",
        userTypeId: "",
        username: "",
        photo: "",
    }; /* Set the Forms Initial Values */

    const { data: userTypes, isLoading: isLoadingUserType } =
        useGetUserTypesQuery();

    const [createUser, { isLoading }] = usePostUserMutation();

    const handleFormSubmit = async (values, onSubmitProps) => {
        setErrMsg("");

        try {
            let userToInsert = {
                name: values.name,
                email: values.email,
                address: values.address,
                phoneNumber: values.phoneNumber,
                userTypeId: values.userTypeId,
                username: values.username,
                language: language,
            };

            const { codeResult } = await createUser(userToInsert).unwrap();

            if (codeResult !== RESPONSES.user.successToInsertUser) {
                if (codeResult === RESPONSES.user.invalidUser) {
                    setErrMsg(t("invalidUser"));
                } else {
                    setErrMsg(t("errorInsertUser"));
                }
            } else {
                setOpenSnackBar(true);
                onSubmitProps.resetForm();
            }
        } catch (err) {
            setErrMsg(t("errorInsertUser"));
        }
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesEmployee}
            validationSchema={employeeSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        m={!isNonMobile ? "0" : "1.5rem 2.5rem"}
                        display="grid"
                        gridTemplateRows="repeat(3, auto)"
                        gap="20px"
                        borderRadius="5px"
                        p="2rem"
                    >
                        <PageHeader title={t("newUserTitle")} subtitle="" />
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("userInformation")}
                            </Typography>
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <TextField
                                    autoFocus={!isNonMobile ? false : true}
                                    variant="outlined"
                                    label={t("name")}
                                    inputRef={nameRef}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    name="name"
                                    error={
                                        Boolean(touched.name) &&
                                        Boolean(errors.name)
                                    }
                                    helperText={touched.name && errors.name}
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("email")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                    name="email"
                                    error={
                                        Boolean(touched.email) &&
                                        Boolean(errors.email)
                                    }
                                    helperText={touched.email && errors.email}
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("address")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.address}
                                    name="address"
                                    error={
                                        Boolean(touched.address) &&
                                        Boolean(errors.address)
                                    }
                                    helperText={
                                        touched.address && errors.address
                                    }
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("phoneNumber")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                    name="phoneNumber"
                                    error={
                                        Boolean(touched.phoneNumber) &&
                                        Boolean(errors.phoneNumber)
                                    }
                                    helperText={
                                        touched.phoneNumber &&
                                        errors.phoneNumber
                                    }
                                    sx={{ width: "100%" }}
                                />
                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingUserType}
                                    options={userTypes || []}
                                    getOptionLabel={(option) => option.type}
                                    value={
                                        (userTypes &&
                                            userTypes.find(
                                                (user) =>
                                                    user.id ===
                                                    values.userTypeId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "userTypeId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                    }}
                                    onBlur={handleBlur("userTypeId")}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("userType")}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.userTypeId
                                                    ) &&
                                                    Boolean(errors.userTypeId)
                                                }
                                                helperText={
                                                    touched.userTypeId &&
                                                    errors.userTypeId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />

                                <TextField
                                    label={t("username")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.username}
                                    name="username"
                                    error={
                                        Boolean(touched.username) &&
                                        Boolean(errors.username)
                                    }
                                    helperText={
                                        touched.username && errors.username
                                    }
                                    sx={{ width: "100%" }}
                                />
                            </Box>
                        </Box>
                        {errMsg && (
                            <Alert
                                severity="error"
                                sx={{
                                    mt: "10px",
                                    backgroundColor: "#540e0e",
                                    color: "#FFFFFF",
                                    alignItems: "center",
                                }}
                            >
                                {errMsg}
                            </Alert>
                        )}
                        {/* BUTTONS */}
                        <LoadingButton
                            fullWidth
                            type="submit"
                            loading={isLoading}
                            sx={{
                                width: "100%",
                                p: "1rem",
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.neutral.white,
                                "&:hover": {
                                    backgroundColor:
                                        theme.palette.primary.light,
                                },
                            }}
                        >
                            {t("addNewUser")}
                        </LoadingButton>
                    </Box>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity="success"
                            sx={{
                                mt: "10px",
                                backgroundColor: "#10781F",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {t("successToInsertUser")}
                        </Alert>
                    </Snackbar>
                </form>
            )}
        </Formik>
    );
};

export default NewUserForm;

import React from "react";
import { useTheme } from "@mui/material";
import {
    GridToolbarDensitySelector,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import FlexBetween from "./FlexBetween";

const DataGridCustomWithoutToolbar = () => {
    const { palette } = useTheme(); /* Set the Theme Pallet */

    return (
        <GridToolbarContainer
            sx={{
                "& .MuiButtonBase-root:hover": {
                    backgroundColor: `${palette.primary.light} !important`,
                },
            }}
        >
            <FlexBetween width="100%" padding="0.5rem 0">
                <FlexBetween>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                    <GridToolbarExport />
                </FlexBetween>
            </FlexBetween>
        </GridToolbarContainer>
    );
};

export default DataGridCustomWithoutToolbar;

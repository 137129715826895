import { useState, useRef } from "react";
import { usePostEmployeeCategoryMutation } from "./employeeCategoriesApiSlice";
import PageHeader from "components/PageHeader";
import {
    Box,
    TextField,
    Typography,
    useTheme,
    Alert,
    useMediaQuery,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import { Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";

const NewEmployeeCategoryForm = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const employeeCategorySchema = yup.object().shape({
        name: yup.string().required(t("requiredField")),
    }); /* Set the Login Schema */

    const initialValuesEmployeeCategory = {
        name: "",
        description: "",
    }; /* Set the Form Initial Values */

    const [createEmployeeCategory, { isLoading }] =
        usePostEmployeeCategoryMutation(); /* Set the Login Mutation */

    const nameRef = useRef(); /* User Ref to set the focus to the user input */

    const [errMsg, setErrMsg] = useState(""); /* Set the Error Message State */
    const [openSnackbar, setOpenSnackBar] = useState(false);

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    };

    const handleFormSubmit = async (values, onSubmitProps) => {
        setErrMsg("");

        try {
            const { codeResult } = await createEmployeeCategory(
                values
            ).unwrap();

            if (
                codeResult !==
                RESPONSES.employeeCategory.successToInsertEmployeeCategory
            ) {
                if (
                    codeResult ===
                    RESPONSES.employeeCategory.invalidEmployeeCategory
                ) {
                    setErrMsg(t("invalidEmployeeCategory"));
                } else {
                    setErrMsg(t("errorInsertEmployeeCategory"));
                }
            } else {
                setOpenSnackBar(true);
                onSubmitProps.resetForm();
            }
        } catch (err) {
            setErrMsg(t("errorInsertEmployeeCategory"));
        }
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesEmployeeCategory}
            validationSchema={employeeCategorySchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        m={!isNonMobile ? "0" : "1.5rem 2.5rem"}
                        display="grid"
                        gridTemplateRows="repeat(3, auto)"
                        gap="20px"
                        borderRadius="5px"
                        p="2rem"
                    >
                        <PageHeader
                            title={t("newEmployeeCategoryTitle")}
                            subtitle=""
                        />
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("employeeCategoryInformation")}
                            </Typography>
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <TextField
                                    autoFocus={!isNonMobile ? false : true}
                                    variant="outlined"
                                    label={t("name")}
                                    inputRef={nameRef}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    name="name"
                                    error={
                                        Boolean(touched.name) &&
                                        Boolean(errors.name)
                                    }
                                    helperText={touched.name && errors.name}
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    variant="outlined"
                                    label={t("description")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.description}
                                    name="description"
                                    error={
                                        Boolean(touched.description) &&
                                        Boolean(errors.description)
                                    }
                                    helperText={
                                        touched.description &&
                                        errors.description
                                    }
                                    sx={{ width: "100%" }}
                                    multiline
                                />
                            </Box>
                        </Box>
                        {errMsg && (
                            <Alert
                                severity="error"
                                sx={{
                                    mt: "10px",
                                    backgroundColor: "#540e0e",
                                    alignItems: "center",
                                    color: "#FFFFFF",
                                }}
                            >
                                {errMsg}
                            </Alert>
                        )}
                        {/* BUTTONS */}
                        <LoadingButton
                            fullWidth
                            type="submit"
                            loading={isLoading}
                            sx={{
                                width: "100%",
                                p: "1rem",
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.neutral.white,
                                "&:hover": {
                                    backgroundColor:
                                        theme.palette.primary.light,
                                },
                            }}
                        >
                            {t("newEmployeeCategoryTitle")}
                        </LoadingButton>
                    </Box>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity="success"
                            sx={{
                                mt: "10px",
                                backgroundColor: "#10781F",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {t("successToInsertEmployeeCategory")}
                        </Alert>
                    </Snackbar>
                </form>
            )}
        </Formik>
    );
};

export default NewEmployeeCategoryForm;

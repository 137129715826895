import { useState, useRef } from "react";
import { useGetClientsQuery } from "features/clients/clientsApiSlice";
import { useGetWorkTypesQuery, usePostWorkMutation } from "./worksApiSlice";
import PageHeader from "components/PageHeader";
import "dayjs/locale/pt";
import {
    Box,
    TextField,
    Autocomplete,
    Typography,
    useTheme,
    FormControlLabel,
    Checkbox,
    Alert,
    useMediaQuery,
    FormControl,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import { Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { RESPONSES } from "config/responses";
import { useLocation, useNavigate } from "react-router-dom";
import { SETTINGS } from "helpers/settings";

const NewWorkForm = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const nameRef = useRef(); /* User Ref to set the focus to the user input */

    const [errMsg, setErrMsg] = useState(""); /* Set the Error Message State */
    const [openSnackbar, setOpenSnackBar] = useState(false);

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    };

    const [dayAndNight, setDayAndNight] = useState(false);

    const setDayAndNightState = () => {
        if (dayAndNight) {
            setDayAndNight(false);
        } else {
            setDayAndNight(true);
        }
    };

    const workSchema = yup.object().shape({
        name: yup.string().required(t("requiredField")),
        address: yup.string().required(t("requiredField")),
        clientId: yup.string().required(t("requiredField")),
        workTypeId: yup.string().required(t("requiredField")),
        companyName: yup.string().notRequired(),
        budget: yup.number(t("invalidValue")).typeError(t("invalidValue")),
        startDate: yup.date().notRequired(),
        endDate: yup.date().notRequired(),
    });

    const initialValuesWork = {
        name: "",
        address: "",
        clientId: "",
        workTypeId: "",
        companyName: "",
        budget: "",
        startDate: null,
        endDate: null,
    }; /* Set the Forms Initial Values */

    const { data: clients, isLoading: isLoadingClients } = useGetClientsQuery();

    const { data: workTypes, isLoading: isLoadingWorkTypes } =
        useGetWorkTypesQuery();

    const [createWork, { isLoading }] = usePostWorkMutation();

    const handleFormSubmit = async (values, onSubmitProps) => {
        setErrMsg("");
        try {
            if (
                values.startDate !== null &&
                values.endDate !== null &&
                dayjs(values.endDate).isBefore(dayjs(values.startDate))
            ) {
                setErrMsg(t("invalidToInsertOrUpdateWorkBecauseInvalidDates"));
            } else {
                let workToInsert = {
                    ...values,
                    DayAndNight: dayAndNight,
                };

                if (workToInsert.budget === "") {
                    workToInsert.budget = null;
                }

                const { codeResult, workId } = await createWork(
                    workToInsert
                ).unwrap();
                if (codeResult !== RESPONSES.work.sucessToInsertWork) {
                    if (codeResult === RESPONSES.work.invalidWork) {
                        setErrMsg(t("invalidWork"));
                    } else {
                        setErrMsg(t("errorInsertWork"));
                    }
                } else {
                    setOpenSnackBar(true);
                    onSubmitProps.resetForm();
                    navigate(`${location.pathname.replace("new", workId)}`);
                }
            }
        } catch (err) {
            setErrMsg(t("errorInsertWork"));
        }
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesWork}
            validationSchema={workSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        m={!isNonMobile ? "0" : "1.5rem 2.5rem"}
                        display="grid"
                        gridTemplateRows="repeat(3, auto)"
                        gap="20px"
                        borderRadius="5px"
                        p="2rem"
                    >
                        <PageHeader title={t("newWorkTitle")} subtitle="" />
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Box display="flex" justifyContent="space-between">
                                <Typography
                                    fontWeight="500"
                                    fontSize="20px"
                                    color="primary"
                                    variant="h5"
                                    textAlign="left"
                                    alignSelf="center"
                                >
                                    {t("workInformation")}
                                </Typography>
                                <Box>
                                    <FormControlLabel
                                        sx={{ marginLeft: "5px" }}
                                        control={
                                            <Checkbox
                                                checked={dayAndNight}
                                                onChange={setDayAndNightState}
                                            />
                                        }
                                        label={t("dayAndNight")}
                                    />
                                </Box>
                            </Box>
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <TextField
                                    autoFocus={!isNonMobile ? false : true}
                                    variant="outlined"
                                    label={t("name")}
                                    inputRef={nameRef}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    name="name"
                                    error={
                                        Boolean(touched.name) &&
                                        Boolean(errors.name)
                                    }
                                    helperText={touched.name && errors.name}
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("address")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.address}
                                    name="address"
                                    error={
                                        Boolean(touched.address) &&
                                        Boolean(errors.address)
                                    }
                                    helperText={
                                        touched.address && errors.address
                                    }
                                    sx={{ width: "100%" }}
                                />
                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingWorkTypes}
                                    options={workTypes || []}
                                    getOptionLabel={(option) => option.type}
                                    value={
                                        (workTypes &&
                                            workTypes.find(
                                                (workType) =>
                                                    workType.id ===
                                                    values.workTypeId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "workTypeId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                    }}
                                    onBlur={handleBlur("workTypeId")}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("workType")}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.workTypeId
                                                    ) &&
                                                    Boolean(errors.workTypeId)
                                                }
                                                helperText={
                                                    touched.workTypeId &&
                                                    errors.workTypeId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />
                                {values.workTypeId ===
                                    SETTINGS.WorkType.Company && (
                                    <TextField
                                        label={t("companyName")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.companyName}
                                        name="companyName"
                                        error={
                                            Boolean(touched.companyName) &&
                                            Boolean(errors.companyName)
                                        }
                                        helperText={
                                            touched.companyName &&
                                            errors.companyName
                                        }
                                        sx={{ width: "100%" }}
                                    />
                                )}
                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingClients}
                                    options={clients || []}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                        (clients &&
                                            clients.find(
                                                (client) =>
                                                    client.id ===
                                                    values.clientId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "clientId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                    }}
                                    onBlur={handleBlur("clientId")}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("client")}
                                                {...params}
                                                error={
                                                    Boolean(touched.clientId) &&
                                                    Boolean(errors.clientId)
                                                }
                                                helperText={
                                                    touched.clientId &&
                                                    errors.clientId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />
                                {values.workTypeId ===
                                    SETTINGS.WorkType.Private && (
                                    <TextField
                                        label={t("budget")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.budget}
                                        name="budget"
                                        error={
                                            Boolean(touched.budget) &&
                                            Boolean(errors.budget)
                                        }
                                        InputProps={{
                                            endAdornment: "€",
                                        }}
                                        helperText={
                                            touched.budget && errors.budget
                                        }
                                        sx={{ width: "100%" }}
                                    />
                                )}
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="pt"
                                >
                                    <DatePicker
                                        label={t("startDate")}
                                        value={values.startDate}
                                        format="DD/MM/YYYY"
                                        onChange={(value) =>
                                            setFieldValue("startDate", value)
                                        }
                                    />
                                    <DatePicker
                                        label={t("endDate")}
                                        value={values.endDate}
                                        format="DD/MM/YYYY"
                                        onChange={(value) =>
                                            setFieldValue("endDate", value)
                                        }
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Box>
                        {errMsg && (
                            <Alert
                                severity="error"
                                sx={{
                                    mt: "10px",
                                    backgroundColor: "#540e0e",
                                    color: "#FFFFFF",
                                    alignItems: "center",
                                }}
                            >
                                {errMsg}
                            </Alert>
                        )}
                        {/* BUTTONS */}
                        <LoadingButton
                            fullWidth
                            type="submit"
                            loading={isLoading}
                            sx={{
                                width: "100%",
                                p: "1rem",
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.neutral.white,
                                "&:hover": {
                                    backgroundColor:
                                        theme.palette.primary.light,
                                },
                            }}
                        >
                            {t("addWork")}
                        </LoadingButton>
                    </Box>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity="success"
                            sx={{
                                mt: "10px",
                                backgroundColor: "#10781F",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {t("sucessToInsertWork")}
                        </Alert>
                    </Snackbar>
                </form>
            )}
        </Formik>
    );
};

export default NewWorkForm;

import { useState, useEffect } from "react";
import {
    useGetEmployeeWorkHourCostsByIdQuery,
    useGetWorksByEmployeeToProcessWorkHourCostsQuery,
    useAddWorkHourCostMutation,
    useDeleteEmployeeWorkHourCostsMutation,
    useDeleteWorkHourCostMutation,
    useUpdateWorkHourCostMutation,
    useUpdateEmployeeWorkHourCostsMutation,
    useAddWorkHours_Employee_FixedSalaryMutation,
    useUpdateWorkHours_Employee_FixedSalaryMutation,
    useDeleteWorkHours_Employee_FixedSalaryByIdMutation,
    useLazyGetEmployeePlanningByWorkPlanningIdAndEmployeeIdQuery,
} from "./employeeWorkHourCostsApiSlice";
import { SETTINGS } from "helpers/settings";
import PageHeader from "components/PageHeader";
import LoadingSpinner from "components/LoadingSpinner";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGrid } from "@mui/x-data-grid";
import {
    Box,
    TextField,
    Typography,
    Button,
    useTheme,
    Alert,
    useMediaQuery,
    FormControl,
    FormControlLabel,
    Checkbox,
    Autocomplete,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import { Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DeleteIcon from "@mui/icons-material/Delete";

const EditEmployeeWorkHourCostsForm = () => {
    // #region Setups

    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate(); /* Set the Navigation Service */
    const location = useLocation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMsg(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    //#endregion Setups

    const { id } = useParams();
    const {
        data: employeeWorkHourCosts,
        isLoading: isLoadingGetEmployeeWorkHourCosts,
    } = useGetEmployeeWorkHourCostsByIdQuery(id);

    const employeeWorkHourCostsSchema = yup.object().shape({
        employeeId: yup.string().required(t("requiredField")),
        mealAllowance: yup
            .number(t("invalidValue"))
            .min(0, t("invalidValue"))
            .typeError(t("invalidValue")),
        observations: yup.string().notRequired(),
    }); /* Set the employeeWorkHourCosts Schema */

    const initialValuesEmployeeWorkHourCosts = {
        employee: {
            name: "",
        },
        employeeId: "",
        currentDate: "",
        advancePaymentValue: "",
        advancePaymentSumByMoney: "",
        advancePaymentSumByCard: "",
        adjustmentSum: "",
        salary: "",
        isSickLeave: false,
        notWorking: false,
        mealAllowance: "",
        observations: "",
    }; /* Set the Form Initial Values */

    //REGION SALARIOS

    const [openCreateWorkHourCostDialog, setOpenCreateWorkHourCostDialog] =
        useState(false);

    const handleOpenCreateWorkHourCostDialog = () => {
        setOpenCreateWorkHourCostDialog(true);
    };

    const handleCloseCreateWorkHourCostDialog = () => {
        setOpenCreateWorkHourCostDialog(false);
    };

    const [
        openCreateWorkHour_Employee_FixedSalaryDialog,
        setOpenCreateWorkHour_Employee_FixedSalaryDialog,
    ] = useState(false);

    const handleOpenCreateWorkHour_Employee_FixedSalaryDialog = () => {
        if (workHourCosts && workHourCosts.length > 0) {
            setOpenCreateWorkHour_Employee_FixedSalaryDialog(true);
        } else {
            handleSnackbarOpen(t("isNecessaryToAddDetailsFirst"), "error");
        }
    };

    const handleCloseCreateWorkHour_Employee_FixedSalaryDialog = () => {
        setOpenCreateWorkHour_Employee_FixedSalaryDialog(false);
    };

    const [
        openEditWorkHour_Employee_FixedSalaryDialog,
        setOpenEditWorkHour_Employee_FixedSalaryDialog,
    ] = useState(false);

    const handleOpenEditWorkHour_Employee_FixedSalaryDialog = () => {
        setOpenEditWorkHour_Employee_FixedSalaryDialog(true);
    };

    const handleCloseEditWorkHour_Employee_FixedSalaryDialog = () => {
        setOpenEditWorkHour_Employee_FixedSalaryDialog(false);
    };

    const [openEditWorkHourCostDialog, setOpenEditWorkHourCostDialog] =
        useState(false);

    const handleOpenEditWorkHourCostDialog = () => {
        setOpenEditWorkHourCostDialog(true);
    };

    const handleCloseEditWorkHourCostDialog = () => {
        setOpenEditWorkHourCostDialog(false);
    };

    const [openDeleteWorkHourCostDialog, setOpenDeleteWorkHourCostDialog] =
        useState(false);

    const handleOpenDeleteWorkHourCostDialog = () => {
        setOpenDeleteWorkHourCostDialog(true);
    };

    const handleCloseDeleteWorkHourCostDialog = () => {
        setOpenDeleteWorkHourCostDialog(false);
    };

    const [
        openDeleteWorkHour_Employee_FixedSalaryDialog,
        setOpenDeleteWorkHour_Employee_FixedSalaryDialog,
    ] = useState(false);

    const handleOpenDeleteWorkHour_Employee_FixedSalaryDialog = () => {
        setOpenDeleteWorkHour_Employee_FixedSalaryDialog(true);
    };

    const handleCloseDeleteWorkHour_Employee_FixedSalaryDialog = () => {
        setOpenDeleteWorkHour_Employee_FixedSalaryDialog(false);
    };

    const [
        openDeleteEmployeeWorkHourCostsDialog,
        setOpenDeleteEmployeeWorkHourCostsDialog,
    ] = useState(false);

    const handleOpenDeleteEmployeeWorkHourCostsDialog = () => {
        setOpenDeleteEmployeeWorkHourCostsDialog(true);
    };

    const handleCloseDeleteEmployeeWorkHourCostsDialog = () => {
        setOpenDeleteEmployeeWorkHourCostsDialog(false);
    };

    const [employees, setEmployees] = useState([]);
    const [workHourCosts, setWorkHourCosts] = useState([]);
    const [isFixedSalary, setIsFixedSalary] = useState(false);
    const [isOfficeEmployee, setIsOfficeEmployee] = useState(false);
    const [workHours_Employee_FixedSalary, setWorkHours_Employee_FixedSalary] =
        useState([]);
    //vamos buscar o custo ao funcionário
    const [employeeCost, setEmployeeCost] = useState("");
    const [isHistoric, setIsHistoric] = useState(false);
    const [employeeId, setEmployeeId] = useState(0);

    const {
        data: employeePlannings,
        isLoading: isLoadingEmployeePlanning,
        refetch: reftchEmployeePlannings,
    } = useGetWorksByEmployeeToProcessWorkHourCostsQuery(employeeId);

    useEffect(() => {
        if (employeeId !== 0) {
            reftchEmployeePlannings();
        }
    }, [employeeId, reftchEmployeePlannings]);

    const [
        getEmployeePlanningByWorkPlanningIdAndEmployeeIdQuery,
        employeePlanningLazy,
    ] = useLazyGetEmployeePlanningByWorkPlanningIdAndEmployeeIdQuery();

    const [hourCostEmployeePlanning, setHourCostEmployeePlanning] =
        useState("");
    useEffect(() => {
        if (
            employeePlanningLazy.data &&
            employeePlanningLazy.data.hourCost !== null
        ) {
            setHourCostEmployeePlanning(employeePlanningLazy.data.hourCost);
        }
    }, [employeePlanningLazy.data]);

    const getEmployeePlanningByWorkPlanningIdAndEmployeeIdOnWorkPlanningChange =
        async (workPlanningId, employeeId) => {
            getEmployeePlanningByWorkPlanningIdAndEmployeeIdQuery({
                workPlanningId: workPlanningId,
                employeeId: employeeId,
            });
        };

    useEffect(() => {
        if (employeeWorkHourCosts) {
            setIsHistoric(employeeWorkHourCosts.isDeleted);
            setWorkHourCosts(employeeWorkHourCosts.workHourCosts);

            setEmployeeId(employeeWorkHourCosts.employeeId);
            const employees = [];
            employees.push(employeeWorkHourCosts.employee);
            setEmployees(employees);

            setIsOfficeEmployee(
                employeeWorkHourCosts.employee.isOfficeEmployee
            );
            //vamos verificar se o salario é fixo ou nao, se for fixo não mostramos o campo "horas"
            if (
                employeeWorkHourCosts.employee.salaryType.id ===
                SETTINGS.SalaryType.Fixed
            ) {
                setIsFixedSalary(true);
            }

            if (
                employeeWorkHourCosts.employee.salaryType.id ===
                    SETTINGS.SalaryType.Fixed &&
                employeeWorkHourCosts.employee.isOfficeEmployee === false &&
                employeeWorkHourCosts.workHourCosts.length > 0
            ) {
                setWorkHours_Employee_FixedSalary(
                    employeeWorkHourCosts.workHourCosts[0]
                        .workHours_Employee_FixedSalaries
                );
            } else if (employeeWorkHourCosts.workHourCosts.length === 0) {
                setWorkHours_Employee_FixedSalary([]);
            }

            setEmployeeCost(employeeWorkHourCosts.employee.cost);
        }
    }, [employeeWorkHourCosts]);

    const workHourCostsColumns = [
        {
            field: "name",
            headerName: t("planningWork"),
            flex: 1,
            valueGetter: (params) =>
                params.row.workPlanning.name +
                " - " +
                params.row.workPlanning.work.name,
        },
        {
            field: "numberOfWorkHours",
            headerName: t("numberOfHours"),
            flex: 1,
            valueGetter: (params) => params.row.numberOfWorkHours,
        },
        {
            field: "cost",
            headerName: t("hourSalary"),
            flex: 1,
            valueGetter: (params) => params.row.cost,
            valueFormatter: (params) => `${params.value} €`, // Adiciona "€" após o valor
        },
        {
            field: "action",
            headerName: t("edit"),
            flex: 1,
            renderCell: (row) => (
                <EditIcon onClick={() => handleEditWorkHourCost(row)} />
            ),
        },
        {
            field: "actions",
            headerName: t("remove"),
            flex: 1,
            renderCell: (row) => (
                <DeleteIcon
                    onClick={() => handleDeleteWorkHourCostOpenDialog(row.id)}
                />
            ),
        },
    ];

    const workHourCostsColumnsToFixedSalaryAndNotOfficeEmployee = [
        {
            field: "name",
            headerName: t("planningWork"),
            flex: 1,
            valueGetter: (params) =>
                params.row.workPlanning.name +
                " - " +
                params.row.workPlanning.work.name,
        },
        {
            field: "numberOfWorkHours",
            headerName: t("numberOfHours"),
            flex: 1,
            valueGetter: (params) => params.row.numberOfWorkHours,
        },

        {
            field: "action",
            headerName: t("edit"),
            flex: 1,
            renderCell: (row) => (
                <EditIcon
                    onClick={() => handleEditWorkHour_Employee_FixedSalary(row)}
                />
            ),
        },
        {
            field: "actions",
            headerName: t("remove"),
            flex: 1,
            renderCell: (row) => (
                <DeleteIcon
                    onClick={() =>
                        handleDeleteWorkHours_Employee_FixedSalaryOpenDialog(
                            row.id
                        )
                    }
                />
            ),
        },
    ];

    const workHourCostsColumnsToFixedSalary = [
        {
            field: "cost",
            headerName: t("fixedSalary"),
            flex: 1,
            valueGetter: (params) => params.row.cost,
            valueFormatter: (params) => `${params.value} €`, // Adiciona "€" após o valor
        },
        {
            field: "action",
            headerName: t("edit"),
            flex: 1,
            renderCell: (row) => (
                <EditIcon onClick={() => handleEditWorkHourCost(row)} />
            ),
        },
        {
            field: "actions",
            headerName: t("remove"),
            flex: 1,
            renderCell: (row) => (
                <DeleteIcon
                    onClick={() => handleDeleteWorkHourCostOpenDialog(row.id)}
                />
            ),
        },
    ];

    const workHourCostSchema = yup.object().shape({
        workPlanningId: yup.string(),
        numberOfWorkHours: yup
            .number(t("invalidValue"))
            .positive(t("invalidValue"))
            .typeError(t("invalidValue")),
        absencesTotal: yup
            .number(t("invalidValue"))
            .positive(t("invalidValue"))
            .typeError(t("invalidValue"))
            .notRequired(),
        freeDays: yup
            .number(t("invalidValue"))
            .positive(t("invalidValue"))
            .typeError(t("invalidValue"))
            .notRequired(),
        cost: yup
            .number(t("invalidValue"))
            .positive(t("invalidValue"))
            .required(t("requiredField"))
            .typeError(t("invalidValue")),
    }); /* Set the Work Hour Cost forms Schema */

    const workHour_Employee_FixedSalarySchema = yup.object().shape({
        workPlanningId: yup.string().required("Campo obrigatório"),
        numberOfWorkHours: yup
            .number(t("invalidValue"))
            .positive(t("invalidValue"))
            .required(t("requiredField"))
            .typeError(t("invalidValue")),
    }); /* Set the Work Hour Cost forms Schema */

    const initialValuesworkHour_Employee_FixedSalary = {
        workPlanningId: "",
        numberOfWorkHours: "",
    }; /* Set the Forms Initial Values */

    const initialValuesWorkHourCostSchema = {
        workPlanningId: "",
        numberOfWorkHours: "",
        absencesTotal: "",
        freeDays: "",
        cost: employeeCost,
    }; /* Set the Forms Initial Values */

    const [workPlannings, setWorkPlannings] = useState([]);

    useEffect(() => {
        if (employeePlannings) {
            const workList = [];
            employeePlannings.forEach((employeePlanning) => {
                workList.push(employeePlanning.workPlanning);
            });
            setWorkPlannings(workList);
        }
    }, [employeePlannings]);
    // ADD WORK HOUR COST

    const [addWorkHourCost, { isLoading: isLoadingAddWorkHourCost }] =
        useAddWorkHourCostMutation();

    const [
        addWorkHour_Employee_FixedSalary,
        { isLoading: isLoadingAddWorkHour_Employee_FixedSalary },
    ] = useAddWorkHours_Employee_FixedSalaryMutation();

    const handleFormAddWorkHour_Employee_FixedSalarySubmit = async (
        values,
        onSubmitProps
    ) => {
        try {
            let addWorkHour_Employee_FixedSalaryToAdd = {
                EmployeeWorkHourCostId: id.split("-")[0],
                WorkPlanningId: values.workPlanningId,
                NumberOfWorkHours: values.numberOfWorkHours,
            };

            const { codeResult } = await addWorkHour_Employee_FixedSalary(
                addWorkHour_Employee_FixedSalaryToAdd
            ).unwrap();
            if (
                codeResult !==
                RESPONSES.employeeWorkHourCosts
                    .sucessToAddWorkHour_Employee_FixedSalaryToWorkHourCost
            ) {
                handleSnackbarOpen(t("errorToAddHours"), "error");
            } else {
                setOpenCreateWorkHour_Employee_FixedSalaryDialog(false);
                handleSnackbarOpen(t("hoursAddedWithSucess"), "success");
            }
        } catch (err) {
            handleSnackbarOpen(t("errorToAddHours"), "error");
        }
    };

    const handleFormAddWorkHourCostSubmit = async (values, onSubmitProps) => {
        try {
            let workHourCostToAdd = null;
            if (isFixedSalary === false) {
                workHourCostToAdd = {
                    WorkPlanningId: values.workPlanningId,
                    EmployeeWorkHourCostId: id.split("-")[0],
                    NumberOfWorkHours: values.numberOfWorkHours,
                    Cost: hourCostEmployeePlanning
                        ? hourCostEmployeePlanning
                        : values.cost,
                };
            } else {
                workHourCostToAdd = {
                    EmployeeWorkHourCostId: id.split("-")[0],
                    Cost: values.cost,
                    AbsencesTotal:
                        values.absencesTotal !== ""
                            ? values.absencesTotal
                            : null,
                    FreeDays: values.freeDays !== "" ? values.freeDays : null,
                };
            }
            const { codeResult } = await addWorkHourCost(
                workHourCostToAdd
            ).unwrap();

            if (
                codeResult !==
                RESPONSES.employeeWorkHourCosts.sucessToAddWorkHourCosts
            ) {
                if (
                    codeResult ===
                    RESPONSES.employeeWorkHourCosts.invalidToAddWorkHourCosts
                ) {
                    handleSnackbarOpen(t("invalidToAddWorkHourCosts"), "error");
                } else {
                    handleSnackbarOpen(t("erroToAddWorkHourCosts"), "error");
                }
            } else {
                setOpenCreateWorkHourCostDialog(false);
                handleSnackbarOpen(t("sucessToAddWorkHourCosts"), "success");
            }
        } catch (err) {
            handleSnackbarOpen(t("erroToAddWorkHourCosts"), "error");
        }
    };

    // EDIT WORK HOUR COST

    const [workHourCostToEdit, setWorkHourCostToEdit] = useState(null);

    const handleEditWorkHourCost = async (row) => {
        let workHourCostToUpdate = null;
        if (isFixedSalary === false) {
            workHourCostToUpdate = {
                id: row.row.id,
                workPlanningId: row.row.workPlanningId,
                employeeWorkHourCostId: id,
                numberOfWorkHours: row.row.numberOfWorkHours,
                cost: hourCostEmployeePlanning
                    ? hourCostEmployeePlanning
                    : row.row.cost,
            };
        } else {
            workHourCostToUpdate = {
                id: row.row.id,
                employeeWorkHourCostId: id,
                cost: row.row.cost,
                absencesTotal:
                    row.row.absencesTotal === null ? "" : row.row.absencesTotal,
                freeDays: row.row.freeDays === null ? "" : row.row.freeDays,
            };
        }
        const updatedWorkPlannings = [...workPlannings];
        if (row.row.workPlanning !== null) {
            updatedWorkPlannings.push(row.row.workPlanning);
        }

        setWorkPlannings(updatedWorkPlannings);

        setWorkHourCostToEdit(workHourCostToUpdate);
        setOpenEditWorkHourCostDialog(true);
    };

    const [
        workHour_Employee_FixedSalaryToEdit,
        setWorkHour_Employee_FixedSalaryToEdit,
    ] = useState(null);

    const [editWorkPlannigns, setEditWorkPlanning] = useState([]);

    const handleEditWorkHour_Employee_FixedSalary = async (row) => {
        let workHour_Employee_FixedSalaryToUpdate = {
            id: row.row.id,
            workPlanningId: row.row.workPlanningId,
            numberOfWorkHours: row.row.numberOfWorkHours,
        };

        const updatedWorkPlannings = [...workPlannings];

        updatedWorkPlannings.push(row.row.workPlanning);

        setEditWorkPlanning(updatedWorkPlannings);

        setWorkHour_Employee_FixedSalaryToEdit(
            workHour_Employee_FixedSalaryToUpdate
        );
        // setOpenEditWorkHour_Employee_FixedSalaryDialog(true);
    };

    useEffect(() => {
        if (workHour_Employee_FixedSalaryToEdit) {
            setOpenEditWorkHour_Employee_FixedSalaryDialog(true);
        }
    }, [workHour_Employee_FixedSalaryToEdit]);

    const [
        editWorkHours_EmployeeFixedSalary,
        { isLoading: isLoadingEditWorkHours_EmployeeFixedSalary },
    ] = useUpdateWorkHours_Employee_FixedSalaryMutation();

    const handleFormEditWorkHours_EmployeeFixedSalarySubmit = async (
        values,
        onSubmitProps
    ) => {
        try {
            let workHours_EmployeeFixedSalary = {
                id: values.id,
                workPlanningId: values.workPlanningId,
                numberOfWorkHours: values.numberOfWorkHours,
                employeeWorkHourCostId: id.split("-")[0],
            };

            const { codeResult } = await editWorkHours_EmployeeFixedSalary(
                workHours_EmployeeFixedSalary
            ).unwrap();

            if (
                codeResult !==
                RESPONSES.workHours_Employee_FixedSalary
                    .sucessToUpdateWorkHours_Employee_FixedSalary
            ) {
                handleSnackbarOpen(t("errorToEditHours"), "error");
            } else {
                // refetchEmployeeWorkHourCosts();
                setOpenEditWorkHour_Employee_FixedSalaryDialog(false);
                handleSnackbarOpen(t("sucessToEditHours"), "success");
            }
        } catch (err) {
            handleSnackbarOpen(t("errorToEditHours"), "error");
        }
    };

    const [editWorkHourCost, { isLoading: isLoadingEditWorkHourCost }] =
        useUpdateWorkHourCostMutation();

    const handleFormEditWorkHourCostSubmit = async (values, onSubmitProps) => {
        try {
            let workHourCostToAdd = null;
            if (isFixedSalary === false) {
                workHourCostToAdd = {
                    id: values.id,
                    WorkPlanningId: values.workPlanningId,
                    EmployeeWorkHourCostId: id.split("-")[0],
                    NumberOfWorkHours: values.numberOfWorkHours,
                    Cost: values.cost,
                };
            } else {
                workHourCostToAdd = {
                    id: values.id,
                    EmployeeWorkHourCostId: id.split("-")[0],
                    Cost: values.cost,
                    AbsencesTotal:
                        values.absencesTotal !== ""
                            ? values.absencesTotal
                            : null,
                    FreeDays: values.freeDays !== "" ? values.freeDays : null,
                };
            }
            const { codeResult } = await editWorkHourCost(
                workHourCostToAdd
            ).unwrap();

            if (
                codeResult !== RESPONSES.workHourCost.sucessToUpdateWorkHourCost
            ) {
                if (
                    codeResult ===
                    RESPONSES.workHourCost.invalidToUpdateWorkHourCost
                ) {
                    handleSnackbarOpen(
                        t("invalidToUpdateWorkHourCost"),
                        "error"
                    );
                } else {
                    handleSnackbarOpen(t("erroToUpdateWorkHourCost"), "error");
                }
            } else {
                setOpenEditWorkHourCostDialog(false);
                handleSnackbarOpen(t("sucessToUpdateWorkHourCost"), "success");
            }
        } catch (err) {
            handleSnackbarOpen(t("erroToUpdateWorkHourCost"), "error");
        }
    };

    // DELETE WORK HOUR COST
    const [workHourCostIdToDelete, setWorkHourCostIdToDelete] = useState(null);

    const handleDeleteWorkHourCostOpenDialog = async (id) => {
        setWorkHourCostIdToDelete(id);
        setOpenDeleteWorkHourCostDialog(true);
    };

    const [deleteWorkHourCost, { isLoading: isLoadingDeleteWorkHourCost }] =
        useDeleteWorkHourCostMutation();

    const handleDeleteWorkHourCost = async () => {
        try {
            const { codeResult } = await deleteWorkHourCost(
                workHourCostIdToDelete
            ).unwrap();

            if (
                codeResult !== RESPONSES.workHourCost.sucessToRemoveWorkHourCost
            ) {
                if (
                    codeResult ===
                    RESPONSES.workHourCost.invalidToRemoveWorkHourCost
                ) {
                    handleSnackbarOpen(
                        t("invalidToRemoveWorkHourCost"),
                        "error"
                    );
                } else {
                    handleSnackbarOpen(t("erroToRemoveWorkHourCost"), "error");
                }
            } else {
                setOpenDeleteWorkHourCostDialog(false);
                handleSnackbarOpen(t("sucessToRemoveWorkHourCost"), "success");
            }
        } catch (err) {
            handleSnackbarOpen(t("erroToRemoveWorkHourCost"), "error");
        }
    };

    const [
        workHours_Employee_FixedSalaryIdToDelete,
        setWorkHours_Employee_FixedSalaryId,
    ] = useState(null);

    const handleDeleteWorkHours_Employee_FixedSalaryOpenDialog = async (id) => {
        setWorkHours_Employee_FixedSalaryId(id);
        setOpenDeleteWorkHour_Employee_FixedSalaryDialog(true);
    };

    const [
        deleteWorkHours_Employee_FixedSalary,
        { isLoading: isLoadingDeleteWorkHours_Employee_FixedSalary },
    ] = useDeleteWorkHours_Employee_FixedSalaryByIdMutation();

    const handleDeleteWorkHour_Employee_FixedSalary = async () => {
        try {
            const { codeResult } = await deleteWorkHours_Employee_FixedSalary(
                workHours_Employee_FixedSalaryIdToDelete,
                id
            ).unwrap();

            if (
                codeResult !==
                RESPONSES.workHours_Employee_FixedSalary
                    .sucessToDeleteWorkHours_Employee_FixedSalary
            ) {
                handleSnackbarOpen(t("errorToDelete"), "error");
            } else {
                setOpenDeleteWorkHour_Employee_FixedSalaryDialog(false);
                handleSnackbarOpen(t("sucessToDeleteHours"), "success");
            }
        } catch (err) {
            handleSnackbarOpen(t("errorToDelete"), "error");
        }
    };
    // DELETE EMPLOYEE WORK HOUR COSTS
    const [
        deleteEmployeeWorkHourCosts,
        { isLoading: isLoadingDeleteEmployeeWorkHourCosts },
    ] = useDeleteEmployeeWorkHourCostsMutation();

    const handleDeleteEmployeeWorkHourCosts = async () => {
        try {
            let employeeWorkHourCostIdToRemove = id.split("-")[0];
            const { codeResult } = await deleteEmployeeWorkHourCosts(
                employeeWorkHourCostIdToRemove
            ).unwrap();

            if (
                codeResult !==
                RESPONSES.employeeWorkHourCosts
                    .sucessToRemoveEmployeeWorkHourCosts
            ) {
                if (
                    codeResult ===
                    RESPONSES.employeeWorkHourCosts
                        .invalidToRemoveEmployeeWorkHourCosts
                ) {
                    handleSnackbarOpen(
                        t("invalidToRemoveEmployeeWorkHourCosts"),
                        "error"
                    );
                } else {
                    handleSnackbarOpen(
                        t("errorToRemoveEmployeeWorkHourCosts"),
                        "error"
                    );
                }
            } else {
                //fechar popup
                navigate(`/${location.pathname.split("/")[1]}`);
            }
        } catch (err) {
            handleSnackbarOpen(
                t("errorToRemoveEmployeeWorkHourCosts"),
                "error"
            );
        }
    };

    //dar update das observações e se o funcionário está ou nao de baixa
    const [
        updateEmployeeWorkHourCosts,
        { isLoading: isLoadingUpdateEmployeeWorkHourCosts },
    ] = useUpdateEmployeeWorkHourCostsMutation();

    const handleFormSubmit = async (values, onSubmitProps) => {
        try {
            let updateEmployeeWorkHourCost = null;

            updateEmployeeWorkHourCost = {
                EmployeeWorkHourCostId: id,
                MealAllowance: values.mealAllowance,
                Observations: values.observations,
            };

            const { codeResult } = await updateEmployeeWorkHourCosts(
                updateEmployeeWorkHourCost
            ).unwrap();

            if (
                codeResult !==
                RESPONSES.employeeWorkHourCosts
                    .sucessToUpdateEmployeeWorkHourCosts
            ) {
                if (
                    codeResult ===
                    RESPONSES.employeeWorkHourCosts
                        .invalidToUpdateEmployeeWorkHourCosts
                ) {
                    handleSnackbarOpen(
                        t("invalidToUpdateEmployeeWorkHourCosts"),
                        "error"
                    );
                } else if (
                    codeResult ===
                    RESPONSES.employeeWorkHourCosts
                        .invalidToUpdateBecauseDetails
                ) {
                    handleSnackbarOpen(
                        t("invalidToUpdateBecauseDetails"),
                        "error"
                    );
                } else {
                    handleSnackbarOpen(
                        t("errorToUpdateEmployeeWorkHourCosts"),
                        "error"
                    );
                }
            } else {
                // refetchEmployeeWorkHourCosts();
                handleSnackbarOpen(
                    t("sucessToUpdateEmployeeWorkHourCosts"),
                    "success"
                );
            }
        } catch (err) {
            handleSnackbarOpen(
                t("errorToUpdateEmployeeWorkHourCosts"),
                "error"
            );
        }
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={
                employeeWorkHourCosts || initialValuesEmployeeWorkHourCosts
            }
            validationSchema={employeeWorkHourCostsSchema}
            enableReinitialize={true}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    {isLoadingGetEmployeeWorkHourCosts ? (
                        <LoadingSpinner />
                    ) : null}
                    <Box
                        m={!isNonMobile ? "0" : "1.5rem 2.5rem"}
                        display={!isNonMobile ? "flex" : "grid"}
                        gridTemplateRows={
                            !isNonMobile ? undefined : "repeat(4, auto)"
                        }
                        flexDirection={!isNonMobile ? "column" : undefined}
                        gap="20px"
                        borderRadius="5px"
                        p="2rem"
                    >
                        <PageHeader title={values.employee.name} subtitle="" />
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("salarialProcessing")}
                            </Typography>
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(3, 1fr)"
                                }
                                gap="10px"
                            >
                                <Autocomplete
                                    readOnly={true}
                                    variant="outlined"
                                    loading={isLoadingGetEmployeeWorkHourCosts}
                                    options={employees || []}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                        (employees &&
                                            employees.find(
                                                (employee) =>
                                                    employee.id ===
                                                    values.employeeId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "employeeId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                    }}
                                    onBlur={handleBlur}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("employee")}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.employeeId
                                                    ) &&
                                                    Boolean(errors.employeeId)
                                                }
                                                helperText={
                                                    touched.employeeId &&
                                                    errors.employeeId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        label={t("monthOfProcessing")}
                                        format="MM/YYYY"
                                        value={dayjs(values.currentDate)}
                                        onChange={(value) =>
                                            setFieldValue("currentDate", value)
                                        }
                                        readOnly={true}
                                    />
                                </LocalizationProvider>
                                <TextField
                                    label={t("advancePaymentByMoney")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.advancePaymentSumByMoney}
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        endAdornment: "€",
                                    }}
                                    readOnly={true}
                                />
                                <TextField
                                    label={t("advancePaymentByCard")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.advancePaymentSumByCard}
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        endAdornment: "€",
                                    }}
                                    readOnly={true}
                                />
                                <TextField
                                    label={t("adjustments")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.adjustmentSum}
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        endAdornment: "€",
                                    }}
                                    readOnly={true}
                                />
                                <TextField
                                    label={t("mealAllowance")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={
                                        values.mealAllowance === null
                                            ? ""
                                            : values.mealAllowance
                                    }
                                    name="mealAllowance"
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        endAdornment: "€",
                                    }}
                                />
                                <TextField
                                    label={t("salary")}
                                    onBlur={handleBlur}
                                    value={values.salary}
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        endAdornment: "€",
                                    }}
                                    readOnly={true}
                                />
                                {values.isSickLeave === true && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.isSickLeave}
                                                disabled
                                            />
                                        }
                                        label={t("sickLeave")}
                                    />
                                )}

                                {values.notWorking === true && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.notWorking}
                                                disabled
                                            />
                                        }
                                        label={t("notWorking")}
                                    />
                                )}

                                <TextField
                                    label={t("observations")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.observations}
                                    name="observations"
                                    multiline
                                    sx={{
                                        width: "100%",
                                    }}
                                />
                            </Box>
                            {/* WORK HOUR COSTS*/}
                            {values.isSickLeave === false &&
                                values.notWorking === false && (
                                    <Box
                                        display="flex"
                                        gap="20px"
                                        flexDirection="column"
                                        width="100%"
                                    >
                                        <Box
                                            display="flex"
                                            width="100%"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Typography
                                                fontWeight="500"
                                                fontSize="20px"
                                                color="primary"
                                                variant="h5"
                                                textAlign="left"
                                            >
                                                {t("details")}
                                            </Typography>
                                            {(workHourCosts.length === 1 &&
                                                isFixedSalary === true) ||
                                            isHistoric === true ? (
                                                <LoadingButton
                                                    type="button"
                                                    sx={{
                                                        p: "5px 1rem",
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.main,
                                                        color: theme.palette
                                                            .neutral.white,
                                                        "&:hover": {
                                                            backgroundColor:
                                                                theme.palette
                                                                    .primary
                                                                    .light,
                                                        },
                                                    }}
                                                >
                                                    {t("addDetail")}
                                                </LoadingButton>
                                            ) : (
                                                <LoadingButton
                                                    type="button"
                                                    onClick={
                                                        handleOpenCreateWorkHourCostDialog
                                                    }
                                                    sx={{
                                                        p: "5px 1rem",
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.main,
                                                        color: theme.palette
                                                            .neutral.white,
                                                        "&:hover": {
                                                            backgroundColor:
                                                                theme.palette
                                                                    .primary
                                                                    .light,
                                                        },
                                                    }}
                                                >
                                                    {t("addDetail")}
                                                </LoadingButton>
                                            )}
                                        </Box>

                                        {isFixedSalary === false ? (
                                            <Box
                                                display="flex"
                                                sx={{
                                                    "& .MuiDataGrid-root": {
                                                        border: "none",
                                                        flexGrow: 1,
                                                        width: "100%",
                                                    },
                                                    "& .MuiDataGrid-cell": {
                                                        // borderBottom: "none",
                                                    },
                                                    "& .MuiDataGrid-columnHeaders":
                                                        {
                                                            backgroundColor: `${theme.palette.background.J} !important`,
                                                            color: theme.palette
                                                                .secondary[100],
                                                            borderBottom:
                                                                "none",
                                                        },
                                                    "& .MuiDataGrid-virtualScroller":
                                                        {
                                                            backgroundColor:
                                                                theme.palette
                                                                    .background
                                                                    .A,
                                                        },
                                                    "& .MuiDataGrid-footerContainer":
                                                        {
                                                            backgroundColor: `${theme.palette.background.J} !important`,
                                                            color: theme.palette
                                                                .secondary[100],
                                                            borderTop: "none",
                                                        },
                                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text":
                                                        {
                                                            color: `${theme.palette.background.I}`,
                                                        },
                                                    "& .MuiDataGrid-toolbarContainer .MuiBox-root":
                                                        {
                                                            display:
                                                                !isNonMobile
                                                                    ? "flex"
                                                                    : undefined,
                                                            flexDirection:
                                                                !isNonMobile
                                                                    ? "row"
                                                                    : undefined,
                                                        },
                                                    "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                                        {
                                                            backgroundColor: `${theme.palette.background.A} !important`,
                                                        },
                                                }}
                                            >
                                                <DataGrid
                                                    autoHeight
                                                    loading={
                                                        isLoadingGetEmployeeWorkHourCosts ||
                                                        !workHourCosts
                                                    }
                                                    getRowId={(row) => row.id}
                                                    rows={workHourCosts || []}
                                                    columns={
                                                        workHourCostsColumns
                                                    }
                                                    initialState={{
                                                        pagination: {
                                                            paginationModel: {
                                                                pageSize: 5,
                                                            },
                                                        },
                                                    }}
                                                    pageSizeOptions={[5]}
                                                    key={JSON.stringify(
                                                        workHourCosts
                                                    )}
                                                    disableRowSelectionOnClick
                                                />
                                            </Box>
                                        ) : (
                                            <Box
                                                display="flex"
                                                sx={{
                                                    "& .MuiDataGrid-root": {
                                                        border: "none",
                                                        flexGrow: 1,
                                                        width: "100%",
                                                    },
                                                    "& .MuiDataGrid-cell": {
                                                        // borderBottom: "none",
                                                    },
                                                    "& .MuiDataGrid-columnHeaders":
                                                        {
                                                            backgroundColor: `${theme.palette.background.J} !important`,
                                                            color: theme.palette
                                                                .secondary[100],
                                                            borderBottom:
                                                                "none",
                                                        },
                                                    "& .MuiDataGrid-virtualScroller":
                                                        {
                                                            backgroundColor:
                                                                theme.palette
                                                                    .background
                                                                    .A,
                                                        },
                                                    "& .MuiDataGrid-footerContainer":
                                                        {
                                                            backgroundColor: `${theme.palette.background.J} !important`,
                                                            color: theme.palette
                                                                .secondary[100],
                                                            borderTop: "none",
                                                        },
                                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text":
                                                        {
                                                            color: `${theme.palette.background.I}`,
                                                        },
                                                    "& .MuiDataGrid-toolbarContainer .MuiBox-root":
                                                        {
                                                            display:
                                                                !isNonMobile
                                                                    ? "flex"
                                                                    : undefined,
                                                            flexDirection:
                                                                !isNonMobile
                                                                    ? "row"
                                                                    : undefined,
                                                        },
                                                    "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                                        {
                                                            backgroundColor: `${theme.palette.background.A} !important`,
                                                        },
                                                }}
                                            >
                                                <DataGrid
                                                    autoHeight
                                                    loading={
                                                        isLoadingGetEmployeeWorkHourCosts ||
                                                        !workHourCosts
                                                    }
                                                    getRowId={(row) => row.id}
                                                    rows={workHourCosts || []}
                                                    columns={
                                                        workHourCostsColumnsToFixedSalary
                                                    }
                                                    initialState={{
                                                        pagination: {
                                                            paginationModel: {
                                                                pageSize: 5,
                                                            },
                                                        },
                                                    }}
                                                    key={JSON.stringify(
                                                        workHourCosts
                                                    )}
                                                    pageSizeOptions={[5]}
                                                    disableRowSelectionOnClick
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                )}
                            {isFixedSalary === true &&
                                isOfficeEmployee === false &&
                                values.isSickLeave === false &&
                                values.notWorking === false && (
                                    <Box
                                        display="flex"
                                        gap="20px"
                                        flexDirection="column"
                                        width="100%"
                                    >
                                        <Box
                                            display="flex"
                                            width="100%"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Typography
                                                fontWeight="500"
                                                fontSize="20px"
                                                color="primary"
                                                variant="h5"
                                                textAlign="left"
                                            >
                                                {t("workHours")}
                                            </Typography>
                                            <LoadingButton
                                                type="button"
                                                onClick={
                                                    handleOpenCreateWorkHour_Employee_FixedSalaryDialog
                                                }
                                                sx={{
                                                    p: "5px 1rem",
                                                    backgroundColor:
                                                        theme.palette.primary
                                                            .main,
                                                    color: theme.palette.neutral
                                                        .white,
                                                    "&:hover": {
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.light,
                                                    },
                                                }}
                                            >
                                                {t("addHours")}
                                            </LoadingButton>
                                        </Box>
                                        <Box
                                            display="flex"
                                            sx={{
                                                "& .MuiDataGrid-root": {
                                                    border: "none",
                                                    flexGrow: 1,
                                                    width: "100%",
                                                },
                                                "& .MuiDataGrid-cell": {
                                                    // borderBottom: "none",
                                                },
                                                "& .MuiDataGrid-columnHeaders":
                                                    {
                                                        backgroundColor: `${theme.palette.background.J} !important`,
                                                        color: theme.palette
                                                            .secondary[100],
                                                        borderBottom: "none",
                                                    },
                                                "& .MuiDataGrid-virtualScroller":
                                                    {
                                                        backgroundColor:
                                                            theme.palette
                                                                .background.A,
                                                    },
                                                "& .MuiDataGrid-footerContainer":
                                                    {
                                                        backgroundColor: `${theme.palette.background.J} !important`,
                                                        color: theme.palette
                                                            .secondary[100],
                                                        borderTop: "none",
                                                    },
                                                "& .MuiDataGrid-toolbarContainer .MuiButton-text":
                                                    {
                                                        color: `${theme.palette.background.I}`,
                                                    },
                                                "& .MuiDataGrid-toolbarContainer .MuiBox-root":
                                                    {
                                                        display: !isNonMobile
                                                            ? "flex"
                                                            : undefined,
                                                        flexDirection:
                                                            !isNonMobile
                                                                ? "row"
                                                                : undefined,
                                                    },
                                                "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                                    {
                                                        backgroundColor: `${theme.palette.background.A} !important`,
                                                    },
                                            }}
                                        >
                                            <DataGrid
                                                autoHeight
                                                loading={
                                                    isLoadingGetEmployeeWorkHourCosts ||
                                                    !workHours_Employee_FixedSalary
                                                }
                                                getRowId={(row) => row.id}
                                                rows={
                                                    workHours_Employee_FixedSalary ||
                                                    []
                                                }
                                                columns={
                                                    workHourCostsColumnsToFixedSalaryAndNotOfficeEmployee
                                                }
                                                initialState={{
                                                    pagination: {
                                                        paginationModel: {
                                                            pageSize: 5,
                                                        },
                                                    },
                                                }}
                                                pageSizeOptions={[5]}
                                                key={JSON.stringify(
                                                    workHours_Employee_FixedSalary
                                                )}
                                                disableRowSelectionOnClick
                                            />
                                        </Box>
                                    </Box>
                                )}
                            {/** Adicionar Horas */}
                            <Dialog
                                open={
                                    openCreateWorkHour_Employee_FixedSalaryDialog
                                }
                                onClose={
                                    handleCloseCreateWorkHour_Employee_FixedSalaryDialog
                                }
                                maxWidth="800px"
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <Formik
                                    onSubmit={
                                        handleFormAddWorkHour_Employee_FixedSalarySubmit
                                    }
                                    initialValues={
                                        initialValuesworkHour_Employee_FixedSalary
                                    }
                                    validationSchema={
                                        workHour_Employee_FixedSalarySchema
                                    }
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        setFieldValue,
                                        resetForm,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box
                                                display="grid"
                                                gridTemplateColumns={
                                                    !isNonMobile
                                                        ? "repeat(1, 1fr)"
                                                        : "repeat(1, auto)"
                                                }
                                                gap="20px"
                                                backgroundColor={
                                                    theme.palette.background.D
                                                }
                                                borderRadius="5px"
                                                p="2rem"
                                                width={
                                                    !isNonMobile
                                                        ? "100%"
                                                        : "40rem"
                                                }
                                            >
                                                <PageHeader
                                                    title={t("addHours")}
                                                    subtitle=""
                                                />
                                                <Box
                                                    display="flex"
                                                    gap="20px"
                                                    flexDirection="column"
                                                    width="100%"
                                                    mt="10px"
                                                >
                                                    <Box
                                                        display="grid"
                                                        gridTemplateColumns={
                                                            !isNonMobile
                                                                ? "repeat(1, 1fr)"
                                                                : "repeat(2, 1fr)"
                                                        }
                                                        gap="10px"
                                                    >
                                                        <Autocomplete
                                                            variant="outlined"
                                                            loading={
                                                                isLoadingEmployeePlanning
                                                            }
                                                            options={
                                                                workPlannings ||
                                                                []
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) =>
                                                                option.name +
                                                                " - " +
                                                                option.work.name
                                                            }
                                                            value={
                                                                (workPlannings &&
                                                                    workPlannings.find(
                                                                        (
                                                                            workPlanning
                                                                        ) =>
                                                                            workPlanning.id ===
                                                                            values.workPlanningId
                                                                    )) ||
                                                                null
                                                            }
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                handleChange({
                                                                    target: {
                                                                        name: "workPlanningId",
                                                                        value: newValue
                                                                            ? newValue.id
                                                                            : "",
                                                                    },
                                                                });
                                                            }}
                                                            onBlur={handleBlur(
                                                                "workPlanningId"
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <FormControl
                                                                    fullWidth
                                                                >
                                                                    <TextField
                                                                        label={t(
                                                                            "planningWork"
                                                                        )}
                                                                        {...params}
                                                                        error={
                                                                            Boolean(
                                                                                touched.workPlanningId
                                                                            ) &&
                                                                            Boolean(
                                                                                errors.workPlanningId
                                                                            )
                                                                        }
                                                                        helperText={
                                                                            touched.workId &&
                                                                            errors.workId
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            )}
                                                        />

                                                        <TextField
                                                            label={t("hours")}
                                                            onBlur={handleBlur}
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.numberOfWorkHours
                                                            }
                                                            name="numberOfWorkHours"
                                                            error={
                                                                Boolean(
                                                                    touched.numberOfWorkHours
                                                                ) &&
                                                                Boolean(
                                                                    errors.numberOfWorkHours
                                                                )
                                                            }
                                                            helperText={
                                                                touched.numberOfWorkHours &&
                                                                errors.numberOfWorkHours
                                                            }
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                                {/* BUTTONS */}
                                                <LoadingButton
                                                    fullWidth
                                                    type="submit"
                                                    loading={
                                                        isLoadingAddWorkHour_Employee_FixedSalary
                                                    }
                                                    sx={{
                                                        width: "100%",
                                                        p: "1rem",
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.main,
                                                        color: theme.palette
                                                            .neutral.white,
                                                        "&:hover": {
                                                            backgroundColor:
                                                                theme.palette
                                                                    .primary
                                                                    .light,
                                                        },
                                                    }}
                                                >
                                                    {t("addHours")}
                                                </LoadingButton>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            </Dialog>
                            {/** Editar Horas */}
                            <Dialog
                                open={
                                    openEditWorkHour_Employee_FixedSalaryDialog
                                }
                                onClose={
                                    handleCloseEditWorkHour_Employee_FixedSalaryDialog
                                }
                                maxWidth="800px"
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <Formik
                                    onSubmit={
                                        handleFormEditWorkHours_EmployeeFixedSalarySubmit
                                    }
                                    initialValues={
                                        workHour_Employee_FixedSalaryToEdit ||
                                        initialValuesworkHour_Employee_FixedSalary
                                    }
                                    validationSchema={
                                        workHour_Employee_FixedSalarySchema
                                    }
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        setFieldValue,
                                        resetForm,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box
                                                display="grid"
                                                gridTemplateColumns={
                                                    !isNonMobile
                                                        ? "repeat(1, 1fr)"
                                                        : "repeat(1, auto)"
                                                }
                                                gap="20px"
                                                backgroundColor={
                                                    theme.palette.background.D
                                                }
                                                borderRadius="5px"
                                                p="2rem"
                                                width={
                                                    !isNonMobile
                                                        ? "100%"
                                                        : "40rem"
                                                }
                                            >
                                                <PageHeader
                                                    title={t("editDetail")}
                                                    subtitle=""
                                                />
                                                <Box
                                                    display="flex"
                                                    gap="20px"
                                                    flexDirection="column"
                                                    width="100%"
                                                    mt="10px"
                                                >
                                                    <Box
                                                        display="grid"
                                                        gridTemplateColumns={
                                                            !isNonMobile
                                                                ? "repeat(1, 1fr)"
                                                                : "repeat(2, 1fr)"
                                                        }
                                                        gap="10px"
                                                    >
                                                        <Autocomplete
                                                            variant="outlined"
                                                            loading={
                                                                isLoadingEmployeePlanning
                                                            }
                                                            options={
                                                                editWorkPlannigns ||
                                                                []
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) =>
                                                                option.name +
                                                                " - " +
                                                                option.work.name
                                                            }
                                                            value={
                                                                (editWorkPlannigns &&
                                                                    editWorkPlannigns.find(
                                                                        (
                                                                            workPlanning
                                                                        ) =>
                                                                            workPlanning.id ===
                                                                            values.workPlanningId
                                                                    )) ||
                                                                null
                                                            }
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                handleChange({
                                                                    target: {
                                                                        name: "workPlanningId",
                                                                        value: newValue
                                                                            ? newValue.id
                                                                            : "",
                                                                    },
                                                                });
                                                            }}
                                                            onBlur={handleBlur(
                                                                "workPlanningId"
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <FormControl
                                                                    fullWidth
                                                                >
                                                                    <TextField
                                                                        label={t(
                                                                            "planningWork"
                                                                        )}
                                                                        {...params}
                                                                        error={
                                                                            Boolean(
                                                                                touched.workPlanningId
                                                                            ) &&
                                                                            Boolean(
                                                                                errors.workPlanningId
                                                                            )
                                                                        }
                                                                        helperText={
                                                                            touched.workId &&
                                                                            errors.workId
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            )}
                                                        />

                                                        <TextField
                                                            label={t("hours")}
                                                            onBlur={handleBlur}
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.numberOfWorkHours
                                                            }
                                                            name="numberOfWorkHours"
                                                            error={
                                                                Boolean(
                                                                    touched.numberOfWorkHours
                                                                ) &&
                                                                Boolean(
                                                                    errors.numberOfWorkHours
                                                                )
                                                            }
                                                            helperText={
                                                                touched.numberOfWorkHours &&
                                                                errors.numberOfWorkHours
                                                            }
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                                {/* BUTTONS */}
                                                <LoadingButton
                                                    fullWidth
                                                    type="submit"
                                                    loading={
                                                        isLoadingEditWorkHours_EmployeeFixedSalary
                                                    }
                                                    sx={{
                                                        width: "100%",
                                                        p: "1rem",
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.main,
                                                        color: theme.palette
                                                            .neutral.white,
                                                        "&:hover": {
                                                            backgroundColor:
                                                                theme.palette
                                                                    .primary
                                                                    .light,
                                                        },
                                                    }}
                                                >
                                                    {t("editHours")}
                                                </LoadingButton>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            </Dialog>
                            {/* DELETE WORK HOUR COST */}
                            <Dialog
                                open={
                                    openDeleteWorkHour_Employee_FixedSalaryDialog
                                }
                                onClose={
                                    handleCloseDeleteWorkHour_Employee_FixedSalaryDialog
                                }
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {t(
                                        "deleteWorkHours_Employee_FixedSalaryConfirmationTitle"
                                    )}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {t(
                                            "deleteWorkHours_Employee_FixedSalaryConfirmationMessage"
                                        )}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        sx={{
                                            backgroundColor:
                                                theme.palette.alt.red,
                                            color: theme.palette.neutral.white,
                                            "&:hover": {
                                                backgroundColor:
                                                    theme.palette.alt.lightRed,
                                            },
                                        }}
                                        onClick={
                                            handleCloseDeleteWorkHour_Employee_FixedSalaryDialog
                                        }
                                    >
                                        {t("noMessage")}
                                    </Button>
                                    <Button
                                        sx={{
                                            backgroundColor:
                                                theme.palette.primary.main,
                                            color: theme.palette.neutral.white,
                                            "&:hover": {
                                                backgroundColor:
                                                    theme.palette.primary.light,
                                            },
                                        }}
                                        onClick={
                                            handleDeleteWorkHour_Employee_FixedSalary
                                        }
                                        autoFocus
                                    >
                                        {t("yesMessage")}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            {/* CREATE WORK HOUR COST */}
                            <Dialog
                                open={openCreateWorkHourCostDialog}
                                onClose={handleCloseCreateWorkHourCostDialog}
                                maxWidth="800px"
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <Formik
                                    onSubmit={handleFormAddWorkHourCostSubmit}
                                    initialValues={
                                        initialValuesWorkHourCostSchema
                                    }
                                    validationSchema={workHourCostSchema}
                                    enableReinitialize={true}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        setFieldValue,
                                        resetForm,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box
                                                display="grid"
                                                gridTemplateColumns={
                                                    !isNonMobile
                                                        ? "repeat(1, 1fr)"
                                                        : "repeat(1, auto)"
                                                }
                                                gap="20px"
                                                backgroundColor={
                                                    theme.palette.background.D
                                                }
                                                borderRadius="5px"
                                                p="2rem"
                                                width={
                                                    !isNonMobile
                                                        ? "100%"
                                                        : "40rem"
                                                }
                                            >
                                                <PageHeader
                                                    title={t("addDetail")}
                                                    subtitle=""
                                                />
                                                <Box
                                                    display="flex"
                                                    gap="20px"
                                                    flexDirection="column"
                                                    width="100%"
                                                    mt="10px"
                                                >
                                                    <Box
                                                        display="grid"
                                                        gridTemplateColumns={
                                                            !isNonMobile
                                                                ? "repeat(1, 1fr)"
                                                                : "repeat(2, 1fr)"
                                                        }
                                                        gap="10px"
                                                    >
                                                        {isFixedSalary ===
                                                            false && (
                                                            <Autocomplete
                                                                variant="outlined"
                                                                loading={
                                                                    isLoadingEmployeePlanning
                                                                }
                                                                options={
                                                                    workPlannings ||
                                                                    []
                                                                }
                                                                getOptionLabel={(
                                                                    option
                                                                ) =>
                                                                    option.name +
                                                                    " - " +
                                                                    option.work
                                                                        .name
                                                                }
                                                                value={
                                                                    (workPlannings &&
                                                                        workPlannings.find(
                                                                            (
                                                                                workPlanning
                                                                            ) =>
                                                                                workPlanning.id ===
                                                                                values.workPlanningId
                                                                        )) ||
                                                                    null
                                                                }
                                                                onChange={(
                                                                    event,
                                                                    newValue
                                                                ) => {
                                                                    handleChange(
                                                                        {
                                                                            target: {
                                                                                name: "workPlanningId",
                                                                                value: newValue
                                                                                    ? newValue.id
                                                                                    : "",
                                                                            },
                                                                        }
                                                                    );
                                                                    if (
                                                                        newValue
                                                                    ) {
                                                                        getEmployeePlanningByWorkPlanningIdAndEmployeeIdOnWorkPlanningChange(
                                                                            newValue.id,
                                                                            employeeWorkHourCosts
                                                                                .employee
                                                                                .id
                                                                        );
                                                                    }
                                                                }}
                                                                onBlur={handleBlur(
                                                                    "workPlanningId"
                                                                )}
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <FormControl
                                                                        fullWidth
                                                                    >
                                                                        <TextField
                                                                            label={t(
                                                                                "planningWork"
                                                                            )}
                                                                            {...params}
                                                                            error={
                                                                                Boolean(
                                                                                    touched.workPlanningId
                                                                                ) &&
                                                                                Boolean(
                                                                                    errors.workPlanningId
                                                                                )
                                                                            }
                                                                            helperText={
                                                                                touched.workId &&
                                                                                errors.workId
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                )}
                                                            />
                                                        )}
                                                        {isFixedSalary ===
                                                        false ? (
                                                            <TextField
                                                                label={t(
                                                                    "hourSalary"
                                                                )}
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                value={
                                                                    hourCostEmployeePlanning
                                                                        ? hourCostEmployeePlanning
                                                                        : values.cost
                                                                }
                                                                name="cost"
                                                                error={
                                                                    Boolean(
                                                                        touched.cost
                                                                    ) &&
                                                                    Boolean(
                                                                        errors.cost
                                                                    )
                                                                }
                                                                helperText={
                                                                    touched.cost &&
                                                                    errors.cost
                                                                }
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                            />
                                                        ) : (
                                                            <TextField
                                                                label={t(
                                                                    "salary"
                                                                )}
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                value={
                                                                    values.cost
                                                                }
                                                                name="cost"
                                                                error={
                                                                    Boolean(
                                                                        touched.cost
                                                                    ) &&
                                                                    Boolean(
                                                                        errors.cost
                                                                    )
                                                                }
                                                                helperText={
                                                                    touched.cost &&
                                                                    errors.cost
                                                                }
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                            />
                                                        )}
                                                        {isFixedSalary ===
                                                            true && (
                                                            <TextField
                                                                label={t(
                                                                    "absencesNumber"
                                                                )}
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                value={
                                                                    values.absencesTotal
                                                                        ? values.absencesTotal
                                                                        : ""
                                                                }
                                                                name="absencesTotal"
                                                                error={
                                                                    Boolean(
                                                                        touched.absencesTotal
                                                                    ) &&
                                                                    Boolean(
                                                                        errors.absencesTotal
                                                                    )
                                                                }
                                                                helperText={
                                                                    touched.absencesTotal &&
                                                                    errors.absencesTotal
                                                                }
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                            />
                                                        )}

                                                        {isFixedSalary ===
                                                            true && (
                                                            <TextField
                                                                label={t(
                                                                    "freeDays"
                                                                )}
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                value={
                                                                    values.freeDays
                                                                        ? values.freeDays
                                                                        : ""
                                                                }
                                                                name="freeDays"
                                                                error={
                                                                    Boolean(
                                                                        touched.freeDays
                                                                    ) &&
                                                                    Boolean(
                                                                        errors.freeDays
                                                                    )
                                                                }
                                                                helperText={
                                                                    touched.freeDays &&
                                                                    errors.freeDays
                                                                }
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                            />
                                                        )}

                                                        {isFixedSalary ===
                                                            false && (
                                                            <TextField
                                                                label={t(
                                                                    "hours"
                                                                )}
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                value={
                                                                    values.numberOfWorkHours
                                                                }
                                                                name="numberOfWorkHours"
                                                                error={
                                                                    Boolean(
                                                                        touched.numberOfWorkHours
                                                                    ) &&
                                                                    Boolean(
                                                                        errors.numberOfWorkHours
                                                                    )
                                                                }
                                                                helperText={
                                                                    touched.numberOfWorkHours &&
                                                                    errors.numberOfWorkHours
                                                                }
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                            />
                                                        )}
                                                    </Box>
                                                </Box>
                                                {/* BUTTONS */}
                                                <LoadingButton
                                                    fullWidth
                                                    type="submit"
                                                    loading={
                                                        isLoadingAddWorkHourCost
                                                    }
                                                    sx={{
                                                        width: "100%",
                                                        p: "1rem",
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.main,
                                                        color: theme.palette
                                                            .neutral.white,
                                                        "&:hover": {
                                                            backgroundColor:
                                                                theme.palette
                                                                    .primary
                                                                    .light,
                                                        },
                                                    }}
                                                >
                                                    {t("addDetail")}
                                                </LoadingButton>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            </Dialog>
                            {/* EDIT WORK HOUR COST */}
                            <Dialog
                                open={openEditWorkHourCostDialog}
                                onClose={handleCloseEditWorkHourCostDialog}
                                maxWidth="800px"
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <Formik
                                    onSubmit={handleFormEditWorkHourCostSubmit}
                                    initialValues={
                                        workHourCostToEdit ||
                                        initialValuesWorkHourCostSchema
                                    }
                                    validationSchema={workHourCostSchema}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        setFieldValue,
                                        resetForm,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box
                                                display="grid"
                                                gridTemplateColumns={
                                                    !isNonMobile
                                                        ? "repeat(1, 1fr)"
                                                        : "repeat(1, auto)"
                                                }
                                                gap="20px"
                                                backgroundColor={
                                                    theme.palette.background.D
                                                }
                                                borderRadius="5px"
                                                p="2rem"
                                                width={
                                                    !isNonMobile
                                                        ? "100%"
                                                        : "40rem"
                                                }
                                            >
                                                <PageHeader
                                                    title={t("editDetail")}
                                                    subtitle=""
                                                />
                                                <Box
                                                    display="flex"
                                                    gap="20px"
                                                    flexDirection="column"
                                                    width="100%"
                                                    mt="10px"
                                                >
                                                    <Box
                                                        display="grid"
                                                        gridTemplateColumns={
                                                            !isNonMobile
                                                                ? "repeat(1, 1fr)"
                                                                : "repeat(2, 1fr)"
                                                        }
                                                        gap="10px"
                                                    >
                                                        {isFixedSalary ===
                                                            false && (
                                                            <Autocomplete
                                                                variant="outlined"
                                                                loading={
                                                                    isLoadingEmployeePlanning
                                                                }
                                                                options={
                                                                    workPlannings ||
                                                                    []
                                                                }
                                                                getOptionLabel={(
                                                                    option
                                                                ) =>
                                                                    option.name +
                                                                    " - " +
                                                                    option.work
                                                                        .name
                                                                }
                                                                value={
                                                                    (workPlannings &&
                                                                        workPlannings.find(
                                                                            (
                                                                                workPlanning
                                                                            ) =>
                                                                                workPlanning.id ===
                                                                                values.workPlanningId
                                                                        )) ||
                                                                    null
                                                                }
                                                                onChange={(
                                                                    event,
                                                                    newValue
                                                                ) => {
                                                                    handleChange(
                                                                        {
                                                                            target: {
                                                                                name: "workPlanningId",
                                                                                value: newValue
                                                                                    ? newValue.id
                                                                                    : "",
                                                                            },
                                                                        }
                                                                    );
                                                                    if (
                                                                        newValue
                                                                    ) {
                                                                        getEmployeePlanningByWorkPlanningIdAndEmployeeIdOnWorkPlanningChange(
                                                                            newValue.id,
                                                                            employeeWorkHourCosts
                                                                                .employee
                                                                                .id
                                                                        );
                                                                    }
                                                                }}
                                                                onBlur={handleBlur(
                                                                    "workPlanningId"
                                                                )}
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <FormControl
                                                                        fullWidth
                                                                    >
                                                                        <TextField
                                                                            label={t(
                                                                                "planningWork"
                                                                            )}
                                                                            {...params}
                                                                            error={
                                                                                Boolean(
                                                                                    touched.workPlanningId
                                                                                ) &&
                                                                                Boolean(
                                                                                    errors.workPlanningId
                                                                                )
                                                                            }
                                                                            helperText={
                                                                                touched.workId &&
                                                                                errors.workId
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                )}
                                                            />
                                                        )}
                                                        {isFixedSalary ===
                                                        false ? (
                                                            <TextField
                                                                label={t(
                                                                    "hourSalary"
                                                                )}
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                value={
                                                                    hourCostEmployeePlanning
                                                                        ? hourCostEmployeePlanning
                                                                        : values.cost
                                                                }
                                                                name="cost"
                                                                error={
                                                                    Boolean(
                                                                        touched.cost
                                                                    ) &&
                                                                    Boolean(
                                                                        errors.cost
                                                                    )
                                                                }
                                                                helperText={
                                                                    touched.cost &&
                                                                    errors.cost
                                                                }
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                            />
                                                        ) : (
                                                            <TextField
                                                                label={t(
                                                                    "salary"
                                                                )}
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                value={
                                                                    values.cost
                                                                }
                                                                name="cost"
                                                                error={
                                                                    Boolean(
                                                                        touched.cost
                                                                    ) &&
                                                                    Boolean(
                                                                        errors.cost
                                                                    )
                                                                }
                                                                helperText={
                                                                    touched.cost &&
                                                                    errors.cost
                                                                }
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                            />
                                                        )}

                                                        {isFixedSalary ===
                                                            true && (
                                                            <TextField
                                                                label={t(
                                                                    "absencesNumber"
                                                                )}
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                value={
                                                                    values.absencesTotal
                                                                        ? values.absencesTotal
                                                                        : ""
                                                                }
                                                                name="absencesTotal"
                                                                error={
                                                                    Boolean(
                                                                        touched.absencesTotal
                                                                    ) &&
                                                                    Boolean(
                                                                        errors.absencesTotal
                                                                    )
                                                                }
                                                                helperText={
                                                                    touched.absencesTotal &&
                                                                    errors.absencesTotal
                                                                }
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                            />
                                                        )}

                                                        {isFixedSalary ===
                                                            true && (
                                                            <TextField
                                                                label={t(
                                                                    "freeDays"
                                                                )}
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                value={
                                                                    values.freeDays
                                                                        ? values.freeDays
                                                                        : ""
                                                                }
                                                                name="freeDays"
                                                                error={
                                                                    Boolean(
                                                                        touched.freeDays
                                                                    ) &&
                                                                    Boolean(
                                                                        errors.freeDays
                                                                    )
                                                                }
                                                                helperText={
                                                                    touched.freeDays &&
                                                                    errors.freeDays
                                                                }
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                            />
                                                        )}

                                                        {isFixedSalary ===
                                                            false && (
                                                            <TextField
                                                                label={t(
                                                                    "hours"
                                                                )}
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                value={
                                                                    values.numberOfWorkHours
                                                                }
                                                                name="numberOfWorkHours"
                                                                error={
                                                                    Boolean(
                                                                        touched.numberOfWorkHours
                                                                    ) &&
                                                                    Boolean(
                                                                        errors.numberOfWorkHours
                                                                    )
                                                                }
                                                                helperText={
                                                                    touched.numberOfWorkHours &&
                                                                    errors.numberOfWorkHours
                                                                }
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                            />
                                                        )}
                                                    </Box>
                                                </Box>
                                                {/* BUTTONS */}
                                                <LoadingButton
                                                    fullWidth
                                                    type="submit"
                                                    loading={
                                                        isLoadingEditWorkHourCost
                                                    }
                                                    sx={{
                                                        width: "100%",
                                                        p: "1rem",
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.main,
                                                        color: theme.palette
                                                            .neutral.white,
                                                        "&:hover": {
                                                            backgroundColor:
                                                                theme.palette
                                                                    .primary
                                                                    .light,
                                                        },
                                                    }}
                                                >
                                                    {t("editDetail")}
                                                </LoadingButton>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            </Dialog>
                            {/* DELETE WORK HOUR COST */}
                            <Dialog
                                open={openDeleteWorkHourCostDialog}
                                onClose={handleCloseDeleteWorkHourCostDialog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {t("deleteWorkHourCostConfirmationTitle")}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {t(
                                            "deleteWorkHourCostConfirmationMessage"
                                        )}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        sx={{
                                            backgroundColor:
                                                theme.palette.alt.red,
                                            color: theme.palette.neutral.white,
                                            "&:hover": {
                                                backgroundColor:
                                                    theme.palette.alt.lightRed,
                                            },
                                        }}
                                        onClick={
                                            handleCloseDeleteWorkHourCostDialog
                                        }
                                    >
                                        {t("noMessage")}
                                    </Button>
                                    <Button
                                        sx={{
                                            backgroundColor:
                                                theme.palette.primary.main,
                                            color: theme.palette.neutral.white,
                                            "&:hover": {
                                                backgroundColor:
                                                    theme.palette.primary.light,
                                            },
                                        }}
                                        onClick={handleDeleteWorkHourCost}
                                        autoFocus
                                    >
                                        {t("yesMessage")}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Box>

                        {/* BUTTONS */}
                        <Box display="flex" gap="10px">
                            {isHistoric === false ? (
                                <LoadingButton
                                    fullWidth
                                    type="button"
                                    onClick={
                                        handleOpenDeleteEmployeeWorkHourCostsDialog
                                    }
                                    sx={{
                                        width: "100%",
                                        p: "1rem",
                                        backgroundColor: theme.palette.alt.red,
                                        color: theme.palette.neutral.white,
                                        "&:hover": {
                                            backgroundColor:
                                                theme.palette.alt.lightRed,
                                        },
                                    }}
                                >
                                    {t("deleteEmployeeWorkHourCostButton")}
                                </LoadingButton>
                            ) : (
                                <LoadingButton
                                    fullWidth
                                    type="button"
                                    sx={{
                                        width: "100%",
                                        p: "1rem",
                                        backgroundColor: theme.palette.alt.red,
                                        color: theme.palette.neutral.white,
                                        "&:hover": {
                                            backgroundColor:
                                                theme.palette.alt.lightRed,
                                        },
                                    }}
                                >
                                    {t("deleteEmployeeWorkHourCostButton")}
                                </LoadingButton>
                            )}

                            <LoadingButton
                                fullWidth
                                type="submit"
                                loading={isLoadingUpdateEmployeeWorkHourCosts}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary?.light,
                                    },
                                }}
                            >
                                {t("updateEmployeeWorkHourCostButton")}
                            </LoadingButton>
                        </Box>
                    </Box>
                    {/* DELETE EMPLOYEE WORK HOUR COST */}
                    <Dialog
                        open={openDeleteEmployeeWorkHourCostsDialog}
                        onClose={handleCloseDeleteEmployeeWorkHourCostsDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("deleteEmployeeWorkHourCostConfirmationTitle")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t(
                                    "deleteEmployeeWorkHourCostConfirmationMessage"
                                )}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                                onClick={
                                    handleCloseDeleteEmployeeWorkHourCostsDialog
                                }
                            >
                                {t("noMessage")}
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                                onClick={handleDeleteEmployeeWorkHourCosts}
                                autoFocus
                            >
                                {t("yesMessage")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity={snackbarSeverity}
                            sx={{
                                mt: "10px",
                                backgroundColor:
                                    snackbarSeverity === "success"
                                        ? "#10781F"
                                        : "#ff000082",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {snackbarMsg}
                        </Alert>
                    </Snackbar>
                </form>
            )}
        </Formik>
    );
};

export default EditEmployeeWorkHourCostsForm;

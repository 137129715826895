import { apiSlice } from "app/api/apiSlice";

export const remindersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getReminders: builder.query({
            query: () => ({
                url: `Reminders/GetAll`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "Reminders", id: "LIST" }],
        }),
        postReminder: builder.mutation({
            query: (reminderData) => ({
                url: `Reminders`,
                method: "POST",
                body: {
                    ...reminderData,
                },
            }),
            invalidatesTags: [
                { type: "Reminders", id: "LIST" },
                { type: "Alerts", id: "LIST" },
            ],
        }),
        getReminderById: builder.query({
            query: (id) => ({
                url: `Reminders/${id}`,
            }),
            providesTags: (result, error, arg) => [
                { type: "Reminders", id: arg },
            ],
        }),
        updateReminder: builder.mutation({
            query: (reminderData) => ({
                url: `Reminders`,
                method: "PUT",
                body: {
                    ...reminderData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Reminders", id: "LIST" },
                { type: "Alerts", id: "LIST" },
            ],
        }),
        deleteReminderById: builder.mutation({
            query: (id) => ({
                url: `Reminders/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [
                { type: "Reminders", id: "LIST" },
                { type: "Alerts", id: "LIST" },
            ],
        }),
    }),
});

export const {
    useGetRemindersQuery,
    useLazyGetReminderByIdQuery,
    usePostReminderMutation,
    useUpdateReminderMutation,
    useDeleteReminderByIdMutation,
} = remindersApiSlice;

import React, { useState, useCallback, useRef } from "react";
import { Box, useTheme, Alert, useMediaQuery } from "@mui/material";
import { useDeleteUserByIdMutation } from "./usersApiSlice";
import Snackbar from "@mui/material/Snackbar";
import PageHeader from "components/PageHeader";
import { RESPONSES } from "config/responses";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingSpinner from "components/LoadingSpinner";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";
import { LoadingButton } from "@mui/lab";
import { Delete } from "@mui/icons-material";
import { apiSlice } from "app/api/apiSlice";
import { useDispatch } from "react-redux";
import CustomTable from "components/CustomTable";

const PAGE_SIZE_OPTIONS = [10, 20, 50];

const UsersList = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */
    const dispatch = useDispatch();

    const user = useSelector(selectCurrentUser);

    const columns = useRef([
        {
            field: "name",
            title: t("name"),
            filtering: true,
        },
        {
            field: "email",
            title: t("email"),
            filtering: true,
        },
        {
            field: "address",
            title: t("address"),
            filtering: true,
        },
        {
            field: "phoneNumber",
            title: t("phoneNumber"),
            filtering: true,
        },
        {
            field: "username",
            title: t("username"),
            filtering: true,
        },
        {
            field: "userType.type",
            title: t("userType"),
            filtering: true,
        },
    ]);

    const columnsMobile = useRef([
        {
            field: "name",
            title: t("name"),
            filtering: true,
        },
        {
            field: "email",
            title: t("email"),
            filtering: true,
        },
    ]);

    const tableRef = useRef(null);
    const handleGetUsers = useCallback(
        async (query) => {
            const limit = query.pageSize;
            const offset = query.page * query.pageSize;
            const search = query.search || null;
            const filters = query.filters
                ? JSON.stringify(
                      query.filters.map((filter) => ({
                          column: filter.column.field,
                          operator: filter.operator,
                          value: filter.value,
                      }))
                  )
                : null;
            const orderByCollection = query.orderByCollection
                ? JSON.stringify(
                      query.orderByCollection.map((orderBy) => ({
                          column: isNonMobile
                              ? columns.current[orderBy.orderBy].field
                              : columnsMobile.current[orderBy.orderBy].field,
                          orderDirection: orderBy.orderDirection,
                      }))
                  )
                : null;

            const response = await dispatch(
                apiSlice.endpoints.getUsersPaginated.initiate({
                    limit: limit || 10,
                    offset: offset || 0,
                    search,
                    filters,
                    orderByCollection,
                })
            ).unwrap();
            return {
                data: response.users,
                page: query.page,
                totalCount: response.totalUsers || 0,
            };
        },
        [isNonMobile ? columns : columnsMobile]
    );

    const [deleteUser, { isLoading: isLoadingDeleteUser }] =
        useDeleteUserByIdMutation(); /* Set the Delete cost accomodation Mutation */

    const [
        openDeleteAccomodationCostDialog,
        setOpenDeleteAccomodationCostDialog,
    ] = useState(false);

    const handleOpenDeleteAccomodationCostDialog = () => {
        setOpenDeleteAccomodationCostDialog(true);
    };

    const handleCloseDeleteAccomodationCostDialog = () => {
        setOpenDeleteAccomodationCostDialog(false);
    };

    const [idToDelete, setIdToDelete] = useState(null);
    const handleDeleteAccomodationCostOpenDialog = async (id) => {
        setIdToDelete(id);
        setOpenDeleteAccomodationCostDialog(true);
    };
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMsg(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleDeleteUser = async () => {
        try {
            if (user.id === idToDelete) {
                handleSnackbarOpen(
                    t("invalidToDeleteUserBecauseIsCurrentUser"),
                    "error"
                );
            } else {
                const { codeResult } = await deleteUser(idToDelete).unwrap();

                if (codeResult !== RESPONSES.user.successToDeleteUserById) {
                    if (codeResult !== RESPONSES.user.invalidToDeleteUserById) {
                        handleSnackbarOpen(
                            t("invalidToDeleteUserById"),
                            "error"
                        );
                    } else {
                        handleSnackbarOpen(t("errorDeleteUser"), "error");
                    }
                } else {
                    setOpenDeleteAccomodationCostDialog(false);
                    handleSnackbarOpen(t("successToDeleteUserById"), "success");
                }
            }
        } catch (err) {
            handleSnackbarOpen(t("errorDeleteUser"), "error");
        }
    };

    return (
        <Box m={!isNonMobile ? "1.5rem 1.5rem" : "1.5rem 2.5rem"}>
            {isLoadingDeleteUser ? (
                <LoadingSpinner />
            ) : (
                <>
                    <PageHeader
                        title={t("users")}
                        // subtitle={t("userList")}
                    />
                    <Box
                        mt="40px"
                        minHeight="70vh"
                        display="flex"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                                flexGrow: 1,
                                width: "100%",
                            },
                            "& .MuiDataGrid-cell": {
                                // borderBottom: "none",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: `${theme.palette.background.J} !important`,
                                color: theme.palette.secondary[100],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: theme.palette.background.A,
                            },
                            "& .MuiDataGrid-footerContainer": {
                                backgroundColor: `${theme.palette.background.J} !important`,
                                color: theme.palette.secondary[100],
                                borderTop: "none",
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${theme.palette.background.I}`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiBox-root": {
                                display: !isNonMobile ? "flex" : undefined,
                                flexDirection: !isNonMobile
                                    ? "column"
                                    : undefined,
                            },
                            "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                {
                                    backgroundColor: `${theme.palette.background.A} !important`,
                                },
                        }}
                    >
                        <CustomTable
                            tableRef={tableRef}
                            title={t("userList")}
                            pageSizeOptions={PAGE_SIZE_OPTIONS}
                            columns={
                                isNonMobile
                                    ? columns.current
                                    : columnsMobile.current
                            }
                            getData={handleGetUsers}
                            style={{
                                width: "100%",
                                padding: "0 10px 0 10px",
                                margin: "0 2% 1% 2%",
                                overflow: !isNonMobile ? "auto" : null,
                            }}
                            actions={[
                                {
                                    icon: () => <Delete />,
                                    tooltip: "Remover",
                                    isFreeAction: false,
                                    onClick: (event, rowData) => {
                                        handleDeleteAccomodationCostOpenDialog(
                                            rowData.id
                                        );
                                    },
                                },
                            ]}
                            onRefresh={() =>
                                tableRef.current &&
                                tableRef.current.onQueryChange()
                            }
                        />
                    </Box>
                    <Dialog
                        open={openDeleteAccomodationCostDialog}
                        onClose={handleCloseDeleteAccomodationCostDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("deleteUserConfirmationTitle")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t("deleteUserConfirmationMessage")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <LoadingButton
                                sx={{
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                                onClick={
                                    handleCloseDeleteAccomodationCostDialog
                                }
                            >
                                {t("noMessage")}
                            </LoadingButton>
                            <LoadingButton
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                                // isLoading={isLoadingDeleteUser}
                                onClick={handleDeleteUser}
                                autoFocus
                            >
                                {t("yesMessage")}
                            </LoadingButton>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity={snackbarSeverity}
                            sx={{
                                mt: "10px",
                                backgroundColor:
                                    snackbarSeverity === "success"
                                        ? "#10781F"
                                        : "#ff000082",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {snackbarMsg}
                        </Alert>
                    </Snackbar>
                </>
            )}
        </Box>
    );
};

export default UsersList;

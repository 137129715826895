import React, { forwardRef, useRef } from "react";
import { CustomMaterialTable } from "./styles";
import Pagination from "./Pagination";
import {
    AddBox,
    Check,
    Clear,
    DeleteOutline,
    ChevronRight,
    Edit,
    SaveAlt,
    FilterList,
    FirstPage,
    LastPage,
    Remove,
    ViewColumn,
    ChevronLeft,
    Search,
    ArrowUpward,
    Refresh,
} from "@mui/icons-material";

const TABLE_ICONS = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    Refresh: forwardRef((props, ref) => <Refresh {...props} ref={ref} />),
};

const CustomTable = ({
    key,
    title,
    tableRef,
    columns,
    getData,
    onRowAdd,
    onRowUpdate,
    onRowDelete,
    isDeleteHidden,
    options,
    pageSizeOptions,
    onRefresh,
    actions,
    onChangePage,
    _onChangePage,
    onChangeRowsPerPage,
    _onChangeRowsPerPage,
    detailPanel,
    style,
    isLoading,
    cellEditable,
    editable,
}) => {
    const standardTableRef = useRef(null);
    return (
        <CustomMaterialTable
            key={key}
            tableRef={tableRef || standardTableRef}
            title={title}
            columns={columns}
            data={getData}
            editable={{
                ...editable,
                onRowAdd,
                onRowUpdate,
                onRowDelete,
                isDeleteHidden,
            }}
            options={{
                actionsColumnIndex: -1,
                filtering: true,
                pageSize: pageSizeOptions[0],
                pageSizeOptions: pageSizeOptions || [10, 20, 50],
                paging: true,
                ...options,
            }}
            actions={[
                !!onRefresh && {
                    icon: Refresh,
                    tooltip: "Atualizar",
                    isFreeAction: true,
                    onClick: () => {
                        onRefresh();
                        return Promise.resolve();
                    },
                },
                ...(actions || []),
            ]}
            localization={{
                body: {
                    emptyDataSourceMessage: "Nenhum registro para exibir",
                    addTooltip: "Adicionar",
                    deleteTooltip: "Apagar",
                    editTooltip: "Editar",
                    editRow: {
                        deleteText: "Tem certeza que deseja apagar?",
                        saveTooltip: "Salvar",
                        cancelTooltip: "Cancelar",
                    },
                },
                toolbar: {
                    searchTooltip: "Pesquisar",
                    searchPlaceholder: "Pesquisar",
                },
                header: { actions: "Ações" },
            }}
            components={{
                Pagination: (props) => (
                    <Pagination
                        onChangePage={props.onPageChange}
                        onChangeRowsPerPage={props.onRowsPerPageChange}
                        page={props.page}
                        rowsPerPage={props.rowsPerPage}
                        rowsPerPageOptions={props.rowsPerPageOptions}
                        count={props.count}
                    />
                ),
            }}
            onPageChange={onChangePage || _onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage || _onChangeRowsPerPage}
            detailPanel={detailPanel}
            style={style}
            isLoading={isLoading}
            icons={TABLE_ICONS}
            cellEditable={cellEditable}
        />
    );
};

export default CustomTable;

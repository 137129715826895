import React, { useCallback, useRef } from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import PageHeader from "components/PageHeader";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { apiSlice } from "app/api/apiSlice";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import CustomTable from "components/CustomTable";
import { Edit } from "@mui/icons-material";

const PAGE_SIZE_OPTIONS = [10, 20, 50];

const EmployeeWorkHourCostsHistoricList = () => {
    const theme = useTheme();
    const navigate = useNavigate(); /* Set the Navigation Service */
    const location = useLocation();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const employeeWorkHourCostsColumns = useRef([
        {
            title: t("nInternal"),
            field: "employee.nInternal",
            filtering: true,
        },
        {
            title: t("name"),
            field: "employee.name",
            filtering: true,
        },
        {
            title: t("salaryType"),
            field: "employee.salaryType.type",
            filtering: true,
        },
        {
            title: t("monthOfProcessing"),
            field: "currentDate",
            filtering: true,
            render: (rowData) => {
                const currentDate = rowData.currentDate;
                return currentDate
                    ? dayjs(currentDate).format("YYYY-MM-DD")
                    : "";
            },
        },
    ]);

    const employeeWorkHourCostsColumnsMobile = useRef([
        {
            title: t("name"),
            field: "employee.name",
            filtering: true,
        },
        {
            title: t("monthOfProcessing"),
            field: "currentDate",
            filtering: true,
            render: (rowData) => {
                const currentDate = rowData.currentDate;
                return currentDate
                    ? dayjs(currentDate).format("YYYY-MM-DD")
                    : "";
            },
            hidden: true,
        },
    ]);

    const tableRef = useRef(null);
    const handleGetEmployeeWorkHourCosts = useCallback(
        async (query) => {
            const limit = query.pageSize;
            const offset = query.page * query.pageSize;
            const search = query.search || null;
            const filters = query.filters
                ? JSON.stringify(
                      query.filters.map((filter) => ({
                          column: filter.column.field,
                          operator: filter.operator,
                          value: filter.value,
                      }))
                  )
                : null;
            const isHistoric = true;
            const orderByCollection = query.orderByCollection
                ? JSON.stringify(
                      query.orderByCollection.map((orderBy) => ({
                          column: isNonMobile
                              ? employeeWorkHourCostsColumns.current[
                                    orderBy.orderBy
                                ].field
                              : employeeWorkHourCostsColumnsMobile.current[
                                    orderBy.orderBy
                                ].field,
                          orderDirection: orderBy.orderDirection,
                      }))
                  )
                : null;
            const response = await dispatch(
                apiSlice.endpoints.getEmployeeWorkHourCostsPaginated.initiate({
                    limit: limit || 10,
                    offset: offset || 0,
                    search,
                    filters,
                    orderByCollection,
                    isHistoric,
                })
            ).unwrap();
            return {
                data: response.employeeWorkHourCosts,
                page: query.page,
                totalCount: response.totalEmployeeWorkHourCosts || 0,
            };
        },
        [
            isNonMobile
                ? employeeWorkHourCostsColumns
                : employeeWorkHourCostsColumnsMobile,
        ]
    );

    return (
        <Box m={!isNonMobile ? "1.5rem 1.5rem" : "1.5rem 2.5rem"}>
            <>
                <PageHeader
                    title={t("processing")}
                    // subtitle={t("processingHistory")}
                />
                <Box
                    mt="40px"
                    minHeight="70vh"
                    display="flex"
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                            flexGrow: 1,
                            width: "100%",
                        },
                        "& .MuiDataGrid-cell": {
                            // borderBottom: "none",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: `${theme.palette.background.J} !important`,
                            color: theme.palette.secondary[100],
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: theme.palette.background.A,
                        },
                        "& .MuiDataGrid-footerContainer": {
                            backgroundColor: `${theme.palette.background.J} !important`,
                            color: theme.palette.secondary[100],
                            borderTop: "none",
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${theme.palette.background.I}`,
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiBox-root": {
                            display: !isNonMobile ? "flex" : undefined,
                            flexDirection: !isNonMobile ? "row" : undefined,
                        },
                        "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                            {
                                backgroundColor: `${theme.palette.background.A} !important`,
                            },
                    }}
                >
                    <CustomTable
                        tableRef={tableRef}
                        title={t("processingHistory")}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        columns={
                            isNonMobile
                                ? employeeWorkHourCostsColumns.current
                                : employeeWorkHourCostsColumnsMobile.current
                        }
                        getData={handleGetEmployeeWorkHourCosts}
                        style={{
                            width: "100%",
                            padding: "0 10px 0 10px",
                            margin: "0% 2% 1% 2%",
                            overflow: !isNonMobile ? "auto" : null,
                        }}
                        actions={[
                            {
                                icon: () => <Edit />,
                                tooltip: "Editar",
                                isFreeAction: false,
                                onClick: (event, rowData) => {
                                    navigate(
                                        `${location.pathname}/Historic/${rowData.id}-1`
                                    );
                                    return Promise.resolve();
                                },
                            },
                        ]}
                        onRefresh={() =>
                            tableRef.current && tableRef.current.onQueryChange()
                        }
                    />
                </Box>
            </>
        </Box>
    );
};

export default EmployeeWorkHourCostsHistoricList;

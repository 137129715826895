import { apiSlice } from "app/api/apiSlice";

export const clientsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getClientsPaginated: builder.query({
            query: (query) => {
                const params = [];
                params.push(`limit=${(query && query.limit) || 10}`);
                params.push(`offset=${(query && query.offset) || 0}`);
                if (query && query.search)
                    params.push(
                        `search=${encodeURIComponent(query && query.search)}`
                    );
                if (query && query.filters)
                    params.push(
                        `filters=${encodeURIComponent(
                            JSON.stringify(query && query.filters)
                        )}`
                    );
                if (query && query.orderByCollection)
                    params.push(
                        `orderByCollection=${encodeURIComponent(
                            JSON.stringify(query && query.orderByCollection)
                        )}`
                    );
                const queryString =
                    params.length > 0 ? `?${params.join("&")}` : "";
                return {
                    url: `Clients/GetAllPaginated${queryString.toString()}`,
                    validateStatus: (response, result) => {
                        return response.status === 200 && !result.isError;
                    },
                    query,
                };
            },
            providesTags: [{ type: "Clients", id: "LIST" }],
        }),
        getClients: builder.query({
            query: () => ({
                url: `Clients/GetAll`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "Clients", id: "LIST" }],
        }),
        getClientById: builder.query({
            query: (id) => ({
                url: `Clients/${id}`,
            }),
            providesTags: (result, error, arg) => [
                { type: "Clients", id: arg },
            ],
        }),
        postClient: builder.mutation({
            query: (clientData) => ({
                url: `Clients`,
                method: "POST",
                body: {
                    ...clientData,
                },
            }),
            invalidatesTags: [
                { type: "Clients", id: "LIST" },
                { type: "Contacts", id: "LIST" },
            ],
        }),
        updateClient: builder.mutation({
            query: (clientData) => ({
                url: `Clients`,
                method: "PUT",
                body: {
                    ...clientData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Clients", id: arg.id },
                { type: "Clients", id: "LIST" },
                { type: "Contacts", id: "LIST" },
                { type: "WorkById", id: "BYID" },
                { type: "Works", id: "LIST" },
            ],
        }),
        deleteClientById: builder.mutation({
            query: (id) => ({
                url: `Clients/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [
                { type: "Clients", id: "LIST" },
                { type: "Contacts", id: "LIST" },
            ],
        }),
    }),
});

export const {
    useGetClientsQuery,
    useGetClientByIdQuery,
    usePostClientMutation,
    useUpdateClientMutation,
    useDeleteClientByIdMutation,
    useDeleteImageMutation,
    useUploadImageMutation,
} = clientsApiSlice;

import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    useGetUserByIdQuery,
    useUpdateUserMutation,
    useUpdatePasswordMutation,
} from "./usersApiSlice";
import PageHeader from "components/PageHeader";
import LoadingSpinner from "components/LoadingSpinner";
import {
    Box,
    TextField,
    Typography,
    useTheme,
    Alert,
    useMediaQuery,
    IconButton,
    Card,
    CardContent,
} from "@mui/material";
import { DeleteOutlined } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import { Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";
import FlexBetween from "components/FlexBetween";
import { useParams } from "react-router-dom";
import Dropzone from "react-dropzone";
import { setUser } from "../auth/authSlice";

const EditUserForm = () => {
    // #region Setups

    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch(); /* Set the Dispatch Service */
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const nameRef = useRef(); /* User Ref to set the focus to the user input */

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMsg(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const [file, setFile] = useState(null); /* Set the Files State */
    const [deleteFile, setDeleteFile] = useState(false);

    //#endregion Setups

    // #region User

    const { id } = useParams(); /* Get the Client Id from the route Params */

    const { data: user, isLoading: isLoadingGetUser } =
        useGetUserByIdQuery(id); /* Set the Get User by Id Mutation */

    useEffect(() => {
        if (user) {
            const blob = new Blob([user.photo]);
            const file = new File([blob], "", {
                lastModified: new Date().getTime(),
            });
            Object.assign(file, { preview: user.photo });

            setFile(file);
        }
    }, [user]);

    const [updateUser, { isLoading: isLoadingUpdateUser }] =
        useUpdateUserMutation(); /* Set the Update User Mutation */

    const [updatePassword, { isLoading: isLoadingUpdatePassword }] =
        useUpdatePasswordMutation(); /* Set the Update Password Mutation */

    const userSchema = yup.object().shape({
        name: yup.string().required(t("requiredField")),
        email: yup
            .string()
            .email(t("invalidEmail"))
            .required(t("requiredField")),
        address: yup.string().required(t("requiredField")),
        phoneNumber: yup.string().required(t("requiredField")),
        username: yup.string().required(t("requiredField")),
    }); /* Set the User Schema */

    const changePasswordSchema = yup.object().shape({
        oldPassword: yup.string().required(t("requiredField")),
        password: yup.string().required(t("requiredField")),
    }); /* Set the Change Password Schema */

    const changePassword = {
        oldPassword: "",
        password: "",
    };

    const initialValuesUser = {
        name: "",
        email: "",
        address: "",
        phoneNumber: "",
        username: "",
    }; /* Set the Form Initial Values */

    //#endregion Client

    const handleRemoveFile = (event, fileToDelete) => {
        event.stopPropagation();
        setFile(null);
        setDeleteFile(false);
    };

    const onDropFiles = (acceptedFiles) => {
        setDeleteFile(false);
        setFile(
            acceptedFiles.map((file) =>
                Object.assign(file, { preview: URL.createObjectURL(file) })
            )[0]
        );
    };

    const convertToBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handleFormSubmit = async (values, onSubmitProps) => {
        try {
            let updatedUser = { ...values };
            if (file && deleteFile === false) {
                if (file.name !== "") {
                    updatedUser = {
                        ...values,
                        photo: await convertToBase64(file),
                    };
                } else {
                    updatedUser = {
                        ...values,
                        photo: file.preview,
                    };
                }
            } else {
                updatedUser = {
                    ...values,
                    photo: null,
                };
            }
            const { codeResult } = await updateUser(updatedUser).unwrap();
            if (codeResult !== RESPONSES.user.successToUpdateUser) {
                if (codeResult === RESPONSES.user.invalidUserToUpdate) {
                    handleSnackbarOpen(t("invalidUserToUpdate"), "error");
                } else if (codeResult === RESPONSES.user.invalidUser) {
                    handleSnackbarOpen(t("invalidUser"), "error");
                } else {
                    handleSnackbarOpen(t("errorUpdateUser"), "error");
                }
            } else {
                dispatch(setUser(updatedUser));
                handleSnackbarOpen(t("successToUpdateUser"), "success");
            }
        } catch (err) {
            handleSnackbarOpen(t("errorUpdateUser"), "error");
        }
    };

    const handleFormSubmitPassword = async (values, onSubmitProps) => {
        try {
            let updatedUser = {
                Id: id,
                Email: user.email,
                OldPassword: values.oldPassword,
                Password: values.password,
            };

            const { codeResult } = await updatePassword(updatedUser).unwrap();

            if (codeResult !== RESPONSES.user.sucessToUpdatePassword) {
                if (codeResult === RESPONSES.user.invalidToUpdatePassword) {
                    handleSnackbarOpen(t("invalidToUpdatePassword"), "error");
                } else if (codeResult === RESPONSES.user.invalidUserToUpdate) {
                    handleSnackbarOpen(t("invalidUserToUpdate"), "error");
                } else {
                    handleSnackbarOpen(t("errorUpdatePassword"), "error");
                }
            } else {
                handleSnackbarOpen(t("sucessToUpdatePassword"), "success");
                onSubmitProps.resetForm();
            }
        } catch (err) {
            handleSnackbarOpen(t("errorUpdatePassword"), "error");
        }
    };

    return (
        <Box width="100%">
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={user || initialValuesUser}
                validationSchema={userSchema}
                enableReinitialize={true}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    resetForm,
                }) => (
                    <form onSubmit={handleSubmit}>
                        {isLoadingGetUser || isLoadingUpdateUser ? (
                            <LoadingSpinner />
                        ) : null}
                        <Box
                            m={!isNonMobile ? "0" : "0.5rem 2.5rem"}
                            display={!isNonMobile ? "flex" : "grid"}
                            flexDirection={!isNonMobile ? "column" : undefined}
                            gridTemplateRows={
                                isNonMobile ? "repeat(4, auto)" : undefined
                            }
                            // gridTemplateRows="repeat(4, auto)"
                            gap="20px"
                            borderRadius="5px"
                            p="2rem 2rem 0rem 2rem"
                        >
                            <PageHeader title={values.name} subtitle="" />
                            <Box
                                display="flex"
                                gap="20px"
                                flexDirection="column"
                                width="100%"
                            >
                                <Typography
                                    fontWeight="500"
                                    fontSize="20px"
                                    color="primary"
                                    variant="h5"
                                    textAlign="left"
                                >
                                    {t("userInformation")}
                                </Typography>
                                <Box
                                    display="grid"
                                    gridTemplateColumns={
                                        !isNonMobile
                                            ? "repeat(1, 1fr)"
                                            : "repeat(2, 1fr)"
                                    }
                                    gap="10px"
                                >
                                    <TextField
                                        variant="outlined"
                                        label={t("name")}
                                        inputRef={nameRef}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.name}
                                        name="name"
                                        error={
                                            Boolean(touched.name) &&
                                            Boolean(errors.name)
                                        }
                                        helperText={touched.name && errors.name}
                                        sx={{ width: "100%" }}
                                    />
                                    <TextField
                                        label={t("email")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                        name="email"
                                        error={
                                            Boolean(touched.email) &&
                                            Boolean(errors.email)
                                        }
                                        helperText={
                                            touched.email && errors.email
                                        }
                                        sx={{ width: "100%" }}
                                    />
                                </Box>
                                <Box
                                    display="grid"
                                    gridTemplateColumns={
                                        !isNonMobile
                                            ? "repeat(1, 1fr)"
                                            : "repeat(3, 1fr)"
                                    }
                                    gap="10px"
                                >
                                    <TextField
                                        label={t("address")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.address}
                                        name="address"
                                        error={
                                            Boolean(touched.address) &&
                                            Boolean(errors.address)
                                        }
                                        helperText={
                                            touched.address && errors.address
                                        }
                                        sx={{ width: "100%" }}
                                    />
                                    <TextField
                                        label={t("phoneNumber")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.phoneNumber}
                                        name="phoneNumber"
                                        error={
                                            Boolean(touched.phoneNumber) &&
                                            Boolean(errors.phoneNumber)
                                        }
                                        helperText={
                                            touched.phoneNumber &&
                                            errors.phoneNumber
                                        }
                                        sx={{ width: "100%" }}
                                    />
                                    <TextField
                                        label={t("username")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.username}
                                        name="username"
                                        error={
                                            Boolean(touched.username) &&
                                            Boolean(errors.username)
                                        }
                                        helperText={
                                            touched.username && errors.username
                                        }
                                        sx={{ width: "100%" }}
                                    />
                                </Box>
                                <Dropzone
                                    multiple={false}
                                    onDrop={onDropFiles}
                                    acceptedFiles=".jpg,.jpeg,.png"
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <FlexBetween>
                                            <Box
                                                {...getRootProps()}
                                                border={`2px dashed ${theme.palette.primary.main}`}
                                                p="1rem"
                                                width="100%"
                                                display="flex"
                                                gap="10px"
                                                borderRadius="5px"
                                                sx={{
                                                    "&:hover": {
                                                        cursor: "pointer",
                                                    },
                                                }}
                                            >
                                                <input
                                                    {...getInputProps()}
                                                    type="file"
                                                    accept="image/png"
                                                />
                                                {file === null ||
                                                file.preview === null ? (
                                                    <p>
                                                        {t(
                                                            "dropzoneProfilePicture"
                                                        )}
                                                    </p>
                                                ) : (
                                                    <Card
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                        }}
                                                        sx={{
                                                            backgroundColor:
                                                                theme.palette
                                                                    .background
                                                                    .D,
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            gap: "10px",
                                                            p: "15px 10px 15px 15px",
                                                            "& .MuiCardContent-root:last-child":
                                                                {
                                                                    paddingBottom:
                                                                        "20px",
                                                                },
                                                        }}
                                                    >
                                                        <CardContent
                                                            sx={{
                                                                p: "unset",
                                                            }}
                                                        >
                                                            <Box
                                                                component="img"
                                                                alt="photo"
                                                                src={
                                                                    file.preview
                                                                }
                                                                height={
                                                                    !isNonMobile
                                                                        ? "100%"
                                                                        : "400px"
                                                                }
                                                                width={
                                                                    !isNonMobile
                                                                        ? "100%"
                                                                        : "400px"
                                                                }
                                                                borderRadius="50%"
                                                                sx={{
                                                                    objectFit:
                                                                        "cover",
                                                                }}
                                                            />
                                                        </CardContent>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                pl: 1,
                                                                pb: 1,
                                                            }}
                                                        >
                                                            <IconButton
                                                                onClick={(
                                                                    event
                                                                ) =>
                                                                    handleRemoveFile(
                                                                        event,
                                                                        file
                                                                    )
                                                                }
                                                            >
                                                                <DeleteOutlined />
                                                            </IconButton>
                                                        </Box>
                                                    </Card>
                                                )}
                                            </Box>
                                        </FlexBetween>
                                    )}
                                </Dropzone>
                            </Box>
                            {/* BUTTONS */}
                            <LoadingButton
                                fullWidth
                                type="submit"
                                loading={isLoadingUpdateUser}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                            >
                                {t("updateUserButton")}
                            </LoadingButton>
                        </Box>
                        <Snackbar
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            open={openSnackbar}
                            autoHideDuration={6000}
                            onClose={handleCloseSnackBar}
                        >
                            <Alert
                                onClose={handleCloseSnackBar}
                                severity={snackbarSeverity}
                                sx={{
                                    mt: "10px",
                                    backgroundColor:
                                        snackbarSeverity === "success"
                                            ? "#10781F"
                                            : "#ff000082",
                                    alignItems: "center",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    color: "#fff",
                                }}
                            >
                                {snackbarMsg}
                            </Alert>
                        </Snackbar>
                    </form>
                )}
            </Formik>
            <Formik
                onSubmit={handleFormSubmitPassword}
                initialValues={changePassword}
                validationSchema={changePasswordSchema}
                enableReinitialize={true}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    resetForm,
                }) => (
                    <form onSubmit={handleSubmit}>
                        {isLoadingGetUser || isLoadingUpdatePassword ? (
                            <LoadingSpinner />
                        ) : null}
                        <Box
                            m={!isNonMobile ? "0" : "0.5rem 2.5rem"}
                            display="grid"
                            gridTemplateRows="repeat(4, auto)"
                            gap="20px"
                            borderRadius="5px"
                            p="2rem 2rem 0rem 2rem"
                        >
                            <Box
                                display="flex"
                                gap="20px"
                                flexDirection="column"
                                width="100%"
                            >
                                <Typography
                                    fontWeight="500"
                                    fontSize="20px"
                                    color="primary"
                                    variant="h5"
                                    textAlign="left"
                                >
                                    {t("changePassword")}
                                </Typography>
                                <Box
                                    display="grid"
                                    gridTemplateColumns={
                                        !isNonMobile
                                            ? "repeat(1, 1fr)"
                                            : "repeat(2, 1fr)"
                                    }
                                    gap="10px"
                                >
                                    <TextField
                                        variant="outlined"
                                        label={t("actualPassword")}
                                        type="password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.oldPassword}
                                        name="oldPassword"
                                        sx={{ width: "100%" }}
                                    />
                                    <TextField
                                        label={t("newPassword")}
                                        type="password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.password}
                                        name="password"
                                        sx={{ width: "100%" }}
                                    />
                                </Box>
                            </Box>
                            {/* BUTTONS */}
                            <LoadingButton
                                fullWidth
                                type="submit"
                                loading={isLoadingUpdatePassword}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                            >
                                {t("changePasswordUserButton")}
                            </LoadingButton>
                        </Box>
                        <Snackbar
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            open={openSnackbar}
                            autoHideDuration={6000}
                            onClose={handleCloseSnackBar}
                        >
                            <Alert
                                onClose={handleCloseSnackBar}
                                severity={snackbarSeverity}
                                sx={{
                                    mt: "10px",
                                    backgroundColor:
                                        snackbarSeverity === "success"
                                            ? "#10781F"
                                            : "#ff000082",
                                    alignItems: "center",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    color: "#fff",
                                }}
                            >
                                {snackbarMsg}
                            </Alert>
                        </Snackbar>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default EditUserForm;

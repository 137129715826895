import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    useTheme,
} from "@mui/material";
import {
    ChevronLeft,
    ChevronRightOutlined,
    HomeOutlined,
    Groups3Outlined,
    BadgeOutlined,
    PersonOutline,
    DirectionsCarOutlined,
} from "@mui/icons-material";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import ReadMoreOutlinedIcon from "@mui/icons-material/ReadMoreOutlined";
import HistoryIcon from "@mui/icons-material/History";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import { useNavigate } from "react-router-dom";
import ConstructionIcon from "@mui/icons-material/Construction";
import FlexBetween from "./FlexBetween";
import useAuth from "hooks/useAuth";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import logo from "img/logoJRP.png";
import { useTranslation } from "react-i18next";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";

const Sidebar = ({
    drawerWidth,
    isSidebarOpen,
    setIsSidebarOpen,
    isNonMobile,
}) => {
    const mode = useSelector((state) => state.global.mode);
    // const { pathname } = useLocation();
    const [active, setActive] = useState("");
    const navigate = useNavigate();
    const theme = useTheme();
    const { t } = useTranslation();

    const navItems = [
        {
            text: t("dashboard"),
            icon: <HomeOutlined />,
        },
        {
            text: t("business"),
            icon: null,
        },
        {
            text: t("users"),
            icon: <PersonOutline />,
        },
        {
            text: t("clients"),
            icon: <Groups3Outlined />,
        },
        {
            text: t("subContractors"),
            icon: <PersonAddAltOutlinedIcon />,
        },
        {
            text: t("categories"),
            icon: <CategoryOutlinedIcon />,
        },
        {
            text: t("employees"),
            icon: <BadgeOutlined />,
        },
        {
            text: t("applications"),
            icon: <LibraryBooksIcon />,
        },
        {
            text: t("vehicles"),
            icon: <DirectionsCarOutlined />,
        },
        {
            text: t("accomodations"),
            icon: <HotelOutlinedIcon />,
        },
        {
            text: t("suppliers"),
            icon: <LocalShippingOutlinedIcon />,
        },
        {
            text: t("works"),
            icon: <HomeWorkOutlinedIcon />,
        },
        {
            text: t("costCategories"),
            icon: <AddCardOutlinedIcon />,
        },
        {
            text: t("equipments"),
            icon: <ConstructionIcon />,
        },
        {
            text: t("documents"),
            icon: <DifferenceOutlinedIcon />,
        },
        {
            text: t("contacts"),
            icon: <ContactPageOutlinedIcon />,
        },
        {
            text: t("planning"),
            icon: null,
        },
        {
            text: t("workPlannings"),
            icon: <EditNoteOutlinedIcon />,
        },
        {
            text: t("payrollProcessing"),
            icon: null,
        },
        {
            text: t("advancePayments"),
            icon: <ReadMoreOutlinedIcon />,
        },
        {
            text: t("adjustments"),
            icon: <CalculateOutlinedIcon />,
        },
        {
            text: t("processing"),
            icon: <PaymentOutlinedIcon />,
        },
        {
            text: t("processingHistory"),
            icon: <HistoryIcon />,
        },
        {
            text: t("costs"),
            icon: null,
        },
        {
            text: t("employeeCosts"),
            icon: <SsidChartIcon />,
        },
        {
            text: t("employeeCostHistory"),
            icon: <HistoryIcon />,
        },
        {
            text: t("internalRegists"),
            icon: <BadgeOutlined />,
        },
    ];

    // useEffect(() => {
    //     setActive(pathname.substring(1).split("/")[0]);
    // }, [pathname]);

    const { userType } = useAuth();

    const styles = {
        drawerPaper: {
            // Estilos do Drawer-paper
            color: theme.palette.secondary[200],
            backgroundColor: "#FFFFFF",
            boxSizing: "border-box",
            borderWidth: isNonMobile ? "1px" : "2px",
            width: drawerWidth,
            justifyContent: "space-between",
            flexShrink: 0,
            boxShadow: "8px 12px 15px rgba(0, 0, 0, 0.1)",
            // Adicione estilos para a barra de rolagem aqui
            scrollbarWidth: "none",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
                width: "12px",
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "lightgray",
                borderRadius: "6px",
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: "lightgray",
            },
        },
    };

    return (
        <Drawer
            open={isSidebarOpen}
            onClose={() => setIsSidebarOpen(false)}
            variant="persistent"
            anchor="left"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    // Estilos do Drawer-paper
                    ...styles.drawerPaper,
                },
            }}
        >
            <Box width="100%">
                <Box
                    height="80px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    // sx={{ backgroundColor: theme.palette.background.C }}
                    sx={{
                        backgroundColor: "#FFFFFF",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                >
                    <FlexBetween color={theme.palette.background.C}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            gap="0.5rem"
                        >
                            <Box
                                component="img"
                                alt="profile"
                                src={logo}
                                height="50%"
                                alignSelf="center"
                                width="65%"
                                sx={{ objectFit: "cover" }}
                            />
                            {/* <Typography
                                variant="h4"
                                fontWeight="bold"
                                fontSize="27px"
                                // color={theme.palette.primary.main}
                                color="#0071BB"
                                fontFamily="coursive"
                                borderBottom="1px solid rgba(0, 0, 0, 0.12)"
                            >
                                JRP Construções
                            </Typography> */}
                        </Box>

                        {!isNonMobile && (
                            <IconButton
                                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                            >
                                <ChevronLeft />
                            </IconButton>
                        )}
                    </FlexBetween>
                </Box>
                <List>
                    {navItems.map(({ text, icon }) => {
                        if (
                            (text === t("users") || text === t("dashboard")) &&
                            userType !== "ADMIN"
                        ) {
                        } else if (text === t("dashboard") && !isNonMobile) {
                        } else {
                            if (!icon) {
                                return (
                                    <Typography
                                        variant="h5"
                                        fontWeight="bold"
                                        key={text}
                                        sx={{ m: "1.5rem 0 1rem 2rem" }}
                                    >
                                        {text}
                                    </Typography>
                                );
                            }
                            const lcText = text.toLowerCase();

                            return (
                                <ListItem key={text} disablePadding>
                                    <ListItemButton
                                        onClick={() => {
                                            navigate(`/${lcText}`);
                                            setActive(lcText);
                                            if (!isNonMobile) {
                                                setIsSidebarOpen(false);
                                            }
                                        }}
                                        sx={{
                                            backgroundColor:
                                                active === lcText
                                                    ? theme.palette.background.D
                                                    : "transparent",
                                            color:
                                                active === lcText
                                                    ? theme.palette.primary[600]
                                                    : theme.palette
                                                          .secondary[100],
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                ml: "1rem",
                                                minWidth: "40px",
                                                color:
                                                    active === lcText
                                                        ? theme.palette
                                                              .primary[600]
                                                        : theme.palette
                                                              .secondary[200],
                                            }}
                                        >
                                            {icon}
                                        </ListItemIcon>
                                        <ListItemText primary={text} />
                                        {active === lcText && (
                                            <ChevronRightOutlined
                                                sx={{ ml: "auto" }}
                                            />
                                        )}
                                    </ListItemButton>
                                </ListItem>
                            );
                        }
                    })}
                </List>
            </Box>
        </Drawer>
    );
};

export default Sidebar;

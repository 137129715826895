import { useState, useRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import { useForgotPasswordMutation } from "./authApiSlice";
import {
    Box,
    TextField,
    useMediaQuery,
    Typography,
    useTheme,
    Alert,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";
import usePersist from "hooks/usePersist";

const ForgotPasswordForm = ({ toggleForm }) => {
    const { palette } = useTheme(); /* Set the Theme Pallet */
    const { t } = useTranslation(); /* Set the Translation Service */
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */
    const [persist, setPersist, language, setLanguage] = usePersist();

    const forgotPasswordSchema = yup.object().shape({
        email: yup
            .string()
            .email(t("invalidEmail"))
            .required(t("requiredField")),
    }); /* Set the Forgot Password Schema */

    const initialValuesForgotPassword = {
        email: "",
    }; /* Set the Form Initial Values */

    const userRef = useRef(); /* User Ref to set the focus to the user input */
    const [errMsg, setErrMsg] = useState(""); /* Set the Error Message State */
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    };
    const handleFormSubmit = async (values, onSubmitProps) => {
        try {
            let forgotPasswordRequest = {
                email: values.email,
                language: language,
            };

            const { codeResult } = await forgotPassword(
                forgotPasswordRequest
            ).unwrap();
            if (codeResult !== RESPONSES.login.newPasswordSent) {
                if (codeResult === RESPONSES.login.incorrectEmail) {
                    setErrMsg(t("incorrectEmail"));
                } else {
                    setErrMsg(t("anErrorOccurred"));
                }
            } else {
                setErrMsg("");
                setOpenSnackBar(true);
                onSubmitProps.resetForm();
            }
        } catch (err) {
            setErrMsg(err.data?.message || "Unknown error");
        }
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesForgotPassword}
            validationSchema={forgotPasswordSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        display="flex"
                        gap="30px"
                        flexDirection="column"
                        sx={{
                            "& > div": {
                                gridColumn: isNonMobile ? undefined : "span 4",
                            },
                        }}
                    >
                        <TextField
                            variant="outlined"
                            label={t("email")}
                            inputRef={userRef}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            name="email"
                            error={
                                Boolean(touched.email) && Boolean(errors.email)
                            }
                            helperText={touched.email && errors.email}
                            sx={{ gridColumn: "span 4" }}
                        />
                    </Box>
                    {errMsg && (
                        <Alert
                            severity="error"
                            sx={{
                                mt: "10px",
                                backgroundColor: "#540e0e",
                                color: "#FFFFFF",
                                alignItems: "center",
                            }}
                        >
                            {errMsg}
                        </Alert>
                    )}
                    {/* BUTTONS */}
                    <Box>
                        <LoadingButton
                            fullWidth
                            type="submit"
                            loading={isLoading}
                            sx={{
                                m: "2rem 0",
                                p: "1rem",
                                backgroundColor: palette.primary.main,
                                color: palette.neutral.white,
                                "&:hover": { color: palette.primary.main },
                            }}
                        >
                            {t("sendButton")}
                        </LoadingButton>
                        <Typography
                            onClick={() => {
                                toggleForm();
                                resetForm();
                            }}
                            sx={{
                                textDecoration: "underline",
                                color: palette.primary.main,
                                "&:hover": {
                                    cursor: "pointer",
                                    color: palette.primary.light,
                                },
                            }}
                        >
                            {t("loginButton")}
                        </Typography>
                    </Box>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity="success"
                            sx={{
                                mt: "10px",
                                backgroundColor: "#10781F",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {t("newPasswordSent")}
                        </Alert>
                    </Snackbar>
                </form>
            )}
        </Formik>
    );
};
export default ForgotPasswordForm;

import { useState, useRef, useEffect } from "react";
import PageHeader from "components/PageHeader";
import LoadingSpinner from "components/LoadingSpinner";
import {
    Box,
    Button,
    TextField,
    Typography,
    useTheme,
    Alert,
    IconButton,
    useMediaQuery,
    Card,
    CardContent,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DeleteOutlined } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";
import FlexBetween from "components/FlexBetween";
import { useParams } from "react-router-dom";
import Dropzone from "react-dropzone";
import {
    useGetSupplierByIdQuery,
    useUpdateSupplierMutation,
    useDeleteSupplierByIdMutation,
    useDeleteSupplierContactMutation,
    useAddSupplierContactMutation,
    useEditSupplierContactMutation,
} from "./suppliersApiSlice";
import DataGridCustomWithoutToolbar from "components/DataGridCustomWithoutToolbar";
import {
    useDeleteAttachmentMutation,
    useUploadAttachmentMutation,
} from "features/attachments/attachmentsApiSlice";

const EditSupplierForm = () => {
    // #region Setups

    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate(); /* Set the Navigation Service */
    const location = useLocation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const nameRef = useRef(); /* User Ref to set the focus to the user input */

    const [files, setFiles] = useState([]);
    const [supplierWorks, setSupplierWorks] = useState([]);
    const [supplierContacts, setSupplierContacts] = useState([]);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMsg(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
        useState(false);

    const handleOpenDeleteConfirmationDialog = () => {
        setOpenDeleteConfirmationDialog(true);
    };

    const handleCloseDeleteConfirmationDialog = () => {
        setOpenDeleteConfirmationDialog(false);
    };

    const [
        openCreateSupplierContactDialog,
        setOpenCreateSupplierContactDialog,
    ] = useState(false);

    const handleOpenCreateSupplierContactDialog = () => {
        setOpenCreateSupplierContactDialog(true);
    };

    const handleCloseCreateSupplierContactDialog = () => {
        setOpenCreateSupplierContactDialog(false);
    };

    const [openEditSupplierContactDialog, setOpenEditSupplierContactDialog] =
        useState(false);

    const handleOpenEditSupplierContactDialog = () => {
        setOpenEditSupplierContactDialog(true);
    };

    const handleCloseEditSupplierContactDialog = () => {
        setOpenEditSupplierContactDialog(false);
    };

    const [
        openDeleteSupplierContactDialog,
        setOpenDeleteSupplierContactDialog,
    ] = useState(false);

    const handleOpenDeleteSupplierContactDialog = () => {
        setOpenDeleteSupplierContactDialog(true);
    };

    const handleCloseDeleteSupplierContactDialog = () => {
        setOpenDeleteSupplierContactDialog(false);
    };

    //#endregion Setups

    // #region Supplier Works

    const supplierWorksColumns = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
        },
        {
            field: "address",
            headerName: t("address"),
            flex: 1,
        },
    ];

    // #endregion

    // # region Supplier Contacts
    const supplierContactsColumns = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
        },
        {
            field: "email",
            headerName: t("email"),
            flex: 1,
        },
        {
            field: "position",
            headerName: t("position"),
            flex: 1,
        },
        {
            field: "phoneNumber",
            headerName: t("phoneNumber"),
            flex: 1,
        },
        {
            field: "action",
            headerName: t("edit"),
            flex: 1,
            renderCell: (row) => (
                <EditIcon onClick={() => handleEditSupplierContact(row)} />
            ),
        },
        {
            field: "actions",
            headerName: t("remove"),
            flex: 1,
            renderCell: (row) => (
                <DeleteIcon
                    onClick={() =>
                        handleDeleteSupplierContactOpenDialog(row.id)
                    }
                />
            ),
        },
    ];

    const supplierContactsColumnsMobile = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
        },
        {
            field: "phoneNumber",
            headerName: t("phoneNumber"),
            flex: 1,
        },
        {
            field: "action",
            headerName: t("edit"),
            flex: 1,
            renderCell: (row) => (
                <EditIcon onClick={() => handleEditSupplierContact(row)} />
            ),
        },
        {
            field: "actions",
            headerName: t("remove"),
            flex: 1,
            renderCell: (row) => (
                <DeleteIcon
                    onClick={() =>
                        handleDeleteSupplierContactOpenDialog(row.id)
                    }
                />
            ),
        },
    ];

    // #endregion

    // #region Supplier

    const { id } = useParams();

    const { data: supplier, isLoading: isLoadingGetSupplier } =
        useGetSupplierByIdQuery(id);

    const [updateSupplier, { isLoading: isLoadingUpdateSupplier }] =
        useUpdateSupplierMutation();

    const [deleteSupplier, { isLoading: isLoadingDeleteSupplier }] =
        useDeleteSupplierByIdMutation();

    const [addSupplierContact, { isLoading: isLoadingAddSupplierContact }] =
        useAddSupplierContactMutation();

    const [editSupplierContact, { isLoading: isLoadingEditSupplierContact }] =
        useEditSupplierContactMutation();

    const [deleteSupplierContact] = useDeleteSupplierContactMutation();

    useEffect(() => {
        if (supplier) {
            setSupplierWorks(supplier.works);
            setSupplierContacts(supplier.contacts);

            const processAttachments = async () => {
                const attachmentPromises = supplier.attachments.map(
                    async (attachment) => {
                        let file = "";
                        if (!attachment.preSignedUrl) {
                            file = await base64ToFile(
                                attachment.content,
                                attachment.fileName,
                                attachment.fileType
                            );
                        }
                        return { ...attachment, file };
                    }
                );

                try {
                    const attachments = await Promise.all(attachmentPromises);
                    setFiles(
                        attachments.filter(
                            (attachment) => !!attachment.fileName.split("/")[2]
                        )
                    );
                } catch (error) {
                    console.error("Error processing attachments:", error);
                }
            };

            processAttachments();
        }
    }, [supplier]);

    function base64ToFile(base64Data, fileName, mimeType) {
        let base64Content = null;
        if (base64Data.split(",").length > 1) {
            base64Content = base64Data.split(",")[1];
        } else {
            base64Content = base64Data;
        }

        const uint8Array = new Uint8Array(
            atob(base64Content)
                .split("")
                .map((char) => char.charCodeAt(0))
        );

        const blob = new Blob([uint8Array], { type: mimeType });

        const file = new File([blob], fileName, { type: mimeType });

        return file;
    }

    const supplierSchema = yup.object().shape({
        name: yup.string().required(t("requiredField")),
        address: yup.string().required(t("requiredField")),
    }); /* Set the Supplier Schema */

    const contactSchema = yup.object().shape({
        name: yup.string().required(t("requiredField")),
        position: yup.string().required(t("requiredField")),
        email: yup
            .string()
            .email(t("invalidEmail"))
            .required(t("requiredField")),
        phoneNumber: yup.string().required(t("requiredField")),
    }); /* Set the Supplier Schema */

    const initialValuesSupplier = {
        name: "",
        address: "",
        attachments: [],
        works: [],
        contacts: [],
    }; /* Set the Form Initial Values to Supplier */

    const initialValuesContact = {
        name: "",
        email: "",
        position: "",
        phoneNumber: "",
    }; /* Set the Form Initial Values to Contact*/

    //#endregion Supplier

    const handleRemoveFile = (event, file) => {
        event.stopPropagation();
        const updatedFiles = files.filter((f) => f !== file);
        setFiles(updatedFiles);
    };

    const onDropFiles = (acceptedFiles) => {
        const updatedFiles = [...files];

        acceptedFiles.forEach((file) => {
            const existingFile = updatedFiles.find(
                (f) => f.fileName === file.name
            );
            if (!existingFile) {
                updatedFiles.push({
                    fileName: file.name,
                    fileType: file.type,
                    preSignedUrl: "",
                    content: "",
                    file: file,
                });
            } else {
                handleSnackbarOpen(
                    t("file") + file.name + t("exists"),
                    "error"
                );
            }
        });

        setFiles(updatedFiles);
    };

    const convertToBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handleFilePreview = async (event, file) => {
        event.stopPropagation();
        if (file.preSignedUrl) {
            try {
                const newWindow = window.open(file.preSignedUrl, "_blank");
                if (newWindow) newWindow.opener = null;
            } catch (err) {
                handleSnackbarOpen(t("errorWhenDownload"), "error");
            }
        } else {
            try {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(file.file);
                link.download = file.fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (err) {
                handleSnackbarOpen(t("errorWhenDownload"), "error");
            }
        }
    };

    const [uploadAttachment, { isLoading: isLoadingUploadAttachment }] =
        useUploadAttachmentMutation();

    const [deleteAttachment, { isLoading: isLoadingDeleteAttachment }] =
        useDeleteAttachmentMutation();

    const [loadingFiles, setLoadingFiles] = useState(false);
    const [isToUpdateSupplier, setIsToUpdateSupplier] = useState(false);

    const handleFormSubmit = async (values, onSubmitProps) => {
        let attachments = [];
        setLoadingFiles(true);
        let isToReload = false;
        try {
            // add only the new attachments
            const _files = files.filter((file) => !!file.file);

            await Promise.all(
                _files.map(async (file) => {
                    if (
                        values.attachments.length === 0 ||
                        values.attachments.filter(
                            (a) => a.fileName === file.fileName
                        ).length === 0
                    ) {
                        let attachment = {
                            id: 0,
                            fileName: file.fileName,
                            content: file.file,
                            fileType: file.fileType,
                        };
                        attachments.push(attachment);
                    }
                })
            );

            let newAttachments = [...attachments.filter((a) => a.id === 0)];

            let attachmentsToAdd = {
                attachments: [...newAttachments],
            };

            // region delete attachments
            let attachmentsToDelete = [];
            values.attachments.forEach((attachment) => {
                const _attachment = {
                    ...attachment,
                    fileName: attachment.fileName.split("/")[2],
                    fileType: attachment.fileType || "image/jpg",
                };
                if (
                    files.filter((f) => f.fileName === attachment.fileName)
                        .length === 0
                ) {
                    delete _attachment.preSignedUrl;
                    if (attachment.fileName.split("/")[2]) {
                        attachmentsToDelete.push(_attachment);
                    }
                }
            });

            const createPromises = [];

            if (attachmentsToAdd.attachments.length > 0) {
                isToReload = true;
                attachmentsToAdd.attachments.forEach((attachment) => {
                    let addAttachmentsDTO = {
                        Id: parseInt(id),
                        Attachment: attachment,
                        EntityName: "Suppliers",
                    };

                    // Store the promise in the array
                    const promise =
                        uploadAttachment(addAttachmentsDTO).unwrap();

                    createPromises.push(promise);
                });

                await Promise.all(createPromises).then(async (results) => {
                    // Create an array of promises from results
                    const processPromises = results.map(
                        async ({
                            codeResult,
                            signedUrl,
                            expiresIn,
                            fileName,
                        }) => {
                            if (
                                codeResult !==
                                RESPONSES.client.sucessToAddAttachments
                            ) {
                                handleSnackbarOpen(
                                    t("anErrorOcurred"),
                                    "error"
                                );
                            } else {
                                const attachment =
                                    attachmentsToAdd.attachments.find(
                                        (attachment) =>
                                            attachment.fileName === fileName
                                    );

                                return fetch(signedUrl, {
                                    method: "PUT",
                                    body: attachment.content,
                                    headers: {
                                        "Content-Type": "multipart/form-data",
                                        mimeType: attachment.fileType,
                                    },
                                });
                            }
                        }
                    );

                    // Wait for all processes to complete
                    await Promise.all(processPromises);
                    handleSnackbarOpen(t("sucess"), "success");
                });
            }

            const deletePromises = [];

            if (attachmentsToDelete.length > 0) {
                isToReload = true;
                attachmentsToDelete.forEach((attachment) => {
                    let addAttachmentsDTO = {
                        Id: parseInt(id),
                        Attachment: attachment,
                        EntityName: "Suppliers",
                    };
                    // Store the promise in the array
                    const promise =
                        deleteAttachment(addAttachmentsDTO).unwrap();

                    deletePromises.push(promise);
                });

                await Promise.all(deletePromises).then((results) => {
                    // Handle the results after all promises are resolved
                    results.forEach(({ codeResult }) => {
                        if (
                            codeResult !==
                            RESPONSES.client.sucessToAddAttachments
                        ) {
                            handleSnackbarOpen(t("anErrorOcurred"), "error");
                        } else {
                            handleSnackbarOpen(t("sucess"), "success");
                        }
                    });
                });
            }
        } catch (err) {
            handleSnackbarOpen(t("anErrorOcurred"), "error");
        }
        setLoadingFiles(false);

        if (isToUpdateSupplier === true) {
            values.attachments = null;
            const { codeResult } = await updateSupplier(values).unwrap();
            if (codeResult !== RESPONSES.supplier.successToUpdateSupplier) {
                if (codeResult === RESPONSES.supplier.invalidToUpdateSupplier) {
                    handleSnackbarOpen(t("invalidToUpdateSupplier"), "error");
                } else if (codeResult === RESPONSES.supplier.invalidSupplier) {
                    handleSnackbarOpen(t("invalidSupplier"), "error");
                } else {
                    handleSnackbarOpen(t("errorUpdateSupplier"), "error");
                }
            } else {
                handleSnackbarOpen(t("successToUpdateSupplier"), "success");
            }
            setIsToUpdateSupplier(false);
        } else {
            if (isToReload === true) {
                window.location.reload();
            }
        }
    };

    const handleDeleteSupplier = async () => {
        setOpenDeleteConfirmationDialog(false);

        try {
            const { codeResult } = await deleteSupplier(id).unwrap();
            if (codeResult !== RESPONSES.supplier.successToDeleteSupplier) {
                if (codeResult === RESPONSES.supplier.invalidToDeleteSupplier) {
                    handleSnackbarOpen(t("invalidToDeleteSupplier"), "error");
                } else if (
                    codeResult ===
                    RESPONSES.supplier.invalidToDeleteSupplierBecauseWorks
                ) {
                    handleSnackbarOpen(
                        t("invalidToDeleteSupplierBecauseWorks"),
                        "error"
                    );
                } else {
                    handleSnackbarOpen(t("errorDeleteSupplier"), "error");
                }
            } else {
                navigate(`/${location.pathname.split("/")[1]}`);
            }
        } catch (err) {
            handleSnackbarOpen(t("errorDeleteSupplier"), "error");
        }
    };

    const handleFormAddSupplierContactSubmit = async (
        values,
        onSubmitProps
    ) => {
        try {
            let newSupplierContact = {
                Id: id,
                Contact: values,
                ContactId: 0,
            };
            const { codeResult } = await addSupplierContact(
                newSupplierContact
            ).unwrap();

            if (codeResult !== RESPONSES.supplier.sucessToAddSupplierContact) {
                if (
                    codeResult ===
                    RESPONSES.supplier.invalidToAddSupplierContact
                ) {
                    handleSnackbarOpen(
                        t("invalidToAddSupplierContact"),
                        "error"
                    );
                } else if (codeResult === RESPONSES.supplier.invalidContact) {
                    handleSnackbarOpen(t("invalidContact"), "error");
                } else {
                    handleSnackbarOpen(t("errorUpdateSupplier"), "error");
                }
            } else {
                handleCloseCreateSupplierContactDialog();
                handleSnackbarOpen(t("sucessToAddSupplierContact"), "success");
            }
        } catch (err) {
            handleSnackbarOpen(t("errorUpdateSupplier"), "error");
        }
    };

    // EDIT WORK CONTACT

    const [supplierContactToEdit, setSupplierContactToEdit] = useState(null);

    const handleEditSupplierContact = async (row) => {
        let supplierContactToUpdate = {
            id: id,
            contactId: row.row.id,
            name: row.row.name,
            email: row.row.email,
            position: row.row.position,
            phoneNumber: row.row.phoneNumber,
        };
        setSupplierContactToEdit(supplierContactToUpdate);
        setOpenEditSupplierContactDialog(true);
    };

    const handleFormEditSupplierContactSubmit = async (
        values,
        onSubmitProps
    ) => {
        try {
            let supplierContactToUpdate = {
                id: id,
                contactId: values.contactId,
                contact: {
                    name: values.name,
                    email: values.email,
                    position: values.position,
                    phoneNumber: values.phoneNumber,
                },
            };

            const { codeResult } = await editSupplierContact(
                supplierContactToUpdate
            ).unwrap();

            if (
                codeResult !== RESPONSES.supplier.sucessToUpdateSupplierContact
            ) {
                if (
                    codeResult ===
                    RESPONSES.supplier.invalidToUpdateSupplierContact
                ) {
                    handleSnackbarOpen(
                        t("invalidToUpdateSupplierContact"),
                        "error"
                    );
                } else if (codeResult === RESPONSES.supplier.invalidContact) {
                    handleSnackbarOpen(t("invalidContact"), "error");
                } else {
                    handleSnackbarOpen(
                        t("invalidToUpdateSupplierContact"),
                        "error"
                    );
                }
            } else {
                handleCloseEditSupplierContactDialog();
                handleSnackbarOpen(
                    t("sucessToUpdateSupplierContact"),
                    "success"
                );
            }
        } catch (err) {
            handleSnackbarOpen(t("invalidToUpdateSupplierContact"), "error");
        }
    };

    const [idToDelete, setIdToDelete] = useState(null);

    const handleDeleteSupplierContactOpenDialog = async (id) => {
        setIdToDelete(id);
        setOpenDeleteSupplierContactDialog(true);
    };

    const handleDeleteSupplierContact = async () => {
        try {
            let supplierContactToDelete = {
                Id: 0,
                ContactId: idToDelete,
                Contact: null,
            };

            const { codeResult } = await deleteSupplierContact(
                supplierContactToDelete
            ).unwrap();

            if (
                codeResult !== RESPONSES.supplier.sucessToDeleteSupplierContact
            ) {
                handleSnackbarOpen(
                    t("invalidToDeleteSupplierContact"),
                    "error"
                );
            } else {
                setOpenDeleteSupplierContactDialog(false);
                handleSnackbarOpen(
                    t("sucessToDeleteSupplierContact"),
                    "success"
                );
            }
        } catch (err) {
            handleSnackbarOpen(t("invalidToDeleteSupplierContact"), "error");
        }
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={supplier || initialValuesSupplier}
            validationSchema={supplierSchema}
            enableReinitialize={true}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    {isLoadingGetSupplier ||
                    isLoadingUpdateSupplier ||
                    loadingFiles ||
                    isLoadingDeleteSupplier ? (
                        <LoadingSpinner />
                    ) : null}
                    <Box
                        m={!isNonMobile ? "0" : "1.5rem 2.5rem"}
                        display={!isNonMobile ? "flex" : "grid"}
                        gridTemplateRows={
                            !isNonMobile ? undefined : "repeat(4, auto)"
                        }
                        flexDirection={!isNonMobile ? "column" : undefined}
                        gap="20px"
                        borderRadius="5px"
                        p="2rem"
                    >
                        <PageHeader title={values.name} subtitle="" />
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("supplierInformation")}
                            </Typography>
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <TextField
                                    variant="outlined"
                                    label={t("name")}
                                    inputRef={nameRef}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    name="name"
                                    onChange={(event) => {
                                        handleChange(event);
                                        setIsToUpdateSupplier(true); // Atualiza a flag sem substituir handleChange
                                    }}
                                    error={
                                        Boolean(touched.name) &&
                                        Boolean(errors.name)
                                    }
                                    helperText={touched.name && errors.name}
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("address")}
                                    onBlur={handleBlur}
                                    value={values.address}
                                    name="address"
                                    onChange={(event) => {
                                        handleChange(event);
                                        setIsToUpdateSupplier(true); // Atualiza a flag sem substituir handleChange
                                    }}
                                    error={
                                        Boolean(touched.address) &&
                                        Boolean(errors.address)
                                    }
                                    helperText={
                                        touched.address && errors.address
                                    }
                                    sx={{ width: "100%" }}
                                />
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                            borderRadius="5px"
                            backgroundColor={theme.palette.background.alt}
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("supplierDocuments")}
                            </Typography>
                            <Dropzone multiple={true} onDrop={onDropFiles}>
                                {({ getRootProps, getInputProps }) => (
                                    <FlexBetween>
                                        <Box
                                            {...getRootProps()}
                                            border={`2px dashed ${theme.palette.primary.main}`}
                                            p="1rem"
                                            width="100%"
                                            display="grid"
                                            gridTemplateColumns={
                                                !isNonMobile
                                                    ? "repeat(1, 1fr)"
                                                    : "repeat(5, auto)"
                                            }
                                            gap="10px"
                                            borderRadius="5px"
                                            sx={{
                                                "&:hover": {
                                                    cursor: "pointer",
                                                },
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            {files.length === 0 ? (
                                                <p>
                                                    {t("dropzoneAddYouFiles")}
                                                </p>
                                            ) : (
                                                files.map((file) => (
                                                    <Card
                                                        sx={{
                                                            backgroundColor:
                                                                theme.palette
                                                                    .background
                                                                    .D,
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            gap: "10px",
                                                            p: "15px 10px 15px 0px",
                                                            "& .MuiCardContent-root:last-child":
                                                                {
                                                                    paddingBottom:
                                                                        "20px",
                                                                },
                                                        }}
                                                        key={file.fileName}
                                                        onClick={(event) =>
                                                            handleFilePreview(
                                                                event,
                                                                file
                                                            )
                                                        }
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                // pl: 1,
                                                                // pb: 1,
                                                            }}
                                                        >
                                                            <IconButton
                                                                onClick={(
                                                                    event
                                                                ) =>
                                                                    handleRemoveFile(
                                                                        event,
                                                                        file
                                                                    )
                                                                }
                                                            >
                                                                <DeleteOutlined />
                                                            </IconButton>
                                                        </Box>
                                                        <CardContent
                                                            sx={{
                                                                p: "0px !important",
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: 14,
                                                                }}
                                                                color="text.secondary"
                                                            >
                                                                {file.fileName.split(
                                                                    "/"
                                                                )[2]
                                                                    ? file.fileName.split(
                                                                          "/"
                                                                      )[2]
                                                                    : file.fileName}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                ))
                                            )}
                                        </Box>
                                    </FlexBetween>
                                )}
                            </Dropzone>
                        </Box>
                        <Box
                            display="flex"
                            marginTop="10px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Box
                                display="flex"
                                marginTop="10px"
                                flexDirection="row"
                                justifyContent="space-between"
                                width="100%"
                            >
                                <Typography
                                    fontWeight="500"
                                    fontSize="20px"
                                    color="primary"
                                    variant="h5"
                                    textAlign="left"
                                >
                                    {t("associatedContacts")}
                                </Typography>
                                <LoadingButton
                                    type="button"
                                    onClick={
                                        handleOpenCreateSupplierContactDialog
                                    }
                                    // loading={isLoadingDeleteAccomodation}
                                    sx={{
                                        p: "5px 1rem",
                                        backgroundColor:
                                            theme.palette.primary.main,
                                        color: theme.palette.neutral.white,
                                        "&:hover": {
                                            backgroundColor:
                                                theme.palette.primary.light,
                                        },
                                    }}
                                >
                                    {t("addContact")}
                                </LoadingButton>
                            </Box>
                            <Box
                                display="flex"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        flexGrow: 1,
                                        width: "100%",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        // borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: `${theme.palette.background.J} !important`,
                                        color: theme.palette.secondary[100],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor:
                                            theme.palette.background.A,
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        backgroundColor: `${theme.palette.background.J} !important`,
                                        color: theme.palette.secondary[100],
                                        borderTop: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text":
                                        {
                                            color: `${theme.palette.background.I}`,
                                        },
                                    "& .MuiDataGrid-toolbarContainer .MuiBox-root":
                                        {
                                            display: !isNonMobile
                                                ? "flex"
                                                : undefined,
                                            flexDirection: !isNonMobile
                                                ? "row"
                                                : undefined,
                                        },
                                    "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                        {
                                            backgroundColor: `${theme.palette.background.A} !important`,
                                        },
                                }}
                            >
                                <DataGrid
                                    autoHeight
                                    loading={isLoadingGetSupplier || !supplier}
                                    getRowId={(row) => row.id}
                                    rows={supplierContacts || []}
                                    columns={
                                        !isNonMobile
                                            ? supplierContactsColumnsMobile
                                            : supplierContactsColumns
                                    }
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    slots={{
                                        toolbar: DataGridCustomWithoutToolbar,
                                    }}
                                    key={JSON.stringify(supplierContacts)}
                                    disableRowSelectionOnClick
                                />
                            </Box>
                        </Box>

                        {/* <Box
                            display="flex"
                            marginTop="10px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("works")}
                            </Typography>
                            <Box
                                display="flex"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        flexGrow: 1,
                                        width: "100%",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        // borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: `${theme.palette.background.J} !important`,
                                        color: theme.palette.secondary[100],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor:
                                            theme.palette.background.A,
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        backgroundColor: `${theme.palette.background.J} !important`,
                                        color: theme.palette.secondary[100],
                                        borderTop: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text":
                                        {
                                            color: `${theme.palette.background.I}`,
                                        },
                                    "& .MuiDataGrid-toolbarContainer .MuiBox-root":
                                        {
                                            display: !isNonMobile
                                                ? "flex"
                                                : undefined,
                                            flexDirection: !isNonMobile
                                                ? "row"
                                                : undefined,
                                        },
                                    "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                        {
                                            backgroundColor: `${theme.palette.background.A} !important`,
                                        },
                                }}
                            >
                                <DataGrid
                                    autoHeight
                                    loading={isLoadingGetSupplier || !supplier}
                                    getRowId={(row) => row.id}
                                    rows={supplierWorks || []}
                                    columns={supplierWorksColumns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    slots={{
                                        toolbar: DataGridCustomWithoutToolbar,
                                    }}
                                    disableRowSelectionOnClick
                                />
                            </Box>
                        </Box> */}
                        {/* BUTTONS */}
                        <Box display="flex" gap="10px">
                            <LoadingButton
                                fullWidth
                                type="button"
                                onClick={handleOpenDeleteConfirmationDialog}
                                loading={isLoadingDeleteSupplier}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                            >
                                {t("deleteSupplierButton")}
                            </LoadingButton>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                loading={isLoadingGetSupplier}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                            >
                                {t("updateSupplierButton")}
                            </LoadingButton>
                        </Box>
                    </Box>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity={snackbarSeverity}
                            sx={{
                                mt: "10px",
                                backgroundColor:
                                    snackbarSeverity === "success"
                                        ? "#10781F"
                                        : "#ff000082",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {snackbarMsg}
                        </Alert>
                    </Snackbar>
                    <Dialog
                        open={openDeleteConfirmationDialog}
                        onClose={handleCloseDeleteConfirmationDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("deleteSupplierConfirmationTitle")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t("deleteSupplierConfirmationMessage")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                                onClick={handleCloseDeleteConfirmationDialog}
                            >
                                {t("noMessage")}
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                                onClick={handleDeleteSupplier}
                                autoFocus
                            >
                                {t("yesMessage")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* Delete Supplier Contact */}
                    <Dialog
                        open={openDeleteSupplierContactDialog}
                        onClose={handleCloseDeleteSupplierContactDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("deleteSupplierContactConfirmationTitle")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t("deleteSupplierContactConfirmationMessage")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                                onClick={handleCloseDeleteSupplierContactDialog}
                            >
                                {t("noMessage")}
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                                // isLoading={isLoadingDeleteSupplierContact}
                                onClick={handleDeleteSupplierContact}
                                autoFocus
                            >
                                {t("yesMessage")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* Create Supplier Contact */}
                    <Dialog
                        maxWidth="800px"
                        open={openCreateSupplierContactDialog}
                        onClose={handleCloseCreateSupplierContactDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <Formik
                            onSubmit={handleFormAddSupplierContactSubmit}
                            initialValues={initialValuesContact}
                            validationSchema={contactSchema}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                resetForm,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box
                                        display="grid"
                                        gridTemplateColumns={
                                            !isNonMobile
                                                ? "repeat(1, 1fr)"
                                                : "repeat(1, auto)"
                                        }
                                        gap="20px"
                                        backgroundColor={
                                            theme.palette.background.D
                                        }
                                        borderRadius="5px"
                                        p="2rem"
                                        width={!isNonMobile ? "100%" : "40rem"}
                                    >
                                        <PageHeader
                                            title={t("newContact")}
                                            subtitle=""
                                        />
                                        <Box
                                            display="flex"
                                            gap="20px"
                                            flexDirection="column"
                                            width="100%"
                                            mt="10px"
                                        >
                                            <Box
                                                display="grid"
                                                gridTemplateColumns={
                                                    !isNonMobile
                                                        ? "repeat(1, 1fr)"
                                                        : "repeat(2, 1fr)"
                                                }
                                                gap="10px"
                                            >
                                                <TextField
                                                    variant="outlined"
                                                    label={t("name")}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.name}
                                                    name="name"
                                                    error={
                                                        Boolean(touched.name) &&
                                                        Boolean(errors.name)
                                                    }
                                                    helperText={
                                                        touched.name &&
                                                        errors.name
                                                    }
                                                    sx={{ width: "100%" }}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    label={t("email")}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.email}
                                                    name="email"
                                                    error={
                                                        Boolean(
                                                            touched.email
                                                        ) &&
                                                        Boolean(errors.email)
                                                    }
                                                    helperText={
                                                        touched.email &&
                                                        errors.email
                                                    }
                                                    sx={{ width: "100%" }}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    label={t("position")}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.position}
                                                    name="position"
                                                    error={
                                                        Boolean(
                                                            touched.position
                                                        ) &&
                                                        Boolean(errors.position)
                                                    }
                                                    helperText={
                                                        touched.position &&
                                                        errors.position
                                                    }
                                                    sx={{ width: "100%" }}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    label={t("phoneNumber")}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    name="phoneNumber"
                                                    error={
                                                        Boolean(
                                                            touched.phoneNumber
                                                        ) &&
                                                        Boolean(
                                                            errors.phoneNumber
                                                        )
                                                    }
                                                    helperText={
                                                        touched.phoneNumber &&
                                                        errors.phoneNumber
                                                    }
                                                    sx={{ width: "100%" }}
                                                />
                                            </Box>
                                        </Box>
                                        {/* BUTTONS */}
                                        <LoadingButton
                                            fullWidth
                                            type="submit"
                                            loading={
                                                isLoadingAddSupplierContact
                                            }
                                            sx={{
                                                width: "100%",
                                                p: "1rem",
                                                backgroundColor:
                                                    theme.palette.primary.main,
                                                color: theme.palette.neutral
                                                    .white,
                                                "&:hover": {
                                                    backgroundColor:
                                                        theme.palette.primary
                                                            .light,
                                                },
                                            }}
                                        >
                                            {t("addContact")}
                                        </LoadingButton>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Dialog>
                    {/* Edit Work Contacts */}
                    <Dialog
                        maxWidth="800px"
                        open={openEditSupplierContactDialog}
                        onClose={handleCloseEditSupplierContactDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <Formik
                            onSubmit={handleFormEditSupplierContactSubmit}
                            initialValues={
                                supplierContactToEdit || initialValuesContact
                            }
                            validationSchema={contactSchema}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                resetForm,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box
                                        display="grid"
                                        gridTemplateColumns={
                                            !isNonMobile
                                                ? "repeat(1, 1fr)"
                                                : "repeat(1, auto)"
                                        }
                                        gap="20px"
                                        backgroundColor={
                                            theme.palette.background.D
                                        }
                                        borderRadius="5px"
                                        p="2rem"
                                        width={!isNonMobile ? "100%" : "40rem"}
                                    >
                                        <PageHeader
                                            title={t("editContact")}
                                            subtitle=""
                                        />
                                        <Box
                                            display="flex"
                                            gap="20px"
                                            flexDirection="column"
                                            width="100%"
                                            mt="10px"
                                        >
                                            <Box
                                                display="grid"
                                                gridTemplateColumns={
                                                    !isNonMobile
                                                        ? "repeat(1, 1fr)"
                                                        : "repeat(2, 1fr)"
                                                }
                                                gap="10px"
                                            >
                                                <TextField
                                                    variant="outlined"
                                                    label={t("name")}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.name}
                                                    name="name"
                                                    error={
                                                        Boolean(touched.name) &&
                                                        Boolean(errors.name)
                                                    }
                                                    helperText={
                                                        touched.name &&
                                                        errors.name
                                                    }
                                                    sx={{ width: "100%" }}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    label={t("email")}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.email}
                                                    name="email"
                                                    error={
                                                        Boolean(
                                                            touched.email
                                                        ) &&
                                                        Boolean(errors.email)
                                                    }
                                                    helperText={
                                                        touched.email &&
                                                        errors.email
                                                    }
                                                    sx={{ width: "100%" }}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    label={t("position")}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.position}
                                                    name="position"
                                                    error={
                                                        Boolean(
                                                            touched.position
                                                        ) &&
                                                        Boolean(errors.position)
                                                    }
                                                    helperText={
                                                        touched.position &&
                                                        errors.position
                                                    }
                                                    sx={{ width: "100%" }}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    label={t("phoneNumber")}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    name="phoneNumber"
                                                    error={
                                                        Boolean(
                                                            touched.phoneNumber
                                                        ) &&
                                                        Boolean(
                                                            errors.phoneNumber
                                                        )
                                                    }
                                                    helperText={
                                                        touched.phoneNumber &&
                                                        errors.phoneNumber
                                                    }
                                                    sx={{ width: "100%" }}
                                                />
                                            </Box>
                                        </Box>
                                        {/* BUTTONS */}
                                        <LoadingButton
                                            fullWidth
                                            type="submit"
                                            loading={
                                                isLoadingEditSupplierContact
                                            }
                                            sx={{
                                                width: "100%",
                                                p: "1rem",
                                                backgroundColor:
                                                    theme.palette.primary.main,
                                                color: theme.palette.neutral
                                                    .white,
                                                "&:hover": {
                                                    backgroundColor:
                                                        theme.palette.primary
                                                            .light,
                                                },
                                            }}
                                        >
                                            {t("editContact")}
                                        </LoadingButton>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Dialog>
                </form>
            )}
        </Formik>
    );
};

export default EditSupplierForm;

import { useState, useRef, useEffect } from "react";
import PageHeader from "components/PageHeader";
import LoadingSpinner from "components/LoadingSpinner";
import {
    Box,
    Button,
    TextField,
    Typography,
    useTheme,
    Alert,
    useMediaQuery,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";
import { useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomWithoutToolbar from "components/DataGridCustomWithoutToolbar";
import {
    useDeleteSubContractorByIdMutation,
    useGetSubContractorByIdQuery,
    useUpdateSubContractorMutation,
} from "./subContractorsApiSlice";
import useAuth from "hooks/useAuth";
import { useGetEmpoyeeCostsBySucontractorIdQuery } from "features/home/homePageApiSlice";

const EditSubContractorForm = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const nameRef = useRef();

    const { userType } = useAuth();

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const [employees, setEmployees] = useState([]);

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMsg(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const [totalHours, setTotalHours] = useState(0);
    const [totalProfit, setTotalProfit] = useState(0);

    const [openSubcontractorResumeDialog, setOpenSubcontractorResumeDialog] =
        useState(false);

    const handleOpenSubcontractorResumeDialog = () => {
        setOpenSubcontractorResumeDialog(true);
    };

    const handleCloseSubcontractorResumeDialog = () => {
        setOpenSubcontractorResumeDialog(false);
    };

    const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
        useState(false);

    const handleOpenDeleteConfirmationDialog = () => {
        setOpenDeleteConfirmationDialog(true);
    };

    const handleCloseDeleteConfirmationDialog = () => {
        setOpenDeleteConfirmationDialog(false);
    };

    const { id } = useParams();

    const { data: subContractor, isLoading: isloadingGetSubContractorById } =
        useGetSubContractorByIdQuery(id);

    const [updateSubContractor, { isLoading: isLoadingUpdateSubContractor }] =
        useUpdateSubContractorMutation();

    const [deleteSubContractor, { isLoading: isLoadingDeleteSubContractor }] =
        useDeleteSubContractorByIdMutation();

    const subContractorSchema = yup.object().shape({
        name: yup.string().required(t("requiredField")),
    });

    const initialValuesSubContractor = {
        name: "",
    };

    const employeesColumns = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
            valueGetter: (params) => params.row.name,
        },
        {
            field: "email",
            headerName: t("email"),
            flex: 1,
            valueGetter: (params) => params.row.email,
        },
    ];

    useEffect(() => {
        if (subContractor) {
            setEmployees(subContractor.employees);
        }
    }, [subContractor]);

    const {
        data: employeeCosts,
        isLoading: isLoadingGetEmployeeCostsBySubcontractorId,
    } = useGetEmpoyeeCostsBySucontractorIdQuery(id);

    useEffect(() => {
        if (employeeCosts) {
            const hoursSum = employeeCosts.reduce(
                (total, item) =>
                    total + item.employeeRegist.workHoursTotalByWork,
                0
            );
            const profitSum = employeeCosts.reduce(
                (total, item) => total + item.clearance.workProfit,
                0
            );

            setTotalHours(hoursSum.toFixed(2));
            setTotalProfit(profitSum.toFixed(2));
        }
    }, [employeeCosts]);

    const handleFormSubmit = async (values, onSubmitProps) => {
        try {
            let subContractorToUpdate = {
                id: id,
                name: values.name,
            };
            const { codeResult } = await updateSubContractor(
                subContractorToUpdate
            ).unwrap();

            if (
                codeResult !==
                RESPONSES.subContractor.sucessToUpdateSubContractor
            ) {
                if (
                    codeResult === RESPONSES.subContractor.invalidSubContractor
                ) {
                    handleSnackbarOpen(t("invalidSubContractor"), "error");
                } else {
                    handleSnackbarOpen(t("errorUpdateSubContrator"), "error");
                }
            } else {
                handleSnackbarOpen(t("sucessToUpdateSubContractor"), "success");
            }
        } catch (err) {
            handleSnackbarOpen(t("errorUpdateSubContrator"), "error");
        }
    };

    const handleDeleteSubContractor = async () => {
        setOpenDeleteConfirmationDialog(false);

        try {
            const { codeResult } = await deleteSubContractor(id).unwrap();

            if (
                codeResult !==
                RESPONSES.subContractor.sucessToDeleteSubContractor
            ) {
                if (
                    codeResult ===
                    RESPONSES.subContractor
                        .invalidToDeleteSubContractorBeacauseHaveAssignedEmployees
                ) {
                    handleSnackbarOpen(
                        t(
                            "invalidToDeleteSubContractorBeacauseHaveAssignedEmployees"
                        ),
                        "error"
                    );
                } else {
                    handleSnackbarOpen(t("errorDeleteSubContractor"), "error");
                }
            } else {
                navigate(`/${location.pathname.split("/")[1]}`);
            }
        } catch (err) {
            handleSnackbarOpen(t("errorDeleteSubContractor"), "error");
        }
    };
    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={subContractor || initialValuesSubContractor}
            validationSchema={subContractorSchema}
            enableReinitialize={true}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    {isloadingGetSubContractorById ||
                    isLoadingUpdateSubContractor ||
                    isLoadingDeleteSubContractor ? (
                        <LoadingSpinner />
                    ) : null}
                    <Box
                        m={!isNonMobile ? "0" : "1.5rem 2.5rem"}
                        display={!isNonMobile ? "flex" : "grid"}
                        gridTemplateRows={
                            !isNonMobile ? undefined : "repeat(4, auto)"
                        }
                        flexDirection={!isNonMobile ? "column" : undefined}
                        gap="20px"
                        borderRadius="5px"
                        p="2rem"
                    >
                        <PageHeader title={values.name} subtitle="" />
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            {userType !== "ADMIN" ? (
                                <Typography
                                    fontWeight="500"
                                    fontSize="20px"
                                    color="primary"
                                    variant="h5"
                                    textAlign="left"
                                >
                                    {t("subcontractorInformation")}
                                </Typography>
                            ) : (
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography
                                        fontWeight="500"
                                        fontSize="20px"
                                        color="primary"
                                        variant="h5"
                                        textAlign="left"
                                    >
                                        {t("subcontractorInformation")}
                                    </Typography>
                                    <QueryStatsIcon
                                        sx={{
                                            color: "#0071BB",
                                            cursor: "pointer",
                                            transition: "box-shadow 0.3s ease",
                                            ":hover": {
                                                boxShadow:
                                                    "0px 0px 10px rgba(0, 0, 0, 0.2)",
                                            },
                                        }}
                                        onClick={
                                            handleOpenSubcontractorResumeDialog
                                        }
                                    ></QueryStatsIcon>
                                </Box>
                            )}
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(3, 1fr)"
                                }
                                gap="10px"
                            >
                                <TextField
                                    variant="outlined"
                                    label={t("name")}
                                    inputRef={nameRef}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    name="name"
                                    error={
                                        Boolean(touched.name) &&
                                        Boolean(errors.name)
                                    }
                                    helperText={touched.name && errors.name}
                                    sx={{ width: "100%" }}
                                />
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                width="100%"
                            >
                                <Typography
                                    fontWeight="500"
                                    fontSize="20px"
                                    color="primary"
                                    variant="h5"
                                    textAlign="left"
                                >
                                    {t("employees")}
                                </Typography>
                                <Box
                                    display="flex"
                                    sx={{
                                        "& .MuiDataGrid-root": {
                                            border: "none",
                                            flexGrow: 1,
                                        },
                                        "& .MuiDataGrid-cell": {
                                            // borderBottom: "none",
                                        },
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: `${theme.palette.background.J} !important`,
                                            color: theme.palette.secondary[100],
                                            borderBottom: "none",
                                        },
                                        "& .MuiDataGrid-virtualScroller": {
                                            backgroundColor:
                                                theme.palette.background.A,
                                        },
                                        "& .MuiDataGrid-footerContainer": {
                                            backgroundColor: `${theme.palette.background.J} !important`,
                                            color: theme.palette.secondary[100],
                                            borderTop: "none",
                                        },
                                        "& .MuiDataGrid-toolbarContainer .MuiButton-text":
                                            {
                                                color: "#666666",
                                            },
                                        "& .MuiDataGrid-toolbarContainer .MuiBox-root":
                                            {
                                                display: !isNonMobile
                                                    ? "flex"
                                                    : undefined,
                                                flexDirection: !isNonMobile
                                                    ? "row"
                                                    : undefined,
                                            },
                                        "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                            {
                                                backgroundColor: `${theme.palette.background.A} !important`,
                                            },
                                    }}
                                >
                                    <DataGrid
                                        autoHeight
                                        loading={
                                            isloadingGetSubContractorById ||
                                            !subContractor
                                        }
                                        getRowId={(row) => row.id}
                                        rows={employees || []}
                                        columns={employeesColumns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 10,
                                                },
                                            },
                                        }}
                                        pageSizeOptions={[10]}
                                        slots={{
                                            toolbar:
                                                DataGridCustomWithoutToolbar,
                                        }}
                                        disableRowSelectionOnClick
                                    />
                                </Box>
                            </Box>
                        </Box>

                        {/* BUTTONS */}
                        <Box display="flex" gap="10px">
                            <LoadingButton
                                fullWidth
                                type="button"
                                onClick={handleOpenDeleteConfirmationDialog}
                                loading={isLoadingDeleteSubContractor}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                            >
                                {t("removeSubcontractor")}
                            </LoadingButton>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                loading={isLoadingUpdateSubContractor}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                            >
                                {t("editSubcontractor")}
                            </LoadingButton>
                        </Box>
                    </Box>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity={snackbarSeverity}
                            sx={{
                                mt: "10px",
                                backgroundColor:
                                    snackbarSeverity === "success"
                                        ? "#10781F"
                                        : "#ff000082",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {snackbarMsg}
                        </Alert>
                    </Snackbar>
                    <Dialog
                        open={openDeleteConfirmationDialog}
                        onClose={handleCloseDeleteConfirmationDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("deleteSubContractorConfirmationTitle")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t("deleteSubContractorConfirmationMessage")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                                onClick={handleCloseDeleteConfirmationDialog}
                            >
                                {t("noMessage")}
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                                onClick={handleDeleteSubContractor}
                                autoFocus
                            >
                                {t("yesMessage")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* Subcontractor Resume */}
                    <Dialog
                        open={openSubcontractorResumeDialog}
                        onClose={handleCloseSubcontractorResumeDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <Box
                                boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                sx={{
                                    borderRadius: "20px",
                                    margin: "20px",
                                    padding: "10px",
                                }}
                            >
                                <Typography
                                    variant="h1"
                                    component="h2"
                                    color="black"
                                    textAlign="center"
                                >
                                    {totalHours}
                                </Typography>
                                <Typography
                                    variant="h2"
                                    component="h2"
                                    color="#c8c8c8"
                                    textAlign="center"
                                    fontSize="32px"
                                    fontWeight="500"
                                >
                                    {t("totalHours")}
                                </Typography>
                            </Box>
                            <Box
                                boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                sx={{
                                    borderRadius: "20px",
                                    margin: "20px",
                                    padding: "10px",
                                }}
                            >
                                <Typography
                                    variant="h1"
                                    component="h2"
                                    color="black"
                                    textAlign="center"
                                >
                                    {totalProfit + " €"}
                                </Typography>
                                <Typography
                                    variant="h2"
                                    component="h2"
                                    color="#c8c8c8"
                                    textAlign="center"
                                    fontSize="32px"
                                    fontWeight="500"
                                >
                                    {t("totalProfit")}
                                </Typography>
                            </Box>
                        </DialogContent>
                    </Dialog>
                </form>
            )}
        </Formik>
    );
};

export default EditSubContractorForm;

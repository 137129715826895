import { apiSlice } from "app/api/apiSlice";

export const internalRegistsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getInternalRegistsPaginated: builder.query({
            query: (query) => {
                const params = [];
                params.push(`limit=${(query && query.limit) || 10}`);
                params.push(`offset=${(query && query.offset) || 0}`);
                if (query && query.search)
                    params.push(
                        `search=${encodeURIComponent(query && query.search)}`
                    );
                if (query && query.filters)
                    params.push(
                        `filters=${encodeURIComponent(
                            JSON.stringify(query && query.filters)
                        )}`
                    );
                if (query && query.orderByCollection)
                    params.push(
                        `orderByCollection=${encodeURIComponent(
                            JSON.stringify(query && query.orderByCollection)
                        )}`
                    );
                const queryString =
                    params.length > 0 ? `?${params.join("&")}` : "";
                return {
                    url: `InternalRegists/GetAllPaginated${queryString.toString()}`,
                    validateStatus: (response, result) => {
                        return response.status === 200 && !result.isError;
                    },
                    query,
                };
            },
            providesTags: [{ type: "InternalRegists", id: "LIST" }],
        }),
        getInternalRegists: builder.query({
            query: () => ({
                url: `InternalRegists/GetAll`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "InternalRegists", id: "LIST" }],
        }),
        postInternalRegist: builder.mutation({
            query: (InternalRegistData) => ({
                url: `InternalRegists`,
                method: "POST",
                body: {
                    ...InternalRegistData,
                },
            }),
            invalidatesTags: [{ type: "InternalRegists", id: "LIST" }],
        }),
        getInternalRegistById: builder.query({
            query: (id) => ({
                url: `InternalRegists/${id}`,
            }),
            providesTags: (result, error, arg) => [
                { type: "InternalRegists", id: arg },
            ],
        }),
        updateInternalRegist: builder.mutation({
            query: (InternalRegistData) => ({
                url: `InternalRegists`,
                method: "PUT",
                body: {
                    ...InternalRegistData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "InternalRegists", id: "LIST" },
                { type: "InternalRegists", id: arg.id },
            ],
        }),
        deleteInternalRegistById: builder.mutation({
            query: (id) => ({
                url: `InternalRegists/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "InternalRegists", id: "LIST" }],
        }),
    }),
});

export const {
    useGetInternalRegistsQuery,
    usePostInternalRegistMutation,
    useGetInternalRegistByIdQuery,
    useUpdateInternalRegistMutation,
    useDeleteInternalRegistByIdMutation,
} = internalRegistsApiSlice;

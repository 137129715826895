import React, { useState, useEffect } from "react";
import { ArrowDropDownOutlined } from "@mui/icons-material";
import FlexBetween from "./FlexBetween";
import { useTranslation } from "react-i18next";
import {
    AppBar,
    Badge,
    Box,
    DialogActions,
    DialogContent,
    Button,
    Dialog,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    useMediaQuery,
    Typography,
    useTheme,
    Alert,
    Card,
    CardContent,
    TextField,
} from "@mui/material";
import { useSendLogoutMutation } from "features/auth/authApiSlice";
import DialogContentText from "@mui/material/DialogContentText";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DeleteOutlined } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import PageHeader from "components/PageHeader";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import { Formik } from "formik";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "components/LoadingSpinner";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import useAuth from "hooks/useAuth";
import { RESPONSES } from "config/responses";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import EventIcon from "@mui/icons-material/Event";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Dropzone from "react-dropzone";
import * as yup from "yup";
import { format } from "date-fns";
import {
    useDeleteReminderByIdMutation,
    useGetRemindersQuery,
    useLazyGetReminderByIdQuery,
    usePostReminderMutation,
    useUpdateReminderMutation,
} from "features/reminders/remindersApiSlice";
import { useGetAllAlertsQuery } from "features/alerts/alertsApiSlice";
import { SETTINGS } from "helpers/settings";
import {
    useDeleteAttachmentMutation,
    useUploadAttachmentMutation,
} from "features/attachments/attachmentsApiSlice";

const Navbar = ({ isSidebarOpen, setIsSidebarOpen }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const { username, userType, userId, userPhoto } = useAuth();

    //#REGION REMINDERS
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const [files, setFiles] = useState([]);

    const [reminderId, setReminderId] = useState("");

    const [activeAlerts, setActiveAlerts] = useState(0);

    const [reminderIdToDelete, setReminderIdToDelete] = useState();

    const [errMsg, setErrMsg] = useState(""); /* Set the Error Message State */

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMsg(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const [openRemindersDialog, setOpenRemindersDialog] = useState(false);

    const handleOpenRemindersDialog = () => {
        refetchReminders();
        setOpenRemindersDialog(true);
    };

    const handleCloseRemindersDialog = () => {
        setOpenRemindersDialog(false);
    };

    const [openNewReminderDialog, setOpenNewReminderDialog] = useState(false);

    const handleOpenNewReminderDialog = () => {
        setOpenNewReminderDialog(true);
    };

    const handleCloseNewReminderDialog = () => {
        setErrMsg("");
        setOpenNewReminderDialog(false);
    };

    const [openEditReminderDialog, setOpenEditReminderDialog] = useState(false);

    const handleOpenEditReminderDialog = () => {
        setOpenEditReminderDialog(true);
    };

    const handleCloseEditReminderDialog = () => {
        setErrMsg("");
        setOpenEditReminderDialog(false);
    };

    const [openDeleteReminderDialog, setOpenDeleteReminderDialog] =
        useState(false);

    const handleOpenDeleteReminderDialog = () => {
        setOpenDeleteReminderDialog(true);
    };

    const handleCloseDeleteReminderDialog = () => {
        setOpenDeleteReminderDialog(false);
    };

    const {
        data: reminders,
        isLoadingReminders,
        refetch: refetchReminders,
    } = useGetRemindersQuery();

    const { data: alerts, isLoadingAlerts } = useGetAllAlertsQuery();

    const reminderSchema = yup.object().shape({
        description: yup.string().required(t("requiredField")),
        endDate: yup.date().notRequired(),
        numberOfDaysAlert: yup
            .number()
            .positive()
            .integer()
            .required(t("requiredField")),
    });

    const initialValuesReminder = {
        description: "",
        numberOfDaysAlert: "",
        endDate: null,
        attachments: [],
    };

    const [createReminder, { isLoadingCreateReminder }] =
        usePostReminderMutation();

    const handleFormSubmit = async (values, onSubmitProps) => {
        try {
            if (
                !values.endDate ||
                values.endDate === "" ||
                values.endDate === null
            ) {
                setErrMsg(t("requiredReminderDate"));
            } else {
                const { codeResult } = await createReminder(values).unwrap();

                if (codeResult !== RESPONSES.reminder.sucessToInsertReminder) {
                    handleSnackbarOpen(t("errorToInsertReminder"), "error");
                } else {
                    onSubmitProps.resetForm();
                    handleCloseNewReminderDialog();
                    handleSnackbarOpen(t("sucessToInsertReminder"), "success");
                }
            }
        } catch (err) {
            handleSnackbarOpen(t("errorToInsertReminder"), "error");
        }
    };

    const handleRemoveFile = (event, file) => {
        event.stopPropagation();
        const updatedFiles = files.filter((f) => f !== file);
        setFiles(updatedFiles);
    };

    const onDropFiles = (acceptedFiles) => {
        const updatedFiles = [...files];

        acceptedFiles.forEach((file) => {
            const existingFile = updatedFiles.find(
                (f) => f.fileName === file.name
            );
            if (!existingFile) {
                updatedFiles.push({
                    fileName: file.name,
                    fileType: file.type,
                    preSignedUrl: "",
                    content: "",
                    file: file,
                });
            } else {
                handleSnackbarOpen(
                    t("file") + file.name + t("exists"),
                    "error"
                );
            }
        });

        setFiles(updatedFiles);
    };

    const convertToBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handleFilePreview = async (event, file) => {
        event.stopPropagation();
        if (file.preSignedUrl) {
            try {
                const newWindow = window.open(file.preSignedUrl, "_blank");
                if (newWindow) newWindow.opener = null;
            } catch (err) {
                handleSnackbarOpen(t("errorWhenDownload"), "error");
            }
        } else {
            try {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(file.file);
                link.download = file.fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (err) {
                handleSnackbarOpen(t("errorWhenDownload"), "error");
            }
        }
    };

    function base64ToFile(base64Data, fileName, mimeType) {
        let base64Content = null;
        if (base64Data.split(",").length > 1) {
            base64Content = base64Data.split(",")[1];
        } else {
            base64Content = base64Data;
        }

        const uint8Array = new Uint8Array(
            atob(base64Content)
                .split("")
                .map((char) => char.charCodeAt(0))
        );

        const blob = new Blob([uint8Array], { type: mimeType });

        const file = new File([blob], fileName, { type: mimeType });

        return file;
    }

    const [editReminder, { isLoadingEditReminder }] =
        useUpdateReminderMutation();

    const [getReminderById, reminderById] = useLazyGetReminderByIdQuery();

    const handleEditReminder = async (reminder) => {
        await getReminderById(reminder.id);
    };

    useEffect(() => {
        if (reminderById.data) {
            const processAttachments = async () => {
                const attachmentPromises = reminderById.data.attachments.map(
                    async (attachment) => {
                        let file = "";
                        if (!attachment.preSignedUrl) {
                            file = await base64ToFile(
                                attachment.content,
                                attachment.fileName,
                                attachment.fileType
                            );
                        }
                        return { ...attachment, file };
                    }
                );

                try {
                    const attachments = await Promise.all(attachmentPromises);
                    setFiles(
                        attachments.filter(
                            (attachment) => !!attachment.fileName.split("/")[2]
                        )
                    );
                } catch (error) {
                    console.error("Error processing attachments:", error);
                }
            };

            processAttachments();

            setOpenEditReminderDialog(true);
        }
    }, [reminderById]);

    const [uploadAttachment, { isLoading: isLoadingUploadAttachment }] =
        useUploadAttachmentMutation();

    const [deleteAttachment, { isLoading: isLoadingDeleteAttachment }] =
        useDeleteAttachmentMutation();

    const [loadingFiles, setLoadingFiles] = useState(false);
    const [isToUpdateReminder, setIsToUpdateReminder] = useState(false);

    const handleEditReminderFormSubmit = async (values, onSubmitProps) => {
        if (!values.endDate || values.endDate === "") {
            setErrMsg(t("requiredReminderDate"));
        } else {
            let attachments = [];
            setLoadingFiles(true);
            let isToReload = false;
            try {
                // add only the new attachments
                const _files = files.filter((file) => !!file.file);

                await Promise.all(
                    _files.map(async (file) => {
                        if (
                            values.attachments.length === 0 ||
                            values.attachments.filter(
                                (a) => a.fileName === file.fileName
                            ).length === 0
                        ) {
                            let attachment = {
                                id: 0,
                                fileName: file.fileName,
                                content: file.file,
                                fileType: file.fileType,
                            };
                            attachments.push(attachment);
                        }
                    })
                );

                let newAttachments = [...attachments.filter((a) => a.id === 0)];

                let attachmentsToAdd = {
                    attachments: [...newAttachments],
                };

                // region delete attachments
                let attachmentsToDelete = [];
                values.attachments.forEach((attachment) => {
                    const _attachment = {
                        ...attachment,
                        fileName: attachment.fileName.split("/")[2],
                        fileType: attachment.fileType || "image/jpg",
                    };
                    if (
                        files.filter((f) => f.fileName === attachment.fileName)
                            .length === 0
                    ) {
                        delete _attachment.preSignedUrl;
                        if (attachment.fileName.split("/")[2]) {
                            attachmentsToDelete.push(_attachment);
                        }
                    }
                });

                const createPromises = [];

                if (attachmentsToAdd.attachments.length > 0) {
                    isToReload = true;
                    attachmentsToAdd.attachments.forEach((attachment) => {
                        let addAttachmentsDTO = {
                            Id: parseInt(reminderId),
                            Attachment: attachment,
                            EntityName: "Reminders",
                        };

                        // Store the promise in the array
                        const promise =
                            uploadAttachment(addAttachmentsDTO).unwrap();

                        createPromises.push(promise);
                    });

                    await Promise.all(createPromises).then(async (results) => {
                        // Create an array of promises from results
                        const processPromises = results.map(
                            async ({
                                codeResult,
                                signedUrl,
                                expiresIn,
                                fileName,
                            }) => {
                                if (
                                    codeResult !==
                                    RESPONSES.client.sucessToAddAttachments
                                ) {
                                    handleSnackbarOpen(
                                        t("anErrorOcurred"),
                                        "error"
                                    );
                                } else {
                                    const attachment =
                                        attachmentsToAdd.attachments.find(
                                            (attachment) =>
                                                attachment.fileName === fileName
                                        );

                                    return fetch(signedUrl, {
                                        method: "PUT",
                                        body: attachment.content,
                                        headers: {
                                            "Content-Type":
                                                "multipart/form-data",
                                            mimeType: attachment.fileType,
                                        },
                                    });
                                }
                            }
                        );

                        // Wait for all processes to complete
                        await Promise.all(processPromises);
                        handleSnackbarOpen(t("sucess"), "success");
                    });
                }

                const deletePromises = [];

                if (attachmentsToDelete.length > 0) {
                    isToReload = true;
                    attachmentsToDelete.forEach((attachment) => {
                        let addAttachmentsDTO = {
                            Id: parseInt(reminderId),
                            Attachment: attachment,
                            EntityName: "Reminders",
                        };
                        // Store the promise in the array
                        const promise =
                            deleteAttachment(addAttachmentsDTO).unwrap();

                        deletePromises.push(promise);
                    });

                    await Promise.all(deletePromises).then((results) => {
                        // Handle the results after all promises are resolved
                        results.forEach(({ codeResult }) => {
                            if (
                                codeResult !==
                                RESPONSES.client.sucessToAddAttachments
                            ) {
                                handleSnackbarOpen(
                                    t("anErrorOcurred"),
                                    "error"
                                );
                            } else {
                                handleSnackbarOpen(t("sucess"), "success");
                            }
                        });
                    });
                }
            } catch (err) {
                handleSnackbarOpen(t("anErrorOcurred"), "error");
            }
            setLoadingFiles(false);

            if (isToUpdateReminder === true) {
                values.attachments = null;
                const { codeResult } = await editReminder(values).unwrap();

                if (codeResult !== RESPONSES.reminder.sucessToUpdateReminder) {
                    handleSnackbarOpen(t("errorToUpdateReminder"), "error");
                } else {
                    onSubmitProps.resetForm();
                    handleSnackbarOpen(t("sucessToUpdateReminder"), "success");
                    handleCloseEditReminderDialog();
                }
                setIsToUpdateReminder(false);
            } else {
                if (isToReload === true) {
                    await getReminderById(reminderId);
                }
            }
        }
    };

    //#ENDREGION REMINDERS

    //#REGION ALERTS
    const [openAlertsDialog, setOpenAlertsDialog] = useState(false);

    const handleOpenAlertsDialog = () => {
        setOpenAlertsDialog(true);
    };

    const handleCloseAlertsDialog = () => {
        setOpenAlertsDialog(false);
    };

    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        if (alerts) {
            let alertsCount = 0;
            alerts.forEach((alert) => {
                if (
                    alert.employee_Alerts.length > 0 ||
                    alert.vehicle_Alerts.length > 0 ||
                    alert.reminder_Alerts.length > 0
                ) {
                    alertsCount = alertsCount + 1;
                }
            });

            setActiveAlerts(alertsCount);

            if (alertsCount > 0) {
                setShowAlert(true);
                let timer = setTimeout(() => {
                    setShowAlert(false);
                }, 30000);

                return () => {
                    clearTimeout(timer);
                };
            }
        }
    }, [alerts]);

    //#ENDREGION ALERTS
    const handleDeleteReminder = async (reminder) => {
        setReminderIdToDelete(reminder.id);
        setOpenDeleteReminderDialog(true);
    };

    const [deleteReminder] =
        useDeleteReminderByIdMutation(); /* Set the Delete cost accomodation Mutation */

    const handleDeleteReminderSubmit = async () => {
        try {
            const { codeResult } = await deleteReminder(
                reminderIdToDelete
            ).unwrap();

            if (codeResult !== RESPONSES.reminder.sucessToDeleteReminder) {
                handleSnackbarOpen(t("errorToDeleteReminder"), "error");
            } else {
                setOpenDeleteReminderDialog(false);
                handleSnackbarOpen(t("sucessToDeleteReminder"), "success");
            }
        } catch (err) {
            handleSnackbarOpen(t("errorToDeleteReminder"), "error");
        }
    };

    //#REGION LOUGOUT
    const [sendLogout, { isSuccess }] = useSendLogoutMutation(userId);

    const handleLogout = async () => {
        const userToDeleteToken = {
            id: userId,
        };
        const { codeResult } = await sendLogout(userToDeleteToken).unwrap();
        if (codeResult === RESPONSES.user.sucessToLogout) {
            navigate("/");
            setAnchorEl(null);
        }
    };

    //#ENDREGION LOGOUT

    const handleNavigateMyInformation = () => {
        navigate(`/` + t("users") + `/${userId}`);
        setAnchorEl(null);
    };

    useEffect(() => {
        if (isSuccess) navigate("/");
    }, [isSuccess, navigate]);

    return (
        <AppBar
            sx={{
                position: "sticky",
                background: "none",
                boxShadow: "none",
                paddingRight: "unset !important",
            }}
        >
            <Toolbar
                sx={{
                    justifyContent: "space-between",
                    // backgroundColor: theme.palette.background.C,
                    backgroundColor: "#FFFFFF",
                    minHeight: "50px",
                    paddingLeft: "10px",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    boxShadow: "8px 12px 15px rgba(0, 0, 0, 0.1)", // Adiciona uma sombra
                }}
            >
                {/* LEFT SIDE */}
                <FlexBetween gap="1rem">
                    {!isNonMobile && (
                        <IconButton
                            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                        >
                            <MenuIcon sx={{ fontSize: "25px" }} />
                        </IconButton>
                    )}
                </FlexBetween>

                {/* RIGHT SIDE */}
                <Box display="flex" gap="1.5rem">
                    {/* <IconButton onClick={() => dispatch(setMode())}>
                        {theme.palette.mode === "dark" ? (
                            <DarkModeOutlined sx={{ fontSize: "25px" }} />
                        ) : (
                            <LightModeOutlined sx={{ fontSize: "25px" }} />
                        )}
                    </IconButton>
                    <IconButton>
                        <SettingsOutlined sx={{ fontSize: "25px" }} />
                    </IconButton> */}

                    <FlexBetween>
                        <IconButton
                            size="large"
                            aria-label="show 17 new notifications"
                            color="inherit"
                            sx={{ marginRight: "3px", marginTop: "7px" }}
                            onClick={handleOpenAlertsDialog}
                        >
                            <Badge badgeContent={activeAlerts} color="error">
                                <NotificationsIcon
                                    sx={{
                                        color: "black",
                                        height: "1.5rem",
                                        width: "1.5rem",
                                    }}
                                />
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="large"
                            aria-label="show 17 new notifications"
                            color="inherit"
                            sx={{ marginTop: "7px" }}
                            onClick={handleOpenRemindersDialog}
                        >
                            <EventIcon
                                sx={{
                                    color: "black",
                                    height: "1.5rem",
                                    width: "1.5rem",
                                }}
                            />
                        </IconButton>
                        <Button
                            onClick={handleClick}
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                textTransform: "none",
                                gap: "1rem",
                            }}
                        >
                            {userPhoto ? (
                                <Box
                                    component="img"
                                    alt="profile"
                                    src={userPhoto}
                                    height="32px"
                                    width="32px"
                                    borderRadius="50%"
                                    sx={{ objectFit: "cover" }}
                                />
                            ) : null}
                            <Box textAlign="left">
                                <Typography
                                    fontWeight="bold"
                                    fontSize="0.85rem"
                                    sx={{ color: theme.palette.primary.main }}
                                >
                                    {username}
                                </Typography>
                                <Typography
                                    fontSize="0.75rem"
                                    sx={{ color: theme.palette.primary.main }}
                                >
                                    {userType}
                                </Typography>
                            </Box>
                            <ArrowDropDownOutlined
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontSize: "25px",
                                }}
                            />
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={isOpen}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                        >
                            <MenuItem onClick={handleNavigateMyInformation}>
                                {t("myInformation")}
                            </MenuItem>
                            <MenuItem onClick={handleLogout}>Sair</MenuItem>
                        </Menu>
                    </FlexBetween>
                </Box>
            </Toolbar>

            {/** ALERTS */}
            {isLoadingAlerts || !alerts ? (
                <LoadingSpinner />
            ) : (
                <>
                    <Dialog
                        maxWidth="800px"
                        open={openAlertsDialog}
                        onClose={handleCloseAlertsDialog}
                        width="800px"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle
                            id="alert-dialog-title"
                            fontSize="25px"
                            sx={{ color: "#0071BB" }}
                        >
                            {t("alerts")}
                        </DialogTitle>
                        <DialogContent sx={{ padding: "0 24px" }}>
                            <List
                                sx={{
                                    width: !isNonMobile ? "100%" : "40rem",
                                    bgcolor: "background.paper",
                                }}
                            >
                                {alerts &&
                                    alerts.map((alert) => {
                                        return (
                                            <Box key={alert.id}>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                sx={{
                                                                    fontWeight:
                                                                        "bold",
                                                                    fontSize:
                                                                        "17px",
                                                                }}
                                                            >
                                                                {alert.alertTypeId ===
                                                                SETTINGS
                                                                    .AlertType
                                                                    .EpisAlert
                                                                    ? t("epi") +
                                                                      t(
                                                                          "fifteenDays"
                                                                      )
                                                                    : alert.alertTypeId ===
                                                                      SETTINGS
                                                                          .AlertType
                                                                          .SkillsSheetAlert
                                                                    ? t(
                                                                          "skillsSheet"
                                                                      ) +
                                                                      t(
                                                                          "fifteenDays"
                                                                      )
                                                                    : alert.alertTypeId ===
                                                                      SETTINGS
                                                                          .AlertType
                                                                          .SickLeavesAlert
                                                                    ? t(
                                                                          "sickLeave"
                                                                      ) +
                                                                      t(
                                                                          "fiveDays"
                                                                      )
                                                                    : alert.alertTypeId ===
                                                                      SETTINGS
                                                                          .AlertType
                                                                          .PayIUCAlert
                                                                    ? t("IUC") +
                                                                      t(
                                                                          "fiveDays"
                                                                      )
                                                                    : alert.alertTypeId ===
                                                                      SETTINGS
                                                                          .AlertType
                                                                          .InspectionDateAlert
                                                                    ? t(
                                                                          "inspection"
                                                                      ) +
                                                                      t(
                                                                          "oneMonth"
                                                                      )
                                                                    : t(
                                                                          "reminder"
                                                                      )}
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            (alert.alertTypeId ===
                                                                SETTINGS
                                                                    .AlertType
                                                                    .EpisAlert ||
                                                                alert.alertTypeId ===
                                                                    SETTINGS
                                                                        .AlertType
                                                                        .SkillsSheetAlert ||
                                                                alert.alertTypeId ===
                                                                    SETTINGS
                                                                        .AlertType
                                                                        .SickLeavesAlert) &&
                                                            alert
                                                                .employee_Alerts
                                                                .length > 0
                                                                ? alert.employee_Alerts.map(
                                                                      (
                                                                          employee
                                                                      ) => (
                                                                          <React.Fragment
                                                                              key={
                                                                                  employee.id
                                                                              }
                                                                          >
                                                                              <Typography
                                                                                  sx={{
                                                                                      paddingLeft:
                                                                                          "10px",
                                                                                      fontSize:
                                                                                          "16px",
                                                                                      display:
                                                                                          "inline",
                                                                                  }}
                                                                                  component="span"
                                                                                  variant="body2"
                                                                                  color="gray"
                                                                              >
                                                                                  {" - " +
                                                                                      employee
                                                                                          .employee
                                                                                          .name}
                                                                              </Typography>
                                                                              <br></br>
                                                                          </React.Fragment>
                                                                      )
                                                                  )
                                                                : (alert.alertTypeId ===
                                                                      SETTINGS
                                                                          .AlertType
                                                                          .PayIUCAlert ||
                                                                      alert.alertTypeId ===
                                                                          SETTINGS
                                                                              .AlertType
                                                                              .InspectionDateAlert) &&
                                                                  alert
                                                                      .vehicle_Alerts
                                                                      .length >
                                                                      0
                                                                ? alert.vehicle_Alerts.map(
                                                                      (
                                                                          vehicle
                                                                      ) => (
                                                                          <React.Fragment
                                                                              key={
                                                                                  vehicle.id
                                                                              }
                                                                          >
                                                                              <Typography
                                                                                  sx={{
                                                                                      paddingLeft:
                                                                                          "10px",
                                                                                      fontSize:
                                                                                          "16px",
                                                                                      display:
                                                                                          "inline",
                                                                                  }}
                                                                                  component="span"
                                                                                  variant="body2"
                                                                                  color="gray"
                                                                              >
                                                                                  {" - " +
                                                                                      vehicle
                                                                                          .vehicle
                                                                                          .licensePlateNumber}
                                                                              </Typography>
                                                                              <br></br>
                                                                          </React.Fragment>
                                                                      )
                                                                  )
                                                                : alert
                                                                      .reminder_Alerts
                                                                      .length >
                                                                  0
                                                                ? alert.reminder_Alerts.map(
                                                                      (
                                                                          reminder
                                                                      ) => (
                                                                          <React.Fragment
                                                                              key={
                                                                                  reminder.id
                                                                              }
                                                                          >
                                                                              <Typography
                                                                                  sx={{
                                                                                      paddingLeft:
                                                                                          "10px",
                                                                                      fontSize:
                                                                                          "16px",
                                                                                      display:
                                                                                          "inline",
                                                                                  }}
                                                                                  component="span"
                                                                                  variant="body2"
                                                                                  color="gray"
                                                                              >
                                                                                  {" - " +
                                                                                      reminder
                                                                                          .reminder
                                                                                          .description +
                                                                                      " ( <= " +
                                                                                      reminder
                                                                                          .reminder
                                                                                          .numberOfDaysAlert +
                                                                                      t(
                                                                                          "days"
                                                                                      ) +
                                                                                      " )"}
                                                                              </Typography>
                                                                              <br></br>
                                                                          </React.Fragment>
                                                                      )
                                                                  )
                                                                : null // You may need to handle the case when the conditions are not met
                                                        }
                                                    />
                                                </ListItem>
                                                <Divider
                                                    variant="inset"
                                                    component="li"
                                                    sx={{ marginLeft: "0" }}
                                                />
                                            </Box>
                                        );
                                    })}
                            </List>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                    lineHeight: "0",
                                    padding: "14px",
                                }}
                                onClick={handleCloseAlertsDialog}
                            >
                                {t("close")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
            {/** REMINDERS */}
            {isLoadingReminders || !reminders ? (
                <LoadingSpinner />
            ) : (
                <>
                    <Dialog
                        maxWidth="800px"
                        open={openRemindersDialog}
                        onClose={handleCloseRemindersDialog}
                        width={!isNonMobile ? "100%" : "800px"}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <Box display="flex" justifyContent="space-between">
                            <DialogTitle
                                id="alert-dialog-title"
                                fontSize="25px"
                                sx={{ color: "#0071BB" }}
                            >
                                {t("reminders")}
                            </DialogTitle>
                            <IconButton
                                size="large"
                                color="inherit"
                                sx={{
                                    marginRight: "10px",
                                    alignSelf: "center",
                                }}
                                onClick={handleOpenNewReminderDialog}
                            >
                                <AddOutlinedIcon
                                    sx={{
                                        color: "#0071BB",
                                        height: "1.5rem",
                                        width: "1.5rem",
                                    }}
                                />
                            </IconButton>
                        </Box>
                        <DialogContent sx={{ padding: "0 24px" }}>
                            <List
                                sx={{
                                    width: !isNonMobile ? "100%" : "40rem",
                                    bgcolor: "background.paper",
                                }}
                            >
                                {reminders &&
                                    reminders.map((reminder) => {
                                        return (
                                            <Box key={reminder.id}>
                                                <ListItem alignItems="flex-start">
                                                    <Box
                                                        display="flex"
                                                        width="100%"
                                                        justifyContent="space-between"
                                                    >
                                                        <ListItemText
                                                            primary={
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight:
                                                                            "bold",
                                                                        fontSize:
                                                                            "16px",
                                                                    }}
                                                                >
                                                                    {format(
                                                                        new Date(
                                                                            reminder.endDate
                                                                        ),
                                                                        "dd/MM/yyyy"
                                                                    )}
                                                                </Typography>
                                                            }
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography
                                                                        sx={{
                                                                            paddingLeft:
                                                                                "10px",
                                                                            fontSize:
                                                                                "16px",
                                                                            display:
                                                                                "inline",
                                                                        }}
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="gray"
                                                                    >
                                                                        {" - " +
                                                                            reminder.description}
                                                                    </Typography>
                                                                </React.Fragment>
                                                            }
                                                        />
                                                        <IconButton
                                                            size="large"
                                                            color="inherit"
                                                            sx={{
                                                                marginRight:
                                                                    "10px",
                                                                alignSelf:
                                                                    "center",
                                                            }}
                                                            onClick={() =>
                                                                handleDeleteReminder(
                                                                    reminder
                                                                )
                                                            }
                                                        >
                                                            <DeleteIcon
                                                                sx={{
                                                                    alignSelf:
                                                                        "center",
                                                                }}
                                                            ></DeleteIcon>
                                                        </IconButton>
                                                        <IconButton
                                                            size="large"
                                                            color="inherit"
                                                            sx={{
                                                                marginRight:
                                                                    "10px",
                                                                alignSelf:
                                                                    "center",
                                                            }}
                                                            onClick={() => {
                                                                handleEditReminder(
                                                                    reminder
                                                                );
                                                                setReminderId(
                                                                    reminder.id
                                                                );
                                                            }}
                                                        >
                                                            <EditIcon
                                                                sx={{
                                                                    alignSelf:
                                                                        "center",
                                                                }}
                                                            ></EditIcon>
                                                        </IconButton>
                                                    </Box>
                                                </ListItem>
                                                <Divider
                                                    variant="inset"
                                                    component="li"
                                                    sx={{ marginLeft: "0" }}
                                                />
                                            </Box>
                                        );
                                    })}
                            </List>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                    lineHeight: "0",
                                    padding: "14px",
                                }}
                                onClick={handleCloseRemindersDialog}
                            >
                                {t("close")}
                            </Button>
                        </DialogActions>
                        <Snackbar
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            open={openSnackbar}
                            autoHideDuration={6000}
                            onClose={handleCloseSnackBar}
                        >
                            <Alert
                                onClose={handleCloseSnackBar}
                                severity={snackbarSeverity}
                                sx={{
                                    mt: "10px",
                                    backgroundColor:
                                        snackbarSeverity === "success"
                                            ? "#10781F"
                                            : "#ff000082",
                                    alignItems: "center",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    color: "#fff",
                                }}
                            >
                                {snackbarMsg}
                            </Alert>
                        </Snackbar>
                    </Dialog>
                </>
            )}
            {/** NEW REMINDER */}
            <Dialog
                open={openNewReminderDialog}
                onClose={handleCloseNewReminderDialog}
                maxWidth="800px"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValuesReminder}
                    validationSchema={reminderSchema}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        resetForm,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                m="1.5rem 2.5rem"
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(1, auto)"
                                }
                                gap="20px"
                                borderRadius="5px"
                                width={!isNonMobile ? undefined : "40rem"}
                            >
                                <PageHeader
                                    title={t("newReminderTitle")}
                                    subtitle=""
                                />
                                <Box
                                    display="flex"
                                    gap="20px"
                                    flexDirection="column"
                                    width="100%"
                                >
                                    <Typography
                                        fontWeight="500"
                                        fontSize="20px"
                                        color="primary"
                                        variant="h5"
                                        textAlign="left"
                                    >
                                        {t("reminderInformation")}
                                    </Typography>
                                    <Box
                                        display="grid"
                                        gridTemplateColumns={
                                            !isNonMobile
                                                ? "repeat(1, 1fr)"
                                                : "repeat(2, 1fr)"
                                        }
                                        gap="10px"
                                    >
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="pt"
                                        >
                                            <DatePicker
                                                label={t("endDate")}
                                                value={values.endDate}
                                                format="DD/MM/YYYY"
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "endDate",
                                                        value
                                                    )
                                                }
                                            />
                                        </LocalizationProvider>
                                        <TextField
                                            variant="outlined"
                                            label={t("description")}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.description}
                                            name="description"
                                            error={
                                                Boolean(touched.description) &&
                                                Boolean(errors.description)
                                            }
                                            helperText={
                                                touched.description &&
                                                errors.description
                                            }
                                            sx={{ width: "100%" }}
                                            multiline
                                        />
                                        <TextField
                                            variant="outlined"
                                            label={t("numberOfDaysToAlert")}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.numberOfDaysAlert}
                                            name="numberOfDaysAlert"
                                            error={
                                                Boolean(
                                                    touched.numberOfDaysAlert
                                                ) &&
                                                Boolean(
                                                    errors.numberOfDaysAlert
                                                )
                                            }
                                            helperText={
                                                touched.numberOfDaysAlert &&
                                                errors.numberOfDaysAlert
                                            }
                                            sx={{ width: "100%" }}
                                        />
                                    </Box>
                                </Box>
                                {errMsg && (
                                    <Alert
                                        severity="error"
                                        sx={{
                                            mt: "10px",
                                            backgroundColor: "#540e0e",
                                            color: "#FFFFFF",
                                            alignItems: "center",
                                        }}
                                    >
                                        {errMsg}
                                    </Alert>
                                )}
                                {/* BUTTONS */}
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    loading={isLoadingCreateReminder}
                                    sx={{
                                        width: "100%",
                                        p: "1rem",
                                        backgroundColor:
                                            theme.palette.primary.main,
                                        color: theme.palette.neutral.white,
                                        "&:hover": {
                                            backgroundColor:
                                                theme.palette.primary.light,
                                        },
                                    }}
                                >
                                    {t("newReminderTitle")}
                                </LoadingButton>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Dialog>
            {/** EDIT REMINDER */}
            <Dialog
                open={openEditReminderDialog}
                onClose={handleCloseEditReminderDialog}
                maxWidth="800px"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Formik
                    onSubmit={handleEditReminderFormSubmit}
                    initialValues={reminderById.data || initialValuesReminder}
                    validationSchema={reminderSchema}
                    enableReinitialize={true}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        resetForm,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            {loadingFiles ? <LoadingSpinner /> : null}
                            <Box
                                m="1.5rem 2.5rem"
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(1, auto)"
                                }
                                gap="20px"
                                borderRadius="5px"
                                width={!isNonMobile ? undefined : "80rem"}
                            >
                                <PageHeader
                                    title={t("editReminder")}
                                    subtitle=""
                                />
                                <Box
                                    display="flex"
                                    gap="20px"
                                    flexDirection="column"
                                    width="100%"
                                >
                                    <Typography
                                        fontWeight="500"
                                        fontSize="20px"
                                        color="primary"
                                        variant="h5"
                                        textAlign="left"
                                    >
                                        {t("reminderInformation")}
                                    </Typography>
                                    <Box
                                        display="grid"
                                        gridTemplateColumns={
                                            !isNonMobile
                                                ? "repeat(1, 1fr)"
                                                : "repeat(3, 1fr)"
                                        }
                                        gap="10px"
                                    >
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="pt"
                                        >
                                            <DatePicker
                                                label={t("endDate")}
                                                value={dayjs(values.endDate)}
                                                format="DD/MM/YYYY"
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "endDate",
                                                        value
                                                    );
                                                    setIsToUpdateReminder(true);
                                                }}
                                            />
                                        </LocalizationProvider>
                                        <TextField
                                            variant="outlined"
                                            label={t("description")}
                                            onBlur={handleBlur}
                                            value={values.description}
                                            name="description"
                                            onChange={(event) => {
                                                handleChange(event);
                                                setIsToUpdateReminder(true); // Atualiza a flag sem substituir handleChange
                                            }}
                                            error={
                                                Boolean(touched.description) &&
                                                Boolean(errors.description)
                                            }
                                            helperText={
                                                touched.description &&
                                                errors.description
                                            }
                                            sx={{ width: "100%" }}
                                            multiline
                                        />
                                        <TextField
                                            variant="outlined"
                                            label={t("numberOfDaysToAlert")}
                                            onBlur={handleBlur}
                                            value={values.numberOfDaysAlert}
                                            name="numberOfDaysAlert"
                                            onChange={(event) => {
                                                handleChange(event);
                                                setIsToUpdateReminder(true); // Atualiza a flag sem substituir handleChange
                                            }}
                                            error={
                                                Boolean(
                                                    touched.numberOfDaysAlert
                                                ) &&
                                                Boolean(
                                                    errors.numberOfDaysAlert
                                                )
                                            }
                                            helperText={
                                                touched.numberOfDaysAlert &&
                                                errors.numberOfDaysAlert
                                            }
                                            sx={{ width: "100%" }}
                                        />
                                    </Box>
                                    {errMsg && (
                                        <Alert
                                            severity="error"
                                            sx={{
                                                mt: "10px",
                                                backgroundColor: "#540e0e",
                                                color: "#FFFFFF",
                                                alignItems: "center",
                                            }}
                                        >
                                            {errMsg}
                                        </Alert>
                                    )}
                                    <Box
                                        display="flex"
                                        gap="20px"
                                        flexDirection="column"
                                        width="100%"
                                    >
                                        <Typography
                                            fontWeight="500"
                                            fontSize="20px"
                                            color="primary"
                                            variant="h5"
                                            textAlign="left"
                                        >
                                            {t("attachments")}
                                        </Typography>
                                        <Dropzone
                                            multiple={true}
                                            onDrop={onDropFiles}
                                        >
                                            {({
                                                getRootProps,
                                                getInputProps,
                                            }) => (
                                                <FlexBetween>
                                                    <Box
                                                        {...getRootProps()}
                                                        border={`2px dashed ${theme.palette.primary.main}`}
                                                        p="1rem"
                                                        width="100%"
                                                        display="grid"
                                                        gridTemplateColumns={
                                                            !isNonMobile
                                                                ? "repeat(1, 1fr)"
                                                                : "repeat(5, auto)"
                                                        }
                                                        gap="10px"
                                                        borderRadius="5px"
                                                        sx={{
                                                            "&:hover": {
                                                                cursor: "pointer",
                                                            },
                                                        }}
                                                    >
                                                        <input
                                                            {...getInputProps()}
                                                        />
                                                        {files.length === 0 ? (
                                                            <p>
                                                                {t(
                                                                    "dropzoneAddYouFiles"
                                                                )}
                                                            </p>
                                                        ) : (
                                                            files.map(
                                                                (file) => (
                                                                    <Card
                                                                        sx={{
                                                                            backgroundColor:
                                                                                theme
                                                                                    .palette
                                                                                    .background
                                                                                    .D,
                                                                            display:
                                                                                "flex",
                                                                            alignItems:
                                                                                "center",
                                                                            gap: "10px",
                                                                            p: "15px 10px 15px 0px",
                                                                            "& .MuiCardContent-root:last-child":
                                                                                {
                                                                                    paddingBottom:
                                                                                        "20px",
                                                                                },
                                                                        }}
                                                                        key={
                                                                            file.fileName
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) =>
                                                                            handleFilePreview(
                                                                                event,
                                                                                file
                                                                            )
                                                                        }
                                                                    >
                                                                        <Box
                                                                            sx={{
                                                                                display:
                                                                                    "flex",
                                                                                alignItems:
                                                                                    "center",
                                                                                // pl: 1,
                                                                                // pb: 1,
                                                                            }}
                                                                        >
                                                                            <IconButton
                                                                                onClick={(
                                                                                    event
                                                                                ) =>
                                                                                    handleRemoveFile(
                                                                                        event,
                                                                                        file
                                                                                    )
                                                                                }
                                                                            >
                                                                                <DeleteOutlined />
                                                                            </IconButton>
                                                                        </Box>
                                                                        <CardContent
                                                                            sx={{
                                                                                p: "0px !important",
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: 14,
                                                                                }}
                                                                                color="text.secondary"
                                                                            >
                                                                                {file.fileName.split(
                                                                                    "/"
                                                                                )[2]
                                                                                    ? file.fileName.split(
                                                                                          "/"
                                                                                      )[2]
                                                                                    : file.fileName}
                                                                            </Typography>
                                                                        </CardContent>
                                                                    </Card>
                                                                )
                                                            )
                                                        )}
                                                    </Box>
                                                </FlexBetween>
                                            )}
                                        </Dropzone>
                                    </Box>
                                </Box>
                                {/* BUTTONS */}
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    loading={isLoadingEditReminder}
                                    sx={{
                                        width: "100%",
                                        p: "1rem",
                                        backgroundColor:
                                            theme.palette.primary.main,
                                        color: theme.palette.neutral.white,
                                        "&:hover": {
                                            backgroundColor:
                                                theme.palette.primary.light,
                                        },
                                    }}
                                >
                                    {t("editReminder")}
                                </LoadingButton>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Dialog>
            {/* Delete Reminder */}
            <Dialog
                open={openDeleteReminderDialog}
                onClose={handleCloseDeleteReminderDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t("deleteReminderConfirmationTitle")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("deleteReminderConfirmationMessage")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{
                            backgroundColor: theme.palette.alt.red,
                            color: theme.palette.neutral.white,
                            "&:hover": {
                                backgroundColor: theme.palette.alt.lightRed,
                            },
                        }}
                        onClick={handleCloseDeleteReminderDialog}
                    >
                        {t("noMessage")}
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.neutral.white,
                            "&:hover": {
                                backgroundColor: theme.palette.primary.light,
                            },
                        }}
                        onClick={handleDeleteReminderSubmit}
                        autoFocus
                    >
                        {t("yesMessage")}
                    </Button>
                </DialogActions>
            </Dialog>
            {showAlert && activeAlerts > 0 && (
                <Alert severity="warning">
                    {t("analiseYourAlers")} {activeAlerts} {t("alertsSpace")}
                </Alert>
            )}
        </AppBar>
    );
};

export default Navbar;

import { useState, useRef, useEffect } from "react";
import {
    useGetCostCategoryCategoriesQuery,
    useGetCostCategoryFirstTypesQuery,
    useGetCostCategorySecondTypesQuery,
    usePostCostCategoryMutation,
} from "./costCategoriesApiSlice";
import PageHeader from "components/PageHeader";
import {
    Box,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
    Autocomplete,
    Alert,
    FormControl,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import { Formik } from "formik";
import * as yup from "yup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";
import { useGetVehiclesQuery } from "features/vehicles/vehiclesApiSlice";
import { useGetAccomodationsQuery } from "features/accomodations/accomodationsApiSlice";
import { SETTINGS } from "helpers/settings";
import { useGetNotOfficeEmployeesQuery } from "features/employees/employeesApiSlice";
import { useGetAllPrivateWorkPlanningsQuery } from "features/workPlanning/workPlanningsApiSlice";

const NewCostCategoryForm = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const nameRef = useRef(); /* User Ref to set the focus to the user input */

    const [errMsg, setErrMsg] = useState(""); /* Set the Error Message State */
    const [openSnackbar, setOpenSnackBar] = useState(false);

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    };

    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [selectedAccomodation, setSelectedAccomodation] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedWorkPlanning, setSelectedWorkPlanning] = useState(null);

    const costCategorySchema = yup.object().shape({
        costCategoryCategoryId: yup.string().required(t("requiredField")),
        costCategoryFirstTypeId: yup.string().required(t("requiredField")),
        costCategorySecondTypeId: yup.string().required(t("requiredField")),
        monthCost: yup.date().notRequired(),
        description: yup.string().notRequired(),
        cost: yup
            .number(t("invalidValue"))
            .required(t("requiredField"))
            .typeError(t("invalidValue")),
    });

    const initialValuesCostCategory = {
        costCategoryCategoryId: "",
        costCategoryFirstTypeId: "",
        costCategorySecondTypeId: "",
        description: "",
        monthCost: null,
        cost: "",
    };

    const { data: workPlannings, isLoading: isLoadingWorkPlannings } =
        useGetAllPrivateWorkPlanningsQuery();

    const {
        data: costCategoryCategories,
        isLoading: isLoadingCategoryCategories,
    } = useGetCostCategoryCategoriesQuery();

    const { data: vehicles, isLoading: isLoadingVehicles } =
        useGetVehiclesQuery();

    const { data: accomodations, isLoading: isLoadingAccomodations } =
        useGetAccomodationsQuery();

    const { data: employees, isLoading: isLoadingEmployees } =
        useGetNotOfficeEmployeesQuery();

    const {
        data: costCategoryFirstTypes,
        isLoading: isLoadingcostCategoryFirstTypes,
    } = useGetCostCategoryFirstTypesQuery();

    const {
        data: costCategorySecondTypes,
        isLoading: isLoadingCostCategorySecondTypes,
    } = useGetCostCategorySecondTypesQuery();

    const [createCostCategory, { isLoading }] = usePostCostCategoryMutation();

    useEffect(() => {
        if (selectedAccomodation) {
            setSelectedVehicle(null);
            setSelectedEmployee(null);
            setSelectedWorkPlanning(null);
        }
    }, [selectedAccomodation]);

    useEffect(() => {
        if (selectedVehicle) {
            setSelectedAccomodation(null);
            setSelectedEmployee(null);
            setSelectedWorkPlanning(null);
        }
    }, [selectedVehicle]);

    useEffect(() => {
        if (selectedEmployee) {
            setSelectedVehicle(null);
            setSelectedAccomodation(null);
            setSelectedWorkPlanning(null);
        }
    }, [selectedEmployee]);

    useEffect(() => {
        if (selectedWorkPlanning) {
            setSelectedAccomodation(null);
            setSelectedEmployee(null);
            setSelectedVehicle(null);
        }
    }, [selectedWorkPlanning]);

    const handleFormSubmit = async (values, onSubmitProps) => {
        setErrMsg("");

        try {
            if (!values.monthCost || values.monthCost === "") {
                setErrMsg(t("requiredMonthCost"));
            } else {
                const costCategoryToInsert = {
                    costCategoryCategoryId: values.costCategoryCategoryId,
                    costCategoryFirstTypeId: values.costCategoryFirstTypeId,
                    costCategorySecondTypeId: values.costCategorySecondTypeId,
                    vehicleId: selectedVehicle ? selectedVehicle.id : null,
                    accomodationId: selectedAccomodation
                        ? selectedAccomodation.id
                        : null,
                    employeeId: selectedEmployee ? selectedEmployee.id : null,
                    workPlanningId: selectedWorkPlanning
                        ? selectedWorkPlanning.id
                        : null,
                    description: values.description,
                    cost: values.cost,
                    monthCost: values.monthCost,
                };

                const { codeResult } = await createCostCategory(
                    costCategoryToInsert
                ).unwrap();

                if (
                    codeResult !==
                    RESPONSES.costCategory.sucessToInsertCostCategory
                ) {
                    setErrMsg(t("errorInsertCostCategory"));
                } else {
                    setOpenSnackBar(true);
                    setSelectedAccomodation(null);
                    setSelectedVehicle(null);
                    onSubmitProps.resetForm();
                }
            }
        } catch (err) {
            setErrMsg(t("errorInsertCostCategory"));
        }
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesCostCategory}
            validationSchema={costCategorySchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        m={!isNonMobile ? "0" : "1.5rem 2.5rem"}
                        display="grid"
                        gridTemplateRows="repeat(3, auto)"
                        gap="20px"
                        borderRadius="5px"
                        p="2rem"
                    >
                        <PageHeader
                            title={t("newCostCategoryTitle")}
                            subtitle=""
                        />
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("costCategoryInformation")}
                            </Typography>
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingCategoryCategories}
                                    options={costCategoryCategories || []}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                        (costCategoryCategories &&
                                            costCategoryCategories.find(
                                                (costCategoryCategory) =>
                                                    costCategoryCategory.id ===
                                                    values.costCategoryCategoryId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "costCategoryCategoryId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                    }}
                                    onBlur={handleBlur(
                                        "costCategoryCategoryId"
                                    )}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t(
                                                    "costCategoryCategory"
                                                )}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.costCategoryCategoryId
                                                    ) &&
                                                    Boolean(
                                                        errors.costCategoryCategoryId
                                                    )
                                                }
                                                helperText={
                                                    touched.costCategoryCategoryId &&
                                                    errors.costCategoryCategoryId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />
                                <TextField
                                    variant="outlined"
                                    label={t("description")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.description}
                                    name="description"
                                    error={
                                        Boolean(touched.description) &&
                                        Boolean(errors.description)
                                    }
                                    helperText={
                                        touched.description &&
                                        errors.description
                                    }
                                    sx={{ width: "100%" }}
                                    multiline
                                />
                                {(values.costCategoryCategoryId ===
                                    SETTINGS.CostCategoryCategoryId.Fuel ||
                                    values.costCategoryCategoryId ===
                                        SETTINGS.CostCategoryCategoryId.Toll ||
                                    values.costCategoryCategoryId ===
                                        SETTINGS.CostCategoryCategoryId
                                            .CarMaintenance) && (
                                    <Autocomplete
                                        variant="outlined"
                                        loading={isLoadingVehicles}
                                        options={vehicles || []}
                                        getOptionLabel={(option) =>
                                            option
                                                ? option.licensePlateNumber
                                                : null
                                        }
                                        value={selectedVehicle}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setSelectedVehicle(newValue);
                                            } else {
                                                setSelectedVehicle(null);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <FormControl fullWidth>
                                                <TextField
                                                    label={t("vehicle")}
                                                    {...params}
                                                />
                                            </FormControl>
                                        )}
                                    />
                                )}

                                {(values.costCategoryCategoryId ===
                                    SETTINGS.CostCategoryCategoryId.Water ||
                                    values.costCategoryCategoryId ===
                                        SETTINGS.CostCategoryCategoryId
                                            .Electricity ||
                                    values.costCategoryCategoryId ===
                                        SETTINGS.CostCategoryCategoryId.Gas ||
                                    values.costCategoryCategoryId ===
                                        SETTINGS.CostCategoryCategoryId
                                            .Repair ||
                                    values.costCategoryCategoryId ===
                                        SETTINGS.CostCategoryCategoryId
                                            .GeneralAccomodationExpenses) && (
                                    <Autocomplete
                                        variant="outlined"
                                        loading={isLoadingAccomodations}
                                        options={accomodations || []}
                                        getOptionLabel={(option) =>
                                            option ? option.address : null
                                        }
                                        value={selectedAccomodation}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setSelectedAccomodation(
                                                    newValue
                                                );
                                            } else {
                                                setSelectedAccomodation(null);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <FormControl fullWidth>
                                                <TextField
                                                    label={t("accomodation")}
                                                    {...params}
                                                />
                                            </FormControl>
                                        )}
                                    />
                                )}

                                {values.costCategoryCategoryId ===
                                    SETTINGS.CostCategoryCategoryId
                                        .ExtraCost && (
                                    <Autocomplete
                                        variant="outlined"
                                        loading={isLoadingEmployees}
                                        options={employees || []}
                                        getOptionLabel={(option) =>
                                            option ? option.name : null
                                        }
                                        value={selectedEmployee}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setSelectedEmployee(newValue);
                                            } else {
                                                setSelectedEmployee(null);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <FormControl fullWidth>
                                                <TextField
                                                    label={t("employee")}
                                                    {...params}
                                                />
                                            </FormControl>
                                        )}
                                    />
                                )}

                                {values.costCategoryCategoryId ===
                                    SETTINGS.CostCategoryCategoryId
                                        .WorkCost && (
                                    <Autocomplete
                                        variant="outlined"
                                        loading={isLoadingWorkPlannings}
                                        options={workPlannings || []}
                                        getOptionLabel={(option) =>
                                            option
                                                ? option.name +
                                                  " - " +
                                                  option.work.name
                                                : null
                                        }
                                        value={selectedWorkPlanning}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setSelectedWorkPlanning(
                                                    newValue
                                                );
                                            } else {
                                                setSelectedWorkPlanning(null);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <FormControl fullWidth>
                                                <TextField
                                                    label={t("planningWork")}
                                                    {...params}
                                                />
                                            </FormControl>
                                        )}
                                    />
                                )}

                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingcostCategoryFirstTypes}
                                    options={costCategoryFirstTypes || []}
                                    getOptionLabel={(option) => option.type}
                                    value={
                                        (costCategoryFirstTypes &&
                                            costCategoryFirstTypes.find(
                                                (costCategoryFirstType) =>
                                                    costCategoryFirstType.id ===
                                                    values.costCategoryFirstTypeId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "costCategoryFirstTypeId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                    }}
                                    onBlur={handleBlur(
                                        "costCategoryFirstTypeId"
                                    )}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("costCategoryType")}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.costCategoryFirstTypeId
                                                    ) &&
                                                    Boolean(
                                                        errors.costCategoryFirstTypeId
                                                    )
                                                }
                                                helperText={
                                                    touched.costCategoryFirstTypeId &&
                                                    errors.costCategoryFirstTypeId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />

                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingCostCategorySecondTypes}
                                    options={costCategorySecondTypes || []}
                                    getOptionLabel={(option) => option.type}
                                    value={
                                        (costCategorySecondTypes &&
                                            costCategorySecondTypes.find(
                                                (costCategorySecondType) =>
                                                    costCategorySecondType.id ===
                                                    values.costCategorySecondTypeId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "costCategorySecondTypeId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                    }}
                                    onBlur={handleBlur(
                                        "costCategorySecondTypeId"
                                    )}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("costCategorySubType")}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.costCategorySecondTypeId
                                                    ) &&
                                                    Boolean(
                                                        errors.costCategorySecondTypeId
                                                    )
                                                }
                                                helperText={
                                                    touched.costCategorySecondTypeId &&
                                                    errors.costCategorySecondTypeId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="pt"
                                >
                                    <DatePicker
                                        label={t("costDate")}
                                        value={values.monthCost}
                                        format="DD/MM/YYYY"
                                        onChange={(value) =>
                                            setFieldValue("monthCost", value)
                                        }
                                    />
                                </LocalizationProvider>
                                <TextField
                                    label={t("monthlyCost")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.cost}
                                    name="cost"
                                    error={
                                        Boolean(touched.cost) &&
                                        Boolean(errors.cost)
                                    }
                                    InputProps={{
                                        endAdornment: "€",
                                    }}
                                    helperText={touched.cost && errors.cost}
                                    sx={{ width: "100%" }}
                                />
                            </Box>
                        </Box>
                        {errMsg && (
                            <Alert
                                severity="error"
                                sx={{
                                    mt: "10px",
                                    backgroundColor: "#540e0e",
                                    color: "#FFFFFF",
                                    alignItems: "center",
                                }}
                            >
                                {errMsg}
                            </Alert>
                        )}
                        {/* BUTTONS */}
                        <LoadingButton
                            fullWidth
                            type="submit"
                            loading={isLoading}
                            sx={{
                                width: "100%",
                                p: "1rem",
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.neutral.white,
                                "&:hover": {
                                    backgroundColor:
                                        theme.palette.primary.light,
                                },
                            }}
                        >
                            {t("addNewCostCategory")}
                        </LoadingButton>
                    </Box>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity="success"
                            sx={{
                                mt: "10px",
                                backgroundColor: "#10781F",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {t("sucessToInsertCostCategory")}
                        </Alert>
                    </Snackbar>
                </form>
            )}
        </Formik>
    );
};

export default NewCostCategoryForm;

import { useState, useRef, useEffect } from "react";
import {
    useGetWorkByIdQuery,
    useUpdateWorkMutation,
    useDeleteWorkByIdMutation,
    useAddWorkContactMutation,
    useDeleteWorkContactMutation,
    useEditWorkContactMutation,
    useGetWorkTypesQuery,
    useGetEmployeePlanningsByWorkIdQuery,
    useGetAccomodationPlanningsByWorkIdQuery,
    useGetVehiclePlanningsByWorkIdQuery,
    useGetWorkExtraCostsByWorkIdQuery,
    usePostWorkExtraCostMutation,
    useUpdateWorkExtraCostMutation,
    useLazyGetWorkExtraCostByIdQuery,
    useDeleteWorkExtraCostByIdMutation,
} from "./worksApiSlice";
import {
    GridToolbarDensitySelector,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import { useGetClientsQuery } from "features/clients/clientsApiSlice";
import PageHeader from "components/PageHeader";
import LoadingSpinner from "components/LoadingSpinner";
import {
    Box,
    Button,
    TextField,
    Typography,
    useTheme,
    Alert,
    Autocomplete,
    IconButton,
    useMediaQuery,
    Card,
    CardContent,
    FormControl,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DeleteOutlined } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";
import FlexBetween from "components/FlexBetween";
import { useParams } from "react-router-dom";
import Dropzone from "react-dropzone";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { format } from "date-fns";
import DataGridCustomWithoutToolbar from "components/DataGridCustomWithoutToolbar";
import { SETTINGS } from "helpers/settings";
import useAuth from "hooks/useAuth";
import BoxDataGrid from "components/dataGrid/BoxDataGrid";
import {
    useDeleteAttachmentMutation,
    useUploadAttachmentMutation,
} from "features/attachments/attachmentsApiSlice";

const EditWorkForm = () => {
    // #region Setups

    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate(); /* Set the Navigation Service */
    const location = useLocation();
    const { palette } = useTheme(); /* Set the Theme Pallet */
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const nameRef = useRef(); /* User Ref to set the focus to the user input */

    const [dayAndNight, setDayAndNight] = useState(false);

    const setDayAndNightState = () => {
        if (dayAndNight) {
            setDayAndNight(false);
        } else {
            setDayAndNight(true);
        }
    };

    const [workCompleted, setWorkCompleted] = useState(false);

    const setWorkCompletedState = () => {
        if (workCompleted !== true) {
            setWorkCompleted(true);
        } else {
            setWorkCompleted(false);
        }
    };

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMsg(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
        useState(false);

    const handleOpenDeleteConfirmationDialog = () => {
        setOpenDeleteConfirmationDialog(true);
    };

    const handleCloseDeleteConfirmationDialog = () => {
        setOpenDeleteConfirmationDialog(false);
    };

    const [openCreateWorkContactDialog, setOpenCreateWorkContactDialog] =
        useState(false);

    const handleOpenCreateWorkContactDialog = () => {
        setOpenCreateWorkContactDialog(true);
    };

    const handleCloseCreateWorkContactDialog = () => {
        setOpenCreateWorkContactDialog(false);
    };

    const [openDeleteWorkContactDialog, setOpenDeleteWorkContactDialog] =
        useState(false);

    const handleOpenDeleteWorkContactDialog = () => {
        setOpenDeleteWorkContactDialog(true);
    };

    const handleCloseDeleteWorkContactDialog = () => {
        setOpenDeleteWorkContactDialog(false);
    };

    const [openEditWorkContactDialog, setOpenEditWorkContactDialog] =
        useState(false);

    const handleOpenEditWorkContactDialog = () => {
        setOpenEditWorkContactDialog(true);
    };

    const handleCloseEditWorkContactDialog = () => {
        setOpenEditWorkContactDialog(false);
    };

    const [
        openAddOrEditWorkExtraCostDialog,
        setOpenAddOrEditWorkExtraCostDialog,
    ] = useState(false);

    const handleOpenAddOrEditWorkExtraCostDialog = () => {
        setOpenAddOrEditWorkExtraCostDialog(true);
    };

    const handleCloseAddOrEditWorkExtraCostDialog = () => {
        setWorkExtraCostForm(null);
        setOpenAddOrEditWorkExtraCostDialog(false);
    };

    const [openDeleteWorkExtraCostDialog, setOpenDeleteWorkExtraCostDialog] =
        useState(false);

    const handleOpenDeleteWorkExtraCostDialog = () => {
        setOpenDeleteWorkExtraCostDialog(true);
    };

    const handleCloseDeleteWorkExtraCostDialog = () => {
        setOpenDeleteWorkExtraCostDialog(false);
    };

    const [files, setFiles] = useState([]);
    const [workAccomodations, setWorkAccomodations] = useState([]);
    const [workEmployees, setWorkEmployees] = useState([]);
    // const [workSuppliers, setWorkSuppliers] = useState([]);
    const [workVehicles, setWorkVehicles] = useState([]);
    const [workContacts, setWorkContacts] = useState([]);

    //#endregion Setups

    // Work contact schema
    const contactSchema = yup.object().shape({
        name: yup.string().required(t("requiredField")),
        position: yup.string().required(t("requiredField")),
        email: yup
            .string()
            .email(t("invalidEmail"))
            .required(t("requiredField")),
        phoneNumber: yup.string().required(t("requiredField")),
    }); /* Set the Work Schema */

    const initialValuesContact = {
        name: "",
        email: "",
        position: "",
        phoneNumber: "",
    }; /* Set the Form Initial Values to Contact*/

    // #region Work Contacts
    // # region Supplier Contacts
    const workContactsColumns = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
        },
        {
            field: "email",
            headerName: t("email"),
            flex: 1,
        },
        {
            field: "position",
            headerName: t("position"),
            flex: 1,
        },
        {
            field: "phoneNumber",
            headerName: t("phoneNumber"),
            flex: 1,
        },
        {
            field: "action",
            headerName: t("edit"),
            flex: 1,
            renderCell: (row) => (
                <EditIcon onClick={() => handleEditWorkContact(row)} />
            ),
        },
        {
            field: "actions",
            headerName: t("remove"),
            flex: 1,
            renderCell: (row) => (
                <DeleteIcon
                    onClick={() => handleDeleteWorkContactOpenDialog(row.id)}
                />
            ),
        },
    ];

    const workContactsColumnsMobile = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
        },
        {
            field: "phoneNumber",
            headerName: t("phoneNumber"),
            flex: 1,
        },
        {
            field: "action",
            headerName: t("edit"),
            flex: 1,
            renderCell: (row) => (
                <EditIcon onClick={() => handleEditWorkContact(row)} />
            ),
        },
        {
            field: "actions",
            headerName: t("remove"),
            flex: 1,
            renderCell: (row) => (
                <DeleteIcon
                    onClick={() => handleDeleteWorkContactOpenDialog(row.id)}
                />
            ),
        },
    ];

    // #region Work Accomodations

    const workAccomodationsColumns = [
        {
            field: "address",
            headerName: t("address"),
            flex: 1,
            valueGetter: (params) => params.row.accomodation.address,
        },
        {
            field: "startDate",
            headerName: t("startDate"),
            flex: 1,
            valueGetter: (params) => {
                const startDate = params.row.startDate;
                if (startDate !== null) {
                    return format(new Date(startDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "endDate",
            headerName: t("endDate"),
            flex: 1,
            valueGetter: (params) => {
                const endDate = params.row.endDate;
                if (endDate !== null) {
                    return format(new Date(endDate), "dd/MM/yyyy");
                }
            },
        },
    ];

    // #endregion Work Accomodations

    // #region Work extra cost

    // #endregion Work extra cost

    const initialValuesWorkExtraCost = {
        name: "",
        description: "",
        cost: "",
        startDate: null,
        endDate: null,
    };

    const workExtraCostSchema = yup.object().shape({
        name: yup.string().required(t("requiredField")),
        description: yup.string().required(t("requiredField")),
        cost: yup
            .number(t("invalidValue"))
            .positive()
            .required(t("requiredField"))
            .typeError(t("invalidValue")),
        startDate: yup.date().required(),
        endDate: yup.date().notRequired(),
    });

    const workExtraCostColumns = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
            valueGetter: (params) => params.row.name,
        },
        {
            field: "description",
            headerName: t("description"),
            flex: 1,
            valueGetter: (params) => params.row.description,
        },
        {
            field: "cost",
            headerName: t("cost"),
            flex: 1,
            valueGetter: (params) => params.row.cost,
        },
        {
            field: "startDate",
            headerName: t("startDate"),
            flex: 1,
            valueGetter: (params) => {
                const startDate = params.row.startDate;
                if (startDate !== null) {
                    return format(new Date(startDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "endDate",
            headerName: t("endDate"),
            flex: 1,
            valueGetter: (params) => {
                const endDate = params.row.endDate;
                if (endDate !== null) {
                    return format(new Date(endDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "action",
            headerName: t("edit"),
            flex: 1,
            renderCell: (row) => (
                <EditIcon onClick={() => handleEditWorkExtraCost(row)} />
            ),
        },
        {
            field: "actions",
            headerName: t("remove"),
            flex: 1,
            renderCell: (row) => (
                <DeleteIcon
                    onClick={() => handleDeleteWorkExtraCostOpenDialog(row.id)}
                />
            ),
        },
    ];

    const workExtraCostColumnsMobile = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
            valueGetter: (params) => params.row.name,
        },
        {
            field: "cost",
            headerName: t("cost"),
            flex: 1,
            valueGetter: (params) => params.row.cost,
        },
    ];
    // #region Work Employees

    const workEmployeesColumns = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
            valueGetter: (params) => params.row.employee.name,
        },
        {
            field: "address",
            headerName: t("address"),
            flex: 1,
            valueGetter: (params) => params.row.employee.address,
        },
        {
            field: "startDate",
            headerName: t("startDate"),
            flex: 1,
            valueGetter: (params) => {
                const startDate = params.row.startDate;
                if (startDate !== null) {
                    return format(new Date(startDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "endDate",
            headerName: t("endDate"),
            flex: 1,
            valueGetter: (params) => {
                const endDate = params.row.endDate;
                if (endDate !== null) {
                    return format(new Date(endDate), "dd/MM/yyyy");
                }
            },
        },
    ];

    const workEmployeesColumnsMobile = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
            valueGetter: (params) => params.row.employee.name,
        },
        {
            field: "startDate",
            headerName: t("startDate"),
            flex: 1,
            valueGetter: (params) => {
                const startDate = params.row.startDate;
                if (startDate !== null) {
                    return format(new Date(startDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "endDate",
            headerName: t("endDate"),
            flex: 1,
            valueGetter: (params) => {
                const endDate = params.row.endDate;
                if (endDate !== null) {
                    return format(new Date(endDate), "dd/MM/yyyy");
                }
            },
        },
    ];

    // #endregion Work Employees

    // #region Work Suppliers

    const workSuppliersColumns = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
        },
        {
            field: "address",
            headerName: t("address"),
            flex: 1,
        },
    ];

    // #endregion Work Suppliers

    // #region Work Vehicles

    const workVehiclesColumns = [
        {
            field: "brand",
            headerName: t("brand"),
            flex: 1,
            valueGetter: (params) => params.row.vehicle.brand,
        },
        {
            field: "licensePlateNumber",
            headerName: t("licensePlateNumber"),
            flex: 1,
            valueGetter: (params) => params.row.vehicle.licensePlateNumber,
        },
        {
            field: "startDate",
            headerName: t("startDate"),
            flex: 1,
            valueGetter: (params) => {
                const startDate = params.row.startDate;
                if (startDate !== null) {
                    return format(new Date(startDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "endDate",
            headerName: t("endDate"),
            flex: 1,
            valueGetter: (params) => {
                const endDate = params.row.endDate;
                if (endDate !== null) {
                    return format(new Date(endDate), "dd/MM/yyyy");
                }
            },
        },
    ];

    const workVehiclesColumnsMobile = [
        {
            field: "licensePlateNumber",
            headerName: t("licensePlateNumber"),
            flex: 1,
            valueGetter: (params) => params.row.vehicle.licensePlateNumber,
        },
        {
            field: "startDate",
            headerName: t("startDate"),
            flex: 1,
            valueGetter: (params) => {
                const startDate = params.row.startDate;
                if (startDate !== null) {
                    return format(new Date(startDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "endDate",
            headerName: t("endDate"),
            flex: 1,
            valueGetter: (params) => {
                const endDate = params.row.endDate;
                if (endDate !== null) {
                    return format(new Date(endDate), "dd/MM/yyyy");
                }
            },
        },
    ];

    const { data: clients, isLoading: isLoadingClients } = useGetClientsQuery();

    // #region Work
    const { userType } = useAuth();
    const { id } = useParams();

    const { data: work, isLoading: isLoadingGetWork } = useGetWorkByIdQuery(id);

    const {
        data: employeePlanningsByWorkId,
        isLoading: isLoadingGetEmployeePlanningsByWorkId,
    } = useGetEmployeePlanningsByWorkIdQuery(id);
    const {
        data: accomodationPlanningsByWorkId,
        isLoading: isLoadingGetAccomodationPlanningsByWorkId,
    } = useGetAccomodationPlanningsByWorkIdQuery(id);
    const {
        data: vehiclePlanningsByWorkId,
        isLoading: isLoadingGetVehiclePlanningsByWorkId,
    } = useGetVehiclePlanningsByWorkIdQuery(id);

    const { data: workTypes, isLoading: isLoadingWorkTypes } =
        useGetWorkTypesQuery();

    const [updateWork, { isLoading: isLoadingUpdateWork }] =
        useUpdateWorkMutation();

    const [deleteWork, { isLoading: isLoadingDeleteWork }] =
        useDeleteWorkByIdMutation();

    useEffect(() => {
        if (employeePlanningsByWorkId) {
            setWorkEmployees(employeePlanningsByWorkId);
        }
    }, [employeePlanningsByWorkId]);

    useEffect(() => {
        if (accomodationPlanningsByWorkId) {
            setWorkAccomodations(accomodationPlanningsByWorkId);
        }
    }, [accomodationPlanningsByWorkId]);

    useEffect(() => {
        if (vehiclePlanningsByWorkId) {
            setWorkVehicles(vehiclePlanningsByWorkId);
        }
    }, [vehiclePlanningsByWorkId]);

    useEffect(() => {
        if (work) {
            setWorkContacts(work.contacts);
            setDayAndNight(work.dayAndNight);
            // setWorkSuppliers(work.suppliers);
            setWorkCompleted(work.completed);
            const processAttachments = async () => {
                const attachmentPromises = work.attachments.map(
                    async (attachment) => {
                        let file = "";
                        if (!attachment.preSignedUrl) {
                            file = await base64ToFile(
                                attachment.content,
                                attachment.fileName,
                                attachment.fileType
                            );
                        }
                        return { ...attachment, file };
                    }
                );

                try {
                    console.log(attachmentPromises);
                    const attachments = await Promise.all(attachmentPromises);
                    console.log(attachments);
                    setFiles(
                        attachments.filter(
                            (attachment) => !!attachment.fileName.split("/")[2]
                        )
                    );
                } catch (error) {
                    console.error("Error processing attachments:", error);
                }
            };

            processAttachments();
        }
    }, [work]);

    function base64ToFile(base64Data, fileName, mimeType) {
        let base64Content = null;
        if (base64Data.split(",").length > 1) {
            base64Content = base64Data.split(",")[1];
        } else {
            base64Content = base64Data;
        }

        const uint8Array = new Uint8Array(
            atob(base64Content)
                .split("")
                .map((char) => char.charCodeAt(0))
        );

        const blob = new Blob([uint8Array], { type: mimeType });

        const file = new File([blob], fileName, { type: mimeType });

        return file;
    }

    const WorkSchema = yup.object().shape({
        name: yup.string().required(t("requiredField")),
        address: yup.string().required(t("requiredField")),
        clientId: yup.string().required(t("requiredField")),
        startDate: yup.date().notRequired(),
        endDate: yup.date().notRequired(),
        budget: yup
            .number(t("invalidValue"))
            .typeError(t("invalidValue"))
            .notRequired(),
        workTypeId: yup.string().required(t("requiredField")),
        companyName: yup.string().notRequired(),
    }); /* Set the work Schema */

    const initialValuesWork = {
        name: "",
        address: "",
        clientId: "",
        workTypeId: "",
        companyName: null,
        startDate: null,
        endDate: null,
        budget: null,
        profit: null,
        attachments: [],
        employees: [],
        suppliers: [],
        vehicles: [],
        accomodations: [],
    }; /* Set the Form Initial Values */

    //#endregion Work

    const handleRemoveFile = (event, file) => {
        event.stopPropagation();
        const updatedFiles = files.filter((f) => f !== file);
        setFiles(updatedFiles);
    };

    const onDropFiles = (acceptedFiles) => {
        const updatedFiles = [...files];

        acceptedFiles.forEach((file) => {
            const existingFile = updatedFiles.find(
                (f) => f.fileName === file.name
            );
            if (!existingFile) {
                updatedFiles.push({
                    fileName: file.name,
                    fileType: file.type,
                    preSignedUrl: "",
                    content: "",
                    file: file,
                });
            } else {
                handleSnackbarOpen(
                    t("file") + file.name + t("exists"),
                    "error"
                );
            }
        });

        setFiles(updatedFiles);
    };

    const convertToBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handleFilePreview = async (event, file) => {
        event.stopPropagation();
        if (file.preSignedUrl) {
            try {
                const newWindow = window.open(file.preSignedUrl, "_blank");
                if (newWindow) newWindow.opener = null;
            } catch (err) {
                handleSnackbarOpen(t("errorWhenDownload"), "error");
            }
        } else {
            try {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(file.file);
                link.download = file.fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (err) {
                handleSnackbarOpen(t("errorWhenDownload"), "error");
            }
        }
    };

    const handleFormSubmit = async (values, onSubmitProps) => {
        if (
            values.startDate !== null &&
            values.endDate !== null &&
            dayjs(values.endDate).isBefore(dayjs(values.startDate))
        ) {
            handleSnackbarOpen(
                t("invalidToInsertOrUpdateWorkBecauseInvalidDates"),
                "error"
            );
        } else {
            let attachments = [];
            setLoadingFiles(true);
            let isToReload = false;
            try {
                // add only the new attachments
                const _files = files.filter((file) => !!file.file);

                await Promise.all(
                    _files.map(async (file) => {
                        if (
                            values.attachments.length === 0 ||
                            values.attachments.filter(
                                (a) => a.fileName === file.fileName
                            ).length === 0
                        ) {
                            let attachment = {
                                id: 0,
                                fileName: file.fileName,
                                content: file.file,
                                fileType: file.fileType,
                            };
                            attachments.push(attachment);
                        }
                    })
                );

                let newAttachments = [...attachments.filter((a) => a.id === 0)];

                let attachmentsToAdd = {
                    attachments: [...newAttachments],
                };

                // region delete attachments
                let attachmentsToDelete = [];
                values.attachments.forEach((attachment) => {
                    const _attachment = {
                        ...attachment,
                        fileName: attachment.fileName.split("/")[2],
                        fileType: attachment.fileType || "image/jpg",
                    };
                    if (
                        files.filter((f) => f.fileName === attachment.fileName)
                            .length === 0
                    ) {
                        delete _attachment.preSignedUrl;
                        if (attachment.fileName.split("/")[2]) {
                            attachmentsToDelete.push(_attachment);
                        }
                    }
                });

                const createPromises = [];

                if (attachmentsToAdd.attachments.length > 0) {
                    isToReload = true;
                    attachmentsToAdd.attachments.forEach((attachment) => {
                        let addAttachmentsDTO = {
                            Id: parseInt(id),
                            Attachment: attachment,
                            EntityName: "Works",
                        };

                        // Store the promise in the array
                        const promise =
                            uploadAttachment(addAttachmentsDTO).unwrap();

                        createPromises.push(promise);
                    });

                    await Promise.all(createPromises).then(async (results) => {
                        // Create an array of promises from results
                        const processPromises = results.map(
                            async ({
                                codeResult,
                                signedUrl,
                                expiresIn,
                                fileName,
                            }) => {
                                if (
                                    codeResult !==
                                    RESPONSES.client.sucessToAddAttachments
                                ) {
                                    handleSnackbarOpen(
                                        t("anErrorOcurred"),
                                        "error"
                                    );
                                } else {
                                    const attachment =
                                        attachmentsToAdd.attachments.find(
                                            (attachment) =>
                                                attachment.fileName === fileName
                                        );

                                    return fetch(signedUrl, {
                                        method: "PUT",
                                        body: attachment.content,
                                        headers: {
                                            "Content-Type":
                                                "multipart/form-data",
                                            mimeType: attachment.fileType,
                                        },
                                    });
                                }
                            }
                        );

                        // Wait for all processes to complete
                        await Promise.all(processPromises);
                        handleSnackbarOpen(t("sucess"), "success");
                    });
                }

                const deletePromises = [];

                if (attachmentsToDelete.length > 0) {
                    isToReload = true;
                    attachmentsToDelete.forEach((attachment) => {
                        let addAttachmentsDTO = {
                            Id: parseInt(id),
                            Attachment: attachment,
                            EntityName: "Works",
                        };
                        // Store the promise in the array
                        const promise =
                            deleteAttachment(addAttachmentsDTO).unwrap();

                        deletePromises.push(promise);
                    });

                    await Promise.all(deletePromises).then((results) => {
                        // Handle the results after all promises are resolved
                        results.forEach(({ codeResult }) => {
                            if (
                                codeResult !==
                                RESPONSES.client.sucessToAddAttachments
                            ) {
                                handleSnackbarOpen(
                                    t("anErrorOcurred"),
                                    "error"
                                );
                            } else {
                                handleSnackbarOpen(t("sucess"), "success");
                            }
                        });
                    });
                }
            } catch (err) {
                handleSnackbarOpen(t("anErrorOcurred"), "error");
            }
            setLoadingFiles(false);

            if (isToUpdateWork === true) {
                try {
                    let upd = {
                        id: values.id,
                        name: values.name,
                        address: values.address,
                        clientId: values.clientId,
                        startDate: values.startDate,
                        endDate: values.endDate,
                        workTypeId: values.workTypeId,
                        companyName: values.companyName,
                        budget: values.budget === "" ? null : values.budget,
                        dayAndNight: dayAndNight,
                        completed: workCompleted,
                        attachments: null,
                    };
                    const { codeResult } = await updateWork(upd).unwrap();

                    if (codeResult !== RESPONSES.work.sucessToUpdateWork) {
                        if (codeResult !== RESPONSES.work.invalidToUpdateWork) {
                            handleSnackbarOpen(
                                t("invalidToUpdateWork"),
                                "error"
                            );
                        } else if (codeResult !== RESPONSES.work.invalidWork) {
                            handleSnackbarOpen(t("invalidWork"), "error");
                        } else {
                            handleSnackbarOpen(t("errorUpdateWork"), "error");
                        }
                    } else {
                        handleSnackbarOpen(t("sucessToUpdateWork"), "success");
                    }
                } catch (error) {
                    handleSnackbarOpen(t("errorUpdateWork"), "error");
                }
                setIsToUpdateWork(false);
            } else {
                if (isToReload === true) {
                    window.location.reload();
                }
            }
        }
    };

    const [uploadAttachment, { isLoading: isLoadingUploadAttachment }] =
        useUploadAttachmentMutation();

    const [deleteAttachment, { isLoading: isLoadingDeleteAttachment }] =
        useDeleteAttachmentMutation();

    const [loadingFiles, setLoadingFiles] = useState(false);
    const [isToUpdateWork, setIsToUpdateWork] = useState(false);

    const handleDeleteWork = async () => {
        setOpenDeleteConfirmationDialog(false);

        try {
            const { codeResult } = await deleteWork(id).unwrap();

            if (codeResult !== RESPONSES.work.sucessToDeleteWork) {
                if (
                    codeResult ===
                    RESPONSES.work
                        .invalidToDeleteWorkBecauseAssignedToWorkPlanning
                ) {
                    handleSnackbarOpen(
                        t("invalidToDeleteWorkBecauseAssignedToWorkPlanning"),
                        "error"
                    );
                } else if (codeResult === RESPONSES.work.invalidToDeleteWork) {
                    handleSnackbarOpen(t("invalidToDeleteWork"), "error");
                } else {
                    handleSnackbarOpen(t("errorDeleteWork"), "error");
                }
            } else {
                navigate(`/${location.pathname.split("/")[1]}`);
            }
        } catch (err) {
            handleSnackbarOpen(t("errorDeleteWork"), "error");
        }
    };

    // #region Functions to add, edit and delete work contact

    const [addWorkContact, { isLoading: isLoadingAddWorkContact }] =
        useAddWorkContactMutation();

    const [editWorkContact, { isLoading: isLoadingEditWorkContact }] =
        useEditWorkContactMutation();

    const [deleteWorkContact] = useDeleteWorkContactMutation();

    const handleFormAddWorkContactSubmit = async (values, onSubmitProps) => {
        try {
            let newWorkContact = {
                Id: id,
                Contact: values,
                ContactId: 0,
            };
            const { codeResult } = await addWorkContact(
                newWorkContact
            ).unwrap();

            if (codeResult !== RESPONSES.work.sucessToAddWorkContact) {
                if (codeResult === RESPONSES.work.invalidToAddWorkContact) {
                    handleSnackbarOpen(t("invalidToAddWorkContact"), "error");
                } else if (codeResult === RESPONSES.supplier.invalidContact) {
                    handleSnackbarOpen(t("invalidContact"), "error");
                } else {
                    handleSnackbarOpen(t("errorUpdateWork"), "error");
                }
            } else {
                handleCloseCreateWorkContactDialog();
                handleSnackbarOpen(t("sucessToAddWorkContact"), "success");
            }
        } catch (err) {
            handleSnackbarOpen(t("errorUpdateWork"), "error");
        }
    };

    // EDIT WORK CONTACT

    const [workContactToEdit, setWorkContactToEdit] = useState(null);

    const handleEditWorkContact = async (row) => {
        let workContactToUpdate = {
            id: id,
            contactId: row.row.id,
            name: row.row.name,
            email: row.row.email,
            position: row.row.position,
            phoneNumber: row.row.phoneNumber,
        };
        setWorkContactToEdit(workContactToUpdate);
        setOpenEditWorkContactDialog(true);
    };

    const handleFormEditWorkContactSubmit = async (values, onSubmitProps) => {
        try {
            let workContactToUpdate = {
                id: id,
                contactId: values.contactId,
                contact: {
                    name: values.name,
                    email: values.email,
                    position: values.position,
                    phoneNumber: values.phoneNumber,
                },
            };

            const { codeResult } = await editWorkContact(
                workContactToUpdate
            ).unwrap();

            if (codeResult !== RESPONSES.work.sucessToUpdateWorkContact) {
                if (codeResult === RESPONSES.work.invalidToUpdateWorkContact) {
                    handleSnackbarOpen(
                        t("invalidToUpdateWorkContact"),
                        "error"
                    );
                } else if (codeResult === RESPONSES.supplier.invalidContact) {
                    handleSnackbarOpen(t("invalidContact"), "error");
                } else {
                    handleSnackbarOpen(
                        t("invalidToUpdateWorkContact"),
                        "error"
                    );
                }
            } else {
                handleCloseEditWorkContactDialog();
                handleSnackbarOpen(t("sucessToUpdateWorkContact"), "success");
            }
        } catch (err) {
            handleSnackbarOpen(t("invalidToUpdateWorkContact"), "error");
        }
    };

    const [idToDelete, setIdToDelete] = useState(null);

    const handleDeleteWorkContactOpenDialog = async (id) => {
        setIdToDelete(id);
        setOpenDeleteWorkContactDialog(true);
    };

    const handleDeleteWorkContact = async () => {
        try {
            let workContactToDelete = {
                Id: 0,
                ContactId: idToDelete,
                Contact: null,
            };

            const { codeResult } = await deleteWorkContact(
                workContactToDelete
            ).unwrap();

            if (codeResult !== RESPONSES.work.sucessToDeleteWorkContact) {
                handleSnackbarOpen(t("invalidToDeleteWorkContact"), "error");
            } else {
                setOpenDeleteWorkContactDialog(false);
                handleSnackbarOpen(t("sucessToDeleteWorkContact"), "success");
            }
        } catch (err) {
            handleSnackbarOpen(t("invalidToDeleteWorkContact"), "error");
        }
    };

    //#REGION  WORK EXTRA COST
    const { data: workExtraCostsByWorkId, isLoadingGetWorkExtraCostsByWorkId } =
        useGetWorkExtraCostsByWorkIdQuery(id);

    const [getWorkExtraCostById, workExtraCost] =
        useLazyGetWorkExtraCostByIdQuery();

    const [addWorkExtraCost, { isLoading: isLoadingAddWorkExtraCost }] =
        usePostWorkExtraCostMutation();

    const [editWorkExtraCost, { isLoading: isLoadingEditWorkExtraCost }] =
        useUpdateWorkExtraCostMutation();

    const handleFormWorkExtraCostSubmit = async (values, onSubmitProps) => {
        let response = null;

        try {
            if (values.id) {
                let workExtraCost = {
                    Id: values.id,
                    Name: values.name,
                    Description: values.description,
                    WorkId: id,
                    Cost: values.cost,
                    StartDate: values.startDate,
                    EndDate: values.endDate,
                };
                response = await editWorkExtraCost(workExtraCost);
            } else {
                let workExtraCost = {
                    Name: values.name,
                    Description: values.description,
                    WorkId: id,
                    Cost: values.cost,
                    StartDate: values.startDate,
                    EndDate: values.endDate,
                };
                response = await addWorkExtraCost(workExtraCost);
            }

            if (
                response.data.codeResult ===
                RESPONSES.workExtraCost.sucessToInsertWorkExtraCost
            ) {
                handleCloseAddOrEditWorkExtraCostDialog();
                handleSnackbarOpen(t("sucessToAddAccomodationCost"), "success");
            } else if (
                response.data.codeResult ===
                RESPONSES.workExtraCost.sucessToUpdateWorkExtraCost
            ) {
                handleCloseAddOrEditWorkExtraCostDialog();
                handleSnackbarOpen(
                    t("sucessToUpdateAccomodationCost"),
                    "success"
                );
            } else {
                handleSnackbarOpen(t("anErrorOcurred"), "error");
            }
        } catch (err) {
            handleSnackbarOpen(t("anErrorOcurred"), "error");
        }
    };

    const [workExtraCostForm, setWorkExtraCostForm] = useState(null);
    const handleEditWorkExtraCost = async (row) => {
        await getWorkExtraCostById(row.row.id);
    };

    useEffect(() => {
        if (workExtraCost.data) {
            setWorkExtraCostForm(workExtraCost.data);
            setOpenAddOrEditWorkExtraCostDialog(true);
        }
    }, [workExtraCost]);

    const [workExtraCostId, setWorkExtraCostId] = useState(null);

    const handleDeleteWorkExtraCostOpenDialog = async (id) => {
        setWorkExtraCostId(id);
        setOpenDeleteWorkExtraCostDialog(true);
    };

    const [deleteWorkExtraCost] = useDeleteWorkExtraCostByIdMutation();

    const handleDeleteWorkExtraCost = async () => {
        try {
            const { codeResult } = await deleteWorkExtraCost(
                workExtraCostId
            ).unwrap();

            if (
                codeResult !==
                RESPONSES.workExtraCost.sucessToDeleteWorkExtraCost
            ) {
                handleSnackbarOpen(t("anErrorOcurred"), "error");
            } else {
                setOpenDeleteWorkExtraCostDialog(false);
                handleSnackbarOpen(
                    t("sucessToDeleteEmployeeWorkHourCosts"),
                    "success"
                );
            }
        } catch (err) {
            handleSnackbarOpen(t("anErrorOcurred"), "error");
        }
    };
    //#ENDREGION WORK EXTRA COST

    // tool bar to add vehicle planning
    function CustomToolbarAddWorkContact() {
        return (
            <GridToolbarContainer
                sx={{
                    "& .MuiButtonBase-root:hover": {
                        backgroundColor: `${palette.primary.light} !important`,
                    },
                }}
            >
                <FlexBetween width="100%" padding="0.5rem 0">
                    <FlexBetween>
                        <GridToolbarColumnsButton />
                        <GridToolbarFilterButton />
                        <GridToolbarDensitySelector />
                        <GridToolbarExport />
                    </FlexBetween>
                    <Button
                        type="button"
                        onClick={handleOpenCreateWorkContactDialog}
                        sx={{
                            p: "0.5rem 1rem",
                            backgroundColor: palette.primary.main,
                            color: `${palette.neutral.white} !important`,
                            "&:hover": {
                                color: palette.neutral.white,
                            },
                        }}
                    >
                        {t("addContact")}
                    </Button>
                </FlexBetween>
            </GridToolbarContainer>
        );
    }

    // tool bar to add vehicle planning
    function CustomToolbarAddWorkExtraCost() {
        return (
            <GridToolbarContainer
                sx={{
                    "& .MuiButtonBase-root:hover": {
                        backgroundColor: `${palette.primary.light} !important`,
                    },
                }}
            >
                <FlexBetween width="100%" padding="0.5rem 0">
                    <FlexBetween>
                        <GridToolbarColumnsButton />
                        <GridToolbarFilterButton />
                        <GridToolbarDensitySelector />
                        <GridToolbarExport />
                    </FlexBetween>
                    <Button
                        type="button"
                        onClick={handleOpenAddOrEditWorkExtraCostDialog}
                        sx={{
                            p: "0.5rem 1rem",
                            backgroundColor: palette.primary.main,
                            color: `${palette.neutral.white} !important`,
                            "&:hover": {
                                color: palette.neutral.white,
                            },
                        }}
                        disabled={!workCompleted}
                    >
                        {t("addWorkExtraCost")}
                    </Button>
                </FlexBetween>
            </GridToolbarContainer>
        );
    }
    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={work || initialValuesWork}
            validationSchema={WorkSchema}
            enableReinitialize={true}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    {isLoadingGetWork ||
                    isLoadingUpdateWork ||
                    loadingFiles ||
                    isLoadingDeleteWork ? (
                        <LoadingSpinner />
                    ) : null}
                    <Box
                        m={!isNonMobile ? "0" : "1.5rem 2.5rem"}
                        display={!isNonMobile ? "flex" : "grid"}
                        gridTemplateRows={
                            !isNonMobile ? undefined : "repeat(4, auto)"
                        }
                        flexDirection={!isNonMobile ? "column" : undefined}
                        gap="20px"
                        borderRadius="5px"
                        p="2rem"
                    >
                        <PageHeader title={values.name} subtitle="" />
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Box display="flex" justifyContent="space-between">
                                <Typography
                                    fontWeight="500"
                                    fontSize="20px"
                                    color="primary"
                                    variant="h5"
                                    alignSelf="center"
                                    textAlign="left"
                                >
                                    {t("workInformation")}
                                </Typography>
                                <Box>
                                    <FormControlLabel
                                        sx={{ marginLeft: "5px" }}
                                        control={
                                            <Checkbox
                                                checked={dayAndNight}
                                                onChange={() => {
                                                    setDayAndNightState();
                                                    setIsToUpdateWork(true);
                                                }}
                                            />
                                        }
                                        label={t("dayAndNight")}
                                    />
                                    <FormControlLabel
                                        sx={{ marginLeft: "5px" }}
                                        control={
                                            <Checkbox
                                                checked={workCompleted}
                                                onChange={() => {
                                                    setWorkCompletedState();
                                                    setIsToUpdateWork(true);
                                                }}
                                            />
                                        }
                                        label={t("completed")}
                                    />
                                </Box>
                            </Box>
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <TextField
                                    variant="outlined"
                                    label={t("name")}
                                    inputRef={nameRef}
                                    onBlur={handleBlur}
                                    onChange={(event) => {
                                        handleChange(event);
                                        setIsToUpdateWork(true); // Atualiza a flag sem substituir handleChange
                                    }}
                                    value={values.name}
                                    name="name"
                                    error={
                                        Boolean(touched.name) &&
                                        Boolean(errors.name)
                                    }
                                    helperText={touched.name && errors.name}
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("address")}
                                    onBlur={handleBlur}
                                    value={values.address}
                                    name="address"
                                    onChange={(event) => {
                                        handleChange(event);
                                        setIsToUpdateWork(true); // Atualiza a flag sem substituir handleChange
                                    }}
                                    error={
                                        Boolean(touched.address) &&
                                        Boolean(errors.address)
                                    }
                                    helperText={
                                        touched.address && errors.address
                                    }
                                    sx={{ width: "100%" }}
                                />
                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingWorkTypes}
                                    options={workTypes || []}
                                    getOptionLabel={(option) => option.type}
                                    value={
                                        (workTypes &&
                                            workTypes.find(
                                                (workType) =>
                                                    workType.id ===
                                                    values.workTypeId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "workTypeId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                        setIsToUpdateWork(true);
                                    }}
                                    onBlur={handleBlur("workTypeId")}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("workType")}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.workTypeId
                                                    ) &&
                                                    Boolean(errors.workTypeId)
                                                }
                                                helperText={
                                                    touched.workTypeId &&
                                                    errors.workTypeId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />
                                {values.workTypeId ===
                                    SETTINGS.WorkType.Company && (
                                    <TextField
                                        label={t("companyName")}
                                        onBlur={handleBlur}
                                        value={values.companyName}
                                        name="companyName"
                                        onChange={(event) => {
                                            handleChange(event);
                                            setIsToUpdateWork(true); // Atualiza a flag sem substituir handleChange
                                        }}
                                        error={
                                            Boolean(touched.companyName) &&
                                            Boolean(errors.companyName)
                                        }
                                        helperText={
                                            touched.companyName &&
                                            errors.companyName
                                        }
                                        sx={{ width: "100%" }}
                                    />
                                )}
                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingClients}
                                    options={clients || []}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                        (clients &&
                                            clients.find(
                                                (client) =>
                                                    client.id ===
                                                    values.clientId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "clientId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                        setIsToUpdateWork(true);
                                    }}
                                    onBlur={handleBlur("clientId")}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("client")}
                                                {...params}
                                                error={
                                                    Boolean(touched.clientId) &&
                                                    Boolean(errors.clientId)
                                                }
                                                helperText={
                                                    touched.clientId &&
                                                    errors.clientId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />
                                {values.workTypeId ===
                                    SETTINGS.WorkType.Private && (
                                    <TextField
                                        label={t("budget")}
                                        onBlur={handleBlur}
                                        value={
                                            values.budget === null
                                                ? ""
                                                : values.budget
                                        }
                                        name="budget"
                                        onChange={(event) => {
                                            handleChange(event);
                                            setIsToUpdateWork(true); // Atualiza a flag sem substituir handleChange
                                        }}
                                        error={
                                            Boolean(touched.budget) &&
                                            Boolean(errors.budget)
                                        }
                                        InputProps={{
                                            endAdornment: "€",
                                        }}
                                        helperText={
                                            touched.budget && errors.budget
                                        }
                                        sx={{ width: "100%" }}
                                    />
                                )}

                                {values.workTypeId ===
                                    SETTINGS.WorkType.Private &&
                                    userType === "ADMIN" && (
                                        <TextField
                                            label={t("profit")}
                                            onBlur={handleBlur}
                                            value={
                                                values.profit === null
                                                    ? ""
                                                    : values.profit
                                            }
                                            onChange={(event) => {
                                                handleChange(event);
                                                setIsToUpdateWork(true); // Atualiza a flag sem substituir handleChange
                                            }}
                                            InputProps={{
                                                endAdornment: "€",
                                            }}
                                            sx={{ width: "100%" }}
                                            readOnly={true}
                                        />
                                    )}

                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="pt"
                                >
                                    {values.startDate !== null ? (
                                        <DatePicker
                                            label={t("startDate")}
                                            value={dayjs(values.startDate)}
                                            format="DD/MM/YYYY"
                                            onChange={(value) => {
                                                setFieldValue(
                                                    "startDate",
                                                    value
                                                );
                                                setIsToUpdateWork(true);
                                            }}
                                            disabled={values.completed}
                                        />
                                    ) : (
                                        <DatePicker
                                            label={t("startDate")}
                                            value={values.startDate}
                                            format="DD/MM/YYYY"
                                            onChange={(value) => {
                                                setFieldValue(
                                                    "startDate",
                                                    value
                                                );
                                                setIsToUpdateWork(true);
                                            }}
                                        />
                                    )}
                                    {values.endDate !== null ? (
                                        <DatePicker
                                            label={t("endDate")}
                                            value={dayjs(values.endDate)}
                                            format="DD/MM/YYYY"
                                            onChange={(value) => {
                                                setFieldValue("endDate", value);
                                                setIsToUpdateWork(true);
                                            }}
                                            disabled={values.completed}
                                        />
                                    ) : (
                                        <DatePicker
                                            label={t("endDate")}
                                            value={values.endDate}
                                            format="DD/MM/YYYY"
                                            onChange={(value) => {
                                                setFieldValue("endDate", value);
                                                setIsToUpdateWork(true);
                                            }}
                                        />
                                    )}
                                </LocalizationProvider>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            marginTop="10px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Box
                                display="flex"
                                width="100%"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    fontWeight="500"
                                    fontSize="20px"
                                    color="primary"
                                    variant="h5"
                                    textAlign="left"
                                >
                                    {t("associatedContacts")}
                                </Typography>
                            </Box>
                            <Box
                                display="flex"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        flexGrow: 1,
                                        width: "100%",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        // borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: `${theme.palette.background.J} !important`,
                                        color: theme.palette.secondary[100],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor:
                                            theme.palette.background.A,
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        backgroundColor: `${theme.palette.background.J} !important`,
                                        color: theme.palette.secondary[100],
                                        borderTop: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text":
                                        {
                                            color: `${theme.palette.background.I}`,
                                        },
                                    "& .MuiDataGrid-toolbarContainer .MuiBox-root":
                                        {
                                            display: !isNonMobile
                                                ? "flex"
                                                : undefined,
                                            flexDirection: !isNonMobile
                                                ? "column"
                                                : undefined,
                                        },
                                    "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                        {
                                            backgroundColor: `${theme.palette.background.A} !important`,
                                        },
                                }}
                            >
                                <DataGrid
                                    autoHeight
                                    loading={isLoadingGetWork || !work}
                                    getRowId={(row) => row.id}
                                    rows={workContacts || []}
                                    columns={
                                        !isNonMobile
                                            ? workContactsColumnsMobile
                                            : workContactsColumns
                                    }
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    slots={{
                                        toolbar: CustomToolbarAddWorkContact,
                                    }}
                                    key={JSON.stringify(workContacts)}
                                    disableRowSelectionOnClick
                                />
                            </Box>
                        </Box>
                        {/* Create Work Contacts */}
                        <Dialog
                            maxWidth="800px"
                            open={openCreateWorkContactDialog}
                            onClose={handleCloseCreateWorkContactDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <Formik
                                onSubmit={handleFormAddWorkContactSubmit}
                                initialValues={initialValuesContact}
                                validationSchema={contactSchema}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                    resetForm,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Box
                                            display="grid"
                                            gridTemplateColumns={
                                                !isNonMobile
                                                    ? "repeat(1, 1fr)"
                                                    : "repeat(1, auto)"
                                            }
                                            gap="20px"
                                            backgroundColor={
                                                theme.palette.background.D
                                            }
                                            borderRadius="5px"
                                            p="2rem"
                                            width={
                                                !isNonMobile ? "100%" : "40rem"
                                            }
                                        >
                                            <PageHeader
                                                title={t("newContact")}
                                                subtitle=""
                                            />
                                            <Box
                                                display="flex"
                                                gap="20px"
                                                flexDirection="column"
                                                width="100%"
                                                mt="10px"
                                            >
                                                <Box
                                                    display="grid"
                                                    gridTemplateColumns={
                                                        !isNonMobile
                                                            ? "repeat(1, 1fr)"
                                                            : "repeat(2, 1fr)"
                                                    }
                                                    gap="10px"
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        label={t("name")}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.name}
                                                        name="name"
                                                        error={
                                                            Boolean(
                                                                touched.name
                                                            ) &&
                                                            Boolean(errors.name)
                                                        }
                                                        helperText={
                                                            touched.name &&
                                                            errors.name
                                                        }
                                                        sx={{ width: "100%" }}
                                                    />
                                                    <TextField
                                                        variant="outlined"
                                                        label={t("email")}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.email}
                                                        name="email"
                                                        error={
                                                            Boolean(
                                                                touched.email
                                                            ) &&
                                                            Boolean(
                                                                errors.email
                                                            )
                                                        }
                                                        helperText={
                                                            touched.email &&
                                                            errors.email
                                                        }
                                                        sx={{ width: "100%" }}
                                                    />
                                                    <TextField
                                                        variant="outlined"
                                                        label={t("position")}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.position}
                                                        name="position"
                                                        error={
                                                            Boolean(
                                                                touched.position
                                                            ) &&
                                                            Boolean(
                                                                errors.position
                                                            )
                                                        }
                                                        helperText={
                                                            touched.position &&
                                                            errors.position
                                                        }
                                                        sx={{ width: "100%" }}
                                                    />
                                                    <TextField
                                                        variant="outlined"
                                                        label={t("phoneNumber")}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={
                                                            values.phoneNumber
                                                        }
                                                        name="phoneNumber"
                                                        error={
                                                            Boolean(
                                                                touched.phoneNumber
                                                            ) &&
                                                            Boolean(
                                                                errors.phoneNumber
                                                            )
                                                        }
                                                        helperText={
                                                            touched.phoneNumber &&
                                                            errors.phoneNumber
                                                        }
                                                        sx={{ width: "100%" }}
                                                    />
                                                </Box>
                                            </Box>
                                            {/* BUTTONS */}
                                            <LoadingButton
                                                fullWidth
                                                type="submit"
                                                loading={
                                                    isLoadingAddWorkContact
                                                }
                                                sx={{
                                                    width: "100%",
                                                    p: "1rem",
                                                    backgroundColor:
                                                        theme.palette.primary
                                                            .main,
                                                    color: theme.palette.neutral
                                                        .white,
                                                    "&:hover": {
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.light,
                                                    },
                                                }}
                                            >
                                                {t("addContact")}
                                            </LoadingButton>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </Dialog>
                        {/* Edit Work Contacts */}
                        <Dialog
                            maxWidth="800px"
                            open={openEditWorkContactDialog}
                            onClose={handleCloseEditWorkContactDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <Formik
                                onSubmit={handleFormEditWorkContactSubmit}
                                initialValues={
                                    workContactToEdit || initialValuesContact
                                }
                                validationSchema={contactSchema}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                    resetForm,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Box
                                            display="grid"
                                            gridTemplateColumns={
                                                !isNonMobile
                                                    ? "repeat(1, 1fr)"
                                                    : "repeat(1, auto)"
                                            }
                                            gap="20px"
                                            backgroundColor={
                                                theme.palette.background.D
                                            }
                                            borderRadius="5px"
                                            p="2rem"
                                            width={
                                                !isNonMobile ? "100%" : "40rem"
                                            }
                                        >
                                            <PageHeader
                                                title={t("editContact")}
                                                subtitle=""
                                            />
                                            <Box
                                                display="flex"
                                                gap="20px"
                                                flexDirection="column"
                                                width="100%"
                                                mt="10px"
                                            >
                                                <Box
                                                    display="grid"
                                                    gridTemplateColumns={
                                                        !isNonMobile
                                                            ? "repeat(1, 1fr)"
                                                            : "repeat(2, 1fr)"
                                                    }
                                                    gap="10px"
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        label={t("name")}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.name}
                                                        name="name"
                                                        error={
                                                            Boolean(
                                                                touched.name
                                                            ) &&
                                                            Boolean(errors.name)
                                                        }
                                                        helperText={
                                                            touched.name &&
                                                            errors.name
                                                        }
                                                        sx={{ width: "100%" }}
                                                    />
                                                    <TextField
                                                        variant="outlined"
                                                        label={t("email")}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.email}
                                                        name="email"
                                                        error={
                                                            Boolean(
                                                                touched.email
                                                            ) &&
                                                            Boolean(
                                                                errors.email
                                                            )
                                                        }
                                                        helperText={
                                                            touched.email &&
                                                            errors.email
                                                        }
                                                        sx={{ width: "100%" }}
                                                    />
                                                    <TextField
                                                        variant="outlined"
                                                        label={t("position")}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.position}
                                                        name="position"
                                                        error={
                                                            Boolean(
                                                                touched.position
                                                            ) &&
                                                            Boolean(
                                                                errors.position
                                                            )
                                                        }
                                                        helperText={
                                                            touched.position &&
                                                            errors.position
                                                        }
                                                        sx={{ width: "100%" }}
                                                    />
                                                    <TextField
                                                        variant="outlined"
                                                        label={t("phoneNumber")}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={
                                                            values.phoneNumber
                                                        }
                                                        name="phoneNumber"
                                                        error={
                                                            Boolean(
                                                                touched.phoneNumber
                                                            ) &&
                                                            Boolean(
                                                                errors.phoneNumber
                                                            )
                                                        }
                                                        helperText={
                                                            touched.phoneNumber &&
                                                            errors.phoneNumber
                                                        }
                                                        sx={{ width: "100%" }}
                                                    />
                                                </Box>
                                            </Box>
                                            {/* BUTTONS */}
                                            <LoadingButton
                                                fullWidth
                                                type="submit"
                                                loading={
                                                    isLoadingEditWorkContact
                                                }
                                                sx={{
                                                    width: "100%",
                                                    p: "1rem",
                                                    backgroundColor:
                                                        theme.palette.primary
                                                            .main,
                                                    color: theme.palette.neutral
                                                        .white,
                                                    "&:hover": {
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.light,
                                                    },
                                                }}
                                            >
                                                {t("editContact")}
                                            </LoadingButton>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </Dialog>
                        {/* Delete Work Contact */}
                        <Dialog
                            open={openDeleteWorkContactDialog}
                            onClose={handleCloseDeleteWorkContactDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {t("deleteSupplierContactConfirmationTitle")}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {t(
                                        "deleteSupplierContactConfirmationMessage"
                                    )}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    sx={{
                                        backgroundColor: theme.palette.alt.red,
                                        color: theme.palette.neutral.white,
                                        "&:hover": {
                                            backgroundColor:
                                                theme.palette.alt.lightRed,
                                        },
                                    }}
                                    onClick={handleCloseDeleteWorkContactDialog}
                                >
                                    {t("noMessage")}
                                </Button>
                                <Button
                                    sx={{
                                        backgroundColor:
                                            theme.palette.primary.main,
                                        color: theme.palette.neutral.white,
                                        "&:hover": {
                                            backgroundColor:
                                                theme.palette.primary.light,
                                        },
                                    }}
                                    // loading={isLoadingDeleteWorkContact}
                                    onClick={handleDeleteWorkContact}
                                    autoFocus
                                >
                                    {t("yesMessage")}
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {/* WORK EXTRA COSTS*/}
                        {values.workTypeId === SETTINGS.WorkType.Private && (
                            <Box
                                display="flex"
                                marginTop="10px"
                                flexDirection="column"
                                width="100%"
                            >
                                <Box
                                    display="flex"
                                    width="100%"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography
                                        fontWeight="500"
                                        fontSize="20px"
                                        color="primary"
                                        variant="h5"
                                        textAlign="left"
                                    >
                                        {t("workExtraCosts")}
                                    </Typography>
                                </Box>
                                <BoxDataGrid
                                    isLoading={
                                        isLoadingGetWorkExtraCostsByWorkId
                                    }
                                    entity={workExtraCostsByWorkId}
                                    columns={workExtraCostColumns}
                                    columnsMobile={workExtraCostColumnsMobile}
                                    CustomToolbar={
                                        CustomToolbarAddWorkExtraCost
                                    }
                                />
                            </Box>
                        )}

                        {/* ADD OR EDIT WORK EXTRA COST*/}
                        <Dialog
                            maxWidth="800px"
                            open={openAddOrEditWorkExtraCostDialog}
                            onClose={handleCloseAddOrEditWorkExtraCostDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <Formik
                                onSubmit={handleFormWorkExtraCostSubmit}
                                // initialValues={
                                //     workExtraCost.id
                                //         ? workExtraCost
                                //         : initialValuesWorkExtraCost
                                // }
                                initialValues={
                                    workExtraCostForm ||
                                    initialValuesWorkExtraCost
                                }
                                validationSchema={workExtraCostSchema}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                    resetForm,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Box
                                            display="grid"
                                            gridTemplateColumns={
                                                !isNonMobile
                                                    ? "repeat(1, 1fr)"
                                                    : "repeat(1, auto)"
                                            }
                                            gap="20px"
                                            backgroundColor={
                                                theme.palette.background.D
                                            }
                                            borderRadius="5px"
                                            p="2rem"
                                            width={
                                                !isNonMobile ? "100%" : "40rem"
                                            }
                                        >
                                            <PageHeader
                                                title={
                                                    values.id
                                                        ? t("editWorkExtraCost")
                                                        : t("addWorkExtraCost")
                                                }
                                                subtitle=""
                                            />
                                            <Box
                                                display="flex"
                                                gap="20px"
                                                flexDirection="column"
                                                width="100%"
                                                mt="10px"
                                            >
                                                <Box
                                                    display="grid"
                                                    gridTemplateColumns={
                                                        !isNonMobile
                                                            ? "repeat(1, 1fr)"
                                                            : "repeat(2, 1fr)"
                                                    }
                                                    gap="10px"
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        label={t("name")}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.name}
                                                        name="name"
                                                        error={
                                                            Boolean(
                                                                touched.name
                                                            ) &&
                                                            Boolean(errors.name)
                                                        }
                                                        helperText={
                                                            touched.name &&
                                                            errors.name
                                                        }
                                                        sx={{ width: "100%" }}
                                                    />
                                                    <TextField
                                                        variant="outlined"
                                                        label={t("description")}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={
                                                            values.description
                                                        }
                                                        name="description"
                                                        error={
                                                            Boolean(
                                                                touched.description
                                                            ) &&
                                                            Boolean(
                                                                errors.description
                                                            )
                                                        }
                                                        helperText={
                                                            touched.description &&
                                                            errors.description
                                                        }
                                                        sx={{ width: "100%" }}
                                                    />
                                                    <TextField
                                                        label={t("workCost")}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.cost}
                                                        name="cost"
                                                        error={
                                                            Boolean(
                                                                touched.cost
                                                            ) &&
                                                            Boolean(errors.cost)
                                                        }
                                                        InputProps={{
                                                            endAdornment: "€",
                                                        }}
                                                        helperText={
                                                            touched.cost &&
                                                            errors.cost
                                                        }
                                                        sx={{ width: "100%" }}
                                                    />
                                                    <LocalizationProvider
                                                        dateAdapter={
                                                            AdapterDayjs
                                                        }
                                                        adapterLocale="pt"
                                                    >
                                                        {values.startDate !==
                                                        null ? (
                                                            <DatePicker
                                                                label={t(
                                                                    "startDate"
                                                                )}
                                                                value={dayjs(
                                                                    values.startDate
                                                                )}
                                                                format="DD/MM/YYYY"
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    setFieldValue(
                                                                        "startDate",
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                        ) : (
                                                            <DatePicker
                                                                label={t(
                                                                    "startDate"
                                                                )}
                                                                value={
                                                                    values.startDate
                                                                }
                                                                format="DD/MM/YYYY"
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    setFieldValue(
                                                                        "startDate",
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                        {values.endDate !==
                                                        null ? (
                                                            <DatePicker
                                                                label={t(
                                                                    "endDate"
                                                                )}
                                                                value={dayjs(
                                                                    values.endDate
                                                                )}
                                                                format="DD/MM/YYYY"
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    setFieldValue(
                                                                        "endDate",
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                        ) : (
                                                            <DatePicker
                                                                label={t(
                                                                    "endDate"
                                                                )}
                                                                value={
                                                                    values.endDate
                                                                }
                                                                format="DD/MM/YYYY"
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    setFieldValue(
                                                                        "endDate",
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </LocalizationProvider>
                                                </Box>
                                            </Box>
                                            {/* BUTTONS */}
                                            <LoadingButton
                                                fullWidth
                                                type="submit"
                                                loading={
                                                    isLoadingAddWorkExtraCost ||
                                                    isLoadingEditWorkExtraCost
                                                }
                                                sx={{
                                                    width: "100%",
                                                    p: "1rem",
                                                    backgroundColor:
                                                        theme.palette.primary
                                                            .main,
                                                    color: theme.palette.neutral
                                                        .white,
                                                    "&:hover": {
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.light,
                                                    },
                                                }}
                                            >
                                                {t("submit")}
                                            </LoadingButton>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </Dialog>
                        {/* Delete Work Extra Cost */}
                        <Dialog
                            open={openDeleteWorkExtraCostDialog}
                            onClose={handleCloseDeleteWorkExtraCostDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {t("deleteSupplierContactConfirmationTitle")}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {t(
                                        "deleteSupplierContactConfirmationMessage"
                                    )}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    sx={{
                                        backgroundColor: theme.palette.alt.red,
                                        color: theme.palette.neutral.white,
                                        "&:hover": {
                                            backgroundColor:
                                                theme.palette.alt.lightRed,
                                        },
                                    }}
                                    onClick={
                                        handleCloseDeleteWorkExtraCostDialog
                                    }
                                >
                                    {t("noMessage")}
                                </Button>
                                <Button
                                    sx={{
                                        backgroundColor:
                                            theme.palette.primary.main,
                                        color: theme.palette.neutral.white,
                                        "&:hover": {
                                            backgroundColor:
                                                theme.palette.primary.light,
                                        },
                                    }}
                                    // loading={isLoadingDeleteWorkContact}
                                    onClick={handleDeleteWorkExtraCost}
                                    autoFocus
                                >
                                    {t("yesMessage")}
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("workDocuments")}
                            </Typography>
                            <Dropzone multiple={true} onDrop={onDropFiles}>
                                {({ getRootProps, getInputProps }) => (
                                    <FlexBetween>
                                        <Box
                                            {...getRootProps()}
                                            border={`2px dashed ${theme.palette.primary.main}`}
                                            p="1rem"
                                            width="100%"
                                            display="grid"
                                            gridTemplateColumns={
                                                !isNonMobile
                                                    ? "repeat(1, 1fr)"
                                                    : "repeat(5, auto)"
                                            }
                                            gap="10px"
                                            borderRadius="5px"
                                            sx={{
                                                "&:hover": {
                                                    cursor: "pointer",
                                                },
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            {files.length === 0 ? (
                                                <p>
                                                    {t("dropzoneAddYouFiles")}
                                                </p>
                                            ) : (
                                                files.map((file) => (
                                                    <Card
                                                        sx={{
                                                            backgroundColor:
                                                                theme.palette
                                                                    .background
                                                                    .D,
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            gap: "10px",
                                                            p: "15px 10px 15px 0px",
                                                            "& .MuiCardContent-root:last-child":
                                                                {
                                                                    paddingBottom:
                                                                        "20px",
                                                                },
                                                        }}
                                                        key={file.fileName}
                                                        onClick={(event) =>
                                                            handleFilePreview(
                                                                event,
                                                                file
                                                            )
                                                        }
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                // pl: 1,
                                                                // pb: 1,
                                                            }}
                                                        >
                                                            <IconButton
                                                                onClick={(
                                                                    event
                                                                ) =>
                                                                    handleRemoveFile(
                                                                        event,
                                                                        file
                                                                    )
                                                                }
                                                            >
                                                                <DeleteOutlined />
                                                            </IconButton>
                                                        </Box>
                                                        <CardContent
                                                            sx={{
                                                                p: "0px !important",
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: 14,
                                                                }}
                                                                color="text.secondary"
                                                            >
                                                                {file.fileName.split(
                                                                    "/"
                                                                )[2]
                                                                    ? file.fileName.split(
                                                                          "/"
                                                                      )[2]
                                                                    : file.fileName}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                ))
                                            )}
                                        </Box>
                                    </FlexBetween>
                                )}
                            </Dropzone>
                        </Box>
                        {/* Accomodations*/}
                        <Box
                            display="flex"
                            marginTop="10px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("accomodations")}
                            </Typography>
                            <Box
                                display="flex"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        flexGrow: 1,
                                        width: "100%",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        // borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: `${theme.palette.background.J} !important`,
                                        color: theme.palette.secondary[100],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor:
                                            theme.palette.background.A,
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        backgroundColor: `${theme.palette.background.J} !important`,
                                        color: theme.palette.secondary[100],
                                        borderTop: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text":
                                        {
                                            color: `${theme.palette.background.I}`,
                                        },
                                    "& .MuiDataGrid-toolbarContainer .MuiBox-root":
                                        {
                                            display: !isNonMobile
                                                ? "flex"
                                                : undefined,
                                            flexDirection: !isNonMobile
                                                ? "row"
                                                : undefined,
                                        },
                                    "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                        {
                                            backgroundColor: `${theme.palette.background.A} !important`,
                                        },
                                }}
                            >
                                <DataGrid
                                    autoHeight
                                    loading={
                                        isLoadingGetAccomodationPlanningsByWorkId ||
                                        !workAccomodations
                                    }
                                    getRowId={(row) => row.id}
                                    rows={workAccomodations || []}
                                    columns={workAccomodationsColumns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    slots={{
                                        toolbar: DataGridCustomWithoutToolbar,
                                    }}
                                    disableRowSelectionOnClick
                                />
                            </Box>
                        </Box>
                        {/* Employees*/}
                        <Box
                            display="flex"
                            marginTop="10px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("employees")}
                            </Typography>
                            <Box
                                display="flex"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        flexGrow: 1,
                                        width: "100%",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        // borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: `${theme.palette.background.J} !important`,
                                        color: theme.palette.secondary[100],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor:
                                            theme.palette.background.A,
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        backgroundColor: `${theme.palette.background.J} !important`,
                                        color: theme.palette.secondary[100],
                                        borderTop: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text":
                                        {
                                            color: `${theme.palette.background.I}`,
                                        },
                                    "& .MuiDataGrid-toolbarContainer .MuiBox-root":
                                        {
                                            display: !isNonMobile
                                                ? "flex"
                                                : undefined,
                                            flexDirection: !isNonMobile
                                                ? "row"
                                                : undefined,
                                        },
                                    "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                        {
                                            backgroundColor: `${theme.palette.background.A} !important`,
                                        },
                                }}
                            >
                                <DataGrid
                                    autoHeight
                                    loading={
                                        isLoadingGetEmployeePlanningsByWorkId ||
                                        !workEmployees
                                    }
                                    getRowId={(row) => row.id}
                                    rows={workEmployees || []}
                                    columns={
                                        !isNonMobile
                                            ? workEmployeesColumnsMobile
                                            : workEmployeesColumns
                                    }
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    slots={{
                                        toolbar: DataGridCustomWithoutToolbar,
                                    }}
                                    disableRowSelectionOnClick
                                />
                            </Box>
                        </Box>
                        {/* Suppliers*/}
                        {/* <Box
                            display="flex"
                            marginTop="10px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("suppliers")}
                            </Typography>
                            <Box
                                display="flex"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        flexGrow: 1,
                                        width: "100%",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        // borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: `${theme.palette.background.J} !important`,
                                        color: theme.palette.secondary[100],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor:
                                            theme.palette.background.A,
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        backgroundColor: `${theme.palette.background.J} !important`,
                                        color: theme.palette.secondary[100],
                                        borderTop: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text":
                                        {
                                            color: `${theme.palette.background.I}`,
                                        },
                                    "& .MuiDataGrid-toolbarContainer .MuiBox-root":
                                        {
                                            display: !isNonMobile
                                                ? "flex"
                                                : undefined,
                                            flexDirection: !isNonMobile
                                                ? "row"
                                                : undefined,
                                        },
                                    "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                        {
                                            backgroundColor: `${theme.palette.background.A} !important`,
                                        },
                                }}
                            >
                                <DataGrid
                                    autoHeight
                                    loading={isLoadingGetWork || !work}
                                    getRowId={(row) => row.id}
                                    rows={workSuppliers || []}
                                    columns={workSuppliersColumns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    slots={{
                                        toolbar: DataGridCustomWithoutToolbar,
                                    }}
                                    disableRowSelectionOnClick
                                />
                            </Box>
                        </Box> */}
                        {/* Vehicles*/}
                        <Box
                            display="flex"
                            marginTop="10px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("vehicles")}
                            </Typography>
                            <Box
                                display="flex"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        flexGrow: 1,
                                        width: "100%",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        // borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: `${theme.palette.background.J} !important`,
                                        color: theme.palette.secondary[100],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor:
                                            theme.palette.background.A,
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        backgroundColor: `${theme.palette.background.J} !important`,
                                        color: theme.palette.secondary[100],
                                        borderTop: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text":
                                        {
                                            color: `${theme.palette.background.I}`,
                                        },
                                    "& .MuiDataGrid-toolbarContainer .MuiBox-root":
                                        {
                                            display: !isNonMobile
                                                ? "flex"
                                                : undefined,
                                            flexDirection: !isNonMobile
                                                ? "row"
                                                : undefined,
                                        },
                                    "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                        {
                                            backgroundColor: `${theme.palette.background.A} !important`,
                                        },
                                }}
                            >
                                <DataGrid
                                    autoHeight
                                    loading={
                                        isLoadingGetVehiclePlanningsByWorkId ||
                                        !workVehicles
                                    }
                                    getRowId={(row) => row.id}
                                    rows={workVehicles || []}
                                    columns={
                                        !isNonMobile
                                            ? workVehiclesColumnsMobile
                                            : workVehiclesColumns
                                    }
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    slots={{
                                        toolbar: DataGridCustomWithoutToolbar,
                                    }}
                                    disableRowSelectionOnClick
                                />
                            </Box>
                        </Box>
                        {/* BUTTONS */}
                        <Box display="flex" gap="10px">
                            <LoadingButton
                                fullWidth
                                type="button"
                                onClick={handleOpenDeleteConfirmationDialog}
                                loading={isLoadingDeleteWork}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                            >
                                {t("deleteWorkButton")}
                            </LoadingButton>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                loading={isLoadingGetWork}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                            >
                                {t("editWork")}
                            </LoadingButton>
                        </Box>
                    </Box>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity={snackbarSeverity}
                            sx={{
                                mt: "10px",
                                backgroundColor:
                                    snackbarSeverity === "success"
                                        ? "#10781F"
                                        : "#ff000082",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {snackbarMsg}
                        </Alert>
                    </Snackbar>
                    <Dialog
                        open={openDeleteConfirmationDialog}
                        onClose={handleCloseDeleteConfirmationDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("deleteWorkConfirmationTitle")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t("deleteWorkConfirmationMessage")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                                onClick={handleCloseDeleteConfirmationDialog}
                            >
                                {t("noMessage")}
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                                onClick={handleDeleteWork}
                                autoFocus
                            >
                                {t("yesMessage")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            )}
        </Formik>
    );
};

export default EditWorkForm;

import { apiSlice } from "app/api/apiSlice";

export const suppliersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSuppliersPaginated: builder.query({
            query: (query) => {
                const params = [];
                params.push(`limit=${(query && query.limit) || 10}`);
                params.push(`offset=${(query && query.offset) || 0}`);
                if (query && query.search)
                    params.push(
                        `search=${encodeURIComponent(query && query.search)}`
                    );
                if (query && query.filters)
                    params.push(
                        `filters=${encodeURIComponent(
                            JSON.stringify(query && query.filters)
                        )}`
                    );
                if (query && query.orderByCollection)
                    params.push(
                        `orderByCollection=${encodeURIComponent(
                            JSON.stringify(query && query.orderByCollection)
                        )}`
                    );
                const queryString =
                    params.length > 0 ? `?${params.join("&")}` : "";
                return {
                    url: `Suppliers/GetAllPaginated${queryString.toString()}`,
                    validateStatus: (response, result) => {
                        return response.status === 200 && !result.isError;
                    },
                    query,
                };
            },
            providesTags: [{ type: "Suppliers", id: "LIST" }],
        }),
        getSuppliers: builder.query({
            query: () => ({
                url: `Suppliers/GetAll`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "Suppliers", id: "LIST" }],
        }),
        postSupplier: builder.mutation({
            query: (supplierData) => ({
                url: `Suppliers`,
                method: "POST",
                body: {
                    ...supplierData,
                },
            }),
            invalidatesTags: [{ type: "Suppliers", id: "LIST" }],
        }),
        getSupplierById: builder.query({
            query: (id) => ({
                url: `Suppliers/${id}`,
            }),
            providesTags: (result, error, arg) => [
                { type: "Suppliers", id: arg },
            ],
        }),
        updateSupplier: builder.mutation({
            query: (supplierData) => ({
                url: `Suppliers`,
                method: "PUT",
                body: {
                    ...supplierData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Suppliers", id: "LIST" },
                { type: "Suppliers", id: arg.id },
            ],
        }),
        addSupplierContact: builder.mutation({
            query: (supplierData) => ({
                url: `Suppliers/AddSupplierContact`,
                method: "PUT",
                body: {
                    ...supplierData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Suppliers", id: arg.id },
                { type: "Contacts", id: "LIST" },
            ],
        }),
        editSupplierContact: builder.mutation({
            query: (supplierData) => ({
                url: `Suppliers/UpdateSupplierContact`,
                method: "PUT",
                body: {
                    ...supplierData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Suppliers", id: arg.id },
                { type: "Contacts", id: "LIST" },
            ],
        }),
        deleteSupplierContact: builder.mutation({
            query: (supplierData) => ({
                url: `Suppliers/DeleteSupplierContact`,
                method: "PUT",
                body: {
                    ...supplierData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Suppliers", id: arg.id },
                { type: "Contacts", id: "LIST" },
            ],
        }),
        deleteSupplierById: builder.mutation({
            query: (id) => ({
                url: `Suppliers/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [
                { type: "Suppliers", id: "LIST" },
                { type: "Contacts", id: "LIST" },
            ],
        }),
    }),
});

export const {
    useGetSuppliersQuery,
    usePostSupplierMutation,
    useGetSupplierByIdQuery,
    useUpdateSupplierMutation,
    useDeleteSupplierByIdMutation,
    useEditSupplierContactMutation,
    useDeleteSupplierContactMutation,
    useAddSupplierContactMutation,
} = suppliersApiSlice;

import { useState, useEffect } from "react";
import { useGetEmployeesWithoutEmployeeCostByWorkQuery } from "features/employees/employeesApiSlice";
import PageHeader from "components/PageHeader";
import {
    Box,
    TextField,
    Typography,
    useTheme,
    Alert,
    FormControl,
    Autocomplete,
    useMediaQuery,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";
import {
    useCalculateEmployeeRegistMutation,
    usePostEmployeeCostsByWorkMutation,
} from "./employeeCostsByWorkApiSlice";
import {
    useGetAllWorksWichEmployeeHasProcessedSalaryLastMonthQuery,
    useLazyGetAllWorksWichEmployeeHasProcessedSalaryLastMonthQuery,
} from "features/works/worksApiSlice";

const NewEmployeeCostsByWorkForm = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate(); /* Set the Navigation Service */
    const location = useLocation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const [employeeId, setEmployeeId] = useState(0);

    const employeeCostsByWorkSchema = yup.object().shape({
        employeeId: yup.string().required(t("requiredField")),
        workPlanningId: yup.string().required(t("requiredField")),
    }); /* Set the company doc Schema */

    const initialValuesEmployeeCostsByWork = {
        employeeId: "",
        workPlanningId: "",
    }; /* Set the Form Initial Values */

    const [createEmployeeCostsByWork, { isLoading }] =
        usePostEmployeeCostsByWorkMutation(); /* Set the Login Mutation */

    const [calculateEmployeeRegist, { isLoadingCalculateEmployeeRegista }] =
        useCalculateEmployeeRegistMutation(); /* Set the Login Mutation */

    const {
        data: employeesWithoutEmplyeeCostsByWork,
        isLoading: isLoadingEmployeesWithoutEmplyeeCostsByWork,
    } = useGetEmployeesWithoutEmployeeCostByWorkQuery();

    // const {
    //     data: workPlannings,
    //     isLoading: isLoadingWorkPlannings,
    //     refetch: refetchWorkPlannings,
    // } = useGetAllWorksWichEmployeeHasProcessedSalaryLastMonthQuery(employeeId);

    const [
        getAllWorksWichEmployeeHasProcessedSalaryLastMonthQuery,
        workPlannings,
    ] = useLazyGetAllWorksWichEmployeeHasProcessedSalaryLastMonthQuery();

    const [errMsg, setErrMsg] = useState(""); /* Set the Error Message State */

    useEffect(() => {
        if (employeeId) {
            getAllWorksWichEmployeeHasProcessedSalaryLastMonthQuery(employeeId);
        }
    }, [employeeId]);

    const getWorksByEmployeeId = async (id) => {
        setEmployeeId(id);
    };

    const handleFormSubmit = async (values, onSubmitProps) => {
        setErrMsg("");

        try {
            const { codeResult, employeeCostByWorkId } =
                await createEmployeeCostsByWork(values).unwrap();

            if (
                codeResult !==
                RESPONSES.employeeCostsByWork.sucessToInsertEmployeeCostsByWork
            ) {
                if (
                    codeResult ===
                    RESPONSES.employeeCostsByWork
                        .invalidToInsertEmployeeCostsByWork
                ) {
                    setErrMsg(t("invalidToInsertEmployeeCostsByWork"));
                } else {
                    setErrMsg(t("errorToInsertEmployeeCostByWork"));
                }
            } else {
                let calculateEmployeeRegistDTO = {
                    id: employeeCostByWorkId,
                    isToUpdate: false,
                };

                const { codeResult } = await calculateEmployeeRegist(
                    calculateEmployeeRegistDTO
                ).unwrap();

                if (
                    codeResult ===
                    RESPONSES.employeeCostsByWork
                        .sucessToCalculateEmployeeRegist
                ) {
                    navigate(
                        `${location.pathname.replace(
                            "new",
                            employeeCostByWorkId
                        )}`
                    );
                    onSubmitProps.resetForm();
                } else {
                    setErrMsg(t("errorToInsertEmployeeCostByWork"));
                }
            }
        } catch (err) {
            setErrMsg(t("errorToInsertEmployeeCostByWork"));
        }
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesEmployeeCostsByWork}
            validationSchema={employeeCostsByWorkSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        m={!isNonMobile ? "0" : "1.5rem 2.5rem"}
                        display="grid"
                        gridTemplateRows="repeat(3, auto)"
                        gap="20px"
                        borderRadius="5px"
                        p="2rem"
                    >
                        <PageHeader title={t("newCostTitle")} subtitle="" />
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("selectAnEmployeeYouWantToProcessCost")}
                            </Typography>
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <Autocomplete
                                    variant="outlined"
                                    loading={
                                        isLoadingEmployeesWithoutEmplyeeCostsByWork
                                    }
                                    options={
                                        employeesWithoutEmplyeeCostsByWork || []
                                    }
                                    getOptionLabel={(option) => option.name}
                                    value={
                                        (employeesWithoutEmplyeeCostsByWork &&
                                            employeesWithoutEmplyeeCostsByWork.find(
                                                (employee) =>
                                                    employee.id ===
                                                    values.employeeId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "employeeId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                        if (newValue) {
                                            getWorksByEmployeeId(newValue.id);
                                        }
                                    }}
                                    onBlur={handleBlur("employeeId")}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("employee")}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.employeeId
                                                    ) &&
                                                    Boolean(errors.employeeId)
                                                }
                                                helperText={
                                                    touched.employeeId &&
                                                    errors.employeeId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />
                                <Autocomplete
                                    variant="outlined"
                                    options={workPlannings.data || []}
                                    getOptionLabel={(option) =>
                                        option.name + " - " + option.work.name
                                    }
                                    value={
                                        (workPlannings.data &&
                                            workPlannings.data.length > 0 &&
                                            workPlannings.data.find(
                                                (workPlanning) =>
                                                    workPlanning.id ===
                                                    values.workPlanningId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "workPlanningId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                    }}
                                    onBlur={handleBlur("workPlanningId")}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("planningWork")}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.workPlanningId
                                                    ) &&
                                                    Boolean(
                                                        errors.workPlanningId
                                                    )
                                                }
                                                helperText={
                                                    touched.workPlanningId &&
                                                    errors.workPlanningId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />
                            </Box>
                        </Box>
                        {errMsg && (
                            <Alert
                                severity="error"
                                sx={{
                                    mt: "10px",
                                    backgroundColor: "#540e0e",
                                    color: "#FFFFFF",
                                    alignItems: "center",
                                }}
                            >
                                {errMsg}
                            </Alert>
                        )}
                        {/* BUTTONS */}
                        <LoadingButton
                            fullWidth
                            type="submit"
                            loading={
                                isLoading || isLoadingCalculateEmployeeRegista
                            }
                            sx={{
                                width: "100%",
                                p: "1rem",
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.neutral.white,
                                "&:hover": {
                                    backgroundColor:
                                        theme.palette.primary.light,
                                },
                            }}
                        >
                            {t("newCostTitle")}
                        </LoadingButton>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

export default NewEmployeeCostsByWorkForm;

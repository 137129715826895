import { useState, useRef, useEffect } from "react";
import {
    useGetClientByIdQuery,
    useUpdateClientMutation,
    useDeleteClientByIdMutation,
    useDeleteImageMutation,
    useUploadImageMutation,
} from "./clientsApiSlice";
import PageHeader from "components/PageHeader";
import LoadingSpinner from "components/LoadingSpinner";
import {
    Box,
    Button,
    TextField,
    Typography,
    useTheme,
    useMediaQuery,
    Alert,
    IconButton,
    Card,
    CardContent,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DeleteOutlined } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";
import FlexBetween from "components/FlexBetween";
import { useParams } from "react-router-dom";
import Dropzone from "react-dropzone";
import { format } from "date-fns";
import DataGridCustomWithoutToolbar from "components/DataGridCustomWithoutToolbar";
import { useGetWorkResumeByClientIdQuery } from "features/home/homePageApiSlice";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import useAuth from "hooks/useAuth";
import {
    useDeleteAttachmentMutation,
    useUploadAttachmentMutation,
} from "features/attachments/attachmentsApiSlice";

const EditClientForm = () => {
    // #region Setups

    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate(); /* Set the Navigation Service */
    const location = useLocation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const nameRef = useRef(); /* User Ref to set the focus to the user input */

    const { userType } = useAuth();

    const [files, setFiles] = useState([]);
    const [clientWorks, setClientWorks] = useState([]);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const [totalHours, setTotalHours] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [totalProfit, setTotalProfit] = useState(0);

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMsg(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
        useState(false);

    const handleOpenDeleteConfirmationDialog = () => {
        setOpenDeleteConfirmationDialog(true);
    };

    const handleCloseDeleteConfirmationDialog = () => {
        setOpenDeleteConfirmationDialog(false);
    };

    /* Dialog to See Client Resume*/
    const [openClientResumeDialog, setOpenClientResumeDialog] = useState(false);

    const handleOpenClientResumeDialog = () => {
        setOpenClientResumeDialog(true);
    };

    const handleCloseClientResumeDialog = () => {
        setOpenClientResumeDialog(false);
    };

    //#endregion Setups

    // #region Client Works

    const clientWorksColumns = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
        },
        {
            field: "address",
            headerName: t("address"),
            flex: 1,
        },
        {
            field: "startDate",
            headerName: t("startDate"),
            flex: 1,
            valueGetter: (params) => {
                const startDate = params.row.startDate;
                if (startDate !== null) {
                    return format(new Date(startDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "endDate",
            headerName: t("endDate"),
            flex: 1,
            valueGetter: (params) => {
                const endDate = params.row.endDate;
                if (endDate !== null) {
                    return format(new Date(endDate), "dd/MM/yyyy");
                }
            },
        },
    ];

    const clientWorksColumnsMobile = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
        },
        {
            field: "startDate",
            headerName: t("startDate"),
            flex: 1,
            valueGetter: (params) => {
                const startDate = params.row.startDate;
                if (startDate !== null) {
                    return format(new Date(startDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "endDate",
            headerName: t("endDate"),
            flex: 1,
            valueGetter: (params) => {
                const endDate = params.row.endDate;
                if (endDate !== null) {
                    return format(new Date(endDate), "dd/MM/yyyy");
                }
            },
        },
    ];

    // #endregion Works

    // #region Client

    const { id } = useParams(); /* Get the Client Id from the route Params */

    const { data: client, isLoading: isLoadingGetClient } =
        useGetClientByIdQuery(id); /* Set the Get Client by Id Mutation */

    const [updateClient, { isLoading: isLoadingUpdateClient }] =
        useUpdateClientMutation(); /* Set the Update Client Mutation */

    const [deleteClient, { isLoading: isLoadingDeleteClient }] =
        useDeleteClientByIdMutation(); /* Set the Delete Client Mutation */

    useEffect(() => {
        if (client) {
            setClientWorks(client.works);
            const processAttachments = async () => {
                const attachmentPromises = client.attachments.map(
                    async (attachment) => {
                        let file = "";
                        if (!attachment.preSignedUrl) {
                            file = await base64ToFile(
                                attachment.content,
                                attachment.fileName,
                                attachment.fileType
                            );
                        }
                        return { ...attachment, file };
                    }
                );

                try {
                    const attachments = await Promise.all(attachmentPromises);
                    setFiles(
                        attachments.filter(
                            (attachment) => !!attachment.fileName.split("/")[2]
                        )
                    );
                } catch (error) {
                    console.error("Error processing attachments:", error);
                }
            };

            processAttachments();
        }
    }, [client]);

    const { data: workResume, isLoading: isLoadingGetWorkResumeByClientId } =
        useGetWorkResumeByClientIdQuery(id);

    useEffect(() => {
        if (workResume) {
            setTotalHours(workResume.hours.toFixed(2));
            setTotalCost(workResume.cost.toFixed(2));
            setTotalProfit(workResume.profit.toFixed(2));
        }
    }, [workResume]);

    function base64ToFile(base64Data, fileName, mimeType) {
        let base64Content = null;
        if (base64Data.split(",").length > 1) {
            base64Content = base64Data.split(",")[1];
        } else {
            base64Content = base64Data;
        }

        const uint8Array = new Uint8Array(
            atob(base64Content)
                .split("")
                .map((char) => char.charCodeAt(0))
        );

        const blob = new Blob([uint8Array], { type: mimeType });

        const file = new File([blob], fileName, { type: mimeType });

        return file;
    }

    const clientSchema = yup.object().shape({
        name: yup.string().required(t("requiredField")),
        address: yup.string().required(t("requiredField")),
        contact: yup.object().shape({
            name: yup.string().required(t("requiredField")),
            position: yup.string().required(t("requiredField")),
            email: yup
                .string()
                .email(t("invalidEmail"))
                .required(t("requiredField")),
            phoneNumber: yup.string().required(t("requiredField")),
        }),
    }); /* Set the Login Schema */

    const initialValuesClient = {
        name: "",
        address: "",
        contact: {
            name: "",
            position: "",
            email: "",
            phoneNumber: "",
        },
        attachments: [],
        works: [],
    }; /* Set the Form Initial Values */

    const handleRemoveFile = (event, file) => {
        event.stopPropagation();
        const updatedFiles = files.filter((f) => f !== file);
        setFiles(updatedFiles);
    };

    const onDropFiles = (acceptedFiles) => {
        const updatedFiles = [...files];

        acceptedFiles.forEach((file) => {
            const existingFile = updatedFiles.find(
                (f) => f.fileName === file.name
            );
            if (!existingFile) {
                updatedFiles.push({
                    fileName: file.name,
                    fileType: file.type,
                    preSignedUrl: "",
                    content: "",
                    file: file,
                });
            } else {
                handleSnackbarOpen(
                    t("file") + file.name + t("exists"),
                    "error"
                );
            }
        });

        setFiles(updatedFiles);
    };

    const handleFilePreview = async (event, file) => {
        event.stopPropagation();
        if (file.preSignedUrl) {
            try {
                const newWindow = window.open(file.preSignedUrl, "_blank");
                if (newWindow) newWindow.opener = null;
            } catch (err) {
                handleSnackbarOpen(t("errorWhenDownload"), "error");
            }
        } else {
            try {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(file.file);
                link.download = file.fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (err) {
                handleSnackbarOpen(t("errorWhenDownload"), "error");
            }
        }
    };

    const handleFormSubmit = async (values, onSubmitProps) => {
        let attachments = [];
        setLoadingFiles(true);
        let isToReload = false;
        try {
            // add only the new attachments
            const _files = files.filter((file) => !!file.file);

            await Promise.all(
                _files.map(async (file) => {
                    if (
                        values.attachments.length === 0 ||
                        values.attachments.filter(
                            (a) => a.fileName === file.fileName
                        ).length === 0
                    ) {
                        let attachment = {
                            id: 0,
                            fileName: file.fileName,
                            content: file.file,
                            fileType: file.fileType,
                        };
                        attachments.push(attachment);
                    }
                })
            );

            let newAttachments = [...attachments.filter((a) => a.id === 0)];

            let attachmentsToAdd = {
                attachments: [...newAttachments],
            };

            // region delete attachments
            let attachmentsToDelete = [];
            values.attachments.forEach((attachment) => {
                const _attachment = {
                    ...attachment,
                    fileName: attachment.fileName.split("/")[2],
                    fileType: attachment.fileType || "image/jpg",
                };
                if (
                    files.filter((f) => f.fileName === attachment.fileName)
                        .length === 0
                ) {
                    delete _attachment.preSignedUrl;
                    if (attachment.fileName.split("/")[2]) {
                        attachmentsToDelete.push(_attachment);
                    }
                }
            });

            const createPromises = [];

            if (attachmentsToAdd.attachments.length > 0) {
                isToReload = true;
                attachmentsToAdd.attachments.forEach((attachment) => {
                    let addAttachmentsDTO = {
                        Id: parseInt(id),
                        Attachment: attachment,
                        EntityName: "Clients",
                    };

                    // Store the promise in the array
                    const promise =
                        uploadAttachment(addAttachmentsDTO).unwrap();

                    createPromises.push(promise);
                });

                await Promise.all(createPromises).then(async (results) => {
                    // Create an array of promises from results
                    const processPromises = results.map(
                        async ({
                            codeResult,
                            signedUrl,
                            expiresIn,
                            fileName,
                        }) => {
                            if (
                                codeResult !==
                                RESPONSES.client.sucessToAddAttachments
                            ) {
                                handleSnackbarOpen(
                                    t("anErrorOcurred"),
                                    "error"
                                );
                            } else {
                                const attachment =
                                    attachmentsToAdd.attachments.find(
                                        (attachment) =>
                                            attachment.fileName === fileName
                                    );

                                return fetch(signedUrl, {
                                    method: "PUT",
                                    body: attachment.content,
                                    headers: {
                                        "Content-Type": "multipart/form-data",
                                        mimeType: attachment.fileType,
                                    },
                                });
                            }
                        }
                    );

                    // Wait for all processes to complete
                    await Promise.all(processPromises);
                    handleSnackbarOpen(t("sucess"), "success");
                });
            }

            const deletePromises = [];

            if (attachmentsToDelete.length > 0) {
                isToReload = true;
                attachmentsToDelete.forEach((attachment) => {
                    let addAttachmentsDTO = {
                        Id: parseInt(id),
                        Attachment: attachment,
                        EntityName: "Clients",
                    };
                    // Store the promise in the array
                    const promise =
                        deleteAttachment(addAttachmentsDTO).unwrap();

                    deletePromises.push(promise);
                });

                await Promise.all(deletePromises).then((results) => {
                    // Handle the results after all promises are resolved
                    results.forEach(({ codeResult }) => {
                        if (
                            codeResult !==
                            RESPONSES.client.sucessToAddAttachments
                        ) {
                            handleSnackbarOpen(t("anErrorOcurred"), "error");
                        } else {
                            handleSnackbarOpen(t("sucess"), "success");
                        }
                    });
                });
            }
        } catch (err) {
            handleSnackbarOpen(t("anErrorOcurred"), "error");
        }
        setLoadingFiles(false);
        if (isToUpdateClient === true) {
            try {
                values.attachments = null;
                //endregion delete attachments
                const { codeResult } = await updateClient(values).unwrap();
                if (codeResult !== RESPONSES.client.successToUpdateClient) {
                    if (codeResult === RESPONSES.client.invalidToUpdateClient) {
                        handleSnackbarOpen(t("invalidToUpdateClient"), "error");
                    } else if (
                        codeResult === RESPONSES.client.invalidToInsertClient
                    ) {
                        handleSnackbarOpen(t("invalidToInsertClient"), "error");
                    } else {
                        handleSnackbarOpen(t("errorUpdateClient"), "error");
                    }
                } else {
                    handleSnackbarOpen(t("successToUpdateClient"), "success");
                }
            } catch (err) {
                handleSnackbarOpen(t("errorUpdateClient"), "error");
            }
            setIsToUpdateClient(false);
        } else {
            if (isToReload === true) {
                window.location.reload();
            }
        }
    };

    const [isToUpdateClient, setIsToUpdateClient] = useState(false);

    const handleDeleteClient = async () => {
        setOpenDeleteConfirmationDialog(false);

        try {
            const { codeResult } = await deleteClient(id).unwrap();
            if (codeResult !== RESPONSES.client.successToDeleteClientById) {
                if (codeResult === RESPONSES.client.invalidToDeleteClientById) {
                    handleSnackbarOpen(t("invalidToDeleteClientById"), "error");
                } else if (
                    codeResult ===
                    RESPONSES.client.invalidToDeleteClientByIdBecauseWorks
                ) {
                    handleSnackbarOpen(
                        t("invalidToDeleteClientByIdBecauseWorks"),
                        "error"
                    );
                } else {
                    handleSnackbarOpen(t("errorDeleteClient"), "error");
                }
            } else {
                navigate(`/${location.pathname.split("/")[1]}`);
            }
        } catch (err) {
            handleSnackbarOpen(t("errorDeleteClient"), "error");
        }
    };

    const [uploadAttachment, { isLoading: isLoadingUploadAttachment }] =
        useUploadAttachmentMutation();

    const [deleteAttachment, { isLoading: isLoadingDeleteAttachment }] =
        useDeleteAttachmentMutation();

    const [loadingFiles, setLoadingFiles] = useState(false);

    // function removeSpecialCharacters(fileName) {
    //     // Remove caracteres especiais usando regex, mantendo letras, números, traços e underscores
    //     const cleanFileName = fileName.replace(/[^a-zA-Z0-9._-]/g, '');
    //     return cleanFileName;
    //   }

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={client || initialValuesClient}
            validationSchema={clientSchema}
            enableReinitialize={true}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    {isLoadingGetClient ||
                    isLoadingUpdateClient ||
                    loadingFiles ||
                    isLoadingDeleteClient ? (
                        <LoadingSpinner />
                    ) : null}
                    <Box
                        m={!isNonMobile ? "0" : "1.5rem 2.5rem"}
                        display={!isNonMobile ? "flex" : "grid"}
                        gridTemplateRows={
                            !isNonMobile ? undefined : "repeat(4, auto)"
                        }
                        flexDirection={!isNonMobile ? "column" : undefined}
                        gap="20px"
                        borderRadius="5px"
                        p="2rem"
                    >
                        <PageHeader title={values.name} subtitle="" />
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            {userType !== "ADMIN" ? (
                                <Typography
                                    fontWeight="500"
                                    fontSize="20px"
                                    color="primary"
                                    variant="h5"
                                    textAlign="left"
                                >
                                    {t("clientInformation")}
                                </Typography>
                            ) : (
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography
                                        fontWeight="500"
                                        fontSize="20px"
                                        color="primary"
                                        variant="h5"
                                        textAlign="left"
                                    >
                                        {t("clientInformation")}
                                    </Typography>
                                    <QueryStatsIcon
                                        sx={{
                                            color: "#0071BB",
                                            cursor: "pointer",
                                            transition: "box-shadow 0.3s ease",
                                            ":hover": {
                                                boxShadow:
                                                    "0px 0px 10px rgba(0, 0, 0, 0.2)",
                                            },
                                        }}
                                        onClick={handleOpenClientResumeDialog}
                                    ></QueryStatsIcon>
                                </Box>
                            )}
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <TextField
                                    variant="outlined"
                                    label={t("name")}
                                    inputRef={nameRef}
                                    onBlur={handleBlur}
                                    onChange={(event) => {
                                        handleChange(event);
                                        setIsToUpdateClient(true); // Atualiza a flag sem substituir handleChange
                                    }}
                                    value={values.name}
                                    name="name"
                                    error={
                                        Boolean(touched.name) &&
                                        Boolean(errors.name)
                                    }
                                    helperText={touched.name && errors.name}
                                    sx={{ width: "100%" }}
                                />

                                <TextField
                                    label={t("address")}
                                    onBlur={handleBlur}
                                    value={values.address}
                                    name="address"
                                    onChange={(event) => {
                                        handleChange(event);
                                        setIsToUpdateClient(true); // Atualiza a flag sem substituir handleChange
                                    }}
                                    error={
                                        Boolean(touched.address) &&
                                        Boolean(errors.address)
                                    }
                                    helperText={
                                        touched.address && errors.address
                                    }
                                    sx={{ width: "100%" }}
                                />
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("contactInformation")}
                            </Typography>
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <TextField
                                    variant="outlined"
                                    label={t("name")}
                                    onBlur={() => handleBlur("contact.name")}
                                    onChange={(event) => {
                                        handleChange("contact.name")(
                                            event.target.value
                                        );
                                        setIsToUpdateClient(true);
                                    }}
                                    name="contact.name"
                                    value={values.contact.name}
                                    error={
                                        Boolean(touched.contact?.name) &&
                                        Boolean(errors.contact?.name)
                                    }
                                    helperText={
                                        touched.contact?.name &&
                                        errors.contact?.name
                                    }
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("position")}
                                    onBlur={() =>
                                        handleBlur("contact.position")
                                    }
                                    onChange={(event) => {
                                        handleChange("contact.position")(
                                            event.target.value
                                        );
                                        setIsToUpdateClient(true);
                                    }}
                                    name="contact.position"
                                    value={values.contact.position}
                                    error={
                                        Boolean(touched.contact?.position) &&
                                        Boolean(errors.contact?.position)
                                    }
                                    helperText={
                                        touched.contact?.position &&
                                        errors.contact?.position
                                    }
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("email")}
                                    onBlur={() => handleBlur("contact.email")}
                                    onChange={(event) => {
                                        handleChange("contact.email")(
                                            event.target.value
                                        );
                                        setIsToUpdateClient(true);
                                    }}
                                    name="contact.email"
                                    value={values.contact.email}
                                    error={
                                        Boolean(touched.contact?.email) &&
                                        Boolean(errors.contact?.email)
                                    }
                                    helperText={
                                        touched.contact?.email &&
                                        errors.contact?.email
                                    }
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("phoneNumber")}
                                    onBlur={() =>
                                        handleBlur("contact.phoneNumber")
                                    }
                                    onChange={(event) => {
                                        handleChange("contact.phoneNumber")(
                                            event.target.value
                                        );
                                        setIsToUpdateClient(true);
                                    }}
                                    name="contact.phoneNumber"
                                    value={values.contact.phoneNumber}
                                    error={
                                        Boolean(touched.contact?.phoneNumber) &&
                                        Boolean(errors.contact?.phoneNumber)
                                    }
                                    helperText={
                                        touched.contact?.phoneNumber &&
                                        errors.contact?.phoneNumber
                                    }
                                    sx={{ width: "100%" }}
                                />
                            </Box>
                        </Box>

                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("clientDocuments")}
                            </Typography>
                            <Dropzone multiple={true} onDrop={onDropFiles}>
                                {({ getRootProps, getInputProps }) => (
                                    <FlexBetween>
                                        <Box
                                            {...getRootProps()}
                                            border={`2px dashed ${theme.palette.primary.main}`}
                                            p="1rem"
                                            width="100%"
                                            display="grid"
                                            gridTemplateColumns={
                                                !isNonMobile
                                                    ? "repeat(1, 1fr)"
                                                    : "repeat(5, auto)"
                                            }
                                            gap="10px"
                                            borderRadius="5px"
                                            sx={{
                                                "&:hover": {
                                                    cursor: "pointer",
                                                },
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            {files.length === 0 ? (
                                                <p>
                                                    {t("dropzoneAddYouFiles")}
                                                </p>
                                            ) : (
                                                files.map((file) => (
                                                    <Card
                                                        sx={{
                                                            backgroundColor:
                                                                theme.palette
                                                                    .background
                                                                    .D,
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            gap: "10px",
                                                            p: "15px 10px 15px 0px",
                                                            "& .MuiCardContent-root:last-child":
                                                                {
                                                                    paddingBottom:
                                                                        "20px",
                                                                },
                                                        }}
                                                        key={file.fileName}
                                                        onClick={(event) =>
                                                            handleFilePreview(
                                                                event,
                                                                file
                                                            )
                                                        }
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                // pl: 1,
                                                                // pb: 1,
                                                            }}
                                                        >
                                                            <IconButton
                                                                onClick={(
                                                                    event
                                                                ) =>
                                                                    handleRemoveFile(
                                                                        event,
                                                                        file
                                                                    )
                                                                }
                                                            >
                                                                <DeleteOutlined />
                                                            </IconButton>
                                                        </Box>
                                                        <CardContent
                                                            sx={{
                                                                p: "0px !important",
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: 14,
                                                                }}
                                                                color="text.secondary"
                                                            >
                                                                {file.fileName.split(
                                                                    "/"
                                                                )[2]
                                                                    ? file.fileName.split(
                                                                          "/"
                                                                      )[2]
                                                                    : file.fileName}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                ))
                                            )}
                                        </Box>
                                    </FlexBetween>
                                )}
                            </Dropzone>
                        </Box>
                        <Box
                            display="flex"
                            marginTop="10px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("works")}
                            </Typography>
                            <Box
                                display="flex"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        flexGrow: 1,
                                        width: "100%",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        // borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: `${theme.palette.background.J} !important`,
                                        color: theme.palette.secondary[100],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor:
                                            theme.palette.background.A,
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        backgroundColor: `${theme.palette.background.J} !important`,
                                        color: theme.palette.secondary[100],
                                        borderTop: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text":
                                        {
                                            color: `${theme.palette.background.I}`,
                                        },
                                    "& .MuiDataGrid-toolbarContainer .MuiBox-root":
                                        {
                                            display: !isNonMobile
                                                ? "flex"
                                                : undefined,
                                            flexDirection: !isNonMobile
                                                ? "row"
                                                : undefined,
                                        },
                                    "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                        {
                                            backgroundColor: `${theme.palette.background.A} !important`,
                                        },
                                }}
                            >
                                <DataGrid
                                    autoHeight
                                    loading={isLoadingGetClient || !client}
                                    getRowId={(row) => row.id}
                                    rows={clientWorks || []}
                                    columns={
                                        !isNonMobile
                                            ? clientWorksColumnsMobile
                                            : clientWorksColumns
                                    }
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    slots={{
                                        toolbar: DataGridCustomWithoutToolbar,
                                    }}
                                    disableRowSelectionOnClick
                                />
                            </Box>
                        </Box>
                        {/* BUTTONS */}
                        <Box display="flex" gap="10px">
                            <LoadingButton
                                fullWidth
                                type="button"
                                onClick={handleOpenDeleteConfirmationDialog}
                                loading={isLoadingDeleteClient}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                            >
                                {t("deleteClientButton")}
                            </LoadingButton>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                loading={isLoadingUpdateClient}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                            >
                                {t("updateClientButton")}
                            </LoadingButton>
                        </Box>
                    </Box>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity={snackbarSeverity}
                            sx={{
                                mt: "10px",
                                backgroundColor:
                                    snackbarSeverity === "success"
                                        ? "#10781F"
                                        : "#ff000082",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {snackbarMsg}
                        </Alert>
                    </Snackbar>
                    <Dialog
                        open={openDeleteConfirmationDialog}
                        onClose={handleCloseDeleteConfirmationDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("deleteClientConfirmationTitle")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t("deleteClientConfirmationMessage")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                                onClick={handleCloseDeleteConfirmationDialog}
                            >
                                {t("noMessage")}
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                                onClick={handleDeleteClient}
                                autoFocus
                            >
                                {t("yesMessage")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* Client Resume */}
                    <Dialog
                        open={openClientResumeDialog}
                        onClose={handleCloseClientResumeDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <Box
                                boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                sx={{
                                    borderRadius: "20px",
                                    margin: "20px",
                                    padding: "10px",
                                }}
                            >
                                <Typography
                                    variant="h1"
                                    component="h2"
                                    color="black"
                                    textAlign="center"
                                >
                                    {totalHours}
                                </Typography>
                                <Typography
                                    variant="h2"
                                    component="h2"
                                    color="#c8c8c8"
                                    textAlign="center"
                                    fontSize="32px"
                                    fontWeight="500"
                                >
                                    {t("totalHours")}
                                </Typography>
                            </Box>
                            <Box
                                boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                sx={{
                                    borderRadius: "20px",
                                    margin: "20px",
                                    padding: "10px",
                                }}
                            >
                                <Typography
                                    variant="h1"
                                    component="h2"
                                    color="black"
                                    textAlign="center"
                                >
                                    {totalCost + " €"}
                                </Typography>
                                <Typography
                                    variant="h2"
                                    component="h2"
                                    color="#c8c8c8"
                                    textAlign="center"
                                    fontSize="32px"
                                    fontWeight="500"
                                >
                                    {t("totalCost")}
                                </Typography>
                            </Box>
                            <Box
                                boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                sx={{
                                    borderRadius: "20px",
                                    margin: "20px",
                                    padding: "10px",
                                }}
                            >
                                <Typography
                                    variant="h1"
                                    component="h2"
                                    color="black"
                                    textAlign="center"
                                >
                                    {totalProfit + " €"}
                                </Typography>
                                <Typography
                                    variant="h2"
                                    component="h2"
                                    color="#c8c8c8"
                                    textAlign="center"
                                    fontSize="32px"
                                    fontWeight="500"
                                >
                                    {t("totalProfit")}
                                </Typography>
                            </Box>
                        </DialogContent>
                    </Dialog>
                </form>
            )}
        </Formik>
    );
};

export default EditClientForm;

import { useState, useRef } from "react";
import {
    useGetEmployeeCategoryByIdQuery,
    useUpdateEmployeeCategoryMutation,
    useDeleteEmployeeCategoryByIdMutation,
} from "./employeeCategoriesApiSlice";
import PageHeader from "components/PageHeader";
import LoadingSpinner from "components/LoadingSpinner";
import {
    Box,
    Button,
    TextField,
    Typography,
    useTheme,
    Alert,
    useMediaQuery,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";
import { useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { SETTINGS } from "helpers/settings";

const EditEmployeeCategoryForm = () => {
    // #region Setups

    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate(); /* Set the Navigation Service */
    const location = useLocation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const nameRef = useRef(); /* User Ref to set the focus to the user input */

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMsg(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
        useState(false);

    const handleOpenDeleteConfirmationDialog = () => {
        setOpenDeleteConfirmationDialog(true);
    };

    const handleCloseDeleteConfirmationDialog = () => {
        setOpenDeleteConfirmationDialog(false);
    };

    //#endregion Setups

    // #region Employee Category

    const { id } =
        useParams(); /* Get the Employee by Id from the route Params */

    const { data: employeeCategory, isLoading: isLoadingGetEmployeeCategory } =
        useGetEmployeeCategoryByIdQuery(
            id
        ); /* Set the Get Client by Id Mutation */

    const [
        updateEmployeeCategory,
        { isLoading: isLoadingUpdateEmployeeCategory },
    ] =
        useUpdateEmployeeCategoryMutation(); /* Set the Update Client Mutation */

    const [
        deleteEmployeeCategory,
        { isLoading: isLoadingDeleteEmployeeCategory },
    ] =
        useDeleteEmployeeCategoryByIdMutation(); /* Set the Delete Cost Category Mutation */

    const employeeCategorySchema = yup.object().shape({
        name: yup.string().required(t("requiredField")),
    }); /* Set the employee category Schema */

    const initialValuesEmployeeCategory = {
        name: "",
        description: "",
    }; /* Set the Form Initial Values */

    //#endregion Employee

    const handleFormSubmit = async (values, onSubmitProps) => {
        try {
            if (values.id === SETTINGS.EmployeeCategory.OfficeEmployeeId) {
                handleSnackbarOpen(
                    t("invalidToUpdateOfficeEmployeeCategory"),
                    "error"
                );
            } else {
                const { codeResult } = await updateEmployeeCategory(
                    values
                ).unwrap();

                if (
                    codeResult !==
                    RESPONSES.employeeCategory.successToUpdateEmployeeCategory
                ) {
                    if (
                        codeResult ===
                        RESPONSES.employeeCategory
                            .invalidToUpdateEmployeeCategory
                    ) {
                        handleSnackbarOpen(
                            t("invalidToUpdateEmployeeCategory"),
                            "error"
                        );
                    } else if (
                        codeResult ===
                        RESPONSES.employeeCategory.invalidEmployeeCategory
                    ) {
                        handleSnackbarOpen(
                            t("invalidEmployeeCategory"),
                            "error"
                        );
                    } else {
                        handleSnackbarOpen(
                            t("errorUpdateEmployeeCategory"),
                            "error"
                        );
                    }
                } else {
                    handleSnackbarOpen(
                        t("successToUpdateEmployeeCategory"),
                        "success"
                    );
                }
            }
        } catch (err) {
            handleSnackbarOpen(t("errorUpdateEmployeeCategory"), "error");
        }
    };

    const handleDeleteEmployeeCategory = async () => {
        setOpenDeleteConfirmationDialog(false);

        try {
            if (parseInt(id) === SETTINGS.EmployeeCategory.OfficeEmployeeId) {
                handleSnackbarOpen(
                    t("invalidToDeleteOfficeEmployeeCategory"),
                    "error"
                );
            } else {
                const { codeResult } = await deleteEmployeeCategory(
                    id
                ).unwrap();

                if (
                    codeResult !==
                    RESPONSES.employeeCategory.successToDeleteEmployeeCategory
                ) {
                    if (
                        codeResult ===
                        RESPONSES.employeeCategory
                            .invalidToDeleteEmployeeCategory
                    ) {
                        handleSnackbarOpen(
                            t("invalidToDeleteEmployeeCategory"),
                            "error"
                        );
                    } else if (
                        codeResult ===
                        RESPONSES.employeeCategory
                            .invalidToDeleteEmployeeCategoryBecauseIsAssignedToEmployees
                    ) {
                        handleSnackbarOpen(
                            t(
                                "invalidToDeleteEmployeeCategoryBecauseIsAssignedToEmployees"
                            ),
                            "error"
                        );
                    } else {
                        handleSnackbarOpen(
                            t("errorDeleteEmployeeCategory"),
                            "error"
                        );
                    }
                } else {
                    navigate(`/${location.pathname.split("/")[1]}`);
                }
            }
        } catch (err) {
            handleSnackbarOpen(t("errorDeleteEmployeeCategory"), "error");
        }
    };
    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={employeeCategory || initialValuesEmployeeCategory}
            validationSchema={employeeCategorySchema}
            enableReinitialize={true}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    {isLoadingGetEmployeeCategory ||
                    isLoadingUpdateEmployeeCategory ||
                    isLoadingDeleteEmployeeCategory ? (
                        <LoadingSpinner />
                    ) : null}
                    <Box
                        m={!isNonMobile ? "0" : "1.5rem 2.5rem"}
                        display={!isNonMobile ? "flex" : "grid"}
                        gridTemplateRows={
                            !isNonMobile ? undefined : "repeat(4, auto)"
                        }
                        flexDirection={!isNonMobile ? "column" : undefined}
                        gap="20px"
                        borderRadius="5px"
                        p="2rem"
                    >
                        <PageHeader title={values.name} subtitle="" />
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("employeeCategoryInformation")}
                            </Typography>
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <TextField
                                    variant="outlined"
                                    label={t("name")}
                                    inputRef={nameRef}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    name="name"
                                    error={
                                        Boolean(touched.name) &&
                                        Boolean(errors.name)
                                    }
                                    helperText={touched.name && errors.name}
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("description")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.description}
                                    name="description"
                                    error={
                                        Boolean(touched.description) &&
                                        Boolean(errors.description)
                                    }
                                    helperText={
                                        touched.description &&
                                        errors.description
                                    }
                                    sx={{ width: "100%" }}
                                    multiline
                                />
                            </Box>
                        </Box>

                        {/* BUTTONS */}
                        <Box display="flex" gap="10px">
                            <LoadingButton
                                fullWidth
                                type="button"
                                onClick={handleOpenDeleteConfirmationDialog}
                                loading={isLoadingDeleteEmployeeCategory}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                            >
                                {t("deleteEmployeeCategoryButton")}
                            </LoadingButton>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                loading={isLoadingUpdateEmployeeCategory}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                            >
                                {t("editEmployeeCategory")}
                            </LoadingButton>
                        </Box>
                    </Box>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity={snackbarSeverity}
                            sx={{
                                mt: "10px",
                                backgroundColor:
                                    snackbarSeverity === "success"
                                        ? "#10781F"
                                        : "#ff000082",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {snackbarMsg}
                        </Alert>
                    </Snackbar>
                    <Dialog
                        open={openDeleteConfirmationDialog}
                        onClose={handleCloseDeleteConfirmationDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("deleteEmployeeCategoryConfirmationTitle")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t("deleteEmployeeCategoryConfirmationMessage")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                                onClick={handleCloseDeleteConfirmationDialog}
                            >
                                {t("noMessage")}
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                                onClick={handleDeleteEmployeeCategory}
                                autoFocus
                            >
                                {t("yesMessage")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            )}
        </Formik>
    );
};

export default EditEmployeeCategoryForm;

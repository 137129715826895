import { apiSlice } from "app/api/apiSlice";

export const advancePaymentsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAdvancePaymentsPaginated: builder.query({
            query: (query) => {
                const params = [];
                params.push(`limit=${(query && query.limit) || 10}`);
                params.push(`offset=${(query && query.offset) || 0}`);
                if (query && query.search)
                    params.push(
                        `search=${encodeURIComponent(query && query.search)}`
                    );
                if (query && query.filters)
                    params.push(
                        `filters=${encodeURIComponent(
                            JSON.stringify(query && query.filters)
                        )}`
                    );
                if (query && query.orderByCollection)
                    params.push(
                        `orderByCollection=${encodeURIComponent(
                            JSON.stringify(query && query.orderByCollection)
                        )}`
                    );
                const queryString =
                    params.length > 0 ? `?${params.join("&")}` : "";
                return {
                    url: `AdvancePayments/GetAllPaginated${queryString.toString()}`,
                    validateStatus: (response, result) => {
                        return response.status === 200 && !result.isError;
                    },
                    query,
                };
            },
            providesTags: [{ type: "AdvancePayments", id: "LIST" }],
        }),
        getAdvancePayments: builder.query({
            query: () => ({
                url: `AdvancePayments/GetAll`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "AdvancePayments", id: "LIST" }],
        }),
        postAdvancePayment: builder.mutation({
            query: (advancePaymentData) => ({
                url: `AdvancePayments`,
                method: "POST",
                body: {
                    ...advancePaymentData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "AdvancePayments", id: "LIST" },
                {
                    type: "EmployeeWorkHourCosts",
                    id: result.employeeWorkHourCostId,
                },
            ],
        }),
        getAdvancePaymentById: builder.query({
            query: (id) => ({
                url: `AdvancePayments/${id}`,
            }),
            providesTags: (result, error, arg) => [
                { type: "AdvancePayments", id: arg },
            ],
        }),
        updateAdvancePayment: builder.mutation({
            query: (advancePaymentData) => ({
                url: `AdvancePayments`,
                method: "PUT",
                body: {
                    ...advancePaymentData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "AdvancePayments", id: "LIST" },
                { type: "AdvancePayments", id: arg.id },
                {
                    type: "EmployeeWorkHourCosts",
                    id: result.employeeWorkHourCostId,
                },
            ],
        }),
        deleteAdvancePaymentById: builder.mutation({
            query: (id) => ({
                url: `AdvancePayments/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "AdvancePayments", id: "LIST" },
                {
                    type: "EmployeeWorkHourCosts",
                    id: result.employeeWorkHourCostId,
                },
            ],
        }),
        deleteAdvancePaymentsAfterGenerateProcessingSalaryPDF: builder.mutation(
            {
                query: () => ({
                    url: `AdvancePayments`,
                    method: "DELETE",
                }),
                invalidatesTags: [{ type: "AdvancePayments", id: "LIST" }],
            }
        ),
    }),
});

export const {
    useGetAdvancePaymentsQuery,
    useGetAdvancePaymentByIdQuery,
    usePostAdvancePaymentMutation,
    useUpdateAdvancePaymentMutation,
    useDeleteAdvancePaymentByIdMutation,
    useDeleteAdvancePaymentsAfterGenerateProcessingSalaryPDFMutation,
} = advancePaymentsApiSlice;

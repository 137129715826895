import { apiSlice } from "app/api/apiSlice";

export const workPlanningsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getEmployeePlanningsByWorkPlanningIdPaginated: builder.query({
            query: (query) => {
                const params = [];
                params.push(`limit=${(query && query.limit) || 10}`);
                params.push(`offset=${(query && query.offset) || 0}`);
                if (query && query.search)
                    params.push(
                        `search=${encodeURIComponent(query && query.search)}`
                    );
                params.push(`id=${query.id}`);
                if (query && query.filters)
                    params.push(
                        `filters=${encodeURIComponent(
                            JSON.stringify(query && query.filters)
                        )}`
                    );
                if (query && query.orderByCollection)
                    params.push(
                        `orderByCollection=${encodeURIComponent(
                            JSON.stringify(query && query.orderByCollection)
                        )}`
                    );
                const queryString =
                    params.length > 0 ? `?${params.join("&")}` : "";
                return {
                    url: `EmployeePlannings/GetAllPaginated${queryString.toString()}`,
                    validateStatus: (response, result) => {
                        return response.status === 200 && !result.isError;
                    },
                    query,
                };
            },
            providesTags: [
                { type: "EmployeePlanningsByWorkPlanningId", id: "LIST" },
            ],
        }),
        getWorkPlanningsPaginated: builder.query({
            query: (query) => {
                const params = [];
                params.push(`limit=${(query && query.limit) || 10}`);
                params.push(`offset=${(query && query.offset) || 0}`);
                if (query && query.search)
                    params.push(
                        `search=${encodeURIComponent(query && query.search)}`
                    );
                if (query && query.filters)
                    params.push(
                        `filters=${encodeURIComponent(
                            JSON.stringify(query && query.filters)
                        )}`
                    );
                if (query && query.orderByCollection)
                    params.push(
                        `orderByCollection=${encodeURIComponent(
                            JSON.stringify(query && query.orderByCollection)
                        )}`
                    );
                const queryString =
                    params.length > 0 ? `?${params.join("&")}` : "";
                return {
                    url: `WorkPlannings/GetAllPaginated${queryString.toString()}`,
                    validateStatus: (response, result) => {
                        return response.status === 200 && !result.isError;
                    },
                    query,
                };
            },
            providesTags: [{ type: "WorkPlannings", id: "LIST" }],
        }),
        getWorkPlannings: builder.query({
            query: () => ({
                url: `WorkPlannings/GetAll`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "WorkPlannings", id: "LIST" }],
        }),
        getEmployeePlanningById: builder.query({
            query: (id) => ({
                url: `EmployeePlannings/GetEmployeePlanningById/${id}`,
            }),
            providesTags: (result, error, arg) => [
                { type: "EmployeePlannings", id: arg },
            ],
        }),
        getAllCompanyWorkPlannings: builder.query({
            query: () => ({
                url: `WorkPlannings/GetAllCompanyWorkPlannings`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "CompanyWorkPlannings", id: "LIST" }],
        }),
        getAllPrivateWorkPlannings: builder.query({
            query: () => ({
                url: `WorkPlannings/GetAllPrivateWorkPlannings`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "PrivateWorkPlannings", id: "LIST" }],
        }),
        getWorkPlanningById: builder.query({
            query: (id) => ({
                url: `WorkPlannings/${id}`,
            }),
            providesTags: (result, error, arg) => [
                { type: "WorkPlannings", id: arg },
                { type: "WorkPlanningById", id: "BYID" },
            ],
        }),
        getAccomodationPlanningsByWorkPlanningId: builder.query({
            query: (id) => ({
                url: `AccomodationPlannings/GetAccomodationPlanningsByWorkPlanningId/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [
                {
                    type: "AccomodationPlanningsByWorkPlanningId",
                    id: "LIST",
                },
            ],
        }),
        getEmployeePlanningsByWorkPlanningId: builder.query({
            query: (id) => ({
                url: `EmployeePlannings/GetEmployeePlanningsByWorkPlanningId/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [
                {
                    type: "EmployeePlanningsByWorkPlanningId",
                    id: "LIST",
                },
            ],
        }),
        getVehiclePlanningsByWorkPlanningId: builder.query({
            query: (id) => ({
                url: `VehiclePlannings/GetVehiclePlanningsByWorkPlanningId/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [
                {
                    type: "VehiclePlanningsByWorkPlanningId",
                    id: "LIST",
                },
            ],
        }),
        postWorkPlanning: builder.mutation({
            query: (workPlanningData) => ({
                url: `WorkPlannings`,
                method: "POST",
                body: {
                    ...workPlanningData,
                },
            }),
            invalidatesTags: [
                { type: "WorkPlannings", id: "LIST" },
                { type: "WorksWithoutWorkPlanning", id: "LIST" },
                {
                    type: "WorksWithouWorkPlanningAndWithActualWorkInWorkPlanningById",
                    id: "LIST",
                },
            ],
        }),
        updateWorkPlanning: builder.mutation({
            query: (workPlanningData) => ({
                url: `WorkPlannings`,
                method: "PUT",
                body: {
                    ...workPlanningData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "WorkPlanningById", id: arg.id },
                { type: "WorkById", id: "BYID" },
                { type: "AccomodationById", id: "BYID" },
                { type: "VehicleById", id: "BYID" },
                { type: "EmployeeById", id: "BYID" },
                { type: "WorksWithoutWorkPlanning", id: "LIST" },
                { type: "WorksByEmployeeToProcessWorkHourCosts", id: "LIST" },
                {
                    type: "WorksWithouWorkPlanningAndWithActualWorkInWorkPlanningById",
                    id: "LIST",
                },
                { type: "WorkPlannings", id: "LIST" },
            ],
        }),
        addAccomodationPlanning: builder.mutation({
            query: (accomodationPlanningData) => ({
                url: `WorkPlannings/AddAccomodationPlanning`,
                method: "PUT",
                body: {
                    ...accomodationPlanningData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "WorkPlannings", id: result.workPlanningId },
                { type: "Accomodations", id: result.accomodationId },
                { type: "Works", id: result.workId },
                {
                    type: "AccomodationsNotInWorkPlanning",
                    id: result.workPlanningId,
                },
                {
                    type: "AccomodationsInWorkPlanning",
                    id: result.workPlanningId,
                },
            ],
        }),
        addEmployeePlanning: builder.mutation({
            query: (employeePlanningData) => ({
                url: `WorkPlannings/AddEmployeePlanning`,
                method: "PUT",
                body: {
                    ...employeePlanningData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Employees", id: result.employeeId },
                { type: "WorksByEmployeeToProcessWorkHourCosts", id: "LIST" },
                { type: "WorkPlannings", id: result.workPlanningId },
                { type: "EmployeesWithoutEmployeeCostByWork", id: "LIST" },
                { type: "Works", id: result.workId },
                { type: "EmployeePlannings", id: result.vehicleId },
                { type: "EmployeesWithoutEmployeeWorkHourCost", id: "LIST" },
                {
                    type: "EmployeesNotInWorkPlanning",
                    id: result.workPlanningId,
                },
                { type: "EmployeePlannings", id: result.vehicleId },
            ],
        }),
        addVehiclePlanning: builder.mutation({
            query: (vehiclePlanningData) => ({
                url: `WorkPlannings/AddVehiclePlanning`,
                method: "PUT",
                body: {
                    ...vehiclePlanningData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "WorkPlannings", id: result.workPlanningId },
                { type: "Vehicles", id: result.vehicleId },
                { type: "Works", id: result.workId },
                {
                    type: "VehiclesNotInWorkPlanning",
                    id: result.workPlanningId,
                },
                { type: "VehiclesInWorkPlanning", id: result.workPlanningId },
            ],
        }),
        updateAccomodationPlanning: builder.mutation({
            query: (accomodationPlanningData) => ({
                url: `AccomodationPlannings`,
                method: "PUT",
                body: {
                    ...accomodationPlanningData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Accomodations", id: result.actualAccomodationId },
                { type: "Accomodations", id: result.lastAccomodationId },
                { type: "WorkPlannings", id: result.workPlanningId },
                { type: "Works", id: result.workId },
                {
                    type: "AccomodationsNotInWorkPlanning",
                    id: result.workPlanningId,
                },
                {
                    type: "AccomodationsInWorkPlanning",
                    id: result.workPlanningId,
                },
            ],
        }),
        updateEmployeePlanning: builder.mutation({
            query: (employeePlanningData) => ({
                url: `EmployeePlannings`,
                method: "PUT",
                body: {
                    ...employeePlanningData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Employees", id: result.lastEmployeeId },
                { type: "WorksByEmployeeToProcessWorkHourCosts", id: "LIST" },
                { type: "Employees", id: result.actualEmployeeId },
                { type: "WorkPlannings", id: result.workPlanningId },
                { type: "EmployeesWithoutEmployeeCostByWork", id: "LIST" },
                { type: "Works", id: result.workId },
                { type: "EmployeePlannings", id: result.vehicleId },
                { type: "EmployeesWithoutEmployeeWorkHourCost", id: "LIST" },
                {
                    type: "EmployeesNotInWorkPlanning",
                    id: result.workPlanningId,
                },
            ],
        }),
        updateVehiclePlanning: builder.mutation({
            query: (vehiclePlanningData) => ({
                url: `VehiclePlannings`,
                method: "PUT",
                body: {
                    ...vehiclePlanningData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Vehicles", id: result.actualVehicleId },
                { type: "Vehicles", id: result.lastVehicleId },
                { type: "WorkPlannings", id: result.workPlanningId },
                { type: "Works", id: result.workId },
                {
                    type: "VehiclesNotInWorkPlanning",
                    id: result.workPlanningId,
                },
                { type: "VehiclesInWorkPlanning", id: result.workPlanningId },
            ],
        }),
        verifyIfAccomodationInOtherWorkPlanning: builder.mutation({
            query: (accomodationPlanningData) => ({
                url: `WorkPlannings/VerifyIfAccomodationInOtherWorkPlanning`,
                method: "PUT",
                body: {
                    ...accomodationPlanningData,
                },
            }),
        }),
        verifyIfVehicleInOtherWorkPlanning: builder.mutation({
            query: (vehiclePlanningData) => ({
                url: `WorkPlannings/VerifyIfVehicleInOtherWorkPlanning`,
                method: "PUT",
                body: {
                    ...vehiclePlanningData,
                },
            }),
        }),
        verifyIfEmployeeInOtherWorkPlanning: builder.mutation({
            query: (employeePlanningData) => ({
                url: `WorkPlannings/VerifyIfEmployeeInOtherWorkPlanning`,
                method: "PUT",
                body: {
                    ...employeePlanningData,
                },
            }),
        }),
        addVehicleToEmployee: builder.mutation({
            query: (vehiclePlanningData) => ({
                url: `EmployeePlannings/AddVehicleToEmployeePlanning`,
                method: "POST",
                body: {
                    ...vehiclePlanningData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                // { type: "WorkPlannings", id: arg.workPlanningId },
                {
                    type: "VehiclesInWorkPlanningAndNotInEmployeePlanning",
                    id: arg.employeePlanningId,
                },
                { type: "Vehicles", id: arg.vehicleId },
                { type: "EmployeePlannings", id: arg.employeePlanningId },
            ],
        }),
        addAccomodationToEmployee: builder.mutation({
            query: (accomodationPlanningData) => ({
                url: `EmployeePlannings/AddAccomodationToEmployeePlanning`,
                method: "POST",
                body: {
                    ...accomodationPlanningData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                // { type: "WorkPlannings", id: arg.workPlanningId },
                {
                    type: "AccomodationsInWorkPlanningAndNotInEmployeePlanning",
                    id: arg.employeePlanningId,
                },
                { type: "Accomodations", id: arg.accomodationId },
                { type: "EmployeePlannings", id: arg.employeePlanningId },
            ],
        }),
        updateVehicleFromEmployeePlanning: builder.mutation({
            query: (employeePlanning_VehicleData) => ({
                url: `EmployeePlannings/UpdateVehicleFromEmployeePlanning`,
                method: "PUT",
                body: {
                    ...employeePlanning_VehicleData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                // { type: "WorkPlannings", id: arg.workPlanningId },
                {
                    type: "VehiclesInWorkPlanningAndNotInEmployeePlanning",
                    id: arg.employeePlanningId,
                },
                { type: "Vehicles", id: arg.vehicleId },
                { type: "EmployeePlannings", id: arg.employeePlanningId },
            ],
        }),
        updateAccomodationFromEmployeePlanning: builder.mutation({
            query: (employeePlanning_AccomodationData) => ({
                url: `EmployeePlannings/UpdateAccomodationFromEmployeePlanning`,
                method: "PUT",
                body: {
                    ...employeePlanning_AccomodationData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                // { type: "WorkPlannings", id: arg.workPlanningId },
                {
                    type: "AccomodationsInWorkPlanningAndNotInEmployeePlanning",
                    id: arg.employeePlanningId,
                },
                { type: "Accomodations", id: arg.accomodationId },
                { type: "EmployeePlannings", id: arg.employeePlanningId },
            ],
        }),
        deleteVehicleFromEmployeePlanning: builder.mutation({
            query: (id) => ({
                url: `EmployeePlannings/DeleteVehicleFromEmployeePlanning/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => [
                // { type: "WorkPlannings", id: result.workPlanningId },
                {
                    type: "VehiclesInWorkPlanningAndNotInEmployeePlanning",
                    id: result.employeePlanningId,
                },
                { type: "Vehicles", id: result.actualVehicleId },
                { type: "EmployeePlannings", id: result.employeePlanningId },
            ],
        }),
        deleteAccomodationFromEmployeePlanning: builder.mutation({
            query: (id) => ({
                url: `EmployeePlannings/DeleteAccomodationFromEmployeePlanning/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => [
                // { type: "WorkPlannings", id: result.workPlanningId },
                {
                    type: "AccomodationsInWorkPlanningAndNotInEmployeePlanning",
                    id: result.employeePlanningId,
                },
                { type: "Accomodations", id: result.actualAccomodationId },
                { type: "EmployeePlannings", id: result.employeePlanningId },
            ],
        }),
        deleteAccomodationPlanning: builder.mutation({
            query: (id) => ({
                url: `WorkPlannings/DeleteAccomodationPlanning/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Accomodations", id: result.accomodationId },
                { type: "WorkPlannings", id: result.workPlanningId },
                { type: "Works", id: result.workId },
                {
                    type: "AccomodationsNotInWorkPlanning",
                    id: result.workPlanningId,
                },
                {
                    type: "AccomodationsInWorkPlanning",
                    id: result.workPlanningId,
                },
            ],
        }),
        deleteEmployeePlanning: builder.mutation({
            query: (id) => ({
                url: `WorkPlannings/DeleteEmployeePlanning/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Employees", id: result.employeeId },
                { type: "WorksByEmployeeToProcessWorkHourCosts", id: "LIST" },
                { type: "EmployeesWithoutEmployeeCostByWork", id: "LIST" },
                { type: "EmployeesWithoutEmployeeWorkHourCost", id: "LIST" },
                { type: "WorkPlannings", id: result.workPlanningId },
                { type: "Works", id: result.workId },
                { type: "EmployeePlannings", id: result.vehicleId },
                {
                    type: "EmployeesNotInWorkPlanning",
                    id: result.workPlanningId,
                },
            ],
        }),
        deleteVehiclePlanning: builder.mutation({
            query: (id) => ({
                url: `WorkPlannings/DeleteVehiclePlanning/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Vehicles", id: result.vehicleId },
                { type: "WorkPlannings", id: result.workPlanningId },
                { type: "Works", id: result.workId },
                {
                    type: "VehiclesNotInWorkPlanning",
                    id: result.workPlanningId,
                },
                { type: "VehiclesInWorkPlanning", id: result.workPlanningId },
            ],
        }),
        deleteWorkPlanning: builder.mutation({
            query: (id) => ({
                url: `WorkPlannings/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [
                { type: "WorkPlanningById", id: "BYID" },
                { type: "WorkById", id: "BYID" },
                { type: "AccomodationById", id: "BYID" },
                { type: "VehicleById", id: "BYID" },
                { type: "EmployeeById", id: "BYID" },
                { type: "WorksWithoutWorkPlanning", id: "LIST" },
                { type: "EmployeePlannings", id: "LIST" },
                { type: "WorkPlannings", id: "LIST" },
                {
                    type: "WorksWithouWorkPlanningAndWithActualWorkInWorkPlanningById",
                    id: "LIST",
                },
            ],
        }),
    }),
});

export const {
    useGetWorkPlanningsQuery,
    useGetAllCompanyWorkPlanningsQuery,
    useGetAllPrivateWorkPlanningsQuery,
    useGetWorkPlanningByIdQuery,
    useGetAccomodationPlanningsByWorkPlanningIdQuery,
    useGetEmployeePlanningsByWorkPlanningIdQuery,
    useGetVehiclePlanningsByWorkPlanningIdQuery,
    usePostWorkPlanningMutation,
    useAddAccomodationPlanningMutation,
    useAddEmployeePlanningMutation,
    useAddVehicleToEmployeeMutation,
    useAddAccomodationToEmployeeMutation,
    useAddVehiclePlanningMutation,
    useUpdateAccomodationPlanningMutation,
    useDeleteAccomodationPlanningMutation,
    useDeleteEmployeePlanningMutation,
    useDeleteVehiclePlanningMutation,
    useUpdateEmployeePlanningMutation,
    useUpdateVehiclePlanningMutation,
    useUpdateWorkPlanningMutation,
    useUpdateVehicleFromEmployeePlanningMutation,
    useUpdateAccomodationFromEmployeePlanningMutation,
    useDeleteVehicleFromEmployeePlanningMutation,
    useDeleteAccomodationFromEmployeePlanningMutation,
    useVerifyIfAccomodationInOtherWorkPlanningMutation,
    useVerifyIfEmployeeInOtherWorkPlanningMutation,
    useVerifyIfVehicleInOtherWorkPlanningMutation,
    useDeleteWorkPlanningMutation,
    useLazyGetEmployeePlanningByIdQuery,
} = workPlanningsApiSlice;

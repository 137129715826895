import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCredentials } from "./authSlice";
import { useLoginMutation, useVerifyLoginCodeMutation } from "./authApiSlice";
import {
    Box,
    TextField,
    useMediaQuery,
    Typography,
    useTheme,
    Alert,
    Button,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import PageHeader from "components/PageHeader";
import LoadingButton from "@mui/lab/LoadingButton";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";
import usePersist from "hooks/usePersist";
import Dialog from "@mui/material/Dialog";
import Snackbar from "@mui/material/Snackbar";

const LoginForm = ({ toggleForm }) => {
    const theme = useTheme();
    const { palette } = useTheme(); /* Set the Theme Pallet */
    const { t, i18n } = useTranslation(); /* Set the Translation Service */
    const navigate = useNavigate(); /* Set the Navigation Service */
    const dispatch = useDispatch(); /* Set the Dispatch Service */
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const [persist, setPersist, language, setLanguage] = usePersist();

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMsg(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const [openVerifyLoginCodeDialog, setOpenVerifyLoginCodeDialog] =
        useState(false);

    const handleOpenVerifyLoginCodeDialog = () => {
        setOpenVerifyLoginCodeDialog(true);
    };

    const handleCloseVerifyLoginCodeDialog = () => {
        setOpenVerifyLoginCodeDialog(false);
    };

    const verifyLoginCodeSchema = yup.object().shape({
        loginCode: yup.string().required(t("requiredField")),
    });

    const initialValuesVerifyLoginCode = {
        loginCode: "",
    };

    const loginSchema = yup.object().shape({
        username: yup.string().required(t("requiredField")),
        password: yup.string().required(t("requiredField")),
    }); /* Set the Login Schema */

    const initialValuesLogin = {
        username: "",
        password: "",
    }; /* Set the Form Initial Values */

    const userRef = useRef(); /* User Ref to set the focus to the user input */
    const passwordRef =
        useRef(); /* User Ref to set the focus to the user input */

    const [errMsg, setErrMsg] = useState(""); /* Set the Error Message State */

    const [login, { isLoading }] =
        useLoginMutation(); /* Set the Login Mutation */

    const [verifyLoginCode, { isLoading: isLoadingVerifyLoginCode }] =
        useVerifyLoginCodeMutation(); /* Set the Login Mutation */

    const handleTogglePersist = () => {
        setPersist((prev) => !prev);
    };

    const [userId, setUserId] = useState(0);

    const handleFormSubmit = async (values, onSubmitProps) => {
        setErrMsg("");

        let loginRequest = {
            username: values.username,
            password: values.password,
            language: language,
        };

        try {
            const { user, codeResult } = await login(loginRequest).unwrap();

            if (codeResult !== RESPONSES.login.success) {
                if (codeResult === RESPONSES.login.invalidCredentials) {
                    setErrMsg(t("invalidCredentials"));
                    userRef.current.focus();
                } else if (codeResult === RESPONSES.login.invalidPassword) {
                    setErrMsg(t("invalidPassword"));
                    passwordRef.current.focus();
                } else {
                    setErrMsg(t("anErrorOccurred"));
                }
            } else {
                onSubmitProps.resetForm();
                setUserId(user.id);
                setOpenVerifyLoginCodeDialog(true);
            }
        } catch (err) {
            if (!err.status) {
                setErrMsg("Network error");
            } else if (err.status === 400) {
                setErrMsg("Invalid credentials");
            } else if (err.status === 401) {
                setErrMsg("Unauthorized");
            } else {
                setErrMsg(err.data?.message || "Unknown error");
            }
        }
    };

    const handleFormVerifyLoginCodeSubmit = async (values, onSubmitProps) => {
        try {
            let verifyLoginCodeSend = {
                userId: userId,
                loginCode: values.loginCode,
            };

            const { user, token, codeResult } = await verifyLoginCode(
                verifyLoginCodeSend
            ).unwrap();

            if (codeResult !== RESPONSES.login.success) {
                if (codeResult === RESPONSES.login.invalidLoginCode) {
                    handleSnackbarOpen(t("invalidLoginCode"), "error");
                    userRef.current.focus();
                } else {
                    handleSnackbarOpen(t("errorLogin"), "error");
                }

                return;
            } else {
                dispatch(setCredentials({ user, token }));
                onSubmitProps.resetForm();
                if (user.userTypeId === 1 && isNonMobile) {
                    navigate("/" + t("dashboard"));
                } else if (user.userTypeId === 1 && !isNonMobile) {
                    navigate("/" + t("users"));
                } else {
                    navigate("/" + t("clients"));
                }
            }
        } catch (err) {
            handleSnackbarOpen(t("errorLogin"), "error");
        }
    };

    useEffect(() => {
        userRef.current.focus(); /* Set the Focus to the User Input */
    }, []);

    const changeLanguage = (language) => {
        if (language) {
            i18n.changeLanguage(language);
            setLanguage(language);
        }
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesLogin}
            validationSchema={loginSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        display="flex"
                        gap="30px"
                        flexDirection="column"
                        sx={{
                            "& > div": {
                                gridColumn: isNonMobile ? undefined : "span 4",
                            },
                        }}
                    >
                        <TextField
                            variant="outlined"
                            label={t("username")}
                            inputRef={userRef}
                            onChange={handleChange}
                            value={values.username}
                            name="username"
                            error={
                                Boolean(touched.username) &&
                                Boolean(errors.username)
                            }
                            helperText={touched.username && errors.username}
                            sx={{ gridColumn: "span 4" }}
                        />
                        <TextField
                            label={t("password")}
                            type="password"
                            inputRef={passwordRef}
                            onChange={handleChange}
                            value={values.password}
                            name="password"
                            error={
                                Boolean(touched.password) &&
                                Boolean(errors.password)
                            }
                            helperText={touched.password && errors.password}
                            sx={{ gridColumn: "span 4" }}
                        />
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        flexDirection={isNonMobile ? "row" : "column"}
                    >
                        <FormControlLabel
                            sx={{ width: "100%", mt: "1rem" }}
                            control={
                                <Checkbox
                                    checked={persist}
                                    onChange={handleTogglePersist}
                                />
                            }
                            label={t("trustInDevice")}
                        />
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            marginTop="15px"
                        >
                            <Button
                                onClick={() => changeLanguage("pt")}
                                sx={{
                                    textDecoration: "underline",
                                    ":hover": {
                                        backgroundColor: "#aaaaaa40",
                                        cursor: "pointer",
                                    },
                                    ":active, :focus": {
                                        backgroundColor: "#aaaaaa40",
                                    },
                                }}
                            >
                                PT
                            </Button>
                            <Button
                                onClick={() => changeLanguage("en")}
                                sx={{
                                    textDecoration: "underline",
                                    ":hover": {
                                        backgroundColor: "#aaaaaa40",
                                        cursor: "pointer",
                                    },
                                    ":active, :focus": {
                                        backgroundColor: "#aaaaaa40",
                                    },
                                }}
                            >
                                EN
                            </Button>
                            <Button
                                onClick={() => changeLanguage("es")}
                                sx={{
                                    textDecoration: "underline",
                                    ":hover": {
                                        backgroundColor: "#aaaaaa40",
                                        cursor: "pointer",
                                    },
                                    ":active, :focus": {
                                        backgroundColor: "#aaaaaa40",
                                    },
                                }}
                            >
                                ES
                            </Button>
                            <Button
                                onClick={() => changeLanguage("fr")}
                                sx={{
                                    textDecoration: "underline",
                                    ":hover": {
                                        backgroundColor: "#aaaaaa40",
                                        cursor: "pointer",
                                    },
                                    ":active, :focus": {
                                        backgroundColor: "#aaaaaa40",
                                    },
                                }}
                            >
                                FR
                            </Button>
                        </Box>
                    </Box>
                    {errMsg && (
                        <Alert
                            severity="error"
                            sx={{
                                mt: "20px",
                                backgroundColor: "#540e0e",
                                color: "#FFFFFF",
                                alignItems: "center",
                            }}
                        >
                            {errMsg}
                        </Alert>
                    )}
                    {/* BUTTONS */}
                    <Box>
                        <LoadingButton
                            fullWidth
                            type="submit"
                            loading={isLoading}
                            sx={{
                                m: "2rem 0",
                                p: "1rem",
                                backgroundColor: palette.primary.main,
                                color: palette.neutral.white,
                                "&:hover": { color: palette.primary.main },
                            }}
                        >
                            {t("loginButton")}
                        </LoadingButton>
                        <Typography
                            onClick={() => {
                                toggleForm();
                                resetForm();
                            }}
                            sx={{
                                textDecoration: "underline",
                                color: palette.primary.main,
                                "&:hover": {
                                    cursor: "pointer",
                                    color: palette.primary.light,
                                },
                            }}
                        >
                            {t("recoverPassword")}
                        </Typography>
                    </Box>
                    <Dialog
                        open={openVerifyLoginCodeDialog}
                        onClose={handleCloseVerifyLoginCodeDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <Formik
                            onSubmit={handleFormVerifyLoginCodeSubmit}
                            initialValues={initialValuesVerifyLoginCode}
                            validationSchema={verifyLoginCodeSchema}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                resetForm,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box
                                        display="grid"
                                        gridTemplateRows="repeat(2, auto)"
                                        gap="20px"
                                        textAlign="center"
                                        backgroundColor={
                                            theme.palette.background.D
                                        }
                                        borderRadius="5px"
                                        p="2rem"
                                    >
                                        <PageHeader
                                            title={t("putVerificationCode")}
                                            subtitle=""
                                        />
                                        <Box
                                            display="flex"
                                            gap="20px"
                                            flexDirection="column"
                                            width="100%"
                                            alignItems="center"
                                        >
                                            <Box
                                                display="grid"
                                                gap="10px"
                                                width="70%"
                                            >
                                                <TextField
                                                    variant="outlined"
                                                    label={t(
                                                        "verificationCode"
                                                    )}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.loginCode}
                                                    name="loginCode"
                                                    error={
                                                        Boolean(
                                                            touched.loginCode
                                                        ) &&
                                                        Boolean(
                                                            errors.loginCode
                                                        )
                                                    }
                                                    helperText={
                                                        touched.loginCode &&
                                                        errors.loginCode
                                                    }
                                                    type="password"
                                                    sx={{ width: "100%" }}
                                                />
                                            </Box>
                                        </Box>
                                        {/* BUTTONS */}
                                        <LoadingButton
                                            fullWidth
                                            type="submit"
                                            loading={isLoadingVerifyLoginCode}
                                            sx={{
                                                width: "100%",
                                                p: "1rem",
                                                backgroundColor:
                                                    theme.palette.primary.main,
                                                color: theme.palette.neutral
                                                    .white,
                                                "&:hover": {
                                                    backgroundColor:
                                                        theme.palette.primary
                                                            .light,
                                                },
                                            }}
                                        >
                                            {t("sendButton")}
                                        </LoadingButton>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Dialog>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity={snackbarSeverity}
                            sx={{
                                mt: "10px",
                                backgroundColor:
                                    snackbarSeverity === "success"
                                        ? "#10781F"
                                        : "#ff000082",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {snackbarMsg}
                        </Alert>
                    </Snackbar>
                </form>
            )}
        </Formik>
    );
};

export default LoginForm;

import { useState, useRef, useEffect } from "react";
import PageHeader from "components/PageHeader";
import { useGetAllWorksWithouWorkPlanningAndWithActualWorkInWorkPlanningByIdQuery } from "features/works/worksApiSlice";
import {
    useGetAccomodationsNotInWorkPlanningQuery,
    useLazyGetAccomodationsInWorkPlanningAndNotInEmployeePlanningQuery,
} from "features/accomodations/accomodationsApiSlice";
import {
    useGetvehiclesNotInWorkPlanningQuery,
    useLazyGetVehiclesInWorkPlanningAndNotInEmployeePlanningQuery,
} from "features/vehicles/vehiclesApiSlice";
import { useGetEmployeesNotInWorkPlanningQuery } from "features/employees/employeesApiSlice";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import {
    useGetWorkPlanningByIdQuery,
    useAddAccomodationPlanningMutation,
    useAddEmployeePlanningMutation,
    useAddVehiclePlanningMutation,
    useUpdateAccomodationPlanningMutation,
    useUpdateEmployeePlanningMutation,
    useDeleteAccomodationPlanningMutation,
    useDeleteEmployeePlanningMutation,
    useDeleteVehiclePlanningMutation,
    useUpdateVehiclePlanningMutation,
    useUpdateWorkPlanningMutation,
    useDeleteWorkPlanningMutation,
    useVerifyIfAccomodationInOtherWorkPlanningMutation,
    useVerifyIfEmployeeInOtherWorkPlanningMutation,
    useVerifyIfVehicleInOtherWorkPlanningMutation,
    useAddVehicleToEmployeeMutation,
    useAddAccomodationToEmployeeMutation,
    useUpdateVehicleFromEmployeePlanningMutation,
    useUpdateAccomodationFromEmployeePlanningMutation,
    useDeleteVehicleFromEmployeePlanningMutation,
    useDeleteAccomodationFromEmployeePlanningMutation,
    useLazyGetEmployeePlanningByIdQuery,
    useGetEmployeePlanningsByWorkPlanningIdQuery,
    useGetAccomodationPlanningsByWorkPlanningIdQuery,
    useGetVehiclePlanningsByWorkPlanningIdQuery,
} from "./workPlanningsApiSlice";
import {
    GridToolbarDensitySelector,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import FlexBetween from "components/FlexBetween";
import LoadingSpinner from "components/LoadingSpinner";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
    Box,
    TextField,
    Typography,
    Button,
    useTheme,
    Alert,
    useMediaQuery,
    FormControlLabel,
    Checkbox,
    FormControl,
    Autocomplete,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid } from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";
import { format } from "date-fns";
import useAuth from "hooks/useAuth";
import { useGetWorkResumeByWorkPlanningIdQuery } from "features/home/homePageApiSlice";
import { SETTINGS } from "helpers/settings";

const EditWorkPlanningForm = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { palette } = useTheme(); /* Set the Theme Pallet */
    const navigate = useNavigate(); /* Set the Navigation Service */
    const location = useLocation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const { userType } = useAuth();

    const [employeeCost, setEmployeeCost] = useState("");
    const [employeeSalaryType, setEmployeeSalaryType] = useState(1);

    const [accomodationPlannings, setAccomodationPlannings] = useState([]);
    const [accomodationPlanning, setAccomodationPlanning] = useState();

    const [vehiclePlanningToEdit, setVehiclePlanningToEdit] = useState([]);
    const [vehiclePlannings, setVehiclePlannings] = useState([]);

    const [employeePlannings, setEmployeePlannings] = useState([]);
    const [employeePlanningToEdit, setEmployeePlanningToEdit] = useState();

    const [vehiclesFromEmployee, setVehiclesFromEmployee] = useState([]);
    const [accomodationsFromEmployee, setAccomodationsFromEmployee] = useState(
        []
    );

    const [employeePlanningIdUpdated, setEmployeePlanningIdUpdated] =
        useState(0);

    const nameRef = useRef(); /* User Ref to set the focus to the user input */

    const { id } = useParams(); /* Get the Client Id from the route Params */

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const [errMsg, setErrMsg] = useState(""); /* Set the Error Message State */
    const [errMsgEmployeeAssociations, setErrMsgEmployeeAssociations] =
        useState(""); /* Set the Error Message State */

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMsg(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const [totalHours, setTotalHours] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [totalProfit, setTotalProfit] = useState(0);

    // se o funcionário é condutor
    const [isDriver, setIsDriver] = useState(false);

    const setIsDriverState = () => {
        if (isDriver) {
            setIsDriver(false);
        } else {
            setIsDriver(true);
        }
    };

    const { data: workPlanning, isLoading: isLoadingGetWorkPlanning } =
        useGetWorkPlanningByIdQuery(
            id
        ); /* Set the Get Work Planning by Id Mutation */

    /* Dialog to Create Accomodation Planning */
    const [
        openCreateAccomodationPlanningDialog,
        setOpenCreateAccomodationPlanningDialog,
    ] = useState(false);

    const handleOpenCreateAccomodationPlanningDialog = () => {
        setOpenCreateAccomodationPlanningDialog(true);
    };

    const handleCloseCreateAccomodationPlanningDialog = () => {
        setErrMsg("");
        setOpenCreateAccomodationPlanningDialog(false);
    };

    /* Dialog to Edit Accomodation Planning */
    const [
        openEditAccomodationPlanningDialog,
        setOpenEditAccomodationPlanningDialog,
    ] = useState(false);

    const handleOpenEditAccomodationPlanningDialog = () => {
        setOpenEditAccomodationPlanningDialog(true);
    };

    const handleCloseEditAccomodationPlanningDialog = () => {
        setErrMsg("");
        setOpenEditAccomodationPlanningDialog(false);
    };

    /* Dialog to Employee Planning */
    const [
        openCreateEmployeePlanningDialog,
        setOpenCreateEmployeePlanningDialog,
    ] = useState(false);

    const handleOpenCreateEmployeePlanningDialog = () => {
        setOpenCreateEmployeePlanningDialog(true);
    };

    const handleCloseCreateEmployeePlanningDialog = () => {
        setErrMsg("");
        setOpenCreateEmployeePlanningDialog(false);
    };

    /* Dialog to Edit Employee Planning */
    const [openEditEmployeePlanningDialog, setOpenEditEmployeePlanningDialog] =
        useState(false);

    const handleOpenEditEmployeePlanningDialog = () => {
        setOpenEditEmployeePlanningDialog(true);
    };

    const handleCloseEditEmployeePlanningDialog = () => {
        setErrMsg("");
        setOpenEditEmployeePlanningDialog(false);
    };

    /* Dialog to Add Vehicle to Employee */
    const [openAddVehicleToEmployeeDialog, setOpenAddVehicleToEmployeeDialog] =
        useState(false);

    const handleOpenAddVehicleToEmployeeDialog = () => {
        setIsDriver(false);
        setOpenAddVehicleToEmployeeDialog(true);
    };

    const handleCloseAddVehicleToEmployeeDialog = () => {
        setErrMsgEmployeeAssociations("");
        setOpenAddVehicleToEmployeeDialog(false);
    };

    /* Dialog to Edit Vehicle from Employee */
    const [
        openEditEmployeePlanning_VehicleDialog,
        setOpenEditEmployeePlanning_VehicleDialog,
    ] = useState(false);

    const handleOpenEditEmployeePlanning_VehicleDialog = () => {
        setOpenEditEmployeePlanning_VehicleDialog(true);
    };

    const handleCloseEditEmployeePlanning_VehicleDialog = () => {
        setErrMsgEmployeeAssociations("");
        setOpenEditEmployeePlanning_VehicleDialog(false);
    };

    /* Dialog to See Work Planning Resume*/
    const [openWorkPlanningResumeDialog, setOpenWorkPlanningResumeDialog] =
        useState(false);

    const handleOpenWorkPlanningResumeDialog = () => {
        setOpenWorkPlanningResumeDialog(true);
    };

    const handleCloseWorkPlanningResumeDialog = () => {
        setOpenWorkPlanningResumeDialog(false);
    };

    /* Dialog to Add Accomodation to Employee */
    const [
        openAddAccomodationToEmployeeDialog,
        setOpenAddAccomodationToEmployeeDialog,
    ] = useState(false);

    const handleOpenAddAccomodationToEmployeeDialog = () => {
        setOpenAddAccomodationToEmployeeDialog(true);
    };

    const handleCloseAddAccomodationToEmployeeDialog = () => {
        setErrMsgEmployeeAssociations("");
        setOpenAddAccomodationToEmployeeDialog(false);
    };

    /* Dialog to Edit Vehicle from Employee */
    const [
        openEditEmployeePlanning_AccomodationDialog,
        setOpenEditEmployeePlanning_AccomodationDialog,
    ] = useState(false);

    const handleOpenEditEmployeePlanning_AccomodationDialog = () => {
        setOpenEditEmployeePlanning_AccomodationDialog(true);
    };

    const handleCloseEditEmployeePlanning_AccomodationDialog = () => {
        setErrMsgEmployeeAssociations("");
        setOpenEditEmployeePlanning_AccomodationDialog(false);
    };

    /* Dialog to Vehicle Planning */
    const [
        openCreateVehiclePlanningDialog,
        setOpenCreateVehiclePlanningDialog,
    ] = useState(false);

    const handleOpenCreateVehiclePlanningDialog = () => {
        setOpenCreateVehiclePlanningDialog(true);
    };

    const handleCloseCreateVehiclePlanningDialog = () => {
        setErrMsg("");
        setOpenCreateVehiclePlanningDialog(false);
    };

    /* Dialog to Edit Vehicle Planning */
    const [openEditVehiclePlanningDialog, setOpenEditVehiclePlanningDialog] =
        useState(false);

    const handleOpenEditVehiclePlanningDialog = () => {
        setOpenEditVehiclePlanningDialog(true);
    };

    const handleCloseEditVehiclePlanningDialog = () => {
        setErrMsg("");
        setOpenEditVehiclePlanningDialog(false);
    };

    /* Delete Employee Planning_Vehicle */
    const [
        openDeleteEmployeePlanning_VehicleDialog,
        setOpenDeleteEmployeePlanning_VehicleDialog,
    ] = useState(false);

    const handleOpenDeleteEmployeePlanning_VehicleDialog = () => {
        setOpenDeleteEmployeePlanning_VehicleDialog(true);
    };

    const handleCloseDeleteEmployeePlanning_VehicleDialog = () => {
        setOpenDeleteEmployeePlanning_VehicleDialog(false);
    };

    /* Delete Employee Planning_Accomodadion */
    const [
        openDeleteEmployeePlanning_AccomodationDialog,
        setOpenDeleteEmployeePlanning_AccomodationDialog,
    ] = useState(false);

    const handleOpenDeleteEmployeePlanning_AccomodationDialog = () => {
        setOpenDeleteEmployeePlanning_AccomodationDialog(true);
    };

    const handleCloseDeleteEmployeePlanning_AccomodationDialog = () => {
        setOpenDeleteEmployeePlanning_AccomodationDialog(false);
    };

    /* DIALOG PARA ALERTAS DE ALOJAMENTOS EM OUTRAS OBRAS*/
    const [openAlertAccomodationDialog, setOpenAlertAccomodationDialog] =
        useState(false);

    const handleOpenAlertAccomodationDialog = () => {
        setOpenAlertAccomodationDialog(true);
    };

    const handleCloseAlertAccomodationDialog = () => {
        setOpenAlertAccomodationDialog(false);
    };

    const [
        openAlertEditAccomodationDialog,
        setOpenAlertEditAccomodationDialog,
    ] = useState(false);

    const handleOpenAlertEditAccomodationDialog = () => {
        setOpenAlertEditAccomodationDialog(true);
    };

    const handleCloseAlertEditAccomodationDialog = () => {
        setOpenAlertEditAccomodationDialog(false);
    };

    /* DIALOG PARA ALERTAS DE FUNCIONÁROS EM OUTRAS OBRAS */
    const [openAlertEmployeeDialog, setOpenAlertEmployeeDialog] =
        useState(false);

    const handleOpenAlertEmployeeDialog = () => {
        setOpenAlertEmployeeDialog(true);
    };

    const handleCloseAlertEmployeeDialog = () => {
        setOpenAlertEmployeeDialog(false);
    };

    const [openAlertEditEmployeeDialog, setOpenAlertEditEmployeeDialog] =
        useState(false);

    const handleOpenAlertEditEmployeeDialog = () => {
        setOpenAlertEditEmployeeDialog(true);
    };

    const handleCloseAlertEditEmployeeDialog = () => {
        setOpenAlertEditEmployeeDialog(false);
    };

    /* DIALOG PARA ALERTAS DE VEICULOS EM OUTRAS OBRAS */
    const [openAlertVehicleDialog, setOpenAlertVehicleDialog] = useState(false);

    const handleOpenAlertVehicleDialog = () => {
        setIsDriver(false);
        setOpenAlertVehicleDialog(true);
    };

    const handleCloseAlertVehicleDialog = () => {
        setOpenAlertVehicleDialog(false);
    };

    const [openAlertEditVehicleDialog, setOpenAlertEditVehicleDialog] =
        useState(false);

    const handleOpenAlertEditVehicleDialog = () => {
        setOpenAlertEditVehicleDialog(true);
    };

    const handleCloseAlertEditVehicleDialog = () => {
        setOpenAlertEditVehicleDialog(false);
    };

    const { data: workResume } =
        useGetWorkResumeByWorkPlanningIdQuery(
            id
        ); /* Set the Get Work Planning by Id Mutation */

    useEffect(() => {
        if (workResume) {
            setTotalHours(workResume.hours.toFixed(2));
            setTotalCost(workResume.cost.toFixed(2));
            setTotalProfit(workResume.profit.toFixed(2));
        }
    }, [workResume]);

    const [
        getVehiclesInWorkPlanningAndNotInEmployeePlanning,
        vehiclesInWorkPlanningAndNotInEmployeePlanning,
    ] = useLazyGetVehiclesInWorkPlanningAndNotInEmployeePlanningQuery();

    const [
        getAccomodationsInWorkPlanningAndNotInEmployeePlanning,
        accomodationsInWorkPlanningAndNotInEmployeePlanning,
    ] = useLazyGetAccomodationsInWorkPlanningAndNotInEmployeePlanningQuery();

    const {
        data: employeePlanningsByWorkPlanningId,
        isLoading: isLoadingEmployeePlanningsByWorkPlanningId,
        refetch: refetchEmployeePlannings,
    } = useGetEmployeePlanningsByWorkPlanningIdQuery(id);

    const {
        data: accomodationPlanningsByWorkPlanningId,
        isLoading: isLoadingAccomodationPlanningsByWorkPlanningId,
        refetch: refetchAccomodationPlannings,
    } = useGetAccomodationPlanningsByWorkPlanningIdQuery(id);

    const {
        data: vehiclePlanningsByWorkPlanningId,
        isLoading: isLoadingVehiclePlanningsByWorkPlanningId,
        refetch: refetchVehiclePlannings,
    } = useGetVehiclePlanningsByWorkPlanningIdQuery(id);

    useEffect(() => {
        if (accomodationPlanningsByWorkPlanningId) {
            setAccomodationPlannings(accomodationPlanningsByWorkPlanningId);
        }
    }, [accomodationPlanningsByWorkPlanningId]);

    useEffect(() => {
        if (vehiclePlanningsByWorkPlanningId) {
            setVehiclePlannings(vehiclePlanningsByWorkPlanningId);
        }
    }, [vehiclePlanningsByWorkPlanningId]);

    useEffect(() => {
        if (employeePlanningsByWorkPlanningId) {
            setEmployeePlannings(employeePlanningsByWorkPlanningId);
        }
    }, [employeePlanningsByWorkPlanningId]);

    /** #region ACCOMODATION PLANNINGS */
    /* Accomodation Planning Columns */

    const accomodationPlanningColumns = [
        {
            field: "address",
            headerName: t("address"),
            flex: 1,
            valueGetter: (params) => params.row.accomodation.address,
        },
        {
            field: "startDate",
            headerName: t("startDate"),
            flex: 1,
            valueGetter: (params) => {
                const startDate = params.row.startDate;
                if (startDate !== null) {
                    return format(new Date(startDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "endDate",
            headerName: t("endDate"),
            flex: 1,
            valueGetter: (params) => {
                const endDate = params.row.endDate;
                if (endDate !== null) {
                    return format(new Date(endDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "action",
            headerName: t("edit"),
            flex: 1,
            renderCell: (row) => (
                <EditIcon onClick={() => handleEditAccomodationPlanning(row)} />
            ),
        },
        {
            field: "actions",
            headerName: t("remove"),
            flex: 1,
            renderCell: (row) => (
                <DeleteIcon
                    onClick={() =>
                        handleDeleteAccomodationPlanningOpenDialog(row.id)
                    }
                />
            ),
        },
    ];

    const accomodationPlanningColumnsMobile = [
        {
            field: "address",
            headerName: t("address"),
            flex: 1,
            valueGetter: (params) => params.row.accomodation.address,
        },
        {
            field: "action",
            headerName: t("edit"),
            flex: 1,
            renderCell: (row) => (
                <EditIcon onClick={() => handleEditAccomodationPlanning(row)} />
            ),
        },
        {
            field: "actions",
            headerName: t("remove"),
            flex: 1,
            renderCell: (row) => (
                <DeleteIcon
                    onClick={() =>
                        handleDeleteAccomodationPlanningOpenDialog(row.id)
                    }
                />
            ),
        },
    ];

    /** Accomodations from employee columns */
    const accomodationsFromEmployeeColumns = [
        {
            field: "address",
            headerName: t("address"),
            flex: 1,
            valueGetter: (params) => params.row.accomodation.address,
        },
        {
            field: "startDate",
            headerName: t("startDate"),
            flex: 1,
            valueGetter: (params) => {
                const startDate = params.row.startDate;
                if (startDate !== null) {
                    return format(new Date(startDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "endDate",
            headerName: t("endDate"),
            flex: 1,
            valueGetter: (params) => {
                const endDate = params.row.endDate;
                if (endDate !== null) {
                    return format(new Date(endDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "action",
            headerName: t("edit"),
            flex: 1,
            renderCell: (row) => (
                <EditIcon
                    onClick={() => handleEditEmployeePlaninng_Accomodation(row)}
                />
            ),
        },
        {
            field: "actions",
            headerName: t("remove"),
            flex: 1,
            renderCell: (row) => (
                <DeleteIcon
                    onClick={() =>
                        handleDeleteEmployeePlanning_AccomodationOpenDialog(
                            row.id
                        )
                    }
                />
            ),
        },
    ];

    const accomodationsFromEmployeeColumnsMobile = [
        {
            field: "address",
            headerName: t("address"),
            flex: 1,
            valueGetter: (params) => params.row.accomodation.address,
        },
        {
            field: "action",
            headerName: t("edit"),
            flex: 1,
            renderCell: (row) => (
                <EditIcon
                    onClick={() => handleEditEmployeePlaninng_Accomodation(row)}
                />
            ),
        },
        {
            field: "actions",
            headerName: t("remove"),
            flex: 1,
            renderCell: (row) => (
                <DeleteIcon
                    onClick={() =>
                        handleDeleteEmployeePlanning_AccomodationOpenDialog(
                            row.id
                        )
                    }
                />
            ),
        },
    ];

    /* Accomodation Planning Schema */

    const accomodationPlanningSchema = yup.object().shape({
        accomodationId: yup.string().required(t("requiredField")),
        startDate: yup.date().notRequired(),
        endDate: yup.date().notRequired(),
    }); /* Set the Accomodation Planning forms Schema */

    const initialValuesAccomodationPlanning = {
        startDate: null,
        endDate: null,
    }; /* Set the Forms Initial Values */

    /* Get  Alojamentos */
    const {
        data: accomodationsNotInWorkPlanning,
        isLoading: isLoadingAccomodations,
    } =
        useGetAccomodationsNotInWorkPlanningQuery(
            id
        ); /* Set the Get Work Planning by Id Mutation */

    /* Add Accomodation Planning */
    const [
        addAccomodationPlanning,
        { isLoading: isLoadingAddAccomodationPlanning },
    ] = useAddAccomodationPlanningMutation();

    const [
        verifyIfAccomodationInOtherWorkPlanningMutation,
        { isLoading: isLoadingVerifyIfAccomodationInOtherWorkPlanningMutation },
    ] = useVerifyIfAccomodationInOtherWorkPlanningMutation();

    const [accomodationPlanningToAdd, setAccomodationPlanningToAdd] =
        useState();

    const handleFormAddAccomodationPlanningSubmit = async (
        values,
        onSubmitProps
    ) => {
        try {
            if (!values.startDate || values.startDate === "") {
                setErrMsg(t("requiredStartDate"));
            } else {
                setErrMsg("");
                if (
                    values.startDate !== null &&
                    values.endDate !== null &&
                    dayjs(values.endDate).isBefore(dayjs(values.startDate))
                ) {
                    handleSnackbarOpen(
                        t("errorBecauseEndDateBeforeThanStartDate"),
                        "error"
                    );
                } else {
                    let workPlanningDTO = {
                        WorkPlanningId: id,
                        AccomodationId: values.accomodationId,
                        StartDate: values.startDate,
                        EndDate: values.endDate,
                    };
                    setAccomodationPlanningToAdd(workPlanningDTO);
                    const { codeResult } =
                        await verifyIfAccomodationInOtherWorkPlanningMutation(
                            workPlanningDTO
                        ).unwrap();
                    if (
                        codeResult ===
                        RESPONSES.workPlanning
                            .haveAccomodationVehiclesOrEmployeesInOtherWorkPlanning
                    ) {
                        const { codeResult } = await addAccomodationPlanning(
                            workPlanningDTO
                        ).unwrap();
                        if (
                            codeResult !==
                            RESPONSES.accommodation.successToInsertAccomodation
                        ) {
                            if (
                                codeResult ===
                                RESPONSES.accommodation.invalidAccomodation
                            ) {
                                handleSnackbarOpen(
                                    t("invalidAccomodation"),
                                    "error"
                                );
                            } else if (
                                codeResult ===
                                RESPONSES.workPlanning.invalidWorkPlanning
                            ) {
                                handleSnackbarOpen(
                                    t("invalidWorkPlanning"),
                                    "error"
                                );
                            } else {
                                handleSnackbarOpen(
                                    t("errorToAddAccomodation"),
                                    "error"
                                );
                            }
                        } else {
                            setOpenCreateAccomodationPlanningDialog(false);
                            handleSnackbarOpen(
                                t("accomodationAddedWithSucess"),
                                "success"
                            );
                            refetchAccomodationPlannings();
                        }
                    } else {
                        if (
                            codeResult ===
                            RESPONSES.accommodation.invalidAccomodation
                        ) {
                            handleSnackbarOpen(
                                t("invalidAccomodation"),
                                "error"
                            );
                        } else if (
                            codeResult ===
                            RESPONSES.workPlanning
                                .accomodationInOtherWorkPlannings
                        ) {
                            setOpenAlertAccomodationDialog(true);
                        } else {
                            handleSnackbarOpen(
                                t("errorToAddAccomodation"),
                                "error"
                            );
                        }
                    }
                }
            }
        } catch (err) {
            handleSnackbarOpen(t("errorToAddAccomodation"), "error");
        }
    };

    /* DEPOIS DE CONFIRMAR NO DIALOG DE ALOJAMENTOS SE PRETENDE NA MESMA ADICIONAR O ALOJAMENTO POIS ELE JA EXISTE NOUTRO PLANEAMENTO */
    const handleFormAddAccomodationPlanningAfterDialogVerifySubmit = async (
        values,
        onSubmitProps
    ) => {
        try {
            const { codeResult } = await addAccomodationPlanning(
                accomodationPlanningToAdd
            ).unwrap();
            if (
                codeResult !==
                RESPONSES.accommodation.successToInsertAccomodation
            ) {
                if (
                    codeResult === RESPONSES.accommodation.invalidAccomodation
                ) {
                    handleSnackbarOpen(t("invalidAccomodation"), "error");
                } else if (
                    codeResult === RESPONSES.workPlanning.invalidWorkPlanning
                ) {
                    handleSnackbarOpen(t("invalidWorkPlanning"), "error");
                } else {
                    handleSnackbarOpen(t("errorToAddAccomodation"), "error");
                }
            } else {
                setOpenCreateAccomodationPlanningDialog(false);
                setOpenAlertAccomodationDialog(false);
                handleSnackbarOpen(t("accomodationAddedWithSucess"), "success");
                refetchAccomodationPlannings();
            }
        } catch (err) {
            handleSnackbarOpen(t("errorToAddAccomodation"), "error");
        }
    };

    /* Delete Accomodation Planning */
    const [
        openDeleteAccomodationPlanningDialog,
        setOpenDeleteAccomodationPlanningDialog,
    ] = useState(false);

    const handleOpenDeleteAccomodationPlanningDialog = () => {
        setOpenDeleteAccomodationPlanningDialog(true);
    };

    const handleCloseDeleteAccomodationPlanningDialog = () => {
        setOpenDeleteAccomodationPlanningDialog(false);
    };

    const [accomodationPlanningIdToDelete, setAccomodationPlanningIdToDelete] =
        useState(null);
    const handleDeleteAccomodationPlanningOpenDialog = async (id) => {
        setAccomodationPlanningIdToDelete(id);
        setOpenDeleteAccomodationPlanningDialog(true);
    };

    const [deleteAccomodationPlanning] =
        useDeleteAccomodationPlanningMutation();

    const handleDeleteAccomodationPlanning = async () => {
        try {
            const { codeResult } = await deleteAccomodationPlanning(
                accomodationPlanningIdToDelete
            ).unwrap();

            if (
                codeResult !==
                RESPONSES.workPlanning
                    .sucessToRemoveAccomodationFromWorkPlanning
            ) {
                if (
                    codeResult ===
                    RESPONSES.workPlanning
                        .invalidToRemoveAccomodationFromWorkPlanning
                ) {
                    handleSnackbarOpen(
                        t("invalidToRemoveAccomodationFromWorkPlanning"),
                        "error"
                    );
                } else if (
                    codeResult ===
                    RESPONSES.workPlanning
                        .invalidToRemoveAccomodationPlanningBecauseAssignedToEmployeePlanning
                ) {
                    handleSnackbarOpen(
                        t(
                            "invalidToRemoveAccomodationPlanningBecauseAssignedToEmployeePlanning"
                        ),
                        "error"
                    );
                } else {
                    handleSnackbarOpen(
                        t("erroToRemoveAccomodationFromWorkPlanning"),
                        "error"
                    );
                }
            } else {
                setOpenDeleteAccomodationPlanningDialog(false);
                handleSnackbarOpen(
                    t("sucessToRemoveAccomodationFromWorkPlanning"),
                    "success"
                );
                refetchAccomodationPlannings();
            }
        } catch (err) {
            handleSnackbarOpen(
                t("erroToRemoveAccomodationFromWorkPlanning"),
                "error"
            );
        }
    };

    // alojamento anterior
    const [lastAccomodation, setLastAccomodation] = useState("");
    const [editAccomodations, setEditAccomodations] = useState([]);
    const [editEmployees, setEditEmployees] = useState([]);
    const [editVehicles, setEditVehicles] = useState([]);

    useEffect(() => {
        if (editEmployees.length > 0) {
            setOpenEditEmployeePlanningDialog(true);
        }
    }, [editEmployees]);

    useEffect(() => {
        if (editVehicles.length > 0) {
            setOpenEditVehiclePlanningDialog(true);
        }
    }, [editVehicles]);

    useEffect(() => {
        if (editAccomodations.length > 0) {
            setOpenEditAccomodationPlanningDialog(true);
        }
    }, [editAccomodations]);

    //junta ao array de accomodations a accomodation atribuida a esse accomodation planning
    const handleEditAccomodationPlanning = (row) => {
        const accomodationsNotInWorkPlanningAux =
            accomodationsNotInWorkPlanning;

        let accomodationPlanningToUpdate = {
            id: row.row.id,
            workPlanningId: id,
            accomodationId: row.row.accomodationId,
            startDate: row.row.startDate,
            endDate: row.row.endDate,
        };

        setLastAccomodation(accomodationPlanningToUpdate.accomodationId);

        let aux = [...accomodationsNotInWorkPlanningAux];
        aux.push(row.row.accomodation);
        setEditAccomodations(aux);

        setAccomodationPlanning(accomodationPlanningToUpdate);
    };

    const [
        updateAccomodationPlanning,
        { isLoading: isLoadingUpdateAccomodationPlanning },
    ] =
        useUpdateAccomodationPlanningMutation(); /* Set the Delete cost accomodation Mutation */

    const [accomodationToEdit, setAccomodationToEdit] = useState("");

    const handleFormEditAccomodationPlanningSubmit = async (
        values,
        onSubmitProps
    ) => {
        try {
            if (!values.startDate || values.startDate === "") {
                setErrMsg(t("requiredStartDate"));
            } else {
                setErrMsg("");
                if (
                    values.startDate !== null &&
                    values.endDate !== null &&
                    dayjs(values.endDate).isBefore(dayjs(values.startDate))
                ) {
                    handleSnackbarOpen(
                        t("errorBecauseEndDateBeforeThanStartDate"),
                        "error"
                    );
                } else {
                    let workPlanningDTO = {
                        WorkPlanningId: id,
                        AccomodationId: values.accomodationId,
                        StartDate: values.startDate,
                        EndDate: values.endDate,
                    };
                    const { codeResult } =
                        await verifyIfAccomodationInOtherWorkPlanningMutation(
                            workPlanningDTO
                        ).unwrap();
                    if (
                        codeResult ===
                        RESPONSES.workPlanning
                            .haveAccomodationVehiclesOrEmployeesInOtherWorkPlanning
                    ) {
                        let updateAccomodatiion = null;
                        if (lastAccomodation !== values.accomodationId) {
                            updateAccomodatiion = {
                                lastAccomodationId: lastAccomodation,
                                accomodationId: values.accomodationId,
                                endDate: values.endDate,
                                id: values.id,
                                startDate: values.startDate,
                                workPlanningId: values.workPlanningId,
                            };
                        } else {
                            updateAccomodatiion = {
                                lastAccomodationId: null,
                                accomodationId: values.accomodationId,
                                endDate: values.endDate,
                                id: values.id,
                                startDate: values.startDate,
                                workPlanningId: values.workPlanningId,
                            };
                        }
                        const { codeResult } = await updateAccomodationPlanning(
                            updateAccomodatiion
                        ).unwrap();

                        if (
                            codeResult !==
                            RESPONSES.accomodationPlanning
                                .sucessToUpdateAccomodationPlanning
                        ) {
                            if (
                                codeResult ===
                                RESPONSES.accomodationPlanning
                                    .invalidToUpdateAccomodationPlanning
                            ) {
                                handleSnackbarOpen(
                                    t("invalidToUpdateAccomodationPlanning"),
                                    "error"
                                );
                            } else if (
                                codeResult ===
                                RESPONSES.accomodationPlanning
                                    .invalidToUpdateAccomodationPlanningBecauseAssignedToEmployeePlanning
                            ) {
                                handleSnackbarOpen(
                                    t(
                                        "invalidToUpdateAccomodationPlanningBecauseAssignedToEmployeePlanning"
                                    ),
                                    "error"
                                );
                            } else {
                                handleSnackbarOpen(
                                    t("errorUpdateAccomodationPlanning"),
                                    "error"
                                );
                            }
                        } else {
                            // setOpenEditAccomodationPlanningDialog(false);
                            handleSnackbarOpen(
                                t("sucessToUpdateAccomodationPlanning"),
                                "success"
                            );
                            refetchAccomodationPlannings();
                        }
                    } else {
                        if (
                            codeResult ===
                            RESPONSES.accommodation.invalidAccomodation
                        ) {
                            handleSnackbarOpen(
                                t("invalidAccomodation"),
                                "error"
                            );
                        } else if (
                            codeResult ===
                            RESPONSES.workPlanning
                                .accomodationInOtherWorkPlannings
                        ) {
                            setAccomodationToEdit(values);
                            setOpenAlertEditAccomodationDialog(true);
                        } else {
                            handleSnackbarOpen(
                                t("errorUpdateAccomodationPlanning"),
                                "error"
                            );
                        }
                    }
                }
            }
        } catch (err) {
            handleSnackbarOpen(t("errorUpdateAccomodationPlanning"), "error");
        }
    };

    const handleFormEditAccomodationPlanningAfterDialogVerifySubmit =
        async () => {
            try {
                let updateAccomodatiion = null;
                if (lastAccomodation !== accomodationToEdit.accomodationId) {
                    updateAccomodatiion = {
                        lastAccomodationId: lastAccomodation,
                        accomodationId: accomodationToEdit.accomodationId,
                        endDate: accomodationToEdit.endDate,
                        id: accomodationToEdit.id,
                        startDate: accomodationToEdit.startDate,
                        workPlanningId: accomodationToEdit.workPlanningId,
                    };
                } else {
                    updateAccomodatiion = {
                        lastAccomodationId: null,
                        accomodationId: accomodationToEdit.accomodationId,
                        endDate: accomodationToEdit.endDate,
                        id: accomodationToEdit.id,
                        startDate: accomodationToEdit.startDate,
                        workPlanningId: accomodationToEdit.workPlanningId,
                    };
                }
                const { codeResult } = await updateAccomodationPlanning(
                    updateAccomodatiion
                ).unwrap();

                if (
                    codeResult !==
                    RESPONSES.accomodationPlanning
                        .sucessToUpdateAccomodationPlanning
                ) {
                    if (
                        codeResult ===
                        RESPONSES.accomodationPlanning
                            .invalidToUpdateAccomodationPlanning
                    ) {
                        handleSnackbarOpen(
                            t("invalidToUpdateAccomodationPlanning"),
                            "error"
                        );
                    } else if (
                        codeResult ===
                        RESPONSES.accomodationPlanning
                            .invalidToUpdateAccomodationPlanningBecauseAssignedToEmployeePlanning
                    ) {
                        handleSnackbarOpen(
                            t(
                                "invalidToUpdateAccomodationPlanningBecauseAssignedToEmployeePlanning"
                            ),
                            "error"
                        );
                    } else {
                        handleSnackbarOpen(
                            t("errorUpdateAccomodationPlanning"),
                            "error"
                        );
                    }
                } else {
                    // setOpenEditAccomodationPlanningDialog(false);
                    setOpenAlertEditAccomodationDialog(false);
                    handleSnackbarOpen(
                        t("sucessToUpdateAccomodationPlanning"),
                        "success"
                    );
                    refetchAccomodationPlannings();
                }
            } catch (err) {
                handleSnackbarOpen(
                    t("errorUpdateAccomodationPlanning"),
                    "error"
                );
            }
        };

    /** #endregion ACCOMODATIONS PLANNING */

    /** #region EMPLOYEE PLANNINGS */

    const employeesColumns = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
            valueGetter: (params) => {
                if (params.row.isDriver === true) {
                    return (
                        params.row.employee.name + " (" + t("isDriver") + ")"
                    );
                } else {
                    return params.row.employee.name;
                }
            },
        },
        {
            field: "startDate",
            headerName: t("startDate"),
            flex: 1,
            valueGetter: (params) => {
                const startDate = params.row.startDate;
                if (startDate !== null) {
                    return format(new Date(startDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "endDate",
            headerName: t("endDate"),
            flex: 1,
            valueGetter: (params) => {
                const endDate = params.row.endDate;
                if (endDate !== null) {
                    return format(new Date(endDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "action",
            headerName: t("edit"),
            flex: 1,
            renderCell: (row) => (
                <EditIcon onClick={() => handleEditEmployeePlanning(row)} />
            ),
        },
        {
            field: "actions",
            headerName: t("remove"),
            flex: 1,
            renderCell: (row) => (
                <DeleteIcon
                    onClick={() =>
                        handleDeleteEmployeePlanningOpenDialog(row.id)
                    }
                />
            ),
        },
    ];

    const employeesColumnsMobile = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
            valueGetter: (params) => {
                if (params.row.isDriver === true) {
                    return (
                        params.row.employee.name + " (" + t("isDriver") + ")"
                    );
                } else {
                    return params.row.employee.name;
                }
            },
        },
        {
            field: "action",
            headerName: t("edit"),
            flex: 1,
            renderCell: (row) => (
                <EditIcon onClick={() => handleEditEmployeePlanning(row)} />
            ),
        },
        {
            field: "actions",
            headerName: t("remove"),
            flex: 1,
            renderCell: (row) => (
                <DeleteIcon
                    onClick={() =>
                        handleDeleteEmployeePlanningOpenDialog(row.id)
                    }
                />
            ),
        },
    ];

    const employeePlanningSchema = yup.object().shape({
        employeeId: yup.string().required(t("requiredField")),
        price: yup
            .number(t("invalidValue"))
            .positive(t("invalidValue"))
            .notRequired()
            .typeError(t("invalidValue")),
        hourCost: yup
            .number(t("invalidValue"))
            .positive(t("invalidValue"))
            .notRequired()
            .typeError(t("invalidValue")),
        startDate: yup.date().notRequired(),
        endDate: yup.date().notRequired(),
    }); /* Set the Employee Planning forms Schema */

    const initialValuesEmployeePlanning = {
        employeeId: "",
        price: "",
        hourCost: "",
        startDate: null,
        endDate: null,
    }; /* Set the Forms Initial Values */

    const {
        data: employeesNotInWorkPlanning,
        isLoading: isLoadingEmployeesNotInWorkPlanning,
    } = useGetEmployeesNotInWorkPlanningQuery(id);

    /* Add Employee Planning */
    const [addEmployeePlanning, { isLoading: isLoadingAddEmployeePlanning }] =
        useAddEmployeePlanningMutation();

    const [
        verifyIfEmployeeInOtherWorkPlanningMutation,
        { isLoading: isLoadingVerifyIfEmployeeInOtherWorkPlanningMutation },
    ] = useVerifyIfEmployeeInOtherWorkPlanningMutation();

    const [employeePlanningToAdd, setEmployeePlanningToAdd] = useState();

    const handleFormAddEmployeePlanningSubmit = async (
        values,
        onSubmitProps
    ) => {
        try {
            if (!values.startDate || values.startDate === "") {
                setErrMsg(t("requiredStartDate"));
            } else {
                setErrMsg("");
                if (
                    values.startDate !== null &&
                    values.endDate !== null &&
                    dayjs(values.endDate).isBefore(dayjs(values.startDate))
                ) {
                    handleSnackbarOpen(
                        t("errorBecauseEndDateBeforeThanStartDate"),
                        "error"
                    );
                } else {
                    let workPlanningDTO = null;
                    if (values.price === "") {
                        workPlanningDTO = {
                            WorkPlanningId: id,
                            EmployeeId: values.employeeId,
                            Price: null,
                            HourCost:
                                employeeSalaryType === SETTINGS.SalaryType.Fixed
                                    ? null
                                    : values.hourCost,
                            StartDate: values.startDate,
                            EndDate: values.endDate,
                        };
                    } else {
                        workPlanningDTO = {
                            WorkPlanningId: id,
                            EmployeeId: values.employeeId,
                            Price: values.price,
                            HourCost:
                                employeeSalaryType === SETTINGS.SalaryType.Fixed
                                    ? null
                                    : values.hourCost,
                            StartDate: values.startDate,
                            EndDate: values.endDate,
                        };
                    }

                    setEmployeePlanningToAdd(workPlanningDTO);

                    const { codeResult } =
                        await verifyIfEmployeeInOtherWorkPlanningMutation(
                            workPlanningDTO
                        ).unwrap();
                    if (
                        codeResult ===
                        RESPONSES.workPlanning
                            .haveAccomodationVehiclesOrEmployeesInOtherWorkPlanning
                    ) {
                        const { codeResult } = await addEmployeePlanning(
                            workPlanningDTO
                        ).unwrap();

                        if (
                            codeResult !==
                            RESPONSES.employee.successToInsertEmployee
                        ) {
                            if (
                                codeResult ===
                                RESPONSES.employee.invalidEmployee
                            ) {
                                handleSnackbarOpen(
                                    t("invalidEmployee"),
                                    "error"
                                );
                            } else if (
                                codeResult ===
                                RESPONSES.workPlanning.invalidWorkPlanning
                            ) {
                                handleSnackbarOpen(
                                    t("invalidWorkPlanning"),
                                    "error"
                                );
                            } else {
                                handleSnackbarOpen(
                                    t("errorToAddEmployee"),
                                    "error"
                                );
                            }
                        } else {
                            setOpenCreateEmployeePlanningDialog(false);
                            handleSnackbarOpen(
                                t("employeeAddedWithSucess"),
                                "success"
                            );
                            refetchEmployeePlannings();
                        }
                    } else {
                        if (codeResult === RESPONSES.employee.invalidEmployee) {
                            handleSnackbarOpen(t("invalidEmployee"), "error");
                        } else if (
                            codeResult ===
                            RESPONSES.workPlanning.employeeInOtherWorkPlannings
                        ) {
                            setOpenAlertEmployeeDialog(true);
                        } else {
                            handleSnackbarOpen(
                                t("errorToAddEmployee"),
                                "error"
                            );
                        }
                    }
                }
            }
        } catch (err) {
            handleSnackbarOpen(t("errorToAddEmployee"), "error");
        }
    };
    /* DEPOIS DE CONFIRMAR NO DIALOG DE FUNCIONÁRIOS SE PRETENDE NA MESMA ADICIONAR O FUNCIONARIO POIS ELE JA EXISTE NOUTRO PLANEAMENTO */
    const handleFormAddEmployeePlanningAfterDialogVerifySubmit = async (
        values,
        onSubmitProps
    ) => {
        try {
            const { codeResult } = await addEmployeePlanning(
                employeePlanningToAdd
            ).unwrap();

            if (codeResult !== RESPONSES.employee.successToInsertEmployee) {
                if (codeResult === RESPONSES.employee.invalidEmployee) {
                    handleSnackbarOpen(t("invalidEmployee"), "error");
                } else if (
                    codeResult === RESPONSES.workPlanning.invalidWorkPlanning
                ) {
                    handleSnackbarOpen(t("invalidWorkPlanning"), "error");
                } else {
                    handleSnackbarOpen(t("errorToAddEmployee"), "error");
                }
            } else {
                setOpenCreateEmployeePlanningDialog(false);
                setOpenAlertEmployeeDialog(false);
                handleSnackbarOpen(t("employeeAddedWithSucess"), "success");
                refetchEmployeePlannings();
            }
        } catch (err) {
            handleSnackbarOpen(t("errorToAddEmployee"), "error");
        }
    };

    /* Delete Employee Planning */
    const [
        openDeleteEmployeePlanningDialog,
        setOpenDeleteEmployeePlanningDialog,
    ] = useState(false);

    const handleOpenDeleteEmployeePlanningDialog = () => {
        setOpenDeleteEmployeePlanningDialog(true);
    };

    const handleCloseDeleteEmployeePlanningDialog = () => {
        setOpenDeleteEmployeePlanningDialog(false);
    };

    const [employeePlanningIdToDelete, setEmployeePlanningIdToDelete] =
        useState(null);
    const handleDeleteEmployeePlanningOpenDialog = async (id) => {
        setEmployeePlanningIdToDelete(id);
        setOpenDeleteEmployeePlanningDialog(true);
    };

    const [deleteEmployeePlanning] =
        useDeleteEmployeePlanningMutation(); /* Set the Delete cost accomodation Mutation */

    const handleDeleteEmployeePlanning = async () => {
        try {
            const { codeResult } = await deleteEmployeePlanning(
                employeePlanningIdToDelete
            ).unwrap();

            if (
                codeResult !==
                RESPONSES.workPlanning.sucessToRemoveEmployeeFromWorkPlanning
            ) {
                if (
                    codeResult ===
                    RESPONSES.workPlanning
                        .invalidToRemoveEmployeeFromWorkPlanning
                ) {
                    handleSnackbarOpen(
                        t("invalidToRemoveEmployeeFromWorkPlanning"),
                        "error"
                    );
                } else {
                    handleSnackbarOpen(
                        t("erroToRemoveEmployeeFromWorkPlanning"),
                        "error"
                    );
                }
            } else {
                setOpenDeleteEmployeePlanningDialog(false);
                handleSnackbarOpen(
                    t("sucessToRemoveEmployeeFromWorkPlanning"),
                    "success"
                );
                refetchEmployeePlannings();
            }
        } catch (err) {
            handleSnackbarOpen(
                t("erroToRemoveEmployeeFromWorkPlanning"),
                "error"
            );
        }
    };

    //get emplpoyee planning by id when click in employee planning
    const [getEmployeePlanningById, employeePlanningLazy] =
        useLazyGetEmployeePlanningByIdQuery();

    const [getEmployeePlanningLoading, setGetEmployeePlanningLoading] =
        useState(false);

    useEffect(() => {
        if (employeePlanningLazy.data) {
            setVehiclesFromEmployee(
                employeePlanningLazy.data.employeePlanning_Vehicles
            );
            setAccomodationsFromEmployee(
                employeePlanningLazy.data.employeePlanning_Accomodations
            );
            setEmployeePlanningIdUpdated(employeePlanningLazy.data.id);
            const employeesNotInWorkPlanningAux = employeesNotInWorkPlanning;
            let employeePlanningToUpdate = {
                id: employeePlanningLazy.data.id,
                workPlanningId: id,
                employeeId: employeePlanningLazy.data.employeeId,
                price: employeePlanningLazy.data.price,
                hourCost:
                    employeePlanningLazy.data.hourCost === null
                        ? employeePlanningLazy.data.employee.cost
                        : employeePlanningLazy.data.hourCost,
                startDate: employeePlanningLazy.data.startDate,
                endDate: employeePlanningLazy.data.endDate,
            };

            let aux = [...employeesNotInWorkPlanningAux];
            aux.push(employeePlanningLazy.data.employee);
            setEditEmployees(aux);
            setEmployeePlanningToEdit(employeePlanningToUpdate);

            getVehiclesInWorkPlanningAndNotInEmployeePlanning(
                id + "-" + employeePlanningLazy.data.id
            );
            getAccomodationsInWorkPlanningAndNotInEmployeePlanning(
                id + "-" + employeePlanningLazy.data.id
            );
            setOpenEditEmployeePlanningDialog(true);
        }
        setGetEmployeePlanningLoading(false);
    }, [employeePlanningLazy.data]);
    //junta ao array de accomodations a accomodation atribuida a esse accomodation planning
    const handleEditEmployeePlanning = (row) => {
        setGetEmployeePlanningLoading(true);

        setEmployeeSalaryType(row.row.employee.salaryTypeId);
        getEmployeePlanningById(row.row.id);
        if (employeePlanningIdUpdated === row.row.id) {
            setOpenEditEmployeePlanningDialog(true);
            setGetEmployeePlanningLoading(false);
        }
    };

    // useEffect(() => {
    //     if (
    //         vehiclesInWorkPlanningAndNotInEmployeePlanning.data &&
    //         accomodationsInWorkPlanningAndNotInEmployeePlanning.data
    //     ) {
    //         setOpenEditEmployeePlanningDialog(true);
    //     }
    // }, [
    //     vehiclesInWorkPlanningAndNotInEmployeePlanning,
    //     accomodationsInWorkPlanningAndNotInEmployeePlanning,
    // ]);

    const [
        updateEmployeePlanning,
        { isLoading: isLoadingUpdateEmployeePlanning },
    ] =
        useUpdateEmployeePlanningMutation(); /* Set the Delete cost accomodation Mutation */

    const [employeeToEdit, setEmployeeToEdit] = useState("");

    const handleFormEditEmployeePlanningSubmit = async (
        values,
        onSubmitProps
    ) => {
        try {
            if (!values.startDate || values.startDate === "") {
                setErrMsg(t("requiredStartDate"));
            } else {
                setErrMsg("");
                if (
                    values.startDate !== null &&
                    values.endDate !== null &&
                    dayjs(values.endDate).isBefore(dayjs(values.startDate))
                ) {
                    handleSnackbarOpen(
                        t("errorBecauseEndDateBeforeThanStartDate"),
                        "error"
                    );
                } else {
                    let workPlanningDTO = null;
                    if (values.price === "") {
                        workPlanningDTO = {
                            id: values.id,
                            WorkPlanningId: id,
                            EmployeeId: values.employeeId,
                            Price: null,
                            HourCost:
                                employeeSalaryType === SETTINGS.SalaryType.Fixed
                                    ? null
                                    : values.hourCost,
                            StartDate: values.startDate,
                            EndDate: values.endDate,
                        };
                    } else {
                        workPlanningDTO = {
                            id: values.id,
                            WorkPlanningId: id,
                            EmployeeId: values.employeeId,
                            Price: values.price,
                            HourCost:
                                employeeSalaryType === SETTINGS.SalaryType.Fixed
                                    ? null
                                    : values.hourCost,
                            StartDate: values.startDate,
                            EndDate: values.endDate,
                        };
                    }

                    setEmployeeToEdit(workPlanningDTO);
                    const { codeResult } =
                        await verifyIfEmployeeInOtherWorkPlanningMutation(
                            workPlanningDTO
                        ).unwrap();

                    if (
                        codeResult ===
                        RESPONSES.workPlanning
                            .haveAccomodationVehiclesOrEmployeesInOtherWorkPlanning
                    ) {
                        const { codeResult } = await updateEmployeePlanning(
                            workPlanningDTO
                        ).unwrap();
                        if (
                            codeResult !==
                            RESPONSES.employeePlanning
                                .sucessToUpdateEmployeePlanning
                        ) {
                            if (
                                codeResult ===
                                RESPONSES.employeePlanning
                                    .invalidToUpdateEmployeePlanning
                            ) {
                                handleSnackbarOpen(
                                    t("invalidToUpdateEmployeePlanning"),
                                    "error"
                                );
                            } else {
                                handleSnackbarOpen(
                                    t("errorUpdateEmployeePlanning"),
                                    "error"
                                );
                            }
                        } else {
                            // setOpenEditEmployeePlanningDialog(false);
                            handleSnackbarOpen(
                                t("sucessToUpdateEmployeePlanning"),
                                "success"
                            );
                            refetchEmployeePlannings();
                        }
                    } else {
                        if (codeResult === RESPONSES.employee.invalidEmployee) {
                            handleSnackbarOpen(t("invalidEmployee"), "error");
                        } else if (
                            codeResult ===
                            RESPONSES.workPlanning.employeeInOtherWorkPlannings
                        ) {
                            setOpenAlertEditEmployeeDialog(true);
                        } else {
                            handleSnackbarOpen(
                                t("errorUpdateEmployeePlanning"),
                                "error"
                            );
                        }
                    }
                }
            }
        } catch (err) {
            handleSnackbarOpen(t("errorUpdateEmployeePlanning"), "error");
        }
    };

    const handleFormEditEmployeePlanningAfterDialogVerifySubmit = async () => {
        try {
            const { codeResult } = await updateEmployeePlanning(
                employeeToEdit
            ).unwrap();

            if (
                codeResult !==
                RESPONSES.employeePlanning.sucessToUpdateEmployeePlanning
            ) {
                if (
                    codeResult ===
                    RESPONSES.employeePlanning.invalidToUpdateEmployeePlanning
                ) {
                    handleSnackbarOpen(
                        t("invalidToUpdateEmployeePlanning"),
                        "error"
                    );
                } else {
                    handleSnackbarOpen(
                        t("errorUpdateEmployeePlanning"),
                        "error"
                    );
                }
            } else {
                // setOpenEditEmployeePlanningDialog(false);
                setOpenAlertEditEmployeeDialog(false);
                handleSnackbarOpen(
                    t("sucessToUpdateEmployeePlanning"),
                    "success"
                );
                refetchEmployeePlannings();
            }
        } catch (err) {
            handleSnackbarOpen(t("errorUpdateEmployeePlanning"), "error");
        }
    };

    // #ENDREGION EMPLOYEE PLANNINGS

    // #REGION VEHICLE PLANNINGS

    /* Accomodation Planning Columns */

    const vehiclePlanningColumns = [
        {
            field: "licensePlateNumber",
            headerName: t("licensePlateNumber"),
            flex: 1,
            valueGetter: (params) => params.row.vehicle.licensePlateNumber,
        },
        {
            field: "startDate",
            headerName: t("startDate"),
            flex: 1,
            valueGetter: (params) => {
                const startDate = params.row.startDate;
                if (startDate !== null) {
                    return format(new Date(startDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "endDate",
            headerName: t("endDate"),
            flex: 1,
            valueGetter: (params) => {
                const endDate = params.row.endDate;
                if (endDate !== null) {
                    return format(new Date(endDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "action",
            headerName: t("edit"),
            flex: 1,
            renderCell: (row) => (
                <EditIcon onClick={() => handleEditVehiclePlanning(row)} />
            ),
        },
        {
            field: "actions",
            headerName: t("remove"),
            flex: 1,
            renderCell: (row) => (
                <DeleteIcon
                    onClick={() =>
                        handleDeleteVehiclePlanningOpenDialog(row.id)
                    }
                />
            ),
        },
    ];

    const vehiclePlanningColumnsMobile = [
        {
            field: "licensePlateNumber",
            headerName: t("licensePlateNumber"),
            flex: 1,
            valueGetter: (params) => params.row.vehicle.licensePlateNumber,
        },
        {
            field: "action",
            headerName: t("edit"),
            flex: 1,
            renderCell: (row) => (
                <EditIcon onClick={() => handleEditVehiclePlanning(row)} />
            ),
        },
        {
            field: "actions",
            headerName: t("remove"),
            flex: 1,
            renderCell: (row) => (
                <DeleteIcon
                    onClick={() =>
                        handleDeleteVehiclePlanningOpenDialog(row.id)
                    }
                />
            ),
        },
    ];

    //columns to vehicles from employee
    const vehicleFromEmployeeColumns = [
        {
            field: "brand",
            headerName: t("brand"),
            flex: 1,
            valueGetter: (params) => {
                if (params.row.isDriver === true) {
                    return (
                        params.row.vehicle.brand + " (" + t("isDriver") + ")"
                    );
                } else {
                    return params.row.vehicle.brand;
                }
            },
        },
        {
            field: "licensePlateNumber",
            headerName: t("licensePlateNumber"),
            flex: 1,
            valueGetter: (params) => params.row.vehicle.licensePlateNumber,
        },
        {
            field: "startDate",
            headerName: t("startDate"),
            flex: 1,
            valueGetter: (params) => {
                const startDate = params.row.startDate;
                if (startDate !== null) {
                    return format(new Date(startDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "endDate",
            headerName: t("endDate"),
            flex: 1,
            valueGetter: (params) => {
                const endDate = params.row.endDate;
                if (endDate !== null) {
                    return format(new Date(endDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "action",
            headerName: t("edit"),
            flex: 1,
            renderCell: (row) => (
                <EditIcon
                    onClick={() => handleEditEmployeePlaninng_Vehicle(row)}
                />
            ),
        },
        {
            field: "actions",
            headerName: t("remove"),
            flex: 1,
            renderCell: (row) => (
                <DeleteIcon
                    onClick={() =>
                        handleDeleteEmployeePlanning_VehicleOpenDialog(row.id)
                    }
                />
            ),
        },
    ];

    const vehicleFromEmployeeColumnsMobile = [
        {
            field: "licensePlateNumber",
            headerName: t("licensePlateNumber"),
            flex: 1,
            valueGetter: (params) => params.row.vehicle.licensePlateNumber,
        },
        {
            field: "action",
            headerName: t("edit"),
            flex: 1,
            renderCell: (row) => (
                <EditIcon
                    onClick={() => handleEditEmployeePlaninng_Vehicle(row)}
                />
            ),
        },
        {
            field: "actions",
            headerName: t("remove"),
            flex: 1,
            renderCell: (row) => (
                <DeleteIcon
                    onClick={() =>
                        handleDeleteEmployeePlanning_VehicleOpenDialog(row.id)
                    }
                />
            ),
        },
    ];

    /* Vehicle Planning Schema */

    const vehiclePlanningSchema = yup.object().shape({
        vehicleId: yup.string().required(t("requiredField")),
        startDate: yup.date().notRequired(),
        endDate: yup.date().notRequired(),
    }); /* Set the Vehicle Planning forms Schema */

    const initialValuesVehiclePlanning = {
        startDate: null,
        endDate: null,
    }; /* Set the Forms Initial Values */

    /* Get  Vehicles para o autocomplete no popup para criar vehicle planning*/
    const { data: vehiclesNotInWorkPlanning, isLoading: isLoadingVehicles } =
        useGetvehiclesNotInWorkPlanningQuery(id);

    /* Add Vehicle Planning */
    const [addVehiclePlanning, { isLoading: isLoadingAddVehiclePlanning }] =
        useAddVehiclePlanningMutation();

    const [
        verifyIfVehicleInOtherWorkPlanningMutation,
        { isLoading: isLoadingVerifyIfVehicleInOtherWorkPlanningMutation },
    ] = useVerifyIfVehicleInOtherWorkPlanningMutation();

    const [vehiclePlanningToAdd, setVehiclePlanningToAdd] = useState();

    const handleFormAddVehiclePlanningSubmit = async (
        values,
        onSubmitProps
    ) => {
        try {
            if (!values.startDate || values.startDate === "") {
                setErrMsg(t("requiredStartDate"));
            } else {
                setErrMsg("");
                if (
                    values.startDate !== null &&
                    values.endDate !== null &&
                    dayjs(values.endDate).isBefore(dayjs(values.startDate))
                ) {
                    handleSnackbarOpen(
                        t("errorBecauseEndDateBeforeThanStartDate"),
                        "error"
                    );
                } else {
                    let workPlanningDTO = {
                        WorkPlanningId: id,
                        VehicleId: values.vehicleId,
                        StartDate: values.startDate,
                        EndDate: values.endDate,
                    };

                    setVehiclePlanningToAdd(workPlanningDTO);

                    const { codeResult } =
                        await verifyIfVehicleInOtherWorkPlanningMutation(
                            workPlanningDTO
                        ).unwrap();

                    if (
                        codeResult ===
                        RESPONSES.workPlanning
                            .haveAccomodationVehiclesOrEmployeesInOtherWorkPlanning
                    ) {
                        const { codeResult } = await addVehiclePlanning(
                            workPlanningDTO
                        ).unwrap();
                        if (
                            codeResult !==
                            RESPONSES.vehicle.sucessToInsertVehicle
                        ) {
                            if (
                                codeResult === RESPONSES.vehicle.invalidVehicle
                            ) {
                                handleSnackbarOpen(
                                    t("invalidVehicle"),
                                    "error"
                                );
                            } else if (
                                codeResult ===
                                RESPONSES.workPlanning.invalidWorkPlanning
                            ) {
                                handleSnackbarOpen(
                                    t("invalidWorkPlanning"),
                                    "error"
                                );
                            } else {
                                handleSnackbarOpen(
                                    t("errorToAddVehicle"),
                                    "error"
                                );
                            }
                        } else {
                            setOpenCreateVehiclePlanningDialog(false);
                            handleSnackbarOpen(
                                t("vehicleAddedWithSucess"),
                                "success"
                            );
                            refetchVehiclePlannings();
                        }
                    } else {
                        if (codeResult === RESPONSES.vehicle.invalidVehicle) {
                            handleSnackbarOpen(t("invalidVehicle"), "error");
                        } else if (
                            codeResult ===
                            RESPONSES.workPlanning.vehicleInOtherWorkPlannings
                        ) {
                            setOpenAlertVehicleDialog(true);
                        } else {
                            handleSnackbarOpen(t("errorToAddVehicle"), "error");
                        }
                    }
                }
            }
        } catch (err) {
            handleSnackbarOpen(t("errorToAddVehicle"), "error");
        }
    };

    const handleFormAddVehiclePlanningAfterDialogVerifySubmit = async (
        values,
        onSubmitProps
    ) => {
        try {
            const { codeResult } = await addVehiclePlanning(
                vehiclePlanningToAdd
            ).unwrap();
            if (codeResult !== RESPONSES.vehicle.sucessToInsertVehicle) {
                if (codeResult === RESPONSES.vehicle.invalidVehicle) {
                    handleSnackbarOpen(t("invalidVehicle"), "error");
                } else if (
                    codeResult === RESPONSES.workPlanning.invalidWorkPlanning
                ) {
                    handleSnackbarOpen(t("invalidWorkPlanning"), "error");
                } else {
                    handleSnackbarOpen(t("errorToAddVehicle"), "error");
                }
            } else {
                setOpenCreateVehiclePlanningDialog(false);
                setOpenAlertVehicleDialog(false);
                handleSnackbarOpen(t("vehicleAddedWithSucess"), "success");
                refetchVehiclePlannings();
            }
        } catch (err) {
            handleSnackbarOpen(t("errorToAddVehicle"), "error");
        }
    };

    //junta ao array de accomodations a accomodation atribuida a esse accomodation planning
    const handleEditVehiclePlanning = (row) => {
        const vehiclesNotInWorkPlanningAux = vehiclesNotInWorkPlanning;
        let vehiclePlanningToUpdate = {
            id: row.row.id,
            workPlanningId: id,
            vehicleId: row.row.vehicleId,
            startDate: row.row.startDate,
            endDate: row.row.endDate,
        };

        let aux = [...vehiclesNotInWorkPlanningAux];
        aux.push(row.row.vehicle);
        setEditVehicles(aux);

        setVehiclePlanningToEdit(vehiclePlanningToUpdate);

        setOpenEditVehiclePlanningDialog(true);
    };

    const [
        updateVehiclePlanning,
        { isLoading: isLoadingUpdateVehiclePlanning },
    ] = useUpdateVehiclePlanningMutation();

    const [vehicleToEdit, setVehicleToEdit] = useState("");

    const handleFormEditVehiclePlanningSubmit = async (
        values,
        onSubmitProps
    ) => {
        try {
            if (!values.startDate || values.startDate === "") {
                setErrMsg(t("requiredStartDate"));
            } else {
                setErrMsg("");
                if (
                    values.startDate !== null &&
                    values.endDate !== null &&
                    dayjs(values.endDate).isBefore(dayjs(values.startDate))
                ) {
                    handleSnackbarOpen(
                        t("errorBecauseEndDateBeforeThanStartDate"),
                        "error"
                    );
                } else {
                    let workPlanningDTO = {
                        WorkPlanningId: id,
                        VehicleId: values.vehicleId,
                        StartDate: values.startDate,
                        EndDate: values.endDate,
                    };

                    const { codeResult } =
                        await verifyIfVehicleInOtherWorkPlanningMutation(
                            workPlanningDTO
                        ).unwrap();

                    if (
                        codeResult ===
                        RESPONSES.workPlanning
                            .haveAccomodationVehiclesOrEmployeesInOtherWorkPlanning
                    ) {
                        const { codeResult } = await updateVehiclePlanning(
                            values
                        ).unwrap();

                        if (
                            codeResult !==
                            RESPONSES.vehiclePlanning
                                .sucessToUpdateVehiclePlanning
                        ) {
                            if (
                                codeResult ===
                                RESPONSES.vehiclePlanning
                                    .invalidToUpdateVehiclePlanning
                            ) {
                                handleSnackbarOpen(
                                    t("invalidToUpdateVehiclePlanning"),
                                    "error"
                                );
                            } else {
                                handleSnackbarOpen(
                                    t("errorUpdateVehiclePlanning"),
                                    "error"
                                );
                            }
                        } else {
                            // setOpenEditVehiclePlanningDialog(false);
                            handleSnackbarOpen(
                                t("sucessToUpdateVehiclePlanning"),
                                "success"
                            );
                            refetchVehiclePlannings();
                        }
                    } else {
                        if (codeResult === RESPONSES.vehicle.invalidVehicle) {
                            handleSnackbarOpen(t("invalidVehicle"), "error");
                        } else if (
                            codeResult ===
                            RESPONSES.workPlanning.vehicleInOtherWorkPlannings
                        ) {
                            setVehicleToEdit(values);
                            setOpenAlertEditVehicleDialog(true);
                        } else {
                            handleSnackbarOpen(
                                t("errorUpdateVehiclePlanning"),
                                "error"
                            );
                        }
                    }
                }
            }
        } catch (err) {
            handleSnackbarOpen(t("errorUpdateVehiclePlanning"), "error");
        }
    };

    const handleFormEditVehiclePlanningAfterDialogVerifySubmit = async () => {
        try {
            const { codeResult } = await updateVehiclePlanning(
                vehicleToEdit
            ).unwrap();

            if (
                codeResult !==
                RESPONSES.vehiclePlanning.sucessToUpdateVehiclePlanning
            ) {
                if (
                    codeResult ===
                    RESPONSES.vehiclePlanning.invalidToUpdateVehiclePlanning
                ) {
                    handleSnackbarOpen(
                        t("invalidToUpdateVehiclePlanning"),
                        "error"
                    );
                } else {
                    handleSnackbarOpen(
                        t("errorUpdateVehiclePlanning"),
                        "error"
                    );
                }
            } else {
                // setOpenEditVehiclePlanningDialog(false);
                setOpenAlertEditVehicleDialog(false);
                handleSnackbarOpen(
                    t("sucessToUpdateVehiclePlanning"),
                    "success"
                );
                refetchVehiclePlannings();
            }
        } catch (err) {
            handleSnackbarOpen(t("errorUpdateVehiclePlanning"), "error");
        }
    };

    /* Delete Vehicle Planning */
    const [
        openDeleteVehiclePlanningDialog,
        setOpenDeleteVehiclePlanningDialog,
    ] = useState(false);

    const handleOpenDeleteVehiclePlanningDialog = () => {
        setOpenDeleteVehiclePlanningDialog(true);
    };

    const handleCloseDeleteVehiclePlanningDialog = () => {
        setOpenDeleteVehiclePlanningDialog(false);
    };

    const [vehiclePlanningIdToDelete, setVehiclePlanningIdToDelete] =
        useState(null);
    const handleDeleteVehiclePlanningOpenDialog = async (id) => {
        setVehiclePlanningIdToDelete(id);
        setOpenDeleteVehiclePlanningDialog(true);
    };

    const [deleteVehiclePlanning] =
        useDeleteVehiclePlanningMutation(); /* Set the Delete vehecile planning */

    const handleDeleteVehiclePlanning = async () => {
        try {
            const { codeResult } = await deleteVehiclePlanning(
                vehiclePlanningIdToDelete
            ).unwrap();

            if (
                codeResult !==
                RESPONSES.workPlanning.sucessToRemoveVehicleFromWorkPlanning
            ) {
                if (
                    codeResult ===
                    RESPONSES.workPlanning
                        .invalidToRemoveVehicleFromWorkPlanning
                ) {
                    handleSnackbarOpen(
                        t("invalidToRemoveVehicleFromWorkPlanning"),
                        "error"
                    );
                } else if (
                    codeResult ===
                    RESPONSES.workPlanning
                        .invalidToRemoveVehiclePlanningBecauseAssignedToEmployeePlanning
                ) {
                    handleSnackbarOpen(
                        t(
                            "invalidToRemoveVehiclePlanningBecauseAssignedToEmployeePlanning"
                        ),
                        "error"
                    );
                } else {
                    handleSnackbarOpen(
                        t("erroToRemoveVehicleFromWorkPlanning"),
                        "error"
                    );
                }
            } else {
                setOpenDeleteVehiclePlanningDialog(false);

                handleSnackbarOpen(
                    t("sucessToRemoveVehicleFromWorkPlanning"),
                    "success"
                );
                refetchVehiclePlannings();
            }
        } catch (err) {
            handleSnackbarOpen(
                t("erroToRemoveVehicleFromWorkPlanning"),
                "error"
            );
        }
    };
    // #ENDREGION VEHICLE PLANNING

    const { data: works, isLoading: isLoadingWorks } =
        useGetAllWorksWithouWorkPlanningAndWithActualWorkInWorkPlanningByIdQuery(
            id
        );

    const planningWorkSchema = yup.object().shape({
        name: yup.string().required(t("requiredField")),
        workId: yup.string().required(t("requiredField")),
    }); /* Set the Work Planning forms Schema */

    const initialValuesPlanningWork = {
        name: "",
        workId: "",
    }; /* Set the Forms Initial Values */

    //UPDATE WORK PLANNING

    const [updateWorkPlanning, { isLoading: isLoadingUpdateWorkPlanning }] =
        useUpdateWorkPlanningMutation();

    const handleFormSubmit = async (values, onSubmitProps) => {
        try {
            let workPlanningToUpdate = {
                Id: id,
                WorkId: values.workId,
                Name: values.name,
            };

            const { codeResult } = await updateWorkPlanning(
                workPlanningToUpdate
            ).unwrap();

            if (
                codeResult !== RESPONSES.workPlanning.sucessToUpdateWorkPlanning
            ) {
                if (codeResult === RESPONSES.workPlanning.invalidWorkPlanning) {
                    handleSnackbarOpen(t("invalidWorkPlanning"), "error");
                } else {
                    handleSnackbarOpen(t("erroToUpdateWorkPlanning"), "error");
                }
            } else {
                handleSnackbarOpen(t("sucessToUpdateWorkPlanning"), "success");
            }
        } catch (err) {
            handleSnackbarOpen(t("erroToUpdateWorkPlanning"), "error");
        }
    };

    const [deleteWorkPlanning] = useDeleteWorkPlanningMutation();

    const handleDeleteWorkPlanningSubmit = async (values, onSubmitProps) => {
        try {
            const { codeResult } = await deleteWorkPlanning(id).unwrap();

            if (
                codeResult !== RESPONSES.workPlanning.sucessToRemoveWorkPlanning
            ) {
                if (codeResult === RESPONSES.workPlanning.invalidWorkPlanning) {
                    handleSnackbarOpen(t("invalidWorkPlanning"), "error");
                } else {
                    handleSnackbarOpen(t("erroToRemoveWorkPlanning"), "error");
                }
            } else {
                navigate(`/${location.pathname.split("/")[1]}`);
            }
        } catch (err) {
            handleSnackbarOpen(t("erroToRemoveWorkPlanning"), "error");
        }
    };

    /* Delete Work Planning */
    const [openDeleteWorkPlanningDialog, setOpenDeleteWorkPlanningDialog] =
        useState(false);

    const handleOpenDeleteWorkPlanningDialog = () => {
        setOpenDeleteWorkPlanningDialog(true);
    };

    const handleCloseDeleteWorkPlanningDialog = () => {
        setOpenDeleteWorkPlanningDialog(false);
    };

    /* Add Vehicle to Employee */
    const [addVehicleToEmployee, { isLoading: isLoadingAddVehicleToEmployee }] =
        useAddVehicleToEmployeeMutation();

    const handleFormAddVehicleToEmployeeSubmit = async (values) => {
        try {
            if (!values.startDate || values.startDate === "") {
                setErrMsgEmployeeAssociations(t("requiredStartDate"));
            } else {
                setErrMsgEmployeeAssociations("");
                if (
                    values.startDate !== null &&
                    values.endDate !== null &&
                    dayjs(values.endDate).isBefore(dayjs(values.startDate))
                ) {
                    handleSnackbarOpen(
                        t("invalidToInsertOrUpdateWorkBecauseInvalidDates"),
                        "error"
                    );
                } else {
                    let vehiclePlanning = {
                        workPlanningId: id,
                        employeePlanningId: employeePlanningToEdit.id,
                        vehicleId: values.vehicleId,
                        isDriver: isDriver,
                        startDate: values.startDate,
                        endDate: values.endDate,
                    };

                    const { codeResult } = await addVehicleToEmployee(
                        vehiclePlanning
                    ).unwrap();

                    if (
                        codeResult !==
                        RESPONSES.employeePlanning.sucessToAddVehicle
                    ) {
                        handleSnackbarOpen(
                            t("errorAddVehicleToEmployee"),
                            "error"
                        );
                    } else {
                        setOpenAddVehicleToEmployeeDialog(false);
                        handleSnackbarOpen(
                            t("sucessToAddVehicleToEmployee"),
                            "success"
                        );
                        setIsDriver(false);
                    }
                }
            }
        } catch (err) {
            handleSnackbarOpen(t("errorAddVehicleToEmployee"), "error");
        }
    };

    /* Add Accomodation to Employee */
    const [
        addAccomodationToEmployee,
        { isLoading: isLoadingAddAccomodationToEmployee },
    ] = useAddAccomodationToEmployeeMutation();

    const handleFormAddAccomodationToEmployeeSubmit = async (values) => {
        try {
            if (!values.startDate || values.startDate === "") {
                setErrMsgEmployeeAssociations(t("requiredStartDate"));
            } else {
                setErrMsgEmployeeAssociations("");
                if (
                    values.startDate !== null &&
                    values.endDate !== null &&
                    dayjs(values.endDate).isBefore(dayjs(values.startDate))
                ) {
                    handleSnackbarOpen(
                        t("invalidToInsertOrUpdateWorkBecauseInvalidDates"),
                        "error"
                    );
                } else {
                    let accomodationPlanning = {
                        workPlanningId: id,
                        employeePlanningId: employeePlanningToEdit.id,
                        accomodationId: values.accomodationId,
                        startDate: values.startDate,
                        endDate: values.endDate,
                    };
                    const { codeResult } = await addAccomodationToEmployee(
                        accomodationPlanning
                    ).unwrap();

                    if (
                        codeResult !==
                        RESPONSES.employeePlanning.sucessToAddAccomodation
                    ) {
                        handleSnackbarOpen(
                            t("errorAddAccomodationToEmployee"),
                            "error"
                        );
                    } else {
                        setOpenAddAccomodationToEmployeeDialog(false);
                        handleSnackbarOpen(
                            t("sucessToAddAccomodationToEmployee"),
                            "success"
                        );
                    }
                }
            }
        } catch (err) {
            handleSnackbarOpen(t("errorAddAccomodationToEmployee"), "error");
        }
    };

    //edit employee planning vehicle
    const [employeePlanning_VehicleToEdit, setEmployeePlanning_VehicleToEdit] =
        useState();

    const [
        vehiclesInWorkPlanningAndNotInEmployeePlanningToEdit,
        setVehiclesInWorkPlanningAndNotInEmployeePlanningToEdit,
    ] = useState([]);

    const handleEditEmployeePlaninng_Vehicle = (row) => {
        const vehiclesInWorkPlanningAndNotInEmployeePlanningAux =
            vehiclesInWorkPlanningAndNotInEmployeePlanning.data;
        let employeePlanning_VehicleToUpdate = {
            id: row.row.id,
            employeePlanningId: row.row.employeePlanningId,
            vehicleId: row.row.vehicleId,
            startDate: row.row.startDate,
            endDate: row.row.endDate,
        };

        setIsDriver(row.row.isDriver);
        let aux = [...vehiclesInWorkPlanningAndNotInEmployeePlanningAux];
        aux.push(row.row.vehicle);
        setVehiclesInWorkPlanningAndNotInEmployeePlanningToEdit(aux);

        setEmployeePlanning_VehicleToEdit(employeePlanning_VehicleToUpdate);

        setOpenEditEmployeePlanning_VehicleDialog(true);
    };

    const [
        editEmployeePlanning_Vehicle,
        { isLoading: isLoadingEditEmployeePlanning_Vehicle },
    ] = useUpdateVehicleFromEmployeePlanningMutation();

    const handleFormEditEmployeePlanning_VehicleSubmit = async (values) => {
        try {
            if (!values.startDate || values.startDate === "") {
                setErrMsgEmployeeAssociations(t("requiredStartDate"));
            } else {
                setErrMsgEmployeeAssociations("");
                if (
                    values.startDate !== null &&
                    values.endDate !== null &&
                    dayjs(values.endDate).isBefore(dayjs(values.startDate))
                ) {
                    handleSnackbarOpen(
                        t("invalidToInsertOrUpdateWorkBecauseInvalidDates"),
                        "error"
                    );
                } else {
                    let employeePlanning_VehicleToUpdate = {
                        id: employeePlanning_VehicleToEdit.id,
                        employeePlanningId:
                            employeePlanning_VehicleToEdit.employeePlanningId,
                        vehicleId: values.vehicleId,
                        isDriver: isDriver,
                        startDate: values.startDate,
                        endDate: values.endDate,
                    };
                    const { codeResult } = await editEmployeePlanning_Vehicle(
                        employeePlanning_VehicleToUpdate
                    ).unwrap();

                    if (
                        codeResult !==
                        RESPONSES.employeePlanning
                            .sucessToUpdateVehicleFromEmployeePlanning
                    ) {
                        handleSnackbarOpen(
                            t("errorToUpdateVehicleFromEmployeePlanning"),
                            "error"
                        );
                    } else {
                        setOpenEditEmployeePlanning_VehicleDialog(false);
                        handleSnackbarOpen(
                            t("sucessToUpdateVehicleFromEmployeePlanning"),
                            "success"
                        );
                    }
                }
            }
        } catch (err) {
            handleSnackbarOpen(
                t("errorToUpdateVehicleFromEmployeePlanning"),
                "error"
            );
        }
    };

    //delete employee planning vehicle
    const [
        employeePlanning_VehicleIdToDelete,
        setEmployeePlanning_VehicleIdToDelete,
    ] = useState(null);

    const handleDeleteEmployeePlanning_VehicleOpenDialog = async (id) => {
        setEmployeePlanning_VehicleIdToDelete(id);
        setOpenDeleteEmployeePlanning_VehicleDialog(true);
    };

    const [deleteEmployeePlanning_Vehicle] =
        useDeleteVehicleFromEmployeePlanningMutation();

    const handleDeleteEmployeePlanning_Vehicle = async () => {
        try {
            const { codeResult, employeePlanningId } =
                await deleteEmployeePlanning_Vehicle(
                    employeePlanning_VehicleIdToDelete
                ).unwrap();
            if (
                codeResult !==
                RESPONSES.employeePlanning
                    .sucessToDeleteVehicleFromEmployeePlanning
            ) {
                handleSnackbarOpen(t("errorDeleteVehicle"), "error");
            } else {
                setOpenDeleteEmployeePlanning_VehicleDialog(false);
                handleSnackbarOpen(t("sucessToDeleteVehicle"), "success");
            }
        } catch (err) {
            handleSnackbarOpen(t("errorDeleteVehicle"), "error");
        }
    };

    //delete employee planning accomodation
    const [
        employeePlanning_AccomodationIdToDelete,
        setEmployeePlanning_AccomodationIdToDelete,
    ] = useState(null);

    const handleDeleteEmployeePlanning_AccomodationOpenDialog = async (id) => {
        setEmployeePlanning_AccomodationIdToDelete(id);
        setOpenDeleteEmployeePlanning_AccomodationDialog(true);
    };

    const [deleteEmployeePlanning_Accomodation] =
        useDeleteAccomodationFromEmployeePlanningMutation();

    const handleDeleteEmployeePlanning_Accomodation = async () => {
        try {
            const { codeResult, employeePlanningId } =
                await deleteEmployeePlanning_Accomodation(
                    employeePlanning_AccomodationIdToDelete
                ).unwrap();
            if (
                codeResult !==
                RESPONSES.employeePlanning
                    .sucessToDeleteAccomodationFromEmployeePlanning
            ) {
                handleSnackbarOpen(t("errorDeleteAccomodation"), "error");
            } else {
                setOpenDeleteEmployeePlanning_AccomodationDialog(false);
                handleSnackbarOpen(t("successToDeleteAccomodation"), "success");
            }
        } catch (err) {
            handleSnackbarOpen(t("errorDeleteAccomodation"), "error");
        }
    };

    //edit employee planning accomodation
    const [
        employeePlanning_AccomodationToEdit,
        setEmployeePlanning_AccomodationToEdit,
    ] = useState();

    const [
        accomodationsInWorkPlanningAndNotInEmployeePlanningToEdit,
        setAccomodationsInWorkPlanningAndNotInEmployeePlanningToEdit,
    ] = useState([]);

    const handleEditEmployeePlaninng_Accomodation = (row) => {
        const accommodationsInWorkPlanningAndNotInEmployeePlanningAux =
            accomodationsInWorkPlanningAndNotInEmployeePlanning.data;
        let employeePlanning_AccomodationToUpdate = {
            id: row.row.id,
            employeePlanningId: row.row.employeePlanningId,
            accomodationId: row.row.accomodationId,
            startDate: row.row.startDate,
            endDate: row.row.endDate,
        };
        let aux = [...accommodationsInWorkPlanningAndNotInEmployeePlanningAux];
        aux.push(row.row.accomodation);
        setAccomodationsInWorkPlanningAndNotInEmployeePlanningToEdit(aux);

        setEmployeePlanning_AccomodationToEdit(
            employeePlanning_AccomodationToUpdate
        );

        setOpenEditEmployeePlanning_AccomodationDialog(true);
    };

    const [
        editEmployeePlanning_Accomodation,
        { isLoading: isLoadingEditEmployeePlanning_Accomodation },
    ] = useUpdateAccomodationFromEmployeePlanningMutation();

    const handleFormEditEmployeePlanning_AccomodationSubmit = async (
        values
    ) => {
        try {
            if (!values.startDate || values.startDate === "") {
                setErrMsgEmployeeAssociations(t("requiredStartDate"));
            } else {
                setErrMsgEmployeeAssociations("");
                if (
                    values.startDate !== null &&
                    values.endDate !== null &&
                    dayjs(values.endDate).isBefore(dayjs(values.startDate))
                ) {
                    handleSnackbarOpen(
                        t("invalidToInsertOrUpdateWorkBecauseInvalidDates"),
                        "error"
                    );
                } else {
                    let employeePlanning_AccomodationToUpdate = {
                        id: employeePlanning_AccomodationToEdit.id,
                        employeePlanningId:
                            employeePlanning_AccomodationToEdit.employeePlanningId,
                        accomodationId: values.accomodationId,
                        startDate: values.startDate,
                        endDate: values.endDate,
                    };
                    const { codeResult } =
                        await editEmployeePlanning_Accomodation(
                            employeePlanning_AccomodationToUpdate
                        ).unwrap();

                    if (
                        codeResult !==
                        RESPONSES.employeePlanning
                            .sucessToUpdateAccomodationFromEmployeePlanning
                    ) {
                        handleSnackbarOpen(
                            t("errorToUpdateAccomodationFromEmployeePlanning"),
                            "error"
                        );
                    } else {
                        setOpenEditEmployeePlanning_AccomodationDialog(false);
                        handleSnackbarOpen(
                            t("sucessToUpdateAccomodationFromEmployeePlanning"),
                            "success"
                        );
                    }
                }
            }
        } catch (err) {
            handleSnackbarOpen(
                t("errorToUpdateAccomodationFromEmployeePlanning"),
                "error"
            );
        }
    };

    // tool bar to add employee planning
    function CustomToolbarAddEmployeePlanning() {
        return (
            <GridToolbarContainer
                sx={{
                    "& .MuiButtonBase-root:hover": {
                        backgroundColor: `${palette.primary.light} !important`,
                    },
                }}
            >
                <FlexBetween width="100%" padding="0.5rem 0">
                    <FlexBetween>
                        <GridToolbarColumnsButton />
                        <GridToolbarFilterButton />
                        <GridToolbarDensitySelector />
                        <GridToolbarExport />
                    </FlexBetween>
                    <Button
                        type="button"
                        onClick={handleOpenCreateEmployeePlanningDialog}
                        sx={{
                            p: "0.5rem 1rem",
                            backgroundColor: palette.primary.main,
                            color: `${palette.neutral.white} !important`,
                            "&:hover": {
                                color: palette.neutral.white,
                            },
                        }}
                    >
                        {t("addEmployee")}
                    </Button>
                </FlexBetween>
            </GridToolbarContainer>
        );
    }

    // tool bar to add accomodation planning
    function CustomToolbarAddAccomodationPlanning() {
        return (
            <GridToolbarContainer
                sx={{
                    "& .MuiButtonBase-root:hover": {
                        backgroundColor: `${palette.primary.light} !important`,
                    },
                }}
            >
                <FlexBetween width="100%" padding="0.5rem 0">
                    <FlexBetween>
                        <GridToolbarColumnsButton />
                        <GridToolbarFilterButton />
                        <GridToolbarDensitySelector />
                        <GridToolbarExport />
                    </FlexBetween>
                    <Button
                        type="button"
                        onClick={handleOpenCreateAccomodationPlanningDialog}
                        sx={{
                            p: "0.5rem 1rem",
                            backgroundColor: palette.primary.main,
                            color: `${palette.neutral.white} !important`,
                            "&:hover": {
                                color: palette.neutral.white,
                            },
                        }}
                    >
                        {t("addAccomodation")}
                    </Button>
                </FlexBetween>
            </GridToolbarContainer>
        );
    }

    // tool bar to add vehicle planning
    function CustomToolbarAddVehiclePlanning() {
        return (
            <GridToolbarContainer
                sx={{
                    "& .MuiButtonBase-root:hover": {
                        backgroundColor: `${palette.primary.light} !important`,
                    },
                }}
            >
                <FlexBetween width="100%" padding="0.5rem 0">
                    <FlexBetween>
                        <GridToolbarColumnsButton />
                        <GridToolbarFilterButton />
                        <GridToolbarDensitySelector />
                        <GridToolbarExport />
                    </FlexBetween>
                    <Button
                        type="button"
                        onClick={handleOpenCreateVehiclePlanningDialog}
                        sx={{
                            p: "0.5rem 1rem",
                            backgroundColor: palette.primary.main,
                            color: `${palette.neutral.white} !important`,
                            "&:hover": {
                                color: palette.neutral.white,
                            },
                        }}
                    >
                        {t("addVehicle")}
                    </Button>
                </FlexBetween>
            </GridToolbarContainer>
        );
    }

    function CustomToolbarAddVehicleToEmployee() {
        return (
            <GridToolbarContainer
                sx={{
                    "& .MuiButtonBase-root:hover": {
                        backgroundColor: `${palette.primary.light} !important`,
                    },
                }}
            >
                <FlexBetween width="100%" padding="0.5rem 0">
                    <FlexBetween>
                        <GridToolbarColumnsButton />
                        <GridToolbarFilterButton />
                        <GridToolbarDensitySelector />
                        <GridToolbarExport />
                    </FlexBetween>
                    <Button
                        type="button"
                        onClick={handleOpenAddVehicleToEmployeeDialog}
                        sx={{
                            p: "0.5rem 1rem",
                            backgroundColor: palette.primary.main,
                            color: `${palette.neutral.white} !important`,
                            "&:hover": {
                                color: palette.neutral.white,
                            },
                        }}
                    >
                        {t("addVehicle")}
                    </Button>
                </FlexBetween>
            </GridToolbarContainer>
        );
    }

    function CustomToolbarAddAccomodationToEmployee() {
        return (
            <GridToolbarContainer
                sx={{
                    "& .MuiButtonBase-root:hover": {
                        backgroundColor: `${palette.primary.light} !important`,
                    },
                }}
            >
                <FlexBetween width="100%" padding="0.5rem 0">
                    <FlexBetween>
                        <GridToolbarColumnsButton />
                        <GridToolbarFilterButton />
                        <GridToolbarDensitySelector />
                        <GridToolbarExport />
                    </FlexBetween>
                    <Button
                        type="button"
                        onClick={handleOpenAddAccomodationToEmployeeDialog}
                        sx={{
                            p: "0.5rem 1rem",
                            backgroundColor: palette.primary.main,
                            color: `${palette.neutral.white} !important`,
                            "&:hover": {
                                color: palette.neutral.white,
                            },
                        }}
                    >
                        {t("addAccomodation")}
                    </Button>
                </FlexBetween>
            </GridToolbarContainer>
        );
    }

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={workPlanning || initialValuesPlanningWork}
            validationSchema={planningWorkSchema}
            enableReinitialize={true}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    {isLoadingGetWorkPlanning ||
                    isLoadingWorks ||
                    getEmployeePlanningLoading ? (
                        <LoadingSpinner />
                    ) : null}
                    <Box
                        m={!isNonMobile ? "0" : "1.5rem 2.5rem"}
                        display={!isNonMobile ? "flex" : "grid"}
                        gridTemplateRows={
                            !isNonMobile ? undefined : "repeat(3, auto)"
                        }
                        flexDirection={!isNonMobile ? "column" : undefined}
                        gap="20px"
                        borderRadius="5px"
                        p="2rem"
                    >
                        <PageHeader title={values.name} subtitle="" />
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            {userType !== "ADMIN" ? (
                                <Typography
                                    fontWeight="500"
                                    fontSize="20px"
                                    color="primary"
                                    variant="h5"
                                    textAlign="left"
                                >
                                    {t("buildPlanningBasedOnSelectedWork")}
                                </Typography>
                            ) : (
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography
                                        fontWeight="500"
                                        fontSize="20px"
                                        color="primary"
                                        variant="h5"
                                        textAlign="left"
                                    >
                                        {t("buildPlanningBasedOnSelectedWork")}
                                    </Typography>
                                    <QueryStatsIcon
                                        sx={{
                                            color: "#0071BB",
                                            cursor: "pointer",
                                            transition: "box-shadow 0.3s ease",
                                            ":hover": {
                                                boxShadow:
                                                    "0px 0px 10px rgba(0, 0, 0, 0.2)",
                                            },
                                        }}
                                        onClick={
                                            handleOpenWorkPlanningResumeDialog
                                        }
                                    ></QueryStatsIcon>
                                </Box>
                            )}
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <TextField
                                    variant="outlined"
                                    label={t("name")}
                                    inputRef={nameRef}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    name="name"
                                    error={
                                        Boolean(touched.name) &&
                                        Boolean(errors.name)
                                    }
                                    helperText={touched.name && errors.name}
                                    sx={{ width: "100%" }}
                                />
                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingWorks}
                                    options={works || []}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                        (works &&
                                            works.find(
                                                (work) =>
                                                    work.id === values.workId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "workId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                    }}
                                    onBlur={handleBlur("workId")}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("work")}
                                                {...params}
                                                error={
                                                    Boolean(touched.workId) &&
                                                    Boolean(errors.workId)
                                                }
                                                helperText={
                                                    touched.workId &&
                                                    errors.workId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />
                            </Box>
                            {/* Accomodations*/}
                            <Box
                                display="flex"
                                marginTop="20px"
                                flexDirection="column"
                                width="100%"
                            >
                                <Typography
                                    fontWeight="500"
                                    fontSize="20px"
                                    color="primary"
                                    variant="h5"
                                    textAlign="left"
                                >
                                    {t("accomodations")}
                                </Typography>
                                <Box
                                    display="flex"
                                    sx={{
                                        "& .MuiDataGrid-root": {
                                            border: "none",
                                            flexGrow: 1,
                                            width: "100%",
                                        },
                                        "& .MuiDataGrid-cell": {
                                            // borderBottom: "none",
                                        },
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: `${theme.palette.background.J} !important`,
                                            color: theme.palette.secondary[100],
                                            borderBottom: "none",
                                        },
                                        "& .MuiDataGrid-virtualScroller": {
                                            backgroundColor:
                                                theme.palette.background.A,
                                        },
                                        "& .MuiDataGrid-footerContainer": {
                                            backgroundColor: `${theme.palette.background.J} !important`,
                                            color: theme.palette.secondary[100],
                                            borderTop: "none",
                                        },
                                        "& .MuiDataGrid-toolbarContainer .MuiButton-text":
                                            {
                                                color: `${theme.palette.background.I}`,
                                            },
                                        "& .MuiDataGrid-toolbarContainer .MuiBox-root":
                                            {
                                                display: !isNonMobile
                                                    ? "flex"
                                                    : undefined,
                                                flexDirection: !isNonMobile
                                                    ? "column"
                                                    : undefined,
                                            },
                                        "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                            {
                                                backgroundColor: `${theme.palette.background.A} !important`,
                                            },
                                    }}
                                >
                                    <DataGrid
                                        autoHeight
                                        loading={
                                            isLoadingAccomodationPlanningsByWorkPlanningId ||
                                            !accomodationPlannings
                                        }
                                        getRowId={(row) => row.id}
                                        rows={accomodationPlannings || []}
                                        columns={
                                            !isNonMobile
                                                ? accomodationPlanningColumnsMobile
                                                : accomodationPlanningColumns
                                        }
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 5,
                                                },
                                            },
                                        }}
                                        pageSizeOptions={[5]}
                                        slots={{
                                            toolbar:
                                                CustomToolbarAddAccomodationPlanning,
                                        }}
                                        key={JSON.stringify(
                                            accomodationPlannings
                                        )}
                                        disableRowSelectionOnClick
                                    />
                                </Box>
                            </Box>
                            {/* Create accomodation planning */}
                            <Dialog
                                maxWidth="800px"
                                open={openCreateAccomodationPlanningDialog}
                                onClose={
                                    handleCloseCreateAccomodationPlanningDialog
                                }
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <Formik
                                    onSubmit={
                                        handleFormAddAccomodationPlanningSubmit
                                    }
                                    initialValues={
                                        initialValuesAccomodationPlanning
                                    }
                                    validationSchema={
                                        accomodationPlanningSchema
                                    }
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        setFieldValue,
                                        resetForm,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box
                                                display="grid"
                                                gridTemplateColumns={
                                                    !isNonMobile
                                                        ? "repeat(1, 1fr)"
                                                        : "repeat(1, auto)"
                                                }
                                                gap="20px"
                                                backgroundColor={
                                                    theme.palette.background.D
                                                }
                                                borderRadius="5px"
                                                p="2rem"
                                                width={
                                                    !isNonMobile
                                                        ? "100%"
                                                        : "40rem"
                                                }
                                            >
                                                <PageHeader
                                                    title={t("addAccomodation")}
                                                    subtitle=""
                                                />
                                                <Box
                                                    display="flex"
                                                    gap="20px"
                                                    flexDirection="column"
                                                    width="100%"
                                                    mt="10px"
                                                >
                                                    <Box
                                                        display="grid"
                                                        gridTemplateColumns={
                                                            !isNonMobile
                                                                ? "repeat(1, 1fr)"
                                                                : "repeat(2, 1fr)"
                                                        }
                                                        gap="10px"
                                                    >
                                                        <Autocomplete
                                                            variant="outlined"
                                                            loading={
                                                                isLoadingAccomodations
                                                            }
                                                            options={
                                                                accomodationsNotInWorkPlanning ||
                                                                []
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) => option.address}
                                                            value={
                                                                (accomodationsNotInWorkPlanning &&
                                                                    accomodationsNotInWorkPlanning.find(
                                                                        (
                                                                            accomodation
                                                                        ) =>
                                                                            accomodation.id ===
                                                                            values.accomodationId
                                                                    )) ||
                                                                null
                                                            }
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                handleChange({
                                                                    target: {
                                                                        name: "accomodationId",
                                                                        value: newValue
                                                                            ? newValue.id
                                                                            : "",
                                                                    },
                                                                });
                                                            }}
                                                            onBlur={handleBlur(
                                                                "accomodationId"
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <FormControl
                                                                    fullWidth
                                                                >
                                                                    <TextField
                                                                        label={t(
                                                                            "accomodation"
                                                                        )}
                                                                        {...params}
                                                                        error={
                                                                            Boolean(
                                                                                touched.accomodationId
                                                                            ) &&
                                                                            Boolean(
                                                                                errors.accomodationId
                                                                            )
                                                                        }
                                                                        helperText={
                                                                            touched.accomodationId &&
                                                                            errors.accomodationId
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            )}
                                                        />
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDayjs
                                                            }
                                                        >
                                                            <DatePicker
                                                                label={t(
                                                                    "startDate"
                                                                )}
                                                                value={
                                                                    values.startDate
                                                                }
                                                                format="DD/MM/YYYY"
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    setFieldValue(
                                                                        "startDate",
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                            <DatePicker
                                                                label={t(
                                                                    "endDate"
                                                                )}
                                                                value={
                                                                    values.endDate
                                                                }
                                                                format="DD/MM/YYYY"
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    setFieldValue(
                                                                        "endDate",
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>
                                                </Box>
                                                {errMsg && (
                                                    <Alert
                                                        severity="error"
                                                        sx={{
                                                            mt: "10px",
                                                            backgroundColor:
                                                                "#540e0e",
                                                            color: "#FFFFFF",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {errMsg}
                                                    </Alert>
                                                )}
                                                {/* BUTTONS */}
                                                <LoadingButton
                                                    fullWidth
                                                    type="submit"
                                                    loading={
                                                        isLoadingVerifyIfAccomodationInOtherWorkPlanningMutation ||
                                                        isLoadingAddAccomodationPlanning
                                                    }
                                                    sx={{
                                                        width: "100%",
                                                        p: "1rem",
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.main,
                                                        color: theme.palette
                                                            .neutral.white,
                                                        "&:hover": {
                                                            backgroundColor:
                                                                theme.palette
                                                                    .primary
                                                                    .light,
                                                        },
                                                    }}
                                                >
                                                    {t("addAccomodation")}
                                                </LoadingButton>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            </Dialog>
                            {/* Edit accomodation planning */}
                            <Dialog
                                maxWidth="800px"
                                open={openEditAccomodationPlanningDialog}
                                onClose={
                                    handleCloseEditAccomodationPlanningDialog
                                }
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <Formik
                                    onSubmit={
                                        handleFormEditAccomodationPlanningSubmit
                                    }
                                    initialValues={
                                        accomodationPlanning ||
                                        initialValuesAccomodationPlanning
                                    }
                                    validationSchema={
                                        accomodationPlanningSchema
                                    }
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        setFieldValue,
                                        resetForm,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box
                                                display="grid"
                                                gridTemplateColumns={
                                                    !isNonMobile
                                                        ? "repeat(1, 1fr)"
                                                        : "repeat(1, auto)"
                                                }
                                                gap="20px"
                                                backgroundColor={
                                                    theme.palette.background.D
                                                }
                                                borderRadius="5px"
                                                p="2rem"
                                                width={
                                                    !isNonMobile
                                                        ? "100%"
                                                        : "40rem"
                                                }
                                            >
                                                <PageHeader
                                                    title={t(
                                                        "editAccomodation"
                                                    )}
                                                    subtitle=""
                                                />
                                                <Box
                                                    display="flex"
                                                    gap="20px"
                                                    flexDirection="column"
                                                    width="100%"
                                                    mt="10px"
                                                >
                                                    <Box
                                                        display="grid"
                                                        gridTemplateColumns={
                                                            !isNonMobile
                                                                ? "repeat(1, 1fr)"
                                                                : "repeat(2, 1fr)"
                                                        }
                                                        gap="10px"
                                                    >
                                                        <Autocomplete
                                                            variant="outlined"
                                                            loading={
                                                                isLoadingAccomodations
                                                            }
                                                            options={
                                                                editAccomodations ||
                                                                []
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) => option.address}
                                                            value={
                                                                (editAccomodations &&
                                                                    editAccomodations.find(
                                                                        (
                                                                            accomodation
                                                                        ) =>
                                                                            accomodation.id ===
                                                                            values.accomodationId
                                                                    )) ||
                                                                null
                                                            }
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                handleChange({
                                                                    target: {
                                                                        name: "accomodationId",
                                                                        value: newValue
                                                                            ? newValue.id
                                                                            : "",
                                                                    },
                                                                });
                                                            }}
                                                            onBlur={handleBlur(
                                                                "accomodationId"
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <FormControl
                                                                    fullWidth
                                                                >
                                                                    <TextField
                                                                        label={t(
                                                                            "accomodation"
                                                                        )}
                                                                        {...params}
                                                                        error={
                                                                            Boolean(
                                                                                touched.accomodationId
                                                                            ) &&
                                                                            Boolean(
                                                                                errors.accomodationId
                                                                            )
                                                                        }
                                                                        helperText={
                                                                            touched.accomodationId &&
                                                                            errors.accomodationId
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            )}
                                                        />
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDayjs
                                                            }
                                                        >
                                                            <DatePicker
                                                                label={t(
                                                                    "startDate"
                                                                )}
                                                                value={dayjs(
                                                                    values.startDate
                                                                )}
                                                                format="DD/MM/YYYY"
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    setFieldValue(
                                                                        "startDate",
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                            {values.endDate !==
                                                            null ? (
                                                                <DatePicker
                                                                    label={t(
                                                                        "endDate"
                                                                    )}
                                                                    value={dayjs(
                                                                        values.endDate
                                                                    )}
                                                                    format="DD/MM/YYYY"
                                                                    onChange={(
                                                                        value
                                                                    ) =>
                                                                        setFieldValue(
                                                                            "endDate",
                                                                            value
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                <DatePicker
                                                                    label={t(
                                                                        "endDate"
                                                                    )}
                                                                    value={
                                                                        values.endDate
                                                                    }
                                                                    format="DD/MM/YYYY"
                                                                    onChange={(
                                                                        value
                                                                    ) =>
                                                                        setFieldValue(
                                                                            "endDate",
                                                                            value
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </LocalizationProvider>
                                                    </Box>
                                                </Box>
                                                {errMsg && (
                                                    <Alert
                                                        severity="error"
                                                        sx={{
                                                            mt: "10px",
                                                            backgroundColor:
                                                                "#540e0e",
                                                            color: "#FFFFFF",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {errMsg}
                                                    </Alert>
                                                )}
                                                {/* BUTTONS */}
                                                <LoadingButton
                                                    fullWidth
                                                    type="submit"
                                                    loading={
                                                        isLoadingUpdateAccomodationPlanning ||
                                                        isLoadingVerifyIfAccomodationInOtherWorkPlanningMutation
                                                    }
                                                    sx={{
                                                        width: "100%",
                                                        p: "1rem",
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.main,
                                                        color: theme.palette
                                                            .neutral.white,
                                                        "&:hover": {
                                                            backgroundColor:
                                                                theme.palette
                                                                    .primary
                                                                    .light,
                                                        },
                                                    }}
                                                >
                                                    {t("editAccomodation")}
                                                </LoadingButton>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            </Dialog>
                            {/* Delete Accomodation Planning */}
                            <Dialog
                                open={openDeleteAccomodationPlanningDialog}
                                onClose={
                                    handleCloseDeleteAccomodationPlanningDialog
                                }
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {t(
                                        "deleteAccomodationPlanningConfirmationTitle"
                                    )}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {t(
                                            "deleteAccomodationPlanningConfirmationMessage"
                                        )}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        sx={{
                                            backgroundColor:
                                                theme.palette.alt.red,
                                            color: theme.palette.neutral.white,
                                            "&:hover": {
                                                backgroundColor:
                                                    theme.palette.alt.lightRed,
                                            },
                                        }}
                                        onClick={
                                            handleCloseDeleteAccomodationPlanningDialog
                                        }
                                    >
                                        {t("noMessage")}
                                    </Button>
                                    <Button
                                        sx={{
                                            backgroundColor:
                                                theme.palette.primary.main,
                                            color: theme.palette.neutral.white,
                                            "&:hover": {
                                                backgroundColor:
                                                    theme.palette.primary.light,
                                            },
                                        }}
                                        onClick={
                                            handleDeleteAccomodationPlanning
                                        }
                                        autoFocus
                                    >
                                        {t("yesMessage")}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            {/* Employees*/}
                            <Box
                                display="flex"
                                marginTop="20px"
                                flexDirection="column"
                                width="100%"
                            >
                                <Typography
                                    fontWeight="500"
                                    fontSize="20px"
                                    color="primary"
                                    variant="h5"
                                    textAlign="left"
                                >
                                    {t("employees")}
                                </Typography>
                                <Box
                                    display="flex"
                                    sx={{
                                        "& .MuiDataGrid-root": {
                                            border: "none",
                                            flexGrow: 1,
                                            width: "100%",
                                        },
                                        "& .MuiDataGrid-cell": {
                                            // borderBottom: "none",
                                        },
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: `${theme.palette.background.J} !important`,
                                            color: theme.palette.secondary[100],
                                            borderBottom: "none",
                                        },
                                        "& .MuiDataGrid-virtualScroller": {
                                            backgroundColor:
                                                theme.palette.background.A,
                                        },
                                        "& .MuiDataGrid-footerContainer": {
                                            backgroundColor: `${theme.palette.background.J} !important`,
                                            color: theme.palette.secondary[100],
                                            borderTop: "none",
                                        },
                                        "& .MuiDataGrid-toolbarContainer .MuiButton-text":
                                            {
                                                color: `${theme.palette.background.I}`,
                                            },
                                        "& .MuiDataGrid-toolbarContainer .MuiBox-root":
                                            {
                                                display: !isNonMobile
                                                    ? "flex"
                                                    : undefined,
                                                flexDirection: !isNonMobile
                                                    ? "column"
                                                    : undefined,
                                            },
                                        "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                            {
                                                backgroundColor: `${theme.palette.background.A} !important`,
                                            },
                                    }}
                                >
                                    <DataGrid
                                        autoHeight
                                        loading={
                                            isLoadingEmployeePlanningsByWorkPlanningId ||
                                            !employeePlannings
                                        }
                                        getRowId={(row) => row.id}
                                        rows={employeePlannings || []}
                                        columns={
                                            !isNonMobile
                                                ? employeesColumnsMobile
                                                : employeesColumns
                                        }
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 5,
                                                },
                                            },
                                        }}
                                        pageSizeOptions={[5]}
                                        slots={{
                                            toolbar:
                                                CustomToolbarAddEmployeePlanning,
                                        }}
                                        key={JSON.stringify(employeePlannings)}
                                        disableRowSelectionOnClick
                                    />
                                </Box>
                            </Box>
                            {/* CREATE EMPLOYEE PLANNING */}
                            <Dialog
                                maxWidth="800px"
                                open={openCreateEmployeePlanningDialog}
                                onClose={
                                    handleCloseCreateEmployeePlanningDialog
                                }
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <Formik
                                    onSubmit={
                                        handleFormAddEmployeePlanningSubmit
                                    }
                                    initialValues={
                                        initialValuesEmployeePlanning
                                    }
                                    validationSchema={employeePlanningSchema}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        setFieldValue,
                                        resetForm,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box
                                                display="grid"
                                                gridTemplateColumns={
                                                    !isNonMobile
                                                        ? "repeat(1, 1fr)"
                                                        : "repeat(1, auto)"
                                                }
                                                gap="20px"
                                                backgroundColor={
                                                    theme.palette.background.D
                                                }
                                                borderRadius="5px"
                                                p="2rem"
                                                width={
                                                    !isNonMobile
                                                        ? "100%"
                                                        : "40rem"
                                                }
                                            >
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                >
                                                    <PageHeader
                                                        title={t("addEmployee")}
                                                        subtitle=""
                                                    />
                                                </Box>

                                                <Box
                                                    display="flex"
                                                    gap="20px"
                                                    flexDirection="column"
                                                    width="100%"
                                                    mt="10px"
                                                >
                                                    <Box
                                                        display="grid"
                                                        gridTemplateColumns={
                                                            !isNonMobile
                                                                ? "repeat(1, 1fr)"
                                                                : "repeat(2, 1fr)"
                                                        }
                                                        gap="10px"
                                                    >
                                                        <Autocomplete
                                                            variant="outlined"
                                                            loading={
                                                                isLoadingEmployeesNotInWorkPlanning
                                                            }
                                                            options={
                                                                employeesNotInWorkPlanning ||
                                                                []
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) => option.name}
                                                            value={
                                                                (employeesNotInWorkPlanning &&
                                                                    employeesNotInWorkPlanning.find(
                                                                        (
                                                                            employee
                                                                        ) =>
                                                                            employee.id ===
                                                                            values.employeeId
                                                                    )) ||
                                                                null
                                                            }
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                handleChange({
                                                                    target: {
                                                                        name: "employeeId",
                                                                        value: newValue
                                                                            ? newValue.id
                                                                            : "",
                                                                    },
                                                                });
                                                                if (newValue) {
                                                                    if (
                                                                        newValue.salaryTypeId ===
                                                                        SETTINGS
                                                                            .SalaryType
                                                                            .Hour
                                                                    ) {
                                                                        values.hourCost =
                                                                            newValue.cost;
                                                                    }

                                                                    setEmployeeSalaryType(
                                                                        newValue.salaryTypeId
                                                                    );
                                                                }
                                                            }}
                                                            onBlur={handleBlur(
                                                                "employeeId"
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <FormControl
                                                                    fullWidth
                                                                >
                                                                    <TextField
                                                                        label={t(
                                                                            "employee"
                                                                        )}
                                                                        {...params}
                                                                        error={
                                                                            Boolean(
                                                                                touched.employeeId
                                                                            ) &&
                                                                            Boolean(
                                                                                errors.employeeId
                                                                            )
                                                                        }
                                                                        helperText={
                                                                            touched.employeeId &&
                                                                            errors.employeeId
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            )}
                                                        />
                                                        {employeeSalaryType ===
                                                            SETTINGS.SalaryType
                                                                .Hour && (
                                                            <TextField
                                                                label={t(
                                                                    "hourSalary"
                                                                )}
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                value={
                                                                    values.hourCost
                                                                }
                                                                name="hourCost"
                                                                error={
                                                                    Boolean(
                                                                        touched.hourCost
                                                                    ) &&
                                                                    Boolean(
                                                                        errors.hourCost
                                                                    )
                                                                }
                                                                InputProps={{
                                                                    endAdornment:
                                                                        "€",
                                                                }}
                                                                helperText={
                                                                    touched.hourCost &&
                                                                    errors.hourCost
                                                                }
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                            />
                                                        )}

                                                        <TextField
                                                            label={t("price")}
                                                            onBlur={handleBlur}
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={values.price}
                                                            name="price"
                                                            error={
                                                                Boolean(
                                                                    touched.price
                                                                ) &&
                                                                Boolean(
                                                                    errors.price
                                                                )
                                                            }
                                                            InputProps={{
                                                                endAdornment:
                                                                    "€",
                                                            }}
                                                            helperText={
                                                                touched.price &&
                                                                errors.price
                                                            }
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        />
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDayjs
                                                            }
                                                        >
                                                            <DatePicker
                                                                label={t(
                                                                    "startDate"
                                                                )}
                                                                value={
                                                                    values.startDate
                                                                }
                                                                format="DD/MM/YYYY"
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    setFieldValue(
                                                                        "startDate",
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                            <DatePicker
                                                                label={t(
                                                                    "endDate"
                                                                )}
                                                                value={
                                                                    values.endDate
                                                                }
                                                                format="DD/MM/YYYY"
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    setFieldValue(
                                                                        "endDate",
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>
                                                </Box>
                                                {errMsg && (
                                                    <Alert
                                                        severity="error"
                                                        sx={{
                                                            mt: "10px",
                                                            backgroundColor:
                                                                "#540e0e",
                                                            color: "#FFFFFF",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {errMsg}
                                                    </Alert>
                                                )}
                                                {/* BUTTONS */}
                                                <LoadingButton
                                                    fullWidth
                                                    type="submit"
                                                    loading={
                                                        isLoadingVerifyIfEmployeeInOtherWorkPlanningMutation ||
                                                        isLoadingAddEmployeePlanning
                                                    }
                                                    sx={{
                                                        width: "100%",
                                                        p: "1rem",
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.main,
                                                        color: theme.palette
                                                            .neutral.white,
                                                        "&:hover": {
                                                            backgroundColor:
                                                                theme.palette
                                                                    .primary
                                                                    .light,
                                                        },
                                                    }}
                                                >
                                                    {t("addEmployee")}
                                                </LoadingButton>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            </Dialog>
                            {/* EDIT EMPLOYEE PLANNING */}
                            <Dialog
                                maxWidth="60rem"
                                open={openEditEmployeePlanningDialog}
                                onClose={handleCloseEditEmployeePlanningDialog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <Formik
                                    onSubmit={
                                        handleFormEditEmployeePlanningSubmit
                                    }
                                    initialValues={
                                        employeePlanningToEdit ||
                                        initialValuesEmployeePlanning
                                    }
                                    validationSchema={employeePlanningSchema}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        setFieldValue,
                                        resetForm,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box
                                                display="grid"
                                                gridTemplateColumns={
                                                    !isNonMobile
                                                        ? "repeat(1, 1fr)"
                                                        : "repeat(1, auto)"
                                                }
                                                gap="20px"
                                                backgroundColor={
                                                    theme.palette.background.D
                                                }
                                                borderRadius="5px"
                                                p="2rem"
                                                width={
                                                    !isNonMobile
                                                        ? "100%"
                                                        : "100rem"
                                                }
                                            >
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                >
                                                    <PageHeader
                                                        title={t(
                                                            "editEmployee"
                                                        )}
                                                        subtitle=""
                                                    />
                                                </Box>
                                                <Box
                                                    display="flex"
                                                    gap="20px"
                                                    flexDirection="column"
                                                    width="100%"
                                                    mt="10px"
                                                >
                                                    <Box
                                                        display="grid"
                                                        gridTemplateColumns={
                                                            !isNonMobile
                                                                ? "repeat(1, 1fr)"
                                                                : "repeat(2, 1fr)"
                                                        }
                                                        gap="10px"
                                                    >
                                                        <Autocomplete
                                                            variant="outlined"
                                                            loading={
                                                                isLoadingEmployeesNotInWorkPlanning
                                                            }
                                                            options={
                                                                editEmployees ||
                                                                []
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) => option.name}
                                                            value={
                                                                (editEmployees &&
                                                                    editEmployees.find(
                                                                        (
                                                                            employee
                                                                        ) =>
                                                                            employee.id ===
                                                                            values.employeeId
                                                                    )) ||
                                                                null
                                                            }
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                handleChange({
                                                                    target: {
                                                                        name: "employeeId",
                                                                        value: newValue
                                                                            ? newValue.id
                                                                            : "",
                                                                    },
                                                                });
                                                                if (newValue) {
                                                                    if (
                                                                        newValue.salaryTypeId ===
                                                                        SETTINGS
                                                                            .SalaryType
                                                                            .Hour
                                                                    ) {
                                                                        values.hourCost =
                                                                            newValue.cost;
                                                                    }
                                                                    setEmployeeSalaryType(
                                                                        newValue.salaryTypeId
                                                                    );
                                                                }
                                                            }}
                                                            onBlur={handleBlur(
                                                                "employeeId"
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <FormControl
                                                                    fullWidth
                                                                >
                                                                    <TextField
                                                                        label={t(
                                                                            "employee"
                                                                        )}
                                                                        {...params}
                                                                        error={
                                                                            Boolean(
                                                                                touched.employeeId
                                                                            ) &&
                                                                            Boolean(
                                                                                errors.employeeId
                                                                            )
                                                                        }
                                                                        helperText={
                                                                            touched.employeeId &&
                                                                            errors.employeeId
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            )}
                                                        />
                                                        {employeeSalaryType ===
                                                            SETTINGS.SalaryType
                                                                .Hour && (
                                                            <TextField
                                                                label={t(
                                                                    "hourSalary"
                                                                )}
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                value={
                                                                    values.hourCost
                                                                }
                                                                name="hourCost"
                                                                error={
                                                                    Boolean(
                                                                        touched.hourCost
                                                                    ) &&
                                                                    Boolean(
                                                                        errors.hourCost
                                                                    )
                                                                }
                                                                InputProps={{
                                                                    endAdornment:
                                                                        "€",
                                                                }}
                                                                helperText={
                                                                    touched.hourCost &&
                                                                    errors.hourCost
                                                                }
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                            />
                                                        )}
                                                        <TextField
                                                            label={t("price")}
                                                            onBlur={handleBlur}
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.price ==
                                                                null
                                                                    ? ""
                                                                    : values.price
                                                            }
                                                            name="price"
                                                            error={
                                                                Boolean(
                                                                    touched.price
                                                                ) &&
                                                                Boolean(
                                                                    errors.price
                                                                )
                                                            }
                                                            InputProps={{
                                                                endAdornment:
                                                                    "€",
                                                            }}
                                                            helperText={
                                                                touched.price &&
                                                                errors.price
                                                            }
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        />
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDayjs
                                                            }
                                                        >
                                                            <DatePicker
                                                                label={t(
                                                                    "startDate"
                                                                )}
                                                                value={dayjs(
                                                                    values.startDate
                                                                )}
                                                                format="DD/MM/YYYY"
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    setFieldValue(
                                                                        "startDate",
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                            {values.endDate !==
                                                            null ? (
                                                                <DatePicker
                                                                    label={t(
                                                                        "endDate"
                                                                    )}
                                                                    value={dayjs(
                                                                        values.endDate
                                                                    )}
                                                                    format="DD/MM/YYYY"
                                                                    onChange={(
                                                                        value
                                                                    ) =>
                                                                        setFieldValue(
                                                                            "endDate",
                                                                            value
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                <DatePicker
                                                                    label={t(
                                                                        "endDate"
                                                                    )}
                                                                    value={
                                                                        values.endDate
                                                                    }
                                                                    format="DD/MM/YYYY"
                                                                    onChange={(
                                                                        value
                                                                    ) =>
                                                                        setFieldValue(
                                                                            "endDate",
                                                                            value
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </LocalizationProvider>
                                                    </Box>
                                                    {errMsg && (
                                                        <Alert
                                                            severity="error"
                                                            sx={{
                                                                mt: "10px",
                                                                backgroundColor:
                                                                    "#540e0e",
                                                                color: "#FFFFFF",
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            {errMsg}
                                                        </Alert>
                                                    )}
                                                    <Box
                                                        display="flex"
                                                        marginTop="20px"
                                                        flexDirection="column"
                                                        width="100%"
                                                    >
                                                        <Typography
                                                            fontWeight="500"
                                                            fontSize="20px"
                                                            color="primary"
                                                            variant="h5"
                                                            textAlign="left"
                                                        >
                                                            {t("vehicles")}
                                                        </Typography>
                                                        <Box
                                                            display="flex"
                                                            sx={{
                                                                "& .MuiDataGrid-root":
                                                                    {
                                                                        border: "none",
                                                                        flexGrow: 1,
                                                                        width: "100%",
                                                                    },
                                                                "& .MuiDataGrid-cell":
                                                                    {
                                                                        // borderBottom: "none",
                                                                    },
                                                                "& .MuiDataGrid-columnHeaders":
                                                                    {
                                                                        backgroundColor: `${theme.palette.background.J} !important`,
                                                                        color: theme
                                                                            .palette
                                                                            .secondary[100],
                                                                        borderBottom:
                                                                            "none",
                                                                    },
                                                                "& .MuiDataGrid-virtualScroller":
                                                                    {
                                                                        backgroundColor:
                                                                            theme
                                                                                .palette
                                                                                .background
                                                                                .A,
                                                                    },
                                                                "& .MuiDataGrid-footerContainer":
                                                                    {
                                                                        backgroundColor: `${theme.palette.background.J} !important`,
                                                                        color: theme
                                                                            .palette
                                                                            .secondary[100],
                                                                        borderTop:
                                                                            "none",
                                                                    },
                                                                "& .MuiDataGrid-toolbarContainer .MuiButton-text":
                                                                    {
                                                                        color: `${theme.palette.background.I}`,
                                                                    },
                                                                "& .MuiDataGrid-toolbarContainer .MuiBox-root":
                                                                    {
                                                                        display:
                                                                            !isNonMobile
                                                                                ? "flex"
                                                                                : undefined,
                                                                        flexDirection:
                                                                            !isNonMobile
                                                                                ? "column"
                                                                                : undefined,
                                                                    },
                                                                "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                                                    {
                                                                        backgroundColor: `${theme.palette.background.D} !important`,
                                                                    },
                                                            }}
                                                        >
                                                            <DataGrid
                                                                autoHeight
                                                                loading={
                                                                    isLoadingGetWorkPlanning ||
                                                                    !vehiclesFromEmployee
                                                                }
                                                                getRowId={(
                                                                    row
                                                                ) => row.id}
                                                                rows={
                                                                    vehiclesFromEmployee ||
                                                                    []
                                                                }
                                                                columns={
                                                                    !isNonMobile
                                                                        ? vehicleFromEmployeeColumnsMobile
                                                                        : vehicleFromEmployeeColumns
                                                                }
                                                                initialState={{
                                                                    pagination:
                                                                        {
                                                                            paginationModel:
                                                                                {
                                                                                    pageSize: 5,
                                                                                },
                                                                        },
                                                                }}
                                                                pageSizeOptions={[
                                                                    5,
                                                                ]}
                                                                slots={{
                                                                    toolbar:
                                                                        CustomToolbarAddVehicleToEmployee,
                                                                }}
                                                                key={JSON.stringify(
                                                                    vehiclesFromEmployee
                                                                )}
                                                                disableRowSelectionOnClick
                                                            />
                                                        </Box>
                                                    </Box>
                                                    <Box
                                                        display="flex"
                                                        marginTop="20px"
                                                        flexDirection="column"
                                                        width="100%"
                                                        marginBottom="30px"
                                                    >
                                                        <Typography
                                                            fontWeight="500"
                                                            fontSize="20px"
                                                            color="primary"
                                                            variant="h5"
                                                            textAlign="left"
                                                        >
                                                            {t("accomodations")}
                                                        </Typography>
                                                        <Box
                                                            display="flex"
                                                            sx={{
                                                                "& .MuiDataGrid-root":
                                                                    {
                                                                        border: "none",
                                                                        flexGrow: 1,
                                                                        width: "100%",
                                                                    },
                                                                "& .MuiDataGrid-cell":
                                                                    {
                                                                        // borderBottom: "none",
                                                                    },
                                                                "& .MuiDataGrid-columnHeaders":
                                                                    {
                                                                        backgroundColor: `${theme.palette.background.J} !important`,
                                                                        color: theme
                                                                            .palette
                                                                            .secondary[100],
                                                                        borderBottom:
                                                                            "none",
                                                                    },
                                                                "& .MuiDataGrid-virtualScroller":
                                                                    {
                                                                        backgroundColor:
                                                                            theme
                                                                                .palette
                                                                                .background
                                                                                .A,
                                                                    },
                                                                "& .MuiDataGrid-footerContainer":
                                                                    {
                                                                        backgroundColor: `${theme.palette.background.J} !important`,
                                                                        color: theme
                                                                            .palette
                                                                            .secondary[100],
                                                                        borderTop:
                                                                            "none",
                                                                    },
                                                                "& .MuiDataGrid-toolbarContainer .MuiButton-text":
                                                                    {
                                                                        color: `${theme.palette.background.I}`,
                                                                    },
                                                                "& .MuiDataGrid-toolbarContainer .MuiBox-root":
                                                                    {
                                                                        display:
                                                                            !isNonMobile
                                                                                ? "flex"
                                                                                : undefined,
                                                                        flexDirection:
                                                                            !isNonMobile
                                                                                ? "column"
                                                                                : undefined,
                                                                    },
                                                                "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                                                    {
                                                                        backgroundColor: `${theme.palette.background.D} !important`,
                                                                    },
                                                            }}
                                                        >
                                                            <DataGrid
                                                                autoHeight
                                                                loading={
                                                                    isLoadingGetWorkPlanning ||
                                                                    !accomodationsFromEmployee
                                                                }
                                                                getRowId={(
                                                                    row
                                                                ) => row.id}
                                                                rows={
                                                                    accomodationsFromEmployee ||
                                                                    []
                                                                }
                                                                columns={
                                                                    !isNonMobile
                                                                        ? accomodationsFromEmployeeColumnsMobile
                                                                        : accomodationsFromEmployeeColumns
                                                                }
                                                                initialState={{
                                                                    pagination:
                                                                        {
                                                                            paginationModel:
                                                                                {
                                                                                    pageSize: 5,
                                                                                },
                                                                        },
                                                                }}
                                                                pageSizeOptions={[
                                                                    5,
                                                                ]}
                                                                slots={{
                                                                    toolbar:
                                                                        CustomToolbarAddAccomodationToEmployee,
                                                                }}
                                                                key={JSON.stringify(
                                                                    accomodationsFromEmployee
                                                                )}
                                                                disableRowSelectionOnClick
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                {/* BUTTONS */}
                                                <LoadingButton
                                                    fullWidth
                                                    type="submit"
                                                    loading={
                                                        isLoadingUpdateEmployeePlanning ||
                                                        isLoadingVerifyIfEmployeeInOtherWorkPlanningMutation
                                                    }
                                                    sx={{
                                                        width: "100%",
                                                        p: "1rem",
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.main,
                                                        color: theme.palette
                                                            .neutral.white,
                                                        "&:hover": {
                                                            backgroundColor:
                                                                theme.palette
                                                                    .primary
                                                                    .light,
                                                        },
                                                    }}
                                                >
                                                    {t("editEmployee")}
                                                </LoadingButton>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            </Dialog>
                            {/* Delete Employee Planning */}
                            <Dialog
                                open={openDeleteEmployeePlanningDialog}
                                onClose={
                                    handleCloseDeleteEmployeePlanningDialog
                                }
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {t("deleteEmployeeConfirmationTitle")}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {t("deleteEmployeeConfirmationMessage")}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        sx={{
                                            backgroundColor:
                                                theme.palette.alt.red,
                                            color: theme.palette.neutral.white,
                                            "&:hover": {
                                                backgroundColor:
                                                    theme.palette.alt.lightRed,
                                            },
                                        }}
                                        onClick={
                                            handleCloseDeleteEmployeePlanningDialog
                                        }
                                    >
                                        {t("noMessage")}
                                    </Button>
                                    <Button
                                        sx={{
                                            backgroundColor:
                                                theme.palette.primary.main,
                                            color: theme.palette.neutral.white,
                                            "&:hover": {
                                                backgroundColor:
                                                    theme.palette.primary.light,
                                            },
                                        }}
                                        onClick={handleDeleteEmployeePlanning}
                                        autoFocus
                                    >
                                        {t("yesMessage")}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            {/* Add Vehicle to Employee */}
                            <Dialog
                                maxWidth="800px"
                                open={openAddVehicleToEmployeeDialog}
                                onClose={handleCloseAddVehicleToEmployeeDialog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <Formik
                                    onSubmit={
                                        handleFormAddVehicleToEmployeeSubmit
                                    }
                                    initialValues={initialValuesVehiclePlanning}
                                    validationSchema={vehiclePlanningSchema}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        setFieldValue,
                                        resetForm,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box
                                                display="grid"
                                                gridTemplateColumns={
                                                    !isNonMobile
                                                        ? "repeat(1, 1fr)"
                                                        : "repeat(1, auto)"
                                                }
                                                gap="20px"
                                                backgroundColor={
                                                    theme.palette.background.D
                                                }
                                                borderRadius="5px"
                                                p="2rem"
                                                width={
                                                    !isNonMobile
                                                        ? "100%"
                                                        : "40rem"
                                                }
                                            >
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                >
                                                    <PageHeader
                                                        title={t("addVehicle")}
                                                        subtitle=""
                                                    />
                                                    <FormControlLabel
                                                        sx={{
                                                            marginLeft: "5px",
                                                        }}
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    isDriver
                                                                }
                                                                onChange={
                                                                    setIsDriverState
                                                                }
                                                            />
                                                        }
                                                        label={t("isDriver")}
                                                    />
                                                </Box>
                                                <Box
                                                    display="flex"
                                                    gap="20px"
                                                    flexDirection="column"
                                                    width="100%"
                                                    mt="10px"
                                                >
                                                    <Box
                                                        display="grid"
                                                        gridTemplateColumns={
                                                            !isNonMobile
                                                                ? "repeat(1, 1fr)"
                                                                : "repeat(2, 1fr)"
                                                        }
                                                        gap="10px"
                                                    >
                                                        <Autocomplete
                                                            variant="outlined"
                                                            options={
                                                                vehiclesInWorkPlanningAndNotInEmployeePlanning.data ||
                                                                []
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) =>
                                                                option.licensePlateNumber
                                                            }
                                                            value={
                                                                (vehiclesInWorkPlanningAndNotInEmployeePlanning.data &&
                                                                    vehiclesInWorkPlanningAndNotInEmployeePlanning.data.find(
                                                                        (
                                                                            vehicle
                                                                        ) =>
                                                                            vehicle.id ===
                                                                            values.vehicleId
                                                                    )) ||
                                                                null
                                                            }
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                handleChange({
                                                                    target: {
                                                                        name: "vehicleId",
                                                                        value: newValue
                                                                            ? newValue.id
                                                                            : "",
                                                                    },
                                                                });
                                                            }}
                                                            onBlur={handleBlur}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <FormControl
                                                                    fullWidth
                                                                >
                                                                    <TextField
                                                                        label={t(
                                                                            "vehicle"
                                                                        )}
                                                                        {...params}
                                                                        error={
                                                                            Boolean(
                                                                                touched.vehicleId
                                                                            ) &&
                                                                            Boolean(
                                                                                errors.vehicleId
                                                                            )
                                                                        }
                                                                        helperText={
                                                                            touched.vehicleId &&
                                                                            errors.vehicleId
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            )}
                                                        />
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDayjs
                                                            }
                                                        >
                                                            <DatePicker
                                                                label={t(
                                                                    "startDate"
                                                                )}
                                                                value={
                                                                    values.startDate
                                                                }
                                                                format="DD/MM/YYYY"
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    setFieldValue(
                                                                        "startDate",
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                            <DatePicker
                                                                label={t(
                                                                    "endDate"
                                                                )}
                                                                value={
                                                                    values.endDate
                                                                }
                                                                format="DD/MM/YYYY"
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    setFieldValue(
                                                                        "endDate",
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>
                                                </Box>
                                                {errMsgEmployeeAssociations && (
                                                    <Alert
                                                        severity="error"
                                                        sx={{
                                                            mt: "10px",
                                                            backgroundColor:
                                                                "#540e0e",
                                                            color: "#FFFFFF",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {
                                                            errMsgEmployeeAssociations
                                                        }
                                                    </Alert>
                                                )}
                                                {/* BUTTONS */}
                                                <LoadingButton
                                                    fullWidth
                                                    type="submit"
                                                    loading={
                                                        isLoadingAddVehicleToEmployee
                                                    }
                                                    sx={{
                                                        width: "100%",
                                                        p: "1rem",
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.main,
                                                        color: theme.palette
                                                            .neutral.white,
                                                        "&:hover": {
                                                            backgroundColor:
                                                                theme.palette
                                                                    .primary
                                                                    .light,
                                                        },
                                                    }}
                                                >
                                                    {t("addVehicle")}
                                                </LoadingButton>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            </Dialog>
                            {/* Edit Vehicle from Employee */}
                            <Dialog
                                maxWidth="800px"
                                open={openEditEmployeePlanning_VehicleDialog}
                                onClose={
                                    handleCloseEditEmployeePlanning_VehicleDialog
                                }
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <Formik
                                    onSubmit={
                                        handleFormEditEmployeePlanning_VehicleSubmit
                                    }
                                    initialValues={
                                        employeePlanning_VehicleToEdit ||
                                        initialValuesVehiclePlanning
                                    }
                                    validationSchema={vehiclePlanningSchema}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        setFieldValue,
                                        resetForm,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box
                                                display="grid"
                                                gridTemplateColumns={
                                                    !isNonMobile
                                                        ? "repeat(1, 1fr)"
                                                        : "repeat(1, auto)"
                                                }
                                                gap="20px"
                                                backgroundColor={
                                                    theme.palette.background.D
                                                }
                                                borderRadius="5px"
                                                p="2rem"
                                                width={
                                                    !isNonMobile
                                                        ? "100%"
                                                        : "40rem"
                                                }
                                            >
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                >
                                                    <PageHeader
                                                        title={t("editVehicle")}
                                                        subtitle=""
                                                    />
                                                    <FormControlLabel
                                                        sx={{
                                                            marginLeft: "5px",
                                                        }}
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    isDriver
                                                                }
                                                                onChange={
                                                                    setIsDriverState
                                                                }
                                                            />
                                                        }
                                                        label={t("isDriver")}
                                                    />
                                                </Box>
                                                <Box
                                                    display="flex"
                                                    gap="20px"
                                                    flexDirection="column"
                                                    width="100%"
                                                    mt="10px"
                                                >
                                                    <Box
                                                        display="grid"
                                                        gridTemplateColumns={
                                                            !isNonMobile
                                                                ? "repeat(1, 1fr)"
                                                                : "repeat(2, 1fr)"
                                                        }
                                                        gap="10px"
                                                    >
                                                        <Autocomplete
                                                            variant="outlined"
                                                            options={
                                                                vehiclesInWorkPlanningAndNotInEmployeePlanningToEdit ||
                                                                []
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) =>
                                                                option.licensePlateNumber
                                                            }
                                                            value={
                                                                (vehiclesInWorkPlanningAndNotInEmployeePlanningToEdit &&
                                                                    vehiclesInWorkPlanningAndNotInEmployeePlanningToEdit.find(
                                                                        (
                                                                            vehicle
                                                                        ) =>
                                                                            vehicle.id ===
                                                                            values.vehicleId
                                                                    )) ||
                                                                null
                                                            }
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                handleChange({
                                                                    target: {
                                                                        name: "vehicleId",
                                                                        value: newValue
                                                                            ? newValue.id
                                                                            : "",
                                                                    },
                                                                });
                                                            }}
                                                            onBlur={handleBlur}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <FormControl
                                                                    fullWidth
                                                                >
                                                                    <TextField
                                                                        label={t(
                                                                            "vehicle"
                                                                        )}
                                                                        {...params}
                                                                        error={
                                                                            Boolean(
                                                                                touched.vehicleId
                                                                            ) &&
                                                                            Boolean(
                                                                                errors.vehicleId
                                                                            )
                                                                        }
                                                                        helperText={
                                                                            touched.vehicleId &&
                                                                            errors.vehicleId
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            )}
                                                        />
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDayjs
                                                            }
                                                        >
                                                            <DatePicker
                                                                label={t(
                                                                    "startDate"
                                                                )}
                                                                value={dayjs(
                                                                    values.startDate
                                                                )}
                                                                format="DD/MM/YYYY"
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    setFieldValue(
                                                                        "startDate",
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                            {values.endDate !==
                                                            null ? (
                                                                <DatePicker
                                                                    label={t(
                                                                        "endDate"
                                                                    )}
                                                                    value={dayjs(
                                                                        values.endDate
                                                                    )}
                                                                    format="DD/MM/YYYY"
                                                                    onChange={(
                                                                        value
                                                                    ) =>
                                                                        setFieldValue(
                                                                            "endDate",
                                                                            value
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                <DatePicker
                                                                    label={t(
                                                                        "endDate"
                                                                    )}
                                                                    value={
                                                                        values.endDate
                                                                    }
                                                                    format="DD/MM/YYYY"
                                                                    onChange={(
                                                                        value
                                                                    ) =>
                                                                        setFieldValue(
                                                                            "endDate",
                                                                            value
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </LocalizationProvider>
                                                    </Box>
                                                </Box>
                                                {errMsgEmployeeAssociations && (
                                                    <Alert
                                                        severity="error"
                                                        sx={{
                                                            mt: "10px",
                                                            backgroundColor:
                                                                "#540e0e",
                                                            color: "#FFFFFF",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {
                                                            errMsgEmployeeAssociations
                                                        }
                                                    </Alert>
                                                )}
                                                {/* BUTTONS */}
                                                <LoadingButton
                                                    fullWidth
                                                    type="submit"
                                                    loading={
                                                        isLoadingEditEmployeePlanning_Vehicle
                                                    }
                                                    sx={{
                                                        width: "100%",
                                                        p: "1rem",
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.main,
                                                        color: theme.palette
                                                            .neutral.white,
                                                        "&:hover": {
                                                            backgroundColor:
                                                                theme.palette
                                                                    .primary
                                                                    .light,
                                                        },
                                                    }}
                                                >
                                                    {t("editVehicle")}
                                                </LoadingButton>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            </Dialog>
                            {/* Delete Employee Planning_Vehicle */}
                            <Dialog
                                open={openDeleteEmployeePlanning_VehicleDialog}
                                onClose={
                                    handleCloseDeleteEmployeePlanning_VehicleDialog
                                }
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {t("deleteVehicleConfirmationTitle")}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {t("deleteVehicleConfirmationMessage")}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        sx={{
                                            backgroundColor:
                                                theme.palette.alt.red,
                                            color: theme.palette.neutral.white,
                                            "&:hover": {
                                                backgroundColor:
                                                    theme.palette.alt.lightRed,
                                            },
                                        }}
                                        onClick={
                                            handleCloseDeleteEmployeePlanning_VehicleDialog
                                        }
                                    >
                                        {t("noMessage")}
                                    </Button>
                                    <Button
                                        sx={{
                                            backgroundColor:
                                                theme.palette.primary.main,
                                            color: theme.palette.neutral.white,
                                            "&:hover": {
                                                backgroundColor:
                                                    theme.palette.primary.light,
                                            },
                                        }}
                                        onClick={
                                            handleDeleteEmployeePlanning_Vehicle
                                        }
                                        autoFocus
                                    >
                                        {t("yesMessage")}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            {/* Add Accomodation to Employee */}
                            <Dialog
                                maxWidth="800px"
                                open={openAddAccomodationToEmployeeDialog}
                                onClose={
                                    handleCloseAddAccomodationToEmployeeDialog
                                }
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <Formik
                                    onSubmit={
                                        handleFormAddAccomodationToEmployeeSubmit
                                    }
                                    initialValues={
                                        initialValuesAccomodationPlanning
                                    }
                                    validationSchema={
                                        accomodationPlanningSchema
                                    }
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        setFieldValue,
                                        resetForm,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box
                                                display="grid"
                                                gridTemplateColumns={
                                                    !isNonMobile
                                                        ? "repeat(1, 1fr)"
                                                        : "repeat(1, auto)"
                                                }
                                                gap="20px"
                                                backgroundColor={
                                                    theme.palette.background.D
                                                }
                                                borderRadius="5px"
                                                p="2rem"
                                                width={
                                                    !isNonMobile
                                                        ? "100%"
                                                        : "40rem"
                                                }
                                            >
                                                <PageHeader
                                                    title={t("addAccomodation")}
                                                    subtitle=""
                                                />
                                                <Box
                                                    display="flex"
                                                    gap="20px"
                                                    flexDirection="column"
                                                    width="100%"
                                                    mt="10px"
                                                >
                                                    <Box
                                                        display="grid"
                                                        gridTemplateColumns={
                                                            !isNonMobile
                                                                ? "repeat(1, 1fr)"
                                                                : "repeat(2, 1fr)"
                                                        }
                                                        gap="10px"
                                                    >
                                                        <Autocomplete
                                                            variant="outlined"
                                                            options={
                                                                accomodationsInWorkPlanningAndNotInEmployeePlanning.data ||
                                                                []
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) => option.address}
                                                            value={
                                                                (accomodationsInWorkPlanningAndNotInEmployeePlanning.data &&
                                                                    accomodationsInWorkPlanningAndNotInEmployeePlanning.data.find(
                                                                        (
                                                                            accomodation
                                                                        ) =>
                                                                            accomodation.id ===
                                                                            values.accomodationId
                                                                    )) ||
                                                                null
                                                            }
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                handleChange({
                                                                    target: {
                                                                        name: "accomodationId",
                                                                        value: newValue
                                                                            ? newValue.id
                                                                            : "",
                                                                    },
                                                                });
                                                            }}
                                                            onBlur={handleBlur}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <FormControl
                                                                    fullWidth
                                                                >
                                                                    <TextField
                                                                        label={t(
                                                                            "accomodation"
                                                                        )}
                                                                        {...params}
                                                                        error={
                                                                            Boolean(
                                                                                touched.accomodationId
                                                                            ) &&
                                                                            Boolean(
                                                                                errors.accomodationId
                                                                            )
                                                                        }
                                                                        helperText={
                                                                            touched.accomodationId &&
                                                                            errors.accomodationId
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            )}
                                                        />
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDayjs
                                                            }
                                                        >
                                                            <DatePicker
                                                                label={t(
                                                                    "startDate"
                                                                )}
                                                                value={
                                                                    values.startDate
                                                                }
                                                                format="DD/MM/YYYY"
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    setFieldValue(
                                                                        "startDate",
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                            <DatePicker
                                                                label={t(
                                                                    "endDate"
                                                                )}
                                                                value={
                                                                    values.endDate
                                                                }
                                                                format="DD/MM/YYYY"
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    setFieldValue(
                                                                        "endDate",
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>
                                                </Box>
                                                {errMsgEmployeeAssociations && (
                                                    <Alert
                                                        severity="error"
                                                        sx={{
                                                            mt: "10px",
                                                            backgroundColor:
                                                                "#540e0e",
                                                            color: "#FFFFFF",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {
                                                            errMsgEmployeeAssociations
                                                        }
                                                    </Alert>
                                                )}
                                                {/* BUTTONS */}
                                                <LoadingButton
                                                    fullWidth
                                                    type="submit"
                                                    loading={
                                                        isLoadingAddAccomodationToEmployee
                                                    }
                                                    sx={{
                                                        width: "100%",
                                                        p: "1rem",
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.main,
                                                        color: theme.palette
                                                            .neutral.white,
                                                        "&:hover": {
                                                            backgroundColor:
                                                                theme.palette
                                                                    .primary
                                                                    .light,
                                                        },
                                                    }}
                                                >
                                                    {t("addAccomodation")}
                                                </LoadingButton>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            </Dialog>
                            {/* Edit Accomodation from Employee */}
                            <Dialog
                                maxWidth="800px"
                                open={
                                    openEditEmployeePlanning_AccomodationDialog
                                }
                                onClose={
                                    handleCloseEditEmployeePlanning_AccomodationDialog
                                }
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <Formik
                                    onSubmit={
                                        handleFormEditEmployeePlanning_AccomodationSubmit
                                    }
                                    initialValues={
                                        employeePlanning_AccomodationToEdit ||
                                        initialValuesAccomodationPlanning
                                    }
                                    validationSchema={
                                        accomodationPlanningSchema
                                    }
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        setFieldValue,
                                        resetForm,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box
                                                display="grid"
                                                gridTemplateColumns={
                                                    !isNonMobile
                                                        ? "repeat(1, 1fr)"
                                                        : "repeat(1, auto)"
                                                }
                                                gap="20px"
                                                backgroundColor={
                                                    theme.palette.background.D
                                                }
                                                borderRadius="5px"
                                                p="2rem"
                                                width={
                                                    !isNonMobile
                                                        ? "100%"
                                                        : "40rem"
                                                }
                                            >
                                                <PageHeader
                                                    title={t(
                                                        "editAccomodation"
                                                    )}
                                                    subtitle=""
                                                />
                                                <Box
                                                    display="flex"
                                                    gap="20px"
                                                    flexDirection="column"
                                                    width="100%"
                                                    mt="10px"
                                                >
                                                    <Box
                                                        display="grid"
                                                        gridTemplateColumns={
                                                            !isNonMobile
                                                                ? "repeat(1, 1fr)"
                                                                : "repeat(2, 1fr)"
                                                        }
                                                        gap="10px"
                                                    >
                                                        <Autocomplete
                                                            variant="outlined"
                                                            options={
                                                                accomodationsInWorkPlanningAndNotInEmployeePlanningToEdit ||
                                                                []
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) => option.address}
                                                            value={
                                                                (accomodationsInWorkPlanningAndNotInEmployeePlanningToEdit &&
                                                                    accomodationsInWorkPlanningAndNotInEmployeePlanningToEdit.find(
                                                                        (
                                                                            accomodation
                                                                        ) =>
                                                                            accomodation.id ===
                                                                            values.accomodationId
                                                                    )) ||
                                                                null
                                                            }
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                handleChange({
                                                                    target: {
                                                                        name: "accomodationId",
                                                                        value: newValue
                                                                            ? newValue.id
                                                                            : "",
                                                                    },
                                                                });
                                                            }}
                                                            onBlur={handleBlur}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <FormControl
                                                                    fullWidth
                                                                >
                                                                    <TextField
                                                                        label={t(
                                                                            "accomodation"
                                                                        )}
                                                                        {...params}
                                                                        error={
                                                                            Boolean(
                                                                                touched.accomodationId
                                                                            ) &&
                                                                            Boolean(
                                                                                errors.accomodationId
                                                                            )
                                                                        }
                                                                        helperText={
                                                                            touched.accomodationId &&
                                                                            errors.accomodationId
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            )}
                                                        />
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDayjs
                                                            }
                                                        >
                                                            <DatePicker
                                                                label={t(
                                                                    "startDate"
                                                                )}
                                                                value={dayjs(
                                                                    values.startDate
                                                                )}
                                                                format="DD/MM/YYYY"
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    setFieldValue(
                                                                        "startDate",
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                            {values.endDate !==
                                                            null ? (
                                                                <DatePicker
                                                                    label={t(
                                                                        "endDate"
                                                                    )}
                                                                    value={dayjs(
                                                                        values.endDate
                                                                    )}
                                                                    format="DD/MM/YYYY"
                                                                    onChange={(
                                                                        value
                                                                    ) =>
                                                                        setFieldValue(
                                                                            "endDate",
                                                                            value
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                <DatePicker
                                                                    label={t(
                                                                        "endDate"
                                                                    )}
                                                                    value={
                                                                        values.endDate
                                                                    }
                                                                    format="DD/MM/YYYY"
                                                                    onChange={(
                                                                        value
                                                                    ) =>
                                                                        setFieldValue(
                                                                            "endDate",
                                                                            value
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </LocalizationProvider>
                                                    </Box>
                                                </Box>
                                                {errMsgEmployeeAssociations && (
                                                    <Alert
                                                        severity="error"
                                                        sx={{
                                                            mt: "10px",
                                                            backgroundColor:
                                                                "#540e0e",
                                                            color: "#FFFFFF",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {
                                                            errMsgEmployeeAssociations
                                                        }
                                                    </Alert>
                                                )}
                                                {/* BUTTONS */}
                                                <LoadingButton
                                                    fullWidth
                                                    type="submit"
                                                    loading={
                                                        isLoadingEditEmployeePlanning_Accomodation
                                                    }
                                                    sx={{
                                                        width: "100%",
                                                        p: "1rem",
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.main,
                                                        color: theme.palette
                                                            .neutral.white,
                                                        "&:hover": {
                                                            backgroundColor:
                                                                theme.palette
                                                                    .primary
                                                                    .light,
                                                        },
                                                    }}
                                                >
                                                    {t("editAccomodation")}
                                                </LoadingButton>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            </Dialog>
                            {/* Delete Employee Planning_Accomodation */}
                            <Dialog
                                open={
                                    openDeleteEmployeePlanning_AccomodationDialog
                                }
                                onClose={
                                    handleCloseDeleteEmployeePlanning_AccomodationDialog
                                }
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {t("deleteAccomodationConfirmationTitle")}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {t(
                                            "deleteAccomodationConfirmationMessage"
                                        )}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        sx={{
                                            backgroundColor:
                                                theme.palette.alt.red,
                                            color: theme.palette.neutral.white,
                                            "&:hover": {
                                                backgroundColor:
                                                    theme.palette.alt.lightRed,
                                            },
                                        }}
                                        onClick={
                                            handleCloseDeleteEmployeePlanning_AccomodationDialog
                                        }
                                    >
                                        {t("noMessage")}
                                    </Button>
                                    <Button
                                        sx={{
                                            backgroundColor:
                                                theme.palette.primary.main,
                                            color: theme.palette.neutral.white,
                                            "&:hover": {
                                                backgroundColor:
                                                    theme.palette.primary.light,
                                            },
                                        }}
                                        onClick={
                                            handleDeleteEmployeePlanning_Accomodation
                                        }
                                        autoFocus
                                    >
                                        {t("yesMessage")}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            {/* Vehicles*/}
                            <Box
                                display="flex"
                                marginTop="20px"
                                flexDirection="column"
                                width="100%"
                            >
                                <Typography
                                    fontWeight="500"
                                    fontSize="20px"
                                    color="primary"
                                    variant="h5"
                                    textAlign="left"
                                >
                                    {t("vehicles")}
                                </Typography>
                                <Box
                                    display="flex"
                                    sx={{
                                        "& .MuiDataGrid-root": {
                                            border: "none",
                                            flexGrow: 1,
                                            width: "100%",
                                        },
                                        "& .MuiDataGrid-cell": {
                                            // borderBottom: "none",
                                        },
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: `${theme.palette.background.J} !important`,
                                            color: theme.palette.secondary[100],
                                            borderBottom: "none",
                                        },
                                        "& .MuiDataGrid-virtualScroller": {
                                            backgroundColor:
                                                theme.palette.background.A,
                                        },
                                        "& .MuiDataGrid-footerContainer": {
                                            backgroundColor: `${theme.palette.background.J} !important`,
                                            color: theme.palette.secondary[100],
                                            borderTop: "none",
                                        },
                                        "& .MuiDataGrid-toolbarContainer .MuiButton-text":
                                            {
                                                color: `${theme.palette.background.I}`,
                                            },
                                        "& .MuiDataGrid-toolbarContainer .MuiBox-root":
                                            {
                                                display: !isNonMobile
                                                    ? "flex"
                                                    : undefined,
                                                flexDirection: !isNonMobile
                                                    ? "column"
                                                    : undefined,
                                            },
                                        "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                            {
                                                backgroundColor: `${theme.palette.background.A} !important`,
                                            },
                                    }}
                                >
                                    <DataGrid
                                        autoHeight
                                        loading={
                                            isLoadingVehiclePlanningsByWorkPlanningId ||
                                            !vehiclePlannings
                                        }
                                        getRowId={(row) => row.id}
                                        rows={vehiclePlannings || []}
                                        columns={
                                            !isNonMobile
                                                ? vehiclePlanningColumnsMobile
                                                : vehiclePlanningColumns
                                        }
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 5,
                                                },
                                            },
                                        }}
                                        pageSizeOptions={[5]}
                                        slots={{
                                            toolbar:
                                                CustomToolbarAddVehiclePlanning,
                                        }}
                                        key={JSON.stringify(vehiclePlannings)}
                                        disableRowSelectionOnClick
                                    />
                                </Box>
                            </Box>

                            {/* Create vehicle planning */}
                            <Dialog
                                maxWidth="800px"
                                open={openCreateVehiclePlanningDialog}
                                onClose={handleCloseCreateVehiclePlanningDialog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <Formik
                                    onSubmit={
                                        handleFormAddVehiclePlanningSubmit
                                    }
                                    initialValues={initialValuesVehiclePlanning}
                                    validationSchema={vehiclePlanningSchema}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        setFieldValue,
                                        resetForm,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box
                                                display="grid"
                                                gridTemplateColumns={
                                                    !isNonMobile
                                                        ? "repeat(1, 1fr)"
                                                        : "repeat(1, auto)"
                                                }
                                                gap="20px"
                                                backgroundColor={
                                                    theme.palette.background.D
                                                }
                                                borderRadius="5px"
                                                p="2rem"
                                                width={
                                                    !isNonMobile
                                                        ? "100%"
                                                        : "40rem"
                                                }
                                            >
                                                <PageHeader
                                                    title={t("addVehicle")}
                                                    subtitle=""
                                                />
                                                <Box
                                                    display="flex"
                                                    gap="20px"
                                                    flexDirection="column"
                                                    width="100%"
                                                    mt="10px"
                                                >
                                                    <Box
                                                        display="grid"
                                                        gridTemplateColumns={
                                                            !isNonMobile
                                                                ? "repeat(1, 1fr)"
                                                                : "repeat(2, 1fr)"
                                                        }
                                                        gap="10px"
                                                    >
                                                        <Autocomplete
                                                            variant="outlined"
                                                            loading={
                                                                isLoadingVehicles
                                                            }
                                                            options={
                                                                vehiclesNotInWorkPlanning ||
                                                                []
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) =>
                                                                option.licensePlateNumber
                                                            }
                                                            value={
                                                                (vehiclesNotInWorkPlanning &&
                                                                    vehiclesNotInWorkPlanning.find(
                                                                        (
                                                                            vehicle
                                                                        ) =>
                                                                            vehicle.id ===
                                                                            values.vehicleId
                                                                    )) ||
                                                                null
                                                            }
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                handleChange({
                                                                    target: {
                                                                        name: "vehicleId",
                                                                        value: newValue
                                                                            ? newValue.id
                                                                            : "",
                                                                    },
                                                                });
                                                            }}
                                                            onBlur={handleBlur(
                                                                "vehicleId"
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <FormControl
                                                                    fullWidth
                                                                >
                                                                    <TextField
                                                                        label={t(
                                                                            "vehicle"
                                                                        )}
                                                                        {...params}
                                                                        error={
                                                                            Boolean(
                                                                                touched.vehicleId
                                                                            ) &&
                                                                            Boolean(
                                                                                errors.vehicleId
                                                                            )
                                                                        }
                                                                        helperText={
                                                                            touched.vehicleId &&
                                                                            errors.vehicleId
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            )}
                                                        />
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDayjs
                                                            }
                                                        >
                                                            <DatePicker
                                                                label={t(
                                                                    "startDate"
                                                                )}
                                                                value={
                                                                    values.startDate
                                                                }
                                                                format="DD/MM/YYYY"
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    setFieldValue(
                                                                        "startDate",
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                            <DatePicker
                                                                label={t(
                                                                    "endDate"
                                                                )}
                                                                value={
                                                                    values.endDate
                                                                }
                                                                format="DD/MM/YYYY"
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    setFieldValue(
                                                                        "endDate",
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>
                                                </Box>
                                                {errMsg && (
                                                    <Alert
                                                        severity="error"
                                                        sx={{
                                                            mt: "10px",
                                                            backgroundColor:
                                                                "#540e0e",
                                                            color: "#FFFFFF",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {errMsg}
                                                    </Alert>
                                                )}
                                                {/* BUTTONS */}
                                                <LoadingButton
                                                    fullWidth
                                                    type="submit"
                                                    loading={
                                                        isLoadingVerifyIfVehicleInOtherWorkPlanningMutation ||
                                                        isLoadingAddVehiclePlanning
                                                    }
                                                    sx={{
                                                        width: "100%",
                                                        p: "1rem",
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.main,
                                                        color: theme.palette
                                                            .neutral.white,
                                                        "&:hover": {
                                                            backgroundColor:
                                                                theme.palette
                                                                    .primary
                                                                    .light,
                                                        },
                                                    }}
                                                >
                                                    {t("addVehicle")}
                                                </LoadingButton>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            </Dialog>
                            {/* Edit vehicle planning */}
                            <Dialog
                                maxWidth="800px"
                                open={openEditVehiclePlanningDialog}
                                onClose={handleCloseEditVehiclePlanningDialog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <Formik
                                    onSubmit={
                                        handleFormEditVehiclePlanningSubmit
                                    }
                                    initialValues={
                                        vehiclePlanningToEdit ||
                                        initialValuesVehiclePlanning
                                    }
                                    validationSchema={vehiclePlanningSchema}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        setFieldValue,
                                        resetForm,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box
                                                display="grid"
                                                gridTemplateColumns={
                                                    !isNonMobile
                                                        ? "repeat(1, 1fr)"
                                                        : "repeat(1, auto)"
                                                }
                                                gap="20px"
                                                backgroundColor={
                                                    theme.palette.background.D
                                                }
                                                borderRadius="5px"
                                                p="2rem"
                                                width={
                                                    !isNonMobile
                                                        ? "100%"
                                                        : "40rem"
                                                }
                                            >
                                                <PageHeader
                                                    title={t("editVehicle")}
                                                    subtitle=""
                                                />
                                                <Box
                                                    display="flex"
                                                    gap="20px"
                                                    flexDirection="column"
                                                    width="100%"
                                                    mt="10px"
                                                >
                                                    <Box
                                                        display="grid"
                                                        gridTemplateColumns={
                                                            !isNonMobile
                                                                ? "repeat(1, 1fr)"
                                                                : "repeat(2, 1fr)"
                                                        }
                                                        gap="10px"
                                                    >
                                                        <Autocomplete
                                                            variant="outlined"
                                                            loading={
                                                                isLoadingVehicles
                                                            }
                                                            options={
                                                                editVehicles ||
                                                                []
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) =>
                                                                option.licensePlateNumber
                                                            }
                                                            value={
                                                                (editVehicles &&
                                                                    editVehicles.find(
                                                                        (
                                                                            vehicle
                                                                        ) =>
                                                                            vehicle.id ===
                                                                            values.vehicleId
                                                                    )) ||
                                                                null
                                                            }
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                handleChange({
                                                                    target: {
                                                                        name: "vehicleId",
                                                                        value: newValue
                                                                            ? newValue.id
                                                                            : "",
                                                                    },
                                                                });
                                                            }}
                                                            onBlur={handleBlur(
                                                                "vehicleId"
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <FormControl
                                                                    fullWidth
                                                                >
                                                                    <TextField
                                                                        label={t(
                                                                            "vehicle"
                                                                        )}
                                                                        {...params}
                                                                        error={
                                                                            Boolean(
                                                                                touched.vehicleId
                                                                            ) &&
                                                                            Boolean(
                                                                                errors.vehicleId
                                                                            )
                                                                        }
                                                                        helperText={
                                                                            touched.vehicleId &&
                                                                            errors.vehicleId
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            )}
                                                        />
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDayjs
                                                            }
                                                        >
                                                            <DatePicker
                                                                label={t(
                                                                    "startDate"
                                                                )}
                                                                value={dayjs(
                                                                    values.startDate
                                                                )}
                                                                format="DD/MM/YYYY"
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    setFieldValue(
                                                                        "startDate",
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                            {values.endDate !==
                                                            null ? (
                                                                <DatePicker
                                                                    label={t(
                                                                        "endDate"
                                                                    )}
                                                                    value={dayjs(
                                                                        values.endDate
                                                                    )}
                                                                    format="DD/MM/YYYY"
                                                                    onChange={(
                                                                        value
                                                                    ) =>
                                                                        setFieldValue(
                                                                            "endDate",
                                                                            value
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                <DatePicker
                                                                    label={t(
                                                                        "endDate"
                                                                    )}
                                                                    value={
                                                                        values.endDate
                                                                    }
                                                                    format="DD/MM/YYYY"
                                                                    onChange={(
                                                                        value
                                                                    ) =>
                                                                        setFieldValue(
                                                                            "endDate",
                                                                            value
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </LocalizationProvider>
                                                    </Box>
                                                </Box>
                                                {errMsg && (
                                                    <Alert
                                                        severity="error"
                                                        sx={{
                                                            mt: "10px",
                                                            backgroundColor:
                                                                "#540e0e",
                                                            color: "#FFFFFF",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {errMsg}
                                                    </Alert>
                                                )}
                                                {/* BUTTONS */}
                                                <LoadingButton
                                                    fullWidth
                                                    type="submit"
                                                    loading={
                                                        isLoadingUpdateVehiclePlanning ||
                                                        isLoadingVerifyIfVehicleInOtherWorkPlanningMutation
                                                    }
                                                    sx={{
                                                        width: "100%",
                                                        p: "1rem",
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.main,
                                                        color: theme.palette
                                                            .neutral.white,
                                                        "&:hover": {
                                                            backgroundColor:
                                                                theme.palette
                                                                    .primary
                                                                    .light,
                                                        },
                                                    }}
                                                >
                                                    {t("editVehicle")}
                                                </LoadingButton>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            </Dialog>
                            {/* Delete Vehicle Planning */}
                            <Dialog
                                open={openDeleteVehiclePlanningDialog}
                                onClose={handleCloseDeleteVehiclePlanningDialog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {t(
                                        "deleteVehiclePlanningConfirmationTitle"
                                    )}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {t(
                                            "deleteVehiclePlanningConfirmationMessage"
                                        )}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        sx={{
                                            backgroundColor:
                                                theme.palette.alt.red,
                                            color: theme.palette.neutral.white,
                                            "&:hover": {
                                                backgroundColor:
                                                    theme.palette.alt.lightRed,
                                            },
                                        }}
                                        onClick={
                                            handleCloseDeleteVehiclePlanningDialog
                                        }
                                    >
                                        {t("noMessage")}
                                    </Button>
                                    <LoadingButton
                                        sx={{
                                            backgroundColor:
                                                theme.palette.primary.main,
                                            color: theme.palette.neutral.white,
                                            "&:hover": {
                                                backgroundColor:
                                                    theme.palette.primary.light,
                                            },
                                        }}
                                        onClick={handleDeleteVehiclePlanning}
                                        autoFocus
                                    >
                                        {t("yesMessage")}
                                    </LoadingButton>
                                </DialogActions>
                            </Dialog>
                        </Box>
                        {/* BUTTONS */}
                        <Box display="flex" gap="10px">
                            <LoadingButton
                                fullWidth
                                type="button"
                                onClick={handleOpenDeleteWorkPlanningDialog}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                            >
                                {t("deleteWorkPlanningButton")}
                            </LoadingButton>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                loading={isLoadingUpdateWorkPlanning}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                            >
                                {t("editPlanning")}
                            </LoadingButton>
                        </Box>
                    </Box>
                    <Dialog
                        open={openDeleteWorkPlanningDialog}
                        onClose={handleCloseDeleteWorkPlanningDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("deleteWorkPlanningConfirmationTitle")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t("deleteWorkPlanningConfirmationMessage")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                                onClick={handleCloseDeleteWorkPlanningDialog}
                            >
                                {t("noMessage")}
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                                onClick={handleDeleteWorkPlanningSubmit}
                                autoFocus
                            >
                                {t("yesMessage")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* ALERTAS PARA ALOJAMENTO */}
                    <Dialog
                        open={openAlertAccomodationDialog}
                        onClose={handleCloseAlertAccomodationDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("accomodationPlanningTitleDialog")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t("accomodationPlanningMessageDialog")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                                onClick={handleCloseAlertAccomodationDialog}
                            >
                                {t("noMessage")}
                            </Button>
                            <LoadingButton
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                                onClick={
                                    handleFormAddAccomodationPlanningAfterDialogVerifySubmit
                                }
                                autoFocus
                            >
                                {t("yesMessage")}
                            </LoadingButton>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={openAlertEditAccomodationDialog}
                        onClose={handleCloseAlertEditAccomodationDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("accomodationPlanningTitleDialog")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t("accomodationPlanningMessageDialogToEdit")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                                onClick={handleCloseAlertEditAccomodationDialog}
                            >
                                {t("noMessage")}
                            </Button>
                            <LoadingButton
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                                onClick={
                                    handleFormEditAccomodationPlanningAfterDialogVerifySubmit
                                }
                                autoFocus
                            >
                                {t("yesMessage")}
                            </LoadingButton>
                        </DialogActions>
                    </Dialog>
                    {/* ALERTAS PARA FUNCIONÁRIO */}
                    <Dialog
                        open={openAlertEmployeeDialog}
                        onClose={handleCloseAlertEmployeeDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("employeePlanningTitleDialog")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t("employeePlanningMessageDialog")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                                onClick={handleCloseAlertEmployeeDialog}
                            >
                                {t("noMessage")}
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                                onClick={
                                    handleFormAddEmployeePlanningAfterDialogVerifySubmit
                                }
                                autoFocus
                            >
                                {t("yesMessage")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={openAlertEditEmployeeDialog}
                        onClose={handleCloseAlertEditEmployeeDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("employeePlanningTitleDialog")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t("employeePlanningMessageDialogToEdit")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                                onClick={handleCloseAlertEditEmployeeDialog}
                            >
                                {t("noMessage")}
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                                onClick={
                                    handleFormEditEmployeePlanningAfterDialogVerifySubmit
                                }
                                autoFocus
                            >
                                {t("yesMessage")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* ALERTAS PARA VEICULO */}
                    <Dialog
                        open={openAlertVehicleDialog}
                        onClose={handleCloseAlertVehicleDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("vehiclePlanningTitleDialog")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t("vehiclePlanningMessageDialog")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                                onClick={handleCloseAlertVehicleDialog}
                            >
                                {t("noMessage")}
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                                onClick={
                                    handleFormAddVehiclePlanningAfterDialogVerifySubmit
                                }
                                autoFocus
                            >
                                {t("yesMessage")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={openAlertEditVehicleDialog}
                        onClose={handleCloseAlertEditVehicleDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("vehiclePlanningTitleDialog")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t("vehiclePlanningMessageDialogToEdit")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                                onClick={handleCloseAlertEditVehicleDialog}
                            >
                                {t("noMessage")}
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                                onClick={
                                    handleFormEditVehiclePlanningAfterDialogVerifySubmit
                                }
                                autoFocus
                            >
                                {t("yesMessage")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* Work Planning Resume */}
                    <Dialog
                        open={openWorkPlanningResumeDialog}
                        onClose={handleCloseWorkPlanningResumeDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <Box
                                boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                sx={{
                                    borderRadius: "20px",
                                    margin: "20px",
                                    padding: "10px",
                                }}
                            >
                                <Typography
                                    variant="h1"
                                    component="h2"
                                    color="black"
                                    textAlign="center"
                                >
                                    {totalHours}
                                </Typography>
                                <Typography
                                    variant="h2"
                                    component="h2"
                                    color="#c8c8c8"
                                    textAlign="center"
                                    fontSize="32px"
                                    fontWeight="500"
                                >
                                    {t("totalHours")}
                                </Typography>
                            </Box>
                            <Box
                                boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                sx={{
                                    borderRadius: "20px",
                                    margin: "20px",
                                    padding: "10px",
                                }}
                            >
                                <Typography
                                    variant="h1"
                                    component="h2"
                                    color="black"
                                    textAlign="center"
                                >
                                    {totalCost + " €"}
                                </Typography>
                                <Typography
                                    variant="h2"
                                    component="h2"
                                    color="#c8c8c8"
                                    textAlign="center"
                                    fontSize="32px"
                                    fontWeight="500"
                                >
                                    {t("totalCost")}
                                </Typography>
                            </Box>
                            <Box
                                boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                sx={{
                                    borderRadius: "20px",
                                    margin: "20px",
                                    padding: "10px",
                                }}
                            >
                                <Typography
                                    variant="h1"
                                    component="h2"
                                    color="black"
                                    textAlign="center"
                                >
                                    {totalProfit + " €"}
                                </Typography>
                                <Typography
                                    variant="h2"
                                    component="h2"
                                    color="#c8c8c8"
                                    textAlign="center"
                                    fontSize="32px"
                                    fontWeight="500"
                                >
                                    {t("totalProfit")}
                                </Typography>
                            </Box>
                        </DialogContent>
                    </Dialog>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity={snackbarSeverity}
                            sx={{
                                mt: "10px",
                                backgroundColor:
                                    snackbarSeverity === "success"
                                        ? "#10781F"
                                        : "#ff000082",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {snackbarMsg}
                        </Alert>
                    </Snackbar>
                </form>
            )}
        </Formik>
    );
};

export default EditWorkPlanningForm;

// color design tokens export
export const colorTokens = {
    grey: {
        0: "#FFFFFF", // L
        10: "#F6F6F6", // K
        50: "#F0F0F0", // J
        100: "#E0E0E0", // I
        200: "#C2C2C2", // H
        300: "#A3A3A3", // G
        400: "#858585", // F
        500: "#666666", // E
        600: "#4D4D4D", // D
        700: "#0000001f", // C #333333
        800: "#2a2a2a", // B
        900: "#1A1A1A", // A
        1000: "#000000",
    },
    primary: {
        50: AddTransparency("#0071BB", 50),
        100: AddTransparency("#0071BB", 40),
        200: AddTransparency("#0071BB", 30),
        300: AddTransparency("#0071BB", 20),
        400: AddTransparency("#0071BB", 80),
        500: "#0071BB",
        600: darkenColor("#0071BB", 10),
        700: darkenColor("#0071BB", 20),
        800: darkenColor("#0071BB", 30),
        900: darkenColor("#0071BB", 40),
    },
    alt: {
        red: "#FF0000",
        lightRed: "#FF3232",
    },
};

function AddTransparency(color, transparency) {
    // Remove any whitespace and convert the color to lowercase
    color = color.trim().toLowerCase();

    // Check if the color is in hexadecimal format
    if (color.startsWith("#")) {
        // Extract the RGB values from the hexadecimal color
        const red = parseInt(color.substr(1, 2), 16);
        const green = parseInt(color.substr(3, 2), 16);
        const blue = parseInt(color.substr(5, 2), 16);

        // Calculate the new RGBA values with transparency
        const alpha = transparency / 100;
        const newRed = Math.round((1 - alpha) * 255 + alpha * red);
        const newGreen = Math.round((1 - alpha) * 255 + alpha * green);
        const newBlue = Math.round((1 - alpha) * 255 + alpha * blue);

        // Convert the RGBA values back to hexadecimal format
        const newColor = `#${newRed.toString(16).padStart(2, "0")}${newGreen
            .toString(16)
            .padStart(2, "0")}${newBlue.toString(16).padStart(2, "0")}`;

        return newColor;
    }

    // Check if the color is in RGB format
    if (color.startsWith("rgb")) {
        // Extract the RGB values from the RGB color
        const rgbValues = color.match(/\d+/g);
        const red = parseInt(rgbValues[0], 10);
        const green = parseInt(rgbValues[1], 10);
        const blue = parseInt(rgbValues[2], 10);

        // Calculate the new RGBA values with transparency
        const alpha = transparency / 100;
        const newRed = Math.round((1 - alpha) * 255 + alpha * red);
        const newGreen = Math.round((1 - alpha) * 255 + alpha * green);
        const newBlue = Math.round((1 - alpha) * 255 + alpha * blue);

        // Convert the RGBA values back to RGB format
        const newColor = `rgb(${newRed}, ${newGreen}, ${newBlue})`;

        return newColor;
    }

    // If the color format is not recognized, return the original color
    return color;
}

function darkenColor(color, amount) {
    const hex = color.replace(/^#/, "");
    const rgb = parseInt(hex, 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = rgb & 0xff;
    const newR = Math.max(0, r - amount);
    const newG = Math.max(0, g - amount);
    const newB = Math.max(0, b - amount);
    const newHex = ((newR << 16) | (newG << 8) | newB).toString(16);
    return `#${newHex.padStart(6, "0")}`;
}

// mui theme settings
export const themeSettings = (mode) => {
    return {
        palette: {
            mode: mode,
            ...(mode === "dark"
                ? {
                      // palette values for dark mode
                      primary: {
                          dark: colorTokens.primary[800],
                          main: colorTokens.primary[500],
                          light: colorTokens.primary[400],
                      },
                      neutral: {
                          dark: colorTokens.grey[700],
                          main: colorTokens.grey[600],
                          mediumMain: colorTokens.grey[400],
                          medium: colorTokens.grey[300],
                          light: colorTokens.grey[50],
                          white: colorTokens.grey[0],
                      },
                      background: {
                          default: colorTokens.grey[900],
                          A: colorTokens.grey[900],
                          B: colorTokens.grey[800],
                          C: colorTokens.grey[700],
                          D: colorTokens.grey[600],
                          E: colorTokens.grey[500],
                          F: colorTokens.grey[400],
                          G: colorTokens.grey[300],
                          H: colorTokens.grey[200],
                          I: colorTokens.grey[100],
                          J: colorTokens.grey[50],
                          K: colorTokens.grey[10],
                          L: colorTokens.grey[0],
                      },
                      alt: {
                          red: colorTokens.alt.red,
                          lightRed: colorTokens.alt.lightRed,
                      },
                  }
                : {
                      // palette values for light mode
                      primary: {
                          dark: colorTokens.primary[600],
                          main: colorTokens.primary[500],
                          light: colorTokens.primary[400],
                      },
                      neutral: {
                          dark: colorTokens.grey[700],
                          main: colorTokens.grey[600],
                          mediumMain: colorTokens.grey[400],
                          medium: colorTokens.grey[300],
                          light: colorTokens.grey[50],
                          white: colorTokens.grey[0],
                      },
                      background: {
                          default: colorTokens.grey[0],
                          A: colorTokens.grey[0],
                          B: colorTokens.grey[300], // B -> H
                          C: colorTokens.grey[200],
                          D: colorTokens.grey[50],
                          E: colorTokens.grey[100],
                          F: colorTokens.grey[300],
                          G: colorTokens.grey[400],
                          H: colorTokens.grey[500],
                          I: colorTokens.grey[600],
                          J: colorTokens.grey[700],
                          K: colorTokens.grey[800],
                          L: colorTokens.grey[900],
                      },
                      alt: {
                          red: colorTokens.alt.red,
                          lightRed: colorTokens.alt.lightRed,
                      },
                  }),
        },
        typography: {
            fontFamily: ["Open Sans", "sans-serif"].join(","),
            fontSize: 12,
            h1: {
                fontFamily: ["Open Sans", "sans-serif"].join(","),
                fontSize: 40,
            },
            h2: {
                fontFamily: ["Open Sans", "sans-serif"].join(","),
                fontSize: 32,
            },
            h3: {
                fontFamily: ["Open Sans", "sans-serif"].join(","),
                fontSize: 24,
            },
            h4: {
                fontFamily: ["Open Sans", "sans-serif"].join(","),
                fontSize: 20,
            },
            h5: {
                fontFamily: ["Open Sans", "sans-serif"].join(","),
                fontSize: 16,
            },
            h6: {
                fontFamily: ["Open Sans", "sans-serif"].join(","),
                fontSize: 14,
            },
        },
    };
};

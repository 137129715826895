// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');*/

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
}

html,
body,
#root,
.app {
    height: 100%;
    width: 100%;
    font-family: "Open Sans", sans-serif;
}

.MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #000 !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,gGAAgG;;AAGhG;;;IAGI,sBAAsB;AAC1B;;AAEA;IACI,SAAS;AACb;;AAEA;;;;IAII,YAAY;IACZ,WAAW;IACX,oCAAoC;AACxC;;AAEA;IACI,kDAAkD;AACtD;;AAEA,kCAAkC;AAClC;;;;IAII,mCAAmC;AACvC;;AAEA;;;;;;;;;EASE,6CAA6C;EAC7C,yDAAiD;EAAjD,iDAAiD;AACnD","sourcesContent":["/* @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');*/\n@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap');\n\n*,\n*::before,\n*::after {\n    box-sizing: border-box;\n}\n\n* {\n    margin: 0;\n}\n\nhtml,\nbody,\n#root,\n.app {\n    height: 100%;\n    width: 100%;\n    font-family: \"Open Sans\", sans-serif;\n}\n\n.MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {\n    -webkit-box-shadow: 0 0 0px 1000px #000 !important;\n}\n\n/* Change the white to any color */\ninput:-webkit-autofill,\ninput:-webkit-autofill:hover,\ninput:-webkit-autofill:focus,\ninput:-webkit-autofill:active {\n    -webkit-box-shadow: none !important;\n}\n\ninput:-webkit-autofill,\ninput:-webkit-autofill:hover, \ninput:-webkit-autofill:focus,\ntextarea:-webkit-autofill,\ntextarea:-webkit-autofill:hover,\ntextarea:-webkit-autofill:focus,\nselect:-webkit-autofill,\nselect:-webkit-autofill:hover,\nselect:-webkit-autofill:focus {\n  -webkit-box-shadow: 0 0 0px 1000px #000 inset;\n  transition: background-color 5000s ease-in-out 0s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

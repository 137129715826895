import { ResponsiveLine } from "@nivo/line";
import { useTranslation } from "react-i18next";

const LineChartSubContractor = ({ data }) => {
    const { t } = useTranslation();

    return (
        <ResponsiveLine
            data={data}
            margin={{ top: 50, right: 110, bottom: 100, left: 60 }}
            colors={["gray", "green"]}
            xScale={{ type: "point" }}
            yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                reverse: false,
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 15,
                tickRotation: -45,
                format: (value) =>
                    value.length > 10 ? value.substring(0, 10) + "..." : value,
                legend: t("works"),
                legendOffset: 75,
                legendPosition: "middle",
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendOffset: -40,
                legendPosition: "middle",
            }}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
                {
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: "left-to-right",
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: "circle",
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    effects: [
                        {
                            on: "hover",
                            style: {
                                itemBackground: "rgba(0, 0, 0, .03)",
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
            curve="linear" // Tipo de interpolação
        />
    );
};

export default LineChartSubContractor;

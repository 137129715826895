import { Outlet, Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useRefreshTokenMutation } from "./authApiSlice";
import usePersist from "hooks/usePersist";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "./authSlice";
import LoadingSpinner from "components/LoadingSpinner";
import { useDispatch } from "react-redux";
import { setCredentials } from "./authSlice";
import { useTranslation } from "react-i18next";
import { Box, Typography, useTheme } from "@mui/material";

const PersistLogin = () => {
    const theme = useTheme();
    const [persist, setPersist, language, setLanguage] = usePersist();
    const token = useSelector(selectCurrentToken);
    const effectRan = useRef(false);
    const dispatch = useDispatch(); /* Set the Dispatch Service */
    const { t, i18n } = useTranslation(); /* Set the Translation Service */

    const [trueSuccess, setTrueSuccess] = useState(false);

    const [refresh, { isUninitialized, isLoading, isSuccess, isError, error }] =
        useRefreshTokenMutation();

    useEffect(() => {
        if (
            effectRan.current === true ||
            process.env.NODE_ENV !== "development"
        ) {
            // React 18 Strict Mode
            const verifyRefreshToken = async () => {
                try {
                    const { user, token } = await refresh().unwrap();

                    dispatch(setCredentials({ user, token }));
                    i18n.changeLanguage(language);
                    setTrueSuccess(true);
                } catch (err) {
                    console.error(err);
                }
            };

            if (!token && persist) verifyRefreshToken();
        }

        return () => (effectRan.current = true);

        // eslint-disable-next-line
    }, []);

    let content;
    if (!persist) {
        // persist: no
        content = <Outlet />;
    } else if (isLoading) {
        //persist: yes, token: no
        content = (
            <>
                <LoadingSpinner />
            </>
        );
    } else if (isError) {
        //persist: yes, token: no
        content = (
            <>
                <Box
                    m="1.5rem 2.5rem"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Box
                        display="flex"
                        gap="20px"
                        flexDirection="column"
                        width="50%"
                        p="2rem"
                        borderRadius="1rem"
                        backgroundColor={theme.palette.background.alt}
                    >
                        <Typography
                            fontWeight="500"
                            fontSize="20px"
                            color="primary"
                            variant="h5"
                            textAlign="left"
                        >
                            {t("sessionExpired")}
                        </Typography>
                        <Link style={{ color: "#FFF " }} to="/">
                            {t("pleaseLoginAgain")}
                        </Link>
                    </Box>
                </Box>
            </>
        );
    } else if (isSuccess && trueSuccess) {
        //persist: yes, token: yes
        content = <Outlet />;
    } else if (token && isUninitialized) {
        //persist: yes, token: yes
        content = <Outlet />;
    }

    return content;
};
export default PersistLogin;

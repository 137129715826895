import { useState, useEffect } from "react";

const usePersist = () => {
    const [persist, setPersist] = useState(
        JSON.parse(localStorage.getItem("persist")) || false
    );

    const [language, setLanguage] = useState(
        JSON.parse(localStorage.getItem("language")) || "pt"
    );

    useEffect(() => {
        localStorage.setItem("language", JSON.stringify(language));
    }, [language]);

    useEffect(() => {
        localStorage.setItem("persist", JSON.stringify(persist));
    }, [persist]);

    return [persist, setPersist, language, setLanguage];
};

export default usePersist;

import { apiSlice } from "app/api/apiSlice";

export const adjustmentsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAdjustmentsPaginated: builder.query({
            query: (query) => {
                const params = [];
                params.push(`limit=${(query && query.limit) || 10}`);
                params.push(`offset=${(query && query.offset) || 0}`);
                if (query && query.search)
                    params.push(
                        `search=${encodeURIComponent(query && query.search)}`
                    );
                if (query && query.filters)
                    params.push(
                        `filters=${encodeURIComponent(
                            JSON.stringify(query && query.filters)
                        )}`
                    );
                if (query && query.orderByCollection)
                    params.push(
                        `orderByCollection=${encodeURIComponent(
                            JSON.stringify(query && query.orderByCollection)
                        )}`
                    );
                const queryString =
                    params.length > 0 ? `?${params.join("&")}` : "";
                return {
                    url: `Adjustments/GetAllPaginated${queryString.toString()}`,
                    validateStatus: (response, result) => {
                        return response.status === 200 && !result.isError;
                    },
                    query,
                };
            },
            providesTags: [{ type: "Adjustments", id: "LIST" }],
        }),
        getAdjustments: builder.query({
            query: () => ({
                url: `Adjustments/GetAll`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "Adjustments", id: "LIST" }],
        }),
        postAdjustment: builder.mutation({
            query: (adjustmentData) => ({
                url: `Adjustments`,
                method: "POST",
                body: {
                    ...adjustmentData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Adjustments", id: "LIST" },
                {
                    type: "EmployeeWorkHourCosts",
                    id: result.employeeWorkHourCostId,
                },
            ],
        }),
        getAdjustmentById: builder.query({
            query: (id) => ({
                url: `Adjustments/${id}`,
            }),
            providesTags: (result, error, arg) => [
                { type: "Adjustments", id: arg },
            ],
        }),
        updateAdjustment: builder.mutation({
            query: (adjustmentData) => ({
                url: `Adjustments`,
                method: "PUT",
                body: {
                    ...adjustmentData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Adjustments", id: "LIST" },
                { type: "Adjustments", id: arg.id },
                {
                    type: "EmployeeWorkHourCosts",
                    id: result.employeeWorkHourCostId,
                },
            ],
        }),
        deleteAdjustmentById: builder.mutation({
            query: (id) => ({
                url: `Adjustments/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Adjustments", id: "LIST" },
                {
                    type: "EmployeeWorkHourCosts",
                    id: result.employeeWorkHourCostId,
                },
            ],
        }),
        deleteAdjustmentsAfterGenerateProcessingSalaryPDF: builder.mutation({
            query: () => ({
                url: `Adjustments`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "Adjustments", id: "LIST" }],
        }),
    }),
});

export const {
    useGetAdjustmentsQuery,
    useGetAdjustmentByIdQuery,
    usePostAdjustmentMutation,
    useUpdateAdjustmentMutation,
    useDeleteAdjustmentByIdMutation,
    useDeleteAdjustmentsAfterGenerateProcessingSalaryPDFMutation,
} = adjustmentsApiSlice;

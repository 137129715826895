import React, { useCallback, useRef } from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import PageHeader from "components/PageHeader";
import { useTranslation } from "react-i18next";
import CustomTable from "components/CustomTable";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyGetContactsPaginatedQuery } from "./contactsApiSlice";

const PAGE_SIZE_OPTIONS = [10, 20, 50];

const ContactsList = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const columns = useRef([
        {
            field: "name",
            title: t("name"),
            filtering: true,
        },
        {
            field: "phoneNumber",
            title: t("phoneNumber"),
            filtering: true,
        },
    ]);

    const tableRef = useRef(null);

    const [getContactsPaginated] = useLazyGetContactsPaginatedQuery();

    const handleGetContacts = useCallback(
        async (query) => {
            const limit = query.pageSize;
            const offset = query.page * query.pageSize;
            const search = query.search || null;
            const filters = query.filters
                ? JSON.stringify(
                      query.filters.map((filter) => ({
                          column: filter.column.field,
                          operator: filter.operator,
                          value: filter.value,
                      }))
                  )
                : null;
            const orderByCollection = query.orderByCollection
                ? JSON.stringify(
                      query.orderByCollection.map((orderBy) => ({
                          column: columns.current[orderBy.orderBy].field,
                          orderDirection: orderBy.orderDirection,
                      }))
                  )
                : null;

            const response = await getContactsPaginated({
                limit: limit || 10,
                offset: offset || 0,
                search,
                filters,
                orderByCollection,
            }).unwrap();

            return {
                data: response.contacts,
                page: query.page,
                totalCount: response.totalContacts || 0,
            };
        },
        [columns, getContactsPaginated]
    );

    return (
        <Box m={!isNonMobile ? "1.5rem 1.5rem" : "1.5rem 2.5rem"}>
            <PageHeader title={t("contacts")} />
            <Box
                mt="40px"
                minHeight="70vh"
                display="flex"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                        flexGrow: 1,
                        width: "100%",
                    },
                    "& .MuiDataGrid-cell": {},
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: `${theme.palette.background.J} !important`,
                        color: theme.palette.secondary[100],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.background.A,
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: `${theme.palette.background.J} !important`,
                        color: theme.palette.secondary[100],
                        borderTop: "none",
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.background.I}`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiBox-root": {
                        display: !isNonMobile ? "flex" : undefined,
                        flexDirection: !isNonMobile ? "row" : undefined,
                    },
                    "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller": {
                        backgroundColor: `${theme.palette.background.A} !important`,
                    },
                }}
            >
                <CustomTable
                    tableRef={tableRef}
                    title={t("contactsList")}
                    pageSizeOptions={PAGE_SIZE_OPTIONS}
                    columns={columns.current}
                    getData={handleGetContacts}
                    style={{
                        width: "100%",
                        padding: "0 10px 0 10px",
                        margin: "0 2% 1% 2%",
                        overflow: !isNonMobile ? "auto" : null,
                    }}
                    onRefresh={() =>
                        tableRef.current && tableRef.current.onQueryChange()
                    }
                />
            </Box>
        </Box>
    );
};

export default ContactsList;

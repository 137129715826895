import { useState, useEffect, useRef } from "react";
import {
    useGetCostCategoryFirstTypesQuery,
    useGetCostCategorySecondTypesQuery,
    useGetCostCategoryByIdQuery,
    useUpdateCostCategoryMutation,
    useDeleteCostCategoryByIdMutation,
    useGetCostCategoryCategoriesQuery,
} from "./costCategoriesApiSlice";
import PageHeader from "components/PageHeader";
import LoadingSpinner from "components/LoadingSpinner";
import {
    Box,
    TextField,
    Typography,
    useTheme,
    Alert,
    useMediaQuery,
    Autocomplete,
    FormControl,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";
import { useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useGetVehiclesQuery } from "features/vehicles/vehiclesApiSlice";
import { useGetAccomodationsQuery } from "features/accomodations/accomodationsApiSlice";
import { SETTINGS } from "helpers/settings";
import { useGetNotOfficeEmployeesQuery } from "features/employees/employeesApiSlice";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useGetAllPrivateWorkPlanningsQuery } from "features/workPlanning/workPlanningsApiSlice";

const EditCostCategoryForm = () => {
    // #region Setups

    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate(); /* Set the Navigation Service */
    const location = useLocation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const nameRef = useRef(); /* User Ref to set the focus to the user input */

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMsg(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
        useState(false);

    const handleOpenDeleteConfirmationDialog = () => {
        setOpenDeleteConfirmationDialog(true);
    };

    const handleCloseDeleteConfirmationDialog = () => {
        setOpenDeleteConfirmationDialog(false);
    };

    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [selectedAccomodation, setSelectedAccomodation] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedWorkPlanning, setSelectedWorkPlanning] = useState(null);

    //#endregion Setups

    const { id } = useParams();

    const { data: workPlannings, isLoading: isLoadingWorkPlannings } =
        useGetAllPrivateWorkPlanningsQuery();

    const {
        data: costCategoryCategories,
        isLoading: isLoadingCategoryCategories,
    } = useGetCostCategoryCategoriesQuery();

    const { data: vehicles, isLoading: isLoadingVehicles } =
        useGetVehiclesQuery();

    const { data: accomodations, isLoading: isLoadingAccomodations } =
        useGetAccomodationsQuery();

    const { data: employees, isLoading: isLoadingEmployees } =
        useGetNotOfficeEmployeesQuery();

    const {
        data: costCategoryFirstTypes,
        isLoading: isLoadingcostCategoryFirstTypes,
    } = useGetCostCategoryFirstTypesQuery();

    const {
        data: costCategorySecondTypes,
        isLoading: isLoadingCostCategorySecondTypes,
    } = useGetCostCategorySecondTypesQuery();

    const { data: costCategory, isLoading: isLoadingGetCostCategory } =
        useGetCostCategoryByIdQuery(id);

    const [updateCostCategory, { isLoading: isLoadingUpdateCostCategory }] =
        useUpdateCostCategoryMutation();

    const [deleteCostCategory, { isLoading: isLoadingDeleteCostCategory }] =
        useDeleteCostCategoryByIdMutation();

    const costCategorySchema = yup.object().shape({
        costCategoryCategoryId: yup.string().required(t("requiredField")),
        costCategoryFirstTypeId: yup.string().required(t("requiredField")),
        costCategorySecondTypeId: yup.string().required(t("requiredField")),
        description: yup.string().notRequired(),
        monthCost: yup.date().notRequired(),
        cost: yup
            .number(t("invalidValue"))
            .required(t("requiredField"))
            .typeError(t("invalidValue")),
    });

    const initialValuesCostCategory = {
        costCategoryCategoryId: "",
        costCategoryFirstTypeId: "",
        costCategorySecondTypeId: "",
        description: "",
        cost: "",
        monthCost: null,
    };

    useEffect(() => {
        if (selectedAccomodation) {
            setSelectedVehicle(null);
            setSelectedEmployee(null);
            setSelectedWorkPlanning(null);
        }
    }, [selectedAccomodation]);

    useEffect(() => {
        if (selectedVehicle) {
            setSelectedAccomodation(null);
            setSelectedEmployee(null);
            setSelectedWorkPlanning(null);
        }
    }, [selectedVehicle]);

    useEffect(() => {
        if (selectedEmployee) {
            setSelectedVehicle(null);
            setSelectedAccomodation(null);
            setSelectedWorkPlanning(null);
        }
    }, [selectedEmployee]);

    useEffect(() => {
        if (selectedWorkPlanning) {
            setSelectedAccomodation(null);
            setSelectedEmployee(null);
            setSelectedVehicle(null);
        }
    }, [selectedWorkPlanning]);

    useEffect(() => {
        if (costCategory) {
            setSelectedAccomodation(costCategory.accomodation);
            setSelectedVehicle(costCategory.vehicle);
            setSelectedEmployee(costCategory.employee);
            setSelectedWorkPlanning(costCategory.workPlanning);
        }
    }, [costCategory]);

    const handleFormSubmit = async (values, onSubmitProps) => {
        try {
            if (!values.monthCost || values.monthCost === "") {
                handleSnackbarOpen(t("requiredMonthCost"), "error");
            } else {
                const costCategoryToUpdate = {
                    id: id,
                    costCategoryCategoryId: values.costCategoryCategoryId,
                    costCategoryFirstTypeId: values.costCategoryFirstTypeId,
                    costCategorySecondTypeId: values.costCategorySecondTypeId,
                    vehicleId: selectedVehicle ? selectedVehicle.id : null,
                    accomodationId: selectedAccomodation
                        ? selectedAccomodation.id
                        : null,
                    employeeId: selectedEmployee ? selectedEmployee.id : null,
                    workPlanningId: selectedWorkPlanning
                        ? selectedWorkPlanning.id
                        : null,
                    description: values.description,
                    cost: values.cost,
                    monthCost: values.monthCost,
                };

                const { codeResult } = await updateCostCategory(
                    costCategoryToUpdate
                ).unwrap();

                if (
                    codeResult !==
                    RESPONSES.costCategory.sucessToUpdateCostCategory
                ) {
                    if (
                        codeResult ===
                        RESPONSES.costCategory.invalidToUpdateCostCategory
                    ) {
                        handleSnackbarOpen(
                            t("invalidToUpdateCostCategory"),
                            "error"
                        );
                    } else {
                        handleSnackbarOpen(
                            t("errorUpdateCostCategory"),
                            "error"
                        );
                    }
                } else {
                    handleSnackbarOpen(
                        t("sucessToUpdateCostCategory"),
                        "success"
                    );
                }
            }
        } catch (err) {
            handleSnackbarOpen(t("errorUpdateCostCategory"), "error");
        }
    };

    const handleDeleteCostCategory = async () => {
        setOpenDeleteConfirmationDialog(false);

        try {
            const { codeResult } = await deleteCostCategory(id).unwrap();

            if (
                codeResult !== RESPONSES.costCategory.sucessToDeleteCostCategory
            ) {
                if (
                    codeResult ===
                    RESPONSES.costCategory.invalidToDeleteCostCategory
                ) {
                    handleSnackbarOpen(
                        t("invalidToDeleteCostCategory"),
                        "error"
                    );
                } else {
                    handleSnackbarOpen(t("errorDeleteCostCategory"), "error");
                }
            } else {
                navigate(`/${location.pathname.split("/")[1]}`);
            }
        } catch (err) {
            handleSnackbarOpen(t("errorDeleteCostCategory"), "error");
        }
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={costCategory || initialValuesCostCategory}
            validationSchema={costCategorySchema}
            enableReinitialize={true}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    {isLoadingGetCostCategory ||
                    isLoadingUpdateCostCategory ||
                    isLoadingDeleteCostCategory ? (
                        <LoadingSpinner />
                    ) : null}
                    <Box
                        m={!isNonMobile ? "0" : "1.5rem 2.5rem"}
                        display={!isNonMobile ? "flex" : "grid"}
                        gridTemplateRows={
                            !isNonMobile ? undefined : "repeat(4, auto)"
                        }
                        flexDirection={!isNonMobile ? "column" : undefined}
                        gap="20px"
                        borderRadius="5px"
                        p="2rem"
                    >
                        <PageHeader title={values.name} subtitle="" />

                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("costCategoryInformation")}
                            </Typography>
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingCategoryCategories}
                                    options={costCategoryCategories || []}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                        (costCategoryCategories &&
                                            costCategoryCategories.find(
                                                (costCategoryCategory) =>
                                                    costCategoryCategory.id ===
                                                    values.costCategoryCategoryId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "costCategoryCategoryId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                    }}
                                    onBlur={handleBlur(
                                        "costCategoryCategoryId"
                                    )}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t(
                                                    "costCategoryCategory"
                                                )}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.costCategoryCategoryId
                                                    ) &&
                                                    Boolean(
                                                        errors.costCategoryCategoryId
                                                    )
                                                }
                                                helperText={
                                                    touched.costCategoryCategoryId &&
                                                    errors.costCategoryCategoryId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />
                                <TextField
                                    variant="outlined"
                                    label={t("description")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.description}
                                    name="description"
                                    error={
                                        Boolean(touched.description) &&
                                        Boolean(errors.description)
                                    }
                                    helperText={
                                        touched.description &&
                                        errors.description
                                    }
                                    sx={{ width: "100%" }}
                                    multiline
                                />
                                {(values.costCategoryCategoryId ===
                                    SETTINGS.CostCategoryCategoryId.Fuel ||
                                    values.costCategoryCategoryId ===
                                        SETTINGS.CostCategoryCategoryId.Toll ||
                                    values.costCategoryCategoryId ===
                                        SETTINGS.CostCategoryCategoryId
                                            .CarMaintenance) && (
                                    <Autocomplete
                                        variant="outlined"
                                        loading={isLoadingVehicles}
                                        options={vehicles || []}
                                        getOptionLabel={(option) =>
                                            option
                                                ? option.licensePlateNumber
                                                : null
                                        }
                                        value={
                                            (vehicles &&
                                                selectedVehicle &&
                                                vehicles.find(
                                                    (vehicle) =>
                                                        vehicle.id ===
                                                        selectedVehicle.id
                                                )) ||
                                            null
                                        }
                                        // value={selectedVehicle}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setSelectedVehicle(newValue);
                                            } else {
                                                setSelectedVehicle(null);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <FormControl fullWidth>
                                                <TextField
                                                    label={t("vehicle")}
                                                    {...params}
                                                />
                                            </FormControl>
                                        )}
                                    />
                                )}

                                {(values.costCategoryCategoryId ===
                                    SETTINGS.CostCategoryCategoryId.Water ||
                                    values.costCategoryCategoryId ===
                                        SETTINGS.CostCategoryCategoryId
                                            .Electricity ||
                                    values.costCategoryCategoryId ===
                                        SETTINGS.CostCategoryCategoryId.Gas ||
                                    values.costCategoryCategoryId ===
                                        SETTINGS.CostCategoryCategoryId
                                            .Repair ||
                                    values.costCategoryCategoryId ===
                                        SETTINGS.CostCategoryCategoryId
                                            .GeneralAccomodationExpenses) && (
                                    <Autocomplete
                                        variant="outlined"
                                        loading={isLoadingAccomodations}
                                        options={accomodations || []}
                                        getOptionLabel={(option) =>
                                            option ? option.address : null
                                        }
                                        value={
                                            (accomodations &&
                                                selectedAccomodation &&
                                                accomodations.find(
                                                    (accomodation) =>
                                                        accomodation.id ===
                                                        selectedAccomodation.id
                                                )) ||
                                            null
                                        }
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setSelectedAccomodation(
                                                    newValue
                                                );
                                            } else {
                                                setSelectedAccomodation(null);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <FormControl fullWidth>
                                                <TextField
                                                    label={t("accomodation")}
                                                    {...params}
                                                />
                                            </FormControl>
                                        )}
                                    />
                                )}

                                {values.costCategoryCategoryId ===
                                    SETTINGS.CostCategoryCategoryId
                                        .ExtraCost && (
                                    <Autocomplete
                                        variant="outlined"
                                        loading={isLoadingEmployees}
                                        options={employees || []}
                                        getOptionLabel={(option) =>
                                            option ? option.name : null
                                        }
                                        value={
                                            (employees &&
                                                selectedEmployee &&
                                                employees.find(
                                                    (employee) =>
                                                        employee.id ===
                                                        selectedEmployee.id
                                                )) ||
                                            null
                                        }
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setSelectedEmployee(newValue);
                                            } else {
                                                setSelectedEmployee(null);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <FormControl fullWidth>
                                                <TextField
                                                    label={t("employee")}
                                                    {...params}
                                                />
                                            </FormControl>
                                        )}
                                    />
                                )}

                                {values.costCategoryCategoryId ===
                                    SETTINGS.CostCategoryCategoryId
                                        .WorkCost && (
                                    <Autocomplete
                                        variant="outlined"
                                        loading={isLoadingWorkPlannings}
                                        options={workPlannings || []}
                                        getOptionLabel={(option) =>
                                            option
                                                ? option.name +
                                                  " - " +
                                                  option.work.name
                                                : null
                                        }
                                        value={
                                            (workPlannings &&
                                                selectedWorkPlanning &&
                                                workPlannings.find(
                                                    (workPlanning) =>
                                                        workPlanning.id ===
                                                        selectedWorkPlanning.id
                                                )) ||
                                            null
                                        }
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setSelectedWorkPlanning(
                                                    newValue
                                                );
                                            } else {
                                                setSelectedWorkPlanning(null);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <FormControl fullWidth>
                                                <TextField
                                                    label={t("planningWork")}
                                                    {...params}
                                                />
                                            </FormControl>
                                        )}
                                    />
                                )}

                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingcostCategoryFirstTypes}
                                    options={costCategoryFirstTypes || []}
                                    getOptionLabel={(option) => option.type}
                                    value={
                                        (costCategoryFirstTypes &&
                                            costCategoryFirstTypes.find(
                                                (costCategoryFirstType) =>
                                                    costCategoryFirstType.id ===
                                                    values.costCategoryFirstTypeId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "costCategoryFirstTypeId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                    }}
                                    onBlur={handleBlur(
                                        "costCategoryFirstTypeId"
                                    )}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("costCategoryType")}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.costCategoryFirstTypeId
                                                    ) &&
                                                    Boolean(
                                                        errors.costCategoryFirstTypeId
                                                    )
                                                }
                                                helperText={
                                                    touched.costCategoryFirstTypeId &&
                                                    errors.costCategoryFirstTypeId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />

                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingCostCategorySecondTypes}
                                    options={costCategorySecondTypes || []}
                                    getOptionLabel={(option) => option.type}
                                    value={
                                        (costCategorySecondTypes &&
                                            costCategorySecondTypes.find(
                                                (costCategorySecondType) =>
                                                    costCategorySecondType.id ===
                                                    values.costCategorySecondTypeId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "costCategorySecondTypeId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                    }}
                                    onBlur={handleBlur(
                                        "costCategorySecondTypeId"
                                    )}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("costCategorySubType")}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.costCategorySecondTypeId
                                                    ) &&
                                                    Boolean(
                                                        errors.costCategorySecondTypeId
                                                    )
                                                }
                                                helperText={
                                                    touched.costCategorySecondTypeId &&
                                                    errors.costCategorySecondTypeId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="pt"
                                >
                                    <DatePicker
                                        label={t("costDate")}
                                        value={dayjs(values.monthCost)}
                                        format="DD/MM/YYYY"
                                        onChange={(value) =>
                                            setFieldValue("monthCost", value)
                                        }
                                    />
                                </LocalizationProvider>
                                <TextField
                                    label={t("monthlyCost")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.cost}
                                    name="cost"
                                    error={
                                        Boolean(touched.cost) &&
                                        Boolean(errors.cost)
                                    }
                                    InputProps={{
                                        endAdornment: "€",
                                    }}
                                    helperText={touched.cost && errors.cost}
                                    sx={{ width: "100%" }}
                                />
                            </Box>
                        </Box>

                        {/* BUTTONS */}
                        <Box display="flex" gap="10px">
                            <LoadingButton
                                fullWidth
                                type="button"
                                onClick={handleOpenDeleteConfirmationDialog}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                            >
                                {t("deleteCostCategoryButton")}
                            </LoadingButton>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                loading={isLoadingUpdateCostCategory}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                            >
                                {t("editCostCategory")}
                            </LoadingButton>
                        </Box>
                    </Box>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity={snackbarSeverity}
                            sx={{
                                mt: "10px",
                                backgroundColor:
                                    snackbarSeverity === "success"
                                        ? "#10781F"
                                        : "#ff000082",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {snackbarMsg}
                        </Alert>
                    </Snackbar>
                    <Dialog
                        open={openDeleteConfirmationDialog}
                        onClose={handleCloseDeleteConfirmationDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("deleteCostCategoryConfirmationTitle")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t("deleteCostCategoryConfirmationMessage")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <LoadingButton
                                sx={{
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                                onClick={handleCloseDeleteConfirmationDialog}
                            >
                                {t("noMessage")}
                            </LoadingButton>
                            <LoadingButton
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                                // isLoading={isLoadingDeleteCostCategory}
                                onClick={handleDeleteCostCategory}
                                autoFocus
                            >
                                {t("yesMessage")}
                            </LoadingButton>
                        </DialogActions>
                    </Dialog>
                </form>
            )}
        </Formik>
    );
};

export default EditCostCategoryForm;

import { useState, useRef, useEffect } from "react";
import {
    useGetSalaryTypesQuery,
    useGetEmployeeByIdQuery,
    useUpdateEmployeeMutation,
    useDeleteEmployeeByIdMutation,
    useGetEmployeePlanningsByEmployeeIdQuery,
} from "./employeesApiSlice";
import { useGetAllEmployeeCategoriesQuery } from "features/employeeCategories/employeeCategoriesApiSlice";
import PageHeader from "components/PageHeader";
import LoadingSpinner from "components/LoadingSpinner";
import {
    Box,
    Button,
    TextField,
    Typography,
    useTheme,
    Alert,
    IconButton,
    Autocomplete,
    Card,
    CardContent,
    FormControl,
    useMediaQuery,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DeleteOutlined } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";
import FlexBetween from "components/FlexBetween";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import Dropzone from "react-dropzone";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { format } from "date-fns";
import DataGridCustomWithoutToolbar from "components/DataGridCustomWithoutToolbar";
import { useGetSubContractorsQuery } from "features/subContractors/subContractorsApiSlice";
import {
    useDeleteAttachmentMutation,
    useUploadAttachmentMutation,
} from "features/attachments/attachmentsApiSlice";

const EditEmployeeForm = () => {
    // #region Setups

    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate(); /* Set the Navigation Service */
    const location = useLocation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const nameRef = useRef(); /* User Ref to set the focus to the user input */

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMsg(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const [files, setFiles] = useState([]);
    const [employeePlannings, setEmployeePlannings] = useState([]);

    const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
        useState(false);

    const handleOpenDeleteConfirmationDialog = () => {
        setOpenDeleteConfirmationDialog(true);
    };

    const handleCloseDeleteConfirmationDialog = () => {
        setOpenDeleteConfirmationDialog(false);
    };

    //#endregion Setups

    // #region employees

    const employeePlanningsColumns = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
            valueGetter: (params) => params.row.workPlanning.work.name,
        },
        {
            field: "address",
            headerName: t("address"),
            valueGetter: (params) => params.row.workPlanning.work.address,
            flex: 1,
        },
        {
            field: "startDate",
            headerName: t("startDate"),
            flex: 1,
            valueGetter: (params) => {
                const startDate = params.row.startDate;
                if (startDate !== null) {
                    return format(new Date(startDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "endDate",
            headerName: t("endDate"),
            flex: 1,
            valueGetter: (params) => {
                const endDate = params.row.endDate;
                if (endDate !== null) {
                    return format(new Date(endDate), "dd/MM/yyyy");
                }
            },
        },
    ];

    const employeePlanningsColumnsMobile = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
            valueGetter: (params) => params.row.workPlanning.work.name,
        },
        {
            field: "startDate",
            headerName: t("startDate"),
            flex: 1,
            valueGetter: (params) => {
                const startDate = params.row.startDate;
                if (startDate !== null) {
                    return format(new Date(startDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "endDate",
            headerName: t("endDate"),
            flex: 1,
            valueGetter: (params) => {
                const endDate = params.row.endDate;
                if (endDate !== null) {
                    return format(new Date(endDate), "dd/MM/yyyy");
                }
            },
        },
    ];

    // region Get Employee Categories
    const { data: employeeCategories, isLoading: isLoadingEmployeeCategory } =
        useGetAllEmployeeCategoriesQuery();
    //end region employee categories

    // region Get Salary Types
    const { data: salaryTypes, isLoading: isLoadingSalaryType } =
        useGetSalaryTypesQuery();
    // end region Get Salary Types

    const { data: subContractors, isLoading: isLoadingSubContractors } =
        useGetSubContractorsQuery();

    // #region Employee

    const { id } =
        useParams(); /* Get the Employee by Id from the route Params */

    const { data: employee, isLoading: isLoadingGetEmployee } =
        useGetEmployeeByIdQuery(id); /* Set the Get Employee by Id Mutation */

    const {
        data: employeePlanningByEmployeeId,
        isLoading: isLoadingGetEmployeePlanningByEmployeeId,
    } = useGetEmployeePlanningsByEmployeeIdQuery(id);

    const [updateEmployee, { isLoading: isLoadingUpdateEmployee }] =
        useUpdateEmployeeMutation(); /* Set the Update Client Mutation */

    const [deleteEmployee, { isLoading: isLoadingDeleteEmployee }] =
        useDeleteEmployeeByIdMutation(); /* Set the Delete Employee Mutation */

    // se o funcionário está em baixa médica
    const [isSickLeave, setIsSickLeave] = useState(false);

    const setIsSickLeaveState = () => {
        if (isSickLeave) {
            setIsSickLeave(false);
        } else {
            setIsSickLeave(true);
            setNotWorking(false);
        }
    };

    // se o funcionário estiver sem trabalhar
    const [notWorking, setNotWorking] = useState(false);

    const setNotWorkingState = () => {
        if (notWorking) {
            setNotWorking(false);
        } else {
            setNotWorking(true);
            setIsSickLeave(false);
        }
    };

    useEffect(() => {
        if (employeePlanningByEmployeeId) {
            setEmployeePlannings(employeePlanningByEmployeeId);
        }
    }, [employeePlanningByEmployeeId]);

    useEffect(() => {
        if (employee) {
            setIsSickLeave(employee.isSickLeave);
            setNotWorking(employee.notWorking);

            const processAttachments = async () => {
                const attachmentPromises = employee.attachments.map(
                    async (attachment) => {
                        let file = "";
                        if (!attachment.preSignedUrl) {
                            file = await base64ToFile(
                                attachment.content,
                                attachment.fileName,
                                attachment.fileType
                            );
                        }
                        return { ...attachment, file };
                    }
                );

                try {
                    const attachments = await Promise.all(attachmentPromises);
                    setFiles(
                        attachments.filter(
                            (attachment) => !!attachment.fileName.split("/")[2]
                        )
                    );
                } catch (error) {
                    console.error("Error processing attachments:", error);
                }
            };

            processAttachments();
        }
    }, [employee]);

    function base64ToFile(base64Data, fileName, mimeType) {
        const base64Content = base64Data.split(",")[1];

        const uint8Array = new Uint8Array(
            atob(base64Content)
                .split("")
                .map((char) => char.charCodeAt(0))
        );

        const blob = new Blob([uint8Array], { type: mimeType });

        const file = new File([blob], fileName, { type: mimeType });

        return file;
    }

    const employeeSchema = yup.object().shape({
        name: yup.string().required(t("requiredField")),
        address: yup.string().required(t("requiredField")),
        email: yup.string().email(t("invalidEmail")),
        nInternal: yup
            .number(t("invalidValue"))
            .positive(t("invalidValue"))
            .required(t("requiredField"))
            .typeError(t("invalidValue")),
        iban: yup.string().required(t("requiredField")),
        phoneNumber: yup.string().required(t("requiredField")),
        birthDate: yup.date().notRequired(),
        epiDate: yup.date().notRequired(),
        skillsSheetDate: yup.date().notRequired(),
        cost: yup
            .number(t("invalidValue"))
            .positive(t("invalidValue"))
            .required(t("requiredField"))
            .typeError(t("invalidValue")),
        categoryId: yup.string().required(t("requiredField")),
        salaryTypeId: yup.string().required(t("requiredField")),
    }); /* Set the employee Schema */

    const initialValuesEmployee = {
        name: "",
        address: "",
        email: "",
        nInternal: "",
        iban: "",
        phoneNumber: "",
        cost: "",
        birthDate: null,
        epiDate: null,
        skillsSheetDate: null,
        categoryId: "",
        salaryTypeId: "",
        subContractorId: null,
        accomodation: {
            address: "",
        },
        endSickLeaveDate: null,
        startSickLeaveDate: null,
        endNotWorkingDate: null,
        startNotWorkingDate: null,
        attachments: [],
        works: [],
    };

    //#endregion Employee

    const convertToBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handleRemoveFile = (event, file) => {
        event.stopPropagation();
        const updatedFiles = files.filter((f) => f !== file);
        setFiles(updatedFiles);
    };

    const onDropFiles = (acceptedFiles) => {
        const updatedFiles = [...files];

        acceptedFiles.forEach((file) => {
            const existingFile = updatedFiles.find(
                (f) => f.fileName === file.name
            );
            if (!existingFile) {
                updatedFiles.push({
                    fileName: file.name,
                    fileType: file.type,
                    preSignedUrl: "",
                    content: "",
                    file: file,
                });
            } else {
                handleSnackbarOpen(
                    t("file") + file.name + t("exists"),
                    "error"
                );
            }
        });

        setFiles(updatedFiles);
    };

    const handleFilePreview = async (event, file) => {
        event.stopPropagation();
        if (file.preSignedUrl) {
            try {
                const newWindow = window.open(file.preSignedUrl, "_blank");
                if (newWindow) newWindow.opener = null;
            } catch (err) {
                handleSnackbarOpen(t("errorWhenDownload"), "error");
            }
        } else {
            try {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(file.file);
                link.download = file.fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (err) {
                handleSnackbarOpen(t("errorWhenDownload"), "error");
            }
        }
    };

    const [uploadAttachment, { isLoading: isLoadingUploadAttachment }] =
        useUploadAttachmentMutation();

    const [deleteAttachment, { isLoading: isLoadingDeleteAttachment }] =
        useDeleteAttachmentMutation();

    const [loadingFiles, setLoadingFiles] = useState(false);

    const [isToUpdateEmployee, setIsToUpdateEmployee] = useState(false);

    const handleFormSubmit = async (values, onSubmitProps) => {
        try {
            if (!values.birthDate || values.birthDate === "") {
                handleSnackbarOpen(t("requiredEmployeeDates"), "error");
            } else {
                if (
                    (isSickLeave === true &&
                        (values.startSickLeaveDate === null ||
                            values.endSickLeaveDate === null ||
                            dayjs(values.endSickLeaveDate).isBefore(
                                dayjs(values.startSickLeaveDate)
                            ))) ||
                    (notWorking === true &&
                        (values.startNotWorkingDate === null ||
                            values.endNotWorkingDate === null ||
                            dayjs(values.endNotWorkingDate).isBefore(
                                dayjs(values.startNotWorkingDate)
                            )))
                ) {
                    handleSnackbarOpen(
                        t("invalidToUpdateEmployeeBecauseInvalidDates"),
                        "error"
                    );
                } else {
                    let isToReload = false;
                    let attachments = [];
                    setLoadingFiles(true);
                    try {
                        // add only the new attachments
                        const _files = files.filter((file) => !!file.file);

                        await Promise.all(
                            _files.map(async (file) => {
                                if (
                                    values.attachments.length === 0 ||
                                    values.attachments.filter(
                                        (a) => a.fileName === file.fileName
                                    ).length === 0
                                ) {
                                    let attachment = {
                                        id: 0,
                                        fileName: file.fileName,
                                        content: file.file,
                                        fileType: file.fileType,
                                    };
                                    attachments.push(attachment);
                                }
                            })
                        );

                        let newAttachments = [
                            ...attachments.filter((a) => a.id === 0),
                        ];

                        let attachmentsToAdd = {
                            attachments: [...newAttachments],
                        };

                        // region delete attachments
                        let attachmentsToDelete = [];
                        values.attachments.forEach((attachment) => {
                            const _attachment = {
                                ...attachment,
                                fileName: attachment.fileName.split("/")[2],
                                fileType: attachment.fileType || "image/jpg",
                            };
                            if (
                                files.filter(
                                    (f) => f.fileName === attachment.fileName
                                ).length === 0
                            ) {
                                delete _attachment.preSignedUrl;
                                if (attachment.fileName.split("/")[2]) {
                                    attachmentsToDelete.push(_attachment);
                                }
                            }
                        });

                        const createPromises = [];

                        if (attachmentsToAdd.attachments.length > 0) {
                            isToReload = true;
                            attachmentsToAdd.attachments.forEach(
                                (attachment) => {
                                    let addAttachmentsDTO = {
                                        Id: parseInt(id),
                                        Attachment: attachment,
                                        EntityName: "Employees",
                                    };

                                    // Store the promise in the array
                                    const promise =
                                        uploadAttachment(
                                            addAttachmentsDTO
                                        ).unwrap();

                                    createPromises.push(promise);
                                }
                            );

                            await Promise.all(createPromises).then(
                                async (results) => {
                                    // Create an array of promises from results
                                    const processPromises = results.map(
                                        async ({
                                            codeResult,
                                            signedUrl,
                                            expiresIn,
                                            fileName,
                                        }) => {
                                            if (
                                                codeResult !==
                                                RESPONSES.client
                                                    .sucessToAddAttachments
                                            ) {
                                                handleSnackbarOpen(
                                                    t("anErrorOcurred"),
                                                    "error"
                                                );
                                            } else {
                                                const attachment =
                                                    attachmentsToAdd.attachments.find(
                                                        (attachment) =>
                                                            attachment.fileName ===
                                                            fileName
                                                    );

                                                return fetch(signedUrl, {
                                                    method: "PUT",
                                                    body: attachment.content,
                                                    headers: {
                                                        "Content-Type":
                                                            "multipart/form-data",
                                                        mimeType:
                                                            attachment.fileType,
                                                    },
                                                });
                                            }
                                        }
                                    );

                                    // Wait for all processes to complete
                                    await Promise.all(processPromises);
                                    handleSnackbarOpen(t("sucess"), "success");
                                }
                            );
                        }

                        const deletePromises = [];

                        if (attachmentsToDelete.length > 0) {
                            isToReload = true;
                            attachmentsToDelete.forEach((attachment) => {
                                let addAttachmentsDTO = {
                                    Id: parseInt(id),
                                    Attachment: attachment,
                                    EntityName: "Employees",
                                };
                                // Store the promise in the array
                                const promise =
                                    deleteAttachment(
                                        addAttachmentsDTO
                                    ).unwrap();

                                deletePromises.push(promise);
                            });

                            await Promise.all(deletePromises).then(
                                (results) => {
                                    // Handle the results after all promises are resolved
                                    results.forEach(({ codeResult }) => {
                                        if (
                                            codeResult !==
                                            RESPONSES.client
                                                .sucessToAddAttachments
                                        ) {
                                            handleSnackbarOpen(
                                                t("anErrorOcurred"),
                                                "error"
                                            );
                                        } else {
                                            handleSnackbarOpen(
                                                t("sucess"),
                                                "success"
                                            );
                                        }
                                    });
                                }
                            );
                        }
                        // window.location.reload();
                    } catch (err) {
                        handleSnackbarOpen(t("anErrorOcurred"), "error");
                    }
                    setLoadingFiles(false);
                    if (isToUpdateEmployee === true) {
                        let updatedEmployee = {
                            ...values,
                            isSickLeave: isSickLeave,
                            notWorking: notWorking,
                            attachments: null,
                        };

                        if (!isSickLeave) {
                            // Se isSickLeave for falso, redefine as datas como null
                            updatedEmployee.startSickLeaveDate = null;
                            updatedEmployee.endSickLeaveDate = null;
                        }

                        if (!notWorking) {
                            updatedEmployee.startNotWorkingDate = null;
                            updatedEmployee.endNotWorkingDate = null;
                        }

                        if (updatedEmployee.subContractorId === "") {
                            updatedEmployee.subContractorId = null;
                        }

                        const { codeResult } = await updateEmployee(
                            updatedEmployee
                        ).unwrap();

                        if (
                            codeResult !==
                            RESPONSES.employee.successToUpdateEmployee
                        ) {
                            if (
                                codeResult ===
                                RESPONSES.employee.invalidToUpdateEmployee
                            ) {
                                handleSnackbarOpen(
                                    t("invalidToUpdateEmployee"),
                                    "error"
                                );
                            } else if (
                                codeResult ===
                                RESPONSES.employee.invalidEmployee
                            ) {
                                handleSnackbarOpen(
                                    t("invalidEmployee"),
                                    "error"
                                );
                            } else if (
                                codeResult ===
                                RESPONSES.employee
                                    .invalidToUpdateEmployeeBecauseSickLeave
                            ) {
                                handleSnackbarOpen(
                                    t(
                                        "invalidToUpdateEmployeeBecauseSickLeave"
                                    ),
                                    "error"
                                );
                            } else {
                                handleSnackbarOpen(
                                    t("errorUpdateEmployee"),
                                    "error"
                                );
                            }
                        } else {
                            handleSnackbarOpen(
                                t("successToUpdateEmployee"),
                                "success"
                            );
                        }
                        setIsToUpdateEmployee(false);
                    } else {
                        if (isToReload === true) {
                            window.location.reload();
                        }
                    }
                }
            }
        } catch (err) {
            handleSnackbarOpen(t("errorUpdateEmployee"), "error");
        }
    };

    const handleDeleteEmployee = async () => {
        setOpenDeleteConfirmationDialog(false);

        try {
            const { codeResult } = await deleteEmployee(id).unwrap();

            if (codeResult !== RESPONSES.employee.successToDeleteEmployee) {
                if (
                    codeResult ===
                    RESPONSES.employee
                        .invalidToDeleteEmployeeBecauseAssignedToWorkPlanning
                ) {
                    handleSnackbarOpen(
                        t(
                            "invalidToDeleteEmployeeBecauseAssignedToWorkPlanning"
                        ),
                        "error"
                    );
                } else if (
                    codeResult === RESPONSES.employee.invalidToDeleteEmployee
                ) {
                    handleSnackbarOpen(t("invalidToDeleteEmployee"), "error");
                } else {
                    handleSnackbarOpen(t("errorDeleteEmployee"), "error");
                }
            } else {
                navigate(`/${location.pathname.split("/")[1]}`);
            }
        } catch (err) {
            handleSnackbarOpen(t("errorDeleteEmployee"), "error");
        }
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={employee || initialValuesEmployee}
            validationSchema={employeeSchema}
            enableReinitialize={true}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    {isLoadingGetEmployee ||
                    isLoadingUpdateEmployee ||
                    loadingFiles ||
                    isLoadingDeleteEmployee ? (
                        <LoadingSpinner />
                    ) : null}
                    <Box
                        m={!isNonMobile ? "0" : "1.5rem 2.5rem"}
                        display={!isNonMobile ? "flex" : "grid"}
                        gridTemplateRows={
                            !isNonMobile ? undefined : "repeat(4, auto)"
                        }
                        flexDirection={!isNonMobile ? "column" : undefined}
                        gap="20px"
                        borderRadius="5px"
                        p="2rem"
                    >
                        <PageHeader title={values.name} subtitle="" />
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Box display="flex" justifyContent="space-between">
                                <Typography
                                    fontWeight="500"
                                    fontSize="20px"
                                    color="primary"
                                    variant="h5"
                                    textAlign="left"
                                >
                                    {t("employeeInformation")}
                                </Typography>
                                <Box>
                                    <FormControlLabel
                                        sx={{ marginLeft: "5px" }}
                                        control={
                                            <Checkbox
                                                checked={notWorking}
                                                onChange={() => {
                                                    setNotWorkingState();
                                                    setIsToUpdateEmployee(true);
                                                }}
                                            />
                                        }
                                        label={t("notWorking")}
                                    />
                                    <FormControlLabel
                                        sx={{ marginLeft: "5px" }}
                                        control={
                                            <Checkbox
                                                checked={isSickLeave}
                                                onChange={() => {
                                                    setIsSickLeaveState();
                                                    setIsToUpdateEmployee(true);
                                                }}
                                            />
                                        }
                                        label={t("sickLeave")}
                                    />
                                </Box>
                            </Box>
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <TextField
                                    variant="outlined"
                                    label={t("name")}
                                    inputRef={nameRef}
                                    onBlur={handleBlur}
                                    onChange={(event) => {
                                        handleChange(event);
                                        setIsToUpdateEmployee(true);
                                    }}
                                    value={values.name}
                                    name="name"
                                    error={
                                        Boolean(touched.name) &&
                                        Boolean(errors.name)
                                    }
                                    helperText={touched.name && errors.name}
                                    sx={{ width: "100%" }}
                                />
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        label={t("birthDate")}
                                        onBlur={handleBlur}
                                        value={dayjs(values.birthDate)}
                                        format="DD/MM/YYYY"
                                        onChange={(value) => {
                                            setIsToUpdateEmployee(true);
                                            setFieldValue("birthDate", value);
                                        }}
                                        error={
                                            Boolean(touched.birthDate) &&
                                            Boolean(errors.birthDate)
                                        }
                                        helperText={
                                            touched.birthDate &&
                                            errors.birthDate
                                        }
                                    />
                                </LocalizationProvider>
                                <TextField
                                    label={t("email")}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    name="email"
                                    onChange={(event) => {
                                        handleChange(event);
                                        setIsToUpdateEmployee(true);
                                    }}
                                    error={
                                        Boolean(touched.email) &&
                                        Boolean(errors.email)
                                    }
                                    helperText={touched.email && errors.email}
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("address")}
                                    onBlur={handleBlur}
                                    value={values.address}
                                    name="address"
                                    onChange={(event) => {
                                        handleChange(event);
                                        setIsToUpdateEmployee(true);
                                    }}
                                    error={
                                        Boolean(touched.address) &&
                                        Boolean(errors.address)
                                    }
                                    helperText={
                                        touched.address && errors.address
                                    }
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("nInternal")}
                                    onBlur={handleBlur}
                                    value={values.nInternal}
                                    name="nInternal"
                                    onChange={(event) => {
                                        handleChange(event);
                                        setIsToUpdateEmployee(true);
                                    }}
                                    error={
                                        Boolean(touched.nInternal) &&
                                        Boolean(errors.nInternal)
                                    }
                                    helperText={
                                        touched.nInternal && errors.nInternal
                                    }
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("iban")}
                                    onBlur={handleBlur}
                                    value={values.iban}
                                    name="iban"
                                    onChange={(event) => {
                                        handleChange(event);
                                        setIsToUpdateEmployee(true);
                                    }}
                                    error={
                                        Boolean(touched.iban) &&
                                        Boolean(errors.iban)
                                    }
                                    helperText={touched.iban && errors.iban}
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("phoneNumber")}
                                    onBlur={handleBlur}
                                    value={values.phoneNumber}
                                    name="phoneNumber"
                                    onChange={(event) => {
                                        handleChange(event);
                                        setIsToUpdateEmployee(true);
                                    }}
                                    error={
                                        Boolean(touched.phoneNumber) &&
                                        Boolean(errors.phoneNumber)
                                    }
                                    helperText={
                                        touched.phoneNumber &&
                                        errors.phoneNumber
                                    }
                                    sx={{ width: "100%" }}
                                />
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    {values.epiDate !== null ? (
                                        <DatePicker
                                            label={t("epiDate")}
                                            onBlur={handleBlur}
                                            value={dayjs(values.epiDate)}
                                            format="DD/MM/YYYY"
                                            onChange={(value) => {
                                                setFieldValue("epiDate", value);
                                                setIsToUpdateEmployee(true);
                                            }}
                                        />
                                    ) : (
                                        <DatePicker
                                            label={t("epiDate")}
                                            onBlur={handleBlur}
                                            value={values.epiDate}
                                            format="DD/MM/YYYY"
                                            onChange={(value) => {
                                                setFieldValue("epiDate", value);
                                                setIsToUpdateEmployee(true);
                                            }}
                                        />
                                    )}
                                    {values.skillsSheetDate !== null ? (
                                        <DatePicker
                                            label={t("skillSheetDate")}
                                            onBlur={handleBlur}
                                            value={dayjs(
                                                values.skillsSheetDate
                                            )}
                                            format="DD/MM/YYYY"
                                            onChange={(value) => {
                                                setIsToUpdateEmployee(true);
                                                setFieldValue(
                                                    "skillsSheetDate",
                                                    value
                                                );
                                            }}
                                        />
                                    ) : (
                                        <DatePicker
                                            label={t("skillSheetDate")}
                                            onBlur={handleBlur}
                                            value={values.skillsSheetDate}
                                            format="DD/MM/YYYY"
                                            onChange={(value) => {
                                                setIsToUpdateEmployee(true);
                                                setFieldValue(
                                                    "skillsSheetDate",
                                                    value
                                                );
                                            }}
                                        />
                                    )}
                                </LocalizationProvider>
                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingSubContractors}
                                    options={subContractors || []}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                        (subContractors &&
                                            subContractors.find(
                                                (subContractorId) =>
                                                    subContractorId.id ===
                                                    values.subContractorId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "subContractorId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                        setIsToUpdateEmployee(true);
                                    }}
                                    onBlur={handleBlur("subContractorId")}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("subContractor")}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.subContractorId
                                                    ) &&
                                                    Boolean(
                                                        errors.subContractorId
                                                    )
                                                }
                                                helperText={
                                                    touched.subContractorId &&
                                                    errors.subContractorId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />
                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingEmployeeCategory}
                                    options={employeeCategories || []}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                        (employeeCategories &&
                                            employeeCategories.find(
                                                (category) =>
                                                    category.id ===
                                                    values.categoryId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "categoryId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                        setIsToUpdateEmployee(true);
                                    }}
                                    onBlur={handleBlur("categoryId")}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("category")}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.categoryId
                                                    ) &&
                                                    Boolean(errors.categoryId)
                                                }
                                                helperText={
                                                    touched.categoryId &&
                                                    errors.categoryId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />
                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingSalaryType}
                                    options={salaryTypes || []}
                                    getOptionLabel={(option) => option.type}
                                    value={
                                        (salaryTypes &&
                                            salaryTypes.find(
                                                (salary) =>
                                                    salary.id ===
                                                    values.salaryTypeId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "salaryTypeId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                        setIsToUpdateEmployee(true);
                                    }}
                                    onBlur={handleBlur("salaryTypeId")}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("salaryType")}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.salaryTypeId
                                                    ) &&
                                                    Boolean(errors.salaryTypeId)
                                                }
                                                helperText={
                                                    touched.salaryTypeId &&
                                                    errors.salaryTypeId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />

                                {values.salaryTypeId === 2 ? (
                                    <TextField
                                        label={t("hourSalary")}
                                        onBlur={handleBlur}
                                        value={values.cost}
                                        name="cost"
                                        onChange={(event) => {
                                            handleChange(event);
                                            setIsToUpdateEmployee(true);
                                        }}
                                        error={
                                            Boolean(touched.cost) &&
                                            Boolean(errors.cost)
                                        }
                                        InputProps={{
                                            endAdornment: "€",
                                        }}
                                        helperText={touched.cost && errors.cost}
                                        sx={{ width: "100%" }}
                                    />
                                ) : (
                                    <TextField
                                        label={t("salary")}
                                        onBlur={handleBlur}
                                        value={values.cost}
                                        name="cost"
                                        onChange={(event) => {
                                            handleChange(event);
                                            setIsToUpdateEmployee(true);
                                        }}
                                        error={
                                            Boolean(touched.cost) &&
                                            Boolean(errors.cost)
                                        }
                                        InputProps={{
                                            endAdornment: "€",
                                        }}
                                        helperText={touched.cost && errors.cost}
                                        sx={{ width: "100%" }}
                                    />
                                )}
                                {isSickLeave === true && (
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        {values.startSickLeaveDate !== null ? (
                                            <DatePicker
                                                label={t("startSickLeave")}
                                                value={dayjs(
                                                    values.startSickLeaveDate
                                                )}
                                                format="DD/MM/YYYY"
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "startSickLeaveDate",
                                                        value
                                                    );
                                                    setIsToUpdateEmployee(true);
                                                }}
                                            />
                                        ) : (
                                            <DatePicker
                                                label={t("startSickLeave")}
                                                value={
                                                    values.startSickLeaveDate
                                                }
                                                format="DD/MM/YYYY"
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "startSickLeaveDate",
                                                        value
                                                    );
                                                    setIsToUpdateEmployee(true);
                                                }}
                                            />
                                        )}

                                        {values.endSickLeaveDate !== null ? (
                                            <DatePicker
                                                label={t("endSickLeave")}
                                                value={dayjs(
                                                    values.endSickLeaveDate
                                                )}
                                                format="DD/MM/YYYY"
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "endSickLeaveDate",
                                                        value
                                                    );
                                                    setIsToUpdateEmployee(true);
                                                }}
                                            />
                                        ) : (
                                            <DatePicker
                                                label={t("endSickLeave")}
                                                value={values.endSickLeaveDate}
                                                format="DD/MM/YYYY"
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "endSickLeaveDate",
                                                        value
                                                    );
                                                    setIsToUpdateEmployee(true);
                                                }}
                                            />
                                        )}
                                    </LocalizationProvider>
                                )}

                                {notWorking === true && (
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        {values.startNotWorkingDate !== null ? (
                                            <DatePicker
                                                label={t("startDate")}
                                                value={dayjs(
                                                    values.startNotWorkingDate
                                                )}
                                                format="DD/MM/YYYY"
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "startNotWorkingDate",
                                                        value
                                                    );
                                                    setIsToUpdateEmployee(true);
                                                }}
                                            />
                                        ) : (
                                            <DatePicker
                                                label={t("startDate")}
                                                value={
                                                    values.startNotWorkingDate
                                                }
                                                format="DD/MM/YYYY"
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "startNotWorkingDate",
                                                        value
                                                    );
                                                    setIsToUpdateEmployee(true);
                                                }}
                                            />
                                        )}

                                        {values.endNotWorkingDate !== null ? (
                                            <DatePicker
                                                label={t("endDate")}
                                                value={dayjs(
                                                    values.endNotWorkingDate
                                                )}
                                                format="DD/MM/YYYY"
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "endNotWorkingDate",
                                                        value
                                                    );
                                                    setIsToUpdateEmployee(true);
                                                }}
                                            />
                                        ) : (
                                            <DatePicker
                                                label={t("endDate")}
                                                value={values.endNotWorkingDate}
                                                format="DD/MM/YYYY"
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "endNotWorkingDate",
                                                        value
                                                    );
                                                    setIsToUpdateEmployee(true);
                                                }}
                                            />
                                        )}
                                    </LocalizationProvider>
                                )}
                            </Box>
                        </Box>

                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("employeeDocumentation")}
                            </Typography>
                            <Dropzone multiple={true} onDrop={onDropFiles}>
                                {({ getRootProps, getInputProps }) => (
                                    <FlexBetween>
                                        <Box
                                            {...getRootProps()}
                                            border={`2px dashed ${theme.palette.primary.main}`}
                                            p="1rem"
                                            width="100%"
                                            display="grid"
                                            gridTemplateColumns={
                                                !isNonMobile
                                                    ? "repeat(1, 1fr)"
                                                    : "repeat(5, auto)"
                                            }
                                            gap="10px"
                                            borderRadius="5px"
                                            sx={{
                                                "&:hover": {
                                                    cursor: "pointer",
                                                },
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            {files.length === 0 ? (
                                                <p>
                                                    {t("dropzoneAddYouFiles")}
                                                </p>
                                            ) : (
                                                files.map((file) => (
                                                    <Card
                                                        sx={{
                                                            backgroundColor:
                                                                theme.palette
                                                                    .background
                                                                    .D,
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            gap: "10px",
                                                            p: "15px 10px 15px 0px",
                                                            "& .MuiCardContent-root:last-child":
                                                                {
                                                                    paddingBottom:
                                                                        "20px",
                                                                },
                                                        }}
                                                        key={file.fileName}
                                                        onClick={(event) =>
                                                            handleFilePreview(
                                                                event,
                                                                file
                                                            )
                                                        }
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                // pl: 1,
                                                                // pb: 1,
                                                            }}
                                                        >
                                                            <IconButton
                                                                onClick={(
                                                                    event
                                                                ) =>
                                                                    handleRemoveFile(
                                                                        event,
                                                                        file
                                                                    )
                                                                }
                                                            >
                                                                <DeleteOutlined />
                                                            </IconButton>
                                                        </Box>
                                                        <CardContent
                                                            sx={{
                                                                p: "0px !important",
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: 14,
                                                                }}
                                                                color="text.secondary"
                                                            >
                                                                {file.fileName.split(
                                                                    "/"
                                                                )[2]
                                                                    ? file.fileName.split(
                                                                          "/"
                                                                      )[2]
                                                                    : file.fileName}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                ))
                                            )}
                                        </Box>
                                    </FlexBetween>
                                )}
                            </Dropzone>
                        </Box>
                        <Box
                            display="flex"
                            marginTop="10px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("works")}
                            </Typography>
                            <Box
                                display="flex"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        flexGrow: 1,
                                        width: "100%",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        // borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: `${theme.palette.background.J} !important`,
                                        color: theme.palette.secondary[100],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor:
                                            theme.palette.background.A,
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        backgroundColor: `${theme.palette.background.J} !important`,
                                        color: theme.palette.secondary[100],
                                        borderTop: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text":
                                        {
                                            color: `${theme.palette.background.I}`,
                                        },
                                    "& .MuiDataGrid-toolbarContainer .MuiBox-root":
                                        {
                                            display: !isNonMobile
                                                ? "flex"
                                                : undefined,
                                            flexDirection: !isNonMobile
                                                ? "row"
                                                : undefined,
                                        },
                                    "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                        {
                                            backgroundColor: `${theme.palette.background.A} !important`,
                                        },
                                }}
                            >
                                <DataGrid
                                    autoHeight
                                    loading={
                                        isLoadingGetEmployeePlanningByEmployeeId ||
                                        !employeePlannings
                                    }
                                    getRowId={(row) => row.id}
                                    rows={employeePlannings || []}
                                    columns={
                                        !isNonMobile
                                            ? employeePlanningsColumnsMobile
                                            : employeePlanningsColumns
                                    }
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    slots={{
                                        toolbar: DataGridCustomWithoutToolbar,
                                    }}
                                    disableRowSelectionOnClick
                                />
                            </Box>
                        </Box>
                        {/* BUTTONS */}
                        <Box display="flex" gap="10px">
                            <LoadingButton
                                fullWidth
                                type="button"
                                onClick={handleOpenDeleteConfirmationDialog}
                                loading={isLoadingDeleteEmployee}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                            >
                                {t("deleteEmployeeButton")}
                            </LoadingButton>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                loading={isLoadingUpdateEmployee}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                            >
                                {t("editEmployee")}
                            </LoadingButton>
                        </Box>
                    </Box>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity={snackbarSeverity}
                            sx={{
                                mt: "10px",
                                backgroundColor:
                                    snackbarSeverity === "success"
                                        ? "#10781F"
                                        : "#ff000082",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {snackbarMsg}
                        </Alert>
                    </Snackbar>
                    <Dialog
                        open={openDeleteConfirmationDialog}
                        onClose={handleCloseDeleteConfirmationDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("deleteEmployeeConfirmationTitle")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t("deleteEmployeeConfirmationMessage")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                                onClick={handleCloseDeleteConfirmationDialog}
                            >
                                {t("noMessage")}
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                                onClick={handleDeleteEmployee}
                                autoFocus
                            >
                                {t("yesMessage")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            )}
        </Formik>
    );
};

export default EditEmployeeForm;

import { apiSlice } from "app/api/apiSlice";
import { logOut, setCredentials } from "./authSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: "Login/Login",
                method: "POST",
                body: { ...credentials },
            }),
        }),
        verifyLoginCode: builder.mutation({
            query: (loginVerifyCode) => ({
                url: "Login/VerifyLoginCode",
                method: "POST",

                body: { ...loginVerifyCode },
            }),
        }),
        forgotPassword: builder.mutation({
            query: (email) => ({
                url: "Login/ForgotPassword",
                method: "POST",
                body: { ...email },
            }),
        }),
        sendLogout: builder.mutation({
            query: (userToDeleteToken) => ({
                url: "Login/Logout",
                method: "POST",
                body: { ...userToDeleteToken },
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    //const { data } =
                    await queryFulfilled;
                    //console.log(data);
                    dispatch(logOut());
                    dispatch(apiSlice.util.resetApiState());
                } catch (err) {
                    console.log(err);
                }
            },
        }),
        refreshToken: builder.mutation({
            query: () => ({
                url: `Login/RefreshToken`,
                method: "POST",
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { user, token, codeResult } = await queryFulfilled;
                    dispatch(setCredentials({ user, token }));
                } catch (err) {
                    console.log(err);
                }
            },
        }),
    }),
});

export const {
    useLoginMutation,
    useVerifyLoginCodeMutation,
    useForgotPasswordMutation,
    useSendLogoutMutation,
    useRefreshTokenMutation,
} = authApiSlice;

import { useState, useEffect } from "react";
import {
    useDeleteEmployeeWorkHourCostsMutation,
    useGetEmployeeWorkHourCostsByIdQuery,
} from "./employeeWorkHourCostsApiSlice";
import PageHeader from "components/PageHeader";
import LoadingSpinner from "components/LoadingSpinner";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGrid } from "@mui/x-data-grid";
import {
    Box,
    TextField,
    Typography,
    useTheme,
    FormControl,
    FormControlLabel,
    useMediaQuery,
    Checkbox,
    Button,
    Autocomplete,
} from "@mui/material";
import { Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { RESPONSES } from "config/responses";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SETTINGS } from "helpers/settings";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const EmployeeWorkHourCostsHistoricDetailsForm = () => {
    // #region Setups

    const navigate = useNavigate(); /* Set the Navigation Service */
    const location = useLocation();
    const theme = useTheme();
    const { t } = useTranslation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    //#endregion Setups

    const { id } = useParams();
    const {
        data: employeeWorkHourCosts,
        isLoading: isLoadingGetEmployeeWorkHourCosts,
    } = useGetEmployeeWorkHourCostsByIdQuery(id);

    const employeeWorkHourCostsSchema = yup.object().shape({
        employeeId: yup.string().required(t("requiredField")),
        observations: yup.string().notRequired(),
    }); /* Set the employeeWorkHourCosts Schema */

    const initialValuesEmployeeWorkHourCosts = {
        employee: {
            name: "",
        },
        employeeId: "",
        currentDate: "",
        advancePaymentValue: "",
        advancePaymentSumByMoney: "",
        advancePaymentSumByCard: "",
        adjustmentSum: "",
        salary: "",
        isSickLeave: false,
        notWorking: false,
        mealAllowance: "",
        observations: "",
    }; /* Set the Form Initial Values */

    const [employees, setEmployees] = useState([]);
    const [workHourCosts, setWorkHourCosts] = useState([]);
    const [isFixedSalary, setIsFixedSalary] = useState(false);
    const [isOfficeEmployee, setIsOfficeEmployee] = useState(false);
    const [workHours_Employee_FixedSalary, setWorkHours_Employee_FixedSalary] =
        useState([]);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMsg(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const [
        openDeleteEmployeeWorkHourCostsDialog,
        setOpenDeleteEmployeeWorkHourCostsDialog,
    ] = useState(false);

    const handleOpenDeleteEmployeeWorkHourCostsDialog = () => {
        setOpenDeleteEmployeeWorkHourCostsDialog(true);
    };

    const handleCloseDeleteEmployeeWorkHourCostsDialog = () => {
        setOpenDeleteEmployeeWorkHourCostsDialog(false);
    };

    const [
        deleteEmployeeWorkHourCosts,
        { isLoading: isLoadingDeleteEmployeeWorkHourCosts },
    ] = useDeleteEmployeeWorkHourCostsMutation();

    useEffect(() => {
        if (employeeWorkHourCosts) {
            setWorkHourCosts(employeeWorkHourCosts.workHourCosts);

            const employees = [];
            employees.push(employeeWorkHourCosts.employee);
            setEmployees(employees);
            setIsOfficeEmployee(
                employeeWorkHourCosts.employee.isOfficeEmployee
            );
            //vamos verificar se o salario é fixo ou nao, se for fixo não mostramos o campo "horas"
            if (
                employeeWorkHourCosts.employee.salaryType.id ===
                SETTINGS.SalaryType.Fixed
            ) {
                setIsFixedSalary(true);
            }

            if (
                employeeWorkHourCosts.employee.salaryType.id ===
                    SETTINGS.SalaryType.Fixed &&
                employeeWorkHourCosts.employee.isOfficeEmployee === false &&
                employeeWorkHourCosts.workHourCosts.length > 0
            ) {
                setWorkHours_Employee_FixedSalary(
                    employeeWorkHourCosts.workHourCosts[0]
                        .workHours_Employee_FixedSalaries
                );
            } else if (employeeWorkHourCosts.workHourCosts.length === 0) {
                setWorkHours_Employee_FixedSalary([]);
            }
        }
    }, [employeeWorkHourCosts]);

    const handleDeleteEmployeeWorkHourCosts = async () => {
        try {
            let employeeWorkHourCostIdToRemove = id.split("-")[0];
            const { codeResult } = await deleteEmployeeWorkHourCosts(
                employeeWorkHourCostIdToRemove
            ).unwrap();

            if (
                codeResult !==
                RESPONSES.employeeWorkHourCosts
                    .sucessToRemoveEmployeeWorkHourCosts
            ) {
                if (
                    codeResult ===
                    RESPONSES.employeeWorkHourCosts
                        .invalidToRemoveEmployeeWorkHourCosts
                ) {
                    handleSnackbarOpen(
                        t("invalidToRemoveEmployeeWorkHourCosts"),
                        "error"
                    );
                } else {
                    handleSnackbarOpen(
                        t("errorToRemoveEmployeeWorkHourCosts"),
                        "error"
                    );
                }
            } else {
                navigate(`/${location.pathname.split("/")[1]}`);
            }
        } catch (err) {
            handleSnackbarOpen(
                t("errorToRemoveEmployeeWorkHourCosts"),
                "error"
            );
        }
    };

    const workHourCostsColumns = [
        {
            field: "name",
            headerName: t("planningWork"),
            flex: 1,
            valueGetter: (params) =>
                params.row.workPlanning.name +
                " - " +
                params.row.workPlanning.work.name,
        },
        {
            field: "numberOfWorkHours",
            headerName: t("numberOfHours"),
            flex: 1,
            valueGetter: (params) => params.row.numberOfWorkHours,
        },
        {
            field: "cost",
            headerName: t("hourSalary"),
            flex: 1,
            valueGetter: (params) => params.row.cost,
            valueFormatter: (params) => `${params.value} €`, // Adiciona "€" após o valor
        },
    ];

    const workHourCostsColumnsToFixedSalary = [
        {
            field: "cost",
            headerName: t("fixedSalary"),
            flex: 1,
            valueGetter: (params) => params.row.cost,
            valueFormatter: (params) => `${params.value} €`, // Adiciona "€" após o valor
        },
    ];

    const workHourCostsColumnsToFixedSalaryAndNotOfficeEmployee = [
        {
            field: "name",
            headerName: t("planningWork"),
            flex: 1,
            valueGetter: (params) =>
                params.row.workPlanning.name +
                " - " +
                params.row.workPlanning.work.name,
        },
        {
            field: "numberOfWorkHours",
            headerName: t("numberOfHours"),
            flex: 1,
            valueGetter: (params) => params.row.numberOfWorkHours,
        },
    ];

    return (
        <Formik
            initialValues={
                employeeWorkHourCosts || initialValuesEmployeeWorkHourCosts
            }
            validationSchema={employeeWorkHourCostsSchema}
            enableReinitialize={true}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    {isLoadingGetEmployeeWorkHourCosts ? (
                        <LoadingSpinner />
                    ) : null}
                    <Box
                        m={!isNonMobile ? "0" : "1.5rem 2.5rem"}
                        display={!isNonMobile ? "flex" : "grid"}
                        gridTemplateRows={
                            !isNonMobile ? undefined : "repeat(4, auto)"
                        }
                        flexDirection={!isNonMobile ? "column" : undefined}
                        gap="20px"
                        borderRadius="5px"
                        p="2rem"
                    >
                        <PageHeader title={values.employee.name} subtitle="" />
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("salarialProcessing")}
                            </Typography>
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(3, 1fr)"
                                }
                                gap="10px"
                            >
                                <Autocomplete
                                    readOnly={true}
                                    variant="outlined"
                                    loading={isLoadingGetEmployeeWorkHourCosts}
                                    options={employees || []}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                        (employees &&
                                            employees.find(
                                                (employee) =>
                                                    employee.id ===
                                                    values.employeeId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "employeeId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                    }}
                                    onBlur={handleBlur}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("employee")}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.employeeId
                                                    ) &&
                                                    Boolean(errors.employeeId)
                                                }
                                                helperText={
                                                    touched.employeeId &&
                                                    errors.employeeId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        label={t("monthOfProcessing")}
                                        format="MM/YYYY"
                                        value={dayjs(values.currentDate)}
                                        onChange={(value) =>
                                            setFieldValue("currentDate", value)
                                        }
                                        readOnly={true}
                                    />
                                </LocalizationProvider>
                                <TextField
                                    label={t("advancePaymentByMoney")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.advancePaymentSumByMoney}
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        endAdornment: "€",
                                    }}
                                    readOnly={true}
                                />
                                <TextField
                                    label={t("advancePaymentByCard")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.advancePaymentSumByCard}
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        endAdornment: "€",
                                    }}
                                    readOnly={true}
                                />
                                <TextField
                                    label={t("adjustments")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.adjustmentSum}
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        endAdornment: "€",
                                    }}
                                    readOnly={true}
                                />
                                <TextField
                                    label={t("mealAllowance")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.mealAllowance}
                                    name="mealAllowance"
                                    InputProps={{
                                        endAdornment: "€",
                                    }}
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("salary")}
                                    onBlur={handleBlur}
                                    value={values.salary}
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        endAdornment: "€",
                                    }}
                                    readOnly={true}
                                />
                                {values.isSickLeave === true && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.isSickLeave}
                                                disabled
                                            />
                                        }
                                        label={t("sickLeave")}
                                    />
                                )}

                                {values.notWorking === true && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.notWorking}
                                                disabled
                                            />
                                        }
                                        label={t("notWorking")}
                                    />
                                )}
                                <TextField
                                    label={t("observations")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.observations}
                                    name="observations"
                                    sx={{
                                        width: "100%",
                                    }}
                                    readOnly={true}
                                    multiline
                                />
                                {/* )} */}
                            </Box>
                            {/* WORK HOUR COSTS*/}
                            {values.isSickLeave === false &&
                                values.notWorking === false && (
                                    <Box
                                        display="flex"
                                        gap="20px"
                                        flexDirection="column"
                                        width="100%"
                                    >
                                        <Box
                                            display="flex"
                                            width="100%"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Typography
                                                fontWeight="500"
                                                fontSize="20px"
                                                color="primary"
                                                variant="h5"
                                                textAlign="left"
                                            >
                                                {t("details")}
                                            </Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            sx={{
                                                "& .MuiDataGrid-root": {
                                                    border: "none",
                                                    flexGrow: 1,
                                                    width: "100%",
                                                },
                                                "& .MuiDataGrid-cell": {
                                                    // borderBottom: "none",
                                                },
                                                "& .MuiDataGrid-columnHeaders":
                                                    {
                                                        backgroundColor: `${theme.palette.background.J} !important`,
                                                        color: theme.palette
                                                            .secondary[100],
                                                        borderBottom: "none",
                                                    },
                                                "& .MuiDataGrid-virtualScroller":
                                                    {
                                                        backgroundColor:
                                                            theme.palette
                                                                .background.A,
                                                    },
                                                "& .MuiDataGrid-footerContainer":
                                                    {
                                                        backgroundColor: `${theme.palette.background.J} !important`,
                                                        color: theme.palette
                                                            .secondary[100],
                                                        borderTop: "none",
                                                    },
                                                "& .MuiDataGrid-toolbarContainer .MuiButton-text":
                                                    {
                                                        color: `${theme.palette.background.I}`,
                                                    },
                                                "& .MuiDataGrid-toolbarContainer .MuiBox-root":
                                                    {
                                                        display: !isNonMobile
                                                            ? "flex"
                                                            : undefined,
                                                        flexDirection:
                                                            !isNonMobile
                                                                ? "row"
                                                                : undefined,
                                                    },
                                                "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                                    {
                                                        backgroundColor: `${theme.palette.background.A} !important`,
                                                    },
                                            }}
                                        >
                                            {isFixedSalary === false ? (
                                                <DataGrid
                                                    autoHeight
                                                    loading={
                                                        isLoadingGetEmployeeWorkHourCosts ||
                                                        !workHourCosts
                                                    }
                                                    getRowId={(row) => row.id}
                                                    rows={workHourCosts || []}
                                                    columns={
                                                        workHourCostsColumns
                                                    }
                                                    initialState={{
                                                        pagination: {
                                                            paginationModel: {
                                                                pageSize: 5,
                                                            },
                                                        },
                                                    }}
                                                    pageSizeOptions={[5]}
                                                    disableRowSelectionOnClick
                                                />
                                            ) : (
                                                <DataGrid
                                                    autoHeight
                                                    loading={
                                                        isLoadingGetEmployeeWorkHourCosts ||
                                                        !workHourCosts
                                                    }
                                                    getRowId={(row) => row.id}
                                                    rows={workHourCosts || []}
                                                    columns={
                                                        workHourCostsColumnsToFixedSalary
                                                    }
                                                    initialState={{
                                                        pagination: {
                                                            paginationModel: {
                                                                pageSize: 5,
                                                            },
                                                        },
                                                    }}
                                                    pageSizeOptions={[5]}
                                                    disableRowSelectionOnClick
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                )}
                            {isFixedSalary === true &&
                                isOfficeEmployee === false &&
                                values.isSickLeave === false &&
                                values.notWorking === false && (
                                    <Box
                                        display="flex"
                                        gap="20px"
                                        flexDirection="column"
                                        width="100%"
                                    >
                                        <Box
                                            display="flex"
                                            width="100%"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Typography
                                                fontWeight="500"
                                                fontSize="20px"
                                                color="primary"
                                                variant="h5"
                                                textAlign="left"
                                            >
                                                {t("workHours")}
                                            </Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            sx={{
                                                "& .MuiDataGrid-root": {
                                                    border: "none",
                                                    flexGrow: 1,
                                                    width: "100%",
                                                },
                                                "& .MuiDataGrid-cell": {
                                                    // borderBottom: "none",
                                                },
                                                "& .MuiDataGrid-columnHeaders":
                                                    {
                                                        backgroundColor: `${theme.palette.background.J} !important`,
                                                        color: theme.palette
                                                            .secondary[100],
                                                        borderBottom: "none",
                                                    },
                                                "& .MuiDataGrid-virtualScroller":
                                                    {
                                                        backgroundColor:
                                                            theme.palette
                                                                .background.A,
                                                    },
                                                "& .MuiDataGrid-footerContainer":
                                                    {
                                                        backgroundColor: `${theme.palette.background.J} !important`,
                                                        color: theme.palette
                                                            .secondary[100],
                                                        borderTop: "none",
                                                    },
                                                "& .MuiDataGrid-toolbarContainer .MuiButton-text":
                                                    {
                                                        color: `${theme.palette.background.I}`,
                                                    },
                                                "& .MuiDataGrid-toolbarContainer .MuiBox-root":
                                                    {
                                                        display: !isNonMobile
                                                            ? "flex"
                                                            : undefined,
                                                        flexDirection:
                                                            !isNonMobile
                                                                ? "row"
                                                                : undefined,
                                                    },
                                                "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                                    {
                                                        backgroundColor: `${theme.palette.background.A} !important`,
                                                    },
                                            }}
                                        >
                                            <DataGrid
                                                autoHeight
                                                loading={
                                                    isLoadingGetEmployeeWorkHourCosts ||
                                                    !workHours_Employee_FixedSalary
                                                }
                                                getRowId={(row) => row.id}
                                                rows={
                                                    workHours_Employee_FixedSalary ||
                                                    []
                                                }
                                                columns={
                                                    workHourCostsColumnsToFixedSalaryAndNotOfficeEmployee
                                                }
                                                initialState={{
                                                    pagination: {
                                                        paginationModel: {
                                                            pageSize: 5,
                                                        },
                                                    },
                                                }}
                                                pageSizeOptions={[5]}
                                                disableRowSelectionOnClick
                                            />
                                        </Box>
                                    </Box>
                                )}
                        </Box>
                        <Box display="flex" gap="10px">
                            <LoadingButton
                                fullWidth
                                type="button"
                                onClick={
                                    handleOpenDeleteEmployeeWorkHourCostsDialog
                                }
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                            >
                                {t("deleteEmployeeWorkHourCostButton")}
                            </LoadingButton>
                            <LoadingButton
                                fullWidth
                                disabled={true}
                                type="submit"
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary?.light,
                                    },
                                }}
                            >
                                {t("updateEmployeeWorkHourCostButton")}
                            </LoadingButton>
                        </Box>
                        {/* DELETE EMPLOYEE WORK HOUR COST */}
                        <Dialog
                            open={openDeleteEmployeeWorkHourCostsDialog}
                            onClose={
                                handleCloseDeleteEmployeeWorkHourCostsDialog
                            }
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {t(
                                    "deleteEmployeeWorkHourCostConfirmationTitle"
                                )}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {t(
                                        "deleteEmployeeWorkHourCostConfirmationMessage"
                                    )}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    sx={{
                                        backgroundColor: theme.palette.alt.red,
                                        color: theme.palette.neutral.white,
                                        "&:hover": {
                                            backgroundColor:
                                                theme.palette.alt.lightRed,
                                        },
                                    }}
                                    onClick={
                                        handleCloseDeleteEmployeeWorkHourCostsDialog
                                    }
                                >
                                    {t("noMessage")}
                                </Button>
                                <Button
                                    sx={{
                                        backgroundColor:
                                            theme.palette.primary.main,
                                        color: theme.palette.neutral.white,
                                        "&:hover": {
                                            backgroundColor:
                                                theme.palette.primary.light,
                                        },
                                    }}
                                    onClick={handleDeleteEmployeeWorkHourCosts}
                                    autoFocus
                                >
                                    {t("yesMessage")}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

export default EmployeeWorkHourCostsHistoricDetailsForm;

export const SETTINGS = {
    SalaryType: {
        Fixed: 1,
        Hour: 2,
    },
    CostCategoryCategoryId: {
        Fuel: 3,
        Toll: 4,
        CarMaintenance: 5,
        Water: 6,
        Electricity: 7,
        Gas: 8,
        Repair: 9,
        ExtraCost: 19,
        WorkCost: 21,
        GeneralAccomodationExpenses: 22,
    },
    EmployeeCategory: {
        OfficeEmployeeId: 1,
    },
    WorkType: {
        Company: 1,
        Private: 2,
    },
    AlertType: {
        EpisAlert: 1,
        SkillsSheetAlert: 2,
        SickLeavesAlert: 3,
        PayIUCAlert: 4,
        InspectionDateAlert: 5,
        ReminderDateAlert: 6,
    },
    PaymentType: {
        ByMoney: "byMoney",
        ByCard: "byCard",
    },
    ApplicationStatus: {
        Accepted: 2,
        Rejected: 1,
    },
};

import { useState, useRef, useEffect } from "react";
import dayjs from "dayjs";
import {
    useGetVehicleTypesQuery,
    useGetVehicleByIdQuery,
    useDeleteVehicleByIdMutation,
    useUpdateVehicleMutation,
    useGetVehicleCategoriesQuery,
    useGetEmployeePlanningsByVehicleIdQuery,
    useGetVehiclePlanningsByVehicleIdQuery,
} from "./vehiclesApiSlice";
import PageHeader from "components/PageHeader";
import LoadingSpinner from "components/LoadingSpinner";
import { format } from "date-fns";
import {
    Box,
    Button,
    TextField,
    Typography,
    useTheme,
    Alert,
    Autocomplete,
    IconButton,
    Card,
    CardContent,
    useMediaQuery,
    FormControl,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DeleteOutlined } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";
import FlexBetween from "components/FlexBetween";
import { useParams } from "react-router-dom";
import Dropzone from "react-dropzone";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DataGridCustomWithoutToolbar from "components/DataGridCustomWithoutToolbar";
import {
    useDeleteAttachmentMutation,
    useUploadAttachmentMutation,
} from "features/attachments/attachmentsApiSlice";

const EditVehicleForm = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate(); /* Set the Navigation Service */
    const location = useLocation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const [files, setFiles] = useState([]);
    const [vehicleWorks, setVehicleWorks] = useState([]);

    const nameRef = useRef(); /* User Ref to set the focus to the user input */
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const [employees, setEmployees] = useState([]);

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMsg(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
        useState(false);

    const handleOpenDeleteConfirmationDialog = () => {
        setOpenDeleteConfirmationDialog(true);
    };

    const handleCloseDeleteConfirmationDialog = () => {
        setOpenDeleteConfirmationDialog(false);
    };

    const employeePlanningsColumns = [
        {
            field: t("nInternal"),
            headerName: "Número Interno",
            flex: 1,
            valueGetter: (params) => params.row.employee.nInternal,
        },
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
            valueGetter: (params) => {
                if (params.row.isDriver === true) {
                    return (
                        params.row.employee.name + " (" + t("isDriver") + ")"
                    );
                } else {
                    return params.row.employee.name;
                }
            },
        },
        {
            field: "phoneNumber",
            headerName: t("phoneNumber"),
            flex: 1,
            valueGetter: (params) => params.row.employee.phoneNumber,
        },
        {
            field: "startDate",
            headerName: t("startDate"),
            flex: 1,
            valueGetter: (params) => {
                const startDate = params.row.startDate;
                if (startDate !== null) {
                    return format(new Date(startDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "endDate",
            headerName: t("endDate"),
            flex: 1,
            valueGetter: (params) => {
                const endDate = params.row.endDate;
                if (endDate !== null) {
                    return format(new Date(endDate), "dd/MM/yyyy");
                }
            },
        },
    ];

    const employeePlanningsColumnsMobile = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
            valueGetter: (params) => {
                if (params.row.isDriver === true) {
                    return (
                        params.row.employee.name + " (" + t("isDriver") + ")"
                    );
                } else {
                    return params.row.employee.name;
                }
            },
        },
        {
            field: "startDate",
            headerName: t("startDate"),
            flex: 1,
            valueGetter: (params) => {
                const startDate = params.row.startDate;
                if (startDate !== null) {
                    return format(new Date(startDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "endDate",
            headerName: t("endDate"),
            flex: 1,
            valueGetter: (params) => {
                const endDate = params.row.endDate;
                if (endDate !== null) {
                    return format(new Date(endDate), "dd/MM/yyyy");
                }
            },
        },
    ];

    const vehicleWorksColumns = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
            valueGetter: (params) => params.row.workPlanning.work.name,
        },
        {
            field: "address",
            headerName: t("address"),
            flex: 1,
            valueGetter: (params) => params.row.workPlanning.work.address,
        },
        {
            field: "startDate",
            headerName: t("startDate"),
            flex: 1,
            valueGetter: (params) => {
                const startDate = params.row.startDate;
                if (startDate !== null) {
                    return format(new Date(startDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "endDate",
            headerName: t("endDate"),
            flex: 1,
            valueGetter: (params) => {
                const endDate = params.row.endDate;
                if (endDate !== null) {
                    return format(new Date(endDate), "dd/MM/yyyy");
                }
            },
        },
    ];

    const vehicleWorksColumnsMobile = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
            valueGetter: (params) => params.row.workPlanning.work.name,
        },
        {
            field: "startDate",
            headerName: t("startDate"),
            flex: 1,
            valueGetter: (params) => {
                const startDate = params.row.startDate;
                if (startDate !== null) {
                    return format(new Date(startDate), "dd/MM/yyyy");
                }
            },
        },
        {
            field: "endDate",
            headerName: t("endDate"),
            flex: 1,
            valueGetter: (params) => {
                const endDate = params.row.endDate;
                if (endDate !== null) {
                    return format(new Date(endDate), "dd/MM/yyyy");
                }
            },
        },
    ];

    const { id } =
        useParams(); /* Get the accomodation Id from the route Params */

    const { data: vehicle, isLoading: isLoadingGetVehicle } =
        useGetVehicleByIdQuery(id);

    const {
        data: vehiclePlanningByVehicleId,
        isLoading: isLoadingGetVehiclePlanningByVehicleId,
    } = useGetVehiclePlanningsByVehicleIdQuery(id);

    const {
        data: employeePlanningByVehicleId,
        isLoading: isLoadingGetEmployeePlanningByVehicleId,
    } = useGetEmployeePlanningsByVehicleIdQuery(id);

    useEffect(() => {
        if (vehiclePlanningByVehicleId) {
            setVehicleWorks(vehiclePlanningByVehicleId);
        }
    }, [vehiclePlanningByVehicleId]);

    useEffect(() => {
        if (employeePlanningByVehicleId) {
            setEmployees(employeePlanningByVehicleId);
        }
    }, [employeePlanningByVehicleId]);

    useEffect(() => {
        if (vehicle) {
            const processAttachments = async () => {
                const attachmentPromises = vehicle.attachments.map(
                    async (attachment) => {
                        let file = "";
                        if (!attachment.preSignedUrl) {
                            file = await base64ToFile(
                                attachment.content,
                                attachment.fileName,
                                attachment.fileType
                            );
                        }
                        return { ...attachment, file };
                    }
                );

                try {
                    const attachments = await Promise.all(attachmentPromises);
                    setFiles(
                        attachments.filter(
                            (attachment) => !!attachment.fileName.split("/")[2]
                        )
                    );
                } catch (error) {
                    console.error("Error processing attachments:", error);
                }
            };

            processAttachments();
        }
    }, [vehicle]);

    function base64ToFile(base64Data, fileName, mimeType) {
        let base64Content = null;
        if (base64Data.split(",").length > 1) {
            base64Content = base64Data.split(",")[1];
        } else {
            base64Content = base64Data;
        }

        const uint8Array = new Uint8Array(
            atob(base64Content)
                .split("")
                .map((char) => char.charCodeAt(0))
        );

        const blob = new Blob([uint8Array], { type: mimeType });

        const file = new File([blob], fileName, { type: mimeType });

        return file;
    }

    const vehicleSchema = yup.object().shape({
        brand: yup.string().required(t("requiredField")),
        licensePlateNumber: yup.string().required(t("requiredField")),
        vehicleTypeId: yup.string().required(t("requiredField")),
        insurancePrice: yup
            .number(t("invalidValue"))
            .positive(t("invalidValue"))
            .typeError(t("invalidValue"))
            .notRequired(),
        inspectionPrice: yup
            .number(t("invalidValue"))
            .positive(t("invalidValue"))
            .typeError(t("invalidValue"))
            .notRequired(),
        monthPrice: yup
            .number(t("invalidValue"))
            .positive(t("invalidValue"))
            .typeError(t("invalidValue"))
            .notRequired(),
        iUC: yup
            .number(t("invalidValue"))
            .positive(t("invalidValue"))
            .typeError(t("invalidValue"))
            .notRequired(),
        vehicleCategoryId: yup.string().required(t("requiredField")),
        lastMaintenance: yup.date().nullable(true).notRequired(),
        inspectionDate: yup.date().nullable(true).notRequired(),
        licensePlateNumberMonth: yup.date().nullable(true).notRequired(),
        kmLastMaintenance: yup
            .number(t("invalidValue"))
            .positive(t("invalidValue"))
            .typeError(t("invalidValue"))
            .notRequired(),
    }); /* Set the vehicle forms Schema */

    const initialValuesVehicle = {
        brand: "",
        licensePlateNumber: "",
        vehicleTypeId: "",
        insurancePrice: "",
        inspectionPrice: "",
        monthPrice: "",
        inspectionDate: null,
        licensePlateNumberMonth: null,
        iUC: "",
        vehicleCategoryId: "",
        lastMaintenance: null,
        kmLastMaintenance: "",
        attachments: [],
        works: [],
    }; /* Set the Forms Initial Values */

    const { data: vehicleTypes, isLoading: isLoadingVehicleTypes } =
        useGetVehicleTypesQuery();

    const { data: vehicleCategories, isLoading: isLoadingVehicleCategories } =
        useGetVehicleCategoriesQuery();

    const handleRemoveFile = (event, file) => {
        event.stopPropagation();
        const updatedFiles = files.filter((f) => f !== file);
        setFiles(updatedFiles);
    };

    const onDropFiles = (acceptedFiles) => {
        const updatedFiles = [...files];

        acceptedFiles.forEach((file) => {
            const existingFile = updatedFiles.find(
                (f) => f.fileName === file.name
            );
            if (!existingFile) {
                updatedFiles.push({
                    fileName: file.name,
                    fileType: file.type,
                    preSignedUrl: "",
                    content: "",
                    file: file,
                });
            } else {
                handleSnackbarOpen(
                    t("file") + file.name + t("exists"),
                    "error"
                );
            }
        });

        setFiles(updatedFiles);
    };

    const convertToBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handleFilePreview = async (event, file) => {
        event.stopPropagation();
        if (file.preSignedUrl) {
            try {
                const newWindow = window.open(file.preSignedUrl, "_blank");
                if (newWindow) newWindow.opener = null;
            } catch (err) {
                handleSnackbarOpen(t("errorWhenDownload"), "error");
            }
        } else {
            try {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(file.file);
                link.download = file.fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (err) {
                handleSnackbarOpen(t("errorWhenDownload"), "error");
            }
        }
    };

    const [updateVehicle, { isLoading: isLoadingUpdateVehicle }] =
        useUpdateVehicleMutation(); /* Set the Update accomodation Mutation */

    const [deleteVehicle, { isLoading: isLoadingDeleteVehicle }] =
        useDeleteVehicleByIdMutation(); /* Set the Delete accomodation Mutation */

    const [uploadAttachment, { isLoading: isLoadingUploadAttachment }] =
        useUploadAttachmentMutation();

    const [deleteAttachment, { isLoading: isLoadingDeleteAttachment }] =
        useDeleteAttachmentMutation();

    const [loadingFiles, setLoadingFiles] = useState(false);
    const [isToUpdateVehicle, setIsToUpdateVehicle] = useState(false);

    const handleFormSubmit = async (values, onSubmitProps) => {
        let attachments = [];
        setLoadingFiles(true);
        let isToReload = false;
        try {
            // add only the new attachments
            const _files = files.filter((file) => !!file.file);

            await Promise.all(
                _files.map(async (file) => {
                    if (
                        values.attachments.length === 0 ||
                        values.attachments.filter(
                            (a) => a.fileName === file.fileName
                        ).length === 0
                    ) {
                        let attachment = {
                            id: 0,
                            fileName: file.fileName,
                            content: file.file,
                            fileType: file.fileType,
                        };
                        attachments.push(attachment);
                    }
                })
            );

            let newAttachments = [...attachments.filter((a) => a.id === 0)];

            let attachmentsToAdd = {
                attachments: [...newAttachments],
            };

            // region delete attachments
            let attachmentsToDelete = [];
            values.attachments.forEach((attachment) => {
                const _attachment = {
                    ...attachment,
                    fileName: attachment.fileName.split("/")[2],
                    fileType: attachment.fileType || "image/jpg",
                };
                if (
                    files.filter((f) => f.fileName === attachment.fileName)
                        .length === 0
                ) {
                    delete _attachment.preSignedUrl;
                    if (attachment.fileName.split("/")[2]) {
                        attachmentsToDelete.push(_attachment);
                    }
                }
            });

            const createPromises = [];

            if (attachmentsToAdd.attachments.length > 0) {
                isToReload = true;
                attachmentsToAdd.attachments.forEach((attachment) => {
                    let addAttachmentsDTO = {
                        Id: parseInt(id),
                        Attachment: attachment,
                        EntityName: "Vehicles",
                    };

                    // Store the promise in the array
                    const promise =
                        uploadAttachment(addAttachmentsDTO).unwrap();

                    createPromises.push(promise);
                });

                await Promise.all(createPromises).then(async (results) => {
                    // Create an array of promises from results
                    const processPromises = results.map(
                        async ({
                            codeResult,
                            signedUrl,
                            expiresIn,
                            fileName,
                        }) => {
                            if (
                                codeResult !==
                                RESPONSES.client.sucessToAddAttachments
                            ) {
                                handleSnackbarOpen(
                                    t("anErrorOcurred"),
                                    "error"
                                );
                            } else {
                                const attachment =
                                    attachmentsToAdd.attachments.find(
                                        (attachment) =>
                                            attachment.fileName === fileName
                                    );

                                return fetch(signedUrl, {
                                    method: "PUT",
                                    body: attachment.content,
                                    headers: {
                                        "Content-Type": "multipart/form-data",
                                        mimeType: attachment.fileType,
                                    },
                                });
                            }
                        }
                    );

                    // Wait for all processes to complete
                    await Promise.all(processPromises);
                    handleSnackbarOpen(t("sucess"), "success");
                });
            }

            const deletePromises = [];

            if (attachmentsToDelete.length > 0) {
                isToReload = true;
                attachmentsToDelete.forEach((attachment) => {
                    let addAttachmentsDTO = {
                        Id: parseInt(id),
                        Attachment: attachment,
                        EntityName: "Vehicles",
                    };
                    // Store the promise in the array
                    const promise =
                        deleteAttachment(addAttachmentsDTO).unwrap();

                    deletePromises.push(promise);
                });

                await Promise.all(deletePromises).then((results) => {
                    // Handle the results after all promises are resolved
                    results.forEach(({ codeResult }) => {
                        if (
                            codeResult !==
                            RESPONSES.client.sucessToAddAttachments
                        ) {
                            handleSnackbarOpen(t("anErrorOcurred"), "error");
                        } else {
                            handleSnackbarOpen(t("sucess"), "success");
                        }
                    });
                });
            }
        } catch (err) {
            handleSnackbarOpen(t("anErrorOcurred"), "error");
        }
        setLoadingFiles(false);

        if (isToUpdateVehicle === true) {
            try {
                values.attachments = null;
                const { codeResult } = await updateVehicle(values).unwrap();

                if (codeResult !== RESPONSES.vehicle.sucessToUpdateVehicle) {
                    if (
                        codeResult === RESPONSES.vehicle.invalidToUpdateVehicle
                    ) {
                        handleSnackbarOpen(
                            t("invalidToUpdateVehicle"),
                            "error"
                        );
                    } else if (
                        codeResult === RESPONSES.vehicle.invalidVehicle
                    ) {
                        handleSnackbarOpen(t("invalidVehicle"), "error");
                    } else {
                        handleSnackbarOpen(t("errorUpdateVehicle"), "error");
                    }
                } else {
                    handleSnackbarOpen(t("sucessToUpdateVehicle"), "success");
                }
            } catch (error) {
                handleSnackbarOpen(t("errorUpdateVehicle"), "error");
            }
            setIsToUpdateVehicle(false);
        } else {
            if (isToReload === true) {
                window.location.reload();
            }
        }
    };

    const handleDeleteVehicle = async () => {
        setOpenDeleteConfirmationDialog(false);

        try {
            const { codeResult } = await deleteVehicle(id).unwrap();
            if (codeResult !== RESPONSES.vehicle.sucessToDeleteVehicle) {
                if (codeResult === RESPONSES.vehicle.invalidToDeleteVehicle) {
                    handleSnackbarOpen(t("invalidToDeleteVehicle"), "error");
                } else if (
                    codeResult ===
                    RESPONSES.vehicle
                        .invalidToDeleteVehicleBecauseAssignedToWorkPlanning
                ) {
                    handleSnackbarOpen(
                        t(
                            "invalidToDeleteVehicleBecauseAssignedToWorkPlanning"
                        ),
                        "error"
                    );
                } else {
                    handleSnackbarOpen(t("errorDeleteVehicle"), "error");
                }
            } else {
                navigate(`/${location.pathname.split("/")[1]}`);
            }
        } catch (err) {
            handleSnackbarOpen(t("errorDeleteVehicle"), "error");
        }
    };
    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={vehicle || initialValuesVehicle}
            validationSchema={vehicleSchema}
            enableReinitialize={true}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    {isLoadingGetVehicle ||
                    isLoadingUpdateVehicle ||
                    loadingFiles ||
                    isLoadingDeleteVehicle ? (
                        <LoadingSpinner />
                    ) : null}
                    <Box
                        m={!isNonMobile ? "0" : "1.5rem 2.5rem"}
                        display={!isNonMobile ? "flex" : "grid"}
                        gridTemplateRows={
                            !isNonMobile ? undefined : "repeat(3, auto)"
                        }
                        flexDirection={!isNonMobile ? "column" : undefined}
                        gap="20px"
                        borderRadius="5px"
                        p="2rem"
                    >
                        <PageHeader
                            title={values.licensePlateNumber}
                            subtitle=""
                        />
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("vehicleInformation")}
                            </Typography>
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <TextField
                                    label={t("brand")}
                                    inputRef={nameRef}
                                    onBlur={handleBlur}
                                    value={values.brand}
                                    name="brand"
                                    onChange={(event) => {
                                        handleChange(event);
                                        setIsToUpdateVehicle(true); // Atualiza a flag sem substituir handleChange
                                    }}
                                    error={
                                        Boolean(touched.brand) &&
                                        Boolean(errors.brand)
                                    }
                                    helperText={touched.brand && errors.brand}
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("licensePlateNumber")}
                                    onBlur={handleBlur}
                                    value={values.licensePlateNumber}
                                    name="licensePlateNumber"
                                    onChange={(event) => {
                                        handleChange(event);
                                        setIsToUpdateVehicle(true); // Atualiza a flag sem substituir handleChange
                                    }}
                                    error={
                                        Boolean(touched.licensePlateNumber) &&
                                        Boolean(errors.licensePlateNumber)
                                    }
                                    helperText={
                                        touched.licensePlateNumber &&
                                        errors.licensePlateNumber
                                    }
                                    sx={{ width: "100%" }}
                                />
                                <Autocomplete
                                    loading={isLoadingVehicleTypes}
                                    options={vehicleTypes || []}
                                    getOptionLabel={(option) => option.type}
                                    value={
                                        (vehicleTypes &&
                                            vehicleTypes.find(
                                                (vehicleType) =>
                                                    vehicleType.id ===
                                                    values.vehicleTypeId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "vehicleTypeId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                        setIsToUpdateVehicle(true);
                                    }}
                                    onBlur={handleBlur("vehicleTypeId")}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("vehicleType")}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.vehicleTypeId
                                                    ) &&
                                                    Boolean(
                                                        errors.vehicleTypeId
                                                    )
                                                }
                                                helperText={
                                                    touched.vehicleTypeId &&
                                                    errors.vehicleTypeId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />

                                <Autocomplete
                                    loading={isLoadingVehicleCategories}
                                    options={vehicleCategories || []}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                        (vehicleCategories &&
                                            vehicleCategories.find(
                                                (vehicleCategory) =>
                                                    vehicleCategory.id ===
                                                    values.vehicleCategoryId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "vehicleCategoryId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                        setIsToUpdateVehicle(true);
                                    }}
                                    onBlur={handleBlur("vehicleCategoryId")}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("vehicleCategory")}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.vehicleCategoryId
                                                    ) &&
                                                    Boolean(
                                                        errors.vehicleCategoryId
                                                    )
                                                }
                                                helperText={
                                                    touched.vehicleCategoryId &&
                                                    errors.vehicleCategoryId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />
                            </Box>
                        </Box>
                        {values.vehicleTypeId === 1 ? (
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <TextField
                                    label={t("insurancePrice")}
                                    onBlur={handleBlur}
                                    value={values.insurancePrice}
                                    name="insurancePrice"
                                    onChange={(event) => {
                                        handleChange(event);
                                        setIsToUpdateVehicle(true); // Atualiza a flag sem substituir handleChange
                                    }}
                                    error={
                                        Boolean(touched.insurancePrice) &&
                                        Boolean(errors.insurancePrice)
                                    }
                                    helperText={
                                        touched.insurancePrice &&
                                        errors.insurancePrice
                                    }
                                    InputProps={{
                                        endAdornment: "€",
                                    }}
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("inspectionPrice")}
                                    onBlur={handleBlur}
                                    value={values.inspectionPrice}
                                    name="inspectionPrice"
                                    onChange={(event) => {
                                        handleChange(event);
                                        setIsToUpdateVehicle(true); // Atualiza a flag sem substituir handleChange
                                    }}
                                    error={
                                        Boolean(touched.inspectionPrice) &&
                                        Boolean(errors.inspectionPrice)
                                    }
                                    helperText={
                                        touched.inspectionPrice &&
                                        errors.inspectionPrice
                                    }
                                    InputProps={{
                                        endAdornment: "€",
                                    }}
                                    sx={{ width: "100%" }}
                                />
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    {values.lastMaintenance !== null ? (
                                        <DatePicker
                                            label={t("lastMaintenanceDate")}
                                            value={dayjs(
                                                values.lastMaintenance
                                            )}
                                            format="DD/MM/YYYY"
                                            onChange={(value) => {
                                                setFieldValue(
                                                    "lastMaintenance",
                                                    value
                                                );
                                                setIsToUpdateVehicle(true);
                                            }}
                                        />
                                    ) : (
                                        <DatePicker
                                            label={t("lastMaintenanceDate")}
                                            value={values.lastMaintenance}
                                            format="DD/MM/YYYY"
                                            onChange={(value) => {
                                                setFieldValue(
                                                    "lastMaintenance",
                                                    value
                                                );
                                                setIsToUpdateVehicle(true);
                                            }}
                                        />
                                    )}
                                    {values.inspectionDate !== null ? (
                                        <DatePicker
                                            label={t("inspectionDate")}
                                            value={dayjs(values.inspectionDate)}
                                            format="DD/MM/YYYY"
                                            onChange={(value) => {
                                                setFieldValue(
                                                    "inspectionDate",
                                                    value
                                                );
                                                setIsToUpdateVehicle(true);
                                            }}
                                        />
                                    ) : (
                                        <DatePicker
                                            label={t("inspectionDate")}
                                            value={values.inspectionDate}
                                            format="DD/MM/YYYY"
                                            onChange={(value) => {
                                                setFieldValue(
                                                    "inspectionDate",
                                                    value
                                                );
                                                setIsToUpdateVehicle(true);
                                            }}
                                        />
                                    )}
                                    {values.licensePlateNumberMonth !== null ? (
                                        <DatePicker
                                            label={t("licensePlateNumberDate")}
                                            value={dayjs(
                                                values.licensePlateNumberMonth
                                            )}
                                            format="DD/MM/YYYY"
                                            onChange={(value) => {
                                                setFieldValue(
                                                    "licensePlateNumberMonth",
                                                    value
                                                );
                                                setIsToUpdateVehicle(true);
                                            }}
                                        />
                                    ) : (
                                        <DatePicker
                                            label={t("licensePlateNumberDate")}
                                            value={
                                                values.licensePlateNumberMonth
                                            }
                                            format="DD/MM/YYYY"
                                            onChange={(value) => {
                                                setFieldValue(
                                                    "licensePlateNumberMonth",
                                                    value
                                                );
                                                setIsToUpdateVehicle(true);
                                            }}
                                        />
                                    )}
                                </LocalizationProvider>
                                <TextField
                                    label={t("kmLastMaintenance")}
                                    onBlur={handleBlur}
                                    value={values.kmLastMaintenance}
                                    name="kmLastMaintenance"
                                    onChange={(event) => {
                                        handleChange(event);
                                        setIsToUpdateVehicle(true); // Atualiza a flag sem substituir handleChange
                                    }}
                                    error={
                                        Boolean(touched.kmLastMaintenance) &&
                                        Boolean(errors.kmLastMaintenance)
                                    }
                                    helperText={
                                        touched.kmLastMaintenance &&
                                        errors.kmLastMaintenance
                                    }
                                    InputProps={{
                                        endAdornment: "Km",
                                    }}
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("iuc")}
                                    onBlur={handleBlur}
                                    value={values.iuc}
                                    name="iuc"
                                    onChange={(event) => {
                                        handleChange(event);
                                        setIsToUpdateVehicle(true); // Atualiza a flag sem substituir handleChange
                                    }}
                                    error={
                                        Boolean(touched.iuc) &&
                                        Boolean(errors.iuc)
                                    }
                                    helperText={touched.iuc && errors.iuc}
                                    InputProps={{
                                        endAdornment: "€",
                                    }}
                                    sx={{ width: "100%" }}
                                />
                            </Box>
                        ) : values.vehicleTypeId === 2 ? (
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <TextField
                                    label={t("monthPrice")}
                                    onBlur={handleBlur}
                                    value={values.monthPrice}
                                    name="monthPrice"
                                    onChange={(event) => {
                                        handleChange(event);
                                        setIsToUpdateVehicle(true); // Atualiza a flag sem substituir handleChange
                                    }}
                                    error={
                                        Boolean(touched.monthPrice) &&
                                        Boolean(errors.monthPrice)
                                    }
                                    helperText={
                                        touched.monthPrice && errors.monthPrice
                                    }
                                    InputProps={{
                                        endAdornment: "€",
                                    }}
                                    sx={{ width: "100%" }}
                                />

                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    {values.lastMaintenance !== null ? (
                                        <DatePicker
                                            label={t("lastMaintenanceDate")}
                                            value={dayjs(
                                                values.lastMaintenance
                                            )}
                                            format="DD/MM/YYYY"
                                            onChange={(value) => {
                                                setFieldValue(
                                                    "lastMaintenance",
                                                    value
                                                );
                                                setIsToUpdateVehicle(true);
                                            }}
                                        />
                                    ) : (
                                        <DatePicker
                                            label={t("lastMaintenanceDate")}
                                            value={values.lastMaintenance}
                                            format="DD/MM/YYYY"
                                            onChange={(value) => {
                                                setFieldValue(
                                                    "lastMaintenance",
                                                    value
                                                );
                                                setIsToUpdateVehicle(true);
                                            }}
                                        />
                                    )}
                                </LocalizationProvider>

                                <TextField
                                    label={t("kmLastMaintenance")}
                                    onBlur={handleBlur}
                                    value={values.kmLastMaintenance}
                                    name="kmLastMaintenance"
                                    onChange={(event) => {
                                        handleChange(event);
                                        setIsToUpdateVehicle(true); // Atualiza a flag sem substituir handleChange
                                    }}
                                    error={
                                        Boolean(touched.kmLastMaintenance) &&
                                        Boolean(errors.kmLastMaintenance)
                                    }
                                    helperText={
                                        touched.kmLastMaintenance &&
                                        errors.kmLastMaintenance
                                    }
                                    InputProps={{
                                        endAdornment: "Km",
                                    }}
                                    sx={{ width: "100%" }}
                                />
                            </Box>
                        ) : (
                            <div></div>
                        )}
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("vehicleDocuments")}
                            </Typography>
                            <Dropzone multiple={true} onDrop={onDropFiles}>
                                {({ getRootProps, getInputProps }) => (
                                    <FlexBetween>
                                        <Box
                                            {...getRootProps()}
                                            border={`2px dashed ${theme.palette.primary.main}`}
                                            p="1rem"
                                            width="100%"
                                            display="grid"
                                            gridTemplateColumns={
                                                !isNonMobile
                                                    ? "repeat(1, 1fr)"
                                                    : "repeat(5, auto)"
                                            }
                                            gap="10px"
                                            borderRadius="5px"
                                            sx={{
                                                "&:hover": {
                                                    cursor: "pointer",
                                                },
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            {files.length === 0 ? (
                                                <p>
                                                    {t("dropzoneAddYouFiles")}
                                                </p>
                                            ) : (
                                                files.map((file) => (
                                                    <Card
                                                        sx={{
                                                            backgroundColor:
                                                                theme.palette
                                                                    .background
                                                                    .D,
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            gap: "10px",
                                                            p: "15px 10px 15px 0px",
                                                            "& .MuiCardContent-root:last-child":
                                                                {
                                                                    paddingBottom:
                                                                        "20px",
                                                                },
                                                        }}
                                                        key={file.fileName}
                                                        onClick={(event) =>
                                                            handleFilePreview(
                                                                event,
                                                                file
                                                            )
                                                        }
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                // pl: 1,
                                                                // pb: 1,
                                                            }}
                                                        >
                                                            <IconButton
                                                                onClick={(
                                                                    event
                                                                ) =>
                                                                    handleRemoveFile(
                                                                        event,
                                                                        file
                                                                    )
                                                                }
                                                            >
                                                                <DeleteOutlined />
                                                            </IconButton>
                                                        </Box>
                                                        <CardContent
                                                            sx={{
                                                                p: "0px !important",
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: 14,
                                                                }}
                                                                color="text.secondary"
                                                            >
                                                                {file.fileName.split(
                                                                    "/"
                                                                )[2]
                                                                    ? file.fileName.split(
                                                                          "/"
                                                                      )[2]
                                                                    : file.fileName}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                ))
                                            )}
                                        </Box>
                                    </FlexBetween>
                                )}
                            </Dropzone>
                        </Box>
                        <Box
                            display="flex"
                            marginTop="10px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("works")}
                            </Typography>
                            <Box
                                display="flex"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        flexGrow: 1,
                                        width: "100%",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        // borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: `${theme.palette.background.J} !important`,
                                        color: theme.palette.secondary[100],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor:
                                            theme.palette.background.A,
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        backgroundColor: `${theme.palette.background.J} !important`,
                                        color: theme.palette.secondary[100],
                                        borderTop: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text":
                                        {
                                            color: `${theme.palette.background.I}`,
                                        },
                                    "& .MuiDataGrid-toolbarContainer .MuiBox-root":
                                        {
                                            display: !isNonMobile
                                                ? "flex"
                                                : undefined,
                                            flexDirection: !isNonMobile
                                                ? "row"
                                                : undefined,
                                        },
                                    "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                        {
                                            backgroundColor: `${theme.palette.background.A} !important`,
                                        },
                                }}
                            >
                                <DataGrid
                                    autoHeight
                                    loading={
                                        isLoadingGetVehiclePlanningByVehicleId ||
                                        !vehicleWorks
                                    }
                                    getRowId={(row) => row.id}
                                    rows={vehicleWorks || []}
                                    columns={
                                        !isNonMobile
                                            ? vehicleWorksColumnsMobile
                                            : vehicleWorksColumns
                                    }
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    slots={{
                                        toolbar: DataGridCustomWithoutToolbar,
                                    }}
                                    disableRowSelectionOnClick
                                />
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            marginTop="10px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("employees")}
                            </Typography>
                            <Box
                                display="flex"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        flexGrow: 1,
                                        width: "100%",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        // borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: `${theme.palette.background.J} !important`,
                                        color: theme.palette.secondary[100],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor:
                                            theme.palette.background.A,
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        backgroundColor: `${theme.palette.background.J} !important`,
                                        color: theme.palette.secondary[100],
                                        borderTop: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text":
                                        {
                                            color: `${theme.palette.background.I}`,
                                        },
                                    "& .MuiDataGrid-toolbarContainer .MuiBox-root":
                                        {
                                            display: !isNonMobile
                                                ? "flex"
                                                : undefined,
                                            flexDirection: !isNonMobile
                                                ? "row"
                                                : undefined,
                                        },
                                    "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                        {
                                            backgroundColor: `${theme.palette.background.A} !important`,
                                        },
                                }}
                            >
                                <DataGrid
                                    autoHeight
                                    loading={
                                        isLoadingGetEmployeePlanningByVehicleId ||
                                        !employees
                                    }
                                    getRowId={(row) => row.id}
                                    rows={employees || []}
                                    columns={
                                        !isNonMobile
                                            ? employeePlanningsColumnsMobile
                                            : employeePlanningsColumns
                                    }
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    slots={{
                                        toolbar: DataGridCustomWithoutToolbar,
                                    }}
                                    disableRowSelectionOnClick
                                />
                            </Box>
                        </Box>
                        <Box display="flex" gap="10px">
                            <LoadingButton
                                fullWidth
                                type="button"
                                onClick={handleOpenDeleteConfirmationDialog}
                                loading={isLoadingDeleteVehicle}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                            >
                                {t("deleteVehicleButton")}
                            </LoadingButton>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                loading={isLoadingUpdateVehicle}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                            >
                                {t("editVehicle")}
                            </LoadingButton>
                        </Box>
                    </Box>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity={snackbarSeverity}
                            sx={{
                                mt: "10px",
                                backgroundColor:
                                    snackbarSeverity === "success"
                                        ? "#10781F"
                                        : "#ff000082",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {snackbarMsg}
                        </Alert>
                    </Snackbar>
                    <Dialog
                        open={openDeleteConfirmationDialog}
                        onClose={handleCloseDeleteConfirmationDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("deleteVehicleConfirmationTitle")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t("deleteVehicleConfirmationMessage")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                                onClick={handleCloseDeleteConfirmationDialog}
                            >
                                {t("noMessage")}
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                                onClick={handleDeleteVehicle}
                                autoFocus
                            >
                                {t("yesMessage")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            )}
        </Formik>
    );
};
export default EditVehicleForm;

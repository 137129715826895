import { apiSlice } from "app/api/apiSlice";

export const equipmentsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getEquipmentsPaginated: builder.query({
            query: (query) => {
                const params = [];
                params.push(`limit=${(query && query.limit) || 10}`);
                params.push(`offset=${(query && query.offset) || 0}`);
                if (query && query.search)
                    params.push(
                        `search=${encodeURIComponent(query && query.search)}`
                    );
                if (query && query.filters)
                    params.push(
                        `filters=${encodeURIComponent(
                            JSON.stringify(query && query.filters)
                        )}`
                    );
                if (query && query.orderByCollection)
                    params.push(
                        `orderByCollection=${encodeURIComponent(
                            JSON.stringify(query && query.orderByCollection)
                        )}`
                    );
                const queryString =
                    params.length > 0 ? `?${params.join("&")}` : "";
                return {
                    url: `Equipments/GetAllPaginated${queryString.toString()}`,
                    validateStatus: (response, result) => {
                        return response.status === 200 && !result.isError;
                    },
                    query,
                };
            },
            providesTags: [{ type: "Equipments", id: "LIST" }],
        }),
        getEquipments: builder.query({
            query: () => ({
                url: `Equipments/GetAll`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "Equipments", id: "LIST" }],
        }),
        postEquipment: builder.mutation({
            query: (equipmentsData) => ({
                url: `Equipments`,
                method: "POST",
                body: {
                    ...equipmentsData,
                },
            }),
            invalidatesTags: [{ type: "Equipments", id: "LIST" }],
        }),
        getEquipmentById: builder.query({
            query: (id) => ({
                url: `Equipments/${id}`,
            }),
            providesTags: (result, error, arg) => [
                { type: "Equipments", id: arg },
            ],
        }),
        updateEquipment: builder.mutation({
            query: (equipmentData) => ({
                url: `Equipments`,
                method: "PUT",
                body: {
                    ...equipmentData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Equipments", id: "LIST" },
                { type: "Equipments", id: arg.id },
            ],
        }),
        deleteEquipmentById: builder.mutation({
            query: (id) => ({
                url: `Equipments/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "Equipments", id: "LIST" }],
        }),
    }),
});

export const {
    useGetEquipmentByIdQuery,
    useGetEquipmentsQuery,
    usePostEquipmentMutation,
    useUpdateEquipmentMutation,
    useDeleteEquipmentByIdMutation,
} = equipmentsApiSlice;

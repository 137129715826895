import { apiSlice } from "app/api/apiSlice";

export const employeeCostsByWorkApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllEmployeeCostsByWork: builder.query({
            query: () => ({
                url: `EmployeeCostsByWork`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "EmployeeCostsByWork", id: "LIST" }],
        }),
        getAllEmployeeCostsByWorkHistory: builder.query({
            query: () => ({
                url: `EmployeeCostsByWork/GetAllEmployeeCostsByWorkHistory`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "EmployeeCostsByWorkHistory", id: "LIST" }],
        }),
        getEmployeeCostsByWorkPaginated: builder.query({
            query: (query) => {
                const params = [];
                params.push(`limit=${(query && query.limit) || 10}`);
                params.push(`offset=${(query && query.offset) || 0}`);
                params.push(
                    `isHistoric=${
                        query && query.isHistoric !== undefined
                            ? query.isHistoric
                            : true
                    }`
                );

                if (query && query.search)
                    params.push(
                        `search=${encodeURIComponent(query && query.search)}`
                    );
                if (query && query.filters)
                    params.push(
                        `filters=${encodeURIComponent(
                            JSON.stringify(query && query.filters)
                        )}`
                    );
                if (query && query.orderByCollection)
                    params.push(
                        `orderByCollection=${encodeURIComponent(
                            JSON.stringify(query && query.orderByCollection)
                        )}`
                    );
                const queryString =
                    params.length > 0 ? `?${params.join("&")}` : "";
                return {
                    url: `EmployeeCostsByWork/GetAllPaginated${queryString.toString()}`,
                    validateStatus: (response, result) => {
                        return response.status === 200 && !result.isError;
                    },
                    query,
                };
            },
            providesTags: [{ type: "EmployeeCostsByWork", id: "LIST" }],
        }),
        getEmployeeCostsByWorkById: builder.query({
            query: (id) => ({
                url: `EmployeeCostsByWork/${id}`,
            }),
            providesTags: (result, error, arg) => [
                { type: "EmployeeCostsByWork", id: arg },
            ],
        }),
        postEmployeeCostsByWork: builder.mutation({
            query: (employeeCostsByWorkData) => ({
                url: `EmployeeCostsByWork`,
                method: "POST",
                body: {
                    ...employeeCostsByWorkData,
                },
            }),
            invalidatesTags: [
                { type: "EmployeeCostsByWork", id: "LIST" },
                { type: "Employee", id: "LIST" },
                { type: "EmployeesWithoutEmployeeCostByWork", id: "LIST" },
            ],
        }),
        calculateEmployeeRegist: builder.mutation({
            query: (calculateEmployeeRegistDTO) => ({
                url: `EmployeeCostsByWork/CalculateEmployeeRegist`,
                method: "PUT",
                body: {
                    ...calculateEmployeeRegistDTO,
                },
            }),
            invalidatesTags: [{ type: "EmployeeCostsByWork", id: "LIST" }],
        }),
        calculateSalarySheet: builder.mutation({
            query: (salarySheetDTO) => ({
                url: `EmployeeCostsByWork/CalculateSalarySheet`,
                method: "PUT",
                body: {
                    ...salarySheetDTO,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "EmployeeCostsByWork", id: "LIST" },
                { type: "EmployeeCostsByWork", id: arg.EmployeeCostsByWorkId },
            ],
        }),
        calculateCompanyDiscountsFromWork: builder.mutation({
            query: (companyDiscountsFromWorkDTO) => ({
                url: `EmployeeCostsByWork/CalculateCompanyDiscountsFromWork`,
                method: "PUT",
                body: {
                    ...companyDiscountsFromWorkDTO,
                },
            }),
            invalidatesTags: [{ type: "EmployeeCostsByWork", id: "LIST" }],
        }),
        recallCosts: builder.mutation({
            query: (costsRecallDate) => ({
                url: `EmployeeCostsByWork/RecallEmployeeCosts`,
                method: "PUT",
                body: {
                    ...costsRecallDate,
                },
            }),
            invalidatesTags: [
                { type: "RecallCosts", id: "LIST" },
                { type: "EmployeeCostsByWork", id: "LIST" },
            ],
        }),
        generateGraphs: builder.mutation({
            query: () => ({
                url: `EmployeeCostsByWork/GenerateGraphs`,
                method: "PUT",
            }),
            invalidatesTags: [
                { type: "EmployeeCostsByWorkHistory", id: "LIST" },
                { type: "EmployeeCostsByWork", id: "LIST" },
                { type: "CompanyDocs", id: "LIST" },
                { type: "EmployeesWithoutEmployeeCostByWork", id: "LIST" },
                { type: "CostCategories", id: "LIST" },
                { type: "CompanyWorkResumesByMonthResume", id: "LIST" },
                { type: "PrivateWorkResumesByMonthResume", id: "LIST" },
                { type: "EmpoyeeCostsByWorkPlanningIdAndDate", id: "LIST" },
                { type: "EmpoyeeCostsBySucontractorIdAndDate", id: "LIST" },
                { type: "CostCategoriesResume", id: "LIST" },
                { type: "MonthResumes", id: "LIST" },
                { type: "WorkResumesByClientIdAndMonthResume", id: "LIST" },
                { type: "WorkResumeByWorkPlanningId", id: "LIST" },
                { type: "WorkResumeByClientId", id: "LIST" },
                { type: "EmpoyeeCostsBySucontractorId", id: "LIST" },
            ],
        }),
        deleteEmployeeCostByWorkById: builder.mutation({
            query: (id) => ({
                url: `EmployeeCostsByWork/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [
                { type: "EmployeeCostsByWork", id: "LIST" },
                { type: "EmployeesWithoutEmployeeCostByWork", id: "LIST" },
                { type: "EmployeeCostsByWorkHistory", id: "LIST" },
            ],
        }),
    }),
});

export const {
    useGetAllEmployeeCostsByWorkQuery,
    useGetEmployeeCostsByWorkByIdQuery,
    usePostEmployeeCostsByWorkMutation,
    useCalculateEmployeeRegistMutation,
    useCalculateSalarySheetMutation,
    useCalculateCompanyDiscountsFromWorkMutation,
    useDeleteEmployeeCostByWorkByIdMutation,
    useGetAllEmployeeCostsByWorkHistoryQuery,
    useGenerateGraphsMutation,
    useRecallCostsMutation,
} = employeeCostsByWorkApiSlice;

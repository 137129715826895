import React from "react";
import { useEffect } from "react";
import {
    Box,
    TextField,
    useMediaQuery,
    Typography,
    FormControl,
    Autocomplete,
    Alert,
} from "@mui/material";
import LineChart from "components/charts/LineChart";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import Snackbar from "@mui/material/Snackbar";
import {
    useGetAllCompanyWorkResumesByMonthResumeQuery,
    useGetAllPrivateWorkResumesByMonthResumeQuery,
    useGetCostCategoriesResumeByDateQuery,
    useGetInternalRegistsByDateQuery,
    useGetMonthResumesByYearQuery,
    useLazyGetAllCompanyWorkResumesByMonthResumeQuery,
    useLazyGetAllPrivateWorkResumesByMonthResumeQuery,
    useLazyGetAllWorkResumesByClientIdAndMonthResumeQuery,
    useLazyGetCostCategoriesResumeByDateQuery,
    useLazyGetEmpoyeeCostsBySucontractorIdAndDateQuery,
    useLazyGetEmpoyeeCostsByWorkPlanningIdAndDateQuery,
    useLazyGetInternalRegistsByDateQuery,
} from "./homePageApiSlice";
import { subMonths } from "date-fns";
import LoadingSpinner from "components/LoadingSpinner";
import {
    useGetAllCompanyWorkPlanningsQuery,
    useGetAllPrivateWorkPlanningsQuery,
} from "features/workPlanning/workPlanningsApiSlice";
import { useGetSubContractorsQuery } from "features/subContractors/subContractorsApiSlice";
import LineChartCostCategories from "components/charts/LineChartCostCategories";
import LineChartSubContractor from "components/charts/LineChartSubContractor";
import { useGetClientsQuery } from "features/clients/clientsApiSlice";

const HomePage = () => {
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */
    const { t } = useTranslation();

    //REGION GRAFICO PARA INTERNAL REGISTS FILTRADO PELA DATA
    const [internalRegistsGraph, setInternalRegistsGraph] = useState([]);
    const [internalRegistTotalCost, setInternalRegistTotalCost] = useState(0);

    const [generalLoadingInternalRegists, setGeneralLoadingInternalRegists] =
        useState(false);

    const [monthInternalRegistsResumes, setMonthInternalRegistsResume] =
        useState(subMonths(new Date(), 1));

    const { data: internalRegistsResumes, isLoadingInternalRegistsResumes } =
        useGetInternalRegistsByDateQuery({
            month: subMonths(new Date(), 1).getMonth() + 1,
            year: subMonths(new Date(), 1).getFullYear(),
        });

    const [getAllInternalRegistResumeByMonth, internalRegistsResumesLazy] =
        useLazyGetInternalRegistsByDateQuery();

    const [costSumInternalRegistsAux, setCostSumInternalRegistsAux] =
        useState(0);

    const [internalRegistsGraphAuxGraph, setInternalRegistsGraphAuxGraph] =
        useState([]);

    useEffect(() => {
        if (
            costSumInternalRegistsAux !== 0 &&
            internalRegistsGraphAuxGraph &&
            internalRegistsGraphAuxGraph.length > 0
        ) {
            setInternalRegistsGraph(internalRegistsGraphAuxGraph);
            setInternalRegistTotalCost(costSumInternalRegistsAux);
            setGeneralLoadingInternalRegists(false);
        }
    }, [costSumInternalRegistsAux, internalRegistsGraphAuxGraph]);

    useEffect(() => {
        let costSum = 0;
        let internalRegistsResumesGraphAux = [];

        if (internalRegistsResumes && internalRegistsResumes.length > 0) {
            setGeneralLoadingInternalRegists(true);
            costSum = internalRegistsResumes.reduce(
                (total, item) => total + item.cost,
                0
            );
            internalRegistsResumesGraphAux = [
                {
                    id: t("cost"),
                    color: "hsl(121, 60%, 23%)",
                    data: internalRegistsResumes.map((internalRegist) => ({
                        x: internalRegist.employee.name,
                        y: internalRegist.cost,
                    })),
                },
            ];

            setInternalRegistsGraphAuxGraph(internalRegistsResumesGraphAux);
            setCostSumInternalRegistsAux(costSum.toFixed(2));
        }

        setInternalRegistsGraph(internalRegistsResumesGraphAux);
        setInternalRegistTotalCost(costSum);
    }, [internalRegistsResumes]);

    const handleGetAllInternalRegistsResumesByMonthResumeSubmit = async () => {
        try {
            if (monthInternalRegistsResumes) {
                setGeneralLoadingInternalRegists(true);
                let filterDate = {
                    month: new Date(monthInternalRegistsResumes).getMonth() + 1,
                    year: new Date(monthInternalRegistsResumes).getFullYear(),
                };
                await getAllInternalRegistResumeByMonth(filterDate);
            } else {
                handleSnackbarOpen(t("requiredDate"), "error");
            }
        } catch (err) {
            setGeneralLoadingInternalRegists(false);
            handleSnackbarOpen(err, "error");
        }
    };

    useEffect(() => {
        let costSum = 0;
        let internalRegistsResumesGraphAux = [];

        if (
            internalRegistsResumesLazy.data &&
            internalRegistsResumesLazy.data.length > 0
        ) {
            setGeneralLoadingInternalRegists(true);
            costSum = internalRegistsResumesLazy.data.reduce(
                (total, item) => total + item.cost,
                0
            );
            internalRegistsResumesGraphAux = [
                {
                    id: t("cost"),
                    color: "hsl(121, 60%, 23%)",
                    data: internalRegistsResumesLazy.data.map(
                        (internalRegist) => ({
                            x: internalRegist.employee.name,
                            y: internalRegist.cost,
                        })
                    ),
                },
            ];
            setInternalRegistsGraphAuxGraph(internalRegistsResumesGraphAux);
            setCostSumInternalRegistsAux(costSum.toFixed(2));
        }

        setInternalRegistsGraph(internalRegistsResumesGraphAux);
        setInternalRegistTotalCost(costSum);
    }, [internalRegistsResumesLazy]);

    //REGION GRÁFICO PARA RESUMOS OBRAS EMPRESARIAIS FILTRADO PELA DATA
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMsg(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const [companyWorkResumesGraph, setCompanyWorkResumesGraph] = useState([]);
    const [totalHoursCompanyWorks, setTotalHoursCompanyWorks] = useState(0);
    const [totalCostCompanyWorks, setTotalCostCompanyWorks] = useState(0);
    const [totalProfitCompanyWorks, setTotalProfitCompanyWorks] = useState(0);

    const [
        generalLoadingCompanyWorkResumes,
        setGeneralLoadingCompanyWorkResumes,
    ] = useState(false);
    const [
        generalLoadingPrivateWorkResumes,
        setGeneralLoadingPrivateWorkResumes,
    ] = useState(false);

    const [monthCompanyWorkResumes, setMonthCompanyWorkResumes] = useState(
        subMonths(new Date(), 1)
    );

    const { data: companyWorkResumes, isLoadingCompanyWorkResumes } =
        useGetAllCompanyWorkResumesByMonthResumeQuery({
            month: subMonths(new Date(), 1).getMonth() + 1,
            year: subMonths(new Date(), 1).getFullYear(),
        });

    const [getAllCompanyWorkResumesByMonthResume, companyWorkResumesLazy] =
        useLazyGetAllCompanyWorkResumesByMonthResumeQuery();

    const [hoursSumCompanyAux, setHoursSumCompanyAux] = useState(0);
    const [costSumCompanyAux, setCostSumCompanyAux] = useState(0);
    const [profitSumCompanyAux, setProfitSumCompanyAux] = useState(0);
    const [
        companyWorkResumesGraphAuxGraph,
        setCompanyWorkResumesGraphAuxGraph,
    ] = useState([]);

    useEffect(() => {
        if (
            hoursSumCompanyAux !== 0 &&
            costSumCompanyAux !== 0 &&
            profitSumCompanyAux !== 0 &&
            companyWorkResumesGraphAuxGraph &&
            companyWorkResumesGraphAuxGraph.length > 0
        ) {
            setCompanyWorkResumesGraph(companyWorkResumesGraphAuxGraph);
            setTotalHoursCompanyWorks(hoursSumCompanyAux);
            setTotalCostCompanyWorks(costSumCompanyAux);
            setTotalProfitCompanyWorks(profitSumCompanyAux);
            setGeneralLoadingCompanyWorkResumes(false);
        }
    }, [
        hoursSumCompanyAux,
        costSumCompanyAux,
        profitSumCompanyAux,
        companyWorkResumesGraphAuxGraph,
    ]);

    useEffect(() => {
        let hoursSum = 0;
        let costSum = 0;
        let profitSum = 0;
        let companyWorkResumesGraphAux = [];

        if (companyWorkResumes && companyWorkResumes.length > 0) {
            setGeneralLoadingCompanyWorkResumes(true);
            hoursSum = companyWorkResumes.reduce(
                (total, item) => total + item.hours,
                0
            );
            costSum = companyWorkResumes.reduce(
                (total, item) => total + item.cost,
                0
            );
            profitSum = companyWorkResumes.reduce(
                (total, item) => total + item.profit,
                0
            );
            companyWorkResumesGraphAux = [
                {
                    id: t("hours"),
                    color: "hsl(265, 70%, 50%)",
                    data: companyWorkResumes.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.hours,
                    })),
                },
                {
                    id: t("cost"),
                    color: "hsl(121, 60%, 23%)",
                    data: companyWorkResumes.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.cost,
                    })),
                },
                {
                    id: t("profit"),
                    color: "hsl(265, 70%, 50%)",
                    data: companyWorkResumes.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.profit,
                    })),
                },
            ];

            setCompanyWorkResumesGraphAuxGraph(companyWorkResumesGraphAux);
            setHoursSumCompanyAux(hoursSum.toFixed(2));
            setCostSumCompanyAux(costSum.toFixed(2));
            setProfitSumCompanyAux(profitSum.toFixed(2));
        }

        setCompanyWorkResumesGraph(companyWorkResumesGraphAux);
        setTotalHoursCompanyWorks(hoursSum);
        setTotalCostCompanyWorks(costSum);
        setTotalProfitCompanyWorks(profitSum);
    }, [companyWorkResumes]);

    const handleGetAllCompanyWorkResumesByMonthResumeSubmit = async () => {
        try {
            if (monthCompanyWorkResumes) {
                setGeneralLoadingCompanyWorkResumes(true);
                let filterDate = {
                    month: new Date(monthCompanyWorkResumes).getMonth() + 1,
                    year: new Date(monthCompanyWorkResumes).getFullYear(),
                };
                await getAllCompanyWorkResumesByMonthResume(filterDate);
            } else {
                handleSnackbarOpen(t("requiredDate"), "error");
            }
        } catch (err) {
            setGeneralLoadingCompanyWorkResumes(false);
            handleSnackbarOpen(err, "error");
        }
    };

    useEffect(() => {
        let hoursSum = 0;
        let costSum = 0;
        let profitSum = 0;
        let companyWorkResumesGraphAux = [];
        if (
            companyWorkResumesLazy.data &&
            companyWorkResumesLazy.data.length > 0
        ) {
            hoursSum = companyWorkResumesLazy.data.reduce(
                (total, item) => total + item.hours,
                0
            );
            costSum = companyWorkResumesLazy.data.reduce(
                (total, item) => total + item.cost,
                0
            );
            profitSum = companyWorkResumesLazy.data.reduce(
                (total, item) => total + item.profit,
                0
            );
            companyWorkResumesGraphAux = [
                {
                    id: t("hours"),
                    color: "hsl(265, 70%, 50%)",
                    data: companyWorkResumesLazy.data.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.hours,
                    })),
                },
                {
                    id: t("cost"),
                    color: "hsl(121, 60%, 23%)",
                    data: companyWorkResumesLazy.data.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.cost,
                    })),
                },
                {
                    id: t("profit"),
                    color: "hsl(265, 70%, 50%)",
                    data: companyWorkResumesLazy.data.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.profit,
                    })),
                },
            ];

            setCompanyWorkResumesGraphAuxGraph(companyWorkResumesGraphAux);
            setHoursSumCompanyAux(hoursSum.toFixed(2));
            setCostSumCompanyAux(costSum.toFixed(2));
            setProfitSumCompanyAux(profitSum.toFixed(2));
        }

        setTotalHoursCompanyWorks(hoursSum);
        setTotalCostCompanyWorks(costSum);
        setTotalProfitCompanyWorks(profitSum);
        setCompanyWorkResumesGraph(companyWorkResumesGraphAux);
        setGeneralLoadingCompanyWorkResumes(false);
    }, [companyWorkResumesLazy]);

    //ENDREGION GRÁFICO PARA RESUMOS OBRAS EMPRESARIAIS FILTRADO PELA DATA

    //REGION GRÁFICO PARA RESUMOS OBRAS FILTRADO PELO CLIENT ID E DATA
    const [workResumesByClientIdGraph, setWorkResumesByClientIdGraph] =
        useState([]);

    const [
        totalHoursWorkResumesByClientId,
        setTotalHoursWorkResumesByClientId,
    ] = useState(0);

    const [totalCostWorkResumesByClientId, setTotalCostWorkResumesByClientId] =
        useState(0);

    const [
        totalProfitWorkResumesByClientId,
        setTotalProfitWorkResumesByClientId,
    ] = useState(0);

    const [
        generalLoadingWorkResumesByClientId,
        setGeneralLoadingWorkResumesByClientId,
    ] = useState(false);

    const [monthWorkResumesByClientIdDate, setMonthWorkResumesByClientIdDate] =
        useState(subMonths(new Date(), 1));

    const { data: clients, isLoadingClients } = useGetClientsQuery();

    const [getAllWorkResumesByClientId, workResumesByClientIdLazy] =
        useLazyGetAllWorkResumesByClientIdAndMonthResumeQuery();

    const [clientId, setClientId] = useState("");

    useEffect(() => {
        if (clients && clients.length > 0) {
            try {
                setGeneralLoadingWorkResumesByClientId(true);
                setClientId(clients[0].id);

                let filterData = {
                    clientId: clients[0].id,
                    month: subMonths(new Date(), 1).getMonth() + 1,
                    year: subMonths(new Date(), 1).getFullYear(),
                };

                getAllWorkResumesByClientId(filterData);
            } catch (err) {
                handleSnackbarOpen(err, "error");
                setGeneralLoadingWorkResumesByClientId(false);
            }
        }
    }, [clients]);

    useEffect(() => {
        let hoursSum = 0;
        let costSum = 0;
        let profitSum = 0;
        let workResumesByClientIdGraphAux = [];
        if (
            workResumesByClientIdLazy.data &&
            workResumesByClientIdLazy.data.length > 0
        ) {
            hoursSum = workResumesByClientIdLazy.data.reduce(
                (total, item) => total + item.hours,
                0
            );
            costSum = workResumesByClientIdLazy.data.reduce(
                (total, item) => total + item.cost,
                0
            );
            profitSum = workResumesByClientIdLazy.data.reduce(
                (total, item) => total + item.profit,
                0
            );
            workResumesByClientIdGraphAux = [
                {
                    id: t("hours"),
                    color: "hsl(265, 70%, 50%)",
                    data: workResumesByClientIdLazy.data.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.hours,
                    })),
                },
                {
                    id: t("cost"),
                    color: "hsl(121, 60%, 23%)",
                    data: workResumesByClientIdLazy.data.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.cost,
                    })),
                },
                {
                    id: t("profit"),
                    color: "hsl(265, 70%, 50%)",
                    data: workResumesByClientIdLazy.data.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.profit,
                    })),
                },
            ];

            setTotalHoursWorkResumesByClientId(hoursSum.toFixed(2));
            setTotalCostWorkResumesByClientId(costSum.toFixed(2));
            setTotalProfitWorkResumesByClientId(profitSum.toFixed(2));
            setWorkResumesByClientIdGraph(workResumesByClientIdGraphAux);
        } else {
            setTotalHoursWorkResumesByClientId(0);
            setTotalCostWorkResumesByClientId(0);
            setTotalProfitWorkResumesByClientId(0);
            setWorkResumesByClientIdGraph([]);
        }
        setGeneralLoadingWorkResumesByClientId(false);
    }, [workResumesByClientIdLazy]);

    const handleGetWorkResumesByClientIdAndDateSubmit = async () => {
        try {
            if (clientId && monthWorkResumesByClientIdDate) {
                setGeneralLoadingWorkResumesByClientId(true);

                let filterData = {
                    clientId: clientId,
                    month:
                        new Date(monthWorkResumesByClientIdDate).getMonth() + 1,
                    year: new Date(
                        monthWorkResumesByClientIdDate
                    ).getFullYear(),
                };

                getAllWorkResumesByClientId(filterData);
            } else {
                handleSnackbarOpen(t("requiredClientId"), "error");
            }
        } catch (err) {
            handleSnackbarOpen(err, "error");
            setGeneralLoadingWorkResumesByClientId(false);
        }
    };
    //ENDREGION GRÁFICO PARA RESUMOS OBRAS FILTRADO PELO CLIENT ID E DATA

    //REGION GRÁFICO PARA RESUMOS OBRAS PARTICULARES FILTRADO PELA DATA
    const [privateWorkResumesGraph, setPrivateWorkResumesGraph] = useState([]);
    const [totalHoursPrivateWorks, setTotalHoursPrivateWorks] = useState(0);
    const [totalCostPrivateWorks, setTotalCostPrivateWorks] = useState(0);
    const [totalProfitPrivateWorks, setTotalProfitPrivateWorks] = useState(0);

    const [monthPrivateWorkResumes, setMonthPrivateWorkResumes] = useState(
        subMonths(new Date(), 1)
    );

    const { data: privateWorkResumes, isLoadingPrivateWorkResumes } =
        useGetAllPrivateWorkResumesByMonthResumeQuery({
            month: subMonths(new Date(), 1).getMonth() + 1,
            year: subMonths(new Date(), 1).getFullYear(),
        });

    const [getAllPrivateWorkResumesByMonthResume, privateWorkResumesLazy] =
        useLazyGetAllPrivateWorkResumesByMonthResumeQuery();

    const [hoursSumPrivateAux, setHoursSumPrivateAux] = useState(0);
    const [costSumPrivateAux, setCostSumPrivateAux] = useState(0);
    // const [profitSumPrivateAux, setProfitSumPrivateAux] = useState(0);
    const [
        privateWorkResumesGraphAuxGraph,
        setPrivateWorkResumesGraphAuxGraph,
    ] = useState([]);

    useEffect(() => {
        if (
            hoursSumPrivateAux !== 0 &&
            costSumPrivateAux !== 0 &&
            privateWorkResumesGraphAuxGraph &&
            privateWorkResumesGraphAuxGraph.length > 0
        ) {
            setPrivateWorkResumesGraph(privateWorkResumesGraphAuxGraph);
            setTotalHoursPrivateWorks(hoursSumPrivateAux);
            setTotalCostPrivateWorks(costSumPrivateAux);
            // setTotalProfitPrivateWorks(profitSumPrivateAux);
            setGeneralLoadingPrivateWorkResumes(false);
        }
    }, [
        hoursSumPrivateAux,
        costSumPrivateAux,
        privateWorkResumesGraphAuxGraph,
    ]);

    useEffect(() => {
        let hoursSum = 0;
        let costSum = 0;
        let privateWorkResumesGraphAux = [];

        if (privateWorkResumes && privateWorkResumes.length > 0) {
            setGeneralLoadingPrivateWorkResumes(true);
            hoursSum = privateWorkResumes.reduce(
                (total, item) => total + item.hours,
                0
            );
            costSum = privateWorkResumes.reduce(
                (total, item) => total + item.cost,
                0
            );
            privateWorkResumesGraphAux = [
                {
                    id: t("hours"),
                    color: "hsl(265, 70%, 50%)",
                    data: privateWorkResumes.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.hours,
                    })),
                },
                {
                    id: t("cost"),
                    color: "hsl(121, 60%, 23%)",
                    data: privateWorkResumes.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.cost,
                    })),
                },
            ];
            setHoursSumPrivateAux(hoursSum.toFixed(2));
            setCostSumPrivateAux(costSum.toFixed(2));
            setPrivateWorkResumesGraphAuxGraph(privateWorkResumesGraphAux);
        }

        setPrivateWorkResumesGraph(privateWorkResumesGraphAux);
        setTotalHoursPrivateWorks(hoursSum);
        setTotalCostPrivateWorks(costSum);
    }, [privateWorkResumes]);

    const handleGetAllPrivateWorkResumesByMonthResumeSubmit = async () => {
        try {
            if (monthPrivateWorkResumes) {
                setGeneralLoadingPrivateWorkResumes(true);
                let filterDate = {
                    month: new Date(monthPrivateWorkResumes).getMonth() + 1,
                    year: new Date(monthPrivateWorkResumes).getFullYear(),
                };
                await getAllPrivateWorkResumesByMonthResume(filterDate);
            } else {
                handleSnackbarOpen(t("requiredDate"), "error");
            }
        } catch (err) {
            handleSnackbarOpen(err, "error");
            setGeneralLoadingPrivateWorkResumes(false);
        }
    };

    useEffect(() => {
        let hoursSum = 0;
        let costSum = 0;
        let privateWorkResumesGraphAux = [];
        if (
            privateWorkResumesLazy.data &&
            privateWorkResumesLazy.data.length > 0
        ) {
            hoursSum = privateWorkResumesLazy.data.reduce(
                (total, item) => total + item.hours,
                0
            );
            costSum = privateWorkResumesLazy.data.reduce(
                (total, item) => total + item.cost,
                0
            );
            privateWorkResumesGraphAux = [
                {
                    id: t("hours"),
                    color: "hsl(265, 70%, 50%)",
                    data: privateWorkResumesLazy.data.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.hours,
                    })),
                },
                {
                    id: t("cost"),
                    color: "hsl(121, 60%, 23%)",
                    data: privateWorkResumesLazy.data.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.cost,
                    })),
                },
            ];
            setHoursSumPrivateAux(hoursSum.toFixed(2));
            setCostSumPrivateAux(costSum.toFixed(2));
            setPrivateWorkResumesGraphAuxGraph(privateWorkResumesGraphAux);
        }

        setTotalHoursPrivateWorks(hoursSum);
        setTotalCostPrivateWorks(costSum);
        setPrivateWorkResumesGraph(privateWorkResumesGraphAux);
        setGeneralLoadingPrivateWorkResumes(false);
    }, [privateWorkResumesLazy]);

    //ENDREGION GRÁFICO PARA RESUMOS OBRAS PARTICULARES FILTRADO PELA DATA

    //REGION EMPLOYEE COSTS BY WORKPLANNING ID AND DATE
    const { data: companyWorkPlannings, isLoadingCompanyWorkPlannings } =
        useGetAllCompanyWorkPlanningsQuery();

    const { data: privateWorkPlannings, isLoadingPrivateWorkPlannings } =
        useGetAllPrivateWorkPlanningsQuery();

    const [
        filterDateToEmployeeCostCompanyWorkPlanning,
        setFilterDateToEmployeeCostCompanyWorkPlanning,
    ] = useState(subMonths(new Date(), 1));

    const [
        filterDateToEmployeeCostPrivateWorkPlanning,
        setFilterDateToEmployeeCostPrivateWorkPlanning,
    ] = useState(subMonths(new Date(), 1));

    const [companyWorkPlanningId, setCompanyWorkPlanningId] = useState("");
    const [privateWorkPlanningId, setPrivateWorkPlanningId] = useState("");

    const [
        numberOfHoursSumEmpoyeeCostsByCompanyWorkPlanning,
        setNumberOfHoursSumEmpoyeeCostsByCompanyWorkPlanning,
    ] = useState(0);
    const [
        costsSumEmpoyeeCostsByCompanyWorkPlanning,
        setCostsSumEmpoyeeCostsByCompanyWorkPlanning,
    ] = useState(0);
    const [
        profitSumEmpoyeeCostsByCompanyWorkPlanning,
        setProfitSumEmpoyeeCostsByCompanyWorkPlanning,
    ] = useState(0);

    const [
        numberOfHoursSumEmpoyeeCostsByPrivateWorkPlanning,
        setNumberOfHoursSumEmpoyeeCostsByPrivateWorkPlanning,
    ] = useState(0);
    const [
        costsSumEmpoyeeCostsByPrivateWorkPlanning,
        setCostsSumEmpoyeeCostsByPrivateWorkPlanning,
    ] = useState(0);

    const [
        empoyeeCostsByCompanyWorkPlanningIdAndDateGraph,
        setEmpoyeeCostsByCompanyWorkPlanningIdAndDateGraph,
    ] = useState([]);
    const [
        empoyeeCostsByPrivateWorkPlanningIdAndDateGraph,
        setEmpoyeeCostsByPrivateWorkPlanningIdAndDateGraph,
    ] = useState([]);

    const [
        generalLoadindEmpoyeeCostsByCompanyWorkPlanningIdAndDate,
        setGeneralLoadingEmpoyeeCostsByCompanyWorkPlanningIdAndDate,
    ] = useState(false);
    const [
        generalLoadingEmpoyeeCostsByPrivateWorkPlanningIdAndDate,
        setGeneralLoadingEmpoyeeCostsByPrivateWorkPlanningIdAndDate,
    ] = useState(false);

    const [
        getEmpoyeeCostsByCompanyWorkPlanningIdAndDate,
        empoyeeCostsByCompanyWorkPlanningIdAndDate,
    ] = useLazyGetEmpoyeeCostsByWorkPlanningIdAndDateQuery();

    const [
        getEmpoyeeCostsByPrivateWorkPlanningIdAndDate,
        empoyeeCostsByPrivateWorkPlanningIdAndDate,
    ] = useLazyGetEmpoyeeCostsByWorkPlanningIdAndDateQuery();

    useEffect(() => {
        if (companyWorkPlannings && companyWorkPlannings.length > 0) {
            try {
                setGeneralLoadingEmpoyeeCostsByCompanyWorkPlanningIdAndDate(
                    true
                );
                setCompanyWorkPlanningId(companyWorkPlannings[0].id);
                let filterData = {
                    workPlanningId: companyWorkPlannings[0].id,
                    month: subMonths(new Date(), 1).getMonth() + 1,
                    year: subMonths(new Date(), 1).getFullYear(),
                };
                getEmpoyeeCostsByCompanyWorkPlanningIdAndDate(filterData);
            } catch (err) {
                handleSnackbarOpen(err, "error");
                setGeneralLoadingEmpoyeeCostsByCompanyWorkPlanningIdAndDate(
                    false
                );
            }
        }
    }, [companyWorkPlannings]);

    useEffect(() => {
        if (privateWorkPlannings && privateWorkPlannings.length > 0) {
            try {
                setGeneralLoadingEmpoyeeCostsByPrivateWorkPlanningIdAndDate(
                    true
                );
                setPrivateWorkPlanningId(privateWorkPlannings[0].id);
                let filterData = {
                    workPlanningId: privateWorkPlannings[0].id,
                    month: subMonths(new Date(), 1).getMonth() + 1,
                    year: subMonths(new Date(), 1).getFullYear(),
                };

                getEmpoyeeCostsByPrivateWorkPlanningIdAndDate(filterData);
            } catch (err) {
                handleSnackbarOpen(err, "error");
                setGeneralLoadingEmpoyeeCostsByPrivateWorkPlanningIdAndDate(
                    false
                );
            }
        }
    }, [privateWorkPlannings]);

    useEffect(() => {
        if (
            empoyeeCostsByCompanyWorkPlanningIdAndDate.data &&
            empoyeeCostsByCompanyWorkPlanningIdAndDate.data.length > 0
        ) {
            const hoursSum =
                empoyeeCostsByCompanyWorkPlanningIdAndDate.data.reduce(
                    (total, item) =>
                        total + item.employeeRegist.workHoursTotalByWork,
                    0
                );
            const costSum =
                empoyeeCostsByCompanyWorkPlanningIdAndDate.data.reduce(
                    (total, item) =>
                        total +
                        item.employeeRegist.workHoursTotalByWork *
                            item.clearance.hourCost,
                    0
                );
            const profitSum =
                empoyeeCostsByCompanyWorkPlanningIdAndDate.data.reduce(
                    (total, item) => total + item.clearance.workProfit,
                    0
                );

            const empoyeeCostsByCompanyWorkPlanningIdAndDateGraphAux = [
                {
                    id: t("hours"),
                    color: "hsl(265, 70%, 50%)",
                    data: empoyeeCostsByCompanyWorkPlanningIdAndDate.data.map(
                        (employeeCost) => ({
                            x: employeeCost.employee.name,
                            y: employeeCost.employeeRegist.workHoursTotalByWork,
                        })
                    ),
                },
                {
                    id: t("cost"),
                    color: "hsl(121, 60%, 23%)",
                    data: empoyeeCostsByCompanyWorkPlanningIdAndDate.data.map(
                        (employeeCost) => ({
                            x: employeeCost.employee.name,
                            y:
                                employeeCost.clearance.hourCost *
                                employeeCost.employeeRegist
                                    .workHoursTotalByWork,
                        })
                    ),
                },
                {
                    id: t("profit"),
                    color: "hsl(265, 70%, 50%)",
                    data: empoyeeCostsByCompanyWorkPlanningIdAndDate.data.map(
                        (employeeCost) => ({
                            x: employeeCost.employee.name,
                            y: employeeCost.clearance.workProfit,
                        })
                    ),
                },
            ];

            setNumberOfHoursSumEmpoyeeCostsByCompanyWorkPlanning(
                hoursSum.toFixed(2)
            );
            setCostsSumEmpoyeeCostsByCompanyWorkPlanning(costSum.toFixed(2));
            setProfitSumEmpoyeeCostsByCompanyWorkPlanning(profitSum.toFixed(2));
            setEmpoyeeCostsByCompanyWorkPlanningIdAndDateGraph(
                empoyeeCostsByCompanyWorkPlanningIdAndDateGraphAux
            );
        } else {
            setNumberOfHoursSumEmpoyeeCostsByCompanyWorkPlanning(0);
            setCostsSumEmpoyeeCostsByCompanyWorkPlanning(0);
            setProfitSumEmpoyeeCostsByCompanyWorkPlanning(0);
            setEmpoyeeCostsByCompanyWorkPlanningIdAndDateGraph([]);
        }
        setGeneralLoadingEmpoyeeCostsByCompanyWorkPlanningIdAndDate(false);
    }, [empoyeeCostsByCompanyWorkPlanningIdAndDate]);

    useEffect(() => {
        if (
            empoyeeCostsByPrivateWorkPlanningIdAndDate.data &&
            empoyeeCostsByPrivateWorkPlanningIdAndDate.data.length > 0
        ) {
            const hoursSum =
                empoyeeCostsByPrivateWorkPlanningIdAndDate.data.reduce(
                    (total, item) =>
                        total + item.employeeRegist.workHoursTotalByWork,
                    0
                );
            const costSum =
                empoyeeCostsByPrivateWorkPlanningIdAndDate.data.reduce(
                    (total, item) => total + item.clearance.workCost,
                    0
                );
            const empoyeeCostsByPrivateWorkPlanningIdAndDateGraphAux = [
                {
                    id: t("hours"),
                    color: "hsl(265, 70%, 50%)",
                    data: empoyeeCostsByPrivateWorkPlanningIdAndDate.data.map(
                        (employeeCost) => ({
                            x: employeeCost.employee.name,
                            y: employeeCost.employeeRegist.workHoursTotalByWork,
                        })
                    ),
                },
                {
                    id: t("cost"),
                    color: "hsl(121, 60%, 23%)",
                    data: empoyeeCostsByPrivateWorkPlanningIdAndDate.data.map(
                        (employeeCost) => ({
                            x: employeeCost.employee.name,
                            y: employeeCost.clearance.workCost,
                        })
                    ),
                },
            ];

            setNumberOfHoursSumEmpoyeeCostsByPrivateWorkPlanning(
                hoursSum.toFixed(2)
            );
            setCostsSumEmpoyeeCostsByPrivateWorkPlanning(costSum.toFixed(2));
            setEmpoyeeCostsByPrivateWorkPlanningIdAndDateGraph(
                empoyeeCostsByPrivateWorkPlanningIdAndDateGraphAux
            );
        } else {
            setNumberOfHoursSumEmpoyeeCostsByPrivateWorkPlanning(0);
            setCostsSumEmpoyeeCostsByPrivateWorkPlanning(0);
            setEmpoyeeCostsByPrivateWorkPlanningIdAndDateGraph([]);
        }
        setGeneralLoadingEmpoyeeCostsByPrivateWorkPlanningIdAndDate(false);
    }, [empoyeeCostsByPrivateWorkPlanningIdAndDate]);

    const handleGetEmpoyeeCostsByCompanyWorkPlanningIdAndDateSubmit =
        async () => {
            try {
                if (
                    companyWorkPlanningId &&
                    filterDateToEmployeeCostCompanyWorkPlanning
                ) {
                    setGeneralLoadingEmpoyeeCostsByCompanyWorkPlanningIdAndDate(
                        true
                    );
                    let filterData = {
                        workPlanningId: companyWorkPlanningId,
                        month:
                            new Date(
                                filterDateToEmployeeCostCompanyWorkPlanning
                            ).getMonth() + 1,
                        year: new Date(
                            filterDateToEmployeeCostCompanyWorkPlanning
                        ).getFullYear(),
                    };
                    getEmpoyeeCostsByCompanyWorkPlanningIdAndDate(filterData);
                } else {
                    handleSnackbarOpen(t("requiredWorkPlanningId"), "error");
                }
            } catch (err) {
                handleSnackbarOpen(err, "error");
                setGeneralLoadingEmpoyeeCostsByCompanyWorkPlanningIdAndDate(
                    false
                );
            }
        };

    const handleGetEmpoyeeCostsByPrivateWorkPlanningIdAndDateSubmit =
        async () => {
            try {
                if (
                    privateWorkPlanningId &&
                    filterDateToEmployeeCostPrivateWorkPlanning
                ) {
                    setGeneralLoadingEmpoyeeCostsByPrivateWorkPlanningIdAndDate(
                        true
                    );
                    let filterData = {
                        workPlanningId: privateWorkPlanningId,
                        month:
                            new Date(
                                filterDateToEmployeeCostPrivateWorkPlanning
                            ).getMonth() + 1,
                        year: new Date(
                            filterDateToEmployeeCostPrivateWorkPlanning
                        ).getFullYear(),
                    };
                    getEmpoyeeCostsByPrivateWorkPlanningIdAndDate(filterData);
                } else {
                    handleSnackbarOpen(t("requiredWorkPlanningId"), "error");
                }
            } catch (err) {
                handleSnackbarOpen(err, "error");
                setGeneralLoadingEmpoyeeCostsByPrivateWorkPlanningIdAndDate(
                    false
                );
            }
        };
    //ENDREGION EMPLOYEE COSTS BY WORKPLANNING ID AND DATE

    //REGION EMPLOYEE COSTS BY SUB CONTRACTOR ID AND DATE
    const { data: subContractors, isLoadingSubContractors } =
        useGetSubContractorsQuery();

    const [subContractorId, setSubContractorId] = useState("");

    const [sumHoursSucontractors, setSumHoursSubcontractors] = useState(0);
    const [sumProfitSubcontractors, setSumProfitSubcontractors] = useState(0);

    const [
        generalLoadindEmpoyeeCostsBySubcontractorIdAndDate,
        setGeneralLoadingEmpoyeeCostsBySubcontractorIdAndDate,
    ] = useState(false);

    const [
        empoyeeCostsBySubcontractorIdAndDateGraph,
        setEmpoyeeCostsBySubcontractorIdAndDateGraph,
    ] = useState([]);

    const [
        filterDateToEmployeeCostSubContractor,
        setFilterDateToEmployeeCostSubContractor,
    ] = useState(subMonths(new Date(), 1));

    const [
        getEmpoyeeCostsBySucontractorIdAndDate,
        employeeCostsBySubcontractorIdAndDate,
    ] = useLazyGetEmpoyeeCostsBySucontractorIdAndDateQuery();

    useEffect(() => {
        if (subContractors && subContractors.length > 0) {
            try {
                setGeneralLoadingEmpoyeeCostsBySubcontractorIdAndDate(true);
                setSubContractorId(subContractors[0].id);

                let filterData = {
                    subcontractorId: subContractors[0].id,
                    month: subMonths(new Date(), 1).getMonth() + 1,
                    year: subMonths(new Date(), 1).getFullYear(),
                };
                getEmpoyeeCostsBySucontractorIdAndDate(filterData);
            } catch (err) {
                handleSnackbarOpen(err, "error");
                setGeneralLoadingEmpoyeeCostsBySubcontractorIdAndDate(false);
            }
        }
    }, [subContractors]);

    useEffect(() => {
        if (
            employeeCostsBySubcontractorIdAndDate.data &&
            employeeCostsBySubcontractorIdAndDate.data.length > 0
        ) {
            const hoursSum = employeeCostsBySubcontractorIdAndDate.data.reduce(
                (total, item) =>
                    total + item.employeeRegist.workHoursTotalByWork,
                0
            );
            const profitSum = employeeCostsBySubcontractorIdAndDate.data.reduce(
                (total, item) => total + item.clearance.workProfit,
                0
            );

            const empoyeeCostsBySubcontractorIdAndDateGraphAux = [
                {
                    id: t("hours"),
                    color: "hsl(265, 70%, 50%)",
                    data: employeeCostsBySubcontractorIdAndDate.data.map(
                        (employeeCost) => ({
                            x: employeeCost.employee.name,
                            y: employeeCost.employeeRegist.workHoursTotalByWork,
                        })
                    ),
                },
                {
                    id: t("profit"),
                    color: "hsl(265, 70%, 50%)",
                    data: employeeCostsBySubcontractorIdAndDate.data.map(
                        (employeeCost) => ({
                            x: employeeCost.employee.name,
                            y: employeeCost.clearance.workProfit,
                        })
                    ),
                },
            ];
            setSumHoursSubcontractors(hoursSum.toFixed(2));
            setSumProfitSubcontractors(profitSum.toFixed(2));
            setEmpoyeeCostsBySubcontractorIdAndDateGraph(
                empoyeeCostsBySubcontractorIdAndDateGraphAux
            );
        } else {
            setSumHoursSubcontractors(0);
            setSumProfitSubcontractors(0);
            setEmpoyeeCostsBySubcontractorIdAndDateGraph([]);
        }
        setGeneralLoadingEmpoyeeCostsBySubcontractorIdAndDate(false);
    }, [employeeCostsBySubcontractorIdAndDate]);

    const handleGetEmpoyeeCostsSubcontractorIdAndDateSubmit = async () => {
        try {
            if (subContractorId && filterDateToEmployeeCostSubContractor) {
                setGeneralLoadingEmpoyeeCostsBySubcontractorIdAndDate(true);

                let filterData = {
                    subcontractorId: subContractorId,
                    month:
                        new Date(
                            filterDateToEmployeeCostSubContractor
                        ).getMonth() + 1,
                    year: new Date(
                        filterDateToEmployeeCostSubContractor
                    ).getFullYear(),
                };
                getEmpoyeeCostsBySucontractorIdAndDate(filterData);
            } else {
                handleSnackbarOpen(t("requiredSubcontractorId"), "error");
            }
        } catch (err) {
            handleSnackbarOpen(err, "error");
            setGeneralLoadingEmpoyeeCostsBySubcontractorIdAndDate(false);
        }
    };
    //ENDREGION EMPLOYEE COSTS BY SUB CONTRACTOR ID AND DATE

    //REGION COST CATEGORIES RESUME BY DATE
    const [costCategoriesResumeGraph, setCostCategoriesResumeGraph] = useState(
        []
    );

    const [
        generalLoadingCostCategoriesResume,
        setGeneralLoadingCostCategoriesResume,
    ] = useState(false);

    const [monthCostCategoriesResume, setMonthCostCategoriesResume] = useState(
        subMonths(new Date(), 1)
    );

    const { data: costCategoriesResume, isLoadingCostCategoriesResume } =
        useGetCostCategoriesResumeByDateQuery({
            month: subMonths(new Date(), 1).getMonth() + 1,
            year: subMonths(new Date(), 1).getFullYear(),
        });

    const [getAllCostCategoriesResumeByMonthResume, costCategoriesResumeLazy] =
        useLazyGetCostCategoriesResumeByDateQuery();

    const [costCategoriesResumeTotal, setCostCategoriesResumeTotal] =
        useState(0);

    const [costSumCostCategoriesAux, setCostSumCostCategoriesAux] = useState(0);

    const [costCategoriesGraphAuxGraph, setCostCategoriesGraphAuxGraph] =
        useState([]);

    useEffect(() => {
        if (
            costSumCostCategoriesAux !== 0 &&
            costCategoriesGraphAuxGraph &&
            costCategoriesGraphAuxGraph.length > 0
        ) {
            setCostCategoriesResumeTotal(costSumCostCategoriesAux);
            setCostCategoriesResumeGraph(costCategoriesGraphAuxGraph);
            setGeneralLoadingCostCategoriesResume(false);
        }
    }, [costSumCostCategoriesAux, costCategoriesGraphAuxGraph]);

    useEffect(() => {
        let costSum = 0;
        let costCategoriesResumeGraphAux = [];

        if (costCategoriesResume && costCategoriesResume.length > 0) {
            setGeneralLoadingCostCategoriesResume(true);

            costSum = costCategoriesResume.reduce(
                (total, item) => total + item.cost,
                0
            );

            costCategoriesResumeGraphAux = [
                {
                    id: t("cost"),
                    color: "hsl(121, 60%, 23%)",
                    data: costCategoriesResume.map((costCategoryResume) => ({
                        x: costCategoryResume.costCategoryCategory.name,
                        y: costCategoryResume.cost,
                    })),
                },
            ];
            setCostSumCostCategoriesAux(costSum.toFixed(2));
            setCostCategoriesGraphAuxGraph(costCategoriesResumeGraphAux);
        }

        setCostCategoriesResumeTotal(costSum.toFixed(2));
        setCostCategoriesResumeGraph(costCategoriesResumeGraphAux);
        setGeneralLoadingCostCategoriesResume(false);
    }, [costCategoriesResume]);

    const handleGetCostCategoriesResumeByDateSubmit = async () => {
        try {
            if (monthCostCategoriesResume) {
                setGeneralLoadingCostCategoriesResume(true);

                let filterData = {
                    month: new Date(monthCostCategoriesResume).getMonth() + 1,
                    year: new Date(monthCostCategoriesResume).getFullYear(),
                };
                await getAllCostCategoriesResumeByMonthResume(filterData);
            } else {
                handleSnackbarOpen(t("requiredDate"), "error");
            }
        } catch (err) {
            handleSnackbarOpen(err, "error");
            setGeneralLoadingCostCategoriesResume(false);
        }
    };

    useEffect(() => {
        let costSum = 0;
        let costCategoriesResumeGraphAux = [];

        if (
            costCategoriesResumeLazy.data &&
            costCategoriesResumeLazy.data.length > 0
        ) {
            costSum = costCategoriesResumeLazy.data.reduce(
                (total, item) => total + item.cost,
                0
            );

            costCategoriesResumeGraphAux = [
                {
                    id: t("cost"),
                    color: "hsl(121, 60%, 23%)",
                    data: costCategoriesResumeLazy.data.map(
                        (costCategoryResume) => ({
                            x: costCategoryResume.costCategoryCategory.name,
                            y: costCategoryResume.cost,
                        })
                    ),
                },
            ];

            setCostCategoriesResumeTotal(costSum.toFixed(2));
            setCostCategoriesResumeGraph(costCategoriesResumeGraphAux);
        }
        setCostCategoriesResumeTotal(costSum.toFixed(2));
        setCostCategoriesResumeGraph(costCategoriesResumeGraphAux);

        setGeneralLoadingCostCategoriesResume(false);
    }, [costCategoriesResumeLazy]);
    //ENDREGION COST CATEGORIES RESUME BY DATE

    //REIGON MONTH RESUMES BY YEAR
    const [generalLoadingMonthResumes, setGeneralLoadingMonthResumes] =
        useState(false);

    // const [monthResumes, setMonthResumes] = useState(subMonths(new Date(), 1));

    const [totalHoursMonthResumesByYear, setTotalHoursMonthResumesByYear] =
        useState(0);
    const [totalCostsMonthResumesByYear, setTotalCostsMonthResumesByYear] =
        useState(0);
    const [totalProfitMonthResumesByYear, setTotalProfitMonthResumesByYear] =
        useState(0);

    const { data: monthResumesByYear, isLoadingMonthResumesByYear } =
        useGetMonthResumesByYearQuery();

    // const [getMonthResumesByYear, monthResumesByYearLazy] =
    //     useLazyGetMonthResumesByYearQuery();

    const [monthResumesByYearGraph, setMonthResumesByYearGraph] = useState([]);

    const [hoursSumMonthResumeAux, setHoursSumMonthResumeAux] = useState(0);
    const [costSumMonthResumeAux, setCostSumMonthResumeAux] = useState(0);
    const [profitSumMonthResumeAux, setProfitSumMonthResumeAux] = useState(0);
    const [monthResumeGraphAuxGraph, setMonthResumeGraphAuxGraph] = useState(
        []
    );

    useEffect(() => {
        if (
            hoursSumMonthResumeAux !== 0 &&
            costSumMonthResumeAux !== 0 &&
            profitSumMonthResumeAux !== 0 &&
            monthResumeGraphAuxGraph &&
            monthResumeGraphAuxGraph.length > 0
        ) {
            setTotalHoursMonthResumesByYear(hoursSumMonthResumeAux);
            setTotalCostsMonthResumesByYear(costSumMonthResumeAux);
            setTotalProfitMonthResumesByYear(profitSumMonthResumeAux);
            setMonthResumesByYearGraph(monthResumeGraphAuxGraph);
            setGeneralLoadingMonthResumes(false);
        }
    }, [
        hoursSumMonthResumeAux,
        costSumMonthResumeAux,
        profitSumMonthResumeAux,
        monthResumeGraphAuxGraph,
    ]);

    useEffect(() => {
        let hoursSum = 0;
        let costSum = 0;
        let profitSum = 0;
        let monthResumesByYearGraphAux = [];

        if (monthResumesByYear && monthResumesByYear.length > 0) {
            setGeneralLoadingMonthResumes(true);
            hoursSum = monthResumesByYear.reduce(
                (total, item) => total + item.hours,
                0
            );
            costSum = monthResumesByYear.reduce(
                (total, item) => total + item.costs,
                0
            );
            profitSum = monthResumesByYear.reduce(
                (total, item) => total + item.profit,
                0
            );

            monthResumesByYearGraphAux = [
                {
                    id: t("hours"),
                    color: "hsl(265, 70%, 50%)",
                    data: monthResumesByYear.map((monthResume) => ({
                        x: new Date(monthResume.monthResumeDate).getFullYear(),
                        y: monthResume.hours,
                    })),
                },
                {
                    id: t("cost"),
                    color: "hsl(121, 60%, 23%)",
                    data: monthResumesByYear.map((monthResume) => ({
                        x: new Date(monthResume.monthResumeDate).getFullYear(),
                        y: monthResume.costs,
                    })),
                },
                {
                    id: t("profit"),
                    color: "hsl(265, 70%, 50%)",
                    data: monthResumesByYear.map((monthResume) => ({
                        x: new Date(monthResume.monthResumeDate).getFullYear(),
                        y: monthResume.profit,
                    })),
                },
            ];

            setHoursSumMonthResumeAux(hoursSum.toFixed(2));
            setCostSumMonthResumeAux(costSum.toFixed(2));
            setProfitSumMonthResumeAux(profitSum.toFixed(2));
            setMonthResumeGraphAuxGraph(monthResumesByYearGraphAux);
        }

        setTotalHoursMonthResumesByYear(hoursSum);
        setTotalCostsMonthResumesByYear(costSum);
        setTotalProfitMonthResumesByYear(profitSum);
        setMonthResumesByYearGraph(monthResumesByYearGraphAux);
        setGeneralLoadingMonthResumes(false);
    }, [monthResumesByYear]);

    // const handleGetMonthResumesByYearSubmit = async () => {
    //     try {
    //         if (monthResumes) {
    //             setGeneralLoadingMonthResumes(true);
    //             await getMonthResumesByYear();
    //         } else {
    //             handleSnackbarOpen(t("requiredDate"), "error");
    //         }
    //     } catch (err) {
    //         handleSnackbarOpen(err, "error");
    //         setGeneralLoadingMonthResumes(false);
    //     }
    // };

    // useEffect(() => {
    //     let hoursSum = 0;
    //     let costSum = 0;
    //     let profitSum = 0;
    //     let monthResumesByYearGraphAux = [];

    //     if (
    //         monthResumesByYearLazy.data &&
    //         monthResumesByYearLazy.data.length > 0
    //     ) {
    //         hoursSum = monthResumesByYearLazy.data.reduce(
    //             (total, item) => total + item.hours,
    //             0
    //         );
    //         costSum = monthResumesByYearLazy.data.reduce(
    //             (total, item) => total + item.costs,
    //             0
    //         );
    //         profitSum = monthResumesByYearLazy.data.reduce(
    //             (total, item) => total + item.profit,
    //             0
    //         );

    //         monthResumesByYearGraphAux = [
    //             {
    //                 id: t("hours"),
    //                 color: "hsl(265, 70%, 50%)",
    //                 data: monthResumesByYearLazy.data.map((monthResume) => ({
    //                     x: new Date(monthResume.monthResumeDate).getFullYear(),
    //                     y: monthResume.hours,
    //                 })),
    //             },
    //             {
    //                 id: t("cost"),
    //                 color: "hsl(121, 60%, 23%)",
    //                 data: monthResumesByYearLazy.data.map((monthResume) => ({
    //                     x: new Date(monthResume.monthResumeDate).getFullYear(),
    //                     y: monthResume.costs,
    //                 })),
    //             },
    //             {
    //                 id: t("profit"),
    //                 color: "hsl(265, 70%, 50%)",
    //                 data: monthResumesByYearLazy.data.map((monthResume) => ({
    //                     x: new Date(monthResume.monthResumeDate).getFullYear(),
    //                     y: monthResume.profit,
    //                 })),
    //             },
    //         ];

    //         setTotalHoursMonthResumesByYear(hoursSum.toFixed(2));
    //         setTotalCostsMonthResumesByYear(costSum.toFixed(2));
    //         setTotalProfitMonthResumesByYear(profitSum.toFixed(2));
    //         setMonthResumesByYearGraph(monthResumesByYearGraphAux);
    //     }

    //     setTotalHoursMonthResumesByYear(hoursSum);
    //     setTotalCostsMonthResumesByYear(costSum);
    //     setTotalProfitMonthResumesByYear(profitSum);
    //     setMonthResumesByYearGraph(monthResumesByYearGraphAux);
    //     setGeneralLoadingMonthResumes(false);
    // }, [monthResumesByYearLazy.data]);

    //ENDREGION MONTH RESUMES BY YEAR
    return (
        <Box
            m={!isNonMobile ? "0 " : "1.5rem 2.5rem"}
            display="flex"
            flexDirection="column"
            gap="100px"
        >
            {isLoadingCompanyWorkResumes ||
            isLoadingPrivateWorkResumes ||
            isLoadingInternalRegistsResumes ||
            generalLoadindEmpoyeeCostsByCompanyWorkPlanningIdAndDate ||
            generalLoadingEmpoyeeCostsByPrivateWorkPlanningIdAndDate ||
            generalLoadingCompanyWorkResumes ||
            generalLoadindEmpoyeeCostsBySubcontractorIdAndDate ||
            isLoadingClients ||
            generalLoadingWorkResumesByClientId ||
            isLoadingCostCategoriesResume ||
            generalLoadingCostCategoriesResume ||
            generalLoadingMonthResumes ||
            isLoadingMonthResumesByYear ||
            generalLoadingPrivateWorkResumes ? (
                <LoadingSpinner />
            ) : (
                <>
                    <Box
                        display="grid"
                        gridTemplateColumns={
                            !isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
                        }
                        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                        padding="20px"
                        borderRadius="20px"
                    >
                        <Typography
                            variant="h1"
                            component="h2"
                            fontSize="32px"
                            fontWeight="700"
                            color="black"
                        >
                            {t("internalRegists")}
                        </Typography>
                        <Box display="flex" width="100%">
                            <Box
                                maxHeight="600px"
                                width="80%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {internalRegistsGraph &&
                                internalRegistsGraph.length > 0 ? (
                                    <LineChart
                                        data={internalRegistsGraph}
                                        onlyCosts={true}
                                    />
                                ) : (
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        fontSize="32px"
                                        fontWeight="700"
                                    >
                                        {t("withoutData")}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                gap="30px"
                                width="20%"
                                padding="30px"
                            >
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {internalRegistTotalCost + " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalCost")}
                                    </Typography>
                                </Box>

                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        views={["month", "year"]}
                                        label={t("monthResume")}
                                        value={dayjs(
                                            monthInternalRegistsResumes
                                        )}
                                        format="MM/YYYY"
                                        onChange={(value) =>
                                            setMonthInternalRegistsResume(value)
                                        }
                                    />
                                </LocalizationProvider>
                                <LoadingButton
                                    fullWidth
                                    onClick={
                                        handleGetAllInternalRegistsResumesByMonthResumeSubmit
                                    }
                                    sx={{
                                        width: "100%",
                                        p: "1rem",
                                        backgroundColor: "black",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.8)",
                                            color: "white",
                                        },
                                    }}
                                >
                                    {t("filtering")}
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        display="grid"
                        gridTemplateColumns={
                            !isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
                        }
                        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                        padding="20px"
                        borderRadius="20px"
                    >
                        <Typography
                            variant="h1"
                            component="h2"
                            fontSize="32px"
                            fontWeight="700"
                            color="black"
                        >
                            {t("companies")}
                        </Typography>
                        <Box display="flex" width="100%">
                            <Box
                                maxHeight="600px"
                                width="80%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {companyWorkResumesGraph &&
                                companyWorkResumesGraph.length > 0 ? (
                                    <LineChart
                                        data={companyWorkResumesGraph}
                                        onlyCosts={false}
                                    />
                                ) : (
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        fontSize="32px"
                                        fontWeight="700"
                                    >
                                        {t("withoutData")}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                gap="30px"
                                width="20%"
                                padding="30px"
                            >
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {totalHoursCompanyWorks}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalHours")}
                                    </Typography>
                                </Box>
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {totalCostCompanyWorks + " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalCost")}
                                    </Typography>
                                </Box>
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {totalProfitCompanyWorks + " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalProfit")}
                                    </Typography>
                                </Box>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        views={["month", "year"]}
                                        label={t("monthResume")}
                                        value={dayjs(monthCompanyWorkResumes)}
                                        format="MM/YYYY"
                                        onChange={(value) =>
                                            setMonthCompanyWorkResumes(value)
                                        }
                                    />
                                </LocalizationProvider>
                                <LoadingButton
                                    fullWidth
                                    onClick={
                                        handleGetAllCompanyWorkResumesByMonthResumeSubmit
                                    }
                                    sx={{
                                        width: "100%",
                                        p: "1rem",
                                        backgroundColor: "black",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.8)",
                                            color: "white",
                                        },
                                    }}
                                >
                                    {t("filtering")}
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        display="grid"
                        gridTemplateColumns={
                            !isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
                        }
                        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                        padding="20px"
                        borderRadius="20px"
                    >
                        <Typography
                            variant="h1"
                            component="h2"
                            fontSize="32px"
                            fontWeight="700"
                            color="black"
                        >
                            {t("employees")}
                        </Typography>
                        <Box display="flex" width="100%">
                            <Box
                                maxHeight="600px"
                                width="80%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {empoyeeCostsByCompanyWorkPlanningIdAndDateGraph &&
                                empoyeeCostsByCompanyWorkPlanningIdAndDateGraph.length >
                                    0 ? (
                                    <LineChart
                                        data={
                                            empoyeeCostsByCompanyWorkPlanningIdAndDateGraph
                                        }
                                        onlyCosts={false}
                                    />
                                ) : (
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        fontSize="32px"
                                        fontWeight="700"
                                    >
                                        {t("withoutData")}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                gap="30px"
                                width="20%"
                                padding="30px"
                            >
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {
                                            numberOfHoursSumEmpoyeeCostsByCompanyWorkPlanning
                                        }
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalHours")}
                                    </Typography>
                                </Box>

                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {costsSumEmpoyeeCostsByCompanyWorkPlanning +
                                            " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalCost")}
                                    </Typography>
                                </Box>
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {profitSumEmpoyeeCostsByCompanyWorkPlanning +
                                            " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalProfit")}
                                    </Typography>
                                </Box>
                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingCompanyWorkPlannings}
                                    options={companyWorkPlannings || []}
                                    getOptionLabel={(option) =>
                                        option.name + " - " + option.work.name
                                    }
                                    value={
                                        (companyWorkPlannings &&
                                            companyWorkPlannings.find(
                                                (workPlanning) =>
                                                    workPlanning.id ===
                                                    companyWorkPlanningId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        setCompanyWorkPlanningId(
                                            newValue ? newValue.id : ""
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("work")}
                                                {...params}
                                            />
                                        </FormControl>
                                    )}
                                />
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        views={["month", "year"]}
                                        label={t("monthResume")}
                                        value={dayjs(
                                            filterDateToEmployeeCostCompanyWorkPlanning
                                        )}
                                        format="MM/YYYY"
                                        onChange={(value) =>
                                            setFilterDateToEmployeeCostCompanyWorkPlanning(
                                                value
                                            )
                                        }
                                    />
                                </LocalizationProvider>
                                <LoadingButton
                                    fullWidth
                                    onClick={
                                        handleGetEmpoyeeCostsByCompanyWorkPlanningIdAndDateSubmit
                                    }
                                    sx={{
                                        width: "100%",
                                        p: "1rem",
                                        backgroundColor: "black",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.8)",
                                            color: "white",
                                        },
                                    }}
                                >
                                    {t("filtering")}
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        display="grid"
                        gridTemplateColumns={
                            !isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
                        }
                        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                        padding="20px"
                        borderRadius="20px"
                    >
                        <Typography
                            variant="h1"
                            component="h2"
                            color="black"
                            fontSize="32px"
                            fontWeight="700"
                        >
                            {t("private")}
                        </Typography>
                        <Box display="flex" width="100%">
                            <Box
                                maxHeight="600px"
                                width="80%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {privateWorkResumesGraph &&
                                privateWorkResumesGraph.length > 0 ? (
                                    <LineChart
                                        data={privateWorkResumesGraph}
                                        onlyCosts={false}
                                    />
                                ) : (
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        fontSize="32px"
                                        fontWeight="700"
                                    >
                                        {t("withoutData")}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                gap="30px"
                                width="20%"
                                padding="30px"
                            >
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {totalHoursPrivateWorks}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        textAlign="center"
                                        fontSize="32px"
                                        fontWeight="500"
                                    >
                                        {t("totalHours")}
                                    </Typography>
                                </Box>
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {totalCostPrivateWorks + " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        textAlign="center"
                                        fontSize="32px"
                                        fontWeight="500"
                                    >
                                        {t("totalCost")}
                                    </Typography>
                                </Box>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        views={["month", "year"]}
                                        label={t("monthResume")}
                                        value={dayjs(monthPrivateWorkResumes)}
                                        format="MM/YYYY"
                                        onChange={(value) =>
                                            setMonthPrivateWorkResumes(value)
                                        }
                                    />
                                </LocalizationProvider>
                                <LoadingButton
                                    fullWidth
                                    onClick={
                                        handleGetAllPrivateWorkResumesByMonthResumeSubmit
                                    }
                                    sx={{
                                        width: "100%",
                                        p: "1rem",
                                        backgroundColor: "black",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.8)",
                                            color: "white",
                                        },
                                    }}
                                >
                                    {t("filtering")}
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        display="grid"
                        gridTemplateColumns={
                            !isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
                        }
                        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                        padding="20px"
                        borderRadius="20px"
                    >
                        <Typography
                            variant="h1"
                            component="h2"
                            color="black"
                            fontSize="32px"
                            fontWeight="700"
                        >
                            {t("employees")}
                        </Typography>
                        <Box display="flex" width="100%">
                            <Box
                                maxHeight="600px"
                                width="80%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {empoyeeCostsByPrivateWorkPlanningIdAndDateGraph &&
                                empoyeeCostsByPrivateWorkPlanningIdAndDateGraph.length >
                                    0 ? (
                                    <LineChart
                                        data={
                                            empoyeeCostsByPrivateWorkPlanningIdAndDateGraph
                                        }
                                        onlyCosts={false}
                                    />
                                ) : (
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        fontSize="32px"
                                        fontWeight="700"
                                        color="black"
                                    >
                                        {t("withoutData")}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                gap="30px"
                                width="20%"
                                padding="30px"
                            >
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {
                                            numberOfHoursSumEmpoyeeCostsByPrivateWorkPlanning
                                        }
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalHours")}
                                    </Typography>
                                </Box>
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {costsSumEmpoyeeCostsByPrivateWorkPlanning +
                                            " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalCost")}
                                    </Typography>
                                </Box>
                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingPrivateWorkPlannings}
                                    options={privateWorkPlannings || []}
                                    getOptionLabel={(option) =>
                                        option.name + " - " + option.work.name
                                    }
                                    value={
                                        (privateWorkPlannings &&
                                            privateWorkPlannings.find(
                                                (workPlanning) =>
                                                    workPlanning.id ===
                                                    privateWorkPlanningId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        setPrivateWorkPlanningId(
                                            newValue ? newValue.id : ""
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("work")}
                                                {...params}
                                            />
                                        </FormControl>
                                    )}
                                />
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        views={["month", "year"]}
                                        label={t("monthResume")}
                                        value={dayjs(
                                            filterDateToEmployeeCostPrivateWorkPlanning
                                        )}
                                        format="MM/YYYY"
                                        onChange={(value) =>
                                            setFilterDateToEmployeeCostPrivateWorkPlanning(
                                                value
                                            )
                                        }
                                    />
                                </LocalizationProvider>
                                <LoadingButton
                                    fullWidth
                                    onClick={
                                        handleGetEmpoyeeCostsByPrivateWorkPlanningIdAndDateSubmit
                                    }
                                    sx={{
                                        width: "100%",
                                        p: "1rem",
                                        backgroundColor: "black",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.8)",
                                            color: "white",
                                        },
                                    }}
                                >
                                    {t("filtering")}
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        display="grid"
                        gridTemplateColumns={
                            !isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
                        }
                        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                        padding="20px"
                        borderRadius="20px"
                    >
                        <Typography
                            variant="h1"
                            component="h2"
                            fontSize="32px"
                            fontWeight="700"
                            color="black"
                        >
                            {t("subContractors")}
                        </Typography>
                        <Box display="flex" width="100%">
                            <Box
                                maxHeight="600px"
                                width="80%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {empoyeeCostsBySubcontractorIdAndDateGraph &&
                                empoyeeCostsBySubcontractorIdAndDateGraph.length >
                                    0 ? (
                                    <LineChartSubContractor
                                        data={
                                            empoyeeCostsBySubcontractorIdAndDateGraph
                                        }
                                        onlyCosts={false}
                                    />
                                ) : (
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        fontSize="32px"
                                        fontWeight="700"
                                    >
                                        {t("withoutData")}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                gap="30px"
                                width="20%"
                                padding="30px"
                            >
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {sumHoursSucontractors}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalHours")}
                                    </Typography>
                                </Box>
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {sumProfitSubcontractors + " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        textAlign="center"
                                        fontSize="32px"
                                        fontWeight="500"
                                    >
                                        {t("totalProfit")}
                                    </Typography>
                                </Box>
                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingSubContractors}
                                    options={subContractors || []}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                        (subContractors &&
                                            subContractors.find(
                                                (subContractor) =>
                                                    subContractor.id ===
                                                    subContractorId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        setSubContractorId(
                                            newValue ? newValue.id : ""
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("subContractor")}
                                                {...params}
                                            />
                                        </FormControl>
                                    )}
                                />
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        views={["month", "year"]}
                                        label={t("monthResume")}
                                        value={dayjs(
                                            filterDateToEmployeeCostSubContractor
                                        )}
                                        format="MM/YYYY"
                                        onChange={(value) =>
                                            setFilterDateToEmployeeCostSubContractor(
                                                value
                                            )
                                        }
                                    />
                                </LocalizationProvider>
                                <LoadingButton
                                    fullWidth
                                    onClick={
                                        handleGetEmpoyeeCostsSubcontractorIdAndDateSubmit
                                    }
                                    sx={{
                                        width: "100%",
                                        p: "1rem",
                                        backgroundColor: "black",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.8)",
                                            color: "white",
                                        },
                                    }}
                                >
                                    {t("filtering")}
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        display="grid"
                        gridTemplateColumns={
                            !isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
                        }
                        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                        padding="20px"
                        borderRadius="20px"
                    >
                        <Typography
                            variant="h1"
                            component="h2"
                            fontSize="32px"
                            fontWeight="700"
                            color="black"
                        >
                            {t("clients")}
                        </Typography>
                        <Box display="flex" width="100%">
                            <Box
                                maxHeight="600px"
                                width="80%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {workResumesByClientIdGraph &&
                                workResumesByClientIdGraph.length > 0 ? (
                                    <LineChart
                                        data={workResumesByClientIdGraph}
                                        onlyCosts={false}
                                    />
                                ) : (
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        fontSize="32px"
                                        fontWeight="700"
                                    >
                                        {t("withoutData")}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                gap="30px"
                                width="20%"
                                padding="30px"
                            >
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {totalHoursWorkResumesByClientId}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalHours")}
                                    </Typography>
                                </Box>
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {totalCostWorkResumesByClientId + " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalCost")}
                                    </Typography>
                                </Box>
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {totalProfitWorkResumesByClientId +
                                            " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalProfit")}
                                    </Typography>
                                </Box>
                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingClients}
                                    options={clients || []}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                        (clients &&
                                            clients.find(
                                                (client) =>
                                                    client.id === clientId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        setClientId(
                                            newValue ? newValue.id : ""
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("client")}
                                                {...params}
                                            />
                                        </FormControl>
                                    )}
                                />
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        views={["month", "year"]}
                                        label={t("monthResume")}
                                        value={dayjs(
                                            monthWorkResumesByClientIdDate
                                        )}
                                        format="MM/YYYY"
                                        onChange={(value) =>
                                            setMonthWorkResumesByClientIdDate(
                                                value
                                            )
                                        }
                                    />
                                </LocalizationProvider>
                                <LoadingButton
                                    fullWidth
                                    onClick={
                                        handleGetWorkResumesByClientIdAndDateSubmit
                                    }
                                    type="button"
                                    sx={{
                                        width: "100%",
                                        p: "1rem",
                                        backgroundColor: "black",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.8)",
                                            color: "white",
                                        },
                                    }}
                                >
                                    {t("filtering")}
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        display="grid"
                        gridTemplateColumns={
                            !isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
                        }
                        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                        padding="20px"
                        borderRadius="20px"
                    >
                        <Typography
                            variant="h1"
                            component="h2"
                            fontSize="32px"
                            fontWeight="700"
                            color="black"
                        >
                            {t("costCategories")}
                        </Typography>
                        <Box display="flex" width="100%">
                            <Box
                                maxHeight="600px"
                                width="80%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {costCategoriesResumeGraph &&
                                costCategoriesResumeGraph.length > 0 ? (
                                    <LineChartCostCategories
                                        data={costCategoriesResumeGraph}
                                        onlyCosts={false}
                                    />
                                ) : (
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        fontSize="32px"
                                        fontWeight="700"
                                    >
                                        {t("withoutData")}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                gap="30px"
                                width="20%"
                                padding="30px"
                            >
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {costCategoriesResumeTotal + " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        textAlign="center"
                                        fontSize="32px"
                                        fontWeight="500"
                                    >
                                        {t("totalCost")}
                                    </Typography>
                                </Box>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        views={["month", "year"]}
                                        label={t("monthResume")}
                                        value={dayjs(monthCostCategoriesResume)}
                                        format="MM/YYYY"
                                        onChange={(value) =>
                                            setMonthCostCategoriesResume(value)
                                        }
                                    />
                                </LocalizationProvider>
                                <LoadingButton
                                    fullWidth
                                    onClick={
                                        handleGetCostCategoriesResumeByDateSubmit
                                    }
                                    sx={{
                                        width: "100%",
                                        p: "1rem",
                                        backgroundColor: "black",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.8)",
                                            color: "white",
                                        },
                                    }}
                                >
                                    {t("filtering")}
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        display="grid"
                        gridTemplateColumns={
                            !isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
                        }
                        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                        padding="20px"
                        borderRadius="20px"
                        marginBottom="100px"
                    >
                        <Typography
                            variant="h1"
                            component="h2"
                            fontSize="32px"
                            fontWeight="700"
                            color="black"
                        >
                            {t("balance")}
                        </Typography>
                        <Box display="flex" width="100%">
                            <Box
                                maxHeight="600px"
                                width="78%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {monthResumesByYearGraph &&
                                monthResumesByYearGraph.length > 0 ? (
                                    <LineChart
                                        data={monthResumesByYearGraph}
                                        onlyCosts={false}
                                    />
                                ) : (
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        fontSize="32px"
                                        fontWeight="700"
                                    >
                                        {t("withoutData")}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                gap="30px"
                                width="22%"
                                padding="30px"
                            >
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {totalHoursMonthResumesByYear}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalHours")}
                                    </Typography>
                                </Box>
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {totalCostsMonthResumesByYear + " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalCost")}
                                    </Typography>
                                </Box>
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {totalProfitMonthResumesByYear + " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalProfit")}
                                    </Typography>
                                </Box>
                                {/* <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        views={["year"]}
                                        label={t("monthResume")}
                                        value={dayjs(monthResumes)}
                                        format="YYYY"
                                        onChange={(value) =>
                                            setMonthResumes(value)
                                        }
                                    />
                                </LocalizationProvider>
                                <LoadingButton
                                    fullWidth
                                    onClick={handleGetMonthResumesByYearSubmit}
                                    sx={{
                                        width: "100%",
                                        p: "1rem",
                                        backgroundColor: "black",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.8)",
                                            color: "white",
                                        },
                                    }}
                                >
                                    {t("filtering")}
                                </LoadingButton> */}
                            </Box>
                        </Box>
                    </Box>

                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity={snackbarSeverity}
                            sx={{
                                mt: "10px",
                                backgroundColor:
                                    snackbarSeverity === "success"
                                        ? "#10781F"
                                        : "#ff000082",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {snackbarMsg}
                        </Alert>
                    </Snackbar>
                    {/* <Box
                    height="70vh"
                    maxHeight="70vh"
                    display="grid"
                    gap="1rem"
                    gridTemplateColumns={
                        !isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
                    }
                >
                    <Box height="500px" width={!isNonMobile ? "100vw" : "100%"}>
                        <LineChart data={lineData} />
                    </Box> */}
                    {/* <Box height="500px" width={!isNonMobile ? "100vw" : "100%"}>
                        <BarChart data={barData} />
                    </Box>
                    <Box height="500px" width={!isNonMobile ? "100vw" : "100%"}>
                        <PieChart data={pieData} />
                    </Box>
                    <Box height="500px" width={!isNonMobile ? "100vw" : "100%"}>
                        <CalendarChart data={calendarData} />
                    </Box> */}
                    {/* <PowerBIEmbed
                        embedConfig={{
                            type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                            id: "56b3e9a3-ea49-46ee-9452-0bf8c7a09c97",
                            embedUrl:
                                "https://app.powerbi.com/reportEmbed?reportId=56b3e9a3-ea49-46ee-9452-0bf8c7a09c97&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLU5PUlRILUVVUk9QRS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19",
                            accessToken:
                                "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IjVCM25SeHRRN2ppOGVORGMzRnkwNUtmOTdaRSIsImtpZCI6IjVCM25SeHRRN2ppOGVORGMzRnkwNUtmOTdaRSJ9.eyJhdWQiOiJodHRwczovL2FuYWx5c2lzLndpbmRvd3MubmV0L3Bvd2VyYmkvYXBpIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvN2E2MGE2MjktZTYxZi00MzBjLTljNjMtMjY2NjUzYjJiODk5LyIsImlhdCI6MTcwNTQwMjkyMSwibmJmIjoxNzA1NDAyOTIxLCJleHAiOjE3MDU0MDc3MDIsImFjY3QiOjAsImFjciI6IjEiLCJhaW8iOiJFMlZnWUdoUU4zZE5DZndlOGJ2L1NZbUlabnR4dDIzN3p4MXBTZ0dQWlRRc3pWM0M1b2UvOEEvek9KdktIRDZwNHFiYS9nLy9BQT09IiwiYW1yIjpbInB3ZCJdLCJhcHBpZCI6Ijg3MWMwMTBmLTVlNjEtNGZiMS04M2FjLTk4NjEwYTdlOTExMCIsImFwcGlkYWNyIjoiMCIsImZhbWlseV9uYW1lIjoiTW9yZWlyYSIsImdpdmVuX25hbWUiOiJIdWdvIiwiaXBhZGRyIjoiMjAwMTo4MTg6ZGI5MDo0MzAwOjU1NmQ6MTIxMDpiNTg3OjRmMGMiLCJuYW1lIjoiSHVnbyBNb3JlaXJhICgxMTYxMjY5KSIsIm9pZCI6ImIzZWZjM2ZiLWEzMTgtNDc0ZS05YWIwLTdjZDliNTQxMjM4OSIsInB1aWQiOiIxMDAzN0ZGRTlBOEYxMUJDIiwicmgiOiIwLkFRVUFLYVpnZWhfbURFT2NZeVptVTdLNG1Ra0FBQUFBQUFBQXdBQUFBQUFBQUFBYkFZQS4iLCJzY3AiOiJ1c2VyX2ltcGVyc29uYXRpb24iLCJzdWIiOiJ4NzNJT2Z2V0hhckdmRVFvMThKUzhFZW9Cc0hneGRlYjUyUm1CNE1KQjl3IiwidGlkIjoiN2E2MGE2MjktZTYxZi00MzBjLTljNjMtMjY2NjUzYjJiODk5IiwidW5pcXVlX25hbWUiOiIxMTYxMjY5QGlzZXAuaXBwLnB0IiwidXBuIjoiMTE2MTI2OUBpc2VwLmlwcC5wdCIsInV0aSI6InJUWWJ3UXZMcFUtTFJQTUZqV09FQUEiLCJ2ZXIiOiIxLjAiLCJ3aWRzIjpbImI3OWZiZjRkLTNlZjktNDY4OS04MTQzLTc2YjE5NGU4NTUwOSJdLCJ4bXNfcGwiOiJwdC1QVCJ9.2xSdOH-nmKp-2osMRzHYDjxd_FEHRmlsx3CmKbn-hv4XXJTexiU7W3Ox2l6XTRkhi5pqUKyPs0uv6rH-DxbWUk39_6iYYgMsYtxi5PbWfYCW_JZmAkhRe_tvXFQYtkOESx9IBEhbzErfB5VfStdhxvyth9JsqGazIm6mdurmfr8ielTMBewbMwNcppg3KfbAfvLCP-PURxNkWVKevc0aWKO0h-Li2hJhvMdnYPDLiSOZhk1OqcXnKPcvc2jpjXownW-x9xeOPbzMHxDozt4-6jtLUSfSexBw09TPlrjFVKak6fFFZa8KOeHc6iYtBLPkWgLY95euPAl39tRbBvBRsg",
                            tokenType: models.TokenType.Aad, // Use models.TokenType.Aad for SaaS embed
                            settings: {
                                panes: {
                                    filters: {
                                        expanded: false,
                                        visible: false,
                                    },
                                },
                                background: models.BackgroundType.Transparent,
                            },
                        }}
                        eventHandlers={
                            new Map([
                                [
                                    "loaded",
                                    function () {
                                        console.log("Report loaded");
                                    },
                                ],
                                [
                                    "rendered",
                                    function () {
                                        console.log("Report rendered");
                                    },
                                ],
                                [
                                    "error",
                                    function (event) {
                                        console.log(event.detail);
                                    },
                                ],
                                [
                                    "visualClicked",
                                    () => console.log("visual clicked"),
                                ],
                                ["pageChanged", (event) => console.log(event)],
                            ])
                        }
                        cssClassName={"Embed-container"}
                        getEmbeddedComponent={(embeddedReport) => {
                            window.Report = embeddedReport;
                        }}
                    /> */}
                    {/* </Box> */}
                </>
            )}
        </Box>
    );
};

export default HomePage;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    lng:
        localStorage.getItem("language") === null
            ? "pt"
            : JSON.parse(localStorage.getItem("language")), // Default language
    fallbackLng:
        localStorage.getItem("language") === null
            ? "pt"
            : JSON.parse(localStorage.getItem("language")), // Fallback language
    resources: {
        en: {
            translations: require("./locales/en/translation.json"),
        },
        pt: {
            translations: require("./locales/pt/translation.json"),
        },
        es: {
            translations: require("./locales/es/translation.json"),
        },
        fr: {
            translations: require("./locales/fr/translation.json"),
        },
    },
    ns: ["translations"],
    defaultNS: "translations",
    debug: false, // Enable debug mode for logging
    interpolation: {
        escapeValue: false, // React already escapes variables, so no need for additional escaping
    },
});

i18n.languages = ["en", "pt", "es", "fr"];

export default i18n;

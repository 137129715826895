import { useState, useRef } from "react";
import {
    usePostEmployeeMutation,
    useGetSalaryTypesQuery,
} from "./employeesApiSlice";
import { useGetAllEmployeeCategoriesQuery } from "features/employeeCategories/employeeCategoriesApiSlice";
import PageHeader from "components/PageHeader";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
    Box,
    TextField,
    Autocomplete,
    Typography,
    useTheme,
    Alert,
    useMediaQuery,
    FormControl,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";
import { useGetSubContractorsQuery } from "features/subContractors/subContractorsApiSlice";

const NewEmployeeForm = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate(); /* Set the Navigation Service */
    const location = useLocation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const nameRef = useRef(); /* User Ref to set the focus to the user input */

    const [errMsg, setErrMsg] = useState(""); /* Set the Error Message State */
    const [openSnackbar, setOpenSnackBar] = useState(false);

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    };

    const { name, phoneNumber, categoryId } = useParams();

    const employeeSchema = yup.object().shape({
        name: yup.string().required(t("requiredField")),
        address: yup.string().required(t("requiredField")),
        email: yup.string().email(t("invalidEmail")),
        birthDate: yup.date().notRequired(),
        epiDate: yup.date().notRequired(),
        skillsSheetDate: yup.date().notRequired(),
        nInternal: yup
            .number(t("invalidValue"))
            .positive(t("invalidValue"))
            .required(t("requiredField"))
            .typeError(t("invalidValue")),
        iban: yup.string().required(t("requiredField")),
        phoneNumber: yup.string().required(t("requiredField")),
        cost: yup
            .number(t("invalidValue"))
            .positive(t("invalidValue"))
            .required(t("requiredField"))
            .typeError(t("invalidValue")),
        categoryId: yup.string().required(t("requiredField")),
        subContractorId: yup.string().notRequired(),
        salaryTypeId: yup.string().required(t("requiredField")),
    }); /* Set the employee forms Schema */

    const initialValuesEmployee = {
        name: name ? name : "",
        address: "",
        email: "",
        nInternal: "",
        iban: "",
        birthDate: null,
        epiDate: null,
        skillsSheetDate: null,
        phoneNumber: phoneNumber ? phoneNumber : "",
        cost: "",
        categoryId: categoryId ? parseInt(categoryId) : "",
        subContractorId: null,
        salaryTypeId: "",
    }; /* Set the Forms Initial Values */

    const { data: employeeCategories, isLoading: isLoadingEmployeeCategory } =
        useGetAllEmployeeCategoriesQuery();

    const { data: salaryTypes, isLoading: isLoadingSalaryType } =
        useGetSalaryTypesQuery();

    const { data: subContractors, isLoading: isLoadingSubContractors } =
        useGetSubContractorsQuery();

    const [createEmployee, { isLoading }] = usePostEmployeeMutation();

    const handleFormSubmit = async (values, onSubmitProps) => {
        setErrMsg("");
        try {
            if (!values.birthDate || values.birthDate === "") {
                setErrMsg(t("requiredEmployeeDates"));
            } else {
                setErrMsg("");
                let modifiedValues = { ...values };

                if (modifiedValues.subContractorId === "") {
                    modifiedValues.subContractorId = null;
                }
                const { codeResult, employeeId } = await createEmployee(
                    modifiedValues
                ).unwrap();

                if (codeResult !== RESPONSES.employee.successToInsertEmployee) {
                    if (codeResult === RESPONSES.employee.invalidEmployee) {
                        setErrMsg(t("invalidEmployee"));
                    } else {
                        setErrMsg(t("errorInsertEmployee"));
                    }
                } else {
                    setOpenSnackBar(true);
                    onSubmitProps.resetForm();
                    navigate(`${location.pathname.replace("new", employeeId)}`);
                }
            }
        } catch (err) {
            setErrMsg(t("errorInsertEmployee"));
        }
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesEmployee}
            validationSchema={employeeSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        m={!isNonMobile ? "0" : "1.5rem 2.5rem"}
                        display="grid"
                        gridTemplateRows="repeat(3, auto)"
                        gap="20px"
                        borderRadius="5px"
                        p="2rem"
                    >
                        <PageHeader title={t("newEmployeeTitle")} subtitle="" />
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Box display="flex" justifyContent="space-between">
                                <Typography
                                    fontWeight="500"
                                    fontSize="20px"
                                    color="primary"
                                    variant="h5"
                                    textAlign="left"
                                >
                                    {t("employeeInformation")}
                                </Typography>
                            </Box>
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <TextField
                                    autoFocus={!isNonMobile ? false : true}
                                    variant="outlined"
                                    label={t("name")}
                                    inputRef={nameRef}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    name="name"
                                    error={
                                        Boolean(touched.name) &&
                                        Boolean(errors.name)
                                    }
                                    helperText={touched.name && errors.name}
                                    sx={{ width: "100%" }}
                                />
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        label={t("birthDate")}
                                        onBlur={handleBlur}
                                        value={values.birthDate}
                                        format="DD/MM/YYYY"
                                        onChange={(value) =>
                                            setFieldValue("birthDate", value)
                                        }
                                        error={
                                            Boolean(touched.birthDate) &&
                                            Boolean(errors.birthDate)
                                        }
                                        helperText={
                                            touched.birthDate &&
                                            errors.birthDate
                                        }
                                    />
                                </LocalizationProvider>
                                <TextField
                                    label={t("email")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                    name="email"
                                    error={
                                        Boolean(touched.email) &&
                                        Boolean(errors.email)
                                    }
                                    helperText={touched.email && errors.email}
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("address")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.address}
                                    name="address"
                                    error={
                                        Boolean(touched.address) &&
                                        Boolean(errors.address)
                                    }
                                    helperText={
                                        touched.address && errors.address
                                    }
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("nInternal")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.nInternal}
                                    name="nInternal"
                                    error={
                                        Boolean(touched.nInternal) &&
                                        Boolean(errors.nInternal)
                                    }
                                    helperText={
                                        touched.nInternal && errors.nInternal
                                    }
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("iban")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.iban}
                                    name="iban"
                                    error={
                                        Boolean(touched.iban) &&
                                        Boolean(errors.iban)
                                    }
                                    helperText={touched.iban && errors.iban}
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("phoneNumber")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                    name="phoneNumber"
                                    error={
                                        Boolean(touched.phoneNumber) &&
                                        Boolean(errors.phoneNumber)
                                    }
                                    helperText={
                                        touched.phoneNumber &&
                                        errors.phoneNumber
                                    }
                                    sx={{ width: "100%" }}
                                />
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        label={t("epiDate")}
                                        onBlur={handleBlur}
                                        value={values.epiDate}
                                        format="DD/MM/YYYY"
                                        onChange={(value) =>
                                            setFieldValue("epiDate", value)
                                        }
                                        error={
                                            Boolean(touched.epiDate) &&
                                            Boolean(errors.epiDate)
                                        }
                                        helperText={
                                            touched.epiDate && errors.epiDate
                                        }
                                    />
                                    <DatePicker
                                        label={t("skillSheetDate")}
                                        onBlur={handleBlur}
                                        value={values.skillsSheetDate}
                                        format="DD/MM/YYYY"
                                        onChange={(value) =>
                                            setFieldValue(
                                                "skillsSheetDate",
                                                value
                                            )
                                        }
                                        error={
                                            Boolean(touched.skillsSheetDate) &&
                                            Boolean(errors.skillsSheetDate)
                                        }
                                        helperText={
                                            touched.skillsSheetDate &&
                                            errors.skillsSheetDate
                                        }
                                    />
                                </LocalizationProvider>
                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingSubContractors}
                                    options={subContractors || []}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                        (subContractors &&
                                            subContractors.find(
                                                (subContractorId) =>
                                                    subContractorId.id ===
                                                    values.subContractorId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "subContractorId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                    }}
                                    onBlur={handleBlur("subContractorId")}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("subContractor")}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.subContractorId
                                                    ) &&
                                                    Boolean(
                                                        errors.subContractorId
                                                    )
                                                }
                                                helperText={
                                                    touched.subContractorId &&
                                                    errors.subContractorId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />
                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingEmployeeCategory}
                                    options={employeeCategories || []}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                        (employeeCategories &&
                                            employeeCategories.find(
                                                (category) =>
                                                    category.id ===
                                                    values.categoryId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "categoryId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                    }}
                                    onBlur={handleBlur("categoryId")}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("category")}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.categoryId
                                                    ) &&
                                                    Boolean(errors.categoryId)
                                                }
                                                helperText={
                                                    touched.categoryId &&
                                                    errors.categoryId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />

                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingSalaryType}
                                    options={salaryTypes || []}
                                    getOptionLabel={(option) => option.type}
                                    value={
                                        (salaryTypes &&
                                            salaryTypes.find(
                                                (salary) =>
                                                    salary.id ===
                                                    values.salaryTypeId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: "salaryTypeId",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        });
                                    }}
                                    onBlur={handleBlur("salaryTypeId")}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("salaryType")}
                                                {...params}
                                                error={
                                                    Boolean(
                                                        touched.salaryTypeId
                                                    ) &&
                                                    Boolean(errors.salaryTypeId)
                                                }
                                                helperText={
                                                    touched.salaryTypeId &&
                                                    errors.salaryTypeId
                                                }
                                            />
                                        </FormControl>
                                    )}
                                />

                                {values.salaryTypeId === 2 ? (
                                    <TextField
                                        label={t("hourSalary")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.cost}
                                        name="cost"
                                        error={
                                            Boolean(touched.cost) &&
                                            Boolean(errors.cost)
                                        }
                                        InputProps={{
                                            endAdornment: "€",
                                        }}
                                        helperText={touched.cost && errors.cost}
                                        sx={{ width: "100%" }}
                                    />
                                ) : (
                                    <TextField
                                        label={t("salary")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.cost}
                                        name="cost"
                                        error={
                                            Boolean(touched.cost) &&
                                            Boolean(errors.cost)
                                        }
                                        InputProps={{
                                            endAdornment: "€",
                                        }}
                                        helperText={touched.cost && errors.cost}
                                        sx={{ width: "100%" }}
                                    />
                                )}
                            </Box>
                        </Box>
                        {errMsg && (
                            <Alert
                                severity="error"
                                sx={{
                                    mt: "10px",
                                    backgroundColor: "#540e0e",
                                    color: "#FFFFFF",
                                    alignItems: "center",
                                }}
                            >
                                {errMsg}
                            </Alert>
                        )}
                        {/* BUTTONS */}
                        <LoadingButton
                            fullWidth
                            type="submit"
                            loading={isLoading}
                            sx={{
                                width: "100%",
                                p: "1rem",
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.neutral.white,
                                "&:hover": {
                                    backgroundColor:
                                        theme.palette.primary.light,
                                },
                            }}
                        >
                            {t("addNewEmployee")}
                        </LoadingButton>
                    </Box>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity="success"
                            sx={{
                                mt: "10px",
                                backgroundColor: "#10781F",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {t("successToInsertEmployee")}
                        </Alert>
                    </Snackbar>
                </form>
            )}
        </Formik>
    );
};

export default NewEmployeeForm;

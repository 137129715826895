import { useState, useRef, useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import PageHeader from "components/PageHeader";
import LoadingSpinner from "components/LoadingSpinner";
import {
    Box,
    TextField,
    Typography,
    Button,
    useTheme,
    Alert,
    IconButton,
    Card,
    CardContent,
    useMediaQuery,
} from "@mui/material";
import { DeleteOutlined } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";
import FlexBetween from "components/FlexBetween";
import { useParams } from "react-router-dom";
import Dropzone from "react-dropzone";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
    useDeleteEquipmentByIdMutation,
    useGetEquipmentByIdQuery,
    useUpdateEquipmentMutation,
} from "./equipmentsApiSlice";
import {
    useDeleteAttachmentMutation,
    useUploadAttachmentMutation,
} from "features/attachments/attachmentsApiSlice";

const EditEquipmentForm = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate(); /* Set the Navigation Service */
    const location = useLocation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const nameRef = useRef(); /* User Ref to set the focus to the user input */

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
        useState(false);

    const handleOpenDeleteConfirmationDialog = () => {
        setOpenDeleteConfirmationDialog(true);
    };

    const handleCloseDeleteConfirmationDialog = () => {
        setOpenDeleteConfirmationDialog(false);
    };

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMsg(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const [files, setFiles] = useState([]);

    //#endregion Setups

    const { id } = useParams();

    const { data: equipment, isLoading: isLoadingGetEquipment } =
        useGetEquipmentByIdQuery(id);

    const [updateEquipment, { isLoading: isLoadingUpdateEquipment }] =
        useUpdateEquipmentMutation();

    const [deleteEquipment, { isLoading: isLoadingDeleteEquipment }] =
        useDeleteEquipmentByIdMutation();

    useEffect(() => {
        if (equipment) {
            const processAttachments = async () => {
                const attachmentPromises = equipment.attachments.map(
                    async (attachment) => {
                        let file = "";
                        if (!attachment.preSignedUrl) {
                            file = await base64ToFile(
                                attachment.content,
                                attachment.fileName,
                                attachment.fileType
                            );
                        }
                        return { ...attachment, file };
                    }
                );

                try {
                    const attachments = await Promise.all(attachmentPromises);
                    setFiles(
                        attachments.filter(
                            (attachment) => !!attachment.fileName.split("/")[2]
                        )
                    );
                } catch (error) {
                    console.error("Error processing attachments:", error);
                }
            };

            processAttachments();
        }
    }, [equipment]);

    function base64ToFile(base64Data, fileName, mimeType) {
        let base64Content = null;
        if (base64Data.split(",").length > 1) {
            base64Content = base64Data.split(",")[1];
        } else {
            base64Content = base64Data;
        }

        const uint8Array = new Uint8Array(
            atob(base64Content)
                .split("")
                .map((char) => char.charCodeAt(0))
        );

        const blob = new Blob([uint8Array], { type: mimeType });

        const file = new File([blob], fileName, { type: mimeType });

        return file;
    }

    const equipmentSchema = yup.object().shape({
        name: yup.string().required(t("requiredField")),
        acquisitionDate: yup.date().notRequired(),
    });

    const initialValuesEquipment = {
        name: "",
        description: "",
        acquisitionDate: null,
        attachments: [],
    };

    //#endregion companyDoc

    const handleRemoveFile = (event, file) => {
        event.stopPropagation();
        const updatedFiles = files.filter((f) => f !== file);
        setFiles(updatedFiles);
    };

    const onDropFiles = (acceptedFiles) => {
        const updatedFiles = [...files];

        acceptedFiles.forEach((file) => {
            const existingFile = updatedFiles.find(
                (f) => f.fileName === file.name
            );
            if (!existingFile) {
                updatedFiles.push({
                    fileName: file.name,
                    fileType: file.type,
                    preSignedUrl: "",
                    content: "",
                    file: file,
                });
            } else {
                handleSnackbarOpen(
                    t("file") + file.name + t("exists"),
                    "error"
                );
            }
        });

        setFiles(updatedFiles);
    };

    const convertToBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handleFilePreview = async (event, file) => {
        event.stopPropagation();
        if (file.preSignedUrl) {
            try {
                const newWindow = window.open(file.preSignedUrl, "_blank");
                if (newWindow) newWindow.opener = null;
            } catch (err) {
                handleSnackbarOpen(t("errorWhenDownload"), "error");
            }
        } else {
            try {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(file.file);
                link.download = file.fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (err) {
                handleSnackbarOpen(t("errorWhenDownload"), "error");
            }
        }
    };

    const [uploadAttachment, { isLoading: isLoadingUploadAttachment }] =
        useUploadAttachmentMutation();

    const [deleteAttachment, { isLoading: isLoadingDeleteAttachment }] =
        useDeleteAttachmentMutation();

    const [loadingFiles, setLoadingFiles] = useState(false);
    const [isToUpdateEquipment, setIsToUpdateEquipment] = useState(false);

    const handleFormSubmit = async (values, onSubmitProps) => {
        if (!values.acquisitionDate || values.acquisitionDate === "") {
            handleSnackbarOpen(t("requiredAcquisitionDate"), "error");
        } else {
            let attachments = [];
            setLoadingFiles(true);
            let isToReload = false;
            try {
                // add only the new attachments
                const _files = files.filter((file) => !!file.file);

                await Promise.all(
                    _files.map(async (file) => {
                        if (
                            values.attachments.length === 0 ||
                            values.attachments.filter(
                                (a) => a.fileName === file.fileName
                            ).length === 0
                        ) {
                            let attachment = {
                                id: 0,
                                fileName: file.fileName,
                                content: file.file,
                                fileType: file.fileType,
                            };
                            attachments.push(attachment);
                        }
                    })
                );

                let newAttachments = [...attachments.filter((a) => a.id === 0)];

                let attachmentsToAdd = {
                    attachments: [...newAttachments],
                };

                // region delete attachments
                let attachmentsToDelete = [];
                values.attachments.forEach((attachment) => {
                    const _attachment = {
                        ...attachment,
                        fileName: attachment.fileName.split("/")[2],
                        fileType: attachment.fileType || "image/jpg",
                    };
                    if (
                        files.filter((f) => f.fileName === attachment.fileName)
                            .length === 0
                    ) {
                        delete _attachment.preSignedUrl;
                        if (attachment.fileName.split("/")[2]) {
                            attachmentsToDelete.push(_attachment);
                        }
                    }
                });

                const createPromises = [];

                if (attachmentsToAdd.attachments.length > 0) {
                    isToReload = true;
                    attachmentsToAdd.attachments.forEach((attachment) => {
                        let addAttachmentsDTO = {
                            Id: parseInt(id),
                            Attachment: attachment,
                            EntityName: "Equipments",
                        };

                        // Store the promise in the array
                        const promise =
                            uploadAttachment(addAttachmentsDTO).unwrap();

                        createPromises.push(promise);
                    });

                    await Promise.all(createPromises).then(async (results) => {
                        // Create an array of promises from results
                        const processPromises = results.map(
                            async ({
                                codeResult,
                                signedUrl,
                                expiresIn,
                                fileName,
                            }) => {
                                if (
                                    codeResult !==
                                    RESPONSES.client.sucessToAddAttachments
                                ) {
                                    handleSnackbarOpen(
                                        t("anErrorOcurred"),
                                        "error"
                                    );
                                } else {
                                    const attachment =
                                        attachmentsToAdd.attachments.find(
                                            (attachment) =>
                                                attachment.fileName === fileName
                                        );

                                    return fetch(signedUrl, {
                                        method: "PUT",
                                        body: attachment.content,
                                        headers: {
                                            "Content-Type":
                                                "multipart/form-data",
                                            mimeType: attachment.fileType,
                                        },
                                    });
                                }
                            }
                        );

                        // Wait for all processes to complete
                        await Promise.all(processPromises);
                        handleSnackbarOpen(t("sucess"), "success");
                    });
                }

                const deletePromises = [];

                if (attachmentsToDelete.length > 0) {
                    isToReload = true;
                    attachmentsToDelete.forEach((attachment) => {
                        let addAttachmentsDTO = {
                            Id: parseInt(id),
                            Attachment: attachment,
                            EntityName: "Equipments",
                        };
                        // Store the promise in the array
                        const promise =
                            deleteAttachment(addAttachmentsDTO).unwrap();

                        deletePromises.push(promise);
                    });

                    await Promise.all(deletePromises).then((results) => {
                        // Handle the results after all promises are resolved
                        results.forEach(({ codeResult }) => {
                            if (
                                codeResult !==
                                RESPONSES.client.sucessToAddAttachments
                            ) {
                                handleSnackbarOpen(
                                    t("anErrorOcurred"),
                                    "error"
                                );
                            } else {
                                handleSnackbarOpen(t("sucess"), "success");
                            }
                        });
                    });
                }
            } catch (err) {
                handleSnackbarOpen(t("anErrorOcurred"), "error");
            }
            setLoadingFiles(false);
            if (isToUpdateEquipment === true) {
                values.attachments = null;
                const { codeResult } = await updateEquipment(values).unwrap();

                if (
                    codeResult !== RESPONSES.equipment.sucessToUpdateEquipment
                ) {
                    if (
                        codeResult ===
                        RESPONSES.equipment.invalidToUpdateEquipment
                    ) {
                        handleSnackbarOpen(
                            t("invalidToUpdateEquipment"),
                            "error"
                        );
                    } else if (
                        codeResult === RESPONSES.equipment.invalidEquipement
                    ) {
                        handleSnackbarOpen(t("invalidEquipment"), "error");
                    } else {
                        handleSnackbarOpen(t("errorUpdateEquipment"), "error");
                    }
                } else {
                    handleSnackbarOpen(t("sucessToUpdateEquipment"), "success");
                }
                setIsToUpdateEquipment(false);
            } else {
                if (isToReload === true) {
                    window.location.reload();
                }
            }
        }
    };

    const handleDeleteEquipment = async () => {
        setOpenDeleteConfirmationDialog(false);

        try {
            const { codeResult } = await deleteEquipment(id).unwrap();
            if (codeResult !== RESPONSES.equipment.sucessToDeleteEquipment) {
                if (
                    codeResult === RESPONSES.equipment.invalidToDeleteEquipment
                ) {
                    handleSnackbarOpen(t("invalidToDeleteEquipment"), "error");
                } else {
                    handleSnackbarOpen(t("errorDeleteEquipment"), "error");
                }
            } else {
                navigate(`/${location.pathname.split("/")[1]}`);
            }
        } catch (err) {
            handleSnackbarOpen(t("errorDeleteEquipment"), "error");
        }
    };
    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={equipment || initialValuesEquipment}
            validationSchema={equipmentSchema}
            enableReinitialize={true}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    {isLoadingGetEquipment ||
                    loadingFiles ||
                    isLoadingUpdateEquipment ||
                    isLoadingDeleteEquipment ? (
                        <LoadingSpinner />
                    ) : null}
                    <Box
                        m={!isNonMobile ? "0" : "1.5rem 2.5rem"}
                        display={!isNonMobile ? "flex" : "grid"}
                        gridTemplateRows={
                            !isNonMobile ? undefined : "repeat(4, auto)"
                        }
                        flexDirection={!isNonMobile ? "column" : undefined}
                        gap="20px"
                        borderRadius="5px"
                        p="2rem"
                    >
                        <PageHeader title={values.name} subtitle="" />
                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("equipmentInformation")}
                            </Typography>
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    !isNonMobile
                                        ? "repeat(1, 1fr)"
                                        : "repeat(2, 1fr)"
                                }
                                gap="10px"
                            >
                                <TextField
                                    variant="outlined"
                                    label={t("name")}
                                    inputRef={nameRef}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    name="name"
                                    onChange={(event) => {
                                        handleChange(event);
                                        setIsToUpdateEquipment(true); // Atualiza a flag sem substituir handleChange
                                    }}
                                    error={
                                        Boolean(touched.name) &&
                                        Boolean(errors.name)
                                    }
                                    helperText={touched.name && errors.name}
                                    sx={{ width: "100%" }}
                                />
                                <TextField
                                    label={t("description")}
                                    onBlur={handleBlur}
                                    value={values.description}
                                    name="description"
                                    onChange={(event) => {
                                        handleChange(event);
                                        setIsToUpdateEquipment(true); // Atualiza a flag sem substituir handleChange
                                    }}
                                    error={
                                        Boolean(touched.description) &&
                                        Boolean(errors.description)
                                    }
                                    helperText={
                                        touched.description &&
                                        errors.description
                                    }
                                    sx={{ width: "100%" }}
                                    multiline
                                />
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="pt"
                                >
                                    {values.acquisitionDate !== null ? (
                                        <DatePicker
                                            label={t("acquisitionDate")}
                                            value={dayjs(
                                                values.acquisitionDate
                                            )}
                                            format="DD/MM/YYYY"
                                            onChange={(value) => {
                                                setFieldValue(
                                                    "acquisitionDate",
                                                    value
                                                );
                                                setIsToUpdateEquipment(true);
                                            }}
                                        />
                                    ) : (
                                        <DatePicker
                                            label={t("acquisitionDate")}
                                            value={values.acquisitionDate}
                                            format="DD/MM/YYYY"
                                            onChange={(value) => {
                                                setFieldValue(
                                                    "acquisitionDate",
                                                    value
                                                );
                                                setIsToUpdateEquipment(true);
                                            }}
                                        />
                                    )}
                                </LocalizationProvider>
                            </Box>
                        </Box>

                        <Box
                            display="flex"
                            gap="20px"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                fontWeight="500"
                                fontSize="20px"
                                color="primary"
                                variant="h5"
                                textAlign="left"
                            >
                                {t("attachments")}
                            </Typography>
                            <Dropzone multiple={true} onDrop={onDropFiles}>
                                {({ getRootProps, getInputProps }) => (
                                    <FlexBetween>
                                        <Box
                                            {...getRootProps()}
                                            border={`2px dashed ${theme.palette.primary.main}`}
                                            p="1rem"
                                            width="100%"
                                            display="grid"
                                            gridTemplateColumns={
                                                !isNonMobile
                                                    ? "repeat(1, 1fr)"
                                                    : "repeat(5, auto)"
                                            }
                                            gap="10px"
                                            borderRadius="5px"
                                            sx={{
                                                "&:hover": {
                                                    cursor: "pointer",
                                                },
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            {files.length === 0 ? (
                                                <p>
                                                    {t("dropzoneAddYouFiles")}
                                                </p>
                                            ) : (
                                                files.map((file) => (
                                                    <Card
                                                        sx={{
                                                            backgroundColor:
                                                                theme.palette
                                                                    .background
                                                                    .D,
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            gap: "10px",
                                                            p: "15px 10px 15px 0px",
                                                            "& .MuiCardContent-root:last-child":
                                                                {
                                                                    paddingBottom:
                                                                        "20px",
                                                                },
                                                        }}
                                                        key={file.fileName}
                                                        onClick={(event) =>
                                                            handleFilePreview(
                                                                event,
                                                                file
                                                            )
                                                        }
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                // pl: 1,
                                                                // pb: 1,
                                                            }}
                                                        >
                                                            <IconButton
                                                                onClick={(
                                                                    event
                                                                ) =>
                                                                    handleRemoveFile(
                                                                        event,
                                                                        file
                                                                    )
                                                                }
                                                            >
                                                                <DeleteOutlined />
                                                            </IconButton>
                                                        </Box>
                                                        <CardContent
                                                            sx={{
                                                                p: "0px !important",
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: 14,
                                                                }}
                                                                color="text.secondary"
                                                            >
                                                                {file.fileName.split(
                                                                    "/"
                                                                )[2]
                                                                    ? file.fileName.split(
                                                                          "/"
                                                                      )[2]
                                                                    : file.fileName}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                ))
                                            )}
                                        </Box>
                                    </FlexBetween>
                                )}
                            </Dropzone>
                        </Box>
                        {/* BUTTONS */}
                        <Box display="flex" gap="10px">
                            <LoadingButton
                                fullWidth
                                type="button"
                                onClick={handleOpenDeleteConfirmationDialog}
                                loading={isLoadingDeleteEquipment}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                            >
                                {t("deleteEquipmentButton")}
                            </LoadingButton>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                loading={isLoadingUpdateEquipment}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                            >
                                {t("editEquipment")}
                            </LoadingButton>
                        </Box>
                    </Box>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity={snackbarSeverity}
                            sx={{
                                mt: "10px",
                                backgroundColor:
                                    snackbarSeverity === "success"
                                        ? "#10781F"
                                        : "#ff000082",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {snackbarMsg}
                        </Alert>
                    </Snackbar>
                    <Dialog
                        open={openDeleteConfirmationDialog}
                        onClose={handleCloseDeleteConfirmationDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("deleteEquipmentConfirmationTitle")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t("deleteEquipmentConfirmationMessage")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                                onClick={handleCloseDeleteConfirmationDialog}
                            >
                                {t("noMessage")}
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                                onClick={handleDeleteEquipment}
                                autoFocus
                            >
                                {t("yesMessage")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            )}
        </Formik>
    );
};

export default EditEquipmentForm;

import { useState, useEffect } from "react";
import PageHeader from "components/PageHeader";
import {
    Box,
    TextField,
    Typography,
    useTheme,
    Alert,
    useMediaQuery,
} from "@mui/material";
import { useParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import LoadingSpinner from "components/LoadingSpinner";
import Snackbar from "@mui/material/Snackbar";
import { Formik } from "formik";
import * as yup from "yup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTranslation } from "react-i18next";
import { RESPONSES } from "config/responses";
import { useNavigate, useLocation } from "react-router-dom";
import {
    useCalculateSalarySheetMutation,
    useDeleteEmployeeCostByWorkByIdMutation,
    useGetEmployeeCostsByWorkByIdQuery,
} from "./employeeCostsByWorkApiSlice";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SETTINGS } from "helpers/settings";

const EditEmployeeCostsByWorkForm = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate(); /* Set the Navigation Service */
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const [workTypeId, setWorkTypeId] = useState(0);
    const [subContractorId, setSubContractorId] = useState(null);

    const [salarySheet, setSalarySheet] = useState({
        grossSalary: "",
        salarySocialSecurity: "",
        expensesAllowance: "",
    });

    const [companyDiscountsFromWork, setCompanyDiscountsFromWork] = useState({
        employeeDeductions: "",
        socialSecurity: "",
        expensesAllowance: "",
        employeeDeductionsByWork: "",
    });

    const [hourCostsFromWork, setHourCostsFromWork] = useState({
        netSalary: "",
        socialSecurity: "",
        expensesAllowance: "",
        insurance: "",
        civilResponsability: "",
        compensatoryFunds: "",
        transportation: "",
        maintenanceAndConsumables: "",
        rents: "",
        indirectCosts: "",
        extraCost: "",
    });

    const [clearanceFromWork, setClearanceFromWork] = useState({
        hourCost: "",
        hourPrice: "",
        hourProfit: "",
        workCost: "",
        workProfit: "",
    });

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMsg(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
        useState(false);

    const handleOpenDeleteConfirmationDialog = () => {
        setOpenDeleteConfirmationDialog(true);
    };

    const handleCloseDeleteConfirmationDialog = () => {
        setOpenDeleteConfirmationDialog(false);
    };

    const { id } =
        useParams(); /* Get the Employee by Id from the route Params */

    const initialValuesEmployeeCostsByWork = {
        employeeCostMonth: "",
        employeeRegist: {
            hourSalary: "",
            workHoursTotal: "",
            mealAllowance: "",
            workHoursTotalByWork: "",
            mealProporcional: "",
            realSalary: "",
        },
        employee: {
            name: "",
        },
        workPlanning: {
            name: "",
            work: {
                name: "",
            },
        },
    }; /* Set the Form Initial Values */

    const salarySheetSchema = yup.object().shape({
        grossSalary: yup
            .number(t("invalidValue"))
            .typeError(t("invalidValue"))
            .required(t("requiredField")),
        salarySocialSecurity: yup
            .number(t("invalidValue"))
            .typeError(t("invalidValue"))
            .required(t("requiredField")),
        expensesAllowance: yup
            .number(t("invalidValue"))
            .typeError(t("invalidValue"))
            .required(t("requiredField")),
    }); /* Set the company doc Schema */

    const initialValuesSalarySheet = {
        grossSalary: "",
        salarySocialSecurity: "",
        expensesAllowance: "",
    }; /* Set the Form Initial Values */

    const {
        data: employeeCostsByWork,
        isLoading: isLoadingGetEmployeeCostsByWork,
    } = useGetEmployeeCostsByWorkByIdQuery(id);

    useEffect(() => {
        if (employeeCostsByWork) {
            setWorkTypeId(employeeCostsByWork.workPlanning.work.workTypeId);
            setSubContractorId(employeeCostsByWork.employee.subContractorId);

            if (employeeCostsByWork.salarySheet) {
                setSalarySheet(employeeCostsByWork.salarySheet);
            }

            if (employeeCostsByWork.companyDiscountsFromWork) {
                setCompanyDiscountsFromWork(
                    employeeCostsByWork.companyDiscountsFromWork
                );
            }

            if (employeeCostsByWork.hourCosts) {
                setHourCostsFromWork(employeeCostsByWork.hourCosts);
            }

            if (employeeCostsByWork.clearance) {
                setClearanceFromWork(employeeCostsByWork.clearance);
            }
        }
    }, [employeeCostsByWork]);

    /** Calculate Salary Sheet */
    const [calculateSalarySheet, { isLoadingCalculateSalarySheet }] =
        useCalculateSalarySheetMutation();

    const calculateSalarySheetSubmit = async (values) => {
        try {
            let salarySheetDTO = {
                EmployeeCostsByWorkId: id,
                GrossSalary: values.grossSalary,
                SalarySocialSecurity: values.salarySocialSecurity,
                ExpensesAllowance: values.expensesAllowance,
            };

            const { codeResult } = await calculateSalarySheet(
                salarySheetDTO
            ).unwrap();

            if (
                codeResult ===
                RESPONSES.employeeCostsByWork.sucessToCalculateAllCost
            ) {
                handleSnackbarOpen(t("sucessToUpdateCosts"), "success");
            } else {
                handleSnackbarOpen(t("errorToUpdateCosts"), "error");
                //pois quando da algum erro o custo é removido
                navigate(`/${location.pathname.split("/")[1]}`);
            }
        } catch (err) {
            handleSnackbarOpen(t("errorToUpdateCosts"), "error");
        }
    };

    const [deleteEmployeeCostByWork] =
        useDeleteEmployeeCostByWorkByIdMutation();

    const deleteEmployeeCostByWorkSubmit = async (values) => {
        try {
            const { codeResult } = await deleteEmployeeCostByWork(id).unwrap();

            if (
                codeResult ===
                RESPONSES.employeeCostsByWork.sucessToDeleteEmployeeCostByWork
            ) {
                navigate(`/${location.pathname.split("/")[1]}`);
            } else {
                handleSnackbarOpen(
                    t("errorToDeleteEmployeeCostByWork"),
                    "error"
                );
            }
        } catch (err) {
            handleSnackbarOpen(t("errorToDeleteEmployeeCostByWork"), "error");
        }
    };

    return (
        <Box sx={{ paddingBottom: "2rem" }}>
            <Formik
                initialValues={
                    employeeCostsByWork || initialValuesEmployeeCostsByWork
                }
                enableReinitialize={true}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    resetForm,
                }) => (
                    <form>
                        {isLoadingGetEmployeeCostsByWork ? (
                            <LoadingSpinner />
                        ) : null}
                        <Box
                            m={!isNonMobile ? "0" : "1.5rem 2.5rem"}
                            display={!isNonMobile ? "flex" : "grid"}
                            gridTemplateRows={
                                !isNonMobile ? undefined : "repeat(3, auto)"
                            }
                            flexDirection={!isNonMobile ? "column" : undefined}
                            gap="20px"
                            borderRadius="5px"
                            p="2rem 2rem 0rem 2rem"
                        >
                            <PageHeader
                                title={values.employee.name}
                                subtitle=""
                            />
                            <Box
                                display="flex"
                                gap="20px"
                                flexDirection="column"
                                width="100%"
                            >
                                <Typography
                                    fontWeight="500"
                                    fontSize="20px"
                                    color="primary"
                                    variant="h5"
                                    textAlign="left"
                                >
                                    {t("costInformation")}
                                </Typography>
                                <Box
                                    display="grid"
                                    gridTemplateColumns={
                                        !isNonMobile
                                            ? "repeat(1, 1fr)"
                                            : "repeat(3, 1fr)"
                                    }
                                    gap="10px"
                                >
                                    <TextField
                                        label={t("employee")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.employee.name}
                                        sx={{ width: "100%" }}
                                        readOnly={true}
                                    />
                                    <TextField
                                        label={t("planningWork")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={
                                            values.workPlanning.name +
                                            " - " +
                                            values.workPlanning.work.name
                                        }
                                        sx={{ width: "100%" }}
                                        readOnly={true}
                                    />
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <DatePicker
                                            label={t("monthlyEmployeeCost")}
                                            format="MM/YYYY"
                                            value={dayjs(
                                                values.employeeCostMonth
                                            )}
                                            onChange={(value) =>
                                                setFieldValue(
                                                    "employeeCostMonth",
                                                    value
                                                )
                                            }
                                            readOnly={true}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                {/** REGISTO DO FUNCIONÁRIO */}

                                <Box
                                    display="flex"
                                    marginTop="1rem"
                                    gap="20px"
                                    flexDirection="column"
                                    width="100%"
                                >
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap="10px"
                                        justifyContent="space-between"
                                    >
                                        <Typography
                                            fontWeight="500"
                                            fontSize="20px"
                                            color="primary"
                                            variant="h5"
                                            textAlign="left"
                                        >
                                            {t("employeeRegist")}
                                        </Typography>
                                    </Box>
                                    {isLoadingGetEmployeeCostsByWork ? (
                                        <LoadingSpinner />
                                    ) : (
                                        <Box
                                            display="grid"
                                            gridTemplateColumns={
                                                !isNonMobile
                                                    ? "repeat(1, 1fr)"
                                                    : "repeat(3, 1fr)"
                                            }
                                            gap="10px"
                                        >
                                            <TextField
                                                label={t("totalNumberOfHours")}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={
                                                    values.employeeRegist
                                                        .workHoursTotal
                                                }
                                                sx={{ width: "100%" }}
                                                readOnly={true}
                                            />
                                            <TextField
                                                label={t(
                                                    "totalNumberOfHoursInWork"
                                                )}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={
                                                    values.employeeRegist
                                                        .workHoursTotalByWork
                                                }
                                                sx={{ width: "100%" }}
                                                readOnly={true}
                                            />
                                            <TextField
                                                label={t("hourSalary")}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={
                                                    values.employeeRegist
                                                        .hourSalary
                                                }
                                                sx={{ width: "100%" }}
                                                InputProps={{
                                                    endAdornment: "€",
                                                }}
                                                readOnly={true}
                                            />
                                            <TextField
                                                label={t("mealAllowance")}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={
                                                    values.employeeRegist
                                                        .mealAllowance
                                                }
                                                sx={{ width: "100%" }}
                                                InputProps={{
                                                    endAdornment: "€",
                                                }}
                                                readOnly={true}
                                            />
                                            <TextField
                                                label={t("mealProporcional")}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={
                                                    values.employeeRegist
                                                        .mealProporcional
                                                }
                                                sx={{ width: "100%" }}
                                                InputProps={{
                                                    endAdornment: "€",
                                                }}
                                                readOnly={true}
                                            />
                                            <TextField
                                                label={t("realSalary")}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={
                                                    values.employeeRegist
                                                        .realSalary
                                                }
                                                sx={{ width: "100%" }}
                                                InputProps={{
                                                    endAdornment: "€",
                                                }}
                                                readOnly={true}
                                            />
                                        </Box>
                                    )}
                                </Box>
                                {/** FOLHA SALARIAL */}
                            </Box>
                            <Snackbar
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                open={openSnackbar}
                                autoHideDuration={6000}
                                onClose={handleCloseSnackBar}
                            >
                                <Alert
                                    onClose={handleCloseSnackBar}
                                    severity={snackbarSeverity}
                                    sx={{
                                        mt: "10px",
                                        backgroundColor:
                                            snackbarSeverity === "success"
                                                ? "#10781F"
                                                : "#ff000082",
                                        alignItems: "center",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        color: "#fff",
                                    }}
                                >
                                    {snackbarMsg}
                                </Alert>
                            </Snackbar>
                        </Box>
                    </form>
                )}
            </Formik>
            <Formik
                onSubmit={calculateSalarySheetSubmit}
                initialValues={salarySheet || initialValuesSalarySheet}
                validationSchema={salarySheetSchema}
                enableReinitialize={true}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    handleSubmit,
                    resetForm,
                }) => (
                    <form onSubmit={handleSubmit}>
                        {isLoadingGetEmployeeCostsByWork ||
                        isLoadingCalculateSalarySheet ? (
                            <LoadingSpinner />
                        ) : null}
                        {subContractorId === null && (
                            <Box
                                m={
                                    !isNonMobile
                                        ? "1.5rem 0 0 0"
                                        : "1.5rem 2.5rem"
                                }
                                display={!isNonMobile ? "flex" : "grid"}
                                gridTemplateRows={
                                    !isNonMobile ? undefined : "repeat(3, auto)"
                                }
                                flexDirection={
                                    !isNonMobile ? "column" : undefined
                                }
                                gap="20px"
                                borderRadius="5px"
                                p="0rem 2rem 0rem 2rem"
                            >
                                <Box
                                    display="flex"
                                    gap="20px"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    width="100%"
                                >
                                    <Typography
                                        fontWeight="500"
                                        fontSize="20px"
                                        color="primary"
                                        variant="h5"
                                        textAlign="left"
                                    >
                                        {t("salarySheet")}
                                    </Typography>
                                </Box>
                                {isLoadingGetEmployeeCostsByWork ||
                                isLoadingCalculateSalarySheet ? (
                                    <LoadingSpinner />
                                ) : (
                                    <Box
                                        display="grid"
                                        gridTemplateColumns={
                                            !isNonMobile
                                                ? "repeat(1, 1fr)"
                                                : "repeat(3, 1fr)"
                                        }
                                        gap="10px"
                                    >
                                        <TextField
                                            label={t("grossSalary")}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.grossSalary}
                                            name="grossSalary"
                                            error={
                                                Boolean(touched.grossSalary) &&
                                                Boolean(errors.grossSalary)
                                            }
                                            InputProps={{
                                                endAdornment: "€",
                                            }}
                                            helperText={
                                                touched.grossSalary &&
                                                errors.grossSalary
                                            }
                                            sx={{
                                                width: "100%",
                                            }}
                                        />
                                        <TextField
                                            label={t("salarySocialSecurity")}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.salarySocialSecurity}
                                            name="salarySocialSecurity"
                                            error={
                                                Boolean(
                                                    touched.salarySocialSecurity
                                                ) &&
                                                Boolean(
                                                    errors.salarySocialSecurity
                                                )
                                            }
                                            InputProps={{
                                                endAdornment: "€",
                                            }}
                                            helperText={
                                                touched.salarySocialSecurity &&
                                                errors.salarySocialSecurity
                                            }
                                            sx={{
                                                width: "100%",
                                            }}
                                        />
                                        <TextField
                                            label={t("expensesAllowance")}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.expensesAllowance}
                                            name="expensesAllowance"
                                            error={
                                                Boolean(
                                                    touched.expensesAllowance
                                                ) &&
                                                Boolean(
                                                    errors.expensesAllowance
                                                )
                                            }
                                            InputProps={{
                                                endAdornment: "€",
                                            }}
                                            helperText={
                                                touched.expensesAllowance &&
                                                errors.expensesAllowance
                                            }
                                            sx={{
                                                width: "100%",
                                            }}
                                        />
                                    </Box>
                                )}
                            </Box>
                        )}
                        {subContractorId === null && (
                            <Box
                                m={
                                    !isNonMobile
                                        ? "1.5rem 0 0 0"
                                        : "1.5rem 2.5rem"
                                }
                                display={!isNonMobile ? "flex" : "grid"}
                                gridTemplateRows={
                                    !isNonMobile ? undefined : "repeat(3, auto)"
                                }
                                flexDirection={
                                    !isNonMobile ? "column" : undefined
                                }
                                gap="20px"
                                borderRadius="5px"
                                p="0rem 2rem 0rem 2rem"
                                open={false}
                            >
                                <Box
                                    display="flex"
                                    gap="20px"
                                    flexDirection="row"
                                    width="100%"
                                    justifyContent="space-between"
                                >
                                    <Typography
                                        fontWeight="500"
                                        fontSize="20px"
                                        color="primary"
                                        variant="h5"
                                        textAlign="left"
                                    >
                                        {t("companyDiscounts")}
                                    </Typography>
                                </Box>
                                {isLoadingGetEmployeeCostsByWork ||
                                isLoadingCalculateSalarySheet ? (
                                    <LoadingSpinner />
                                ) : (
                                    <Box
                                        display="grid"
                                        gridTemplateColumns={
                                            !isNonMobile
                                                ? "repeat(1, 1fr)"
                                                : "repeat(2, 1fr)"
                                        }
                                        gap="10px"
                                    >
                                        <TextField
                                            label={t("employeeDeductions")}
                                            value={
                                                companyDiscountsFromWork.employeeDeductions
                                            }
                                            InputProps={{
                                                endAdornment: "€",
                                            }}
                                            sx={{
                                                width: "100%",
                                            }}
                                            readOnly={true}
                                        />
                                        <TextField
                                            label={t("socialSecurity")}
                                            value={
                                                companyDiscountsFromWork.socialSecurity
                                            }
                                            InputProps={{
                                                endAdornment: "€",
                                            }}
                                            sx={{
                                                width: "100%",
                                            }}
                                            readOnly={true}
                                        />
                                        <TextField
                                            label={t("expensesAllowance")}
                                            value={
                                                companyDiscountsFromWork.expensesAllowance
                                            }
                                            InputProps={{
                                                endAdornment: "€",
                                            }}
                                            sx={{
                                                width: "100%",
                                            }}
                                            readOnly={true}
                                        />
                                        <TextField
                                            label={t(
                                                "employeeDeductionsInWork"
                                            )}
                                            value={
                                                companyDiscountsFromWork.employeeDeductionsByWork
                                            }
                                            InputProps={{
                                                endAdornment: "€",
                                            }}
                                            sx={{
                                                width: "100%",
                                            }}
                                            readOnly={true}
                                        />
                                    </Box>
                                )}
                            </Box>
                        )}

                        <Box
                            m={!isNonMobile ? "1.5rem 0 0 0" : "1.5rem 2.5rem"}
                            display={!isNonMobile ? "flex" : "grid"}
                            gridTemplateRows={
                                !isNonMobile ? undefined : "repeat(3, auto)"
                            }
                            flexDirection={!isNonMobile ? "column" : undefined}
                            gap="20px"
                            borderRadius="5px"
                            p="0rem 2rem 0rem 2rem"
                            open={false}
                        >
                            <Box
                                display="flex"
                                gap="20px"
                                flexDirection="row"
                                width="100%"
                                justifyContent="space-between"
                            >
                                <Typography
                                    fontWeight="500"
                                    fontSize="20px"
                                    color="primary"
                                    variant="h5"
                                    textAlign="left"
                                >
                                    {t("hourCost")}
                                </Typography>
                            </Box>
                            {isLoadingGetEmployeeCostsByWork ||
                            isLoadingCalculateSalarySheet ? (
                                <LoadingSpinner />
                            ) : subContractorId === null ? (
                                <Box
                                    display="grid"
                                    gridTemplateColumns={
                                        !isNonMobile
                                            ? "repeat(1, 1fr)"
                                            : "repeat(2, 1fr)"
                                    }
                                    gap="10px"
                                >
                                    <TextField
                                        label={t("netSalary")}
                                        value={hourCostsFromWork.netSalary}
                                        InputProps={{
                                            endAdornment: "€",
                                        }}
                                        sx={{
                                            width: "100%",
                                        }}
                                        readOnly={true}
                                    />
                                    <TextField
                                        label={t("socialSecurity")}
                                        value={hourCostsFromWork.socialSecurity}
                                        InputProps={{
                                            endAdornment: "€",
                                        }}
                                        sx={{
                                            width: "100%",
                                        }}
                                        readOnly={true}
                                    />
                                    <TextField
                                        label={t("expensesAllowance")}
                                        value={
                                            hourCostsFromWork.expensesAllowance
                                        }
                                        InputProps={{
                                            endAdornment: "€",
                                        }}
                                        sx={{
                                            width: "100%",
                                        }}
                                        readOnly={true}
                                    />
                                    <TextField
                                        label={t("insurance")}
                                        value={hourCostsFromWork.insurance}
                                        InputProps={{
                                            endAdornment: "€",
                                        }}
                                        sx={{
                                            width: "100%",
                                        }}
                                        readOnly={true}
                                    />
                                    <TextField
                                        label={t("civilResponsability")}
                                        value={
                                            hourCostsFromWork.civilResponsability
                                        }
                                        InputProps={{
                                            endAdornment: "€",
                                        }}
                                        sx={{
                                            width: "100%",
                                        }}
                                        readOnly={true}
                                    />
                                    <TextField
                                        label={t("transports")}
                                        value={hourCostsFromWork.transportation}
                                        InputProps={{
                                            endAdornment: "€",
                                        }}
                                        sx={{
                                            width: "100%",
                                        }}
                                        readOnly={true}
                                    />
                                    <TextField
                                        label={t("maintenanceAndConsumables")}
                                        value={
                                            hourCostsFromWork.maintenanceAndConsumables
                                        }
                                        InputProps={{
                                            endAdornment: "€",
                                        }}
                                        sx={{
                                            width: "100%",
                                        }}
                                        readOnly={true}
                                    />
                                    <TextField
                                        label={t("rents")}
                                        value={hourCostsFromWork.rents}
                                        InputProps={{
                                            endAdornment: "€",
                                        }}
                                        sx={{
                                            width: "100%",
                                        }}
                                        readOnly={true}
                                    />
                                    <TextField
                                        label={t("indirectCosts")}
                                        value={hourCostsFromWork.indirectCosts}
                                        InputProps={{
                                            endAdornment: "€",
                                        }}
                                        sx={{
                                            width: "100%",
                                        }}
                                        readOnly={true}
                                    />
                                    <TextField
                                        label={t("extraCost")}
                                        value={hourCostsFromWork.extraCost}
                                        InputProps={{
                                            endAdornment: "€",
                                        }}
                                        sx={{
                                            width: "100%",
                                        }}
                                        readOnly={true}
                                    />
                                </Box>
                            ) : (
                                <Box
                                    display="grid"
                                    gridTemplateColumns={
                                        !isNonMobile
                                            ? "repeat(1, 1fr)"
                                            : "repeat(3, 1fr)"
                                    }
                                    gap="10px"
                                >
                                    <TextField
                                        label={t("transports")}
                                        value={hourCostsFromWork.transportation}
                                        InputProps={{
                                            endAdornment: "€",
                                        }}
                                        sx={{
                                            width: "100%",
                                        }}
                                        readOnly={true}
                                    />
                                    <TextField
                                        label={t("rents")}
                                        value={hourCostsFromWork.rents}
                                        InputProps={{
                                            endAdornment: "€",
                                        }}
                                        sx={{
                                            width: "100%",
                                        }}
                                        readOnly={true}
                                    />
                                    <TextField
                                        label={t("extraCost")}
                                        value={hourCostsFromWork.extraCost}
                                        InputProps={{
                                            endAdornment: "€",
                                        }}
                                        sx={{
                                            width: "100%",
                                        }}
                                        readOnly={true}
                                    />
                                </Box>
                            )}
                        </Box>
                        <Box
                            m={!isNonMobile ? "1.5rem 0 0 0" : "1.5rem 2.5rem"}
                            display={!isNonMobile ? "flex" : "grid"}
                            gridTemplateRows={
                                !isNonMobile ? undefined : "repeat(3, auto)"
                            }
                            flexDirection={!isNonMobile ? "column" : undefined}
                            gap="20px"
                            borderRadius="5px"
                            p="0rem 2rem 0rem 2rem"
                            open={false}
                        >
                            <Box
                                display="flex"
                                gap="20px"
                                flexDirection="row"
                                width="100%"
                                justifyContent="space-between"
                            >
                                <Typography
                                    fontWeight="500"
                                    fontSize="20px"
                                    color="primary"
                                    variant="h5"
                                    textAlign="left"
                                >
                                    {t("clearance")}
                                </Typography>
                            </Box>
                            {isLoadingGetEmployeeCostsByWork ||
                            isLoadingCalculateSalarySheet ? (
                                <LoadingSpinner />
                            ) : workTypeId === SETTINGS.WorkType.Company ? (
                                subContractorId === null ? (
                                    <Box
                                        display="grid"
                                        gridTemplateColumns={
                                            !isNonMobile
                                                ? "repeat(1, 1fr)"
                                                : "repeat(2, 1fr)"
                                        }
                                        gap="10px"
                                    >
                                        <TextField
                                            label={t("hourCost")}
                                            value={clearanceFromWork.hourCost}
                                            InputProps={{
                                                endAdornment: "€",
                                            }}
                                            sx={{
                                                width: "100%",
                                            }}
                                            readOnly={true}
                                        />
                                        <TextField
                                            label={t("hourPrice")}
                                            value={clearanceFromWork.hourPrice}
                                            InputProps={{
                                                endAdornment: "€",
                                            }}
                                            sx={{
                                                width: "100%",
                                            }}
                                            readOnly={true}
                                        />
                                        <TextField
                                            label={t("hourProfit")}
                                            value={clearanceFromWork.hourProfit}
                                            InputProps={{
                                                endAdornment: "€",
                                            }}
                                            sx={{
                                                width: "100%",
                                            }}
                                            readOnly={true}
                                        />
                                        <TextField
                                            label={t("workProfit")}
                                            value={clearanceFromWork.workProfit}
                                            InputProps={{
                                                endAdornment: "€",
                                            }}
                                            sx={{
                                                width: "100%",
                                            }}
                                            readOnly={true}
                                        />
                                    </Box>
                                ) : (
                                    <Box
                                        display="grid"
                                        gridTemplateColumns={
                                            !isNonMobile
                                                ? "repeat(1, 1fr)"
                                                : "repeat(2, 1fr)"
                                        }
                                        gap="10px"
                                    >
                                        <TextField
                                            label={t("workProfit")}
                                            value={clearanceFromWork.workProfit}
                                            InputProps={{
                                                endAdornment: "€",
                                            }}
                                            sx={{
                                                width: "100%",
                                            }}
                                            readOnly={true}
                                        />
                                    </Box>
                                )
                            ) : workTypeId === SETTINGS.WorkType.Private ? (
                                subContractorId === null ? (
                                    <Box
                                        display="grid"
                                        gridTemplateColumns={
                                            !isNonMobile
                                                ? "repeat(1, 1fr)"
                                                : "repeat(2, 1fr)"
                                        }
                                        gap="10px"
                                    >
                                        <TextField
                                            label={t("hourCost")}
                                            value={clearanceFromWork.hourCost}
                                            InputProps={{
                                                endAdornment: "€",
                                            }}
                                            sx={{
                                                width: "100%",
                                            }}
                                            readOnly={true}
                                        />
                                        <TextField
                                            label={t("workCost")}
                                            value={clearanceFromWork.workCost}
                                            InputProps={{
                                                endAdornment: "€",
                                            }}
                                            sx={{
                                                width: "100%",
                                            }}
                                            readOnly={true}
                                        />
                                    </Box>
                                ) : (
                                    <Box
                                        display="grid"
                                        gridTemplateColumns={
                                            !isNonMobile
                                                ? "repeat(1, 1fr)"
                                                : "repeat(2, 1fr)"
                                        }
                                        gap="10px"
                                    >
                                        <TextField
                                            label={t("workProfit")}
                                            value={clearanceFromWork.workProfit}
                                            InputProps={{
                                                endAdornment: "€",
                                            }}
                                            sx={{
                                                width: "100%",
                                            }}
                                            readOnly={true}
                                        />
                                    </Box>
                                )
                            ) : (
                                <div></div>
                            )}
                        </Box>
                        <Box
                            m={
                                !isNonMobile
                                    ? "1.5rem 0px 0px 0px"
                                    : "1.5rem 2.5rem 0rem 2.5rem"
                            }
                            display="flex"
                            gap="10px"
                            borderRadius="5px"
                            p="0rem 2rem 0rem 2rem"
                        >
                            <LoadingButton
                                fullWidth
                                type="button"
                                onClick={handleOpenDeleteConfirmationDialog}
                                sx={{
                                    width: "100%",
                                    p: "1rem",
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                            >
                                {t("deleteCostButton")}
                            </LoadingButton>
                            {subContractorId === null ? (
                                <LoadingButton
                                    fullWidth
                                    loading={isLoadingCalculateSalarySheet}
                                    type="submit"
                                    sx={{
                                        width: "100%",
                                        p: "1rem",
                                        backgroundColor:
                                            theme.palette.primary.main,
                                        color: theme.palette.neutral.white,
                                        "&:hover": {
                                            backgroundColor:
                                                theme.palette.primary.light,
                                        },
                                    }}
                                >
                                    {t("updateSalarySheet")}
                                </LoadingButton>
                            ) : (
                                <LoadingButton
                                    fullWidth
                                    disabled={true}
                                    type="submit"
                                    sx={{
                                        width: "100%",
                                        p: "1rem",
                                        backgroundColor:
                                            theme.palette.primary.main,
                                        color: theme.palette.neutral.white,
                                        "&:hover": {
                                            backgroundColor:
                                                theme.palette.primary.light,
                                        },
                                    }}
                                >
                                    {t("updateSalarySheet")}
                                </LoadingButton>
                            )}
                        </Box>
                        <Dialog
                            open={openDeleteConfirmationDialog}
                            onClose={handleCloseDeleteConfirmationDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {t("deleteEmployeeCostByWorkConfirmationTitle")}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {t(
                                        "deleteEmployeeCostByWorkConfirmationMessage"
                                    )}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <LoadingButton
                                    sx={{
                                        backgroundColor: theme.palette.alt.red,
                                        color: theme.palette.neutral.white,
                                        "&:hover": {
                                            backgroundColor:
                                                theme.palette.alt.lightRed,
                                        },
                                    }}
                                    onClick={
                                        handleCloseDeleteConfirmationDialog
                                    }
                                >
                                    {t("noMessage")}
                                </LoadingButton>
                                <LoadingButton
                                    sx={{
                                        backgroundColor:
                                            theme.palette.primary.main,
                                        color: theme.palette.neutral.white,
                                        "&:hover": {
                                            backgroundColor:
                                                theme.palette.primary.light,
                                        },
                                    }}
                                    onClick={deleteEmployeeCostByWorkSubmit}
                                    autoFocus
                                >
                                    {t("yesMessage")}
                                </LoadingButton>
                            </DialogActions>
                        </Dialog>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default EditEmployeeCostsByWorkForm;

import { Route, Routes } from "react-router-dom";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "theme";
import Layout from "components/Layout";
import Login from "features/auth/Login";
import MainLayout from "components/MainLayout";
import HomePage from "features/home/HomePage";
import UsersList from "features/users/UsersList";
import ClientsList from "features/clients/ClientsList";
import EmployeesList from "features/employees/EmployeesList";
import EmployeeCategoriesList from "features/employeeCategories/EmployeeCategoriesList";
import VehiclesList from "features/vehicles/VehiclesList";
import WorksList from "features/works/WorksList";
import AccomodationsList from "features/accomodations/AccomodationsList";
import SuppliersList from "features/suppliers/SuppliersList";
import CostCategoriesList from "features/costCategories/CostCategoriesList";
import CompanyDocsList from "features/companyDocs/CompanyDocsList";
import NewClientForm from "features/clients/NewClientForm";
import NewEmployeeForm from "features/employees/NewEmployeeForm";
import NewEmployeeCategoryForm from "features/employeeCategories/NewEmployeeCategoryForm";
import NewWorkForm from "features/works/NewWorkForm";
import NewAccomodationForm from "features/accomodations/NewAccomodationForm";
import NewUserForm from "features/users/NewUserForm";
import NewCostCategoryForm from "features/costCategories/NewCostCategoryForm";
import EditClientForm from "features/clients/EditClientForm";
import PersistLogin from "features/auth/PersistLogin";
import EditEmployeeForm from "features/employees/EditEmployeeForm";
import EditEmployeeCategoryForm from "features/employeeCategories/EditEmployeeCategoryForm";
import EditCostCategoryForm from "features/costCategories/EditCostCategoryForm";
import EditUserForm from "features/users/EditUserForm";
import NewCompanyDocForm from "features/companyDocs/NewCompanyDocForm";
import EditCompanyDocForm from "features/companyDocs/EditCompanyDocForm";
import EditaccomodationForm from "features/accomodations/EditAccomodationForm";
import EditWorkForm from "features/works/EditWorkForm";
import NewSupplierForm from "features/suppliers/NewSupplierForm";
import EditSupplierForm from "features/suppliers/EditSupplierForm";
import NewVehicleForm from "features/vehicles/NewVehicleForm";
import EditVehicleForm from "features/vehicles/EditVehicleForm";
import WorkPlanningsList from "features/workPlanning/WorkPlanningsList";
import NewWorkPlanningForm from "features/workPlanning/NewWorkPlanningForm";
import EditWorkPlanningForm from "features/workPlanning/EditWorkPlanningForm";
import AdvancePaymentsList from "features/advancePayments/AdvancePaymentsList";
import NewAdvancePaymentForm from "features/advancePayments/NewAdvancePaymentForm";
import EditAdvancePaymentForm from "features/advancePayments/EditAdvancePaymentForm";
import EmployeeWorkHourCostsList from "features/employeeWorkHourCosts/EmployeeWorkHourCostsList";
import NewEmployeeWorkHourCostsForm from "features/employeeWorkHourCosts/NewEmployeeWorkHourCostsForm";
import EditEmployeeWorkHourCostsForm from "features/employeeWorkHourCosts/EditEmployeeWorkHourCostsForm";
import EmployeeWorkHourCostsHistoricList from "features/employeeWorkHourCosts/EmployeeWorkHourCostsHistoricList";
import ContactsList from "features/contacts/ContactsList";
import EmployeeWorkHourCostsHistoricDetailsForm from "features/employeeWorkHourCosts/EmployeeWorkHourCostsHistoricDetailsForm";
import AdjustmentsList from "features/adjustments/AdjustmentsList";
import NewAdjustmentForm from "features/adjustments/NewAdjustmentForm";
import EditAdjustmentForm from "features/adjustments/EditAjustmentForm";
import EmployeeCostsByWorkList from "features/employeeCostsByWork/EmployeeCostsByWorkList";
import NewEmployeeCostsByWorkForm from "features/employeeCostsByWork/NewEmployeeCostsByWorkForm";
import EditEmployeeCostsByWorkForm from "features/employeeCostsByWork/EditEmployeeCostsByWorkForm";
import SubContractorsList from "features/subContractors/SubContractorsList";
import NewSubContractorForm from "features/subContractors/NewSubContractorForm";
import EditSubContractorForm from "features/subContractors/EditSubContractorForm";
import EmployeeCostsByWorkListHistory from "features/employeeCostsByWork/EmployeeCostsByWorkListHistory";
import EditEmployeeCostsByWorkHistoryForm from "features/employeeCostsByWork/EditEmployeeCostsByWorkHistoryForm";
import EquipmentsList from "features/equipments/EquipmentsList";
import NewEquipmentForm from "features/equipments/NewEquipmentForm";
import EditEquipmentForm from "features/equipments/EditEquipmentForm";
import { useTranslation } from "react-i18next";
import ApplicationsList from "features/applications/ApplicationsList";
import NewApplicationForm from "features/applications/NewApplicationForm";
import EditApplicationForm from "features/applications/EditApplicationForm";
import InternalRegistsList from "features/internalRegists/InternalRegistsList";
import NewInternalRegistForm from "features/internalRegists/NewInternalRegistForm";
import EditInternalRegistForm from "features/internalRegists/EditInternalRegistForm";

function App() {
    const mode = useSelector((state) => state.global.mode);
    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
    const { t } = useTranslation();

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Login />} />

                    <Route element={<PersistLogin />}>
                        <Route path={t("dashboard")} element={<MainLayout />}>
                            <Route index element={<HomePage />} />
                        </Route>
                        <Route path={t("users")} element={<MainLayout />}>
                            <Route index element={<UsersList />} />
                            <Route path="new" element={<NewUserForm />} />
                            <Route path=":id" element={<EditUserForm />} />
                        </Route>
                        <Route path={t("clients")} element={<MainLayout />}>
                            <Route index element={<ClientsList />} />
                            <Route path="new" element={<NewClientForm />} />
                            <Route path=":id" element={<EditClientForm />} />
                        </Route>
                        <Route path={t("employees")} element={<MainLayout />}>
                            <Route index element={<EmployeesList />} />
                            <Route path="new" element={<NewEmployeeForm />} />
                            <Route
                                path="new/:name/:phoneNumber/:categoryId"
                                element={<NewEmployeeForm />}
                            />
                            <Route path=":id" element={<EditEmployeeForm />} />
                        </Route>
                        <Route path={t("categories")} element={<MainLayout />}>
                            <Route index element={<EmployeeCategoriesList />} />
                            <Route
                                path="new"
                                element={<NewEmployeeCategoryForm />}
                            />
                            <Route
                                path=":id"
                                element={<EditEmployeeCategoryForm />}
                            />
                        </Route>
                        <Route path={t("vehicles")} element={<MainLayout />}>
                            <Route index element={<VehiclesList />} />
                            <Route path="new" element={<NewVehicleForm />} />
                            <Route path=":id" element={<EditVehicleForm />} />
                        </Route>
                        <Route path={t("works")} element={<MainLayout />}>
                            <Route index element={<WorksList />} />
                            <Route path="new" element={<NewWorkForm />} />
                            <Route path=":id" element={<EditWorkForm />} />
                        </Route>
                        <Route
                            path={t("accomodations")}
                            element={<MainLayout />}
                        >
                            <Route index element={<AccomodationsList />} />
                            <Route
                                path="new"
                                element={<NewAccomodationForm />}
                            />
                            <Route
                                path=":id"
                                element={<EditaccomodationForm />}
                            />
                        </Route>
                        <Route path={t("suppliers")} element={<MainLayout />}>
                            <Route index element={<SuppliersList />} />
                            <Route path="new" element={<NewSupplierForm />} />
                            <Route path=":id" element={<EditSupplierForm />} />
                        </Route>
                        <Route
                            path={t("costCategories")}
                            element={<MainLayout />}
                        >
                            <Route index element={<CostCategoriesList />} />
                            <Route
                                path="new"
                                element={<NewCostCategoryForm />}
                            />
                            <Route
                                path=":id"
                                element={<EditCostCategoryForm />}
                            />
                        </Route>
                        <Route path={t("documents")} element={<MainLayout />}>
                            <Route index element={<CompanyDocsList />} />
                            <Route path="new" element={<NewCompanyDocForm />} />
                            <Route
                                path=":id"
                                element={<EditCompanyDocForm />}
                            />
                        </Route>
                        <Route
                            path={t("workPlannings")}
                            element={<MainLayout />}
                        >
                            <Route index element={<WorkPlanningsList />} />
                            <Route
                                path="new"
                                element={<NewWorkPlanningForm />}
                            />
                            <Route
                                path=":id"
                                element={<EditWorkPlanningForm />}
                            />
                        </Route>
                        <Route
                            path={t("advancePayments")}
                            element={<MainLayout />}
                        >
                            <Route index element={<AdvancePaymentsList />} />
                            <Route
                                path="new"
                                element={<NewAdvancePaymentForm />}
                            />
                            <Route
                                path=":id"
                                element={<EditAdvancePaymentForm />}
                            />
                        </Route>
                        <Route path={t("adjustments")} element={<MainLayout />}>
                            <Route index element={<AdjustmentsList />} />
                            <Route path="new" element={<NewAdjustmentForm />} />
                            <Route
                                path=":id"
                                element={<EditAdjustmentForm />}
                            />
                        </Route>
                        <Route path={t("processing")} element={<MainLayout />}>
                            <Route
                                index
                                element={<EmployeeWorkHourCostsList />}
                            />
                            <Route
                                path="new"
                                element={<NewEmployeeWorkHourCostsForm />}
                            />
                            <Route
                                path=":id"
                                element={<EditEmployeeWorkHourCostsForm />}
                            />
                        </Route>
                        <Route
                            path={t("processingHistory")}
                            element={<MainLayout />}
                        >
                            <Route
                                index
                                element={<EmployeeWorkHourCostsHistoricList />}
                            />
                            <Route
                                path="Historic/:id"
                                element={
                                    <EmployeeWorkHourCostsHistoricDetailsForm />
                                }
                            />
                        </Route>
                        <Route
                            path={t("subContractors")}
                            element={<MainLayout />}
                        >
                            <Route index element={<SubContractorsList />} />
                            <Route
                                path="new"
                                element={<NewSubContractorForm />}
                            />
                            <Route
                                path=":id"
                                element={<EditSubContractorForm />}
                            />
                        </Route>
                        <Route path={t("contacts")} element={<MainLayout />}>
                            <Route index element={<ContactsList />} />
                        </Route>
                        <Route
                            path={t("employeeCosts")}
                            element={<MainLayout />}
                        >
                            <Route
                                index
                                element={<EmployeeCostsByWorkList />}
                            />
                            <Route
                                path="new"
                                element={<NewEmployeeCostsByWorkForm />}
                            />
                            <Route
                                path=":id"
                                element={<EditEmployeeCostsByWorkForm />}
                            />
                        </Route>
                        <Route
                            path={t("employeeCostHistory")}
                            element={<MainLayout />}
                        >
                            <Route
                                index
                                element={<EmployeeCostsByWorkListHistory />}
                            />
                            <Route
                                path=":id"
                                element={<EditEmployeeCostsByWorkHistoryForm />}
                            />
                        </Route>
                        <Route path={t("equipments")} element={<MainLayout />}>
                            <Route index element={<EquipmentsList />} />
                            <Route path="new" element={<NewEquipmentForm />} />
                            <Route path=":id" element={<EditEquipmentForm />} />
                        </Route>
                        <Route
                            path={t("applications")}
                            element={<MainLayout />}
                        >
                            <Route index element={<ApplicationsList />} />
                            <Route
                                path="new"
                                element={<NewApplicationForm />}
                            />
                            <Route
                                path=":id"
                                element={<EditApplicationForm />}
                            />
                        </Route>
                        <Route
                            path={t("internalRegists")}
                            element={<MainLayout />}
                        >
                            <Route index element={<InternalRegistsList />} />
                            <Route
                                path="new"
                                element={<NewInternalRegistForm />}
                            />
                            <Route
                                path=":id"
                                element={<EditInternalRegistForm />}
                            />
                        </Route>
                    </Route>
                </Route>
            </Routes>
        </ThemeProvider>
    );
}

export default App;
